import {AxiosResponse} from "axios";
import {$apiNltx, $apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface ReportDashboardDuringPeriodReq {
    from: string,
    to: string
}

export interface ReportDashboardDynamicsReq {
    from: string,
    to: string
}

export interface ReportDashboardTodayRes {
    visits: number,
    avgTime: number,
    ordersSumm: number,
    ordersCount: number,
    feedbackVisited: number,
    feedbackSubmitted: number,
    cartAdditionSumm: number,
    cartAdditionCount: number,
}

export interface ReportDashboardDuringPeriodRes {
    visits: number,
    avgTime: number,
    ordersSumm: number,
    ordersCount: number,
    feedbackVisited: number,
    feedbackSubmitted: number,
    cartAdditionSumm: number,
    cartAdditionCount: number,
}

export interface ReportDashboardDynamicsRes {
    visitDates: string[],
    visits: number[],
    activeUsersDates: string[],
    activeUsersCount: number[],
    newUsers: number[], // len = 5
}

export interface ReportMostVisitedMenus {
    urls: string[],
    allTimeVisits: number[],
    todayVisits: number[], // len = 100
}

export default class DashboardsService {
    static async reportTodayDashboard(orgId: string, controller: AbortController): Promise<AxiosResponse<SuccessResponse<ReportDashboardTodayRes> | ErrorResponse>> {
        return $apiNltx.post<SuccessResponse<ReportDashboardTodayRes> | ErrorResponse>(ApiRoutes.REPORT_TODAY_DASHBOARD, {}, {signal: controller.signal});
    }

    static async reportDashboardDuringPeriod(orgId: string, request: ReportDashboardDuringPeriodReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<ReportDashboardDuringPeriodRes> | ErrorResponse>> {
        return $apiNltx.post<SuccessResponse<ReportDashboardDuringPeriodRes> | ErrorResponse>(ApiRoutes.REPORT_DASHBOARD_DURING_PERIOD, request, {signal: controller.signal});
    }

    static async reportDashboardDynamics(orgId: string, request: ReportDashboardDynamicsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<ReportDashboardDynamicsRes> | ErrorResponse>> {
        return $apiNltx.post<SuccessResponse<ReportDashboardDynamicsRes> | ErrorResponse>(ApiRoutes.REPORT_DASHBOARD_DYNAMICS, request, {signal: controller.signal});
    }

    static async reportMostVisitedMenus(orgId: string, controller: AbortController): Promise<AxiosResponse<SuccessResponse<ReportMostVisitedMenus> | ErrorResponse>> {
        return $apiNltx.post<SuccessResponse<ReportMostVisitedMenus> | ErrorResponse>(ApiRoutes.REPORT_MOST_VISITED_MENUS, {}, {signal: controller.signal});
    }
}
