import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
    label: string
    value: Date
    onChange: (value: Date) => void
    isRequired?: boolean
    isValidate: boolean
    end?: Date
    start?: Date
    selectsStart?: boolean
    selectsEnd?: boolean
}

export function InputDate(props: Props) {
    return (
        <div className="control">
            <label className="control__label">{props.label}</label>
            {props.isRequired ? <span className="required-star">*</span> : null}

            <DatePicker
                className={"control__input"}
                placeholderText={"dd/MM/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                selected={props.value}
                selectsStart={props.selectsStart}
                selectsEnd={props.selectsEnd}
                startDate={props.start}
                endDate={props.end}
                onChange={(date) => {
                    if (date === null) return
                    props.onChange(date)
                }}
            />


        </div>
    )
}