import React, {useState} from "react";
import PhoneInput, {CountryData} from 'react-phone-input-2'
import {Phone} from "../../models/other/phone";

interface Props {
    label: string
    value: Phone[]
    onChange: (phones: Phone[]) => void
    placeholder?: string
}

export function MultiInputPhone(props: Props) {
    const [phone, setPhone] = useState<Phone>({number: "", countryCode: ""})
    return (
        <div className="control">
            <label className="control__label">{props.label}</label>

            <PhoneInput inputClass={"control__input hello"}
                        value={phone.number}
                        onChange={(number, code: CountryData, e, formattedValue) => {
                            if (code) {
                                setPhone({
                                    number: formattedValue,
                                    countryCode: code?.countryCode
                                })
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (phone.countryCode) {
                                    props.onChange([...props.value, phone])
                                    setPhone({number: "", countryCode: ""})
                                }
                            }
                        }}

                        specialLabel={""}
                        prefix={"+"}
                        areaCodes={{kz: ["7"]}}
                        placeholder={props.placeholder || ""}
            />
            <div>
                {props.value.map((phone, index) => {
                    return (
                        <span className={"tags__item"} style={{marginTop: "2px"}}
                        >{phone.number} <span style={{cursor: "pointer"}}
                                              onClick={() => {
                                                  props.onChange(props.value.filter((_, i) => i !== index))
                                              }}
                        >x</span></span>
                    )
                })}
            </div>
        </div>
    )
}