import {SlideForm} from "../../components/form/slideForm";
import {useGlobalContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {RouteNames} from "../../migration/pages";

export function SliderCreate() {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.slide_creating()
    }, [i18n])
    return (
        <section className="app__main">
            <main role="main" className="app__content">
                <div id="toasts"></div>

                <Breadcrumbs infos={[
                    {title: i18n.slider(), link: RouteNames.SLIDER, index: "brcr-0"},
                    {title: i18n.add_slide(), link: RouteNames.SLIDER_CREATE, index: "brcr-1"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.add_slide()}
                        </h1>
                    </div>
                </div>

                <SlideForm isEdit={false}/>


            </main>
        </section>
    )
}
