import {ItemForm} from "../../components/form/itemForm";
import {useGlobalContext} from "../../hooks/globalContext";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ItemMediaForm} from "../../components/form/itemMediaForm";
import {RouteNames} from "../../migration/pages";

export function ItemEdit() {
    const {i18n} = useGlobalContext()
    const {id} = useParams()
    useEffect(() => {
        document.title = i18n.section_editing()
    }, [i18n])
    return (
        <section className={"app__main"}>
            <main role="main" className="app__content">

                <ul className="breadcrumbs">

                    <li className="breadcrumbs__item">
                        <Link to={RouteNames.MENUS} className="breadcrumbs__link">
                            {i18n.menus()}
                        </Link>
                    </li>

                    <li className="breadcrumbs__item">
                        <Link to={RouteNames.MENUS_ITEM_EDIT.replace(":id", id || "")}
                              className="breadcrumbs__link">
                            {i18n.edit_item()}
                        </Link>
                    </li>

                </ul>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.pl_item()}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            <ItemTab
                                item={<ItemForm isEdit={true}/>}
                                media={<ItemMediaForm/>}
                            />

                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}

interface IItemTabProps {
    item: React.ReactNode
    media: React.ReactNode
}

function ItemTab(props: IItemTabProps) {
    const [tab, setTab] = useState<"item" | "media">("item")
    const {i18n} = useGlobalContext()
    return (
        <>
            <ul className="nav default-tabs mb-2 mb-lg-3">
                <li className="default-tabs__item">
                    <a href={"#"}
                       className={tab === "item" ? "default-tabs__link active" : "default-tabs__link "}
                       onClick={(e) => {
                           e.preventDefault()
                           setTab("item")
                       }}
                    >
                        {i18n.item_editing()}
                    </a>
                </li>
                <li className="default-tabs__item">
                    <a href={"#"}
                       className={tab === "media" ? "default-tabs__link active" : "default-tabs__link "}
                       onClick={(e) => {
                           e.preventDefault()
                           setTab("media")
                       }}
                    >
                        {i18n.media()}
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                <div className={tab === "item" ? "tab-pane active" : "tab-pane"}>
                    {props.item}
                </div>
                <div className={tab === "media" ? "tab-pane active" : "tab-pane"}>
                    {props.media}
                </div>
            </div>
        </>
    )
}
