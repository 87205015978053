import {SettingsTab} from "../../components/tabs/settingsTab";
import React, {useEffect, useState} from 'react';
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {OrderModule} from "../../components/form/orderModule";
import {PaymentModule} from "../../components/form/paymentModule";
import {SettingsSupItems} from "../../components/form/supItemModule";
import {RouteNames} from "../../migration/pages";


export function SettingsClientOrders() {

    const {i18n, userInfoLoading} = useGlobalContext()
    const {user} = useUserContext()
    useEffect(() => {
        document.title = i18n.client_order_settings()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.settings(), link: RouteNames.SETTINGS, index: "brcr-0"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.settings()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">

                        <SettingsTab/>

                        <SettingsTabOrder/>
                    </div>
                </div>

            </main>
        </section>
    )
}

function SettingsTabOrder() {
    const {i18n} = useGlobalContext()
    const {user} = useUserContext()

    const [tab, setTab] = useState<"order" | "payment"|"subItem">("order")

    let includeOneVision = user?.org?.availableModules?.includes("payments")

    return (
        <div className="tab-content">
            <div className="default-card">
                <ul className="nav default-tabs mb-2 mb-lg-3">
                    <li className="default-tabs__item">
                        <a href={"#"}
                           className={tab === "order" ? "default-tabs__link active" : "default-tabs__link "}
                           onClick={(e) => {
                               e.preventDefault()
                               setTab("order")
                           }}
                        >
                            {i18n.client_orders()}
                        </a>
                    </li>
                    {includeOneVision &&
                        <li className="default-tabs__item">
                            <a href={"#"}
                               className={tab === "payment" ? "default-tabs__link active" : "default-tabs__link "}
                               onClick={(e) => {
                                   e.preventDefault()
                                   setTab("payment")
                               }}
                            >
                                {i18n.payment()}
                            </a>
                        </li>
                    }

                    <li className="default-tabs__item">
                        <a href={"#"}
                           className={tab === "subItem" ? "default-tabs__link active" : "default-tabs__link "}
                           onClick={(e) => {
                               e.preventDefault()
                               setTab("subItem")
                           }}
                        >
                            {i18n.sup_items()}
                        </a>
                    </li>

                </ul>
                {tab === "order" && <OrderModule/>}
                {tab === "payment" && <PaymentModule/>}
                {tab === "subItem" && <SettingsSupItems/>}
            </div>
        </div>

    )
}

