import {Store} from "react-notifications-component";
import {DeleteButton} from "../../components/input/deleteButton";
import React from "react";
import {TXT} from "../../txtMap/embedded/txt";

interface notificationProps {
    title?: string;
    message: string | React.ReactNode | React.FunctionComponent;
    type: "success" | "danger" | "info" | "default" | "warning";
    durationIsOff?: boolean;
}

export function addNotification(props: notificationProps) {
    Store.addNotification({
        title: props.title || "",
        message: props.message,
        type: props.type,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: !props.durationIsOff ? {
            duration: 5000,
            onScreen: true
        } : undefined
    });
}

interface INotificationWithButtonProps {
    title?: string;
    message: string | React.ReactNode;
    cancel?: string;
    yes?: string;
    onSubmit: () => void;
    loading: boolean;
    i18n: TXT;
}

export function addNotificationWithButton(props: INotificationWithButtonProps) {
    const mess = () => {
        return (
            <>
                <p>{props.message}</p>
                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <DeleteButton onSubmit={props.onSubmit} label={props.yes ? props.yes : props.i18n.yes()}
                                      modal={true} submitLoading={props.loading}/>
                        <button style={{color: "white"}}
                                className="button -gray  modal__button">
                            {props.cancel ? props.cancel : props.i18n.cancel()}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    Store.addNotification({
        title: props.title,
        message: mess(),
        type: "default",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
    })
}