import {AppDispatch, RootState} from "migration/app/store";
import UserService from "migration/app/api/userService";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {ICurrentMenuInfo} from "../../../../../models/menu/menu";
import {
    SetAuthAction,
    SetCurrentMenuIdAction,
    SetIikoActiveGeneralAction,
    SetIikoActiveMenuAction,
    SetIsLoadingUserAction,
    SetMenuAction,
    SetIsLoadingMenuAction,
    SetCurrentUserAction,
    UserActionEnum,
} from "./types";
import {ICurrentUserInfo} from "../../../../../models/user/userLogin";

export const UserActionCreators = {
    setAuth: (payload: boolean): SetAuthAction => ({type: UserActionEnum.SET_AUTH, payload}),
    setCurrentUser: (payload: ICurrentUserInfo | null): SetCurrentUserAction => ({
        type: UserActionEnum.SET_CURRENT_USER,
        payload
    }),
    setIsLoadingUser: (payload: boolean): SetIsLoadingUserAction => ({
        type: UserActionEnum.SET_IS_LOADING_USER,
        payload
    }),

    setMenu: (payload: ICurrentMenuInfo): SetMenuAction => ({type: UserActionEnum.SET_MENU, payload}),
    setCurrentMenuId: (payload: string): SetCurrentMenuIdAction => ({
        type: UserActionEnum.SET_CURRENT_MENU_ID,
        payload
    }),
    setIsLoadingMenu: (payload: boolean): SetIsLoadingMenuAction => ({
        type: UserActionEnum.SET_IS_LOADING_MENU,
        payload
    }),

    setIikoActiveGeneral: (payload: boolean): SetIikoActiveGeneralAction => ({
        type: UserActionEnum.SET_IIKO_ACTIVE_GENERAL,
        payload
    }),
    setIikoActiveMenu: (payload: boolean): SetIikoActiveMenuAction => ({
        type: UserActionEnum.SET_IIKO_ACTIVE_MENU,
        payload
    }),

    fetchCurrentUser: (navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(UserActionCreators.setIsLoadingUser(true));
            const res = await UserService.fetchCurrentUser();
            if (res.data.success) {
                dispatch(UserActionCreators.setCurrentUser({
                    ...res.data.data,
                    isStaff: !res.data.data?.org
                }));
                dispatch(UserActionCreators.setAuth(true));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: true,
            });
        } finally {
            dispatch(UserActionCreators.setIsLoadingUser(false));
        }
    }
}
