import React, {useEffect} from "react";
import {Pagination} from "../../components/pagination/pagination";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useOrders} from "../../hooks/order/order";
import {OrderPageTabs} from "../../components/tabs/orderPageTabs";
import {SearchHeader} from "../../components/input/search";
import {OrderItem} from "../../components/table/orderItemRow";
import {OrderModal} from "../../components/modal/modalOrder";
import {IikoOrderItem} from "../../components/table/iikoOrderItemRow";
import {IikoOrderModal} from "../../components/modal/modalIikoOrder";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {RouteNames} from "../../migration/pages";

export function Orders() {
    const info = useOrders()
    const {i18n, userInfoLoading} = useGlobalContext()
    const {user} = useUserContext()
    useEffect(() => {
        document.title = i18n.client_orders()
    }, [i18n])
    let colums = 7
    if (user?.org?.availableModules?.includes("payments")) colums++
    if (user?.org?.availableModules?.includes("iiko")) colums++
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.client_orders(), link: RouteNames.ORDERS, index: "bracer-0"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.client_orders()}</h1>
                    </div>
                </div>

                <OrderPageTabs/>


                {info.orders?.length === 0 && !userInfoLoading && !info.ordersFound ?
                    <div className="alert alert-success py-4">
                        {i18n.help_empty_orders()}
                    </div>
                    :
                    <div className="default-card">
                        <div className="default-card__top-row">
                            <SearchHeader onSubmit={info.handleFind}
                                          label={i18n.search()}/>
                            <div className="results-text d-none d-lg-block d-sm-block">
                                {i18n.all()}:
                                <span className="search-count">{info.totalCount}</span>
                            </div>
                        </div>

                        <div className="default-table">
                            <table className="orders-table"
                                   style={{display: info.orders?.length === 0 ? "none" : "table"}}>
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>{i18n.customer()}</th>
                                    <th>{i18n.order_content()}</th>
                                    <th>{i18n.delivery_type()}</th>
                                    <th>{i18n.url_parameters()}</th>
                                    <th>{i18n.date()}</th>
                                    {user?.org?.availableModules?.includes("payments") ?
                                        <th style={{width: "12%"}}>{i18n.status()}</th> : null}
                                    {user?.org?.availableModules?.includes("iiko") ?
                                        <th>{i18n.iiko_creation_status()}</th> : null}
                                    <th>{i18n.actions()}</th>
                                </tr>
                                </thead>
                                <TBodyWithLoader rows={7} columns={colums} isLoading={info.ordersLoading}
                                    emptyMessage={i18n.help_empty_orders()} isEmpty={info.orders?.length === 0}
                                >

                                {
                                    info.orders?.map((order) => {
                                        return (
                                            <>
                                                {order.iiko ?
                                                    <IikoOrderItem key={"iiko" + order.id} order={order}
                                                                   iikoOrderTask={order.iikoOrderTask}
                                                                   setIikoOrderModalInfo={info.setIikoOrderModalInfo}
                                                                   iikoDetailedStatus={order.iikoDetailedStatus}
                                                                   createdAt={order.createdAt}
                                                                   iikoStatus={order.iikoStatus}
                                                                   trigger={info.trigger}
                                                                   setTrigger={info.setTrigger}
                                                    />
                                                    :
                                                    <OrderItem key={order.id} order={order}
                                                               setView={info.setOrderModalInfo}
                                                               setOrders={info.setOrders}
                                                    />
                                                }
                                            </>
                                        )
                                    })
                                }

                                </TBodyWithLoader>
                            </table>
                        </div>

                        <Pagination page={info.page} withdraw={info.pageSize} all={info.totalCount}
                                    setPage={info.setPage}/>

                    </div>

                }

                <OrderModal orderModalInfo={info.orderModalInfo} setOrderModalInfo={info.setOrderModalInfo}
                            key={"order"}/>
                <IikoOrderModal iikoOrderModalInfo={info.iikoOrderModalInfo}
                                setIikoOrderModalInfo={info.setIikoOrderModalInfo} key={"iiko"}
                />
            </main>
        </section>
    )
}
