import {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {ICustomPage, ICustomPages} from "../../models/customPage/customPage";

export function useCustomPages() {
    const {menu, currentMenuId, user} = useUserContext()
    const {api} = useGlobalContext()

    const [modalCustomPage, setModalCustomPage] = useState<ICustomPage | null>(null)

    const [customPages, setCustomPages] = useState<ICustomPages>({
        menu: {
            menuId: "",
            menuCustomPageIds: []
        },
        all: []
    });

    const [customPagesLoading, setCustomPagesLoading] = useState<boolean>(false)
    const [createUpdateCustomPageLoading, setCreateUpdateCustomPageLoading] = useState<boolean>(false)
    const [saveCustomPagesLoading, setSaveCustomPagesLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!currentMenuId) return
        if (!user?.org) return
        const controller = new AbortController()
        api.getCustomPages({controller, setLoading: setCustomPagesLoading}, currentMenuId, user.org.id).then((res) => {
            if (res.data) {
                let cp: ICustomPages = {
                    menu: {
                        menuId: res.data.menu.menuId,
                        menuCustomPageIds: []
                    },
                    all: res.data.all || [],
                }
                res.data.menu.customPageIds.forEach((id: string) => {
                    if (res.data && res.data.all && cp.menu.menuCustomPageIds) {
                        let customPage = res.data.all.find((p) => p.id === id)
                        if (customPage) {
                            cp.menu.menuCustomPageIds.push(customPage)
                            cp.all = cp.all?.filter((v) => !res.data?.menu.customPageIds.includes(v.id)) || []
                        }
                    }
                })
                setCustomPages(cp)
            }
        })
        return () => controller.abort()
    }, [currentMenuId])

    function handleCreateModal() {
        setModalCustomPage({
            title: {},
            text: {},
            images: [],
            id: "",
            url: "",
            position: 0
        })
    }

    function handleUpdateModal(cp: ICustomPage) {
        setModalCustomPage(cp)
    }

    function handleCreate() {
        if (user?.org && modalCustomPage) {
            let customPage = modalCustomPage
            customPage.images = (customPage.images || []).filter((v) => {
                let find = false
                for (let textKey in customPage.text) {
                    find = find || customPage.text[textKey].includes(v)
                }
                return find
            })
            api.createCustomPage({setLoading: setCreateUpdateCustomPageLoading}, {
                orgId: user?.org?.id,
                url: customPage.url,
                images: customPage.images,
                title: customPage.title,
                text: customPage.text,
                position: customPage.position,
            }).then((res) => {
                if (res.data) {
                    customPage.id = res.data.id
                    setCustomPages((prevState) => {
                        return {
                            ...prevState,
                            all: [...(prevState.all || []), customPage]
                        }
                    })
                    setModalCustomPage(null)
                }
            })
        }
    }

    function handleUpdate() {
        if (user?.org && modalCustomPage) {
            let customPage = modalCustomPage
            customPage.images = (customPage.images || []).filter((v) => {
                let find = false
                for (let textKey in customPage.text) {
                    find = find || customPage.text[textKey].includes(v)
                }
                return find
            })
            api.updateCustomPage({setLoading: setCreateUpdateCustomPageLoading}, {
                Id: customPage.id,
                orgId: user?.org?.id,
                url: customPage.url,
                images: customPage.images,
                title: customPage.title,
                text: customPage.text,
                position: customPage.position,
            }).then((res) => {
                if (res.success) {
                    setCustomPages((prevState) => {
                        if (prevState.all) {
                            prevState.all = prevState.all.map((v) => {
                                if (v.id === customPage?.id) {
                                    return customPage
                                }
                                return v
                            })
                        }
                        if (prevState.menu.menuCustomPageIds) {
                            prevState.menu.menuCustomPageIds = prevState.menu.menuCustomPageIds.map((v) => {
                                if (v.id === customPage?.id) {
                                    return customPage
                                }
                                return v
                            })
                        }
                        return {
                            ...prevState
                        }
                    })
                    setModalCustomPage(null)
                }
            })
        }
    }

    function handleSaveSort() {
        api.sortMenuCustomPages({setLoading: setSaveCustomPagesLoading}, customPages.menu.menuId, (customPages.menu.menuCustomPageIds || []).map((p) => p.id))
    }


    return {
        modalCustomPage,
        customPages,
        menu,
        currentMenuId,
        handleUpdateModal,
        setModalCustomPage,
        handleSaveSort,
        handleCreate,
        handleUpdate,
        handleCreateModal,
        setCustomPages,
        createUpdateCustomPageLoading,
        saveCustomPagesLoading,
        customPagesLoading,
    }
}