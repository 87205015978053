import {AxiosResponse} from "axios";
import {FeedbackPageShortData} from "migration/entities/Feedback";
import {FeedbackPageId, FeedbackPageName, FeedbackPageSlug, OrgId} from "migration/entities/baseAliases";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface cmdCreateFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    name: FeedbackPageName;
    slug: FeedbackPageSlug;
    isPreFilled: boolean;
}

export interface cmdDeleteFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
}

export default class FeedbackListService {
    // ==================================================== QUERIES ====================================================
    static async getAllFeedbackPages(orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<{pages: FeedbackPageShortData[]}> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<{pages: FeedbackPageShortData[]}> | ErrorResponse>(ApiRoutes.ALL_FEEDBACK_PAGES, {}, {signal: controller.signal});
    }

    // ==================================================== COMMANDS ===================================================
    static async cmdCreateFeedbackPage(request: cmdCreateFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.CREATE_FEEDBACK_PAGE, request);
    }

    static async cmdDeleteFeedbackPage(request: cmdDeleteFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.DELETE_FEEDBACK_PAGE, request);
    }
}
