import {Link} from "react-router-dom";
import {ISlide} from "../../models/slider/slider";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useSliderIndex} from "../../hooks/slider/sliderIndex";
import {Image} from "../../components/image/image";
import {useGlobalContext} from "../../hooks/globalContext";
import {addNotificationWithButton} from "../../utils/toast/toast";
import {LittleSpinner} from "../../components/loader/littleSpinner";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {RouteNames} from "../../migration/pages";

export function SliderIndex() {
    const info = useSliderIndex()
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.slides_page()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs infos={[
                    {title: i18n.slider(), link: RouteNames.SLIDER, index: "brcr-0"},
                ]}/>
                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.slider()}</h1>
                    </div>
                    <div className="app__header-right">
                        {(info.slides || []).length > 1 &&
                            <Link to={RouteNames.SLIDER_SORTING} className="button -blue">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink={"http://www.w3.org/1999/xlink"} x="0px" y="0px" width="15" height="15"
                                     viewBox="0 0 384 384" xmlSpace={"preserve"} enableBackground={"new 0 0 384 384"}>
                                    <polygon
                                        points="128,0 42.667,85.12 106.667,85.12 106.667,234.667 149.333,234.667 149.333,85.12 213.333,85.12 			"
                                        fill="white"></polygon>
                                    <polygon
                                        points="277.333,298.88 277.333,149.333 234.667,149.333 234.667,298.88 170.667,298.88 256,384 341.333,298.88 			"
                                        fill="white"></polygon>
                                </svg>
                                {i18n.sort()}
                            </Link>
                        }

                        <Link to={RouteNames.SLIDER_CREATE} km-partial="true" className="button -blue">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z"
                                    fill="white"></path>
                            </svg>
                            {i18n.add_slide()}
                        </Link>
                    </div>
                </div>

                <div className="default-card">
                    <div id="slider-table" className="default-table">
                        <table>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{i18n.cover()}</th>
                                <th>{i18n.link()}</th>
                                <th>{i18n.actions()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader rows={5} columns={4} isLoading={info.sliderLoading}
                                             isEmpty={(info.slides || []).length === 0}
                                             emptyMessage={i18n.help_empty_slider()}>
                                {info.slides?.map((slide, index) =>
                                    <SliderElement slide={slide} ind={index}
                                                   id={slide.id}
                                                   deleteSlide={info.deleteSlide}/>)}

                            </TBodyWithLoader>
                        </table>
                    </div>

                </div>

            </main>
        </section>
    )
}

function SliderElement({
                           slide, id, ind, deleteSlide
                       }: {
    slide: ISlide,
    id: string,
    ind: number,
    deleteSlide: (id: string, f: (loading: boolean) => void) => void
}) {
    const {i18n} = useGlobalContext()
    const [deleteLoading, setDeleteLoading] = useState(false)
    return (
        <tr id={id}>
            <td data-title="№">{ind + 1}</td>
            <td data-title="Обложка">
                <Link to="#">
                    <Image className={'default-table__img'} alt={'slide image'} src={slide.img} type={"menuSlides"}/>
                </Link>
            </td>
            <td data-title="Ссылка">
                <a href={slide.url ? slide.url : "#"}>{slide.url ? slide.url : ""}</a>
            </td>
            <td>
                <div className="default-table__actions">
                    <Link to={RouteNames.SLIDER_EDIT.replace(":id", id)}
                          className="default-table__button">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.6388 1.77197C8.6388 2.08836 8.38237 2.34478 8.06598 2.34478H2.97999C2.03126 2.3459 1.26243 3.11451 1.26154 4.06324V20.1359C1.26243 21.0846 2.03126 21.8532 2.97999 21.8544H17.907C18.8555 21.8532 19.6244 21.0846 19.6255 20.1359V15.0499C19.6255 14.7335 19.8819 14.4771 20.1983 14.4771C20.5145 14.4771 20.7711 14.7335 20.7711 15.0499V20.1359C20.7693 21.717 19.4881 22.9982 17.907 23H2.97976C1.3987 22.9982 0.117466 21.717 0.115677 20.1359V4.06324C0.117466 2.48217 1.3987 1.20094 2.97976 1.19915H8.06598C8.38237 1.19915 8.6388 1.45557 8.6388 1.77197Z"
                                fill="#8392A5"></path>
                            <path
                                d="M22.245 1.42607C23.2517 2.43275 23.2517 4.06483 22.245 5.07151L12.0256 15.2909C11.9555 15.361 11.8687 15.4115 11.7734 15.4379L6.92167 16.7818C6.72275 16.8371 6.50974 16.7809 6.36362 16.635C6.21774 16.4889 6.16157 16.2759 6.21662 16.077L7.56072 11.2253C7.58713 11.13 7.6377 11.0431 7.70773 10.9731L17.9274 0.753901C18.9347 -0.251217 20.5655 -0.251217 21.5728 0.753901L22.245 1.42607ZM11.6532 14.0428L20.0173 5.67901L17.3199 2.98162L8.95585 11.3457L11.6532 14.0428ZM10.572 14.5816L8.41682 12.4266L7.59093 15.4075L10.572 14.5816ZM18.7374 1.5639L18.1299 2.1714L20.8275 4.86901L21.435 4.26129C21.9942 3.70212 21.9942 2.79546 21.435 2.23629L20.7628 1.5639C20.203 1.00563 19.297 1.00563 18.7374 1.5639Z"
                                fill="#8392A5"></path>
                        </svg>
                    </Link>
                    <button type="button" disabled={deleteLoading} className="default-table__button" onClick={() => {
                        addNotificationWithButton({
                            message: i18n.q_delete_slide(),
                            onSubmit: () => {
                                deleteSlide(id, setDeleteLoading)
                            },
                            loading: deleteLoading,
                            i18n,
                        })
                    }}>
                        {deleteLoading ? <LittleSpinner/> :
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                    fill="#8392A5"></path>
                                <path
                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                    fill="#8392A5"></path>
                            </svg>
                        }
                    </button>
                </div>

            </td>
        </tr>
    )
}
