import {I18nString} from "../../models/lang/i18nString";
import {LangKey} from "../../hooks/globalContext";

function translate(sourceText: string, from: Locale, to: Locale, callback: (result: string) => void) {
    let url = "https://translate.googleapis.com/translate_a/single?client=gtx&sl=" + from + "&tl=" + to + "&dt=t&q=" + encodeURI(sourceText);

    fetch(url).then(async response => {
        let result = await response.json();
        let resultText = "";
        for (let i = 0; i < result[0].length; i++) {
            resultText += result[0][i][0];
        }

        callback(resultText? resultText : sourceText);
    }).catch(error => {
        console.log(error)
        callback(sourceText)
    });
}

export function I18nTranslate(sourceText: I18nString, from: LangKey|string, to:LangKey|string[], callback: (result: I18nString) => void) {
    for (const lang of to) {
        if (!sourceText) {
            return;
        }
        if (sourceText[lang]) {
              continue;
        }
        if (from !== lang) {
            translate(sourceText[from], normalizeLocale(from), normalizeLocale(lang), (result: string) => {
                sourceText[lang] = result;
                callback({...sourceText});
            })
        }
    }
}

function normalizeLocale(key: LangKey|string): Locale {
    switch (key) {
        case 'RU' :
            return 'ru';
        case 'KZ' :
            return 'kk';
        case 'EN' :
            return 'en';
        case "TR" :
            return 'tr';
        case "AZ" :
            return 'az';
        case "CH" :
            return 'zh-CN';
        case "JP" :
            return 'ja';
        case "AR" :
            return 'ar';
        case "UZ" :
            return 'uz';
        case "ID" :
            return 'id';
        case "KY" :
            return 'ky';
        case "UK" :
            return 'uk';
        default:
            return 'auto'
    }
}


export type Locale =
    | 'auto'
    | 'pt-BR'
    | 'af'
    | 'sq'
    | 'am'
    | 'ar'
    | 'hy'
    | 'az'
    | 'eu'
    | 'be'
    | 'bn'
    | 'bs'
    | 'bg'
    | 'ca'
    | 'ceb'
    | 'ny'
    | 'zh-CN'
    | 'zh-TW'
    | 'co'
    | 'hr'
    | 'cs'
    | 'da'
    | 'nl'
    | 'en'
    | 'eo'
    | 'et'
    | 'tl'
    | 'fi'
    | 'fr'
    | 'fy'
    | 'gl'
    | 'ka'
    | 'de'
    | 'el'
    | 'gu'
    | 'ht'
    | 'ha'
    | 'haw'
    | 'iw'
    | 'hi'
    | 'hmn'
    | 'hu'
    | 'is'
    | 'ig'
    | 'id'
    | 'ga'
    | 'it'
    | 'ja'
    | 'jw'
    | 'kn'
    | 'kk'
    | 'km'
    | 'rw'
    | 'ko'
    | 'ku'
    | 'ky'
    | 'lo'
    | 'la'
    | 'lv'
    | 'lt'
    | 'lb'
    | 'mk'
    | 'mg'
    | 'ms'
    | 'ml'
    | 'mt'
    | 'mi'
    | 'mr'
    | 'mn'
    | 'my'
    | 'ne'
    | 'no'
    | 'or'
    | 'ps'
    | 'fa'
    | 'pl'
    | 'pt'
    | 'pa'
    | 'ro'
    | 'ru'
    | 'sm'
    | 'gd'
    | 'sr'
    | 'st'
    | 'sn'
    | 'sd'
    | 'si'
    | 'sk'
    | 'sl'
    | 'so'
    | 'es'
    | 'su'
    | 'sw'
    | 'sv'
    | 'tg'
    | 'ta'
    | 'tt'
    | 'te'
    | 'th'
    | 'tr'
    | 'tk'
    | 'uk'
    | 'ur'
    | 'ug'
    | 'uz'
    | 'vi'
    | 'cy'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'zu';
