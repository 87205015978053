import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Select} from "antd";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {
    DrinkMaterialEnum, drinkMaterialTaggingOptions,
    FoodChannelEnum,
    orgTaggingOptions,
    OtherDrinkEnum,
    otherDrinkTaggingOptions
} from "../store/types";
import classes from "./TaggingSettings.module.scss";

interface TaggingSettingsProps {
    type: "org" | "product";
    orgId: string;
    productId?: string;
}

export const TaggingSettings: FunctionComponent<TaggingSettingsProps> = ({type, orgId, productId}) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser} = useTypedSelector(state => state.user);
    const {foodChannel, otherDrink, drinkMaterial} = useTypedSelector(state => state.organizations);
    const {tenantTagById, productTagById, setTagsForTenant, setTagsForProduct} = useActions();

    const [selectedFoodChannel, setSelectedFoodChannel] = useState<FoodChannelEnum | null>(null);
    const [selectedOtherDrink, setSelectedOtherDrink] = useState<OtherDrinkEnum | null>(null);
    const [selectedDrinkMaterial, setSelectedDrinkMaterial] = useState<DrinkMaterialEnum | null>(null);

    const selectEl = () => {
        switch (type) {
            case "org":
                return (
                    <label className={classes.tagging__service__label}>
                        {txt.select_enterprise_type[currentLang]}
                        <Select
                            value={selectedFoodChannel}
                            onChange={(value) => setSelectedFoodChannel(value)}
                            options={orgTaggingOptions}
                            style={{width: "100%"}}
                        />
                    </label>
                );
            case "product":
                return (
                    <React.Fragment>
                        <label className={classes.tagging__service__label}>
                            {txt.select_drink_type[currentLang]}
                            <Select
                                value={selectedOtherDrink}
                                onChange={(value) => setSelectedOtherDrink(value)}
                                options={otherDrinkTaggingOptions}
                                style={{width: "100%"}}
                            />
                        </label>

                        <label className={classes.tagging__service__label}>
                            {txt.select_drink_material[currentLang]}
                            <Select
                                value={selectedDrinkMaterial}
                                onChange={(value) => setSelectedDrinkMaterial(value)}
                                options={drinkMaterialTaggingOptions}
                                style={{width: "100%"}}
                            />
                        </label>
                    </React.Fragment>
                );
        }
    }

    const disabled = () => {
        switch (type) {
            case "org":
                return foodChannel === selectedFoodChannel;
            case "product":
                return otherDrink === selectedOtherDrink && drinkMaterial === selectedDrinkMaterial;
        }
    }

    const handleSubmitOrg = () => {
        const tags = [];
        if (selectedFoodChannel) {
            tags.push(selectedFoodChannel);
        }
        setTagsForTenant({tags, tenantId: orgId || ""}, {navigate});
    }

    const handleSubmitProduct = () => {
        const tags = [];
        if (selectedOtherDrink) {
            tags.push(selectedOtherDrink);
        }
        if (selectedDrinkMaterial) {
            tags.push(selectedDrinkMaterial);
        }
        setTagsForProduct({tags, productId: productId || ""}, {navigate});
    }

    const handleSubmit = () => {
        switch (type) {
            case "org":
                handleSubmitOrg();
                break;
            case "product":
                handleSubmitProduct();
                break;
        }
    }

    useEffect(() => {
        if (!orgId) return;
        const controller = new AbortController();
        tenantTagById(orgId, controller, {navigate});
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    useEffect(() => {
        if (!productId) return;
        const controller = new AbortController();
        productTagById(currentUser?.org?.id || "", productId, controller, {navigate});
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    useEffect(() => {
        setSelectedFoodChannel(foodChannel);
    }, [foodChannel]);

    useEffect(() => {
        setSelectedOtherDrink(otherDrink);
    }, [otherDrink]);

    useEffect(() => {
        setSelectedDrinkMaterial(drinkMaterial);
    }, [drinkMaterial]);

    if (!currentUser?.isStaff) {
        return null;
    }

    return (
        <div className={classes.tagging__service}>
            {selectEl()}
            <Button onClick={handleSubmit} disabled={disabled()} type={"primary"} style={{width: "fit-content"}}>
                {txt.save[currentLang]}
            </Button>
        </div>
    );
};
