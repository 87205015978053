import React from "react";

interface Props {
    label: string
    value: string
    onChange: (value: string) => void
    textArea?: boolean
    placeholder?: string
    isRequired?: boolean
    isValidate: boolean
}

export function InputText(props: Props) {
    return (
        <div className="control">
            <label className="control__label">{props.label}</label>
            {props.isRequired ? <span className="required-star">*</span> : null}
            {props.textArea ?
                <textarea className="control__textarea "  style={{backgroundColor: props.isValidate ? "" : "#dc3545"}}
                          placeholder={props.placeholder||""}
                          value={props.value}
                          onChange={(e) => props.onChange(e.currentTarget.value)}
                ></textarea>:
            <input className="control__input" name="main_label" style={{backgroundColor: props.isValidate ? "" : "#dc3545"}}
                   value={props.value}
                   onChange={(e) => {
                       props.onChange(e.currentTarget.value)
                   }}
                   type={"text"} placeholder={props.placeholder||""}/>
            }
        </div>
    )
}