import React from "react";
import {TextI18n} from "../input/textI18n";
import {SubmitButton} from "../input/submitButton";
import {Link} from "react-router-dom";
import {useBespoke} from "../../hooks/menuStylize/bespoke";
import {CustomSelect} from "../input/customSelect";
import {showMenuBtnKeys} from "../../models/menuStylize/bespoke";
import {useGlobalContext} from "../../hooks/globalContext";
import {TextAreaI18n} from "../input/textAreaI18n";
import {Checkbox} from "../input/checkbox";
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";

export function BespokeForm() {
    const info = useBespoke();
    const {i18n} = useGlobalContext()
    let options: showMenuBtnKeys[] = ['dish', 'burger']
    return (
        <>
            <FormWithLoader isloading={info.loading} className="form">
                <TextI18n values={info.bespoke.replaceTxts?.showMenuBtn || {}} setValues={info.onChangeShowMenuButton}
                          lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                          label={i18n.text_for_menu_button()} isStaff={info.isStaff}/>
                <TextI18n values={info.bespoke.replaceTxts?.pmOneVisionOption || {}}
                          setValues={info.onChangePmOneVisionOption}
                          lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                          label={i18n.one_vision_button_title()} isStaff={info.isStaff}/>

                <CustomSelect options={options} value={options.indexOf(info.bespoke.customIcons.showMenuBtn)}
                              onChange={(n) => {
                                  let nInt = parseInt(n)
                                  info.onChangeCustomIconsShowMenuBtn(options[nInt])
                              }} label={i18n.icon_for_menu_button()}/>

                <TextAreaI18n values={info.bespoke.inCartMessage || {}} setValues={info.onChangeInCartMessage}
                              lang={info.menu.i18n.defaultLang} allLangs={info.menu.i18n.otherLangs || []}
                              isStaff={info.isStaff} small={true} label={i18n.in_cart_message()}/>

                <Checkbox checked={info.bespoke.isUsingTips} onChange={info.onChangeIsUsingTips}
                          label={i18n.is_using_tips()}/>
                <Checkbox checked={info.bespoke.isHiddenCartTotalPrice} onChange={info.onChangeIsHiddenCartTotalPrice}
                          label={i18n.is_hidden_cart_total_price()}/>


                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={info.onSave} label={i18n.save()} isLoading={info.saveLoading}
                                      disabled={!info.currentMenuId}
                        />
                        <Link to={RouteNames.MENUS}
                              className="button -bordered_blue form__button">
                            {i18n.cancel()}
                        </Link>
                    </div>
                </div>

            </FormWithLoader>
        </>
    )
}
