import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {ISection, ISectionCreate, ISectionUpdate} from "../../models/section/ISection";
import {useNavigate} from "react-router-dom";
import {I18nString} from "../../models/lang/i18nString";
import {RouteNames} from "../../migration/pages";

export function useSectionForm(id: string, isEdit: boolean) {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()
    const navigate = useNavigate()

    const [section, setSection] = useState<ISection>({
        id: "",
        name: {},
        description: {},
        img: null
    })
    const [modal, setModal] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [img, setImg] = useState<string>("")

    useEffect(() => {
        setSection({
            ...section,
            img: img
        })
    }, [img])

    const [sectionLoading, setSectionLoading] = useState<boolean>(false)
    const [saveSectionImgLoading, setSaveSectionImgLoading] = useState<boolean>(false)
    const [saveSectionLoading, setSaveSectionLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (isEdit) {
            api.getSectionById({controller, setLoading: setSectionLoading}, id, currentMenuId).then((resp) => {
                if (resp.data) {
                    setSection(resp.data)
                }
            })
        } else {
            setSection({
                ...section,
            })
        }
        return () => controller.abort()
    }, [currentMenuId])


    //Events
    function onDeleteImg() {
        setImg("")
    }

    function onChangeSectionName(name: I18nString) {
        setSection({
            ...section,
            name: name
        })
    }

    function onChangeSectionDescription(name: I18nString) {
        setSection({
            ...section,
            description: name
        })
    }

    function UploadImg(file: File) {
        api.saveSectionImage({setLoading: setSaveSectionImgLoading}, file).then((resp) => {
            if (resp.data) {
                setSection({...section, img: resp.data?.newFilename})
            }
        })
    }

    function onSave() {
        if (isEdit) {
            let sectionEdit: ISectionUpdate = {
                id: section.id,
                name: section.name,
                desc: section.description,
                img: section.img ? section.img : null,
                menuId: currentMenuId
            }
            api.updateSection({setLoading: setSaveSectionLoading}, sectionEdit).then((resp) => {
                if (resp.success) {
                    navigate(RouteNames.MENUS)
                }
            })
        } else {
            let newSection: ISectionCreate = {
                menuId: currentMenuId,
                img: section.img ? section.img : null,
                name: section.name,
                desc: section.description
            }
            api.createSection({setLoading: setSaveSectionLoading}, newSection).then((resp) => {
                if (resp.data) {
                    navigate(RouteNames.MENUS)
                }
            })
        }
    }

    function onUploadImage(file:File | null) {
        setSelectedImage(file)
        setModal(true)
    }

    return {
        img,
        modal,
        menu,
        isStaff: !!user?.isStaff,
        section,
        selectedImage,
        currentMenuId,
        setImg,
        onSave,
        setModal,
        UploadImg,
        onDeleteImg,
        onChangeSectionName,
        onChangeSectionDescription,
        onUploadImage,
        setSelectedImage,
        saveSectionLoading,
        sectionLoading,
        saveSectionImgLoading
    }
}
