import {useGlobalContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {OrgSettingsTab} from "../../components/tabs/orgSettingsTab";
import React, {useEffect} from "react";
import {DomainSettingsModule} from "../../components/form/domainSettingsModule";

export function DomainSettings() {
    const {i18n, userInfoLoading} = useGlobalContext();
    let infos = [
        {link: "/organization-domain", title: i18n.domain(), index: "breadcrumbs-id"}
    ]
    useEffect(() => {
        document.title = i18n.domain()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={infos}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.domain()}</h1>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <OrgSettingsTab/>
                        <div className="tab-content">
                            <div className="default-card">

                                <div className="alert alert-info py-4">
                                    Для подключения личного домена в DNS настройках домена необходимо добавить <strong>А
                                    запись </strong>
                                    с значением <strong>138.68.113.204</strong>
                                </div>

                                <DomainSettingsModule/>

                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
