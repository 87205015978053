import {BreadcrumbsModel, BreadcrumbInfo} from "../../models/breadcrumbs/breadcrumbs";
import {Link} from "react-router-dom";

export function Breadcrumbs(breadcrumbs: BreadcrumbsModel) {

     return (
         <ul className="breadcrumbs">
             {breadcrumbs.infos.map((info)=> {
                 return (
                     <li className="breadcrumbs__item" key={info.index}>
                         <Link to={info.link} className="breadcrumbs__link"
                               km-partial='true'>
                             {info.title}
                         </Link>
                     </li>
                 )
             })}
         </ul>
     )
}