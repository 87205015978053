import {SettingsTab} from "../../components/tabs/settingsTab";
import Select from "react-select";
import {currencyOptions, options} from "../../utils/options/options";
import React, {useEffect} from "react";
import {useSettingsPriceList} from "../../hooks/settings/priceList";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {SubmitButton} from "../../components/input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {InputText} from "../../components/input/inputText";
import {Link} from "react-router-dom";
import {RouteNames} from "../../migration/pages";

export function SettingsPriceList() {
    const info = useSettingsPriceList()
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.settings()
    }, [i18n])

    //todo add loading
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.settings(), link: RouteNames.SETTINGS, index: "brcr-0"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.settings()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <SettingsTab/>
                        <div className="tab-content">


                            <div className="default-card">
                                <ul className="nav default-tabs mb-2 mb-lg-3">
                                    <li className="default-tabs__item">
                                        <a href={"#"}
                                           className={!info.isNamePage ? "default-tabs__link active" : "default-tabs__link "}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               info.setIsNamePage(false)
                                           }}
                                        >
                                            {i18n.main()}
                                        </a>
                                    </li>
                                    <li className="default-tabs__item">
                                        <a href={"#"}
                                           className={info.isNamePage ? "default-tabs__link active" : "default-tabs__link "}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               info.setIsNamePage(true)
                                           }}
                                        >
                                            {i18n.menu_name()}
                                        </a>
                                    </li>
                                </ul>
                                {info.isNamePage ?
                                    <form className="form">

                                        <input type="hidden" name="pl_id" value="e7ccf8f0-c20b-4c2d-b077-49f8511b411f"/>

                                        <InputText label={i18n.menu_name()} value={info.menuInfo.name}
                                                   onChange={info.onChangeMenuName} isValidate={true}/>
                                        <InputText label={i18n.link()} value={info.menuInfo.link}
                                                   onChange={info.onChangeMenuLink} isValidate={true}/>

                                        <div className="form__buttons mt-4">
                                            <div className="form__buttons-left">
                                                <SubmitButton onSubmit={info.onSubmitMenuInfo} label={i18n.save()}
                                                              modal={true} disabled={!info.currentMenuId}
                                                              isLoading={info.saveMenuInfoLoading}
                                                />
                                                <Link to={RouteNames.SETTINGS} className="button -bordered_blue form__button"
                                                      onClick={info.onCancelMenuInfo}>
                                                    {i18n.cancel()}
                                                </Link>
                                                <Link to={"#"} className="button form__button -bordered_red"
                                                      type="button"
                                                      onClick={(e) => {
                                                          e.preventDefault()
                                                          info.onDeleteMenu()
                                                      }}>
                                                    {i18n.delete()}
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    <form className="form">

                                        <div className="control">
                                            <div className="control__label">{i18n.menu_currency()}</div>
                                            <Select options={currencyOptions}
                                                    onChange={(v) => info.onChangeCurrency(v ? v.value : "")}
                                                    isOptionSelected={(v) => v.value === info.settingsMenuInfo.currency}
                                                    value={
                                                        currencyOptions.filter((v) => v.value === info.settingsMenuInfo.currency)
                                                    }

                                                    placeholder={""}
                                            />
                                        </div>


                                        <div className="control">
                                            <div className="control__label">{i18n.default_menu_lang()}</div>
                                            <Select options={options}
                                                    onChange={(v) => {
                                                        let defLang = v ? v.value : info.menu.i18n.defaultLang
                                                        info.onChangeMenuLang(defLang)
                                                    }}
                                                    value={
                                                        options.filter((v) => v.value === info.settingsMenuInfo.defLang)
                                                    }
                                            />
                                        </div>

                                        <div className="control">
                                            <div className="control__label">{i18n.additional_langs()}</div>
                                            <Select
                                                options={options.filter((v) => v.value !== info.settingsMenuInfo.defLang)}
                                                onChange={(v) => {
                                                    const mlLang = v.map((val) => val)
                                                    info.onChangeAdditionallyLang(mlLang)
                                                }}
                                                isMulti={true}
                                                value={
                                                    options.filter((v) => info.settingsMenuInfo.additionallyLang?.includes(v.value))
                                                }
                                                placeholder={""}
                                            />
                                        </div>
                                        <div style={{display: "none"}}>

                                            <div className="control">
                                                <label
                                                    className="control__label">{i18n.prc_to_total_summ()}</label>
                                                <input className="control__input" name="total_mod_prc_amount" value="0"
                                                       type="number" step="any"/>
                                            </div>


                                            <div className="control">
                                                <label
                                                    className="control__label">{i18n.prc_to_total_summ_label()}</label>
                                                <input className="control__input" name="total_mod_prc_label" value=""
                                                       type="text" placeholder=""/>
                                            </div>

                                        </div>
                                        <div className="form__buttons mt-4">
                                            <div className="form__buttons-left">
                                                <SubmitButton onSubmit={info.onSubmit} label={i18n.save()}
                                                              isLoading={info.saveLoading}
                                                              modal={true} disabled={!info.currentMenuId}/>
                                                <Link to={RouteNames.SETTINGS} className="button -bordered_blue form__button"
                                                      onClick={info.onCancel}>
                                                    {i18n.cancel()}
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </section>
    )
}
