import {FeedbacksListAction, FeedbacksListActionEnum, FeedbacksListState} from "./types";

const initialState: FeedbacksListState = {
    feedbacksList: [],

    isLoadingGetFeedbacksList: false,

    isLoadingCreateFeedbackPage: false,
    isLoadingDeleteFeedbackPage: false,
}

export default function feedbacksListReducer(state = initialState, action: FeedbacksListAction): FeedbacksListState {
    switch (action.type) {
        case FeedbacksListActionEnum.SET_FEEDBACKS_LIST:
            return {...state, feedbacksList: action.payload};
        case FeedbacksListActionEnum.SET_IS_LOADING_GET_FEEDBACKS_LIST:
            return {...state, isLoadingGetFeedbacksList: action.payload};
        case FeedbacksListActionEnum.SET_IS_LOADING_CREATE_FEEDBACK_PAGE:
            return {...state, isLoadingCreateFeedbackPage: action.payload};
        case FeedbacksListActionEnum.SET_IS_LOADING_DELETE_FEEDBACK_PAGE:
            return {...state, isLoadingDeleteFeedbackPage: action.payload};
        default:
            return state;
    }
}
