import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {AppHeader} from "../../components/header/appHeader";
import {ILinkArgs} from "../../models/link/link";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useGlobalContext} from "../../hooks/globalContext";
import {addNotificationWithButton} from "../../utils/toast/toast";
import {Pagination} from "../../components/pagination/pagination";
import {useManagers} from "../../hooks/staff/managers";
import {IStaffUser} from "../../models/user/staff";
import {SearchHeader} from "../../components/input/search";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {LittleSpinner} from "../../components/loader/littleSpinner";
import {CustomSkeletonLoader} from "../../components/loader/customSkeletonLoader";


export function ManagersPage() {
    const info = useManagers()
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.all_staff()
    }, [i18n])
    let headerInfo: ILinkArgs[] = [
        {
            url: "/staff/managers/create",
            title: i18n.create_staff_user(),
            svg: `
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z" fill="white"></path>
                </svg>
            `
        }
    ]
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[{link: "/staff/managers", title: i18n.managers(), index: "breadcrumbs-id"}]}/>

                <AppHeader links={headerInfo} header={i18n.managers()}/>
                <div className="default-card">

                    <div className="default-card__top-row">
                        <SearchHeader onSubmit={info.handleFind}
                                      label={i18n.search()}/>


                        <div className="results-text d-none d-lg-block d-sm-block">
                            {i18n.all()}:
                            <span className="search-count">{info.count}</span>
                        </div>


                    </div>


                    <div className="default-table">
                        <table id="clients-table">
                            <thead>
                            <tr>
                                <th>{i18n.login()}</th>
                                <th>{i18n.tmp_password()}</th>
                                <th>{i18n.actions()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader rows={20} columns={3} isLoading={info.loading}>

                                {info.staffs.map((value, index) =>
                                    <StaffTd staff={value} changePass={(v) => info.resetThePassword(v, index)}
                                             enableDisableOrg={info.enableDisableOrg} key={value.id}
                                    />)}

                            </TBodyWithLoader>
                        </table>
                    </div>

                    <Pagination page={info.page} all={info.count} setPage={info.setPage} withdraw={info.step}/>
                </div>

            </main>
        </section>
    )
}

interface IStaffTd {
    staff: IStaffUser,
    changePass: (pass: string) => void
    enableDisableOrg: (id: string, isDisabled: boolean, setLoading: (loading: boolean) => void) => void
}

function StaffTd(props: IStaffTd) {
    const {i18n, api} = useGlobalContext()
    const [enableDisableLoading, setEnableDisableLoading] = React.useState(false)
    const [resetPassLoading, setResetPassLoading] = React.useState(false)
    return (
        <tr className={props.staff.isDisabled ? "archived-user" : ""} id={props.staff.id}>
            <td>
                {props.staff.username}
            </td>
            <td>
                {resetPassLoading ?
                    <CustomSkeletonLoader/> :
                    <>
                        {props.staff.tmpPswChanged ?
                            <span
                                className="text-success">{i18n.password_changed()}</span> :
                            <span>{props.staff.tmpPassword}</span>}
                    </>
                }
            </td>
            <td>
                {props.staff.isDisabled ?
                    <Link to="#" title={i18n.enable_organization()}
                          className="text-success mr-2" onClick={() => {
                        props.enableDisableOrg(props.staff.id, props.staff.isDisabled, setEnableDisableLoading)
                    }}>
                        {enableDisableLoading ?
                            <LittleSpinner/> :
                            <i className="fa fa-lg fa-check-circle"></i>
                        }
                    </Link>
                    :
                    <Link to="#" title={i18n.disable_organization()}
                          className="text-danger mr-2" onClick={() => {
                        props.enableDisableOrg(props.staff.id, props.staff.isDisabled, setEnableDisableLoading)
                    }}>
                        {enableDisableLoading ?
                            <LittleSpinner/> :
                            <i className="fa fa-lg fa-times-circle"></i>
                        }
                    </Link>
                }


                <Link to="#" title={i18n.change_password()}
                      onClick={(e) => {
                          e.preventDefault()
                          addNotificationWithButton({
                              message: i18n.q_reset_password(),
                              onSubmit: () => {
                                  e.preventDefault()
                                  api.resetPasswordStaffUser({setLoading: setResetPassLoading}, props.staff.id).then((res) => {
                                      if (res.data) {
                                          props.changePass(res.data.newTmpPassword)
                                      }
                                  })
                              },
                              loading: resetPassLoading,
                              i18n,
                          })
                      }}
                      className="mr-2">
                    {resetPassLoading ?
                        <LittleSpinner/> :
                        <i className="fa fa-lg fa-lock-open"></i>
                    }
                </Link>
            </td>
        </tr>
    )
}