import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {IItem, IItemCreate, IItemUpdate, SectionForEditItem} from "../../models/item/IItem";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {I18nString} from "../../models/lang/i18nString";
import {RouteNames} from "../../migration/pages";


export function useItemForm(isEdit: boolean) {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()
    const navigate = useNavigate()
    const [item, setItem] = useState<IItem>({
        id: "",
        name: {},
        mainImg: null,
        isHidden: false,
        isOff: false,
        specs: {
            tags: {},
            shortDesc: {},
            fullDesc: {},
            cookingTimeFrom: 0,
            cookingTimeTo: 0,
            // grams: 0,//todo
            kiloCalories: 0,
            proteins: 0,
            fats: 0,
            carbs: 0,
        },
        prices: {
            primary: {
                label: {},
                price: 0,
                oldPrice: 0,
            },
            others: null,
            modSelects: null,
            modExtras: null
        }
    })

    const [searchParams] = useSearchParams();


    const [menuStr, SetMenuStr] = useState<{ [key: string]: SectionForEditItem }>({})
    const [categoryId, setCategoryId] = useState<string>("")


    const [modal, setModal] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const {id} = useParams()

    const [itemLoading, setItemLoading] = useState<boolean>(true)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [imageUploading, setImageUploading] = useState<boolean>(false)
    const [hideShowLoading, setHideShowLoading] = useState<boolean>(false)
    const [turnOnOffLoading, setTurnOnOffLoading] = useState<boolean>(false)

    function reloadItem(controller: AbortController = new AbortController()) {
        if (!currentMenuId) {
            return
        }
        if (isEdit) {
            api.getItemById({controller, setLoading: setItemLoading}, id as string, currentMenuId).then((res) => {
                if (res.data) {
                    setItem(res.data.item)
                    SetMenuStr(res.data.menuStruct ? res.data.menuStruct : {})
                    setCategoryId(res.data.nowCategoryId)
                }
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            })
        } else {
            api.getMenuStructureForItemCreate({controller, setLoading: setItemLoading}, currentMenuId).then((res) => {
                if (res.data) {
                    let par = searchParams.get("category_id")
                    SetMenuStr(res.data.menuStruct)
                    if (par) {
                        setCategoryId(par)
                    } else {
                        if (Object.keys(res.data.menuStruct).length > 0) {
                            let first = res.data.menuStruct[Object.keys(res.data.menuStruct)[0]].categories?.[0].id
                            if (first) {
                                setCategoryId(first)
                            }
                        }
                    }
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            })
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        reloadItem(controller)
        return () => controller.abort()
    }, [currentMenuId])


//Events
    function onUploadFile(file: File | null) {
        setSelectedImage(file);
        setModal(true)
    }

    function onDeleteImg() {
        setItem({...item, mainImg: null})
    }

    function onChangeItemName(name: I18nString) {
        setItem({...item, name: name})
    }

//short_desc
    function onChangeShortDesc(short_desc: I18nString) {
        setItem({...item, specs: {...item.specs, shortDesc: short_desc}})
    }

//description
    function onChangeDescription(description: I18nString) {
        setItem((prevState) => ({...prevState, specs: {...prevState.specs, fullDesc: description}}))
    }

    //tags
    function onChangeTags(tags: I18nString) {
        setItem({...item, specs: {...item.specs, tags: tags}})
    }

//main_price
    function onChangeMainPriceLabel(price_label: I18nString) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                primary: {...item.prices.primary, label: price_label}
            }
        })
    }

    function onChangeMainPrice(price: number) {
        setItem({...item, prices: {...item.prices, primary: {...item.prices.primary, price: price}}})
    }

//old price
    function onChangeMainOldPrice(price_old_price: number) {
        setItem({
            ...item,
            prices: {...item.prices, primary: {...item.prices.primary, oldPrice: price_old_price}}
        })
    }

//tuple
    function onChangeTuplePriceLabel(index: number, label: I18nString) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                "others": (item.prices.others || []).map((t, i) => i === index ? {
                    ...t,
                    label: label
                } : t)
            }
        })
    }

    function onChangeTuplePrice(index: number, price: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                "others": (item.prices.others || []).map((t, i) => i === index ? {...t, price: price} : t)
            }
        })
    }

    function onChangeTupleOldPrice(index: number, old_price: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                "others": (item.prices.others || []).map((t, i) => i === index ? {
                    ...t,
                    oldPrice: old_price
                } : t)
            }
        })
    }

    function onDeleteTuple(index: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                "others": (item.prices.others || []).filter((t, i) => i !== index)
            }
        })
    }

    function onCreateTuple() {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                "others": [...(item.prices.others || []), {
                    label: {[menu.i18n.defaultLang]: ""},
                    price: 0,
                    oldPrice: 0
                }]
            }
        })
    }

//Modifier selects
    function onChangeHeaderModifierSelects(msIndex: number, header: I18nString) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    header: header
                } : ms)
            }
        })
    }

    function onChangeIsHiddenModifierSelects(msIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    isHidden: !ms.isHidden
                } : ms)
            }
        })
    }

    function onChangeIsRequiredModifierSelects(msIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    isRequired: !ms.isRequired
                } : ms)
            }
        })
    }

    function onDeleteModifierSelects(msIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).filter((ms, i) => i !== msIndex)
            }
        })
    }

    function onChangeLabelOptionModifierSelects(msIndex: number, optIndex: number, label: I18nString) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: ms.options.map((opt, j) => j === optIndex ? {
                        ...opt,
                        label: label
                    } : opt)
                } : ms)
            }
        })
    }

    function onChangePriceDeltaOptionModifierSelects(msIndex: number, optIndex: number, price_delta: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: ms.options.map((opt, j) => j === optIndex ? {
                        ...opt,
                        priceDelta: price_delta
                    } : opt)
                } : ms)
            }
        })
    }

    function onChangeStatusOptionModifierSelects(msIndex: number, optIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: ms.options.map((opt, j) => j === optIndex ? {
                        ...opt,
                        status: "default"
                    } : opt.status == "default" ? {
                        ...opt,
                        status: "normal"
                    } : opt)
                } : ms)
            }
        })
    }

    function onHideStatusOptionModifierSelects(msIndex: number, optIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: ms.options.map((opt, j) => j === optIndex ? {
                        ...opt,
                        status: opt.status == "hidden" ? "normal" : "hidden"
                    } : opt)
                } : ms)
            }
        })
    }

    function onDeleteOptionModifierSelects(msIndex: number, optIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: ms.options.filter((opt, j) => j !== optIndex)
                } : ms)
            }
        })
    }


    function onCreateOptionModifierSelects(msIndex: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: (item.prices.modSelects || []).map((ms, i) => i === msIndex ? {
                    ...ms,
                    options: [...ms.options, {
                        label: {[menu.i18n.defaultLang]: ""},
                        priceDelta: 0,
                        status: "normal"
                    }]
                } : ms)
            }
        })
    }

    function onCreateModifierSelects() {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modSelects: [...(item.prices.modSelects || []), {
                    header: {[menu.i18n.defaultLang]: ""},
                    isHidden: false,
                    isRequired: false,
                    options: [{
                        label: {},
                        status: "default",
                        priceDelta: 0
                    }]
                }]
            }
        })
    }


//modifier_extras

    function onChangeLabelModifierExtra(index: number, label: I18nString) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modExtras: (item.prices.modExtras || []).map((me, i) => i === index ? {
                    ...me,
                    label: label
                } : me)
            }
        })
    }

    function onChangePriceDeltaModifierExtra(index: number, price_delta: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modExtras: (item.prices.modExtras || []).map((me, i) => i === index ? {
                    ...me,
                    priceDelta: price_delta
                } : me)
            }
        })
    }

    function onHideModifierExtra(index: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modExtras: (item.prices.modExtras || []).map((me, i) => i === index ? {
                    ...me,
                    isHidden: !me.isHidden
                } : me)
            }
        })
    }

    function onDeleteModifierExtra(index: number) {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modExtras: (item.prices.modExtras || []).filter((me, i) => i !== index)
            }
        })
    }

    function onCreateModifierExtra() {
        setItem({
            ...item,
            prices: {
                ...item.prices,
                modExtras: [...(item.prices.modExtras || []),
                    {
                        label: {[menu.i18n.defaultLang]: ""},
                        priceDelta: 0,
                        isHidden: false
                    }]
            }
        })
    }

//item info

    function onChangeProductInfo(protein: number, fat: number, carbohydrate: number, calories: number) {
        if (protein < 0) {
            protein = 0
        }
        if (fat < 0) {
            fat = 0
        }
        if (carbohydrate < 0) {
            carbohydrate = 0
        }
        if (calories < 0) {
            calories = 0
        }
        setItem({
            ...item,
            specs: {
                ...item.specs,
                proteins: protein,
                fats: fat,
                carbs: carbohydrate,
                kiloCalories: calories
            }
        })
    }

//cooking time
    function onChangeCookingTime(from: number, to: number) {
        if (from < 0) {
            from = 0
        }
        if (to < 0) {
            to = 0
        }
        setItem({
            ...item,
            specs: {
                ...item.specs,
                cookingTimeTo: to,
                cookingTimeFrom: from
            }
        })
    }

    function onBlurCookingTime(from: number, to: number) {
        if (to < from) {
            to = from
        }
        setItem({
            ...item,
            specs: {
                ...item.specs,
                cookingTimeTo: to,
                cookingTimeFrom: from
            }
        })
    }


    function UploadImage(file: File) {
        api.saveItemImage({setLoading: setImageUploading}, file).then((res) => {
            if (res.data) {
                setItem({...item, mainImg: res.data.newFilename})
            }
        })
    }

    //==================================================================================================================
    //=============================================  Buttons  ==========================================================
    //==================================================================================================================

    async function saveItem() {
        if (isEdit) {
            let updatedItem: IItemUpdate = {
                id: item.id,
                name: item.name,
                mainImg: item.mainImg ? item.mainImg : null,
                specs: item.specs,
                prices: item.prices,
                categoryId: categoryId,
                menuId: currentMenuId,
            }
            api.updateItem({setLoading: setSaveLoading}, updatedItem).then((res) => {
                if (res.data) {
                    // eslint-disable-next-line no-restricted-globals
                    if (history.length > 2) {
                        navigate(-1)
                    } else {
                        navigate(RouteNames.MENUS)
                    }
                }
            })
        } else {
            let newItem: IItemCreate = {
                name: item.name,
                mainImg: item.mainImg ? item.mainImg : null,
                specs: item.specs,
                prices: item.prices,
                categoryId: categoryId,
                menuId: currentMenuId,
            }
            api.createItem({setLoading: setSaveLoading}, newItem).then((res) => {
                if (res.data) {
                    // eslint-disable-next-line no-restricted-globals
                    if (history.length > 2) {
                        navigate(-1)
                    } else {
                        navigate(RouteNames.MENUS)
                    }
                }
            })
        }
    }

    async function handleOffItem() {
        api.turnOffItem({setLoading: setTurnOnOffLoading}, item.id, currentMenuId).then((res) => {
            if (res.success) {
                reloadItem()
            }
        })
    }

    async function handleOnItem() {
        api.turnOnItem({setLoading: setTurnOnOffLoading}, item.id, currentMenuId).then((res) => {
            if (res.success) {
                reloadItem()
            }
        })
    }

    async function handleHideItem() {
        api.hideItem({setLoading: setHideShowLoading}, item.id, currentMenuId).then((res) => {
            if (res.success) {
                reloadItem()
            }
        })
    }

    async function handleShowItem() {
        api.revealItem({setLoading: setHideShowLoading}, item.id, currentMenuId).then((res) => {
            if (res.success) {
                reloadItem()
            }
        })
    }

    return {
        item,
        menu,
        modal,
        isStaff: !!user?.isStaff,
        selectedImage,
        menuStr,
        categoryId,
        currentMenuId,
        setSelectedImage,
        setModal,
        onUploadFile,
        onDeleteImg,
        UploadImage,
        onChangeItemName,
        onChangeShortDesc,
        onChangeDescription,
        onChangeMainPriceLabel,
        onChangeMainPrice,
        onChangeMainOldPrice,
        onChangeOtherPriceLabel: onChangeTuplePriceLabel,
        onChangeOtherPrice: onChangeTuplePrice,
        onChangeOtherOldPrice: onChangeTupleOldPrice,
        onDeleteOtherPrice: onDeleteTuple,
        onCreateOtherPrice: onCreateTuple,
        onChangeHeaderModifierSelects,
        onChangeIsHiddenModifierSelects,
        onChangeIsRequiredModifierSelects,
        onDeleteModifierSelects,
        onChangeLabelOptionModifierSelects,
        onChangePriceDeltaOptionModifierSelects,
        onChangeStatusOptionModifierSelects,
        onHideStatusOptionModifierSelects,
        onDeleteOptionModifierSelects,
        onCreateOptionModifierSelects,
        onCreateModifierSelects,
        onChangeLabelModifierExtra,
        onChangePriceDeltaModifierExtra,
        onHideModifierExtra,
        onDeleteModifierExtra,
        onCreateModifierExtra,
        onChangeProductInfo,
        onChangeCookingTime,
        onBlurCookingTime,
        saveItem,
        setCategoryId,
        handleOffItem,
        handleOnItem,
        handleHideItem,
        handleShowItem,
        onChangeTags,
        saveLoading,
        itemLoading,
        imageUploading,
        hideShowLoading,
        turnOnOffLoading
    }
}
