import {Link} from "react-router-dom";
import React from "react";
import {ILinkArgs, ILinks} from "../../models/link/link";

export function AppHeader(links: ILinks) {
    return (
        <div className="app__header">
            <div className="app__header-left">
                <h1 className="app__header-title">{links.header}</h1>
            </div>

            <div className="app__header-right">

                {links.links.map((link)=><HeaderLink url={link.url} title={link.title} svg={link.svg} key={"header-link"+link.url}/>)}

            </div>
        </div>
    )
}

function HeaderLink(args: ILinkArgs) {
    return (
        <Link to={args.url} km-partial="true" className="button -blue">
            {args.svg? <div dangerouslySetInnerHTML={{__html:args.svg}} />:<></>}
            {args.title}
        </Link>
    )
}