import {useGlobalContext} from "../globalContext";
import React, {useEffect, useState} from "react";
import {IReport} from "../../models/reports/reports";

export function useReports() {
    const {api} = useGlobalContext()

    const paginationStep = 20
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [reports, setReports] = useState<IReport[]>([])

    const [qInfo, setQInfo] = useState<{ days: number, visits: number, search:string }>({
        days: 30,
        visits: 10,
        search: '',
    })

    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        const controller = new AbortController()
        api.getReportRarelyVisitedMenus({controller, setLoading}, {
            limit: paginationStep,
            offset: page * paginationStep,
            days: qInfo.days,
            visits: qInfo.visits,
            search: qInfo.search,
        }).then((res) => {
            if (!res.data) return
            setReports(res.data.menus)
            setCount(res.data.countAll)
        })
        return () => controller.abort()
    }, [page, qInfo])

    function onFind(query: { days: number, visits: number, search:string }) {
        setQInfo(query)
        setPage(0)
    }

    return {
        count, page, loading,
        reports,
        step: paginationStep,
        setPage, onFind,
    }
}