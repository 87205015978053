import {useEffect, useRef, useState} from "react";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useGlobalContext, useUserContext} from "../globalContext";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {MenuTree} from "../../models/menu/menuTree";
import {addNotificationWithButton} from "../../utils/toast/toast";
import {menuItem} from "../../models/menu/menu";
import {IStopListItem} from "../../models/iiko/stopList";

export function useMenu() {
    const [menuInfo, setMenuInfo] = useState<MenuTree>({sections: [], map: {}})
    const {api, i18n} = useGlobalContext()
    const {currentMenuId, menu, user, iikoActiveMenu, iikoBindOrg} = useUserContext()
    const {excelFileUploadError} = useTypedSelector(state => state.menu);

    const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({})
    const [dropLeft, setDropLeft] = useState<string>("")

    const [modalItem, setModalItem] = useState<menuItem | null>(null)
    const ref = useRef(null)
    const handleClickOutside = () => {
        setDropLeft("")
    }
    useOnClickOutside(ref, handleClickOutside, "mousedown")

    const [menuLoading, setMenuLoading] = useState<boolean>(false)
    const [deleteSectionLoading, setDeleteSectionLoading] = useState<boolean>(false)
    const [hideLoading, setHideLoading] = useState<boolean>(false)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [copyLoading, setCopyLoading] = useState<boolean>(false)

    // useEffect(() => {
    //     if (!iikoActiveMenu || !iikoBindOrg) {
    //         return
    //     }
    //     let controller = new AbortController()
    //     if (currentMenuId) {
    //         api.getIikoStopList({controller, setLoading:setStopListLoading},currentMenuId).then((res) => {
    //             if (res.success && res.data) {
    //                 let temp:{[key:string]:string} = {}
    //                 res.data.bindItems.forEach((item) => {
    //                     temp[item.iikoProductId] = item.kamiItemId
    //                 })
    //                 let stopList:{[key:string]:IStopListItem} = {}
    //                 res.data.items.forEach((item) => {
    //                     stopList[temp[item.productId]] = item
    //                 })
    //                 setStopList(stopList)
    //             }
    //         })
    //     }
    //     return () => controller.abort()
    // }, [currentMenuId, iikoActiveMenu, iikoBindOrg])


    useEffect(() => {
        const controller = new AbortController()
        reloadMenu(controller)
        return () => controller.abort()
    }, [currentMenuId])

    useEffect(() => {
        if (!excelFileUploadError.success) {
            return;
        }

        const controller = new AbortController()
        reloadMenu(controller)
        return () => controller.abort()
    }, [excelFileUploadError.message])

    function reloadMenu(controller = new AbortController()) {
        if (!currentMenuId) {
            return
        }
        setMenuInfo({sections: [], map: {}})
        api.getMenuStructForMenuPage({controller, setLoading: setMenuLoading}, currentMenuId).then((res) => {
            if (res.success && res.data) {
                setMenuInfo(res.data)
                let collapseJson = localStorage.getItem("collapse-" + currentMenuId)
                if (collapseJson) {
                    setCollapse(JSON.parse(collapseJson))
                }
            }
        })
    }

    //Events
    function onCollapse(id: string) {
        let value = !Boolean(collapse[id])
        let newCollapse = {...collapse, [id]: value}
        if (!value) {
            let sec = (menuInfo.sections || []).find((section) => section.section.id === id)
            if (sec) {
                (sec.categories || []).forEach((cat) => {
                    if (newCollapse[cat.category.id]) {
                        newCollapse[cat.category.id] = false
                    }
                })
            }
        }
        let collapseJson = JSON.stringify(newCollapse)
        localStorage.setItem("collapse-" + currentMenuId, collapseJson)
        setCollapse(newCollapse)
    }

    function onDropLeft(id: string) {
        setDropLeft(id === dropLeft ? "" : id)
    }

    //section events
    function onDeleteSection(id: string) {
        api.deleteSection({setLoading: setDeleteSectionLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                setMenuInfo({
                    ...menuInfo,
                    sections: (menuInfo.sections || []).filter(section => section.section.id != id)
                })
            }
        })
    }

    function onHideSection(id: string) {
        api.hideSection({setLoading: setHideLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                // hide menu section
                setMenuInfo({
                    ...menuInfo,
                    sections: (menuInfo.sections || []).map(section => {
                        if (section.section.id === id) {
                            section.section.isHidden = true
                        }
                        return section
                    })
                })
            }
        })
    }

    function onShowSection(id: string) {
        api.revealSection({setLoading: setShowLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                // show menu section
                setMenuInfo({
                    ...menuInfo,
                    sections: (menuInfo.sections || []).map(section => {
                        if (section.section.id === id) {
                            section.section.isHidden = false
                        }
                        return section
                    })
                })
            }
        })
    }

    function copyMenu(id: string, name: string) {
        if (id && currentMenuId) {
            addNotificationWithButton({
                message: i18n.q_copy_total_menus().replaceAll("menuName", name),
                onSubmit: () => {
                    api.copyTotalMenus({setLoading: setCopyLoading}, id, currentMenuId).then((res) => {
                        if (res.success) {
                            reloadMenu()
                        }
                    })
                },
                loading: copyLoading || menuLoading,
                i18n
            })
        }

    }

    return {
        menuInfo, collapse, dropLeft, ref, menu, user,
        onCollapse, onDropLeft, onDeleteSection, onHideSection, onShowSection,
        setMenuInfo, currentMenuId,
        copyMenu, iikoActiveMenu: iikoActiveMenu, iikoBindOrg,
        modalItem, setModalItem,
        deleteSectionLoading, hideLoading, showLoading, menuLoading, copyLoading,
    }
}
