import {AxiosResponse} from "axios";
import {MentionsMapping} from "migration/entities/mentions";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {$apiFormData, $apiV2} from "migration/shared/api";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface ProductMentionsOfMenuReq {
    "menuId": string,
}

export interface AddProductMentionToProductReq {
    "menuId": string,
    "mentionId": string,
    "productId": string,
    "toProductId": string,
}

export interface AddProductMentionToTopOfCategoryReq {
    "menuId": string,
    "mentionId": string,
    "productId": string,
    "toCategoryId": string,
}

export interface RemoveProductMentionReq {
    "menuId": string,
    "mentionId": string,
}

export default class MenuService {
    static async cmdFillOutMenuFromExcel(request: FormData): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiFormData.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.FILL_OUT_MENU_FROM_EXCEL, request);
    }

    static async productMentionsOfMenu(orgId: string, request: ProductMentionsOfMenuReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<MentionsMapping> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<MentionsMapping> | ErrorResponse>(ApiRoutes.PRODUCT_MENTIONS_OF_MENU, request, {signal: controller.signal});
    }

    static async addProductMentionToProduct(orgId: string, request: AddProductMentionToProductReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.ADD_PRODUCT_MENTION_TO_PRODUCT, request);
    }

    static async addProductMentionToTopOfCategory(orgId: string, request: AddProductMentionToTopOfCategoryReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.ADD_PRODUCT_MENTION_TO_TOP_OF_CATEGORY, request);
    }

    static async removeProductMention(orgId: string, request: RemoveProductMentionReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.REMOVE_PRODUCT_MENTION, request);
    }
}
