import {AxiosResponse} from "axios";
import {isEmpty} from "lodash";
import {AppDispatch} from "migration/app/store";
import {UserActionCreators} from "migration/app/store/reducers/user/action-creators";
import {RouteNames} from "migration/pages";
import {LangsList} from "migration/entities/base/mlString";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {ErrorResponse, SuccessResponse} from "../../api/response/response";

interface HttpHandlerProps {
    error: any;
    httpStatus: number;
    dispatch: AppDispatch;
    currentLang: LangsList;
    navigateCallback?: NavigateCallback;
    hideNotify?: boolean;
}

export const httpHandler = (
    {
        error,
        httpStatus,
        dispatch,
        currentLang,
        navigateCallback,
        hideNotify
    }: HttpHandlerProps
) => {
    if (hideNotify) {
        return;
    }
    if (httpStatus === 401) {
        dispatch(UserActionCreators.setAuth(false));
        Notify.Warning({title: txt.authorization[currentLang], message: txt.session_expired[currentLang]});
        navigateCallback?.navigate(RouteNames.LOGIN);
    } else if (httpStatus === 403) {
        Notify.Warning({
            title: txt.internal_system[currentLang],
            message: txt.you_dont_have_access[currentLang]
        });
    } else if (httpStatus === 404) {
        Notify.Warning({title: txt.page_not_found[currentLang], message: ""});
    } else if (httpStatus >= 400 && httpStatus < 500) {
        return;
    } else if (error?.code === "ERR_CANCELED") {
        return;
    } else {
        Notify.Error({
            title: txt.critical_error[currentLang],
            message: txt.please_try_again_or_contact_support[currentLang]
        });
        console.log(error)
    }
}

interface FailedResponseHandlerProps {
    message: string;
    httpStatus: number;
    hideNotify?: boolean;
}

export const FailedResponseHandler = ({message, httpStatus, hideNotify}: FailedResponseHandlerProps) => {
    if (httpStatus >= 400 && httpStatus < 500 && !isEmpty(message) && !hideNotify) {
        Notify.Warning({title: message, message: ""});
    }
}

export interface HttpActionCreatorWithResponseProps<T> {
    loadingCallback?: (bool: boolean) => any,
    successCallback?: (data: AxiosResponse<SuccessResponse<T>>) => void,
    failedCallback?: (error: AxiosResponse<ErrorResponse>) => void,
    navigateCallback?: NavigateCallback,
    hideNotify?: boolean,
}

export const HttpActionCreatorWithResponse = async <T>(
    dispatch: AppDispatch,
    currentLang: LangsList,
    response: Promise<AxiosResponse<SuccessResponse<T> | ErrorResponse>>,
    props: HttpActionCreatorWithResponseProps<T>
) => {
    try {
        if (props.loadingCallback) {
            dispatch(props.loadingCallback(true));
        }

        const res = await response;

        if (res.data?.success) {
            if (props.successCallback) {
                props.successCallback(res as AxiosResponse<SuccessResponse<T>>);
            }
        } else {
            if (props.failedCallback) {
                if (props?.failedCallback) {
                    props.failedCallback(res as AxiosResponse<ErrorResponse>);
                }
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        }
    } catch (e: any) {
        httpHandler({
            error: e,
            httpStatus: e?.response?.status,
            dispatch: dispatch,
            currentLang: currentLang,
            navigateCallback: props?.navigateCallback,
            hideNotify: props?.hideNotify,
        });
    } finally {
        if (props.loadingCallback) {
            dispatch(props.loadingCallback(false));
        }
    }
}

export interface HttpActionCreatorProps<T> {
    loadingCallback?: (bool: boolean) => any,
    mainCallback?: (arg: T) => void,
    navigateCallback?: NavigateCallback,
    hideNotify?: boolean,
}

export const HttpActionCreator = async <T>(
    dispatch: AppDispatch,
    currentLang: LangsList,
    response: Promise<AxiosResponse<SuccessResponse<T> | ErrorResponse>>,
    props: HttpActionCreatorProps<T>,
    arg?: T,
) => {
    try {
        if (props.loadingCallback) {
            dispatch(props.loadingCallback(true));
        }

        const req = response;

        if (props.mainCallback) {
            props.mainCallback(arg as T);
        }
    } catch (e: any) {
        httpHandler({
            error: e,
            httpStatus: e?.response?.status,
            dispatch: dispatch,
            currentLang: currentLang,
            navigateCallback: props?.navigateCallback,
            hideNotify: props?.hideNotify,
        });
    } finally {
        if (props.loadingCallback) {
            dispatch(props.loadingCallback(false));
        }
    }
}
