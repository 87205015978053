import {useEffect, useState} from "react";
import {LangKey, useGlobalContext} from "../globalContext";

/** Custom hook to account info
 * @return {LangKey} - lang, langSett
 */
export default function useAccount() {
    /** Now language */
    const [langSett, setLangSett] = useState<LangKey>("RU")
    /** Global context */
    const {i18n, setLang} = useGlobalContext()


    /** Set settings lang */
    useEffect(() => {
        setLangSett(i18n.getLang())
    }, [])


    /** Save language in server */
    const handleSubmit = () => {
        console.log(langSett)
        setLang(langSett)
        // todo save user lang
    }

    /** Change settings language */
    const handleChangeUserLang = (lang: LangKey) => {
        setLangSett(lang)
    }
    return {langSett, handleChangeUserLang, handleSubmit}
}