import {AxiosResponse} from "axios";
import {OrgId} from "migration/entities/baseAliases";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";
import {ColaProductKeyEnum} from "../store/types";

export interface CocaColaClientRes {
    "id": string,
    "isCocaColaClient": boolean,
}

export interface CocaColaMenuCategoryRes {
    "id": string,
    "isCocaColaMenuCategory": boolean,
}

export interface CocaColaProductRes {
    "id": string,
    "productKey": ColaProductKeyEnum,
    "isCocaColaProduct": boolean,
}

export interface MarkAsCocaColaProductReq {
    productId: string;
    colaProductKey: string;
}

export default class ColaService {
    static async getCocaColaClientById(orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<CocaColaClientRes> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<CocaColaClientRes> | ErrorResponse>(ApiRoutes.COCA_COLA_CLIENT_BY_ID, {id: orgId}, {signal: controller.signal});
    }

    static async getCocaColaMenuCategoryById(id: string, orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<CocaColaMenuCategoryRes> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<CocaColaMenuCategoryRes> | ErrorResponse>(ApiRoutes.COCA_COLA_MENU_CATEGORY_BY_ID, {id}, {signal: controller.signal});
    }

    static async getCocaColaProductById(id: string, orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<CocaColaProductRes> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<CocaColaProductRes> | ErrorResponse>(ApiRoutes.COCA_COLA_PRODUCT_BY_ID, {id}, {signal: controller.signal});
    }

    static async cmdMarkAsCocaColaClient(orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.MARK_AS_COCA_COLA_CLIENT, {tenantId: orgId});
    }

    static async cmdMarkAsCocaColaMenuCategory(menuCategoryId: string, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.MARK_AS_COCA_COLA_MENU_CATEGORY, {menuCategoryId});
    }

    static async cmdMarkAsCocaColaProduct(request: MarkAsCocaColaProductReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.MARK_AS_COCA_COLA_PRODUCT, request);
    }

    static async cmdUnMarkAsCocaColaClient(orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNMARK_AS_COCA_COLA_CLIENT, {tenantId: orgId});
    }

    static async cmdUnMarkAsCocaColaMenuCategory(menuCategoryId: string, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNMARK_AS_COCA_COLA_MENU_CATEGORY, {menuCategoryId});
    }

    static async cmdUnMarkAsCocaColaProduct(productId: string, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNMARK_AS_COCA_COLA_PRODUCT, {productId});
    }
}
