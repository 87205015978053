import {LangKey} from "../../hooks/globalContext";

export const KZ:LangKey = "KZ"
export const EN:LangKey = "EN"
export const AZ:LangKey = "AZ"
export const AR:LangKey = "AR"
export const RU:LangKey = "RU"
export const TR:LangKey = "TR"
export const CH:LangKey = "CH"
export const JP:LangKey = "JP"
export const UZ:LangKey = "UZ"
export const KY:LangKey = "KY"
export const ID:LangKey = "ID"
export const UK:LangKey = "UK"
export const options:{value:LangKey, label:string}[] = [
    {value: KZ, label: "Қазақша"},
    {value: UK, label: "Українська"},
    {value: EN, label: "English"},
    {value: AZ, label: "Azərbaycan"},
    {value: AR, label: "عربي"},
    {value: RU, label: "Русский"},
    {value: TR, label: "Türkçe"},
    {value: CH, label: "中文"},
    {value: JP, label: "日本"},
    {value: UZ, label: "Оʻzbek"},
    {value: KY, label: "Кыргызча"},
    {value: ID, label: "Bahasa Indonesia"},
]

// currency
export const currencyOptions = [
    {value: "KZT", label: "KZT ₸"},
    {value: "UAH", label: "UAH ₴"},
    {value: "AED", label: "AED"},
    {value: "KGS", label: "KGS с"},
    {value: "RUB", label: "RUB ₽"},
    {value: "USD", label: "USD $"},
    {value: "EUR", label: "EUR €"},
    {value: "GBP", label: "GBP £"},
    {value: "MYR", label: "MYR RM"},
    {value: "AZN", label: "AZN ₼"},
    {value: "TRY", label: "TRY ₺"},
    {value: "UZS", label: "UZS"},
    {value: "IDR", label: "Rp"},
]


export const CURRENCY:{[key:string]:string} = {
    "KZT": "₸",
    "UAH": "₴",
    "AED": "AED",
    "KGS": "с",
    "RUB": "₽",
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "MYR": "RM",
    "AZN": "₼",
    "TRY": "₺",
    "UZS": "UZS",
    "IDR": "Rp",
}
