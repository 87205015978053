import {
    KamiMenuCategoryList,
    KamiMenuItemsList,
    KamiMenuStruct
} from "migration/entities/kamiMenuStruct";

export interface KamiItemState {
    menuData: KamiMenuStruct;
    kamiMenuItemsList: KamiMenuItemsList
    kamiMenuCategoryList: KamiMenuCategoryList
    isLoadingGetMenuData: boolean;
}

export enum KamiItemActionEnum {
    SET_MENU_DATA = "SET_MENU_DATA",
    SET_KAMI_MENU_ITEMS_LIST = "SET_MENU_ITEMS",
    SET_KAMI_MENU_CATEGORY_LIST = "SET_KAMI_MENU_CATEGORY_LIST",
    SET_IS_LOADING_GET_MENU_DATA = "SET_IS_LOADING_GET_MENU_DATA",
}

export interface SetMenuDataAction {
    type: KamiItemActionEnum.SET_MENU_DATA,
    payload: KamiMenuStruct,
}

export interface SetKamiMenuItemsListAction {
    type: KamiItemActionEnum.SET_KAMI_MENU_ITEMS_LIST,
    payload: KamiMenuItemsList,
}

export interface SetKamiMenuCategoryList {
    type: KamiItemActionEnum.SET_KAMI_MENU_CATEGORY_LIST,
    payload: KamiMenuCategoryList,
}

export interface SetIsLoadingGetMenuDataAction {
    type: KamiItemActionEnum.SET_IS_LOADING_GET_MENU_DATA;
    payload: boolean;
}

export type KamiItemAction =
    SetMenuDataAction |
    SetKamiMenuItemsListAction |
    SetKamiMenuCategoryList |
    SetIsLoadingGetMenuDataAction;
