import {NavLink} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React from "react";
import {RouteNames} from "../../migration/pages";

export function OrderPageTabs() {
    const {i18n} = useGlobalContext()
    return (
        <ul className="nav default-tabs mb-2 mb-lg-3">

            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to={RouteNames.ORDERS}
                >
                    {i18n.client_orders()}
                </NavLink>
            </li>
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to={RouteNames.CUSTOMERS}
                >
                    {i18n.customer()}
                </NavLink>
            </li>
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to={RouteNames.REPORTS}
                >
                    {i18n.reports()}
                </NavLink>
            </li>
        </ul>
    )
}
