import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {PlusCircleFilled} from "@ant-design/icons";
import {Button, Form, Input, Skeleton} from "antd";
import {useForm} from "antd/es/form/Form";
import {RouteNames} from "migration/pages";
import {GradeSettings} from "migration/entities/Feedback";
import {LangsList, MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {rules} from "migration/shared/lib/form-rules/rules";
import {makeid} from "migration/shared/lib/make-id/makeId";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import FeedbackPage from "./components/feedbackPage/FeedbackPage";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import {FeedbackModal} from "./components/feedbackModal";
import classes from "./Main.module.scss";

type FieldType = {
    name?: string;
    slug?: string;
    isPrefilled?: boolean;
};

export const Main: FunctionComponent = () => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser, currentMenuId} = useTypedSelector(state => state.user);
    const {feedbacksList, isLoadingGetFeedbacksList} = useTypedSelector(state => state.feedbacksList);
    const {getFeedbacksList, createFeedbackPage} = useActions();

    const [isPrefilled, setIsPrefilled] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [form] = useForm();

    const fetchFeedbacksList = () => {
        if (!currentUser?.org?.id) return;
        const controller = new AbortController();
        getFeedbacksList({navigate: navigate}, controller);
        return () => controller.abort();
    }

    const handleCreateFeedbackPage = (values: FieldType) => {
        createFeedbackPage({
            id: uuidv4(),
            name: values.name || "",
            slug: makeid(),
            defaultLang: LangsList.RU,
            otherLangs: [],
            isArchived: false,
            backLink: "",
            text: {
                title: {} as MlString,
                subtitle: {} as MlString,
            },
            positiveGrading: {} as GradeSettings,
            negativeGrading: {} as GradeSettings,
            specificGrading: {},
        }, isPrefilled, {navigate: navigate});
        form.resetFields();
        setIsCreateModalOpen(false);
    }

    useEffect(() => {
        fetchFeedbacksList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.org?.id]);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <Breadcrumbs
                    infos={[{title: txt.feedbaker[currentLang], link: RouteNames.FEEDBACK, index: "bracer-0"}]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{txt.feedbaker[currentLang]}</h1>
                    </div>
                </div>

                <div className={classes.content__control}>
                    <Button
                        type={"primary"}
                        icon={<PlusCircleFilled/>}
                        className={classes.add__button}
                        onClick={() => setIsCreateModalOpen(true)}
                    >
                        {txt.add_feedback_page[currentLang]}
                    </Button>
                </div>

                <div className={classes.content}>
                    {isLoadingGetFeedbacksList
                        ?
                        Array(5).fill(0).map((_, index) => (
                            <Skeleton className={classes.feedback__page__skeleton} key={index} active/>
                        ))
                        :
                        !!feedbacksList?.length
                            ?
                            feedbacksList.map((feedbackPage) =>
                                <FeedbackPage feedbackPage={feedbackPage} key={feedbackPage?.id}/>
                            )
                            :
                            <div className={classes.empty}>{txt.no_feedback_pages[currentLang]}</div>
                    }
                </div>
            </div>

            <FeedbackModal
                title={txt.add_feedback_page[currentLang]}
                isModalOpen={isCreateModalOpen}
                onOk={() => form.submit()}
                onCancel={() => setIsCreateModalOpen(false)}
                okText={txt.add[currentLang]}
                cancelText={txt.cancel[currentLang]}
                content={
                    <Form className={classes.form} onFinish={handleCreateFeedbackPage} form={form} layout={"vertical"}>
                        <Form.Item<FieldType>
                            label={txt.name[currentLang]}
                            name="name"
                            rules={[rules.required(txt.please_enter_name[currentLang])]}
                        >
                            <Input placeholder={txt.enter_name[currentLang]}/>
                        </Form.Item>
                        <Form.Item<FieldType> name="isPrefilled">
                            <label style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "5px"
                            }}>
                                <Input
                                    checked={isPrefilled}
                                    onChange={(e) => setIsPrefilled(e.target.checked)}
                                    type={"checkbox"}
                                    style={{width: "15px", height: "15px"}}
                                />
                                {txt.prefill_feedback_page[currentLang]}
                            </label>
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}
