import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {Button, Skeleton} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {RouteNames} from "migration/pages";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Layout} from "migration/shared/ui/Layout";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ModalAddProduct} from "./components/ModalAddProduct";
import {ModalAddCategory} from "./components/ModalAddCategory";
import {ModalAddCommon} from "./components/ModalAddCommon";
import classes from "./CrossSelling.module.scss";

export const CrossSelling: FunctionComponent = () => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser, currentMenuId} = useTypedSelector(state => state.user);
    const {
        kamiMenuItemsList,
        kamiMenuCategoryList,
        isLoadingGetMenuData
    } = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping, isLoadingGetTenant} = useTypedSelector(state => state.crossSelling);
    const {
        getTenantById,
        fetchMenuData,
        dissociateCrossSellingFromProduct,
        dissociateCrossSellingFromMenuCategory
    } = useActions();

    const [isModalOpenProducts, setIsModalOpenProducts] = useState<boolean>(false);
    const [isModalOpenCategories, setIsModalOpenCategories] = useState<boolean>(false);
    const [isModalOpenCommon, setIsModalOpenCommon] = useState<boolean>(false);

    const filteredCrossSaleProducts = useMemo<string[]>(() => {
        if (isEmpty(kamiMenuItemsList)) return [];
        return Object.keys(crossSellingMapping?.products).filter(itemId => Object.keys(kamiMenuItemsList).includes(itemId));
    }, [crossSellingMapping, kamiMenuItemsList]);

    const filteredCrossSaleCategories = useMemo(() => {
        if (isEmpty(crossSellingMapping?.categories)) return [];
        const menuCategories = Object.keys(kamiMenuCategoryList || {});
        const filtered = Object.keys(crossSellingMapping.categories).filter(category => menuCategories.includes(category));
        return filtered || [];
    }, [crossSellingMapping, kamiMenuCategoryList]);

    const filteredCrossSaleCommon = useMemo(() => {
        if (isEmpty(crossSellingMapping?.commonProductIds)) return [];
        return crossSellingMapping?.commonProductIds?.filter(item => Object.keys(kamiMenuItemsList).includes(item));
    }, [crossSellingMapping, kamiMenuItemsList]);

    const dissociateCrossSellingProduct = (itemId: string) => {
        if (!itemId) {
            Notify.Info({title: txt.please_select_product[currentLang], message: ""});
        }
        dissociateCrossSellingFromProduct({sourceProductId: itemId}, crossSellingMapping);
    }

    const dissociateCrossSellingCategory = (categoryId: string) => {
        if (!categoryId) {
            Notify.Info({title: txt.please_select_category[currentLang], message: ""});
        }
        dissociateCrossSellingFromMenuCategory({sourceCategoryId: categoryId}, crossSellingMapping);
    }

    useEffect(() => {
        const controller = new AbortController();
        getTenantById({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentUser?.org]);

    useEffect(() => {
        if (!currentMenuId) return;
        const controller = new AbortController();
        fetchMenuData({menuId: currentMenuId}, controller, {navigate});
        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentMenuId]);

    return (
        <Layout
            title={`${txt.cross_selling[currentLang]}`}
            breadcrumbs={[{title: txt.cross_selling[currentLang], link: RouteNames.CROSS_SELLING, index: "bracer-0"}]}
        >
            <div className={classes.card}>
                <h1 className={classes.card__title}>{txt.products[currentLang]}</h1>
                <div className={classes.card__body}>
                    {
                        (isLoadingGetTenant || isLoadingGetMenuData)
                            ?
                            <Skeleton/>
                            :
                            !!filteredCrossSaleProducts.length
                                ? (
                                    filteredCrossSaleProducts.map(itemId => (
                                        <CrossSaleProduct
                                            key={itemId}
                                            itemId={itemId}
                                            dissociateCrossSellingProduct={dissociateCrossSellingProduct}
                                        />
                                    ))
                                )
                                : (
                                    <div className={classes.card__body__empty}>{txt.empty[currentLang]}</div>
                                )
                    }

                    <Button onClick={() => setIsModalOpenProducts(true)}>
                        {txt.add[currentLang]}
                    </Button>
                </div>
            </div>

            <div className={classes.card}>
                <div className={classes.card__title}>{txt.categories[currentLang]}</div>
                <div className={classes.card__body}>
                    {
                        (isLoadingGetTenant || isLoadingGetMenuData)
                            ?
                            <Skeleton/>
                            :
                            !!filteredCrossSaleCategories.length
                                ? (
                                    filteredCrossSaleCategories.map(categoryId => (
                                        <CrossSaleCategory
                                            key={categoryId}
                                            categoryId={categoryId}
                                            dissociateCrossSellingCategory={dissociateCrossSellingCategory}
                                        />
                                    ))
                                )
                                : (
                                    <div className={classes.card__body__empty}>{txt.empty[currentLang]}</div>
                                )
                    }

                    <Button onClick={() => setIsModalOpenCategories(true)}>
                        {txt.add[currentLang]}
                    </Button>
                </div>
            </div>

            <div className={classes.card}>
                <div className={classes.card__title}>{txt.common[currentLang]}</div>
                <div className={classes.card__body}>
                    {
                        (isLoadingGetTenant || isLoadingGetMenuData)
                            ?
                            <Skeleton/>
                            :
                            !!filteredCrossSaleCommon.length
                                ? (
                                    <div className={classes.card__body__row}>
                                        <div className={classes.card__body__row__input}>
                                            {filteredCrossSaleCommon.map(itemId => (
                                                <div className={classes.cart__body__row__input__item} key={itemId}>
                                                    {`${kamiMenuItemsList[itemId]?.name?.[currentLang]?.trim()},`}
                                                </div>
                                            ))}
                                        </div>
                                        <ModalAddCommon
                                            isOpen={isModalOpenCommon}
                                            setIsOpen={setIsModalOpenCommon}
                                        />
                                    </div>
                                )
                                : (
                                    <div className={classes.card__body__empty}>{txt.empty[currentLang]}</div>
                                )
                    }

                    <Button onClick={() => setIsModalOpenCommon(true)}>
                        {txt.add[currentLang]}
                    </Button>
                </div>
            </div>

            <ModalAddProduct isOpen={isModalOpenProducts} setIsOpen={setIsModalOpenProducts}/>
            <ModalAddCategory isOpen={isModalOpenCategories} setIsOpen={setIsModalOpenCategories}/>
            <ModalAddCommon isOpen={isModalOpenCommon} setIsOpen={setIsModalOpenCommon}/>
        </Layout>
    );
};

interface CrossSaleProductProps {
    itemId: string,
    dissociateCrossSellingProduct: (itemId: string) => void,
}

const CrossSaleProduct: FunctionComponent<CrossSaleProductProps> = ({itemId, dissociateCrossSellingProduct}) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menu} = useTypedSelector(state => state.user);
    const {kamiMenuItemsList} = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);

    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

    return (
        <div className={classes.card__body__row} key={itemId}>
            <div className={classes.card__body__row__title}>
                <div className={classes.card__body__row__title__group}>
                    <EditOutlined
                        onClick={() => setIsEditModalOpen(true)}
                        className={classes.card__body__row__title__icon}
                    />
                    <DeleteOutlined
                        onClick={() => dissociateCrossSellingProduct(itemId)}
                        className={classes.card__body__row__title__icon}
                    />
                </div>
                {`${kamiMenuItemsList[itemId]?.name?.[currentLang]}:`}
            </div>
            <div className={classes.card__body__row__input}>
                {crossSellingMapping.products[itemId]?.productIds?.map(productId => (
                    <div className={classes.cart__body__row__input__item} key={productId}>
                        {`${kamiMenuItemsList[productId]?.name?.[menu?.i18n?.defaultLang]?.trim()},`}
                    </div>
                ))}
            </div>
            <ModalAddProduct
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                isEditMode={true}
                selectedProductId={itemId}
            />
        </div>
    )
}

interface CrossSaleCategoryProps {
    categoryId: string,
    dissociateCrossSellingCategory: (itemId: string) => void,
}

const CrossSaleCategory: FunctionComponent<CrossSaleCategoryProps> = ({categoryId, dissociateCrossSellingCategory}) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menu} = useTypedSelector(state => state.user);
    const {kamiMenuItemsList, kamiMenuCategoryList} = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);

    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

    return (
        <div className={classes.card__body__row} key={categoryId}>
            <div className={classes.card__body__row__title}>
                <div className={classes.card__body__row__title__group}>
                    <EditOutlined
                        onClick={() => setIsEditModalOpen(true)}
                        className={classes.card__body__row__title__icon}
                    />
                    <DeleteOutlined
                        onClick={() => dissociateCrossSellingCategory(categoryId)}
                        className={classes.card__body__row__title__icon}
                    />
                </div>
                {`${kamiMenuCategoryList[categoryId]?.category?.name?.[menu?.i18n?.defaultLang]}:`}
            </div>
            <div className={classes.card__body__row__input}>
                {crossSellingMapping.categories[categoryId]?.productIds?.map(productId => (
                    <div className={classes.cart__body__row__input__item} key={productId}>
                        {`${kamiMenuItemsList[productId]?.name?.[menu?.i18n?.defaultLang]?.trim()},`}
                    </div>
                ))}
            </div>
            <ModalAddCategory
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                isEditMode={true}
                selectedCategoryId={categoryId}
            />
        </div>
    )
}
