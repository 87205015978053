import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../globalContext";
import {IOrgShortInfo} from "../../models/user/userLogin";
import {addNotification} from "../../utils/toast/toast";
import {useDebounce} from "../utils";

export function useCopyMenu() {
    const {api, i18n} = useGlobalContext()
    const {id} = useParams()
    const navigate = useNavigate()

    const [find, setFind] = useState<string>("")

    const [srcOrg, setSrcOrg] = useState<IOrgShortInfo | null>(null)
    const [nowDstOrg, setNowDstOrg] = useState<{ value: string, label: string } | null>(null)
    const [nowSrcMenu, setNowSrcMenu] = useState<{ value: string, label: string } | null>(null)

    const [orgs, setOrgs] = useState<{ value: string, label: string }[]>([])

    const debounce = useDebounce((fn: () => void) => {
        fn()
    }, 1000)

    const [loading, setLoading] = useState<boolean>(false)
    const [orgNameLoading, setOrgNameLoading] = useState<boolean>(false)
    const [copyLoading, setCopyLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!id) return
        const controller = new AbortController()
        api.getOrganizationById({controller, setLoading}, id).then((res) => {
            if (res.data) {
                setSrcOrg(res.data)
            }
        })
        return () => controller.abort()
    }, [id])

    useEffect(() => {
        const controller = new AbortController()
        debounce(() =>{
            if (!id) return
            api.getOrganizationsForCopyMenu({controller, setLoading: setOrgNameLoading}, find, 10, id).then((res) => {
                if (res.data) {
                    setOrgs((res.data.organizations || []).map((org) => {
                        return {
                            value: org.id,
                            label: org.name
                        }
                    }))
                }
            })
        })
        return () => controller.abort()
    }, [find])

    function handleSubmit() {
        if (!nowSrcMenu) {
            addNotification({message: i18n.select_menu(), type: "info"})
            return
        }
        if (!nowDstOrg) {
            addNotification({message: i18n.select_organization(), type: "info"})
            return
        }
        api.copyMenuToAnotherOrg({setLoading: setCopyLoading}, nowSrcMenu.value, nowDstOrg.value).then((res) => {
            if (res.data) {
                navigate("/staff/clients")
            }
        })
    }

    return {
        orgs,
        find,
        srcOrg,
        setFind,
        handleSubmit,
        setNowDstOrg,
        setNowSrcMenu,


        copyLoading,
        orgNameLoading,
        loading
    }
}