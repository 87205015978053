import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {useGlobalContext} from "../../hooks/globalContext";
import {ChangePasswordForm} from "../../components/form/changePasswordForm";

export function StaffChangePassword() {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.changing_password()
    }, [i18n])
    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={[{
                    title: i18n.change_password(),
                    link: "/staff/account/change-password/",
                    index: "brcr-1"
                }]}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.change_password()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            <ChangePasswordForm backUrl={"/staff/clients"}/>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}