import React, {useEffect, useState} from "react";
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.css";
import {useGlobalContext} from "../../hooks/globalContext";

interface CropperModalProps {
    modal: boolean,
    selectedImage: File | null,
    setSelectImage: (file: File | null) => void,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    saveImage: (file: File) => void
    aspectRatio?: number
}

export function CropperModal({
                                 modal,
                                 selectedImage,
                                 setModal,
                                 saveImage,
                                 setSelectImage,
                                 aspectRatio
                             }: CropperModalProps) {
    const {i18n} = useGlobalContext()

    const [cropper, setCropper] = useState<Cropper>();
    const [withBackgroundColor, setWithBackgroundColor] = useState<boolean>(false);
    const [colorToFillEmptySpace, setColorToFillEmptySpace] = useState("#ffffff");
    const [isRectangle, setIsRectangle] = useState<boolean>(true);

    useEffect(() => {
        if (modal) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [modal])

    const height = aspectRatio ? window.innerHeight - 270 : window.innerHeight - 220
    return (
        <>
            <div className={modal ? "modal effect-scale show" : "modal effect-scale"}
                 style={{display: modal ? "block" : "none"}}>
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-fullscreen">
                    <div className="modal-content">
                        <div className="modal-body text-center px-3 py-5 p-lg-5">
                            <button aria-label="Close" className="close"
                                    type="button"
                                    onClick={() => setModal(false)}
                            >
                            </button>
                            {aspectRatio && <div className={"nav default-tabs py-1"}>
                                <li className="default-tabs__item">
                                    <div
                                        className={isRectangle ? "default-tabs__link active" : "default-tabs__link "}
                                        onClick={() => setIsRectangle(true)}>
                                        {i18n.square()}
                                    </div>
                                </li>
                                <li className="default-tabs__item">
                                    <div
                                        className={!isRectangle ? "default-tabs__link active" : "default-tabs__link "}
                                        onClick={() => setIsRectangle(false)}>
                                        {i18n.free()}
                                    </div>
                                </li>
                            </div>}
                            <Cropper
                                key={isRectangle ? 'rectangle' : 'free'}
                                style={{width: "100%", height: `${height}px`}}
                                // zoomTo={0.5}
                                allowFullScreen={true}
                                cropBoxResizable={true}
                                preview=".img-preview"
                                src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
                                viewMode={0}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                aspectRatio={isRectangle ? aspectRatio : 0}
                                modal={true}
                                background={true}
                                responsive={true}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                guides={false}
                            />
                        </div>
                        <div className="modal-footer">
                            <div style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                                    <label style={{display: "flex", alignItems: "center", gap: '10px', margin: "0"}}>
                                        <input
                                            onChange={(e) => setWithBackgroundColor(e.target.checked)}
                                            type={"checkbox"}
                                            className="button -gray"
                                            style={{width: "20px", height: "20px"}}
                                        />
                                        {i18n.with_background_color()}
                                    </label>
                                    <input
                                        defaultValue={colorToFillEmptySpace}
                                        value={colorToFillEmptySpace}
                                        onChange={(e) => setColorToFillEmptySpace(e.target.value)}
                                        type={"color"}
                                        className="button -gray"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: "10px",
                                            opacity: withBackgroundColor ? 1 : 0.5,
                                            transition: "opacity 0.1s"
                                        }}
                                        readOnly={withBackgroundColor}
                                    />
                                </div>
                                <div>
                                    <button type="button"
                                            className="button -blue modal__button"
                                            onClick={() => {
                                                if (withBackgroundColor) {
                                                    cropper?.getCroppedCanvas({fillColor: colorToFillEmptySpace}).toBlob((blob) => {
                                                        if (blob) {
                                                            let file = new File([blob], "cropped." + blob.type.split("/")[1], {type: selectedImage?.type})
                                                            let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                                                            console.log(sizeInMB + 'MB', file.type);
                                                            saveImage(file)
                                                            setSelectImage(null)
                                                            setModal(false)
                                                        }
                                                    }, selectedImage?.type)
                                                } else {
                                                    cropper?.getCroppedCanvas().toBlob((blob) => {
                                                        if (blob) {
                                                            let file = new File([blob], "cropped." + blob.type.split("/")[1], {type: selectedImage?.type})
                                                            let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                                                            console.log(sizeInMB + 'MB', file.type);
                                                            saveImage(file)
                                                            setSelectImage(null)
                                                            setModal(false)
                                                        }
                                                    }, selectedImage?.type)
                                                }
                                            }}
                                    >
                                        {i18n.save()}
                                    </button>
                                    <button type="button"
                                            className="button -bordered_blue modal__button" onClick={() => {
                                        setModal(false)
                                    }}>
                                        {i18n.cancel()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={modal ? "modal-backdrop show" : ""}></div>
        </>
    )
}
