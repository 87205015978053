import {ReceiverList} from "migration/entities/receiverList";
import {TelegramPoolOfTenant} from "migration/entities/telegramPoolOfTenant";

export interface GeneralNotificationState {
    telegramPoolOfTenant: TelegramPoolOfTenant,
    receiverLists: ReceiverList[],
    isLoadingGetTelegramPoolOfTenant: boolean,
    isLoadingGetAllReceiverLists: boolean,
}

export enum GeneralNotificationActionEnum {
    SET_TELEGRAM_POOL_OF_TENANT = "SET_TELEGRAM_POOL_OF_TENANT",
    SET_RECEIVER_LISTS = "SET_RECEIVER_LISTS",
    SET_IS_LOADING_GET_TELEGRAM_POOL_OF_TENANT = "SET_IS_LOADING_GET_TELEGRAM_POOL_OF_TENANT",
    SET_IS_LOADING_GET_ALL_RECEIVER_LISTS = "SET_IS_LOADING_GET_ALL_RECEIVER_LISTS",
}

export interface SetTelegramPoolOfTenantAction {
    type: GeneralNotificationActionEnum.SET_TELEGRAM_POOL_OF_TENANT,
    payload: TelegramPoolOfTenant,
}

export interface SetReceiverListsAction {
    type: GeneralNotificationActionEnum.SET_RECEIVER_LISTS,
    payload: ReceiverList[],
}

export interface SetIsLoadingGetTelegramPoolOfTenantAction {
    type: GeneralNotificationActionEnum.SET_IS_LOADING_GET_TELEGRAM_POOL_OF_TENANT,
    payload: boolean,
}

export interface SetIsLoadingGetAllReceiverListsAction {
    type: GeneralNotificationActionEnum.SET_IS_LOADING_GET_ALL_RECEIVER_LISTS,
    payload: boolean,
}

export type GeneralNotificationAction =
    SetTelegramPoolOfTenantAction |
    SetReceiverListsAction |
    SetIsLoadingGetTelegramPoolOfTenantAction |
    SetIsLoadingGetAllReceiverListsAction;
