import React, {ReactComponentElement} from "react";
import {NavLink} from "react-router-dom";

interface SiteBarNavLink {
    link: string
    title: string
    setShowSiteBar: React.Dispatch<React.SetStateAction<boolean>>
    icon?: ReactComponentElement<any>
}

let isActive = ({isActive}: any) => isActive ? "sidebar__link  is-active" : "sidebar__link"

export function SiteBarNavLink({link, title, icon, setShowSiteBar}: SiteBarNavLink) {
    return (
        <NavLink to={link} end={false} className={isActive} onClick={() => setShowSiteBar(false)}
                 km-partial="true" style={{}}>
            <div className="sidebar__icon">
                {icon}
            </div>
            <div className="sidebar__title" style={{fontSize: "10.5px"}}>
                {title}
            </div>
        </NavLink>
    )
}
