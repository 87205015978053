import {SubmitButton} from "../input/submitButton";
import {Link} from "react-router-dom";
import React from "react";
import {useGlobalContext} from "../../hooks/globalContext";
import {useCopyMenu} from "../../hooks/staff/copyMenuForm";
import Select from "react-select";
import {FormWithLoader} from "../loader/formWithLoader";

export function CopyMenuForm({backUrl}: { backUrl?: string }) {
    const {i18n} = useGlobalContext()
    const info = useCopyMenu()

    return (
        <FormWithLoader isloading={info.loading} className="form">

            <div className="control">
                <label className="control__label">{`${info.srcOrg?.name} ${i18n.menus()}`}</label>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name={`${info.srcOrg?.name} ${i18n.menus()}`}
                    onChange={(e) => info.setNowSrcMenu(e)}
                    options={(info.srcOrg?.menus.menus||[]).map((menu) => {
                        return {
                            value: menu.id,
                            label: menu.name
                        }
                    })}
                />

            </div>

            <div className="control">
                <label className="control__label">{i18n.organizations()}</label>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name={i18n.organizations()}
                    onChange={(e) => info.setNowDstOrg(e)}
                    onInputChange={(e) => info.setFind(e)}
                    options={info.orgs}
                    isLoading={info.orgNameLoading}
                    loadingMessage={() => i18n.loading()}
                />

            </div>

            <div className="form__buttons">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.handleSubmit} label={i18n.save()}
                                  disabled={false} isLoading={info.copyLoading}/>
                    <Link to={backUrl ? backUrl : "/account/"}
                          className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>

        </FormWithLoader>
    )
}