import React from "react";
import Skeleton from "react-loading-skeleton";

interface IMenusListLoaderProps extends React.HTMLAttributes<HTMLUListElement> {
    level: number
    count: number
    loading: boolean
    notFound: boolean
    notFoundText: string
    styleli?: React.CSSProperties
}

export function MenuUlWithLoader(props: IMenusListLoaderProps) {
    return (
        <ul {...props}>
            {props.notFound ?
                <div className="alert alert-success py-4">
                    {props.notFoundText}
                </div>
                :
                props.loading ?
                    Array.from(Array(props.count).keys()).map(() => {
                        return (
                            <li className={"catalog__item"} style={props.styleli}>
                                <div className={"catalog__title"}>
                                    <Skeleton inline={true} containerClassName={"catalog__text"}
                                              count={2} width={"49%"}/>
                                </div>
                                <div className="collapse show">
                                    {props.level === 1 ? null :
                                        <MenuUlWithLoader level={props.level - 1} count={1} style={{marginTop: "10px"}}
                                                          loading={props.loading} notFound={props.notFound}
                                                          styleli={{listStyleType: "none"}}
                                                          notFoundText={props.notFoundText}/>
                                    }
                                </div>
                            </li>
                        )
                    })
                    :
                    props.children
            }
        </ul>
    )
}