import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Input, Select, Skeleton} from "antd";
import {isEmpty} from "lodash";
import {formatDate} from "migration/shared/lib/date/formatDate";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import classes from "./RkeeperMenuSettings.module.scss";

export const RkeeperMenuSettings: FunctionComponent = () => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentMenuId, currentUser} = useTypedSelector(state => state.user);
    const {
        rkeeperAgents,
        rkeeperMenuSettings,
        isLoadingGetRkeeperMenuSettings,
        isLoadingGetRkeeperAgents,
        isLoadingSaveMenuRkeeperSettings
    } = useTypedSelector(state => state.rkeeper);
    const {
        deleteMenuRkeeperSettings,
        saveMenuRkeeperSettings,
        getMenuRkeeperSettings,
        getRkeeperAgents,
    } = useActions();

    const [selectedRkeeperAgent, setSelectedRkeeperAgent] = useState<number>(0);
    const [rkeeperDiscountId, setRkeeperDiscountId] = useState<string>("");
    const [mustApproveOrdersBeforeSend, setMustApproveOrdersBeforeSend] = useState<boolean>(false);
    const [isChangesExist, setIsChangesExist] = useState<boolean>(true);

    const handleResetMenuRkeeperSettings = () => {
        if (isEmpty(rkeeperMenuSettings)) return;
        setSelectedRkeeperAgent(parseInt(rkeeperMenuSettings.rkeeperObjectId));
        setMustApproveOrdersBeforeSend(rkeeperMenuSettings.mustApproveOrdersBeforeSend);
        setRkeeperDiscountId(rkeeperMenuSettings.rkeeperDiscountId);
    }

    const handleDeleteMenuRkeeperSettings = () => {
        deleteMenuRkeeperSettings({
            menuId: currentMenuId,
            organizationId: currentUser?.org?.id || "",
        }, {navigate: navigate});
    }

    const handleSaveMenuRkeeperSettings = () => {
        saveMenuRkeeperSettings({
            menuId: currentMenuId,
            mustApproveOrdersBeforeSend: mustApproveOrdersBeforeSend,
            organizationId: currentUser?.org?.id || "",
            rkeeperDiscountId: rkeeperDiscountId,
            rkeeperObjectId: selectedRkeeperAgent.toString(),
        }, {navigate: navigate});
    }

    useEffect(() => {
        if (!currentMenuId) return;
        const controller = new AbortController();
        getMenuRkeeperSettings({menuId: currentMenuId}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    useEffect(() => {
        const controller = new AbortController();
        getRkeeperAgents(controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmpty(rkeeperMenuSettings)) return;
        setSelectedRkeeperAgent(parseInt(rkeeperMenuSettings.rkeeperObjectId));
        setMustApproveOrdersBeforeSend(rkeeperMenuSettings.mustApproveOrdersBeforeSend);
        setRkeeperDiscountId(rkeeperMenuSettings.rkeeperDiscountId);
    }, [rkeeperMenuSettings]);

    useEffect(() => {
        if (isEmpty(rkeeperMenuSettings)) return;
        setIsChangesExist(
            rkeeperMenuSettings.rkeeperObjectId !== selectedRkeeperAgent.toString() ||
            rkeeperMenuSettings.rkeeperDiscountId !== rkeeperDiscountId ||
            rkeeperMenuSettings.mustApproveOrdersBeforeSend !== mustApproveOrdersBeforeSend
        );
    }, [rkeeperMenuSettings, selectedRkeeperAgent, mustApproveOrdersBeforeSend, rkeeperDiscountId]);

    return (
        <div className={classes.section}>
            {isLoadingGetRkeeperMenuSettings ? (
                <Skeleton className={classes.block}/>
            ) : (
                <div className={classes.block}>
                    <h2 className={classes.block__label}>R-Keeper Current Settings</h2>

                    <div className={classes.block__params}>
                        <div className={classes.block__params__item}>
                            <div className={classes.block__params__item__label}>
                                Object ID
                            </div>
                            <div className={classes.block__params__item__value}>
                                {rkeeperMenuSettings.rkeeperObjectId}
                            </div>
                        </div>
                        <div className={classes.block__params__item}>
                            <div className={classes.block__params__item__label}>
                                Discount ID
                            </div>
                            <div className={classes.block__params__item__value}>
                                <Input
                                    value={rkeeperDiscountId}
                                    onChange={(e) => setRkeeperDiscountId(e.target.value)}
                                    placeholder={"Discount ID"}
                                />
                            </div>
                        </div>
                        <div className={classes.block__params__item}>
                            <div className={classes.block__params__item__label}>
                                Must approve orders before send
                            </div>
                            <div className={classes.block__params__item__value}>
                                <Select
                                    value={mustApproveOrdersBeforeSend}
                                    onChange={() => setMustApproveOrdersBeforeSend(!mustApproveOrdersBeforeSend)}
                                    options={[
                                        {label: "Yes", value: true},
                                        {label: "No", value: false},
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={classes.section__divider}></div>
            {(isLoadingGetRkeeperAgents && isLoadingGetRkeeperMenuSettings) ? (
                <Skeleton className={classes.block}/>
            ) : (
                <div className={classes.block}>
                    <h2 className={classes.block__label}>R-Keeper Agents</h2>

                    <div className={classes.block__row}>
                        <div className={classes.block__row__item}></div>
                        <div className={classes.block__row__item} style={{fontWeight: "600"}}>
                            Object ID
                        </div>
                        <div className={classes.block__row__item} style={{fontWeight: "600"}}>
                            Online
                        </div>
                        <div className={classes.block__row__item} style={{fontWeight: "600"}}>
                            Licence Expiration Date
                        </div>
                        <div className={classes.block__row__item} style={{fontWeight: "600"}}>
                            Version
                        </div>
                    </div>

                    {rkeeperAgents.map(agent => (
                        <div
                            key={agent.objectId}
                            onClick={() => {
                                if (agent.objectId === selectedRkeeperAgent) {
                                    setSelectedRkeeperAgent(0);
                                    return;
                                }
                                setSelectedRkeeperAgent(agent.objectId)
                            }}
                            className={classes.block__row}
                            style={{
                                color: agent.objectId === selectedRkeeperAgent ? "#fff" : "",
                                backgroundColor: agent.objectId === selectedRkeeperAgent ? "#2a5aff" : "",
                            }}
                        >
                            <div className={classes.block__row__item}>
                                <div className={classes.block__row__item__radio__btn}></div>
                            </div>
                            <div className={classes.block__row__item}>{agent.objectId}</div>
                            <div className={classes.block__row__item}>{agent.isOnline ? "Yes" : "No"}</div>
                            <div
                                className={classes.block__row__item}>{formatDate(agent.licenseExpirationDate)}</div>
                            <div className={classes.block__row__item}>{agent.version}</div>
                        </div>
                    ))}
                </div>
            )}
            <div className={classes.section__divider}></div>
            {(isLoadingGetRkeeperAgents && isLoadingGetRkeeperMenuSettings) ? (
                <Skeleton className={classes.block}/>
            ) : (
                <div className={classes.block}>
                    <div className={classes.block__btns}>
                        <Button onClick={handleResetMenuRkeeperSettings}>
                            Reset
                        </Button>
                        <Button
                            onClick={handleDeleteMenuRkeeperSettings}
                            type={"primary"}
                            danger={true}
                        >
                            Delete
                        </Button>
                        <Button
                            loading={isLoadingSaveMenuRkeeperSettings}
                            disabled={!isChangesExist}
                            onClick={handleSaveMenuRkeeperSettings}
                            type={"primary"}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
