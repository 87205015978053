import React, {FunctionComponent, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {RouteNames} from "migration/pages";
import {FeedbackPageShortData} from "migration/entities/Feedback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {FeedbackModal} from "../feedbackModal";
import classes from "./FeedbackPage.module.scss"

interface FeedbackPageProps {
    feedbackPage: FeedbackPageShortData,
}

const FeedbackPage: FunctionComponent<FeedbackPageProps> = (props) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser} = useTypedSelector(state => state.user);
    const {feedbacksList} = useTypedSelector(state => state.feedbacksList);
    const {deleteFeedbackPage} = useActions();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const urlFromSlug = (slug: string) => {
        if (!slug) return "";
        return (process.env.REACT_APP_API_URL || "").includes("kamiqr")
            ? `https://${currentUser?.org?.subdomain}.kamiqr.com/feedback/${slug}`
            : `https://${currentUser?.org?.subdomain}.kamidemo.com/feedback/${slug}`;
    }

    const handleDeleteFeedbackPage = (id: string) => {
        const feedback = feedbacksList?.find(page => page?.id === id);
        if (!feedback) return;
        deleteFeedbackPage(feedback, {navigate: navigate});
        setIsDeleteModalOpen(false);
    }

    return (
        <div className={classes.feedback__page}>
            <div className={classes.feedback__upper}>
                <div className={classes.feedback__name}>
                    {props.feedbackPage?.name}
                </div>
                <div className={classes.feedback__upper__group}>
                    <Link to={RouteNames.FEEDBACK_BY_ID_EDIT.replace(":id", props.feedbackPage?.id)}>
                        {txt.settings[currentLang]}
                    </Link>

                    <div className={classes.divider}></div>

                    <div className={classes.feedback__delete} onClick={() => setIsDeleteModalOpen(true)}>
                        {txt.delete[currentLang]}
                    </div>
                </div>
            </div>
            <div className={classes.feedback__lower}>
                <div
                    className={classes.feedback__slug}
                    onClick={() => window.open(urlFromSlug(props.feedbackPage?.slug), "_blank")}
                >
                    {urlFromSlug(props.feedbackPage?.slug)}
                </div>

                <Link to={RouteNames.FEEDBACK_BY_ID.replace(":id", props.feedbackPage?.id)}>
                    {txt.view_feedbacks[currentLang]}
                </Link>
            </div>

            <FeedbackModal
                title={txt.delete_feedback_page[currentLang]}
                isModalOpen={isDeleteModalOpen}
                onOk={() => handleDeleteFeedbackPage(props.feedbackPage.id)}
                onCancel={() => setIsDeleteModalOpen(false)}
                okText={txt.delete[currentLang]}
                cancelText={txt.cancel[currentLang]}
                content={<p>{txt.are_you_sure_to_delete_feedback_page[currentLang]}</p>}
            />
        </div>
    );
};

export default FeedbackPage;
