import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React from "react";
import * as am4core from "@amcharts/amcharts4/core";

am4core.useTheme(am4themes_animated);

interface IBlockLoaderProps extends React.HTMLAttributes<HTMLDivElement> {
}

export const BlockLoader = (props:IBlockLoaderProps) => {
    return (
        <div className="lds-roller-wrapper" {...props}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}