import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useGlobalContext, useUserContext} from "../../../../../../../hooks/globalContext";
import {FormWithLoader} from "../../../../../../../components/loader/formWithLoader";
import {LittleSpinner} from "../../../../../../../components/loader/littleSpinner";
import {InputText} from "../../../../../../../components/input/inputText";
import {SubmitButton} from "../../../../../../../components/input/submitButton";
import {ReactComponent as DeleteSVG} from '../../../../../../../resourse/img/general/ic-trash.svg';

export function IikoOrgSettings() {
    const navigate = useNavigate();
    const {i18n} = useGlobalContext();
    const {user} = useUserContext();
    const {
        iikoCompanyId,
        isLoadingDeleteOrganizationIikoSettings,
        isLoadingSaveOrganizationIikoSettings
    } = useTypedSelector(state => state.iiko);
    const {
        fetchIikoOrganizationSettings,
        deleteOrganizationIikoSettings,
        saveIikoOrganizationSettings
    } = useActions();
    const [apiKey, setApiKey] = useState<string>("");
    const [initialTrigger, setInitialTrigger] = useState<boolean>(true);

    const deleteIikoOrganizationSettings = () => {
        deleteOrganizationIikoSettings({
            organizationId: user?.org?.id || "",
        }, {organizationId: user?.org?.id || ""}, {navigate: navigate});
        setApiKey("");
    };

    const createIikoOrganizationSettings = async () => {
        await saveIikoOrganizationSettings({
            apiKey: apiKey,
            organizationId: user?.org?.id || "",
        }, {navigate: navigate});
        await fetchIikoOrganizationSettings({organizationId: user?.org?.id || ""}, new AbortController(), {navigate: navigate});
    }

    useEffect(() => {
        if (initialTrigger) {
            const controller = new AbortController();
            fetchIikoOrganizationSettings({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
            setInitialTrigger(false);
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = i18n.iiko_org_settings()
    }, [i18n]);

    return (
        <FormWithLoader isloading={false} className="form">
            {iikoCompanyId ?
                <div className="control">
                    <label className="control__label">{i18n.iiko_company_id()}</label>
                    <div className={"iiko_Tag"}>
                        <div className={"control__input"} style={{display: "flex", alignItems: "center"}}>
                            {iikoCompanyId}
                        </div>
                        {isLoadingDeleteOrganizationIikoSettings
                            ? <LittleSpinner/>
                            : <DeleteSVG style={{cursor: "pointer"}} onClick={deleteIikoOrganizationSettings}/>
                        }
                    </div>
                </div>
                :
                <InputText
                    label={i18n.api_key()}
                    value={apiKey}
                    onChange={setApiKey}
                    placeholder={i18n.api_key_placeholder()}
                    isValidate={true}
                />
            }

            {!iikoCompanyId &&
                <div className="form__buttons mt-4">
                    <div className="form__buttons-left">
                        <SubmitButton
                            onSubmit={createIikoOrganizationSettings}
                            label={i18n.add_key()}
                            disabled={isLoadingSaveOrganizationIikoSettings}
                            isLoading={isLoadingSaveOrganizationIikoSettings}
                        />
                    </div>
                </div>
            }
        </FormWithLoader>
    )
}
