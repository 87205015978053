import React, {FunctionComponent} from "react";
import {Breadcrumbs} from "../../../../components/breadcrumbs/breadcrumbs";
import classes from "./Layout.module.scss";

interface LayoutProps {
    children: React.ReactNode;
    title: string;
    breadcrumbs: { title: string, link: string, index: string }[];
}

export const Layout: FunctionComponent<LayoutProps> = (props) => {
    return (
        <div className={classes.main}>
            <Breadcrumbs infos={props.breadcrumbs}/>
            <div className="app__header">
                <div className="app__header-left">
                    <h1 className="app__header-title">{props.title}</h1>
                </div>
            </div>
            {props.children}
        </div>
    );
}
