import React, {useEffect} from "react";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext} from "../../hooks/globalContext";
import {OrgSettingsTab} from "../../components/tabs/orgSettingsTab";
import {OneVisionPaymentModule} from "../../components/form/oneVisionPaymentModule";

export function OneVisionSettings() {
    const {i18n, userInfoLoading} = useGlobalContext()
    let infos = [
        {link: "/organization-one-vision", title: i18n.one_vision_payment(), index: "breadcrumbs-id"}
    ]
    useEffect(() => {
        document.title = i18n.one_vision_payment()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={infos}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.one_vision_payment()}</h1>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <OrgSettingsTab/>
                        <div className="tab-content">
                            <div className="default-card">

                                <OneVisionPaymentModule/>

                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
