export interface OrgSettingsState {
    publicKey: string,
    encryptedPrivateKey: string,
    isLoadingLiqpaySettings: boolean,
    isLoadingSaveLiqpaySettings: boolean,
    isLoadingEncodeLiqpayPrivateKey: boolean,
}

export enum OrgSettingsEnum {
    SET_PUBLIC_KEY = "SET_PUBLIC_KEY",
    SET_ENCRYPTED_PRIVATE_KEY = "SET_ENCRYPTED_PRIVATE_KEY",
    SET_IS_LOADING_LIQPAY_SETTINGS = "SET_IS_LOADING_LIQPAY_SETTINGS",
    SET_IS_LOADING_SAVE_LIQPAY_SETTINGS = "SET_IS_LOADING_SAVE_LIQPAY_SETTINGS",
    SET_IS_LOADING_ENCODE_LIQPAY_PRIVATE_KEY = "SET_IS_LOADING_ENCODE_LIQPAY_PRIVATE_KEY"
}

export interface SetPublicKeyAction {
    type: OrgSettingsEnum.SET_PUBLIC_KEY,
    payload: string
}

export interface SetEncryptedPrivateKeyAction {
    type: OrgSettingsEnum.SET_ENCRYPTED_PRIVATE_KEY,
    payload: string
}

export interface SetIsLoadingLiqpaySettingsAction {
    type: OrgSettingsEnum.SET_IS_LOADING_LIQPAY_SETTINGS,
    payload: boolean
}

export interface SetIsLoadingSaveLiqpaySettingsAction {
    type: OrgSettingsEnum.SET_IS_LOADING_SAVE_LIQPAY_SETTINGS,
    payload: boolean
}

export interface SetIsLoadingEncodeLiqpayPrivateKeyAction {
    type: OrgSettingsEnum.SET_IS_LOADING_ENCODE_LIQPAY_PRIVATE_KEY,
    payload: boolean
}

export type OrgSettingsAction =
    SetPublicKeyAction |
    SetEncryptedPrivateKeyAction |
    SetIsLoadingLiqpaySettingsAction |
    SetIsLoadingSaveLiqpaySettingsAction |
    SetIsLoadingEncodeLiqpayPrivateKeyAction;
