import React, {FunctionComponent} from 'react';
import {Skeleton} from "antd";
import classes from "./FeedbackPageBarChart.module.scss";

interface FeedbackPageBarChartProps {
    grades: { [key: number]: number };
    loading?: boolean;
}

const FeedbackPageBarChart: FunctionComponent<FeedbackPageBarChartProps> = (props) => {
    const gradesPercents = () => {
        const max = Math.max(...Object.values(props.grades || {})) || 1;
        const percents: { [key: number]: number } = {};
        Object.keys(props.grades || {}).forEach((key) => {
            percents[Number(key)] = (props.grades[parseInt(key)] / max) * 100;
        });
        return percents;
    }

    return (
        <div className={classes.rating__bar__chart}>
            {props.loading
                ?
                <Skeleton className={classes.rating__bar__chart__item} active/>
                :
                [5, 4, 3, 2, 1].map((value) => (
                    <div className={classes.rating__bar__chart__item} key={value}>
                        <div className={classes.rating__bar__chart__item__title}>{value}</div>
                        <div className={classes.rating__bar__chart__item__bar}>
                            <div
                                className={classes.rating__bar__chart__item__bar__progress}
                                style={{
                                    width: `${gradesPercents()[value]}%`,
                                    backgroundColor: value < 4 ? "rgb(255, 107, 107)" : "rgb(0, 204, 153)"
                                }}
                            ></div>
                        </div>
                        <div
                            className={classes.rating__bar__chart__item__rate}>{props?.grades?.[value] || 0}</div>
                    </div>
                ))
            }
        </div>
    );
};

export default FeedbackPageBarChart;
