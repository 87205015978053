import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {InputText} from "../input/inputText";
import {OneVision} from "../../models/payments/oneVision";
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";
import {useActions} from "../../migration/shared/lib/hooks/useActions";

export function LiqPayPaymentModule() {
    const navigate = useNavigate();
    const {i18n} = useGlobalContext();
    const {user} = useUserContext();
    const {
        publicKey,
        encryptedPrivateKey,
        isLoadingLiqpaySettings,
        isLoadingEncodeLiqpayPrivateKey,
        isLoadingSaveLiqpaySettings
    } = useTypedSelector(state => state.orgSettings);
    const {fetchLiqpaySettings, encodeLiqpayPrivateKey, saveLiqpayPaymentSettings} = useActions();

    const [liqpay, setLiqpay] = useState<OneVision>({orgId: "", apiKey: "", encryptedSecretKey: ""});
    const [secretKey, setSecretKey] = useState<string>("");

    function encryptSecretKey() {
        encodeLiqpayPrivateKey(
            {
                privateKey: secretKey,
                // liqPayEncryptionKey: liqpay.encryptedSecretKey,
            },
            {navigate: navigate}
        );
    }

    function onChangeApiKey(v: string) {
        setLiqpay({...liqpay, apiKey: v});
    }

    function onRemoveEncryptionKey() {
        setLiqpay({...liqpay, encryptedSecretKey: ""});
    }

    function onSave() {
        saveLiqpayPaymentSettings(
            {
                orgId: user?.org?.id || "",
                publicKey: liqpay.apiKey,
                encryptedPrivateKey: liqpay.encryptedSecretKey,
            },
            {navigate: navigate}
        );
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchLiqpaySettings(user?.org?.id || "", controller, {navigate: navigate});
        return () => controller.abort();
        // eslint-disable-next-line
    }, [user?.org]);

    useEffect(() => {
        setLiqpay({
            apiKey: publicKey,
            encryptedSecretKey: encryptedPrivateKey,
            orgId: user?.org?.id || ""
        });
    }, [publicKey, encryptedPrivateKey, user]);

    return (
        <FormWithLoader isloading={isLoadingLiqpaySettings} className="form">
            {
                !!liqpay.encryptedSecretKey && (
                    <InputText label={i18n.api_key()} value={liqpay.apiKey} onChange={onChangeApiKey} isValidate={true}/>
                )
            }
            {liqpay.encryptedSecretKey === ""
                ?
                <InputText label={i18n.secret_key()} value={secretKey} onChange={setSecretKey} isValidate={true}/>
                :
                <div className="control">
                    <label className="control__label">{i18n.encrypted_key()}</label>
                    <div className={"iiko_Tag"}>
                        <div
                            className={"control__input"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                textOverflow: "clip",
                                overflow: "hidden"
                            }}
                        >
                            {liqpay.encryptedSecretKey}
                        </div>
                        <DeleteSVG onClick={onRemoveEncryptionKey}/>
                    </div>
                </div>
            }
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    {!liqpay.encryptedSecretKey
                        ?
                        <SubmitButton
                            onSubmit={encryptSecretKey}
                            label={i18n.encode()}
                            disabled={false} isLoading={isLoadingEncodeLiqpayPrivateKey}
                            modal={true}
                        />
                        :
                        <SubmitButton
                            onSubmit={onSave}
                            label={i18n.save()}
                            disabled={false}
                            isLoading={isLoadingSaveLiqpaySettings}
                            modal={true}
                        />
                    }
                    <a href={RouteNames.SETTINGS} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </a>
                </div>
            </div>
        </FormWithLoader>
    )
}
