import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {IOrder} from "../../models/orders/order";

export function useOrders() {
    const {api, lastOrder} = useGlobalContext()
    const {currentMenuId, menu} = useUserContext()

    const [orders, setOrders] = useState<IOrder[] | null>(null)
    const [ordersFound, setOrdersFound] = useState<boolean>(false)
    const [pageSize] = useState<number>(20)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [fetchInfo, setFetchInfo] = useState<{ page: number, find: string }>({page: 0, find: ""})
    const [trigger, setTrigger] = useState<boolean>(false)

    //Modal
    const [orderModalInfo, setOrderModalInfo] = useState<{ order: IOrder | null, active: boolean }>({
        order: null,
        active: false
    })

    const [iikoOrderModalInfo, setIikoOrderModalInfo] = useState<{ order: IOrder | null, active: boolean }>({
        order: null,
        active: false
    })

    const [ordersLoading, setOrdersLoading] = useState<boolean>(false)


    useEffect(() => {
        const controller = new AbortController()
        api.getOrders({
            controller,
            setLoading: setOrdersLoading
        }, currentMenuId, pageSize, pageSize * fetchInfo.page, fetchInfo.find).then((resp) => {
            if (resp.data) {
                setOrders(resp.data.orders)
                setTotalCount(resp.data.count)
                if (resp.data.count > 0) {
                    setOrdersFound(true)
                }
            }
        })
        return () => controller.abort()
    }, [currentMenuId, fetchInfo, lastOrder, trigger])

    useEffect(() => {
        if (orderModalInfo.active || iikoOrderModalInfo.active) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [orderModalInfo, iikoOrderModalInfo])

    function handleFind(value: string) {
        setFetchInfo({page: 0, find: value})
    }

    function setPage(page: number) {
        setFetchInfo({...fetchInfo, page})
    }

    return {
        handleFind,
        ordersFound,
        page: fetchInfo.page,
        menu,
        orderModalInfo,
        iikoOrderModalInfo,
        orders,
        pageSize,
        totalCount,
        setOrderModalInfo,
        setIikoOrderModalInfo,
        setPage,
        setOrders,
        ordersLoading,
        trigger,
        setTrigger
    }
}
