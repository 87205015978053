import Select from 'react-select'
import {options} from "../../utils/options/options";
import {useOrgSettings} from "../../hooks/orgSettings/orgSettings";
import {InputText} from "../input/inputText";
import React from "react";
import {CropperModal} from "../modal/cropperModal";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {ImageUploadComponent} from "../input/imageUploadComponent";
import {CocaColaSettings} from "../../migration/pages/user/menu/cola/components/CocaColaSettings";


export function OrgSettingsForm() {
    const info = useOrgSettings()
    const {i18n} = useGlobalContext()
    return (
        <form className="form">
            <InputText label={i18n.org_name()} value={info.org.name} onChange={info.handleChangeName}
                       placeholder={i18n.enter_name()} isValidate={true}/>
            <InputText label={i18n.org_address()} value={info.org.address}
                       onChange={info.handleChangeAddress} placeholder={i18n.enter_address()} isValidate={true}/>

            <ImageUploadComponent title={i18n.org_logo()} ImgType={"orgLogos"}
                                  img={info.org.logoImg} imgUploading={info.uploadLoading}
                                  message={i18n.image_upload_hint_std()}
                                  onDeleteImage={info.handleDeleteLogoImg}
                                  onUploadImage={info.handleUploadLogoImg}
                                  type={"-logo"}
            />


            <InputText label={i18n.subdomain()} value={info.org.subdomain || ""}
                       onChange={(subd) => {
                           info.handleChangeSubdomain(subd)
                       }}
                       placeholder={i18n.enter_name()} isValidate={true}/>

            <p className="tx-gray tx-13">{i18n.time_needed_for_subdomain()}</p>

            <div className="control">
                <div className="control__label">{i18n.default_menu_lang()}</div>
                <Select options={options}
                        isOptionSelected={(option) => option.value == info.org.defaultLang}
                        value={options.filter((item) => item.value == info.org.defaultLang)}
                        onChange={(v) => v ? info.handleChangeDefaultLang(v.value) : null}
                />
            </div>

            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.handleSave} label={i18n.save()}
                                  disabled={!info.currentMenuId} isLoading={info.saveLoading}/>
                </div>
            </div>
            <CocaColaSettings type={"org"}/>
            <CropperModal modal={info.modal} selectedImage={info.selectedLogoImage} setModal={info.setModal}
                          saveImage={info.UploadLogoImage} setSelectImage={info.setSelectedLogoImage}/>
        </form>
    )
}
