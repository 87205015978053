import React from "react";
import {useI18nText} from "../../hooks/input/i18nText";
import {LangKey} from "../../hooks/globalContext";
import {I18nTranslate} from "../../utils/translate/translate";

interface TextAreaI18nProps {
    label?: string,
    isRequired?: boolean
    values: { [key: string]: string },
    setValues: (c: { [key: string]: string }) => void,
    small?: boolean
    allLangs?: LangKey[]
    lang: LangKey
    isStaff: boolean
    onBlur?: () => void
    onTranslate?: () => void
}

export function TextAreaI18n(props: TextAreaI18nProps) {
    const info = useI18nText(props.allLangs || [], props.lang);
    return (
        <div className="control">
            {props.label ? <label className="control__label">{props.label}</label> : null}
            {props.isRequired ? <span className="required-star">*</span> : null}
            <div style={{display: "flex"}}>
                <textarea
                    className={props.small ? "control__textarea small-textarea" : "control__textarea"}
                    value={props.values[info.langs[0]] || ""}
                    onChange={(e) => props.setValues({
                        ...props.values,
                        [info.langs[0]]: e.currentTarget.value
                    })}
                    onBlur={props.onBlur}
                ></textarea>
                {info.langs.length > 1 ?
                    <>
                        <div className="button -gray" style={{width: "10%"}}
                             onClick={() => info.setIsActive(!info.isActive)}
                        >
                            <p className={"userLang"} aria-expanded={info.isActive}>
                                {info.langs[0]}
                            </p>
                        </div>
                        <button className="button -gray" style={{width: "40px", padding: "10px"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    I18nTranslate(props.values || {}, info.langs[0], info.langs, props.setValues)
                                    props?.onTranslate && props.onTranslate()
                                }}
                        >
                            <svg width="64px" height="64px" style={{margin: 0}} viewBox="0 0 21 21"
                                 xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g fill="none" fillRule="evenodd" stroke="#646D7C" strokeLinecap="round"
                                       strokeLinejoin="round" transform="translate(2 2)">
                                        <path
                                            d="m16.5 8.5v-6c0-1.1045695-.8954305-2-2-2h-6c-1.1045695 0-2 .8954305-2 2v6c0 1.1045695.8954305 2 2 2h6c1.1045695 0 2-.8954305 2-2z"></path>
                                        <path
                                            d="m4.5 6.50344846h-2.00001427c-1.1045695 0-2 .8954305-2 2v5.99943324c0 1.1045695.8954305 2 2 2h.00345627l6.00001428-.0103718c1.10321833-.0019065 1.99654372-.8967771 1.99654372-1.999997v-1.9925129"></path>
                                        <g transform="translate(2.502 9.5)">
                                            <path d="m2.998 1.003h-3"></path>
                                            <path
                                                d="m4.49841597 2.5c-.33333333.33333333-.66666667.66666667-1 1s-1.16666667.83333333-2.5 1.5"></path>
                                            <path
                                                d="m.99841597 1.00316806c.33333333 1.16613866.83333333 1.99894398 1.5 2.49841597s1.5.99894398 2.5 1.49841597"></path>
                                        </g>
                                        <g transform="translate(8.5 2.5)">
                                            <path d="m3 0-3 6"></path>
                                            <path d="m3 0 3 6"></path>
                                            <path d="m3 2v4" transform="matrix(0 1 -1 0 7 1)"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </> : null}
            </div>
            {info.isActive ? info.langs.filter((l) => l !== info.langs[0]).map((l) => {
                return (
                    <div style={{display: "flex"}} key={props.label + l}>
                        <textarea
                            className="control__textarea small-textarea"
                            value={props.values[l] || ""}
                            onChange={(e) => props.setValues({...props.values, [l]: e.currentTarget.value})}
                            onBlur={props.onBlur}
                        ></textarea>
                        <div className="button -disabled" style={{width: "10%"}}>
                            {l}
                        </div>
                    </div>
                )
            }) : null}
        </div>
    )
}
