import React from "react";


interface ICustomCategorySelectProps {
    options: {id:string,name:string, values: { id:string, name:string }[]}[]
    value: string
    onChange: (value: string) => void
    label: string
}

export function CustomCategorySelect(props: ICustomCategorySelectProps) {
    return (

        <div className="control">
            <label className="control__label">{props.label}</label>

            <select className="control__input"
                    value={props.value}
                    onChange={(e) => props.onChange(e.currentTarget.value)}
            >

                {props.options.map((value) => {
                    return (
                        <optgroup key={value.id} label={value.name}>
                            {(value.values || []).map((option) => {
                                return (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                )
                            })}
                        </optgroup>
                    )
                })}
            </select>

        </div>
    )
}