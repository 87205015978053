import {AxiosResponse} from "axios";
import {IikoApiKey} from "migration/entities/iikoApiKey";
import {IikoApiKey as ApiKey} from "migration/entities/baseAliases";
import {IikoStopLists} from "migration/entities/iikoStopLists";
import {IikoPaymentType} from "migration/entities/iikoPaymentType";
import {WebhookSettings} from "migration/entities/webhookSettings";
import {IikoNomenclature} from "migration/entities/iikoNomenclature";
import {IikoMenuSettings} from "migration/entities/iikoMenuSettings";
import {IikoOrganizations} from "migration/entities/iikoOrganizations";
import {IikoTerminalGroups} from "migration/entities/iikoTerminalGroups";
import {IikoRestaurantSections} from "migration/entities/iikoRestaurantSections";
import {IikoOrganizationSettings} from "migration/entities/iikoOrganizationSettings";
import {
    IikoOrgId, IikoPaymentTypeId,
    IikoProductCategoryId,
    IikoProductId, IikoRestaurantSectionTableId, IikoTerminalGroupId,
    ItemId,
    MenuId,
    OrgId,
    PlacementSlug
} from "migration/entities/baseAliases";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";
import {IikoExternalMenu} from "../../../../../entities/iikoExternalMenu";
import {IikoExternalMenuInfo} from "../../../../../entities/iikoExternalMenuInfo";

export interface getIikoApiKeyReq {
    "organizationId": OrgId
}

export interface getIikoNomenclatureReq {
    "iikoOrganizationId": IikoOrgId,
    "organizationId": OrgId
}

export interface getIikoOrganizationsReq {
    "organizationId": OrgId
}

export interface getIikoPaymentTypesReq {
    "organizationId": OrgId
}

export interface getIikoRestaurantSectionsReq {
    "organizationId": OrgId
}

export interface getIikoStopListsReq {
    "organizationId": OrgId
}

export interface getIikoTerminalGroupsReq {
    "organizationId": OrgId
}

export interface getWebhookSettingsReq {
    "menuId": MenuId,
    "organizationId": OrgId
}

export interface getIikoMenuSettingsReq {
    "menuId": MenuId,
}

export interface getIikoOrganizationSettingsReq {
    "organizationId": OrgId,
}

export interface getIikoExternalMenuReq {
    iikoExternalMenuId: string,
    iikoOrganizationId: string,
    iikoPriceCategoryId: string | null,
    organizationId: string,
}

export interface getIikoExternalMenuInfoReq {
    organizationId: string,
}

export interface cmdConfigWebhookSettingsReq {
    "menuId": MenuId,
    "organizationId": OrgId
}

export interface cmdDeleteMenuIikoSettingsReq {
    "menuId": MenuId,
    "organizationId": OrgId
}

export interface cmdDeleteOrganizationIikoSettingsReq {
    "organizationId": OrgId,
}

export interface cmdImportIikoProductsReq {
    "menuId": string,
    "organizationId": string,
    "iikoProducts": {
        [iikoProductCategoryId: IikoProductCategoryId]: IikoProductId[],
    }
}

export interface cmdImportIikoProductsRes {
    "notFoundProductIds": string[],
    "notFoundCategoryIds": string[],
    "alreadyBoundProductIds": string[],
}

export interface cmdSaveIikoProductBindingsReq {
    "menuId": MenuId,
    "organizationId": OrgId,
    "productBindings": {
        [itemId: ItemId]: IikoProductId,
    }
}

export interface cmdSaveIikoTableBindingsReq {
    "menuId": MenuId,
    "organizationId": OrgId,
    "tableBindings": {
        [placementSlug: PlacementSlug]: IikoRestaurantSectionTableId,
    }
}

export interface cmdSaveIikoMenuSettingsReq {
    "menuId": MenuId,
    "organizationId": OrgId,
    "iikoOrganizationId": IikoOrgId,
    "iikoPaymentTypeId": IikoPaymentTypeId | null,
    "iikoTerminalGroupId": IikoTerminalGroupId,
    "iikoExternalMenuId"?: string | null,
    "iikoPriceCategoryId"?: string | null,
    "mustApproveOrdersBeforeSend": boolean,
    "mustIgnoreStopLists": boolean,
    "mustSendDeliveryOrdersForCouriers": boolean,
}

export interface cmdSaveIikoOrganizationSettingsReq {
    "apiKey": ApiKey,
    "organizationId": OrgId
}

export interface cmdApproveIikoOrderReq {
    "menuId": string,
    "orderId": string,
    "organizationId": string,
}

export interface cmdCancelIikoDeliveryOrderReq {
    "menuId": string,
    "orderId": string,
    "organizationId": string
}

export default class IikoService {
    // ==================================================== QUERIES ====================================================
    static async getIikoApiKey(request: getIikoApiKeyReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoApiKey> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoApiKey> | ErrorResponse>(ApiRoutes.IIKO_API_KEY, request, {signal: controller.signal});
    }

    static async getIikoNomenclature(request: getIikoNomenclatureReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoNomenclature> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoNomenclature> | ErrorResponse>(ApiRoutes.IIKO_NOMENCLATURE, request, {signal: controller.signal});
    }

    static async getIikoOrganizations(request: getIikoOrganizationsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoOrganizations> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoOrganizations> | ErrorResponse>(ApiRoutes.IIKO_ORGANIZATIONS, request, {signal: controller.signal});
    }

    static async getIikoPaymentTypes(request: getIikoPaymentTypesReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoPaymentType> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoPaymentType> | ErrorResponse>(ApiRoutes.IIKO_PAYMENT_TYPES, request, {signal: controller.signal});
    }

    static async getIikoRestaurantSections(request: getIikoRestaurantSectionsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoRestaurantSections> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoRestaurantSections> | ErrorResponse>(ApiRoutes.IIKO_RESTAURANT_SECTIONS, request, {signal: controller.signal});
    }

    static async getIikoStopLists(request: getIikoStopListsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoStopLists> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoStopLists> | ErrorResponse>(ApiRoutes.IIKO_STOP_LISTS, request, {signal: controller.signal});
    }

    static async getIikoTerminalGroups(request: getIikoTerminalGroupsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoTerminalGroups> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoTerminalGroups> | ErrorResponse>(ApiRoutes.IIKO_TERMINAL_GROUPS, request, {signal: controller.signal});
    }

    static async getWebhookSettings(request: getWebhookSettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<WebhookSettings> | ErrorResponse>> {
        return $api.post<SuccessResponse<WebhookSettings> | ErrorResponse>(ApiRoutes.IIKO_WEBHOOKS, request, {signal: controller.signal});
    }

    static async getIikoMenuSettings(request: getIikoMenuSettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoMenuSettings> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoMenuSettings> | ErrorResponse>(ApiRoutes.MENU_IIKO_SETTINGS, request, {signal: controller.signal});
    }

    static async getIikoOrganizationSettings(request: getIikoOrganizationSettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoOrganizationSettings> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoOrganizationSettings> | ErrorResponse>(ApiRoutes.ORGANIZATION_IIKO_SETTINGS, request, {signal: controller.signal});
    }

    static async getIikoExternalMenu(request: getIikoExternalMenuReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoExternalMenu> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoExternalMenu> | ErrorResponse>(ApiRoutes.IIKO_EXTERNAL_MENU, request, {signal: controller.signal});
    }

    static async getIikoExternalMenuInfo(request: getIikoExternalMenuInfoReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<IikoExternalMenuInfo> | ErrorResponse>> {
        return $api.post<SuccessResponse<IikoExternalMenuInfo> | ErrorResponse>(ApiRoutes.IIKO_EXTERNAL_MENU_INFO, request, {signal: controller.signal});
    }

    // ==================================================== COMMANDS ===================================================
    static async cmdConfigWebhookSettings(request: cmdConfigWebhookSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.CONFIGURE_IIKO_WEBHOOK, request);
    }

    static async cmdDeleteMenuIikoSettings(request: cmdDeleteMenuIikoSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.DELETE_MENU_IIKO_SETTINGS, request);
    }

    static async cmdDeleteOrganizationIikoSettings(request: cmdDeleteOrganizationIikoSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.DELETE_ORGANIZATION_IIKO_SETTINGS, request);
    }

    static async cmdImportIikoProducts(request: cmdImportIikoProductsReq): Promise<AxiosResponse<SuccessResponse<cmdImportIikoProductsRes> | ErrorResponse>> {
        return $api.post<SuccessResponse<cmdImportIikoProductsRes> | ErrorResponse>(ApiRoutes.IMPORT_IIKO_PRODUCTS, request);
    }

    static async cmdSaveIikoProductBindings(request: cmdSaveIikoProductBindingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_IIKO_PRODUCTS_BINDINGS, request);
    }

    static async cmdSaveIikoTableBindings(request: cmdSaveIikoTableBindingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_IIKO_TABLE_BINDINGS, request);
    }

    static async cmdSaveMenuIikoSettings(request: cmdSaveIikoMenuSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_MENU_IIKO_SETTINGS, request);
    }

    static async cmdSaveOrganizationIikoSettings(request: cmdSaveIikoOrganizationSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_ORGANIZATION_IIKO_SETTINGS, request);
    }

    static async cmdApproveIikoOrders(request: cmdApproveIikoOrderReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.APPROVE_IIKO_ORDER, request);
    }

    static async cmdRetryIikoOrders(request: cmdApproveIikoOrderReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.RETRY_IIKO_ORDER, request);
    }

    static async cmdCancelIikoDeliveryOrder(request: cmdCancelIikoDeliveryOrderReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.CANCEL_IIKO_DELIVERY_ORDER, request);
    }
}
