import {FunctionComponent, useEffect} from "react";
import {useGlobalContext} from "../../../../hooks/globalContext";
import {LoginForm} from "./components/LoginForm";

export const Login: FunctionComponent = () => {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.body.classList.remove("modal-open")
        document.title = i18n.login()
    }, [i18n])
    return (
        <>
            <section id="app" className="login">
                <LoginForm/>
                <div className="login__bottom">
                    <div className="login__website">
                        kamiqr.com
                    </div>
                    <div className="login__copyright">2023, kami team</div>
                </div>
            </section>
        </>
    )
}
