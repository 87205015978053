export enum ColaProductKeyEnum {
    COCA_COLA = "coca-cola",
    FANTA = "fanta",
    SPRITE = "sprite",
    SCHWEPPES = "schweppes",
    PIKO = "piko",
    BONAQUA = "bonaqua",
    FUSE_TEA = "fuse-tea",
}

export interface CocaColaState {
    isCocaColaClient: boolean;
    isCurrentCategoryCocaCola: boolean;
    isCurrentProductCocaCola: boolean;
    currentProductKey: ColaProductKeyEnum;
}

export enum ColaActionEnum {
    SET_IS_COCA_COLA_CLIENT = "SET_IS_COCA_COLA_CLIENT",
    SET_IS_CURRENT_CATEGORY_COCA_COLA = "SET_IS_CURRENT_CATEGORY_COCA_COLA",
    SET_IS_CURRENT_PRODUCT_COCA_COLA = "SET_IS_CURRENT_PRODUCT_COCA_COLA",
    SET_CURRENT_PRODUCT_KEY = "SET_CURRENT_PRODUCT_KEY",
}

export interface SetIsCocaColaClientAction {
    type: ColaActionEnum.SET_IS_COCA_COLA_CLIENT;
    payload: boolean;
}

export interface SetIsCurrentCategoryCocaColaAction {
    type: ColaActionEnum.SET_IS_CURRENT_CATEGORY_COCA_COLA;
    payload: boolean;
}

export interface SetIsCurrentProductCocaColaAction {
    type: ColaActionEnum.SET_IS_CURRENT_PRODUCT_COCA_COLA;
    payload: boolean;
}

export interface SetCurrentProductKeyAction {
    type: ColaActionEnum.SET_CURRENT_PRODUCT_KEY;
    payload: ColaProductKeyEnum;
}

export type ColaAction =
    SetIsCocaColaClientAction |
    SetIsCurrentCategoryCocaColaAction |
    SetIsCurrentProductCocaColaAction |
    SetCurrentProductKeyAction;
