import {useEffect, useMemo} from "react";
import {debounce, throttle} from "../utils/scripts";

export const useDebounce = <A = unknown, R = void>(
    fn: (args: A) => R,
    ms: number
): ((args: A) => Promise<R>) => {
    const [debouncedFun, teardown] = useMemo(() => debounce<A, R>(fn, ms), []);

    useEffect(() => () => teardown(), []);

    return debouncedFun;
};

export const useThrottle = <A = unknown, R = void>(
    fn: (args: A) => R,
    ms: number
): ((args: A) => Promise<R>) => {
    const [throttledFunc, teardown] = useMemo(() => throttle<A, R>(fn, ms), []);

    useEffect(() => () => teardown(), []);

    return throttledFunc;
}