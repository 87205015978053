import React, {FunctionComponent, useEffect, useState} from 'react';
import {isEqual} from "lodash";
import {v4 as uuivv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {Button, Form, Input, Modal, Select, Skeleton, Tag} from "antd";
import {GroupAt, UserAt} from "migration/entities/telegramPoolOfTenant";
import {ReceiverList, TgGroup, TgUser} from "migration/entities/receiverList";
import {txt} from "migration/shared/lib/core/i18ngen";
import {rules} from "migration/shared/lib/form-rules/rules";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {validateEmail} from "migration/shared/lib/email/validateEmail";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {options} from "../../../../../../../utils/options/options";
import {DeleteOutlined} from '@ant-design/icons';
import classes from "./NotificationsReceivers.module.scss";

export const NotificationsReceivers: FunctionComponent = () => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser} = useTypedSelector(state => state.user);
    const {
        receiverLists,
        isLoadingGetTelegramPoolOfTenant,
        isLoadingGetAllReceiverLists,
    } = useTypedSelector(state => state.generalNotification);
    const {getTelegramPoolOfTenant, getAllReceiverLists, createReceiverList, getFeedbacksList} = useActions();
    const [form] = useForm();
    const [isModalCreate, setIsModalCreate] = useState(false);

    const addReceiverList = async () => {
        if (!form.getFieldValue("name")) {
            Notify.Info({title: txt.please_enter_receiver_list_name[currentLang], message: ""});
            return;
        }
        if (!form.getFieldValue("lang")) {
            Notify.Info({title: txt.please_select_lang[currentLang], message: ""});
            return;
        }
        await createReceiverList({
            lang: form.getFieldValue("lang"),
            name: form.getFieldValue("name"),
            receiverListId: uuivv4()
        }, {navigate});
        setIsModalCreate(false);
    }

    useEffect(() => {
        const controller = new AbortController();
        getTelegramPoolOfTenant({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        getAllReceiverLists({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        getFeedbacksList({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.main}>
            <Button onClick={() => setIsModalCreate(true)} type={"primary"} style={{width: "fit-content"}}>
                {txt.add_receivers_list[currentLang]}
            </Button>

            <Modal
                open={isModalCreate}
                onOk={() => form.submit()}
                okText={txt.create[currentLang]}
                onCancel={() => setIsModalCreate(false)}
                title={txt.add_receivers_list[currentLang]}
            >
                <Form
                    form={form}
                    initialValues={{name: "", lang: currentUser?.org?.defaultLang}}
                    layout={"vertical"}
                    onFinish={addReceiverList}
                >
                    <Form.Item name={"lang"} rules={[rules.required(txt.please_select_lang[currentLang])]}>
                        <Select
                            placeholder={txt.enter_receiver_list_name[currentLang]}
                            options={options}
                        />
                    </Form.Item>
                    <Form.Item name={"name"} rules={[rules.required(txt.please_enter_receiver_list_name[currentLang])]}>
                        <Input placeholder={txt.enter_receiver_list_name[currentLang]}/>
                    </Form.Item>
                </Form>
            </Modal>

            {
                (isLoadingGetTelegramPoolOfTenant || isLoadingGetAllReceiverLists)
                    ?
                    Array(3).fill(0).map((_, i) => (
                        <ReceiverListItem receiverList={{} as ReceiverList} loading={true} key={i}/>
                    ))
                    :
                    !receiverLists.length
                        ? <h1 className={classes.main__empty}>{txt.empty[currentLang]}</h1>
                        : receiverLists.map((receiverList, index) => (
                            <ReceiverListItem receiverList={receiverList} loading={false} key={index}/>
                        ))
            }
        </div>
    );
};

interface ReceiverListProps {
    receiverList: ReceiverList;
    loading: boolean;
}

const ReceiverListItem: FunctionComponent<ReceiverListProps> = ({receiverList, loading}) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {feedbacksList} = useTypedSelector(state => state.feedbacksList);
    const {receiverLists} = useTypedSelector(state => state.generalNotification);
    const {
        deleteReceiverList,
        setReceiverLists,
        bindReceiverListToFeedbackPage,
        unbindReceiverListFromFeedbackPage
    } = useActions();

    const [selectedFeedbackPage, setSelectedFeedbackPage] = useState<string>("");

    const handleDelete = () => {
        deleteReceiverList({receiverListId: receiverList.id}, {navigate});
    }

    const handleOnSetEmails = (emails: string[]) => {
        const copyReceiverLists = [...receiverLists];
        const receiverListIndex = copyReceiverLists.findIndex(item => item.id === receiverList.id);
        copyReceiverLists[receiverListIndex].emails = emails || [];
        setReceiverLists(copyReceiverLists);
    }

    const handleOnBindReceiverListToFeedbackPage = (pageId: string) => {
        setSelectedFeedbackPage("");
        bindReceiverListToFeedbackPage(
            {receiverListId: receiverList.id, feedbackPageId: pageId},
            {navigate}
        );
    }

    const handleOnUnbindReceiverListFromFeedbackPage = (pageId: string) => {
        if (!pageId) return;
        unbindReceiverListFromFeedbackPage(
            {receiverListId: receiverList.id, feedbackPageId: pageId},
            {navigate}
        );
    }

    if (loading) return (
        <div className={classes.list}>
            <Skeleton/>
        </div>
    )
    return (
        <div className={classes.list}>
            <h2 className={classes.list__title}>{receiverList?.name}</h2>
            <div className={classes.list__container}>
                <div className={classes.list__container__block}>
                    <ReceiverListRow
                        title={txt.telegram_users[currentLang]}
                        listType={"users"}
                        receiverListId={receiverList?.id}
                        receivers={receiverList?.telegram?.users}
                    />
                    <ReceiverListRow
                        title={txt.telegram_groups[currentLang]}
                        listType={"groups"}
                        receiverListId={receiverList?.id}
                        receivers={receiverList?.telegram?.groups}
                    />
                    <ReceiverListRow
                        title={txt.email_receivers[currentLang]}
                        listType={"emails"}
                        receiverListId={receiverList?.id}
                        receivers={receiverList?.emails}
                        handleOnSetEmails={handleOnSetEmails}
                    />
                </div>
                <div className={classes.list__container__divider}></div>
                <div
                    className={classes.list__container__block}
                    style={{
                        justifyContent: "space-between",
                        alignItems: "flex-end"
                    }}
                >
                    <div className={classes.list__container__block__row}>
                        {receiverList?.usedBy?.map(item => (
                            <React.Fragment key={item.id}>
                                <div className={classes.list__container__block__row__item}>
                                    <div className={classes.list__container__block__row__item__upper}>
                                        <div className={classes.list__container__block__row__item__upper__text}>
                                            {item?.name}
                                        </div>
                                        <div
                                            onClick={() => handleOnUnbindReceiverListFromFeedbackPage(item.id)}
                                            className={classes.list__container__block__row__item__upper__delete}
                                        >
                                            <DeleteOutlined/>
                                        </div>
                                    </div>
                                    <div className={classes.list__container__block__row__item__lower}>
                                        {feedbacksList.find(feedbackPage => feedbackPage.id === item.id)?.slug}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}

                        <Select
                            value={selectedFeedbackPage}
                            onChange={(value) => handleOnBindReceiverListToFeedbackPage(value)}
                            options={[
                                {value: "", label: txt.select_feedback_page[currentLang]},
                                ...feedbacksList.filter(
                                    item => !receiverList.usedBy?.map(item => item.id).includes(item.id)
                                ).map(
                                    item => ({value: item.id, label: item.name})
                                ),
                            ]}
                        />
                    </div>
                    <Button
                        danger
                        type={"primary"}
                        style={{width: "fit-content"}}
                        onClick={handleDelete}
                    >
                        {txt.delete[currentLang]}
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface ReceiverListRowProps {
    title: string;
    listType: "users" | "groups" | "emails";
    receiverListId: string;
    receivers: (TgUser | TgGroup | string)[] | null;
    handleOnSetEmails?: (emails: string[]) => void;
}

const ReceiverListRow: FunctionComponent<ReceiverListRowProps> = ({receivers, listType, receiverListId, title}) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {telegramPoolOfTenant, receiverLists} = useTypedSelector(state => state.generalNotification);
    const {
        setReceiverLists,
        specifyTgUsersInReceiverList,
        specifyTgGroupsInReceiverList,
        specifyEmailsInReceiverList,
    } = useActions();
    const [initialValues, setInitialValues] = useState<(TgUser | TgGroup | string)[]>([]);

    const options = (): { value: string, label: string }[] => {
        switch (listType) {
            case "users":
                return telegramPoolOfTenant?.activeUsers?.map(item => ({
                    value: item.username,
                    label: item?.username || ""
                })) || [];
            case "groups":
                return telegramPoolOfTenant?.activeGroups?.map(item => ({
                    value: item.groupTitle,
                    label: item?.groupTitle || ""
                })) || [];
            case "emails":
                return [];
            default:
                return [];
        }
    }

    const handleOnTagsChange = (value: string[]) => {
        const copyReceiverLists = [...receiverLists];
        const receiverListIndex = copyReceiverLists.findIndex(item => item.id === receiverListId);
        switch (listType) {
            case "users":
                const tgUsers = (value).map(username => telegramPoolOfTenant?.activeUsers?.find(user => user.username === username));
                copyReceiverLists[receiverListIndex].telegram.users = tgUsers as UserAt[] || [];
                setReceiverLists(copyReceiverLists);
                break;
            case "groups":
                const tgGroups = (value).map(groupTitle => telegramPoolOfTenant?.activeGroups?.find(group => group.groupTitle === groupTitle));
                copyReceiverLists[receiverListIndex].telegram.groups = tgGroups as GroupAt[] || [];
                setReceiverLists(copyReceiverLists);
                break;
            case "emails":
                let isEmailValid = true;
                value.forEach(email => {
                    if (!validateEmail(email)) {
                        isEmailValid = false;
                        return;
                    }
                });
                if (!isEmailValid) {
                    Notify.Info({title: txt.email_is_not_valid[currentLang], message: ""});
                    break;
                }
                const emails = (value as string[]) || [];
                copyReceiverLists[receiverListIndex].emails = emails || [];
                setReceiverLists(copyReceiverLists);
                break;
        }
    }

    const handleSave = () => {
        const receiverListIndex = receiverLists.findIndex(item => item.id === receiverListId);
        switch (listType) {
            case "users":
                specifyTgUsersInReceiverList({
                    receiverListId: receiverListId,
                    tgUsers: [...receiverLists[receiverListIndex].telegram.users] || [],
                }, {navigate});
                setInitialValues(receiverLists[receiverListIndex].telegram.users || []);
                break;
            case "groups":
                specifyTgGroupsInReceiverList({
                    receiverListId: receiverListId,
                    tgGroups: [...receiverLists[receiverListIndex].telegram.groups] || [],
                }, {navigate});
                setInitialValues(receiverLists[receiverListIndex].telegram.groups || []);
                break;
            case "emails":
                specifyEmailsInReceiverList(
                    {receiverListId: receiverListId, emails: receiverLists[receiverListIndex].emails || []},
                    {navigate}
                );
                setInitialValues(receiverLists[receiverListIndex].emails || []);
                break;
        }
    }

    useEffect(() => {
        const receiverListIndex = receiverLists.findIndex(item => item.id === receiverListId);
        switch (listType) {
            case "users":
                setInitialValues(receiverLists[receiverListIndex].telegram?.users || []);
                break;
            case "groups":
                setInitialValues(receiverLists[receiverListIndex].telegram?.groups || []);
                break;
            case "emails":
                setInitialValues(receiverLists[receiverListIndex]?.emails || []);
                break;
        }
        // eslint-disable-next-line
    }, [listType]);

    return (
        <div className={classes.list__block__row}>
            <div className={classes.list__block__row__title}>{title}</div>
            {listType === "emails"
                ?
                <Select
                    mode={"tags"}
                    value={receivers || []}
                    onChange={(value) => handleOnTagsChange(value as string[])}
                    suffixIcon={null}
                    dropdownStyle={{display: "none"}}
                    tokenSeparators={[",", " ", ";", "\n"]}
                />
                :
                <Select
                    mode={"multiple"}
                    value={receivers?.map(item => {
                        const temp = item as TgUser | TgGroup;
                        return "username" in temp ? temp.username : temp.groupTitle;
                    }) || []}
                    onChange={(value) => handleOnTagsChange(value)}
                    options={options()}
                    tokenSeparators={[",", " ", ";", "\n"]}
                    onDropdownVisibleChange={(open) => {
                        const diff: string[] = [];
                        const arr1 = options().map(item => item.value);
                        const arr2 = receivers?.map(item => {
                            const temp = item as TgUser | TgGroup;
                            return "username" in temp ? temp.username : temp.groupTitle;
                        }) || [];
                        arr1.forEach(item => {
                            if (!arr2.includes(item)) {
                                diff.push(item);
                            }
                        });
                        arr2.forEach(item => {
                            if (!arr1.includes(item)) {
                                diff.push(item);
                            }
                        });
                        const receiverListIndex = receiverLists.findIndex(item => item.id === receiverListId);
                        switch (listType) {
                            case "users":
                                receiverLists[receiverListIndex].telegram.users = receiverLists[receiverListIndex].telegram.users?.filter(item => !diff.includes(item.username)) || [];
                                break;
                            case "groups":
                                receiverLists[receiverListIndex].telegram.groups = receiverLists[receiverListIndex].telegram.groups?.filter(item => !diff.includes(item.groupTitle)) || [];
                                break;
                        }
                        setReceiverLists(receiverLists);
                    }}
                    tagRender={({label, value, closable, onClose}) => {
                        const isValueExistsInOptions = options().map(item => item.value).includes(value);
                        return (
                            <Tag
                                style={{
                                    backgroundColor: isValueExistsInOptions ? "rgba(0, 0, 0, 0.04)" : "rgb(239,20,20)",
                                    color: isValueExistsInOptions ? "rgba(0, 0, 0, 0.85)" : "white",
                                }}
                            >{label}</Tag>
                        )
                    }}
                />
            }

            <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                <Button
                    type={"primary"}
                    onClick={handleSave}
                    style={{width: "fit-content"}}
                    disabled={isEqual(initialValues, receivers || [])}
                >
                    {txt.save[currentLang]}
                </Button>
            </div>
        </div>
    )
}
