import {TenantCrossSelling} from "migration/entities/tenant";

export interface CrossSellingState {
    crossSellingMapping: TenantCrossSelling;
    isLoadingGetTenant: boolean;
}

export enum CrossSellingActionEnum {
    SET_TENANT = "SET_TENANT",
    SET_IS_LOADING_GET_TENANT = "SET_IS_LOADING_GET_TENANT",
}

export interface SetTenantAction {
    type: CrossSellingActionEnum.SET_TENANT,
    payload: TenantCrossSelling,
}

export interface SetIsLoadingGetTenant {
    type: CrossSellingActionEnum.SET_IS_LOADING_GET_TENANT,
    payload: boolean,
}

export type CrossSellingAction =
    SetTenantAction |
    SetIsLoadingGetTenant;
