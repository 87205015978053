import React from "react";
import {Outlet} from "react-router-dom";
import {HeaderStaff} from "../../components/base/headerStaff";
import {SiteBarStaff} from "../../components/base/siteBarStaff";

export function BaseStaff() {
    return (
        <>
            <HeaderStaff/>
            <SiteBarStaff/>
            <Outlet/>
        </>
    )
}
