import {FeedbackPageShortData} from "migration/entities/Feedback";

export interface FeedbacksListState {
    feedbacksList: FeedbackPageShortData[];

    isLoadingGetFeedbacksList: boolean;

    isLoadingCreateFeedbackPage: boolean;
    isLoadingDeleteFeedbackPage: boolean;
}

export enum FeedbacksListActionEnum {
    SET_FEEDBACKS_LIST = 'SET_FEEDBACKS_LIST',

    SET_IS_LOADING_GET_FEEDBACKS_LIST = 'SET_IS_LOADING_GET_FEEDBACKS_LIST',

    SET_IS_LOADING_CREATE_FEEDBACK_PAGE = 'SET_IS_LOADING_CREATE_FEEDBACK_PAGE',
    SET_IS_LOADING_DELETE_FEEDBACK_PAGE = 'SET_IS_LOADING_DELETE_FEEDBACK_PAGE',
}

export interface SetFeedbacksListAction {
    type: FeedbacksListActionEnum.SET_FEEDBACKS_LIST;
    payload: FeedbackPageShortData[];
}

export interface SetIsLoadingGetFeedbacksListAction {
    type: FeedbacksListActionEnum.SET_IS_LOADING_GET_FEEDBACKS_LIST;
    payload: boolean;
}

export interface SetIsLoadingCreateFeedbackPageAction {
    type: FeedbacksListActionEnum.SET_IS_LOADING_CREATE_FEEDBACK_PAGE;
    payload: boolean;
}

export interface SetIsLoadingDeleteFeedbackPageAction {
    type: FeedbacksListActionEnum.SET_IS_LOADING_DELETE_FEEDBACK_PAGE;
    payload: boolean;
}

export type FeedbacksListAction =
    SetFeedbacksListAction |
    SetIsLoadingGetFeedbacksListAction |
    SetIsLoadingCreateFeedbackPageAction |
    SetIsLoadingDeleteFeedbackPageAction;
