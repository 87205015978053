import {Bespoke, showMenuBtnKeys} from "../../models/menuStylize/bespoke";
import {useEffect, useState} from "react";
import {I18nString} from "../../models/lang/i18nString";
import {useGlobalContext, useUserContext} from "../globalContext";

export function useBespoke() {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()

    const [bespoke, setBespoke] = useState<Bespoke>({
        replaceTxts: {
            showMenuBtn: null,
            pmOneVisionOption: null
        },
        customIcons: {
            showMenuBtn: 'dish'
        },
        isUsingTips: false,
        inCartMessage: null,
        isHiddenCartTotalPrice: false
    })

    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController();
        if (currentMenuId) {
            api.getMenuBespoke({controller, setLoading}, currentMenuId).then((res) => {
                if (res.data) {
                    if (!res.data.customIcons.showMenuBtn) {
                        res.data.customIcons.showMenuBtn = 'dish'
                    }
                    setBespoke({
                        ...res.data,
                        replaceTxts: {
                            showMenuBtn: res.data.replaceTxts?.showMenuBtn || null,
                            pmOneVisionOption: res.data.replaceTxts?.pmOneVisionOption || null
                        }
                    })
                }
            })
        }
        return () => controller.abort();
    }, [currentMenuId])

    function onChangeShowMenuButton(txt: I18nString) {
        setBespoke(prev => ({
            ...prev,
            replaceTxts: {
                ...(prev.replaceTxts ? prev.replaceTxts : {}),
                showMenuBtn: txt,
                pmOneVisionOption: prev.replaceTxts?.pmOneVisionOption || null
            }
        }))
    }

    function onChangePmOneVisionOption(txt: I18nString) {
        setBespoke(prev => ({
            ...prev,
            replaceTxts: {
                ...(prev.replaceTxts ? prev.replaceTxts : {}),
                pmOneVisionOption: txt,
                showMenuBtn: prev.replaceTxts?.showMenuBtn || null
            }
        }))
    }

    function onChangeCustomIconsShowMenuBtn(key: showMenuBtnKeys) {
        setBespoke(prev => ({
            ...prev,
            customIcons: {
                ...prev.customIcons,
                showMenuBtn: key
            }
        }))
    }

    function onChangeInCartMessage(txt: I18nString) {
        setBespoke(prev => ({
            ...prev,
            inCartMessage: txt
        }))
    }

    function onChangeIsUsingTips(isUsingTips: boolean) {
        setBespoke(prev => ({
            ...prev,
            isUsingTips: isUsingTips,
        }))
    }

    function onChangeIsHiddenCartTotalPrice(isHiddenCartTotalPrice: boolean) {
        setBespoke(prev => ({
            ...prev,
            isHiddenCartTotalPrice: isHiddenCartTotalPrice,
        }))
    }


    function onSave() {
        api.saveMenuBespoke({setLoading: setSaveLoading}, currentMenuId, bespoke)
    }

    return {
        menu,
        isStaff: !!user?.isStaff,
        bespoke,
        currentMenuId,
        onChangePmOneVisionOption,
        onSave,
        onChangeShowMenuButton,
        onChangeCustomIconsShowMenuBtn,
        onChangeIsUsingTips,
        onChangeInCartMessage,
        onChangeIsHiddenCartTotalPrice,


        saveLoading,
        loading
    }
}