export interface SystemState {
    initialLocation: string;
}

export enum SystemActionEnum {
    SET_INITIAL_LOCATION = "SET_INITIAL_LOCATION",
}

export type SetInitialLocationAction = {
    type: SystemActionEnum.SET_INITIAL_LOCATION;
    payload: string;
}

export type SystemAction = SetInitialLocationAction;
