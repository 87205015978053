import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext} from "../../hooks/globalContext";
import React, {useEffect} from "react";
import {StaffForm} from "../../components/form/staffForm";

export function CreateStaff() {
    const {i18n} = useGlobalContext()
    let infos = [
        {link: "/staff/managers", title: i18n.managers(), index: "breadcrumbs-id-1"},
        {link: "/staff/managers/create", title: i18n.create_staff_user(), index: "breadcrumbs-id-2"}
    ]
    useEffect(() => {
        document.title = i18n.create_staff_user()
    }, [i18n])
    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={infos}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.create_staff_user()}</h1>
                    </div>
                </div>

                <div className="row">s
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">

                            <StaffForm/>

                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}
