export enum FoodChannelEnum {
    FULL_CYCLE = "food-channel:full-cycle",
    FAST_FOOD = "food-channel:fast-food",
    KIOSK = "food-channel:kiosk",
    PUB_BAR_NIGHTCLUB = "food-channel:pub_bar_nightclub",
    CANTEEN = "food-channel:canteen",
    CAFE = "food-channel:cafe",
    HOTEL = "food-channel:hotel",
    DARK_STORE = "food-channel:dark-store",
    CINEMA = "food-channel:cinema",
    KARAOKE = "food-channel:karaoke",
    DARK_KITCHEN = "food-channel:dark-kitchen",
}

export const orgTaggingOptions = [
    {value: null, label: "Не выбрано"},
    {value: FoodChannelEnum.FULL_CYCLE, label: "Ресторан полного цикла"},
    {value: FoodChannelEnum.FAST_FOOD, label: "Фастфуд"},
    {value: FoodChannelEnum.KIOSK, label: "Киоск"},
    {value: FoodChannelEnum.PUB_BAR_NIGHTCLUB, label: "Паб, бар, ночной клуб"},
    {value: FoodChannelEnum.CANTEEN, label: "Столовая"},
    {value: FoodChannelEnum.CAFE, label: "Кафе"},
    {value: FoodChannelEnum.HOTEL, label: "Отель"},
    {value: FoodChannelEnum.DARK_STORE, label: "Дарк стор"},
    {value: FoodChannelEnum.CINEMA, label: "Кинотеатр"},
    {value: FoodChannelEnum.KARAOKE, label: "Караоке"},
    {value: FoodChannelEnum.DARK_KITCHEN, label: "Дарк китчен"},
];

export enum OtherDrinkEnum {
    LEMONADE = "other-drink:lemonade",
    COMPOTE = "other-drink:compote",
    ALCOHOL_BEVERAGE = "other-drink:alcohol_beverage",
    NON_ALCO_COCKTAIL = "other-drink:non-alco_cocktail",
    AYRAN = "other-drink:ayran",
    TEA = "other-drink:tea",
    ICE_TEA = "other-drink:ice-tea",
    COFFEE = "other-drink:coffee",
    WATER = "other-drink:water",
    ENERGY_DRINK = "other-drink:energy_drink",
    CARBONATED = "other-drink:carbonated",
    FRUIT_DRINK = "other-drink:fruit_drink",
    SMOOTHIE = "other-drink:fresh/smoothie",
    KVASS = "other-drink:kvass",
    NATIONAL_DRINKS = "other-drink:national_drinks",
    TONIC = "other-drink:tonic",
    MILK = "other-drink:milk",
    JUICE = "other-drink:juice",
}

export const otherDrinkTaggingOptions = [
    {value: null, label: "Не выбрано"},
    {value: OtherDrinkEnum.LEMONADE, label: "Лимонад"},
    {value: OtherDrinkEnum.COMPOTE, label: "Компот"},
    {value: OtherDrinkEnum.ALCOHOL_BEVERAGE, label: "Алкогольный напиток"},
    {value: OtherDrinkEnum.NON_ALCO_COCKTAIL, label: "Безалкогольный коктейль"},
    {value: OtherDrinkEnum.AYRAN, label: "Айран"},
    {value: OtherDrinkEnum.TEA, label: "Чай"},
    {value: OtherDrinkEnum.ICE_TEA, label: "Ледяной чай"},
    {value: OtherDrinkEnum.COFFEE, label: "Кофе"},
    {value: OtherDrinkEnum.WATER, label: "Вода"},
    {value: OtherDrinkEnum.ENERGY_DRINK, label: "Энергетический напиток"},
    {value: OtherDrinkEnum.CARBONATED, label: "Газированный напиток"},
    {value: OtherDrinkEnum.FRUIT_DRINK, label: "Фруктовый напиток"},
    {value: OtherDrinkEnum.SMOOTHIE, label: "Смузи"},
    {value: OtherDrinkEnum.KVASS, label: "Квас"},
    {value: OtherDrinkEnum.NATIONAL_DRINKS, label: "Национальные напитки"},
    {value: OtherDrinkEnum.TONIC, label: "Тоник"},
    {value: OtherDrinkEnum.MILK, label: "Молоко"},
    {value: OtherDrinkEnum.JUICE, label: "Сок"},
];

export enum DrinkMaterialEnum {
    GLASS = "drink_material:glass",
    PLASTIC = "drink_material:plastic",
    METAL = "drink_material:metal",
    DRAFT = "drink_material:draft",
    OTHER = "drink_material:other",
}

export const drinkMaterialTaggingOptions = [
    {value: null, label: "Не выбрано"},
    {value: DrinkMaterialEnum.GLASS, label: "Стекло"},
    {value: DrinkMaterialEnum.PLASTIC, label: "Пластик"},
    {value: DrinkMaterialEnum.METAL, label: "Жестяная банка"},
    {value: DrinkMaterialEnum.DRAFT, label: "Разливное"},
    {value: DrinkMaterialEnum.OTHER, label: "Другое"},
];

export interface OrganizationsState {
    foodChannel: FoodChannelEnum | null;
    otherDrink: OtherDrinkEnum | null;
    drinkMaterial: DrinkMaterialEnum | null;
}

export enum OrganizationsActionEnum {
    SET_FOOD_CHANNEL = "SET_FOOD_CHANNEL",
    SET_OTHER_DRINK = "SET_OTHER_DRINK",
    SET_DRINK_MATERIAL = "SET_DRINK_MATERIAL",
}

export interface SetFoodChannelAction {
    type: OrganizationsActionEnum.SET_FOOD_CHANNEL;
    payload: FoodChannelEnum | null;
}

export interface SetOtherDrinkAction {
    type: OrganizationsActionEnum.SET_OTHER_DRINK;
    payload: OtherDrinkEnum | null;
}

export interface SetDrinkMaterialAction {
    type: OrganizationsActionEnum.SET_DRINK_MATERIAL;
    payload: DrinkMaterialEnum | null;
}

export type OrganizationsAction = SetFoodChannelAction | SetOtherDrinkAction | SetDrinkMaterialAction;
