import axios from "axios";
import {OrgId} from "migration/entities/baseAliases";
import {getCookie} from "../lib/cookie";

export interface AuthCredentials {
    accessToken: string;
    accessTokenExpiresAt: Date;
}

const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
    timeout: 60000,
    timeoutErrorMessage: "Timeout error",
});

export default $api;

export const $apiNltx = axios.create({
    baseURL: process.env.REACT_APP_NLTX_API_URL || "",
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
    timeout: 60000,
    timeoutErrorMessage: "Timeout error",
})

const $apiFormData = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "",
    withCredentials: true,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
    timeout: 60000,
    timeoutErrorMessage: "Timeout error",
});

export {$apiFormData};

const apiV2Axios = axios.create({
    baseURL: process.env.REACT_APP_V2_API_URL || "",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "With-Result": true,
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
    timeout: 60000,
    timeoutErrorMessage: "Timeout error",
});

apiV2Axios.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('accessToken');
    let tokenExpiresAt = new Date(localStorage.getItem('accessTokenExpiresAt') || "");
    if (
        token === null ||
        token === undefined ||
        token === "" ||
        token === "undefined" ||
        !tokenExpiresAt ||
        tokenExpiresAt < new Date()
    ) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_V2_API_URL}/cmd/force-generate-access-token`, {refreshToken: getCookie("kami-app-session")}, {withCredentials: true, headers: {"With-Result": true,}});
            localStorage.setItem('accessToken', response.data.data?.data?.accessToken);
            localStorage.setItem('accessTokenExpiresAt', response.data.data?.data?.accessTokenExpiresAt?.toString());
            token = response.data.data?.data?.accessToken;
        } catch(e) {
            console.log(e)
        }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

apiV2Axios.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    console.log(error.config)
    if (error.response?.status === 401 && !error.config._isRetry) {
        originalRequest.isRetry = true;
        try {
            const response = await axios.post(`${process.env.REACT_APP_V2_API_URL}/cmd/force-generate-access-token`, {refreshToken: getCookie("kami-app-session")}, {withCredentials: true, headers: {"With-Result": true,}});
            localStorage.setItem('accessToken', response.data.data?.data?.accessToken);
            localStorage.setItem('accessTokenExpiresAt', response.data.data?.data?.accessTokenExpiresAt.toString());
            return apiV2Axios.request(originalRequest);
        } catch (e) {
            console.log('Not authorized');
        }
    }
    throw error;
})

const $apiV2 = (orgId: OrgId) => {
    apiV2Axios.defaults.headers["Tenant-Id"] = orgId || "";
    return apiV2Axios;
};

export {$apiV2};

const apiV2FormDataAxios = axios.create({
    baseURL: process.env.REACT_APP_V2_API_URL || "",
    withCredentials: true,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
    timeout: 60000,
    timeoutErrorMessage: "Timeout error",
});

apiV2FormDataAxios.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('accessToken');
    let tokenExpiresAt = new Date(localStorage.getItem('accessTokenExpiresAt') || "");
    if (
        token === null ||
        token === undefined ||
        token === "" ||
        token === "undefined" ||
        !tokenExpiresAt ||
        tokenExpiresAt < new Date()
    ) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_V2_API_URL}/cmd/force-generate-access-token`, {refreshToken: getCookie("kami-app-session")}, {withCredentials: true, headers: {"With-Result": true,}});
            localStorage.setItem('accessToken', response.data.data?.data?.accessToken);
            localStorage.setItem('accessTokenExpiresAt', response.data.data?.data?.accessTokenExpiresAt?.toString());
            token = response.data.data?.data?.accessToken;
        } catch(e) {
            console.log(e)
        }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

apiV2FormDataAxios.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && error.config && !error.config._isRetry) {
        originalRequest.isRetry = true;
        try {
            const response = await axios.post(`${process.env.REACT_APP_V2_API_URL}/cmd/force-generate-access-token`, {refreshToken: getCookie("kami-app-session")}, {withCredentials: true, headers: {"With-Result": true,}});
            localStorage.setItem('accessToken', response.data.data?.data?.accessToken);
            localStorage.setItem('accessTokenExpiresAt', response.data.data?.data?.accessTokenExpiresAt.toString());
            return apiV2FormDataAxios.request(originalRequest);
        } catch (e) {
            console.log('Not authorized');
        }
    }
    throw error;
})

const $apiV2FormData = (orgId: OrgId) => {
    apiV2FormDataAxios.defaults.headers["Tenant-Id"] = orgId || "";
    return apiV2FormDataAxios;
};

export {$apiV2FormData};
