import {AppDispatch, RootState} from "migration/app/store";
import {RkeeperMenu} from "migration/entities/rkeeperMenu";
import {RkeeperAgents} from "migration/entities/rkeeperAgents";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {RkeeperRestaurant} from "migration/entities/rkeeperRestaurant";
import {RkeeperMenuSettings} from "migration/entities/rkeeperMenuSettings";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {
    CmdApproveRkeeperOrder, CmdDeleteMenuRkeeperSettings,
    CmdImportRkeeperProducts,
    CmdRetryRkeeperOrder,
    CmdSaveMenuRkeeperSettings,
    CmdSaveRkeeperProductBindings,
    CmdSaveRkeeperTableBindings,
    GetMenuRkeeperSettingsReq,
    GetRkeeperMenuReq,
    GetRkeeperRestaurantReq,
    RkeeperService
} from "../api/rkeeperService";
import {
    RkeeperStoreActions,
    SetRkeeperMenuSettingsAction,
    SetRkeeperAgentsAction,
    SetRkeeperMenuAction,
    SetRkeeperRestaurantAction,
    SetIsLoadingApproveRkeeperOrderAction,
    SetIsLoadingDeleteMenuRkeeperSettingsAction,
    SetIsLoadingImportRkeeperProductsAction,
    SetIsLoadingRetryRkeeperOrderAction,
    SetIsLoadingSaveMenuRkeeperSettingsAction,
    SetIsLoadingSaveRkeeperProductBindingsAction,
    SetIsLoadingSaveRkeeperTableBindingsAction,
    SetIsLoadingGetRkeeperMenuSettingsAction,
    SetIsLoadingGetRkeeperAgentsAction,
    SetIsLoadingGetRkeeperMenuAction,
    SetIsLoadingGetRkeeperRestaurantAction,
} from "./types";

export const RkeeperActionCreators = {
    setRkeeperMenuSettings: (payload: RkeeperMenuSettings): SetRkeeperMenuSettingsAction => ({
        type: RkeeperStoreActions.SET_RKEEPER_MENU_SETTINGS,
        payload
    }),
    setRkeeperAgents: (payload: RkeeperAgents[]): SetRkeeperAgentsAction => ({
        type: RkeeperStoreActions.SET_RKEEPER_AGENTS,
        payload
    }),
    setRkeeperMenu: (payload: RkeeperMenu): SetRkeeperMenuAction => ({
        type: RkeeperStoreActions.SET_RKEEPER_MENU,
        payload
    }),
    setRkeeperRestaurant: (payload: RkeeperRestaurant): SetRkeeperRestaurantAction => ({
        type: RkeeperStoreActions.SET_RKEEPER_RESTAURANT,
        payload
    }),

    setIsLoadingApproveRkeeperOrder: (payload: boolean): SetIsLoadingApproveRkeeperOrderAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_APPROVE_RKEEPER_ORDER,
        payload
    }),
    setIsLoadingDeleteMenuRkeeperSettings: (payload: boolean): SetIsLoadingDeleteMenuRkeeperSettingsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_DELETE_MENU_RKEEPER_SETTINGS,
        payload
    }),
    setIsLoadingImportRkeeperProducts: (payload: boolean): SetIsLoadingImportRkeeperProductsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_IMPORT_RKEEPER_PRODUCTS,
        payload
    }),
    setIsLoadingRetryRkeeperOrder: (payload: boolean): SetIsLoadingRetryRkeeperOrderAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_RETRY_RKEEPER_ORDER,
        payload
    }),
    setIsLoadingSaveMenuRkeeperSettings: (payload: boolean): SetIsLoadingSaveMenuRkeeperSettingsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_SAVE_MENU_RKEEPER_SETTINGS,
        payload
    }),
    setIsLoadingSaveRkeeperProductBindings: (payload: boolean): SetIsLoadingSaveRkeeperProductBindingsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_PRODUCT_BINDINGS,
        payload
    }),
    setIsLoadingSaveRkeeperTableBindings: (payload: boolean): SetIsLoadingSaveRkeeperTableBindingsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_TABLE_BINDINGS,
        payload
    }),

    setIsLoadingGetRkeeperMenuSettings: (payload: boolean): SetIsLoadingGetRkeeperMenuSettingsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU_SETTINGS,
        payload
    }),
    setIsLoadingGetRkeeperAgents: (payload: boolean): SetIsLoadingGetRkeeperAgentsAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_AGENTS,
        payload
    }),
    setIsLoadingGetRkeeperMenu: (payload: boolean): SetIsLoadingGetRkeeperMenuAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU,
        payload
    }),
    setIsLoadingGetRkeeperRestaurant: (payload: boolean): SetIsLoadingGetRkeeperRestaurantAction => ({
        type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_RESTAURANT,
        payload
    }),

    approveRkeeperOrder: (request: CmdApproveRkeeperOrder, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingApproveRkeeperOrder(true));
            const res = await RkeeperService.cmdApproveRkeeperOrder(request);
            if (res.data.success) {
                Notify.Success({title: txt.rkeeper_order_approved[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingApproveRkeeperOrder(false));
        }
    },

    deleteMenuRkeeperSettings: (request: CmdDeleteMenuRkeeperSettings, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingDeleteMenuRkeeperSettings(true));
            const res = await RkeeperService.cmdDeleteMenuRkeeperSettings(request);
            if (res.data.success) {
                Notify.Success({title: txt.menu_rkeeper_settings_deleted[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingDeleteMenuRkeeperSettings(false));
        }
    },

    importRkeeperProducts: (request: CmdImportRkeeperProducts, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingImportRkeeperProducts(true));
            const res = await RkeeperService.cmdImportRkeeperProducts(request);
            if (res.data.success) {
                if (!!res.data.data?.alreadyBoundProductIds) {
                    res.data.data?.alreadyBoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                if (!!res.data.data?.notFoundCategoryIds) {
                    res.data.data?.notFoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                if (!!res.data.data?.notFoundProductIds) {
                    res.data.data?.notFoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                await RkeeperActionCreators.getMenuRkeeperSettings({menuId: request.menuId}, new AbortController())(dispatch, getState);
                Notify.Success({title: txt.rkeeper_products_imported[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingImportRkeeperProducts(false));
        }
    },

    retryRkeeperOrder: (request: CmdRetryRkeeperOrder, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingRetryRkeeperOrder(true));
            const res = await RkeeperService.cmdRetryRkeeperOrder(request);
            if (res.data.success) {
                Notify.Success({title: txt.rkeeper_order_retried[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingRetryRkeeperOrder(false));
        }
    },

    saveMenuRkeeperSettings: (request: CmdSaveMenuRkeeperSettings, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentMenuId} = getState().user;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingSaveMenuRkeeperSettings(true));
            const res = await RkeeperService.cmdSaveMenuRkeeperSettings(request);
            if (res.data.success) {
                await RkeeperActionCreators.getMenuRkeeperSettings({menuId: currentMenuId}, new AbortController())(dispatch, getState);
                Notify.Success({title: txt.rkeeper_menu_settings_saved[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingSaveMenuRkeeperSettings(false));
        }
    },

    saveRkeeperProductBindings: (request: CmdSaveRkeeperProductBindings, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingSaveRkeeperProductBindings(true));
            const res = await RkeeperService.cmdSaveRkeeperProductBindings(request);
            if (res.data.success) {
                Notify.Success({title: txt.rkeeper_product_bindings_saved[currentLang], message: ""});
                await RkeeperActionCreators.getMenuRkeeperSettings({menuId: request.menuId}, new AbortController())(dispatch, getState);
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingSaveRkeeperProductBindings(false));
        }
    },

    saveRkeeperTableBindings: (request: CmdSaveRkeeperTableBindings, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingSaveRkeeperTableBindings(true));
            const res = await RkeeperService.cmdSaveRkeeperTableBindings(request);
            if (res.data.success) {
                Notify.Success({title: txt.rkeeper_table_bindings_saved[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingSaveRkeeperTableBindings(false));
        }
    },

    getMenuRkeeperSettings: (request: GetMenuRkeeperSettingsReq, controller: AbortController, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperMenuSettings(true));
            const res = await RkeeperService.getMenuRkeeperSettings(request, controller);
            if (res.data.success) {
                dispatch(RkeeperActionCreators.setRkeeperMenuSettings(res.data.data));
            } else {
                dispatch(RkeeperActionCreators.setRkeeperMenuSettings({} as RkeeperMenuSettings));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperMenuSettings(false));
        }
    },

    getRkeeperAgents: (controller: AbortController, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperAgents(true));
            const res = await RkeeperService.getRkeeperAgents({}, controller);
            if (res.data.success) {
                dispatch(RkeeperActionCreators.setRkeeperAgents(res.data.data));
            } else {
                dispatch(RkeeperActionCreators.setRkeeperAgents([]));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperAgents(false));
        }
    },

    getRkeeperMenu: (request: GetRkeeperMenuReq, controller: AbortController, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperMenu(true));
            const res = await RkeeperService.getRkeeperMenu(request, controller);
            if (res.data.success) {
                dispatch(RkeeperActionCreators.setRkeeperMenu(res.data.data));
            } else {
                dispatch(RkeeperActionCreators.setRkeeperMenu({} as RkeeperMenu));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperMenu(false));
        }
    },

    getRkeeperRestaurant: (request: GetRkeeperRestaurantReq, controller: AbortController, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperRestaurant(true));
            const res = await RkeeperService.getRkeeperRestaurant(request, controller);
            if (res.data.success) {
                dispatch(RkeeperActionCreators.setRkeeperRestaurant(res.data.data));
            } else {
                dispatch(RkeeperActionCreators.setRkeeperRestaurant({} as RkeeperRestaurant));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(RkeeperActionCreators.setIsLoadingGetRkeeperRestaurant(false));
        }
    },
}
