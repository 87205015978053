import React, {FunctionComponent} from 'react';
import {Layout} from "migration/shared/ui/Layout";
import classes from "./CustomPagesEdit.module.scss";

export const CustomPagesEdit: FunctionComponent = () => {
    return (
        <div className={classes.main}>
            <Layout breadcrumbs={[]} title={""}>
                Hello
            </Layout>
        </div>
    );
};
