import {SystemAction, SystemActionEnum, SystemState} from "./types";

const initialLocation: SystemState = {
    initialLocation: "/login",
}

export default function systemReducer(state = initialLocation, action: SystemAction): SystemState {
    switch (action.type) {
        case SystemActionEnum.SET_INITIAL_LOCATION:
            return {...state, initialLocation: action.payload};
        default:
            return state;
    }
}
