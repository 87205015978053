import React from "react";
import {ReactComponent as CloseEye} from "../../resourse/img/general/ic-close-eye.svg";
import {ReactComponent as Eye} from "../../resourse/img/general/ic-eye.svg";

interface IProps {
    password: string
    onChangePassword: (value: string) => void
    label: string
    placeholder?: string
    isValid: boolean
}

const styleSvg = {marginLeft:"-30px", cursor:"pointer"}

export function PasswordInput(props: IProps) {
    const [visible, setVisible] = React.useState(false)
    return (
        <div className="control">
            <div className="control__label">{props.label}
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
                <input type={visible ? "text" : "password"} className="control__input"
                       name="password" style={{backgroundColor: props.isValid ? "" : "#dc3545"}}
                       value={props.password}
                       onChange={(e) => props.onChangePassword(e.currentTarget.value)}
                       placeholder={props.placeholder}/>
                {!visible?
                    <Eye style={styleSvg} onClick={()=>setVisible(true)}/>
                    :
                    <CloseEye style={styleSvg} onClick={()=>setVisible(false)}/>
                }
            </div>
        </div>
    )
}