import React from "react";

interface IProps {
    options: string[]
    value: number
    onChange: (value: string) => void
    disabled?:boolean
    label: string
}

export function CustomSelect(props: IProps) {
    return (

        <div className="control">
            <label className="control__label">{props.label}</label>

            <select className="control__input"
                    disabled={props.disabled}
                    value={props.value}
                    onChange={(e) => props.onChange(e.currentTarget.value)}
            >

                {props.options.map((option, index) => {
                    return (
                        <option key={index+props.label} selected={index == props.value} value={index}>{option}</option>
                    )
                })}

            </select>

        </div>
    )
}