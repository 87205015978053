import {AuthAction, AuthActionEnum, AuthState} from "./types";

const initialState: AuthState = {
    isLoading: false,
}

export default function authReducer(state = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionEnum.SET_IS_LOADING:
            return {...state, isLoading: action.payload}
        case AuthActionEnum.LOGIN:
            return state;
        default:
            return state;
    }
}
