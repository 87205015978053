import React, {useState} from "react";
import {useI18nText} from "../../hooks/input/i18nText";
import {LangKey} from "../../hooks/globalContext";
import CreatableSelect from "react-select/creatable";
import {I18nString} from "../../models/lang/i18nString";
import {ActionMeta, MultiValue} from "react-select";

interface TagsI18nProps {
    label?: string,
    isRequired?: boolean
    values: I18nString,
    setValues: (c: { [key: string]: string }) => void,
    allLang?: LangKey[]
    lang: LangKey,
    placeholder?: string
}

export function TagsI18n(props: TagsI18nProps) {
    const info = useI18nText(props.allLang || [], props.lang);
    const [tagI18n, setTagI18n] = useState<I18nString>({})
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, lang: LangKey) => {
        console.log("event.key", event.key)
        if (!tagI18n[lang]) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let all = props.values[lang] ? props.values[lang].split(",") : []
                if (all.indexOf(tagI18n[lang]) === -1) {
                    all.push(tagI18n[lang])
                }
                console.log("all", all)
                props.setValues({...props.values, [lang]: all.join(",")})

                setTagI18n((prev) => ({...prev, [lang]: ""}))
                event.preventDefault();

        }
    };

    function onChange(_:MultiValue<{label: string, value: LangKey}>, actionMeta:ActionMeta<{label: string, value: LangKey}>) {
        let removal = actionMeta.removedValue
        if (removal) {
            let val = removal.label
            let nowVal = props.values[removal.value]
            if (nowVal) {
                let all = nowVal.split(",")
                if (removal) {
                    let res = all.filter((v) => v !== val).join(",")
                    props.setValues({...props.values, [removal.value]: res})
                }
            }
        }
    }

    return (
        <div className="control">
            {props.label ? <label className="control__label">{props.label}</label> : null}
            {props.isRequired ? <span className="required-star">*</span> : null}
            <div style={{display: "flex"}}>
                <CreatableSelect
                    className={"col-md-11 p-0"}
                    components={{DropdownIndicator: null}}
                    inputValue={tagI18n[info.langs[0]]}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={onChange}
                    onInputChange={(newValue) => setTagI18n((prev) => ({
                        ...prev,
                        [info.langs[0]]: newValue
                    }))}
                    onKeyDown={(e) => handleKeyDown(e, info.langs[0])}
                    placeholder={[props.placeholder]}
                    value={props.values[info.langs[0]] ? props.values[info.langs[0]].split(",")
                        .map((v) => {
                            return {
                                label: v,
                                value: info.langs[0]
                            }
                        }) : []}
                />
                {info.langs.length > 1 ?
                    <div className="button -gray"
                         onClick={() => info.setIsActive(!info.isActive)}
                    >
                        <p className={"userLang"} aria-expanded={info.isActive}>
                            {info.langs[0]}
                        </p>
                    </div> : null}

            </div>
            {info.isActive ? info.langs.filter((l) => l !== info.langs[0]).map((l, index) => {
                return (
                    <div style={{display: "flex"}} key={"tag_input" + l + index}>
                        <CreatableSelect
                            className={"col-md-11"}
                            components={{DropdownIndicator: null}}
                            inputValue={tagI18n[l]}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={onChange}
                            onInputChange={(newValue) => setTagI18n((prev) => ({...prev, [l]: newValue}))}
                            onKeyDown={(e) => handleKeyDown(e, l)}
                            placeholder={[props.placeholder]}
                            value={props.values[l] ? props.values[l].split(",")
                                .map((v) => {
                                    return {
                                        label: v,
                                        value: l
                                    }
                                }) : []}
                        />

                        <div className="button -disabled" style={{width: "10%"}}>
                            {l}
                        </div>
                    </div>
                )
            }) : null}
        </div>
    )
}

