import {LangKey} from "../globalContext";
import {useEffect, useState} from "react";

export function useI18nText(allLang: LangKey[], lang: LangKey) {
    const [langs, setLangs] = useState<LangKey[]>(["RU"]);
    const [isActive, setIsActive] = useState<boolean>(false)

    useEffect(() => {
        setLangs([lang, ...allLang])
    }, [lang, allLang])

    return {
        langs,
        isActive,
        setIsActive,
    }
}