import {
    DashboardsActionEnum,
    SetReportDuringPeriodAction,
    SetReportDynamicsVisitsAction,
    SetReportTodayAction,
    SetIsLoadingReportTodayAction,
    SetIsLoadingReportDuringPeriodAction,
    SetIsLoadingReportDynamicsVisitsAction,
    SetIsLoadingReportDynamicsActiveUsersAction,
    SetReportDynamicsActiveUsersAction,
    SetReportDynamicsNewUsersAction,
    SetIsLoadingReportDynamicsNewUsersAction,
    SetIsLoadingReportDynamicsAction,
    SetIsLoadingReportMostVisitedMenusAction,
    SetReportMostVisitedMenus
} from "./types";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {AppDispatch, RootState} from "migration/app/store";
import {HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import DashboardsService, {
    ReportDashboardDuringPeriodReq,
    ReportDashboardDuringPeriodRes,
    ReportDashboardDynamicsReq,
    ReportDashboardDynamicsRes,
    ReportDashboardTodayRes, ReportMostVisitedMenus
} from "../api";

export const DashboardsActionCreators = {
    setReportToday: (payload: ReportDashboardTodayRes): SetReportTodayAction => ({
        type: DashboardsActionEnum.SET_REPORT_TODAY,
        payload: payload
    }),
    setReportDuringPeriod: (payload: ReportDashboardDuringPeriodRes): SetReportDuringPeriodAction => ({
        type: DashboardsActionEnum.SET_REPORT_DURING_PERIOD,
        payload: payload
    }),
    setReportDynamicsVisits: (payload: ReportDashboardDynamicsRes): SetReportDynamicsVisitsAction => ({
        type: DashboardsActionEnum.SET_REPORT_DYNAMICS_VISITS,
        payload: payload
    }),
    setReportDynamicsActiveUsers: (payload: ReportDashboardDynamicsRes): SetReportDynamicsActiveUsersAction => ({
        type: DashboardsActionEnum.SET_REPORT_DYNAMICS_ACTIVE_USERS,
        payload: payload
    }),
    setReportDynamicsNewUsers: (payload: ReportDashboardDynamicsRes): SetReportDynamicsNewUsersAction => ({
        type: DashboardsActionEnum.SET_REPORT_DYNAMICS_NEW_USERS,
        payload: payload
    }),
    setReportMostVisitedMenus: (payload: ReportMostVisitedMenus): SetReportMostVisitedMenus => ({
        type: DashboardsActionEnum.SET_REPORT_MOST_VISITED_MENUS,
        payload: payload
    }),
    setIsLoadingReportToday: (payload: boolean): SetIsLoadingReportTodayAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_TODAY,
        payload: payload
    }),
    setIsLoadingReportDuringPeriod: (payload: boolean): SetIsLoadingReportDuringPeriodAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DURING_PERIOD,
        payload: payload
    }),
    setIsLoadingReportDynamics: (payload: boolean): SetIsLoadingReportDynamicsAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS,
        payload: payload
    }),
    setIsLoadingReportDynamicsVisits: (payload: boolean): SetIsLoadingReportDynamicsVisitsAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_VISITS,
        payload: payload
    }),
    setIsLoadingReportDynamicsActiveUsers: (payload: boolean): SetIsLoadingReportDynamicsActiveUsersAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_ACTIVE_USERS,
        payload: payload
    }),
    setIsLoadingReportDynamicsNewUsers: (payload: boolean): SetIsLoadingReportDynamicsNewUsersAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_NEW_USERS,
        payload: payload
    }),
    setIsLoadingReportMostVisitedMenus: (payload: boolean): SetIsLoadingReportMostVisitedMenusAction => ({
        type: DashboardsActionEnum.SET_IS_LOADING_REPORT_MOST_VISITED_MENUS,
        payload: payload
    }),

    fetchReportToday: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportTodayDashboard(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportToday,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportToday(res.data.data));
            },
        });
    },

    fetchReportDuringPeriod: (request: ReportDashboardDuringPeriodReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportDashboardDuringPeriod(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportDuringPeriod,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportDuringPeriod(res.data.data));
            },
        });
    },

    fetchReportDynamics: (request: ReportDashboardDynamicsReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportDashboardDynamics(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportDynamics,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportDynamicsVisits(res.data.data));
                dispatch(DashboardsActionCreators.setReportDynamicsActiveUsers(res.data.data));
                dispatch(DashboardsActionCreators.setReportDynamicsNewUsers(res.data.data));
            },
        });
    },

    fetchReportDynamicsVisits: (request: ReportDashboardDynamicsReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportDashboardDynamics(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportDynamicsVisits,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportDynamicsVisits(res.data.data));
            },
        });
    },

    fetchReportDynamicsActiveUsers: (request: ReportDashboardDynamicsReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportDashboardDynamics(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportDynamicsActiveUsers,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportDynamicsActiveUsers(res.data.data));
            },
        });
    },

    fetchReportDynamicsNewUsers: (request: ReportDashboardDynamicsReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportDashboardDynamics(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportDynamicsNewUsers,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportDynamicsNewUsers(res.data.data));
            },
        });
    },

    fetchReportMostVisitedMenus: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, DashboardsService.reportMostVisitedMenus(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: DashboardsActionCreators.setIsLoadingReportMostVisitedMenus,
            successCallback: (res) => {
                dispatch(DashboardsActionCreators.setReportMostVisitedMenus(res.data.data));
            },
        });
    }
}
