import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {v4 as uuidv4} from "uuid";
import {Button, Form} from "antd";
import {TYLink} from "migration/entities/Feedback";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Reason} from "../Reason";
import {ModalReasonCreate} from "../ModalReasonCreate";
import {TextI18n} from "../../../../../../../components/input/textI18n";
import {TextAreaI18n} from "../../../../../../../components/input/textAreaI18n";
import FeedbackEditService from "../../api/feedbackEditService";
import {TyLink} from "../TYLink";
import classes from "./FormFeedbackMark.module.scss";

export type GradeType = "positiveGrading" | "negativeGrading" | 1 | 2 | 3 | 4 | 5;

interface FormFeedbackMarkProps {
    gradeType: GradeType;
}

export const FormFeedbackMark: FunctionComponent<FormFeedbackMarkProps> = (props) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser} = useTypedSelector(state => state.user);
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {
        setFeedbackPage,

        savePositiveGradeTyTextsFeedbackPage,
        saveNegativeGradeTyTextsFeedbackPage,
        saveSpecificGradeTyTextsFeedbackPage,

        addPositiveGradeTyLinkFeedbackPage,
        addNegativeGradeTyLinkFeedbackPage,
        addSpecificGradeTyLinkFeedbackPage,

        removePositiveGradeTyLinkFeedbackPage,
        removeNegativeGradeTyLinkFeedbackPage,
        removeSpecificGradeTyLinkFeedbackPage,

        editPositiveGradeTyLinkFeedbackPage,
        editNegativeGradeTyLinkFeedbackPage,
        editSpecificGradeTyLinkFeedbackPage,

        attachImageToPositiveGradeTyLinkFeedbackPage,
        attachImageToNegativeGradeTyLinkFeedbackPage,
        attachImageToSpecificGradeTyLinkFeedbackPage,

        detachImageFromPositiveGradeTyLinkFeedbackPage,
        detachImageFromNegativeGradeTyLinkFeedbackPage,
        detachImageFromSpecificGradeTyLinkFeedbackPage,
    } = useActions();

    const [isReasonCreateModalOpen, setIsReasonCreateModalOpen] = useState<boolean>(false);
    const [candidateContentTitle, setCandidateContentTitle] = useState<{ [key: string]: string }>({});
    const [candidateContentSubTitle, setCandidateContentSubTitle] = useState<{ [key: string]: string }>({});
    const [candidateLinks, setCandidateLinks] = useState<TYLink[]>([]);

    const saveContentTitle = () => {
        const updatedFeedback = {...feedbackPage};

        switch (props.gradeType) {
            case "positiveGrading":
                if (isEmpty(updatedFeedback.positiveGrading)) {
                    updatedFeedback.positiveGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.positiveGrading.TYText.TYTitle = candidateContentTitle as MlString;

                if (
                    isEmpty(updatedFeedback.positiveGrading.TYText.TYTitle) ||
                    isEmpty(updatedFeedback.positiveGrading.TYText.TYSubtitle)
                ) {
                    return;
                }
                savePositiveGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.positiveGrading?.TYText?.TYTitle,
                    TYSubtitle: updatedFeedback?.positiveGrading?.TYText?.TYSubtitle,
                }, updatedFeedback, {navigate: navigate});
                break;
            case "negativeGrading":
                if (isEmpty(updatedFeedback?.negativeGrading)) {
                    updatedFeedback.negativeGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.negativeGrading.TYText.TYTitle = candidateContentTitle as MlString;

                if (
                    isEmpty(updatedFeedback.negativeGrading.TYText.TYTitle) ||
                    isEmpty(updatedFeedback.negativeGrading.TYText.TYSubtitle)
                ) {
                    return;
                }
                saveNegativeGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.negativeGrading?.TYText?.TYTitle,
                    TYSubtitle: updatedFeedback?.negativeGrading?.TYText?.TYSubtitle,
                }, updatedFeedback, {navigate: navigate});
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                if (isEmpty(updatedFeedback.specificGrading[props.gradeType])) {
                    updatedFeedback.specificGrading[props.gradeType] = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.specificGrading[props.gradeType].TYText.TYTitle = candidateContentTitle as MlString;

                if (
                    isEmpty(updatedFeedback.specificGrading[props.gradeType].TYText.TYTitle) ||
                    isEmpty(updatedFeedback.specificGrading[props.gradeType].TYText.TYSubtitle)
                ) {
                    return;
                }
                saveSpecificGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.specificGrading[props.gradeType]?.TYText?.TYTitle,
                    TYSubtitle: updatedFeedback?.specificGrading[props.gradeType]?.TYText?.TYSubtitle,
                }, updatedFeedback, {navigate: navigate}, props.gradeType);
                break;
        }
    }

    const saveContentSubTitle = () => {
        const updatedFeedback = {...feedbackPage};

        switch (props.gradeType) {
            case "positiveGrading":
                if (isEmpty(updatedFeedback.positiveGrading)) {
                    updatedFeedback.positiveGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.positiveGrading.TYText.TYSubtitle = candidateContentSubTitle as MlString;

                if (
                    isEmpty(updatedFeedback.positiveGrading.TYText.TYTitle) ||
                    isEmpty(updatedFeedback.positiveGrading.TYText.TYSubtitle)
                ) {
                    return;
                }
                savePositiveGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.positiveGrading?.TYText?.TYTitle || {} as MlString,
                    TYSubtitle: updatedFeedback?.positiveGrading?.TYText?.TYSubtitle || {} as MlString,
                }, updatedFeedback, {navigate: navigate});
                break;
            case "negativeGrading":
                if (isEmpty(updatedFeedback.negativeGrading)) {
                    updatedFeedback.negativeGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.negativeGrading.TYText.TYSubtitle = candidateContentSubTitle as MlString;

                if (
                    isEmpty(updatedFeedback.negativeGrading.TYText.TYTitle) ||
                    isEmpty(updatedFeedback.negativeGrading.TYText.TYSubtitle)
                ) {
                    return;
                }
                saveNegativeGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.negativeGrading?.TYText?.TYTitle || {} as MlString,
                    TYSubtitle: updatedFeedback?.negativeGrading?.TYText?.TYSubtitle || {} as MlString,
                }, updatedFeedback, {navigate: navigate});
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                if (isEmpty(updatedFeedback.specificGrading[props.gradeType])) {
                    updatedFeedback.specificGrading[props.gradeType] = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }
                updatedFeedback.specificGrading[props.gradeType].TYText.TYSubtitle = candidateContentSubTitle as MlString;

                if (
                    isEmpty(updatedFeedback.specificGrading[props.gradeType].TYText.TYTitle) ||
                    isEmpty(updatedFeedback.specificGrading[props.gradeType].TYText.TYSubtitle)
                ) {
                    return;
                }
                saveSpecificGradeTyTextsFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYTitle: updatedFeedback?.specificGrading[props.gradeType]?.TYText?.TYTitle || {} as MlString,
                    TYSubtitle: updatedFeedback?.specificGrading[props.gradeType]?.TYText?.TYSubtitle || {} as MlString,
                }, updatedFeedback, {navigate: navigate}, props.gradeType);
                break;
        }
    }

    const addLink = () => {
        const newLink: TYLink = {
            TYLinkId: uuidv4(),
            TYLinkCaption: {} as MlString,
            TYLinkText: {} as MlString,
            TYLinkURL: "",
            TYLinkImg: {
                fileId: "",
                filename: ""
            },
        }

        const updatedFeedback = {...feedbackPage};

        switch (props.gradeType) {
            case "positiveGrading":
                if (isEmpty(updatedFeedback?.positiveGrading)) {
                    updatedFeedback.positiveGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }

                updatedFeedback.positiveGrading.TYLink.push(newLink);

                if (!newLink?.TYLinkURL) {
                    setFeedbackPage(updatedFeedback);
                    return;
                }
                addPositiveGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: newLink.TYLinkId,
                    TYLinkcaption: newLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: newLink?.TYLinkText || {} as MlString,
                    TYLinkURL: newLink?.TYLinkURL || "",
                }, updatedFeedback, {navigate: navigate});
                setCandidateLinks([...candidateLinks, newLink]);
                break;
            case "negativeGrading":
                if (isEmpty(updatedFeedback?.negativeGrading)) {
                    updatedFeedback.negativeGrading = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }

                updatedFeedback.negativeGrading.TYLink.push(newLink);

                if (!newLink?.TYLinkURL) {
                    setFeedbackPage(updatedFeedback);
                    return;
                }
                addNegativeGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: newLink.TYLinkId,
                    TYLinkcaption: newLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: newLink?.TYLinkText || {} as MlString,
                    TYLinkURL: newLink?.TYLinkURL || "",
                }, updatedFeedback, {navigate: navigate});
                setCandidateLinks([...candidateLinks, newLink]);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                if (isEmpty(updatedFeedback?.specificGrading?.[props.gradeType])) {
                    updatedFeedback.specificGrading[props.gradeType] = {
                        TYText: {
                            TYTitle: {} as MlString,
                            TYSubtitle: {} as MlString,
                        },
                        TYLink: [],
                        reasons: [],
                    }
                }

                updatedFeedback.specificGrading[props.gradeType].TYLink.push(newLink);

                if (!newLink?.TYLinkURL) {
                    setFeedbackPage(updatedFeedback);
                    return;
                }
                addSpecificGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: newLink.TYLinkId,
                    TYLinkcaption: newLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: newLink?.TYLinkText || {} as MlString,
                    TYLinkURL: newLink?.TYLinkURL || "",
                }, updatedFeedback, {navigate: navigate}, props.gradeType);
                setCandidateLinks([...candidateLinks, newLink]);
                break;
        }
    }

    const removeLink = (TYLinkId: string) => {
        const updatedFeedback = {...feedbackPage};
        let linkIndex;

        switch (props.gradeType) {
            case "positiveGrading":
                linkIndex = updatedFeedback.positiveGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                updatedFeedback.positiveGrading.TYLink.splice(linkIndex, 1);

                removePositiveGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: TYLinkId,
                }, updatedFeedback, {navigate: navigate});
                setCandidateLinks(updatedFeedback.positiveGrading.TYLink);
                break;
            case "negativeGrading":
                linkIndex = updatedFeedback.negativeGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                updatedFeedback.negativeGrading.TYLink.splice(linkIndex, 1);

                removeNegativeGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: TYLinkId,
                }, updatedFeedback, {navigate: navigate});
                setCandidateLinks(updatedFeedback.negativeGrading.TYLink);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                linkIndex = updatedFeedback.specificGrading[props.gradeType].TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                updatedFeedback.specificGrading[props.gradeType].TYLink.splice(linkIndex, 1);

                removeSpecificGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: TYLinkId,
                }, updatedFeedback, {navigate: navigate}, props.gradeType);
                setCandidateLinks(updatedFeedback.specificGrading[props.gradeType].TYLink);
                break;
        }
    }

    const attachImage = async (TYLinkId: string, file: File | null, setTYLinkImg: (img: {fileId: string, filename: string}) => void) => {
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);
        const res = await FeedbackEditService.uploadFeedbackPageTyLinkImg(formData, currentUser?.org?.id || "");
        if (res?.data?.success) {
            const {fileId, filename} = res.data?.data;
            const updatedFeedback = {...feedbackPage};
            let linkIndex;

            setCandidateLinks(prevState => {
                const newState = [...prevState];
                const linkIndex = newState.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return prevState;
                newState[linkIndex].TYLinkImg = {
                    fileId: fileId,
                    filename: filename,
                };
                return newState;
            });

            switch (props.gradeType) {
                case "positiveGrading":
                    linkIndex = updatedFeedback.positiveGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                    if (linkIndex === -1) return;
                    updatedFeedback.positiveGrading.TYLink[linkIndex].TYLinkImg = {
                        fileId: fileId,
                        filename: filename,
                    };

                    attachImageToPositiveGradeTyLinkFeedbackPage({
                        feedbackPageId: feedbackPage?.id,
                        TYLinkId: updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkId,
                        TYLinkImgFilename: updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkImg?.filename || "",
                        TYLinkImgFileId: updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkImg?.fileId || "",
                    }, {navigate: navigate});
                    setTYLinkImg({fileId: fileId, filename: filename});
                    break;
                case "negativeGrading":
                    linkIndex = updatedFeedback.negativeGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                    if (linkIndex === -1) return;
                    updatedFeedback.negativeGrading.TYLink[linkIndex].TYLinkImg = {
                        fileId: fileId,
                        filename: filename,
                    };

                    attachImageToNegativeGradeTyLinkFeedbackPage({
                        feedbackPageId: feedbackPage?.id,
                        TYLinkId: updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkId,
                        TYLinkImgFilename: updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkImg?.filename || "",
                        TYLinkImgFileId: updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkImg?.fileId,
                    }, {navigate: navigate});
                    setTYLinkImg({fileId: fileId, filename: filename});
                    break;
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    linkIndex = updatedFeedback.specificGrading[props.gradeType].TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                    if (linkIndex === -1) return;
                    updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex].TYLinkImg = {
                        fileId: fileId,
                        filename: filename,
                    };

                    attachImageToSpecificGradeTyLinkFeedbackPage({
                        feedbackPageId: feedbackPage?.id,
                        TYLinkId: updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkId,
                        TYLinkImgFilename: updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkImg?.filename || "",
                        TYLinkImgFileId: updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkImg?.fileId || "",
                    }, {navigate: navigate}, props.gradeType);
                    setTYLinkImg({fileId: fileId, filename: filename});
                    break;
            }
        }
    }

    const detachImage = async (TYLinkId: string, setTYLinkImg: (img: {fileId: string, filename: string}) => void) => {
        const updatedFeedback = {...feedbackPage};
        let linkIndex;
        let TYLinkImgFileId = "";
        let TYLinkImgFilename;

        switch (props.gradeType) {
            case "positiveGrading":
                linkIndex = updatedFeedback.positiveGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                TYLinkImgFileId = updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkImg?.fileId || "";
                TYLinkImgFilename = updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkImg?.filename || "";

                detachImageFromPositiveGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: updatedFeedback.positiveGrading.TYLink[linkIndex]?.TYLinkId,
                    TYLinkImgFileId: TYLinkImgFileId,
                    TYLinkImgFilename: TYLinkImgFilename,
                }, {navigate: navigate});
                setTYLinkImg({fileId: "", filename: ""});
                break;
            case "negativeGrading":
                linkIndex = updatedFeedback.negativeGrading.TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                TYLinkImgFileId = updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkImg?.fileId || "";
                TYLinkImgFilename = updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkImg?.filename || "";

                detachImageFromNegativeGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: updatedFeedback.negativeGrading.TYLink[linkIndex]?.TYLinkId,
                    TYLinkImgFileId: TYLinkImgFileId,
                    TYLinkImgFilename: TYLinkImgFilename,
                }, {navigate: navigate});
                setTYLinkImg({fileId: "", filename: ""});
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                linkIndex = updatedFeedback.specificGrading[props.gradeType].TYLink.findIndex(link => link.TYLinkId === TYLinkId);
                if (linkIndex === -1) return;
                TYLinkImgFileId = updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkImg?.fileId || "";
                TYLinkImgFilename = updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkImg?.filename || "";

                detachImageFromSpecificGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPage?.id,
                    TYLinkId: updatedFeedback.specificGrading[props.gradeType].TYLink[linkIndex]?.TYLinkId,
                    TYLinkImgFileId: TYLinkImgFileId,
                    TYLinkImgFilename: TYLinkImgFilename,
                }, {navigate: navigate}, props.gradeType);
                setTYLinkImg({fileId: "", filename: ""});
                break;
        }
    }

    const saveTYLink = (TYLink: TYLink) => {
        const feedbackPageCopy = {...feedbackPage};
        switch (props.gradeType) {
            case "positiveGrading":

                feedbackPageCopy.positiveGrading.TYLink = feedbackPageCopy.positiveGrading.TYLink.map(link => {
                    if (link.TYLinkId === TYLink.TYLinkId) {
                        return TYLink;
                    }
                    return link;
                });
                editPositiveGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPageCopy?.id,
                    TYLinkId: TYLink.TYLinkId,
                    TYLinkcaption: TYLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: TYLink?.TYLinkText || {} as MlString,
                    TYLinkURL: TYLink?.TYLinkURL || "",
                }, feedbackPageCopy, {navigate: navigate});
                setCandidateLinks(feedbackPageCopy.positiveGrading.TYLink);
                break;
            case "negativeGrading":
                feedbackPageCopy.negativeGrading.TYLink = feedbackPageCopy.negativeGrading.TYLink.map(link => {
                    if (link.TYLinkId === TYLink.TYLinkId) {
                        return TYLink;
                    }
                    return link;
                });
                editNegativeGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPageCopy?.id,
                    TYLinkId: TYLink.TYLinkId,
                    TYLinkcaption: TYLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: TYLink?.TYLinkText || {} as MlString,
                    TYLinkURL: TYLink?.TYLinkURL || "",
                }, feedbackPageCopy, {navigate: navigate});
                setCandidateLinks(feedbackPageCopy.negativeGrading.TYLink);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                feedbackPageCopy.specificGrading[props.gradeType].TYLink = feedbackPageCopy.specificGrading[props.gradeType].TYLink.map(link => {
                    if (link.TYLinkId === TYLink.TYLinkId) {
                        return TYLink;
                    }
                    return link;
                });
                editSpecificGradeTyLinkFeedbackPage({
                    feedbackPageId: feedbackPageCopy?.id,
                    TYLinkId: TYLink.TYLinkId,
                    TYLinkcaption: TYLink?.TYLinkCaption || {} as MlString,
                    TYLinkText: TYLink?.TYLinkText || {} as MlString,
                    TYLinkURL: TYLink?.TYLinkURL || "",
                }, feedbackPageCopy, {navigate: navigate}, props.gradeType);
                setCandidateLinks(feedbackPageCopy.specificGrading[props.gradeType].TYLink);
        }
    }

    useEffect(() => {
        if (isEmpty(feedbackPage)) return;

        switch (props.gradeType) {
            case "positiveGrading":
                setCandidateContentTitle(feedbackPage?.positiveGrading?.TYText?.TYTitle || {} as MlString);
                setCandidateContentSubTitle(feedbackPage?.positiveGrading?.TYText?.TYSubtitle || {} as MlString);
                setCandidateLinks(feedbackPage?.positiveGrading?.TYLink || []);
                break;
            case "negativeGrading":
                setCandidateContentTitle(feedbackPage?.negativeGrading?.TYText?.TYTitle || {} as MlString);
                setCandidateContentSubTitle(feedbackPage?.negativeGrading?.TYText?.TYSubtitle || {} as MlString);
                setCandidateLinks(feedbackPage?.negativeGrading?.TYLink || []);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                setCandidateContentTitle(feedbackPage?.specificGrading?.[props.gradeType]?.TYText?.TYTitle || {} as MlString);
                setCandidateContentSubTitle(feedbackPage?.specificGrading?.[props.gradeType]?.TYText?.TYSubtitle || {} as MlString);
                setCandidateLinks(feedbackPage?.specificGrading?.[props.gradeType]?.TYLink || []);
                break;
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Form layout={"vertical"} className={classes.form__column}>
            <Form.Item label={<label style={{color: "#8392A5", margin: "0"}}>{txt.reasons[currentLang]}</label>}>
                <div className={classes.reasons}>
                    {props.gradeType === "positiveGrading" && (
                        feedbackPage?.positiveGrading?.reasons?.map((reason, index) => (
                            <Reason
                                key={index + 1}
                                index={index + 1}
                                reason={reason}
                                gradeType={props.gradeType}
                            />
                        ))
                    )}

                    {props.gradeType === "negativeGrading" && (
                        feedbackPage?.negativeGrading?.reasons?.map((reason, index) => (
                            <Reason
                                key={index + 1}
                                index={index + 1}
                                reason={reason}
                                gradeType={props.gradeType}
                            />
                        ))
                    )}

                    {[1, 2, 3, 4, 5].includes(props.gradeType as number) && (
                        feedbackPage?.specificGrading?.[props?.gradeType as number]?.reasons?.map((reason, index) => (
                            <Reason
                                key={index + 1}
                                index={index + 1}
                                reason={reason}
                                gradeType={props.gradeType}
                            />
                        ))
                    )}

                    <div className={classes.reasons__add} onClick={() => setIsReasonCreateModalOpen(true)}>
                        {txt.add[currentLang]}
                    </div>

                    <ModalReasonCreate
                        isReasonCreateModalOpen={isReasonCreateModalOpen}
                        setIsReasonCreateModalOpen={setIsReasonCreateModalOpen}
                        gradeType={props.gradeType}
                    />
                </div>
            </Form.Item>

            <TextI18n
                values={candidateContentTitle}
                lang={feedbackPage?.defaultLang}
                allLang={feedbackPage?.otherLangs || []}
                setValues={setCandidateContentTitle as (c: { [key: string]: string }) => void}
                isStaff={true}
                placeholder={txt.enter_title[currentLang]}
                onBlur={saveContentTitle}
                onTranslate={saveContentTitle}
                label={txt.title[currentLang]}
                isRequired={true}
            />

            <TextAreaI18n
                values={candidateContentSubTitle}
                lang={feedbackPage?.defaultLang}
                allLangs={feedbackPage?.otherLangs || []}
                setValues={setCandidateContentSubTitle as (c: { [key: string]: string }) => void}
                isStaff={true}
                onBlur={saveContentSubTitle}
                onTranslate={saveContentSubTitle}
                label={txt.sub_title[currentLang]}
                isRequired={true}
            />

            <div className={classes.link_column}>
                {candidateLinks.map((link, index) => (
                    <TyLink
                        key={link.TYLinkId}
                        link={link}
                        index={index}
                        saveTYLink={saveTYLink}
                        removeLink={removeLink}
                        attachImage={attachImage}
                        detachImage={detachImage}
                    />
                ))}

                <Button onClick={addLink}>
                    {txt.add_link[currentLang]}
                </Button>
            </div>
        </Form>
    )
}
