import {AxiosResponse} from "axios";
import {Tenant} from "migration/entities/tenant";
import {MlString} from "migration/entities/base/mlString";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface AssociateCrossSellingToMenuCategoryReq {
    "customLabel": MlString,
    "sourceCategoryId": string,
    "targetProductIds": string[],
}

export interface AssociateCrossSellingToProductReq {
    "customLabel": MlString,
    "sourceProductId": string,
    "targetProductIds": string[],
}

export interface DissociateCrossSellingFromMenuCategory {
    "sourceCategoryId": string,
}

export interface DissociateCrossSellingFromProduct {
    "sourceProductId": string,
}

export interface RemoveCommonCrossSellingProducts {
    "productIds": string[],
}

export interface SetCommonCrossSellingProducts {
    "productIds": string[],
}

export interface SetDefaultCrossSellingLabel {
    "label": MlString,
}

export class CrossSellingService {
    static async tenantById(orgId: string, controller: AbortController): Promise<AxiosResponse<SuccessResponse<Tenant> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<Tenant> | ErrorResponse>(ApiRoutes.TENANT_BY_ID, {id: orgId}, {signal: controller.signal});
    }
    static async associateCrossSellingToMenuCategory(orgId: string, request: AssociateCrossSellingToMenuCategoryReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.ASSOCIATE_CROSS_SELLING_TO_MENU_CATEGORY, request);
    }

    static async associateCrossSellingToProduct(orgId: string, request: AssociateCrossSellingToProductReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.ASSOCIATE_CROSS_SELLING_TO_PRODUCT, request);
    }

    static async dissociateCrossSellingFromMenuCategory(orgId: string, request: DissociateCrossSellingFromMenuCategory): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.DISSOCIATE_CROSS_SELLING_FROM_MENU_CATEGORY, request);
    }

    static async dissociateCrossSellingFromProduct(orgId: string, request: DissociateCrossSellingFromProduct): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.DISSOCIATE_CROSS_SELLING_FROM_PRODUCT, request);
    }

    static async removeCommonCrossSellingProducts(orgId: string, request: RemoveCommonCrossSellingProducts): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.REMOVE_COMMON_CROSS_SELLING_PRODUCTS, request);
    }

    static async setCommonCrossSellingProducts(orgId: string, request: SetCommonCrossSellingProducts): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SET_COMMON_CROSS_SELLING_PRODUCTS, request);
    }

    static async setDefaultCrossSellingLabel(orgId: string, request: SetDefaultCrossSellingLabel): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SET_DEFAULT_CROSS_SELLING_LABEL, request);
    }
}
