import React, {useState} from "react";
import {NumberInput} from "./numberInput";
import {useGlobalContext} from "../../hooks/globalContext";
import {InputText} from "./inputText";

interface IReportSearchProps {
    onSubmit: (s: { days: number, visits: number, search: string }) => void
}

export function ReportSearchHeader(props: IReportSearchProps): JSX.Element {
    const {i18n} = useGlobalContext()

    const [query, setQuery] = useState<{ days: number, visits: number, search: string }>({
        days: 30,
        visits: 10,
        search: '',
    })

    function setVisits(visits: number) {
        setQuery({...query, visits})
    }

    function setDays(days: number) {
        setQuery({...query, days})
    }

    function setSearch(search: string) {
        setQuery({...query, search})
    }

    return (
        <div className="search">
            <form style={{display: "-webkit-box"}}>
                <NumberInput label={i18n.days()} value={query.days} onChange={setDays}/>
                <NumberInput label={i18n.visits()} value={query.visits} onChange={setVisits}/>
                <InputText label={i18n.search()} value={query.search} onChange={(s) => {
                    setSearch(s)
                }} isValidate={true}/>

                <div className="control" style={{margin: "auto"}}>
                    <button type="submit"
                            className={"button -blue"}
                            onClick={(e) => {
                                e.preventDefault()
                                props.onSubmit(query)
                            }}
                    >{i18n.search()}</button>
                </div>
            </form>
        </div>
    )
}