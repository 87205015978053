import {useEffect, useState} from "react";
import {copyIikoMenuStruct} from "../../models/iiko/product";
import {useGlobalContext, useUserContext} from "../globalContext";
import {useNavigate} from "react-router-dom";

export function useIIkoCopyByMenu() {
    const {api} = useGlobalContext();
    const {currentMenuId} = useUserContext();
    const navigate = useNavigate();

    // const [strIiko, setStrIiko] = useState<copyIikoMenuStruct>({})
    const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({});
    const [copyLoading, setCopyLoading] = useState<boolean>(false);

    // useEffect(() => {
    //     if (!currentMenuId) return
    //     const controller = new AbortController()
    //     api.getIIkoProductCategories({controller, setLoading:setLoading},currentMenuId).then((cat) => {
    //         if (cat.data) {
    //             api.getIIkoProducts({controller, setLoading:setLoading},currentMenuId).then((itm) => {
    //                 if (itm.data) {
    //                     let str: copyIikoMenuStruct = {}
    //                     str["none"] = {
    //                         id: "none",
    //                         name: "none",
    //                         items: []
    //                     }
    //                     cat.data?.iikoProductCategories?.map((nowCat) => {
    //                         str[nowCat.id] = {
    //                             ...nowCat,
    //                             items: []
    //                         }
    //                     })
    //                     itm.data.iikoProducts?.map((nowItem) => {
    //                         if (nowItem.categoryId && str[nowItem.categoryId]) {
    //                             str[nowItem.categoryId].items.push(nowItem)
    //                         } else {
    //                             nowItem.categoryId = null
    //                             str["none"].items.push(nowItem)
    //                         }
    //                     })
    //                     // sort by name
    //                     for (let strKey in str) {
    //                         let tempMenuForSort = str[strKey]
    //                         tempMenuForSort.items = tempMenuForSort.items.sort((a, b) => {
    //                             let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    //                             if (nameA < nameB) //sort string ascending
    //                                 return -1;
    //                             if (nameA > nameB)
    //                                 return 1;
    //                             return 0; //default return value (no sorting)
    //                         })
    //                         str[strKey] = tempMenuForSort
    //                     }
    //                     setStrIiko(str)
    //                 }
    //             })
    //         }
    //     })
    //     return () => controller.abort()
    // }, [currentMenuId])

    function onCollapse(id: string) {
        setCollapse((prev) => ({...prev, [id]: !prev[id]}));
    }

    // function onCheckAll() {
    //     setStrIiko((prev) => {
    //         for (let prevKey in prev) {
    //             prev[prevKey].items = prev[prevKey].items.map((nowItem) => {
    //                 return {...nowItem, isCheck: true}
    //             })
    //         }
    //         return {...prev}
    //     });
    // }
    //
    // function onCheckUnique() {
    //     setStrIiko((prev) => {
    //         for (let prevKey in prev) {
    //             let unique: Map<string, boolean> = new Map();
    //             prev[prevKey].items = prev[prevKey].items.map((nowItem) => {
    //                 if (unique.has(nowItem.name)) {
    //                     return {...nowItem};
    //                 }
    //                 unique.set(nowItem.name, true);
    //                 return {...nowItem, isCheck: true};
    //             })
    //         }
    //         return {...prev};
    //     });
    // }
    //
    // function onCheckItem(id: string, catId: string) {
    //     setStrIiko((prev) => {
    //         return {
    //             ...prev, [catId]: {
    //                 ...prev[catId],
    //                 items: prev[catId].items.map((nowItem) => {
    //                     if (nowItem.id === id) {
    //                         return {...nowItem, isCheck: !nowItem.isCheck};
    //                     }
    //                     return nowItem;
    //                 })
    //             }
    //         }
    //     });
    // }
    //
    // function onCopy() {
    //     let productIds: string[] = [];
    //     let categoryIds: string[] = [];
    //
    //     Object.keys(strIiko).map((nowCatId) => {
    //         let catCheck = false;
    //
    //         strIiko[nowCatId].items.map((nowItem) => {
    //             if (nowItem.isCheck) {
    //                 catCheck = true;
    //                 productIds.push(nowItem.id);
    //             }
    //         })
    //
    //         if (catCheck && nowCatId !== "none") {
    //             categoryIds.push(nowCatId);
    //         }
    //     });
    //
    //     api.copyIIkoProducts({setLoading: setCopyLoading}, currentMenuId, categoryIds, productIds).then((res) => {
    //         if (res.success) {
    //             navigate("/menus");
    //         }
    //     });
    // }

    return {
        // strIiko,
        collapse,
        onCollapse,
        // onCheckItem,
        // onCopy,
        // onCheckAll,
        // onCheckUnique,
        copyLoading
    }
}
