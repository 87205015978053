import {SubmitButton} from "../input/submitButton";
import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {OneVision} from "../../models/payments/oneVision";
import {Checkbox} from "../input/checkbox";
import {IKaspiPaymentDeviceBinding, IKaspiPaymentTradePoint} from "../../models/kaspi/kaspi";
import {addNotification} from "../../utils/toast/toast";
import {FormWithLoader} from "../loader/formWithLoader";
import {TXT} from "../../txtMap/embedded/txt";
import {RouteNames} from "../../migration/pages";

interface IPaymentSettings {
    paymentMethods: string[] | null,
    activatedPaymentMethods: string[] | null
}

export function PaymentModule() {
    const {i18n, api} = useGlobalContext()
    const {user, currentMenuId} = useUserContext()
    const [paymentSettings, setPaymentSettings] = useState<IPaymentSettings>({
        paymentMethods: null,
        activatedPaymentMethods: null
    })

    const [paymentMethods, setPaymentMethods] = useState<string[] | null>(null)

    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        setPaymentSettings({
            paymentMethods: null,
            activatedPaymentMethods: null
        })
        const controller = new AbortController()
        if (user?.org) {
            api.getMenuPaymentSettings({controller, setLoading}, user?.org.id, currentMenuId).then((res) => {
                if (res.data && user?.org?.id) {
                    setPaymentSettings(res.data)
                }
            })
            api.getMenuInformationalPaymentMethods({controller, setLoading}, currentMenuId).then((res) => {
                if (res.data) {
                    setPaymentMethods(res.data.paymentMethods)
                }
            })
        }
        return () => controller.abort()
    }, [user?.org, currentMenuId])

    function onChangePaymentMethods(b: boolean, v: string) {
        let paymentMethods = paymentSettings?.paymentMethods || []
        if (b) {
            paymentMethods.push(v)
        } else {
            paymentMethods = paymentMethods.filter((p) => p !== v)
        }
        setPaymentSettings({...paymentSettings, paymentMethods: paymentMethods})
    }

    function onSave() {
        api.saveMenuSettingsOrderPayment({setLoading: setSaveLoading}, currentMenuId, paymentSettings.paymentMethods || [])
    }


    const [kaspiPaymentSettings, setKaspiPaymentSettings] = useState<IKaspiPaymentTradePoint>(null)
    const [bindPaymentMethod, setBindPaymentMethod] = useState<IKaspiPaymentDeviceBinding>(null)
    const [nowSelected, setNowSelected] = useState<string>("")

    const [kaspiTradeLoading, setKaspiTradeLoading] = useState<boolean>(false)
    const [kaspiDeviceLoading, setKaspiDeviceLoading] = useState<boolean>(false)
    const [kaspiSaveDeleteLoading, setKaspiSaveDeleteLoading] = useState<boolean>(false)

    useEffect(() => {
        setKaspiPaymentSettings(null)
        setBindPaymentMethod(null)
        const controller = new AbortController()
        if (user?.org) {
            api.getKaspiPaymentTradePoints({controller, setLoading: setKaspiTradeLoading}, user?.org.id).then((res) => {
                if (res.data && user?.org?.id) {
                    setKaspiPaymentSettings(res.data)
                }
            })
            api.getKaspiPaymentDeviceBindings({
                controller,
                setLoading: setKaspiDeviceLoading
            }, currentMenuId).then((res) => {
                if (res.data) {
                    setBindPaymentMethod(res.data)
                }
            })
        }
        return () => controller.abort()
    }, [user?.org, currentMenuId])

    function onSaveKaspi() {
        if (!nowSelected) {
            addNotification({type: "info", message: i18n.select_payment_device()})
            return
        }
        api.createKaspiPaymentDeviceBinding({setLoading: setKaspiSaveDeleteLoading}, currentMenuId, nowSelected).then((res) => {
            if (res.success) {
                api.getKaspiPaymentDeviceBindings({
                    controller: new AbortController(),
                    setLoading: setKaspiDeviceLoading
                }, currentMenuId).then((res) => {
                    if (res.data) {
                        setBindPaymentMethod(res.data)
                    }
                })
            }
        })
    }

    function onDelete() {
        if (bindPaymentMethod?.id) {
            api.deleteKaspiPaymentDeviceBinding({setLoading: setKaspiSaveDeleteLoading}, bindPaymentMethod.id).then((res) => {
                if (res.data) {
                    setBindPaymentMethod(null)
                }
            })
        }
    }


    return (
        <FormWithLoader isloading={loading} className="form">

            {paymentMethods?.map((p) => {
                let meth = p as Exclude<keyof TXT, "runOne">
                return (
                    <Checkbox
                        checked={paymentSettings.paymentMethods?.includes(p) || false}
                        onChange={(b) => onChangePaymentMethods(b, p)}
                        label={i18n.runOne(meth)} children={null}
                    />
                )
            })}


            <Checkbox
                checked={paymentSettings.paymentMethods?.includes("onevision") || false}
                onChange={(b) => onChangePaymentMethods(b, "onevision")}
                label={"OneVision"}
                children={
                    !paymentSettings.activatedPaymentMethods?.includes("onevision") ?
                        <WarningSVG text={i18n.one_vision_bind_info_not_found()}/>
                        : null
                }
            />

            <Checkbox
                checked={paymentSettings.paymentMethods?.includes("liqpay") || false}
                onChange={(b) => onChangePaymentMethods(b, "liqpay")}
                label={"LiqPay"}
                children={
                    !paymentSettings.activatedPaymentMethods?.includes("liqpay") ?
                        <WarningSVG text={i18n.liqpay_bind_info_not_found()}/>
                        : null
                }
            />

            <Checkbox
                checked={paymentSettings.paymentMethods?.includes("kaspi") || false}
                onChange={(b) => onChangePaymentMethods(b, "kaspi")}
                label={"Kaspi"}
                children={
                    !paymentSettings.activatedPaymentMethods?.includes("kaspi") ?
                        <WarningSVG text={i18n.kaspi_bind_info_not_found()}/>
                        : null
                }
            />

            <Checkbox
                checked={paymentSettings.paymentMethods?.includes("jetpay") || false}
                onChange={(b) => onChangePaymentMethods(b, "jetpay")}
                label={"JetPay"}
                children={
                    !paymentSettings.activatedPaymentMethods?.includes("jetpay") ?
                        <WarningSVG text={i18n.jetpay_bind_info_not_found()}/>
                        : null
                }
            />

            {paymentSettings.paymentMethods?.includes("kaspi") && kaspiPaymentSettings &&
                <div className="form__row" style={{marginLeft: "30px"}}>
                    {kaspiPaymentSettings?.map((item) => {
                        return (
                            <Checkbox
                                checked={!nowSelected ? item.id === bindPaymentMethod?.tradePointId : item.id === nowSelected}
                                onClick={() => {
                                    setNowSelected(item.id)
                                }} key={item.id} disabled={!!bindPaymentMethod}
                                label={item.name}/>
                        )
                    })}
                    <div className="form__buttons mt-4">
                        <div className="form__buttons-left">
                            {bindPaymentMethod ?
                                <SubmitButton onSubmit={onDelete} label={i18n.unbind()}
                                              isLoading={kaspiSaveDeleteLoading} disabled={false}
                                              modal={true} isDelete={true}/>

                                :
                                <SubmitButton onSubmit={onSaveKaspi} label={i18n.bind()}
                                              disabled={false} isLoading={kaspiSaveDeleteLoading}
                                              modal={true}/>
                            }
                        </div>
                    </div>
                </div>}
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={onSave} label={i18n.save()}
                                  disabled={false} isLoading={saveLoading}
                                  modal={true}/>
                    <a href={RouteNames.SETTINGS} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </a>
                </div>
            </div>
        </FormWithLoader>
    )
}

function WarningSVG(props: { text: string }) {
    return (
        <svg width="14px" height="14px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#dc3545"
             stroke="#dc3545"><title>{props.text}</title>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g id="Complete">
                    <g id="info-circle">
                        <g>
                            <circle cx="12" cy="12" data-name="--Circle" fill="none" id="_--Circle" r="10"
                                    stroke="#dc3545" strokeLinecap="round" strokeLinejoin="round"
                                    strokeWidth="2"></circle>
                            <line fill="none" stroke="#dc3545" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" x1="12" x2="12" y1="12" y2="16"></line>
                            <line fill="none" stroke="#dc3545" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" x1="12" x2="12" y1="8" y2="8"></line>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
