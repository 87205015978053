import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {IOrder, IOrderItem, IOrderItemChange, IOrderSupItemChange, IOrderUpdateApi} from "../../models/orders/order";
import {useNavigate, useParams} from "react-router-dom";
import {Phone} from "../../models/other/phone";
import {I18nString} from "../../models/lang/i18nString";
import {RouteNames} from "../../migration/pages";
import {IOrderSupItem} from "../../models/orderSupItem/orderSupItem";

export function useOrderEdit() {
    const {api} = useGlobalContext()
    const {currentMenuId, menu} = useUserContext()
    let navigate = useNavigate()

    const [order, setOrder] = useState<IOrder>({
        "id": "",
        "createdAt": "",
        "number": "",
        customerComment: null,
        currentStatus: "",
        "currency": "",
        "customerContacts": {
            "name": "",
            "phone": {
                "number": "",
                "countryCode": ""
            }
        },
        "items": [],
        "delivery": {
            "type": "",
            "data": {
                "address": null,
                "detailedAddress": null,
                "deliveryComment": null,
                label: null,
                value: null,
                name: null,
                cinemaHallNumber: null,
                rowNumber: null,
                seatNumber: null,
            }
        },
        "totalPrice": 0,
        "charges": null,
        "discounts": null,
        "totalQuantity": 0,
        payment: null,
        iikoStatus: null,
        iikoDetailedStatus: null,
        iikoOrderTask: null,
        iiko: null,
        orderSupItems: null,
        originURLParams: {}
    })

    const [items, setItems] = useState<{ item: IOrderItemChange, value: string, label: string }[]>([])
    const [modalItem, setModalItem] = useState<IOrderItemChange | null>(null)
    const [modalItemIndex, setModalItemIndex] = useState<number | null>(null)


    const [supItems, setSupItems] = useState<IOrderSupItem[]>([])
    const [modalSupItem, setModalSupItem] = useState<IOrderSupItem | null>(null)
    const [modalSupItemIndex, setModalSupItemIndex] = useState<number | null>(null)

    const {id} = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)



    useEffect(() => {
        const controller = new AbortController()
        if (id && currentMenuId) {
            api.getOrderById({controller, setLoading}, currentMenuId, id).then((res) => {
                if (res.data) {
                    setOrder(res.data)
                }
            })
        }
        if (currentMenuId) {
            api.getOrderItems({controller, setLoading}, currentMenuId).then((res) => {
                if (res.data) {
                    let newItems: { item: IOrderItemChange, value: string, label: string }[] = []
                    res.data.MenuOrderItems.forEach((item) => {
                        newItems.push({item: item, value: item.id, label: item.name[menu.i18n.defaultLang]})
                    })
                    setItems(newItems)
                }
            })
        }
        return () => controller.abort()
    }, [id, currentMenuId])

    const allPrice = () => {
        let price = 0
        order.items.forEach((item) => {
            price += Math.ceil(item.totalPrice / 100)
        })
        order.charges?.forEach((charge) => {
            price += Math.ceil(charge.amount / 100)
        })
        order.orderSupItems?.forEach((supItem) => {
            price += Math.ceil(supItem.price * supItem.quantity / 100)
        })
        return price
    }

    //Events
    function onChangeClientName(name: string) {
        setOrder({
            ...order,
            customerContacts: {
                ...order.customerContacts,
                name: name
            }
        })
    }

    function onChangeClientPhone(phone: Phone) {
        setOrder({
            ...order,
            customerContacts: {
                ...order.customerContacts,
                phone: phone
            }
        })
    }

    function onChangeDeliveryType(type: string) {
        setOrder({
            ...order,
            delivery: {
                ...order.delivery,
                type: type
            }
        })
    }

    function onChangeMainAddress(address: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        address: address
                    }
                }
            })
        }
    }

    function onChangeDetailAddress(address: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        detailedAddress: address
                    }
                }
            })
        }
    }

    function onChangeDeliveryComment(comment: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        deliveryComment: comment
                    }
                }
            })
        }
    }

    function onChangeDeliveryCinemaHallNumber(cinemaHallNumber: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        cinemaHallNumber: cinemaHallNumber
                    }
                }
            })
        }
    }

    function onChangeDeliveryRowNumber(rowNumber: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        rowNumber: rowNumber
                    }
                }
            })
        }
    }

    function onChangeDeliverySeatNumber(seatNumber: string) {
        if (order.delivery.data) {
            setOrder({
                ...order,
                delivery: {
                    ...order.delivery,
                    data: {
                        ...order.delivery.data,
                        seatNumber: seatNumber
                    }
                }
            })
        }
    }

    function onAddItem() {
        let orderItemsIds = order.items.map((v) => v.id)
        let filteredItems = items.filter((v) => !orderItemsIds.includes(v.item.id))
        let item = filteredItems.length > 0 ? filteredItems[0].item : null
        if (item) {
            item.quantity = 1
        }
        setModalItemIndex(null)
        setModalItem(item)
    }

    function onDeleteItem(index: number) {
        setOrder({
            ...order,
            items: order.items.filter((v, i) => i !== index)
        })
    }

    function onDeleteSupItem(index: number) {
        setOrder({
            ...order,
            orderSupItems: order.orderSupItems?.filter((v, i) => i !== index) || null
        })
    }

    function onEditItem(orderItem: IOrderItem) {
        let item = items.find((v) => v.item.id === orderItem.id)
        if (item) {
            item.item.quantity = 1
            if (orderItem.modExtras) {
                item.item.prices.modExtras = orderItem.modExtras.map((v) => {
                    return {
                        isHidden: false,
                        label: v.label,
                        priceDelta: v.priceDelta,
                    }
                })
            }
            if (orderItem.modSelects) {
                item.item.prices.modSelects = orderItem.modSelects.map((v) => {
                    return {
                        isHidden: false,
                        isRequired: false,
                        header: v.header,
                        options: [{
                            label: v.label,
                            priceDelta: v.priceDelta,
                            status: "ACTIVE",
                        }],
                    }
                })
            }
        }
        setModalItemIndex(order.items.findIndex((v) => v.id === orderItem.id))
        setModalItem(item ? item.item : null)
    }

    function onEditSupItem(orderSupItem: IOrderSupItem) {
        const item = order.orderSupItems?.find((v) => v.id === orderSupItem.id) ?? null;
        const index = order.orderSupItems ? order.orderSupItems.findIndex((v) => v.id === orderSupItem.id) : -1;
        setModalSupItem(item);
        setModalSupItemIndex(index !== -1 ? index : null);
    }

    function onChangeItemAmount(index: number, quantity: number) {
        let items = [...order.items]
        let nowItem = items[index]
        nowItem.quantity = quantity
        let totalPrice = nowItem.variant.price
        if (nowItem.modExtras) {
            nowItem.modExtras.forEach((v) => {
                totalPrice += v.priceDelta
            })
        }
        if (nowItem.modSelects) {
            nowItem.modSelects.forEach((v) => {
                totalPrice += v.priceDelta
            })
        }
        totalPrice *= nowItem.quantity
        nowItem.totalPrice = totalPrice
        items[index] = nowItem
        setOrder({...order, items: items})
    }

    function onChangeSupItemAmount(index: number, quantity: number) {
        let supItems = [...(order.orderSupItems ?? [])];
        let nowSupItem = supItems[index]
        nowSupItem.quantity = quantity
        supItems[index] = nowSupItem
        setOrder({
            ...order,
            orderSupItems: supItems
        });
    }

    function onApproveSaveItem() {
        if (modalItem) {
            //sum price
            let totalPrice = modalItem.prices.primary.price
            if (modalItem.prices.modExtras) {
                modalItem.prices.modExtras.forEach((v) => {
                    totalPrice += v.priceDelta
                })
            }
            if (modalItem.prices.modSelects) {
                modalItem.prices.modSelects.forEach((v) => {
                    totalPrice += v.options[0].priceDelta
                })
            }
            totalPrice *= modalItem.quantity || 1
            // modSelects
            let modSelects: {
                label: I18nString,
                header: I18nString,
                priceDelta: number,
            }[] = []
            if (modalItem.prices.modSelects) {
                modalItem.prices.modSelects.forEach((v) => {
                    modSelects.push({
                        label: v.options[0].label,
                        header: v.header,
                        priceDelta: v.options[0].priceDelta,
                    })
                })
            }
            let newOrderItem: IOrderItem = {
                id: modalItem.id,
                name: modalItem.name,
                quantity: modalItem.quantity || 1,
                totalPrice: totalPrice,
                modSelects: modSelects,
                modExtras: modalItem.prices.modExtras?.map((v) => {
                    return {
                        label: v.label,
                        priceDelta: v.priceDelta,
                    }
                }) || null,
                variant: {
                    label: modalItem.prices.primary.label,
                    price: modalItem.prices.primary.price,
                }
            }
            if (modalItemIndex !== null) {
                setOrder({
                    ...order,
                    items: order.items.map((v, i) => {
                        if (i === modalItemIndex) {
                            return newOrderItem
                        }
                        return v
                    })
                })
            } else {
                setOrder({
                    ...order,
                    items: [...order.items, newOrderItem]
                })
            }
            setModalItem(null)
        }
    }

    function onApproveSaveSupItem() {
        if (modalSupItem) {
            let newOrderSupItem: IOrderSupItem = {
                id: modalSupItem.id,
                name: modalSupItem.name,
                quantity: modalSupItem.quantity || 1,
                price: modalSupItem.price,
                menuId: modalSupItem.menuId,
                minQuantity: modalSupItem.minQuantity,
                isDisabled: modalSupItem.isDisabled
            };

            if (modalSupItemIndex !== null) {
                setOrder({
                    ...order,
                    orderSupItems: order.orderSupItems?.map((v, i) => {
                        if (i === modalSupItemIndex) {
                            return newOrderSupItem;
                        }
                        return v;
                    }) || null,
                });
            } else {
                setOrder({
                    ...order,
                    orderSupItems: [...(order.orderSupItems || []), newOrderSupItem]
                });
            }
            setModalSupItem(null);
            setModalSupItemIndex(null);
        }
    }


    function onChangeCharges(index: number, label: I18nString, price: number) {
        if (order.charges) {
            setOrder({
                ...order,
                charges: order.charges.map((v, i) => {
                    if (i === index) {
                        v.amount = price
                        v.charge = {
                            type: "fixedAmount",
                            data: {
                                name: label,
                                value: price
                            }
                        }
                    }
                    return v
                })
            })
        }
    }

    function onAddCharges() {
        let charges = order.charges || []
        charges.push({
            amount: 0,
            charge: {
                "type": "fixedAmount",
                "data": {
                    "name": {},
                    "value": 0
                }
            }
        })
        setOrder({
            ...order,
            charges: charges
        })
    }

    function onDeleteCharge(index: number) {
        if (order.charges) {
            setOrder({
                ...order,
                charges: order.charges.filter((v, i) => i !== index)
            })
        }
    }

    function onSave() {
        let newOrderApi: IOrderUpdateApi = {
            orderId: order.id,
            menuId: currentMenuId,
            items: order.items.map((v) => {

                return {
                    itemId: v.id,
                    name: v.name,
                    variant: {label: v.variant.label, price: v.variant.price},
                    modSelects: v.modSelects || [],
                    modExtras: v.modExtras || [],
                    quantity: v.quantity,
                    discount: 0,
                    totalPrice: v.totalPrice,

                }
            }),
            delivery: {
                type: order.delivery.type,
                data: {
                    mainAddress: order.delivery.data?.address || "",
                    detailedAddress: order.delivery.data?.detailedAddress || "",
                    deliveryComment: order.delivery.data?.deliveryComment || "",
                    label: order.delivery.data?.label || {},
                    value: order.delivery.data?.value || "",
                    name: order.delivery.data?.name || {},
                    cinemaHallNumber: order.delivery.data?.cinemaHallNumber || "",
                    rowNumber: order.delivery.data?.rowNumber || "",
                    seatNumber: order.delivery.data?.seatNumber || "",
                }
            },
            charges: (order.charges || []).map((v) => {
                return {
                    amount: v.amount,
                    name: v.charge.data.name || {},
                }
            }),
            customerContacts: order.customerContacts,
            orderSupItems: order.orderSupItems?.map((supItem) => {
                    return {
                        id: supItem.id,
                        name: supItem.name,
                        quantity: supItem.quantity,
                        price: supItem.price
                    }
                }
            ) || null
        }
        api.updateOrderManualy({setLoading: setSaveLoading}, newOrderApi).then((res) => {
            if (res.success) {
                navigate(RouteNames.ORDERS)
            }
        })
    }

    return {
        order,
        menu,
        items,
        supItems,
        currentMenuId,
        allPrice,
        onAddItem,
        onEditItem,
        onEditSupItem,
        onDeleteItem,
        onDeleteSupItem,
        onApproveSaveItem,
        onApproveSaveSupItem,
        onChangeItemAmount,
        onChangeSupItemAmount,
        onChangeClientName,
        onChangeMainAddress,
        onChangeClientPhone,
        onChangeDeliveryType,
        modalItem,
        modalSupItem,

        onChangeDeliveryCinemaHallNumber,
        onChangeDeliveryRowNumber,
        onChangeDeliverySeatNumber,

        setModalItem,
        setModalSupItem,
        onChangeDetailAddress,
        onChangeDeliveryComment,
        onAddCharges,
        onChangeCharges,
        onDeleteCharge,
        onSave,

        saveLoading,
        loading,
    }
}
