import {FeedbackDashboardData} from "migration/entities/Feedback";
import {FeedbackDashboardDataAction, FeedbackDashboardDataActionEnum, FeedbackDashboardDataState} from "./types";

const initialState: FeedbackDashboardDataState = {
    feedbackDashboardData: {} as FeedbackDashboardData,
    feedbacks: [],
    feedbacksCount: 0,
    isLoadingGetFeedbackDashboardData: false,
    isLoadingGetFeedbacks: false,
}

export default function feedbackDashboardDataReducer(state = initialState, action: FeedbackDashboardDataAction): FeedbackDashboardDataState {
    switch (action.type) {
        case FeedbackDashboardDataActionEnum.SET_FEEDBACK_DASHBOARD_DATA:
            return {...state, feedbackDashboardData: action.payload};
        case FeedbackDashboardDataActionEnum.SET_FEEDBACKS:
            return {...state, feedbacks: action.payload};
        case FeedbackDashboardDataActionEnum.SET_FEEDBACKS_COUNT:
            return {...state, feedbacksCount: action.payload};
        case FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACK_DASHBOARD_DATA:
            return {...state, isLoadingGetFeedbackDashboardData: action.payload};
        case FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACKS:
            return {...state, isLoadingGetFeedbacks: action.payload};
        default:
            return state;
    }
}
