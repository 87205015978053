import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {isEmpty} from "lodash";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ModalBase} from "../ModalBase";

interface ModalAddCommonProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<boolean>
}

export const ModalAddCommon: FunctionComponent<ModalAddCommonProps> = ({isOpen, setIsOpen}) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {kamiMenuItemsList} = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);
    const {setCommonCrossSellingProducts, removeCommonCrossSellingProducts} = useActions();
    const [associatedProductsListState, setAssociatedProductsListState] = useState<string[]>(() => {
        return crossSellingMapping?.commonProductIds;
    });
    const [searchQuery, setSearchQuery] = useState<string>("");

    const associatedProductsList = useMemo<string[]>(() => {
        if (isEmpty(associatedProductsListState)) return [];
        return associatedProductsListState || [];
    }, [associatedProductsListState]);

    const addAssociatedProduct = (itemId: string) => {
        let copy = [...associatedProductsListState];
        if (associatedProductsListState.includes(itemId)) {
            copy = copy.filter(item => item !== itemId);
            setAssociatedProductsListState(copy);
            return;
        }
        const notUniqueArr = [...copy, itemId];
        copy = notUniqueArr.filter((item, index) => index === notUniqueArr.indexOf(item));
        setAssociatedProductsListState(copy);
    }

    const clearAssociatedProductsList = () => {
        removeCommonCrossSellingProducts({productIds: associatedProductsListState?.filter(item => Object.keys(kamiMenuItemsList).includes(item))}, crossSellingMapping);
        setIsOpen(false);
    }

    const onCancel = () => {
        setIsOpen(false);
    }

    const onSubmit = () => {
        setCommonCrossSellingProducts({productIds: associatedProductsList}, crossSellingMapping);
        setIsOpen(false);
    }

    useEffect(() => {
        setAssociatedProductsListState(() => crossSellingMapping?.commonProductIds);
    }, [crossSellingMapping]);

    return (
        <ModalBase
            associatedProductsList={associatedProductsList}
            addAssociatedProduct={addAssociatedProduct}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmit={onSubmit}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title={txt.add_to_common[currentLang]}
            clearCommonProductsList={clearAssociatedProductsList}
        />
    );
};
