import {SubmitButton} from "../input/submitButton";
import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {InputText} from "../input/inputText";
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";
import {JetPay} from "../../models/payments/jetpay";

export function JetpayPaymentModule() {
    const {i18n, api} = useGlobalContext()
    const {user} = useUserContext()
    const [jetpay, setJetpay] = useState<JetPay>({orgId: "", projectId: 0, encryptedSecretKey: ""})
    const [secretKey, setSecretKey] = useState<string>("")

    const [loading, setLoading] = useState<boolean>(false)
    const [encryptionLoading, setEncryptionLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    useEffect(() => {
        const controller = new AbortController()
        if (user?.org) {
            api.getJetpaySettings({setLoading: setLoading, controller}, user?.org.id).then((res) => {
                if (res.data && user?.org?.id) {
                    setJetpay({
                        orgId: user?.org.id,
                        encryptedSecretKey: res.data?.encryptedSecretKey,
                        projectId: res.data?.projectId,
                    })
                }
            })
        }
        return () => controller.abort()
    }, [user?.org])

    function encryptSecretKey() {
        api.encodeJetpayPaymentKey({setLoading: setEncryptionLoading}, secretKey).then((res) => {
            if (res.data) {
                setJetpay({...jetpay, encryptedSecretKey: res.data.encryptedSecretKey})
            }
        })
    }

    function onChangeProjectId(v: string) {
        setJetpay({...jetpay, projectId: Number(v)})
    }

    function onRemoveEncryptionKey() {
        setJetpay({...jetpay, encryptedSecretKey: ""})
    }

    function onSave() {
        api.saveJetpaySettings({setLoading: setSaveLoading}, jetpay)
    }

    return (
        <FormWithLoader isloading={loading} className="form">
            <InputText label={i18n.project_id()} value={jetpay.projectId.toString()} onChange={onChangeProjectId} isValidate={true}/>
            {jetpay.encryptedSecretKey === "" ?
                <InputText label={i18n.secret_key()} value={secretKey} onChange={setSecretKey} isValidate={true}/>
                :
                <div className="control">
                    <label className="control__label">{i18n.encrypted_key()}</label>
                    <div className={"iiko_Tag"}>
                        <div className={"control__input"}
                             style={{
                                 display: "flex",
                                 alignItems: "center",
                                 textOverflow: "clip",
                                 overflow: "hidden"
                             }}>{jetpay.encryptedSecretKey}</div>
                        <DeleteSVG onClick={onRemoveEncryptionKey}/>
                    </div>
                </div>
            }
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    {jetpay.encryptedSecretKey === "" ?
                        <SubmitButton onSubmit={encryptSecretKey} label={i18n.encode()}
                                      disabled={false} isLoading={encryptionLoading}
                                      modal={true}/>
                        :
                        <SubmitButton onSubmit={onSave} label={i18n.save()}
                                      disabled={false} isLoading={saveLoading}
                                      modal={true}/>
                    }
                    <a href={RouteNames.SETTINGS} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </a>
                </div>
            </div>
        </FormWithLoader>
    )
}
