import {Link} from "react-router-dom";
import Select from 'react-select'
import {options} from "../../utils/options/options";
import {useClientForm} from "../../hooks/staff/clientForm";
import {SubmitButton} from "../input/submitButton";
import {InputText} from "../input/inputText";
import {useGlobalContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../loader/formWithLoader";
import {TaggingSettings} from "../../migration/pages/staff/organizations/components/TaggingSettings";

export function ClientForm() {
    const info = useClientForm()
    const {i18n} = useGlobalContext()
    return (
        <FormWithLoader isloading={info.loading} className={"form"}>
            <InputText label={i18n.org_name()} value={info.userinfo?.org_name || ""}
                       onChange={info.onChangeOrgName} isValidate={true}/>

            <InputText label={i18n.org_address()} value={info.userinfo?.organization_address || ""}
                       onChange={info.onChangeOrgAddress} isValidate={true}/>

            <InputText label={i18n.login()} value={info.userinfo?.user_login || ""}
                       onChange={info.onChangeLogin} isValidate={true}/>

            <InputText label={i18n.the_subdomain()} value={info.userinfo.subdomain}
                       onChange={info.onChangeSubdomain} isValidate={true}/>


            <div className="control">
                <div className="control__label">{i18n.default_language()}</div>
                <Select options={options}
                        placeholder={i18n.choose_language()}
                        onChange={(v) => info.onChangeMenuLang(v ? v.value : "RU")}
                        value={
                            options.filter((v) => v.value === info.userinfo.defaultlang)
                        }
                />
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="orders_checkbox" checked={info.userinfo?.can_use_client_orders}
                           onChange={(e) => info.onChangeCanUseClientOrders(e.currentTarget.checked)}
                    />
                    <span>{i18n.co_module_available()}</span>
                </label>
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="hybrid_pl_checkbox" checked={info.userinfo?.can_use_in_door_orders}
                           onChange={(e) => info.onChangeCanUseInDoorOrders(e.currentTarget.checked)}
                    />
                    <span>{i18n.in_door_orders()}</span>
                </label>
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="hybrid_pl_checkbox" checked={info.userinfo?.can_use_iiko}
                           onChange={(e) => info.onChangeCanUseIiko(e.currentTarget.checked)}
                    />
                    <span>{"IIKO"}</span>
                </label>
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="hybrid_pl_checkbox" checked={info.userinfo?.can_use_rkeeper}
                           onChange={(e) => info.onChangeCanUseRkeeper(e.currentTarget.checked)}
                    />
                    <span>{"R_KEEPER"}</span>
                </label>
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="hybrid_pl_checkbox" checked={info.userinfo?.can_use_payment}
                           onChange={(e) => info.onChangeCanUsePayment(e.currentTarget.checked)}
                    />
                    <span>{i18n.payment()}</span>
                </label>
                {info.userinfo?.can_use_payment &&
                    <label className="custom-checkbox" style={{marginLeft: "20px"}}>
                        <input type="checkbox" name="hybrid_pl_checkbox" checked={info.paymentMethods.onevision}
                               onChange={(e) => info.onCheckOneVision(e.currentTarget.checked)}
                        />
                        <span>{"OneVision"}</span>
                    </label>
                }

                {info.userinfo?.can_use_payment &&
                    <label className="custom-checkbox" style={{marginLeft: "20px"}}>
                        <input type="checkbox" name="hybrid_pl_checkbox" checked={info.paymentMethods.kaspi}
                               onChange={(e) => info.onCheckKaspi(e.currentTarget.checked)}
                        />
                        <span>{"Kaspi"}</span>
                    </label>
                }

                {info.userinfo?.can_use_payment &&
                    <label className="custom-checkbox" style={{marginLeft: "20px"}}>
                        <input type="checkbox" name="hybrid_pl_checkbox" checked={info.paymentMethods.liqpay}
                               onChange={(e) => info.onCheckLiqpay(e.currentTarget.checked)}
                        />
                        <span>{"Liqpay"}</span>
                    </label>
                }

                {info.userinfo?.can_use_payment &&
                    <label className="custom-checkbox" style={{marginLeft: "20px"}}>
                        <input type="checkbox" name="hybrid_pl_checkbox" checked={info.paymentMethods.jetpay}
                               onChange={(e) => info.onCheckJetpay(e.currentTarget.checked)}
                        />
                        <span>{"JetPay"}</span>
                    </label>
                }
            </div>

            <div className="control">
                <label className="custom-checkbox">
                    <input type="checkbox" name="hybrid_pl_checkbox" checked={info.userinfo?.feedback}
                           onChange={(e) => info.onCheckFeedback(e.currentTarget.checked)}
                    />
                    <span>{i18n.feedbacks()}</span>
                </label>
            </div>

            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.onSubmit} label={i18n.save()} modal={true} isLoading={info.saveLoading}
                                  disabled={false}/>
                    <Link to="/staff/clients/" className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>

            <TaggingSettings type={"org"} orgId={info.userinfo?.id}/>
        </FormWithLoader>
    )
}
