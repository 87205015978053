import {IikoApiKey} from "migration/entities/iikoApiKey";
import {IikoCompanyId, IikoProductId} from "migration/entities/baseAliases";
import {IikoStopLists} from "migration/entities/iikoStopLists";
import {WebhookSettings} from "migration/entities/webhookSettings";
import {IikoPaymentType} from "migration/entities/iikoPaymentType";
import {IikoNomenclature} from "migration/entities/iikoNomenclature";
import {IikoMenuSettings} from "migration/entities/iikoMenuSettings";
import {IikoOrganizations} from "migration/entities/iikoOrganizations";
import {IikoTerminalGroups} from "migration/entities/iikoTerminalGroups";
import {IikoRestaurantSections} from "migration/entities/iikoRestaurantSections";
import {IikoExternalMenu, IikoExternalMenuItem} from "migration/entities/iikoExternalMenu";
import {IikoExternalMenuInfo} from "migration/entities/iikoExternalMenuInfo";

export interface IikoState {
    iikoApiKey: IikoApiKey;
    iikoNomenclature: IikoNomenclature;
    iikoOrganizations: IikoOrganizations;
    iikoPaymentTypes: IikoPaymentType;
    iikoRestaurantSections: IikoRestaurantSections;
    iikoStopLists: IikoStopLists;
    iikoTerminalGroups: IikoTerminalGroups;
    webhookSettings: WebhookSettings;
    iikoMenuSettings: IikoMenuSettings;
    iikoCompanyId: IikoCompanyId;
    iikoExternalMenu: IikoExternalMenu;
    iikoExternalMenuItems: {[key: IikoProductId]: IikoExternalMenuItem};
    iikoExternalMenuInfo: IikoExternalMenuInfo;

    errorMsgIikoNomenclature: string;
    errorMsgIikoOrganizations: string;
    errorMsgIikoPaymentTypes: string;
    errorMsgIikoRestaurantSections: string;
    errorMsgIikoStopLists: string;
    errorMsgIikoTerminalGroups: string;
    errorMsgWebhookSettings: string;
    errorMsgIikoMenuSettings: string;
    errorMsgIikoOrganizationSettings: string;

    isLoadingGetIikoApiKey: boolean;
    isLoadingGetNomenclature: boolean;
    isLoadingGetIikoOrganizations: boolean;
    isLoadingGetIikoPaymentTypes: boolean;
    isLoadingGetRestaurantSections: boolean;
    isLoadingGetStopLists: boolean;
    isLoadingGetIikoTerminalGroups: boolean;
    isLoadingGetWebhookSettings: boolean;
    isLoadingGetIikoMenuSettings: boolean;
    isLoadingGetIikoOrganizationSettings: boolean;

    isLoadingConfigWebhookSettings: boolean;
    isLoadingDeleteMenuIikoSettings: boolean;
    isLoadingDeleteOrganizationIikoSettings: boolean;
    isLoadingImportIikoProductBindings: boolean;
    isLoadingSaveIikoProductBindings: boolean;
    isLoadingSaveIikoTableBindings: boolean;
    isLoadingSaveMenuIikoSettings: boolean;
    isLoadingSaveOrganizationIikoSettings: boolean;
    isLoadingApproveIikoOrder: boolean;
    isLoadingCancelIikoDeliveryOrder: boolean;
    isLoadingGetIikoExternalMenu: boolean;
    isLoadingGetIikoExternalMenuInfo: boolean;
}

export enum IikoActionEnum {
    SET_IIKO_API_KEY = "SET_IIKO_API_KEY",
    SET_IIKO_NOMENCLATURE = "SET_IIKO_NOMENCLATURE",
    SET_IIKO_ORGANIZATIONS = "SET_IIKO_ORGANIZATIONS",
    SET_IIKO_PAYMENT_TYPES = "SET_IIKO_PAYMENT_TYPES",
    SET_IIKO_RESTAURANT_SECTIONS = "SET_IIKO_RESTAURANT_SECTIONS",
    SET_IIKO_STOP_LISTS = "SET_IIKO_STOP_LISTS",
    SET_IIKO_TERMINAL_GROUPS = "SET_IIKO_TERMINAL_GROUPS",
    SET_WEBHOOK_SETTINGS = "SET_WEBHOOK_SETTINGS",
    SET_IIKO_MENU_SETTINGS = "SET_IIKO_MENU_SETTINGS",
    SET_IIKO_COMPANY_ID = "SET_IIKO_COMPANY_ID",
    SET_IIKO_EXTERNAL_MENU = "SET_IIKO_EXTERNAL_MENU",
    SET_IIKO_EXTERNAL_MENU_ITEMS = "SET_IIKO_EXTERNAL_MENU_ITEMS",
    SET_IIKO_EXTERNAL_MENU_INFO = "SET_IIKO_EXTERNAL_MENU_INFO",

    SET_ERROR_MSG_IIKO_NOMENCLATURE = "SET_ERROR_MSG_IIKO_NOMENCLATURE",
    SET_ERROR_MSG_IIKO_ORGANIZATIONS = "SET_ERROR_MSG_IIKO_ORGANIZATIONS",
    SET_ERROR_MSG_IIKO_PAYMENT_TYPES = "SET_ERROR_MSG_IIKO_PAYMENT_TYPES",
    SET_ERROR_MSG_IIKO_RESTAURANT_SECTIONS = "SET_ERROR_MSG_IIKO_RESTAURANT_SECTIONS",
    SET_ERROR_MSG_IIKO_STOP_LISTS = "SET_ERROR_MSG_IIKO_STOP_LISTS",
    SET_ERROR_MSG_IIKO_TERMINAL_GROUPS = "SET_ERROR_MSG_IIKO_TERMINAL_GROUPS",
    SET_ERROR_MSG_WEBHOOK_SETTINGS = "SET_ERROR_MSG_WEBHOOK_SETTINGS",
    SET_ERROR_MSG_IIKO_MENU_SETTINGS = "SET_ERROR_MSG_IIKO_MENU_SETTINGS",
    SET_ERROR_MSG_IIKO_ORGANIZATION_SETTINGS = "SET_ERROR_MSG_IIKO_ORGANIZATION_SETTINGS",

    SET_IS_LOADING_GET_IIKO_API_KEY = "SET_IS_LOADING_GET_IIKO_API_KEY",
    SET_IS_LOADING_GET_IIKO_NOMENCLATURE = "SET_IS_LOADING_GET_IIKO_NOMENCLATURE",
    SET_IS_LOADING_GET_IIKO_ORGANIZATIONS = "SET_IS_LOADING_GET_IIKO_ORGANIZATIONS",
    SET_IS_LOADING_GET_IIKO_PAYMENT_TYPES = "SET_IS_LOADING_GET_IIKO_PAYMENT_TYPES",
    SET_IS_LOADING_GET_IIKO_RESTAURANT_SECTIONS = "SET_IS_LOADING_GET_IIKO_RESTAURANT_SECTIONS",
    SET_IS_LOADING_GET_IIKO_STOP_LISTS = "SET_IS_LOADING_GET_IIKO_STOP_LISTS",
    SET_IS_LOADING_GET_IIKO_TERMINAL_GROUPS = "SET_IS_LOADING_GET_IIKO_TERMINAL_GROUPS",
    SET_IS_LOADING_GET_WEBHOOK_SETTINGS = "SET_IS_LOADING_GET_WEBHOOK_SETTINGS",
    SET_IS_LOADING_GET_IIKO_MENU_SETTINGS = "SET_IS_LOADING_GET_IIKO_MENU_SETTINGS",
    SET_IS_LOADING_GET_IIKO_ORGANIZATION_SETTINGS = "SET_IS_LOADING_GET_IIKO_ORGANIZATION_SETTINGS",

    SET_IS_LOADING_CONFIG_WEBHOOK_SETTINGS = "SET_IS_LOADING_CONFIG",
    SET_IS_LOADING_DELETE_MENU_IIKO_SETTINGS = "SET_IS_LOADING_DELETE_MENU_IIKO_SETTINGS",
    SET_IS_LOADING_DELETE_ORGANIZATION_IIKO_SETTINGS = "SET_IS_LOADING_DELETE_ORGANIZATION_IIKO_SETTINGS",
    SET_IS_LOADING_IMPORT_IIKO_PRODUCTS = "SET_IMPORT_IIKO_PRODUCTS",
    SET_IS_LOADING_SAVE_IIKO_PRODUCT_BINDINGS = "SET_IS_LOADING_SAVE_IIKO_PRODUCT_BINDINGS",
    SET_IS_LOADING_SAVE_IIKO_TABLE_BINDINGS = "SET_IS_LOADING_SAVE_IIKO_TABLE_BINDINGS",
    SET_IS_LOADING_SAVE_MENU_IIKO_SETTINGS = "SET_IS_LOADING_SAVE_MENU_IIKO_SETTINGS",
    SET_IS_LOADING_SAVE_ORGANIZATION_IIKO_SETTINGS = "SET_IS_LOADING_SAVE_ORGANIZATION_IIKO_SETTINGS",
    SET_IS_LOADING_APPROVE_IIKO_ORDER = "SET_IS_LOADING_APPROVE_IIKO_ORDER",
    SET_IS_LOADING_CANCEL_IIKO_DELIVERY_ORDER = "SET_IS_LOADING_CANCEL_IIKO_ORDER",
    SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU = "SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU",
    SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU_INFO = "SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU_INFO",
}

export interface SetIikoApiKeyAction {
    type: IikoActionEnum.SET_IIKO_API_KEY;
    payload: IikoApiKey;
}

export interface SetIikoNomenclatureAction {
    type: IikoActionEnum.SET_IIKO_NOMENCLATURE;
    payload: IikoNomenclature;
}

export interface SetIikoOrganizationsAction {
    type: IikoActionEnum.SET_IIKO_ORGANIZATIONS;
    payload: IikoOrganizations;
}

export interface SetIikoPaymentTypesAction {
    type: IikoActionEnum.SET_IIKO_PAYMENT_TYPES;
    payload: IikoPaymentType;
}

export interface SetIikoRestaurantSectionsAction {
    type: IikoActionEnum.SET_IIKO_RESTAURANT_SECTIONS;
    payload: IikoRestaurantSections;
}

export interface SetIikoStopListsAction {
    type: IikoActionEnum.SET_IIKO_STOP_LISTS;
    payload: IikoStopLists;
}

export interface SetIikoTerminalGroupsAction {
    type: IikoActionEnum.SET_IIKO_TERMINAL_GROUPS;
    payload: IikoTerminalGroups;
}

export interface SetWebhookSettingsAction {
    type: IikoActionEnum.SET_WEBHOOK_SETTINGS;
    payload: WebhookSettings;
}

export interface SetIikoMenuSettingsAction {
    type: IikoActionEnum.SET_IIKO_MENU_SETTINGS;
    payload: IikoMenuSettings;
}

export interface SetIikoCompanyIdAction {
    type: IikoActionEnum.SET_IIKO_COMPANY_ID;
    payload: IikoCompanyId;
}

export interface SetIikoExternalMenuAction {
    type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU;
    payload: IikoExternalMenu;
}

export interface SetIikoExternalMenuItemsAction {
    type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU_ITEMS;
    payload: {[key: IikoProductId]: IikoExternalMenuItem};
}

export interface SetIikoExternalMenuInfoAction {
    type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU_INFO;
    payload: IikoExternalMenuInfo;
}

export interface SetErrorMsgGetIikoNomenclatureAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_NOMENCLATURE;
    payload: string;
}

export interface SetErrorMsgGetIikoOrganizationsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATIONS;
    payload: string;
}

export interface SetErrorMsgGetIikoPaymentTypesAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_PAYMENT_TYPES;
    payload: string;
}

export interface SetErrorMsgGetIikoRestaurantSectionsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_RESTAURANT_SECTIONS;
    payload: string;
}

export interface SetErrorMsgGetIikoStopListsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_STOP_LISTS;
    payload: string;
}

export interface SetErrorMsgGetIikoTerminalGroupsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_TERMINAL_GROUPS;
    payload: string;
}

export interface SetErrorMsgGetWebhookSettingsAction {
    type: IikoActionEnum.SET_ERROR_MSG_WEBHOOK_SETTINGS;
    payload: string;
}

export interface SetErrorMsgGetIikoMenuSettingsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_MENU_SETTINGS;
    payload: string;
}

export interface SetErrorMsgGetIikoOrganizationSettingsAction {
    type: IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATION_SETTINGS;
    payload: string;
}

export interface SetIsLoadingGetIikoApiKeyAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_API_KEY;
    payload: boolean;
}

export interface SetIsLoadingGetIikoNomenclatureAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_NOMENCLATURE;
    payload: boolean;
}

export interface SetIsLoadingGetIikoOrganizationsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATIONS;
    payload: boolean;
}

export interface SetIsLoadingGetIikoPaymentTypesAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_PAYMENT_TYPES;
    payload: boolean;
}

export interface SetIsLoadingGetRestaurantSectionsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_RESTAURANT_SECTIONS;
    payload: boolean;
}

export interface SetIsLoadingGetStopListsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_STOP_LISTS;
    payload: boolean;
}

export interface SetIsLoadingGetIikoTerminalGroupsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_TERMINAL_GROUPS;
    payload: boolean;
}

export interface SetIsLoadingGetWebhookSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_WEBHOOK_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingGetIikoMenuSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_MENU_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingGetIikoOrganizationSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATION_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingConfigWebhookSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_CONFIG_WEBHOOK_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingDeleteMenuIikoSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_DELETE_MENU_IIKO_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingDeleteOrganizationIikoSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_DELETE_ORGANIZATION_IIKO_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingImportIikoProductsAction {
    type: IikoActionEnum.SET_IS_LOADING_IMPORT_IIKO_PRODUCTS;
    payload: boolean;
}

export interface SetIsLoadingSaveIikoProductBindingsAction {
    type: IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_PRODUCT_BINDINGS;
    payload: boolean;
}

export interface SetIsLoadingSaveIikoTableBindingsAction {
    type: IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_TABLE_BINDINGS;
    payload: boolean;
}

export interface SetIsLoadingSaveMenuIikoSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_SAVE_MENU_IIKO_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingSaveOrganizationIikoSettingsAction {
    type: IikoActionEnum.SET_IS_LOADING_SAVE_ORGANIZATION_IIKO_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingApproveIikoOrderAction {
    type: IikoActionEnum.SET_IS_LOADING_APPROVE_IIKO_ORDER;
    payload: boolean;
}

export interface SetIsLoadingCancelIikoDeliveryOrderAction {
    type: IikoActionEnum.SET_IS_LOADING_CANCEL_IIKO_DELIVERY_ORDER;
    payload: boolean;
}

export interface SetIsLoadingGetIikoExternalMenuAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU;
    payload: boolean;
}

export interface SetIsLoadingGetIikoExternalMenuInfoAction {
    type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU_INFO;
    payload: boolean;
}

export type IikoAction =
    SetIikoApiKeyAction |
    SetIikoOrganizationsAction |
    SetIikoTerminalGroupsAction |
    SetIikoPaymentTypesAction |
    SetWebhookSettingsAction |
    SetIikoNomenclatureAction |
    SetIikoRestaurantSectionsAction |
    SetIikoStopListsAction |
    SetIikoMenuSettingsAction |
    SetIikoCompanyIdAction |
    SetIikoExternalMenuAction |
    SetIikoExternalMenuItemsAction |
    SetIikoExternalMenuInfoAction |
    SetErrorMsgGetIikoNomenclatureAction |
    SetErrorMsgGetIikoOrganizationsAction |
    SetErrorMsgGetIikoPaymentTypesAction |
    SetErrorMsgGetIikoRestaurantSectionsAction |
    SetErrorMsgGetIikoStopListsAction |
    SetErrorMsgGetIikoTerminalGroupsAction |
    SetErrorMsgGetWebhookSettingsAction |
    SetErrorMsgGetIikoMenuSettingsAction |
    SetErrorMsgGetIikoOrganizationSettingsAction |
    SetIsLoadingGetIikoApiKeyAction |
    SetIsLoadingGetIikoNomenclatureAction |
    SetIsLoadingGetIikoOrganizationsAction |
    SetIsLoadingGetIikoPaymentTypesAction |
    SetIsLoadingGetRestaurantSectionsAction |
    SetIsLoadingGetStopListsAction |
    SetIsLoadingGetIikoTerminalGroupsAction |
    SetIsLoadingGetWebhookSettingsAction |
    SetIsLoadingGetIikoMenuSettingsAction |
    SetIsLoadingGetIikoOrganizationSettingsAction |
    SetIsLoadingConfigWebhookSettingsAction |
    SetIsLoadingDeleteMenuIikoSettingsAction |
    SetIsLoadingDeleteOrganizationIikoSettingsAction |
    SetIsLoadingImportIikoProductsAction |
    SetIsLoadingSaveIikoProductBindingsAction |
    SetIsLoadingSaveIikoTableBindingsAction |
    SetIsLoadingSaveMenuIikoSettingsAction |
    SetIsLoadingSaveOrganizationIikoSettingsAction |
    SetIsLoadingApproveIikoOrderAction |
    SetIsLoadingCancelIikoDeliveryOrderAction |
    SetIsLoadingGetIikoExternalMenuAction |
    SetIsLoadingGetIikoExternalMenuInfoAction;
