import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FeedbackPage, FeedbackPageShortData} from "migration/entities/Feedback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import {
    FeedbacksListActionEnum,
    SetFeedbacksListAction,
    SetIsLoadingCreateFeedbackPageAction,
    SetIsLoadingGetFeedbacksListAction,
    SetIsLoadingDeleteFeedbackPageAction,
} from "./types";
import FeedbackListService from "../api/feedbackListService";

export const FeedbacksListActionCreators = {
    setFeedbacksList: (payload: FeedbackPageShortData[]): SetFeedbacksListAction => ({
        type: FeedbacksListActionEnum.SET_FEEDBACKS_LIST,
        payload
    }),

    setIsLoadingGetFeedbacksList: (payload: boolean): SetIsLoadingGetFeedbacksListAction => ({
        type: FeedbacksListActionEnum.SET_IS_LOADING_GET_FEEDBACKS_LIST,
        payload
    }),

    setIsLoadingCreateFeedbackPage: (payload: boolean): SetIsLoadingCreateFeedbackPageAction => ({
        type: FeedbacksListActionEnum.SET_IS_LOADING_CREATE_FEEDBACK_PAGE,
        payload
    }),
    setIsLoadingDeleteFeedbackPage: (payload: boolean): SetIsLoadingDeleteFeedbackPageAction => ({
        type: FeedbacksListActionEnum.SET_IS_LOADING_DELETE_FEEDBACK_PAGE,
        payload
    }),

    getFeedbacksList: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, FeedbackListService.getAllFeedbackPages(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: FeedbacksListActionCreators.setIsLoadingGetFeedbacksList,
            successCallback: (res) => {
                dispatch(FeedbacksListActionCreators.setFeedbacksList(res.data?.data?.pages || []));
            },
            failedCallback: () => {
                dispatch(FeedbacksListActionCreators.setFeedbacksList([]));
            }
        });
    },

    createFeedbackPage: (feedbackPage: FeedbackPage, isPreFilled: boolean, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {feedbacksList} = getState().feedbacksList;

        await HttpActionCreator(dispatch, currentLang, FeedbackListService.cmdCreateFeedbackPage({feedbackPageId: feedbackPage?.id, name: feedbackPage?.name, slug: feedbackPage?.slug, isPreFilled: isPreFilled}, currentUser?.org?.id || ""),{
            loadingCallback: FeedbacksListActionCreators.setIsLoadingCreateFeedbackPage,
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                Notify.Success({title: txt.feedback_page_created[currentLang], message: ""});
                dispatch(FeedbacksListActionCreators.setFeedbacksList([...feedbacksList, feedbackPage]));
            }
        }, feedbackPage)
    },

    deleteFeedbackPage: (feedbackPage: FeedbackPageShortData, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {feedbacksList} = getState().feedbacksList;

        await HttpActionCreator(dispatch, currentLang, FeedbackListService.cmdDeleteFeedbackPage({feedbackPageId: feedbackPage?.id}, currentUser?.org?.id || ""),{
            loadingCallback: FeedbacksListActionCreators.setIsLoadingDeleteFeedbackPage,
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPageShortData) => {
                Notify.Success({title: txt.feedback_page_deleted[currentLang], message: ""});
                dispatch(FeedbacksListActionCreators.setFeedbacksList(
                    feedbacksList.filter(f => f.id !== feedbackPage.id)
                ));
            }
        }, feedbackPage)
    }
}
