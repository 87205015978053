import {useGlobalContext} from "../globalContext";
import React, {useEffect, useState} from "react";
import {IStaffUser} from "../../models/user/staff";

export function useManagers() {
    const {api} = useGlobalContext()

    const paginationStep = 20
    const [fetchInfo, setFetchInfo] = useState<{ page: number, find: string }>({page: 0, find: ""})
    const [count, setCount] = useState<number>(0)
    const [staffs, setStaffs] = useState<IStaffUser[]>([])

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        api.getStaff({
            controller,
            setLoading
        }, paginationStep, fetchInfo.page * paginationStep, fetchInfo.find).then((res) => {
            if (res.data) {
                setStaffs(res.data.staff || [])
                setCount(res.data.count)
            }
        })
        return () => controller.abort()
    }, [fetchInfo])

    function handleFind(value: string) {
        setFetchInfo({page: 0, find: value})
    }

    function resetThePassword(pass: string, index: number) {
        setStaffs(staffs.map((org, i) => {
            if (i === index) {
                org.tmpPassword = pass
                org.tmpPswChanged = false
            }
            return org
        }))
    }

    function enableDisableOrg(id: string, isDisabled: boolean, setThisLoading: (loading: boolean) => void) {
        if (isDisabled) {
            api.enableUser({setLoading: setThisLoading}, id).then((res) => {
                if (res.success) {
                    setStaffs(staffs.map((staff) => {
                        if (staff.id === id) {
                            staff.isDisabled = false
                            return {...staff}
                        }
                        return staff
                    }))
                }
            })
        } else {
            api.disableUser({setLoading: setThisLoading}, id).then((res) => {
                if (res.success) {
                    setStaffs(staffs.map((staff) => {
                        if (staff.id === id) {
                            staff.isDisabled = true
                            return {...staff}
                        }
                        return staff
                    }))
                }
            })
        }
    }

    function setPage(page: number) {
        setFetchInfo({...fetchInfo, page})
    }

    return {
        count, page: fetchInfo.page,
        staffs, loading,
        step: paginationStep,
        enableDisableOrg, setPage,
        handleFind, resetThePassword
    }
}