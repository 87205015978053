import {IikoApiKey} from "migration/entities/iikoApiKey";
import {IikoStopLists} from "migration/entities/iikoStopLists";
import {WebhookSettings} from "migration/entities/webhookSettings";
import {IikoPaymentType} from "migration/entities/iikoPaymentType";
import {IikoMenuSettings} from "migration/entities/iikoMenuSettings";
import {IikoNomenclature} from "migration/entities/iikoNomenclature";
import {IikoOrganizations} from "migration/entities/iikoOrganizations";
import {IikoRestaurantSections} from "migration/entities/iikoRestaurantSections";
import {IikoTerminalGroups} from "migration/entities/iikoTerminalGroups";
import {IikoExternalMenu} from "migration/entities/iikoExternalMenu";
import {IikoExternalMenuInfo} from "migration/entities/iikoExternalMenuInfo";
import {IikoAction, IikoActionEnum, IikoState} from "./types";

const initialState: IikoState = {
    iikoApiKey: {} as IikoApiKey,
    iikoNomenclature: {} as IikoNomenclature,
    iikoOrganizations: {} as IikoOrganizations,
    iikoPaymentTypes: {} as IikoPaymentType,
    iikoRestaurantSections: {} as IikoRestaurantSections,
    iikoStopLists: {} as IikoStopLists,
    iikoTerminalGroups: {} as IikoTerminalGroups,
    webhookSettings: {} as WebhookSettings,
    iikoMenuSettings: {} as IikoMenuSettings,
    iikoCompanyId: "",
    iikoExternalMenu: {} as IikoExternalMenu,
    iikoExternalMenuItems: {},
    iikoExternalMenuInfo: {} as IikoExternalMenuInfo,

    errorMsgIikoNomenclature: "",
    errorMsgIikoOrganizations: "",
    errorMsgIikoPaymentTypes: "",
    errorMsgIikoRestaurantSections: "",
    errorMsgIikoStopLists: "",
    errorMsgIikoTerminalGroups: "",
    errorMsgWebhookSettings: "",
    errorMsgIikoMenuSettings: "",
    errorMsgIikoOrganizationSettings: "",

    isLoadingGetIikoApiKey: false,
    isLoadingGetNomenclature: false,
    isLoadingGetIikoOrganizations: false,
    isLoadingGetIikoPaymentTypes: false,
    isLoadingGetRestaurantSections: false,
    isLoadingGetStopLists: false,
    isLoadingGetIikoTerminalGroups: false,
    isLoadingGetWebhookSettings: false,
    isLoadingGetIikoMenuSettings: false,
    isLoadingGetIikoOrganizationSettings: false,

    isLoadingConfigWebhookSettings: false,
    isLoadingDeleteMenuIikoSettings: false,
    isLoadingDeleteOrganizationIikoSettings: false,
    isLoadingImportIikoProductBindings: false,
    isLoadingSaveIikoProductBindings: false,
    isLoadingSaveIikoTableBindings: false,
    isLoadingSaveMenuIikoSettings: false,
    isLoadingSaveOrganizationIikoSettings: false,
    isLoadingApproveIikoOrder: false,
    isLoadingCancelIikoDeliveryOrder: false,
    isLoadingGetIikoExternalMenu: false,
    isLoadingGetIikoExternalMenuInfo: false,
}

export default function iikoReducer(state: IikoState = initialState, action: IikoAction): IikoState {
    switch (action.type) {
        case IikoActionEnum.SET_IIKO_API_KEY:
            return {...state, iikoApiKey: action.payload}
        case IikoActionEnum.SET_IIKO_NOMENCLATURE:
            return {...state, iikoNomenclature: action.payload}
        case IikoActionEnum.SET_IIKO_ORGANIZATIONS:
            return {...state, iikoOrganizations: action.payload}
        case IikoActionEnum.SET_IIKO_PAYMENT_TYPES:
            return {...state, iikoPaymentTypes: action.payload}
        case IikoActionEnum.SET_IIKO_RESTAURANT_SECTIONS:
            return {...state, iikoRestaurantSections: action.payload}
        case IikoActionEnum.SET_IIKO_STOP_LISTS:
            return {...state, iikoStopLists: action.payload}
        case IikoActionEnum.SET_IIKO_TERMINAL_GROUPS:
            return {...state, iikoTerminalGroups: action.payload}
        case IikoActionEnum.SET_WEBHOOK_SETTINGS:
            return {...state, webhookSettings: action.payload}
        case IikoActionEnum.SET_IIKO_MENU_SETTINGS:
            return {...state, iikoMenuSettings: action.payload}
        case IikoActionEnum.SET_IIKO_COMPANY_ID:
            return {...state, iikoCompanyId: action.payload}
        case IikoActionEnum.SET_IIKO_EXTERNAL_MENU:
            return {...state, iikoExternalMenu: action.payload}
        case IikoActionEnum.SET_IIKO_EXTERNAL_MENU_ITEMS:
            return {...state, iikoExternalMenuItems: action.payload}
        case IikoActionEnum.SET_IIKO_EXTERNAL_MENU_INFO:
            return {...state, iikoExternalMenuInfo: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_API_KEY:
            return {...state, isLoadingGetIikoApiKey: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_NOMENCLATURE:
            return {...state, isLoadingGetNomenclature: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATIONS:
            return {...state, isLoadingGetIikoOrganizations: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_PAYMENT_TYPES:
            return {...state, isLoadingGetIikoPaymentTypes: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_RESTAURANT_SECTIONS:
            return {...state, isLoadingGetRestaurantSections: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_STOP_LISTS:
            return {...state, isLoadingGetStopLists: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_TERMINAL_GROUPS:
            return {...state, isLoadingGetIikoTerminalGroups: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_WEBHOOK_SETTINGS:
            return {...state, isLoadingGetWebhookSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_MENU_SETTINGS:
            return {...state, isLoadingGetIikoMenuSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATION_SETTINGS:
            return {...state, isLoadingGetIikoOrganizationSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_CONFIG_WEBHOOK_SETTINGS:
            return {...state, isLoadingConfigWebhookSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_DELETE_MENU_IIKO_SETTINGS:
            return {...state, isLoadingDeleteMenuIikoSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_DELETE_ORGANIZATION_IIKO_SETTINGS:
            return {...state, isLoadingDeleteOrganizationIikoSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_IMPORT_IIKO_PRODUCTS:
            return {...state, isLoadingImportIikoProductBindings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_PRODUCT_BINDINGS:
            return {...state, isLoadingSaveIikoProductBindings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_TABLE_BINDINGS:
            return {...state, isLoadingSaveIikoTableBindings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_SAVE_MENU_IIKO_SETTINGS:
            return {...state, isLoadingSaveMenuIikoSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_SAVE_ORGANIZATION_IIKO_SETTINGS:
            return {...state, isLoadingSaveOrganizationIikoSettings: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_NOMENCLATURE:
            return {...state, errorMsgIikoNomenclature: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATIONS:
            return {...state, errorMsgIikoOrganizations: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_PAYMENT_TYPES:
            return {...state, errorMsgIikoPaymentTypes: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_RESTAURANT_SECTIONS:
            return {...state, errorMsgIikoRestaurantSections: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_STOP_LISTS:
            return {...state, errorMsgIikoStopLists: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_TERMINAL_GROUPS:
            return {...state, errorMsgIikoTerminalGroups: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_WEBHOOK_SETTINGS:
            return {...state, errorMsgWebhookSettings: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_MENU_SETTINGS:
            return {...state, errorMsgIikoMenuSettings: action.payload}
        case IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATION_SETTINGS:
            return {...state, errorMsgIikoOrganizationSettings: action.payload}
        case IikoActionEnum.SET_IS_LOADING_APPROVE_IIKO_ORDER:
            return {...state, isLoadingApproveIikoOrder: action.payload}
        case IikoActionEnum.SET_IS_LOADING_CANCEL_IIKO_DELIVERY_ORDER:
            return {...state, isLoadingCancelIikoDeliveryOrder: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU:
            return {...state, isLoadingGetIikoExternalMenu: action.payload}
        case IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU_INFO:
            return {...state, isLoadingGetIikoExternalMenuInfo: action.payload}
        default:
            return state
    }
}
