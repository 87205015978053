import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {ClientForm} from "../../components/form/clientForm";
import {useGlobalContext} from "../../hooks/globalContext";
import {useEffect} from "react";

export function CreateClient() {
    const {i18n} = useGlobalContext()
    let infos = [
        {link: "/staff/clients/", title: i18n.clients(), index: "breadcrumbs-id-1"},
        {link: "/staff/clients/create/", title: i18n.add_client(), index: "breadcrumbs-id-2"}
    ]
    useEffect(() => {
        document.title = i18n.creating_organization()
    }, [i18n])
    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={infos}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.add_client()}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">

                            <ClientForm/>

                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}