import {NotificationsAction, NotificationsActionEnum, NotificationsState} from "./types";

const initialState: NotificationsState = {
    isOnEmailNotification: false,
    emailReceivers: [],
    isLoadingGetNotificationsSettings: false,
    isLoadingSaveEmailReceivers: false,
    isLoadingToggleEmailNotification: false,
}

export default function notificationsReducer(state = initialState, action: NotificationsAction): NotificationsState {
    switch (action.type) {
        case NotificationsActionEnum.SET_IS_ON_EMAIL_NOTIFICATION:
            return {...state, isOnEmailNotification: action.payload};
        case NotificationsActionEnum.SET_EMAIL_RECEIVERS:
            return {...state, emailReceivers: action.payload};
        case NotificationsActionEnum.SET_IS_LOADING_GET_NOTIFICATIONS_SETTINGS:
            return {...state, isLoadingGetNotificationsSettings: action.payload};
        case NotificationsActionEnum.SET_IS_LOADING_TOGGLE_EMAIL_NOTIFICATION:
            return {...state, isLoadingToggleEmailNotification: action.payload};
        case NotificationsActionEnum.SET_IS_LOADING_SAVE_EMAIL_RECEIVERS:
            return {...state, isLoadingSaveEmailReceivers: action.payload};
        default:
            return state;
    }
}
