import React, {useState} from "react";
import {Button} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {RouteNames} from "migration/pages";
import {SubmitButton} from "../input/submitButton";
import {useCustomPages} from "../../hooks/mainPageEditor/custompages";
import {ModalCustomPage} from "../modal/modalCustomPage";
import {ReactComponent as ReactLeft} from '../../resourse/img/general/ic-angle_left.svg';
import {ReactComponent as ReactClose} from '../../resourse/img/general/ic-close.svg';
import {ReactComponent as ReactUp} from '../../resourse/img/general/ic-angle_up.svg';
import {ReactComponent as ReactDown} from '../../resourse/img/general/ic-angle_down.svg';
import {ReactComponent as ReactDelete} from '../../resourse/img/general/ic-trash.svg';
import {LittleSpinner} from "../loader/littleSpinner";
import {TBodyWithLoader} from "../loader/tBodyWithLoader";
import {ICustomPage, ICustomPages} from "../../models/customPage/customPage";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";

export function CustomPagesForm() {
    const navigate = useNavigate();
    const info = useCustomPages();
    const {i18n} = useGlobalContext()
    let menuLength = (info.customPages.menu.menuCustomPageIds || []).length
    let orgLength = (info.customPages.all || []).length
    let max = menuLength > orgLength ? menuLength : orgLength
    let maxItems: string[] = []
    for (let i = 0; i < max; i++) {
        maxItems.push("")
    }

    return (
        <div id="dynamicPart">
            <h3 className="mt-4">{i18n.custom_pages()}</h3>

            <div className="default-table my-4">
                <table>
                    <thead>
                    <tr>
                        <th style={{width: "50%"}}>{i18n.in_menu()}</th>
                        <th style={{width: "50%"}}>{i18n.all()}</th>
                    </tr>
                    </thead>
                    <TBodyWithLoader rows={3} columns={2} isLoading={info.customPagesLoading}>
                        {maxItems.map((_, i) => (
                            <TdCustomPage
                                i={i}
                                customPages={info.customPages}
                                setCustomPages={info.setCustomPages}
                                handleUpdateModal={info.handleUpdateModal}
                            />
                        ))}

                        <tr>
                            <td></td>
                            <td>
                                <a href="#"
                                   onClick={info.handleCreateModal}
                                >+{i18n.add_custom_page()}</a>
                            </td>
                        </tr>
                    </TBodyWithLoader>
                </table>
            </div>

            <div className="form__buttons">
                <div className="form__buttons-left">
                    <SubmitButton
                        onSubmit={info.handleSaveSort}
                        label={i18n.save()}
                        disabled={!info.currentMenuId}
                        isLoading={info.saveCustomPagesLoading}
                    />
                </div>
            </div>

            <ModalCustomPage
                modalInfo={info.modalCustomPage}
                setModalInfo={info.setModalCustomPage}
                createUpdateCustomPageLoading={info.createUpdateCustomPageLoading}
                menu={info.menu}
                create={info.handleCreate}
                update={info.handleUpdate}
            />
        </div>
    )
}

interface TdCustomPageProps {
    i: number
    customPages: ICustomPages
    setCustomPages: React.Dispatch<React.SetStateAction<ICustomPages>>
    handleUpdateModal: (cp: ICustomPage) => void
}

function TdCustomPage(props: TdCustomPageProps) {
    const {api} = useGlobalContext()
    const {user, menu} = useUserContext()
    const [deleteCustomPageLoading, setDeleteCustomPageLoading] = useState<boolean>(false)

    function handleDelete(id: string) {
        if (user?.org) {
            api.deleteCustomPage({setLoading: setDeleteCustomPageLoading}, id, user?.org.id).then((res) => {
                if (res.success) {
                    props.setCustomPages((prevState) => {
                        if (prevState.all) {
                            prevState.all = prevState.all.filter((v) => v.id !== id)
                            return {
                                ...prevState
                            }
                        }
                        return prevState
                    })
                }
            })
        }
    }

    function handlePushToMenu(cp: ICustomPage) {
        if (props.customPages.menu.menuCustomPageIds) {
            props.setCustomPages((customPages) => {
                return {
                    ...customPages,
                    menu: {
                        ...customPages.menu,
                        menuCustomPageIds: [...(customPages.menu.menuCustomPageIds || []), cp]
                    },
                    all: customPages.all?.filter((p) => p.id !== cp.id) || []
                }
            })
        }
    }

    function handleRemoveFromMenu(cp: ICustomPage) {
        if (props.customPages.menu.menuCustomPageIds) {
            props.setCustomPages({
                ...props.customPages,
                menu: {
                    ...props.customPages.menu,
                    menuCustomPageIds: props.customPages.menu.menuCustomPageIds.filter((p) => p.id !== cp.id)
                },
                all: [...props.customPages.all || [], cp]
            })
        }
    }

    function handleUpFromMenu(cp: ICustomPage) {
        if (props.customPages.menu.menuCustomPageIds) {
            const index = props.customPages.menu.menuCustomPageIds.findIndex((p) => p.id === cp.id)
            if (index > 0) {
                const tmp = props.customPages.menu.menuCustomPageIds[index - 1]
                props.customPages.menu.menuCustomPageIds[index - 1] = props.customPages.menu.menuCustomPageIds[index]
                props.customPages.menu.menuCustomPageIds[index] = tmp
                props.setCustomPages({...props.customPages})
            }
        }
    }

    function handleDownFromMenu(cp: ICustomPage) {
        if (props.customPages.menu.menuCustomPageIds) {
            const index = props.customPages.menu.menuCustomPageIds.findIndex((p) => p.id === cp.id)
            if (index < props.customPages.menu.menuCustomPageIds.length - 1) {
                const tmp = props.customPages.menu.menuCustomPageIds[index + 1]
                props.customPages.menu.menuCustomPageIds[index + 1] = props.customPages.menu.menuCustomPageIds[index]
                props.customPages.menu.menuCustomPageIds[index] = tmp
                props.setCustomPages({...props.customPages})
            }
        }
    }

    return (
        <tr>
            {(props.customPages.menu.menuCustomPageIds || [])[props.i] ? (
                <td>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{marginLeft: "7px"}}>
                            <a href={"#"} onClick={() => {
                                props.handleUpdateModal((props.customPages.menu.menuCustomPageIds || [])[props.i])
                            }
                            }>
                                {
                                    (props.customPages.menu.menuCustomPageIds || [])[props.i].title[menu.i18n.defaultLang]
                                }
                            </a>
                        </div>
                        <div style={{display: "inline-grid", justifyItems: "center"}}>
                            <ReactUp
                                onClick={() => {
                                    handleUpFromMenu((props.customPages.menu.menuCustomPageIds || [])[props.i])
                                }}
                            />
                            <ReactClose
                                onClick={() => {
                                    handleRemoveFromMenu((props.customPages.menu.menuCustomPageIds || [])[props.i])
                                }}
                            />
                            <ReactDown
                                onClick={() => {
                                    handleDownFromMenu((props.customPages.menu.menuCustomPageIds || [])[props.i])
                                }}
                            />
                        </div>
                    </div>
                </td>
            ) : (
                <td></td>
            )}

            {(props.customPages.all || [])[props.i] ? (
                <td>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <ReactLeft onClick={() => handlePushToMenu((props.customPages.all || [])[props.i])}/>
                        <div style={{marginLeft: "7px"}}>
                            <a href={"#"} onClick={() => {
                                props.handleUpdateModal((props.customPages.all || [])[props.i])
                            }
                            }>
                                {
                                    (props.customPages.all || [])[props.i].title[menu.i18n.defaultLang]
                                }
                            </a>
                        </div>
                        {deleteCustomPageLoading ? (
                            <LittleSpinner/>
                        ) : (
                            <ReactDelete
                                onClick={() => {
                                    handleDelete((props.customPages.all || [])[props.i].id)
                                }}
                            />
                        )}
                    </div>
                </td>
            ) : (
                <td></td>
            )}
        </tr>
    )
}
