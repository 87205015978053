import React from "react";

interface CustomSwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    size: number;
    title?: string;
}

export function CustomSwitch(props: CustomSwitchProps) {
    return (
        <label title={props.title} style={{margin:"auto", cursor:"pointer"}}>
            <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" onClick={() => {
                props.onChange(!props.checked)
            }}
                 xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                   strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <rect x="5" y="7" width="14" height="10" rx="2"
                          stroke={!props.checked?"#8392A5":"#0252CC"}></rect>
                    <rect x={props.checked ? 12 : 7} y="9" width="5" height="6" rx="1"
                          fill={!props.checked?"#8392A5":"#0252CC"}></rect>
                </g>
            </svg>
            {props.label ? <span>{props.label}</span> : null}
        </label>
    )
}