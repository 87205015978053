import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {I18nString} from "../../models/lang/i18nString";
import {ILinkGroup} from "../../models/link/link";


export function useLinks() {
    const [linksGroup, setLinksGroup] = useState<ILinkGroup[]>([]);
    const {api} = useGlobalContext()
    const {menu, currentMenuId, user} = useUserContext()

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        const controller = new AbortController()
        if (currentMenuId) {
            api.getLinkGroups({setLoading, controller}, currentMenuId).then((res) => {
                if (res.success) {
                    setLinksGroup(res.data?.linkGroups || [])
                }
            })
        }
        return () => controller.abort()
    }, [currentMenuId])

    function handelAddLinkGroup() {
        setLinksGroup([...linksGroup, {
            groupName: {},
            links: []
        }])
    }

    function handleChangeLinkGroupName(index: number, value: I18nString) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup[index].groupName = value
        setLinksGroup(newLinksGroup)
    }

    function handleDeleteLinkGroup(index: number) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup.splice(index, 1)
        setLinksGroup(newLinksGroup)
    }

    function handleAddLink(index: number) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup[index].links.push({
            caption: {},
            url: ""
        })
        setLinksGroup(newLinksGroup)
    }

    function handleDeleteLink(index: number, linkIndex: number) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup[index].links.splice(linkIndex, 1)
        setLinksGroup(newLinksGroup)
    }

    function handleChangeLinkCaption(index: number, linkIndex: number, value: I18nString) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup[index].links[linkIndex].caption = value
        setLinksGroup(newLinksGroup)
    }

    function handelChangeLinkUrl(index: number, linkIndex: number, value: string) {
        const newLinksGroup = [...linksGroup]
        newLinksGroup[index].links[linkIndex].url = value
        setLinksGroup(newLinksGroup)
    }

    function handleSave() {
        api.saveLinkGroups({setLoading: setSaveLoading}, currentMenuId, linksGroup)
    }


    return {
        linksGroup,
        setLinksGroup,
        menu,
        isStaff: !!user?.isStaff,
        currentMenuId,
        handelAddLinkGroup,
        handleChangeLinkGroupName,
        handleDeleteLinkGroup,
        handleAddLink,
        handleChangeLinkCaption,
        handelChangeLinkUrl,
        handleDeleteLink,
        handleSave,

        saveLoading,
        loading
    }
}
