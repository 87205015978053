import React, {useEffect} from "react";
import PhoneInput, {CountryData} from 'react-phone-input-2'
import {Phone} from "../../models/other/phone";

interface Props {
    label: string
    value: Phone
    onChange: (phone: Phone) => void
    placeholder?: string
}

export function InputPhone(props: Props) {
    return (
        <div className="control">
            <label className="control__label">{props.label}</label>

            <PhoneInput inputClass={"control__input hello"}
                        value={props.value.number}
                        onChange={(number, code:CountryData, e, formattedValue) => {
                                if(code) {
                                    props.onChange({
                                        number: formattedValue,
                                        countryCode: code?.countryCode
                                    })
                                }
                        }}

                        specialLabel={""}
                        prefix={"+"}
                        areaCodes={{kz: ["7"]}}
                        placeholder={props.placeholder || ""}
            />
        </div>
    )
}