import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {SliderDnD} from "../../components/dnd/dndSlider";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {ISlide} from "../../models/slider/slider";
import {LittleSpinner} from "../../components/loader/littleSpinner";
import {MenuUlWithLoader} from "../../components/loader/menuUlWithLoader";
import {RouteNames} from "../../migration/pages";

export function SliderSorting() {
    const {currentMenuId} = useUserContext()
    const {api} = useGlobalContext()
    const navigate = useNavigate()
    const [slides, setSlides] = useState<ISlide[]>([])
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.sorting()
    }, [i18n])

    const [sliderLoading, setSliderLoading] = useState<boolean>(false)
    const [sortSliderLoading, setSortSliderLoading] = useState<boolean>(false)
    useEffect(() => {
        // fetch data from server
        const controller = new AbortController()
        api.getSlider({controller, setLoading: setSliderLoading}, currentMenuId).then((res) => {
            if (res.data) {
                setSlides(res.data.slides)
            }
        })
        return () => controller.abort()
    }, [api, currentMenuId])

    function onSave() {
        // save data to server
        api.saveSliderSort({setLoading: setSortSliderLoading}, slides, currentMenuId).then((res) => {
            if (res.success) {
                navigate(RouteNames.SLIDER)
            }
        })
    }

    return (
        <section className="app__main">
            <main role="main" className="app__content">
                <Breadcrumbs infos={[
                    {title: i18n.slider(), link: RouteNames.SLIDER, index: "brcr-0"},
                    {title: i18n.sorting(), link: RouteNames.SLIDER_SORTING, index: "brcr-1"},
                ]}/>
                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.slider()}</h1>
                    </div>
                    <div className="app__header-right">
                        <button className="button -blue" onClick={
                            () => {
                                onSave()
                            }
                        }>
                            {sortSliderLoading ? <LittleSpinner/> : i18n.save_sorting()}
                        </button>
                        <Link to={RouteNames.SLIDER}
                              className="button -bordered_blue">
                            {i18n.cancel()}
                        </Link>
                    </div>
                </div>
                {<MenuUlWithLoader loading={sliderLoading} count={3} level={1} styleli={{listStyleType: "none"}}
                                   notFound={(slides || []).length === 0 && !sliderLoading}
                                   notFoundText={i18n.help_empty_menu()}>
                    <SliderDnD slides={slides} setSlides={setSlides} loading={sliderLoading}/>
                </MenuUlWithLoader>}


            </main>
        </section>
    )
}
