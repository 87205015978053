import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Select} from "antd";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ColaProductKeyEnum} from "../store/types";
import classes from "./CocaColaSettings.module.scss";

interface CocaColaSettingsProps {
    type: "org" | "category" | "product";
    categoryId?: string;
    productId?: string;
}

export const CocaColaSettings: FunctionComponent<CocaColaSettingsProps> = ({type, categoryId, productId}) => {
    const navigate = useNavigate();
    const {currentUser} = useTypedSelector(state => state.user);
    const {currentLang} = useTypedSelector(state => state.lang);
    const {
        isCocaColaClient,
        isCurrentCategoryCocaCola,
        isCurrentProductCocaCola,
        currentProductKey
    } = useTypedSelector(state => state.cola);
    const {
        cocaColaClientById,
        cocaColaMenuCategoryById,
        cocaColaProductById,
        markAsCocaColaClient,
        markAsCocaColaMenuCategory,
        markAsCocaColaProduct,
        unMarkAsCocaColaClient,
        unMarkAsCocaColaMenuCategory,
        unMarkAsCocaColaProduct
    } = useActions();

    const [productKey, setProductKey] = useState<ColaProductKeyEnum>(ColaProductKeyEnum.COCA_COLA);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isCheckedCategory, setIsCheckedCategory] = useState<boolean>(false);
    const [isCheckedProduct, setIsCheckedProduct] = useState<boolean>(false);

    const productKeyOptions = [
        {label: "Coca-Cola", value: ColaProductKeyEnum.COCA_COLA},
        {label: "Fanta", value: ColaProductKeyEnum.FANTA},
        {label: "Sprite", value: ColaProductKeyEnum.SPRITE},
        {label: "Schweppes", value: ColaProductKeyEnum.SCHWEPPES},
        {label: "Piko", value: ColaProductKeyEnum.PIKO},
        {label: "Bonaqua", value: ColaProductKeyEnum.BONAQUA},
        {label: "Fuse Tea", value: ColaProductKeyEnum.FUSE_TEA},
    ]

    const label = () => {
        switch (type) {
            case "org":
                return txt.mark_as_coca_cola_client[currentLang];
            case "category":
                return txt.mark_as_coca_cola_menu_category[currentLang];
            case "product":
                return txt.mark_as_coca_cola_product[currentLang];
        }
    }

    const isDisabled = () => {
        switch (type) {
            case "org":
                return isChecked === isCocaColaClient;
            case "category":
                return isCheckedCategory === isCurrentCategoryCocaCola;
            case "product":
                return isCheckedProduct === isCurrentProductCocaCola && productKey === currentProductKey;
        }
    }

    const handleSubmitClient = () => {
        isChecked
            ? markAsCocaColaClient({navigate})
            : unMarkAsCocaColaClient({navigate});
    }

    const handleSubmitCategory = () => {
        if (!categoryId) return;
        isCheckedCategory
            ? markAsCocaColaMenuCategory(categoryId, {navigate})
            : unMarkAsCocaColaMenuCategory(categoryId, {navigate});
    }

    const handleSubmitProduct = () => {
        if (!productId) return;
        isCheckedProduct
            ? markAsCocaColaProduct(productId, productKey, {navigate})
            : unMarkAsCocaColaProduct(productId, {navigate});
    }

    const handleSubmit = () => {
        switch (type) {
            case "org":
                handleSubmitClient();
                break;
            case "category":
                handleSubmitCategory();
                break;
            case "product":
                handleSubmitProduct();
                break;
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        cocaColaClientById({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentUser?.org?.id]);

    useEffect(() => {
        if (!categoryId) return;
        const controller = new AbortController();
        cocaColaMenuCategoryById(categoryId, {navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, [categoryId]);

    useEffect(() => {
        if (!productId) return;
        const controller = new AbortController();
        cocaColaProductById(productId, {navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, [productId]);

    useEffect(() => {
        setIsChecked(isCocaColaClient);
    }, [isCocaColaClient]);

    useEffect(() => {
        setIsCheckedCategory(isCurrentCategoryCocaCola);
    }, [isCurrentCategoryCocaCola]);

    useEffect(() => {
        setProductKey(currentProductKey);
        setIsCheckedProduct(isCurrentProductCocaCola);
    }, [isCurrentProductCocaCola, currentProductKey]);

    if (!currentUser?.isStaff || (type !== "org" && !isCocaColaClient)) {
        return null;
    }

    return (
        <div className={classes.coca__cola__settings}>
            <label className={classes.coca__cola__settings__label}>
                <input
                    checked={
                        type === "org" ? isChecked :
                            type === "category" ? isCheckedCategory :
                                type === "product" ? isCheckedProduct : false
                    }
                    onChange={() => {
                        switch (type) {
                            case "org":
                                setIsChecked(!isChecked)
                                break;
                            case "category":
                                setIsCheckedCategory(!isCheckedCategory)
                                break;
                            case "product":
                                setIsCheckedProduct(!isCheckedProduct)
                                break;
                        }
                    }}
                    type="checkbox"
                    className={classes.coca__cola__settings__checkbox}
                />
                {label()}
            </label>

            {type === "product" && (
                <label
                    className={classes.coca__cola__settings__label}
                    style={{flexDirection: "column", alignItems: "flex-start", margin: "10px 0"}}
                >
                    {txt.select_cola_product[currentLang]}
                    <Select
                        value={productKey}
                        onChange={(value: ColaProductKeyEnum) => setProductKey(value)}
                        options={productKeyOptions}
                        disabled={!isChecked}
                        className={classes.coca__cola__settings__select}
                    />
                </label>
            )}
            <Button
                onClick={handleSubmit}
                type="primary"
                disabled={isDisabled()}
                className={classes.coca__cola__settings__btn}
            >
                {txt.save[currentLang]}
            </Button>
        </div>
    );
};
