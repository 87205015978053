import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {isEmpty} from "lodash";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ModalBase} from "../ModalBase";

interface ModalAddProductProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<boolean>,
    isEditMode?: boolean,
    selectedProductId?: string,
}

export const ModalAddProduct: FunctionComponent<ModalAddProductProps> = (
    {isOpen, setIsOpen, isEditMode, selectedProductId}
) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menu} = useTypedSelector(state => state.user);
    const {kamiMenuItemsList} = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);
    const {associateCrossSellingToProduct} = useActions();
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [associatedProductsListState, setAssociatedProductsListState] = useState<{ [itemId: string]: string[] }>({});
    const [searchQuery, setSearchQuery] = useState<string>("");

    const associatedProductsList = useMemo<string[]>(() => {
        if (!selectedProduct || isEmpty(associatedProductsListState[selectedProduct])) return [];
        return associatedProductsListState[selectedProduct] || [];
    }, [selectedProduct, associatedProductsListState]);

    const itemTitle = useMemo(() => {
        return (isEditMode && selectedProduct) ? kamiMenuItemsList[selectedProduct]?.name[menu?.i18n?.defaultLang] : txt.add_product[currentLang];
    }, [selectedProduct, isEditMode, kamiMenuItemsList, currentLang]);

    const addAssociatedProduct = (itemId: string) => {
        const copy = {...associatedProductsListState};
        const selectedProductCopy: string = selectedProduct || ""
        if (!(selectedProductCopy in copy)) {
            copy[selectedProductCopy] = [];
        }
        if (copy[selectedProductCopy].includes(itemId)) {
            copy[selectedProductCopy] = copy[selectedProductCopy].filter(item => item !== itemId);
            setAssociatedProductsListState(copy);
            return;
        }
        const notUniqueArr = [...copy[selectedProductCopy], itemId];
        copy[selectedProductCopy] = notUniqueArr.filter((item, index) => index === notUniqueArr.indexOf(item));
        setAssociatedProductsListState(copy);
    }

    const onCancel = () => {
        if (isEditMode) {
            setSearchQuery("");
            setSelectedProduct("");
            setAssociatedProductsListState({});
        }
        setIsOpen(false);
    }

    const onSubmit = () => {
        associateCrossSellingToProduct({
            customLabel: {} as MlString,
            sourceProductId: selectedProduct,
            targetProductIds: associatedProductsList
        }, crossSellingMapping);
        setSearchQuery("");
        setSelectedProduct("");
        setAssociatedProductsListState({});
        setIsOpen(false);
    }

    useEffect(() => {
        if (!isEditMode || !selectedProductId) return;
        setSelectedProduct(selectedProductId);
        setAssociatedProductsListState({[selectedProductId]: crossSellingMapping.products[selectedProductId]?.productIds || []});
    }, [crossSellingMapping, isEditMode, selectedProductId, isOpen]);

    return (
        <ModalBase
            associatedProductsList={associatedProductsList}
            addAssociatedProduct={addAssociatedProduct}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmit={onSubmit}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title={itemTitle}
            isEditMode={isEditMode}
            selectedItem={selectedProduct}
            setSelectedItem={setSelectedProduct}
        />
    )
};
