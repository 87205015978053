import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IikoMenuSettings} from "migration/entities/iikoMenuSettings";
import {IikoRestaurantSections} from "migration/entities/iikoRestaurantSections";
import {IikoRestaurantSectionTableId, PlacementSlug} from "migration/entities/baseAliases";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {TextI18n} from "../../components/input/textI18n";
import {InputText} from "../../components/input/inputText";
import {Placement} from "../../models/placements/placements";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {SettingsTab} from "../../components/tabs/settingsTab";
import {SubmitButton} from "../../components/input/submitButton";
import {CustomSwitch} from "../../components/input/customSwitch";
import {CustomSelect} from "../../components/input/customSelect";
import {usePlacements} from "../../hooks/placements/placements";
import {FormWithLoader} from "../../components/loader/formWithLoader";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {RouteNames} from "../../migration/pages";
import {RkeeperRestaurant} from "../../migration/entities/rkeeperRestaurant";
import {RkeeperMenuSettings} from "../../migration/entities/rkeeperMenuSettings";
import {Select} from "antd";
import {isEmpty} from "lodash";

export function SettingsPlacements() {
    const navigate = useNavigate();
    const info = usePlacements()
    const {i18n} = useGlobalContext()
    const {user, currentMenuId} = useUserContext()

    const {
        iikoMenuSettings,
        iikoRestaurantSections,
        isLoadingSaveIikoTableBindings
    } = useTypedSelector(state => state.iiko);

    const {
        rkeeperMenuSettings,
        rkeeperRestaurant,
        isLoadingSaveRkeeperTableBindings,
        isLoadingGetRkeeperMenuSettings
    } = useTypedSelector(state => state.rkeeper);

    const {
        fetchIikoMenuSettings,
        fetchIikoRestaurantSections,
        saveIikoTableBindings,

        getMenuRkeeperSettings,
        getRkeeperRestaurant,
        saveRkeeperTableBindings,
    } = useActions();
    const [iikoTableBindings, setIikoTableBindings] = useState<{
        [placementSlug: PlacementSlug]: IikoRestaurantSectionTableId
    }>({});

    const removeIikoTableBinding = async (placementSlug: PlacementSlug) => {
        const copy = {...iikoTableBindings};
        delete copy[placementSlug];
        await saveIikoTableBindings({
            "menuId": currentMenuId,
            "organizationId": user?.org?.id || "",
            "tableBindings": copy,
        }, {navigate: navigate});
        await fetchIikoMenuSettings({menuId: currentMenuId}, new AbortController(), {navigate: navigate});
    }

    const removeRkeeperTableBinding = async (placementSlug: PlacementSlug) => {
        const copy = {...rkeeperMenuSettings.tableBindings};
        delete copy[placementSlug];
        await saveRkeeperTableBindings({
            "menuId": currentMenuId,
            "organizationId": user?.org?.id || "",
            "tableBindings": copy,
        }, {navigate: navigate});
        await getMenuRkeeperSettings({menuId: currentMenuId}, new AbortController());
    }

    const addIikoTableBinding = async (placementSlug: PlacementSlug, tableId: IikoRestaurantSectionTableId) => {
        const copy = {...iikoTableBindings};
        copy[placementSlug] = tableId;
        await saveIikoTableBindings({
            "menuId": currentMenuId,
            "organizationId": user?.org?.id || "",
            "tableBindings": copy,
        }, {navigate: navigate});
        await fetchIikoMenuSettings({menuId: currentMenuId}, new AbortController(), {navigate: navigate});
    }

    const addRkeeperTableBinding = async (placementSlug: PlacementSlug, tableId: number) => {
        const copy = {...rkeeperMenuSettings.tableBindings};
        copy[placementSlug] = tableId;
        await saveRkeeperTableBindings({
            "menuId": currentMenuId,
            "organizationId": user?.org?.id || "",
            "tableBindings": copy,
        }, {navigate: navigate});
        await getMenuRkeeperSettings({menuId: currentMenuId}, new AbortController());
    }

    useEffect(() => {
        if (user?.org?.availableModules?.includes("iiko")) {
            const controller = new AbortController();
            fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user?.org?.availableModules?.includes("iiko")) {
            const controller = new AbortController();
            fetchIikoRestaurantSections({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper")) {
            const controller = new AbortController();
            getMenuRkeeperSettings({menuId: currentMenuId}, controller);
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper") && rkeeperMenuSettings.rkeeperObjectId) {
            const controller = new AbortController();
            getRkeeperRestaurant({objectId: parseInt(rkeeperMenuSettings.rkeeperObjectId)}, controller);
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rkeeperMenuSettings.rkeeperObjectId]);

    useEffect(() => {
        setIikoTableBindings(iikoMenuSettings.tableBindings);
    }, [iikoMenuSettings, iikoRestaurantSections]);

    useEffect(() => {
        document.title = i18n.placements()
    }, [i18n])
    //todo plasements loading

    return (
        <section className={"app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[
                        {title: i18n.settings(), link: RouteNames.SETTINGS, index: "brcr-0"},
                    ]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.placements()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5" style={{maxWidth: "100%", width: "100%", flex: "0 0 100%"}}>
                        <SettingsTab/>
                        <div className="tab-content">
                            <div className="default-card">
                                <FormWithLoader isloading={info.PlacementsLoading} className="form">

                                    <TextI18n
                                        label={i18n.common_name()}
                                        isStaff={!!user?.isStaff}
                                        values={info.placements.label}
                                        setValues={info.changeLabel}
                                        placeholder={i18n.placements_label_placeholder()}
                                        lang={info.menu.i18n.defaultLang}
                                        allLang={info.menu.i18n.otherLangs || []}
                                    />

                                    {info.placements.placements?.map((p, i) => {
                                        return (
                                            <div data-gutter={16} className={"row"} key={i}>
                                                <div className="col-md-3">
                                                    <InputText
                                                        label={i18n.placement_slug()}
                                                        value={p.slug}
                                                        onChange={(v) => {
                                                            info.changePlacement(v, p.name, i)
                                                        }}
                                                        isValidate={true}
                                                        placeholder={i18n.placement_slug_placeholder()}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <TextI18n
                                                        label={i18n.placement_name()}
                                                        values={p.name}
                                                        isStaff={!!user?.isStaff}
                                                        setValues={(v) => {
                                                            info.changePlacement(p.slug, v, i)
                                                        }}
                                                        placeholder={i18n.placement_name_placeholder()}
                                                        lang={info.menu.i18n.defaultLang}
                                                        allLang={info.menu.i18n.otherLangs || []}
                                                    />
                                                </div>
                                                {(user?.org?.availableModules?.includes("iiko") && !isEmpty(iikoMenuSettings)) && (
                                                    <div
                                                        className={"col-md-3"}
                                                        title={!p.isCreated ? i18n.placement_not_created() : ""}
                                                        style={{display: "flex", alignItems: "center"}}
                                                    >
                                                        <button
                                                            className={
                                                                !iikoMenuSettings?.tableBindings?.[p.slug || ""] ?
                                                                    "button -bordered_blue form__button" :
                                                                    "button -bordered_red form__button"
                                                            }
                                                            style={{width: "100%", marginTop: "4px"}}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                if (!iikoMenuSettings.tableBindings[p.slug || ""]) {
                                                                    info.setIikoModalPlacement(p)
                                                                } else {
                                                                    removeIikoTableBinding(p.slug || "")
                                                                }
                                                            }}
                                                            onMouseOver={(e) => {
                                                                let bindIIko = iikoMenuSettings.tableBindings[p.slug || ""]
                                                                if (bindIIko) {
                                                                    let number;
                                                                    iikoRestaurantSections?.restaurantSections?.[iikoMenuSettings?.iikoOrganizationId]?.[iikoMenuSettings?.iikoTerminalGroupId]?.forEach(section => {
                                                                        section?.tables?.forEach(table => {
                                                                            if (bindIIko === table?.id) {
                                                                                number = `Зал: ${section?.name}, Номер: ${table?.data?.number}, Название: ${table?.data?.name}`;
                                                                            }
                                                                        })
                                                                    })
                                                                    if (number) {
                                                                        e.currentTarget.textContent = `${number}`
                                                                    } else {
                                                                        e.currentTarget.textContent = i18n.loading()
                                                                    }
                                                                }
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                let bindIIko = iikoMenuSettings.tableBindings[p.slug || ""]
                                                                if (bindIIko) {
                                                                    e.currentTarget.textContent = !iikoMenuSettings.tableBindings[p.slug || ""] ? i18n.bind() : i18n.unbind()
                                                                }
                                                            }}
                                                            disabled={!p?.isCreated}
                                                        >
                                                            {!iikoMenuSettings.tableBindings[p.slug || ""] ? i18n.bind() : i18n.unbind()}
                                                        </button>
                                                    </div>
                                                )}

                                                {user?.org?.availableModules?.includes("rkeeper") && (
                                                    <div
                                                        className={"col-md-3"}
                                                        title={!p.isCreated ? i18n.placement_not_created() : ""}
                                                        style={{display: "flex", alignItems: "center"}}
                                                    >
                                                        <button
                                                            className={
                                                                !rkeeperMenuSettings.tableBindings?.[p.slug || ""]
                                                                    ? "button -bordered_blue form__button"
                                                                    : "button -bordered_red form__button"
                                                            }
                                                            style={{width: "100%", marginTop: "4px"}}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                if (!rkeeperMenuSettings.tableBindings?.[p.slug || ""]) {
                                                                    info.setRkeeperModalPlacement(p)
                                                                } else {
                                                                    removeRkeeperTableBinding(p.slug || "")
                                                                }
                                                            }}
                                                            onMouseOver={(e) => {
                                                                let bindIIko = rkeeperMenuSettings.tableBindings?.[p.slug || ""]
                                                                if (bindIIko) {
                                                                    let number;
                                                                    rkeeperRestaurant?.halls?.forEach(hall => {
                                                                        hall?.tables?.forEach(table => {
                                                                            if (rkeeperMenuSettings.tableBindings?.[p.slug || ""] === table?.code) {
                                                                                number = `Зал: ${hall?.name}, Название: ${table?.name}`;
                                                                            }
                                                                        })
                                                                    })
                                                                    if (number) {
                                                                        e.currentTarget.textContent = `${number}`
                                                                    } else {
                                                                        e.currentTarget.textContent = i18n.loading()
                                                                    }
                                                                }
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                let bindIIko = rkeeperMenuSettings.tableBindings?.[p.slug || ""]
                                                                if (bindIIko) {
                                                                    e.currentTarget.textContent = !rkeeperMenuSettings.tableBindings?.[p.slug || ""] ? i18n.bind() : i18n.unbind()
                                                                }
                                                            }}
                                                            disabled={!p.isCreated}
                                                        >
                                                            {!rkeeperMenuSettings.tableBindings?.[p.slug || ""] ? i18n.bind() : i18n.unbind()}
                                                        </button>
                                                    </div>
                                                )}
                                                <div
                                                    className={"col-md-1"}
                                                    style={{display: "flex", alignItems: "center"}}
                                                >
                                                    <CustomSwitch
                                                        checked={!p.isOff}
                                                        size={36}
                                                        onChange={() => info.changePlacementIsOff(!p.isOff, i)}
                                                        title={p.isOff ? i18n.placement_is_off() : i18n.placement_is_on()}
                                                    />
                                                </div>
                                                <div
                                                    className={"col-md-1"}
                                                    style={{display: "flex", alignItems: "center"}}
                                                >
                                                    <DeleteSVG style={{cursor: "pointer"}} onClick={(e) => {
                                                        info.deletePlacement(e, i)
                                                    }}/>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <a href="#" onClick={info.addPlacement}>{i18n.add_placement()}</a>

                                    <div className="form__buttons mt-4">
                                        <div className="form__buttons-left">
                                            <SubmitButton
                                                onSubmit={info.handleSubmit}
                                                label={i18n.save()} isLoading={info.savePlacementsLoading}
                                                modal={true} disabled={!info.currentMenuId}
                                            />
                                            <SubmitButton
                                                onSubmit={info.handleDownloadQr}
                                                isLoading={info.downloadQrLoading}
                                                label={i18n.download_qr_codes()}
                                                modal={true} disabled={!info.currentMenuId}
                                            />
                                            <button
                                                onClick={info.handleCancel}
                                                className="button -bordered_blue form__button"
                                            >
                                                {i18n.cancel()}
                                            </button>
                                        </div>
                                    </div>
                                </FormWithLoader>
                            </div>
                            <IikoPlacementModal
                                now={info.iikoModalPlacement}
                                setNow={info.setIikoModalPlacement}
                                onBind={addIikoTableBinding}
                                onUnbind={removeIikoTableBinding}
                                createDeleteIikoTableBindingLoading={isLoadingSaveIikoTableBindings}
                                tables={iikoRestaurantSections}
                                iikoMenuSettings={iikoMenuSettings}
                            />
                            <RkeeperPlacementModal
                                now={info.rkeeperModalPlacement}
                                setNow={info.setRkeeperModalPlacement}
                                onBind={addRkeeperTableBinding}
                                onUnbind={removeRkeeperTableBinding}
                                isLoading={isLoadingSaveRkeeperTableBindings || isLoadingGetRkeeperMenuSettings}
                                tables={rkeeperRestaurant}
                                rkeeperMenuSettings={rkeeperMenuSettings}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}

interface IikoPlacementModalProps {
    now: Placement | null
    setNow: (now: Placement | null) => void
    onBind: (placementSlug: string, tableId: string) => void
    onUnbind: (id: string) => void
    tables: IikoRestaurantSections
    iikoMenuSettings: IikoMenuSettings
    createDeleteIikoTableBindingLoading: boolean
}

function IikoPlacementModal(props: IikoPlacementModalProps) {
    const {i18n} = useGlobalContext()

    const [selectInd, setSelectInd] = useState<number>(0)
    const [selectedTableId, setSelectedTableId] = useState<string>("")

    const onBind = async () => {
        if (selectInd === 0) {
            await props.onBind(props.now?.slug || "", (props.tables?.restaurantSections?.[props?.iikoMenuSettings?.iikoOrganizationId]?.[props?.iikoMenuSettings?.iikoTerminalGroupId]?.flatMap(
                (v) => v?.tables?.map(table => {
                    return table
                })
            ) || [])[0]?.id || "")
            return
        } else {
            await props.onBind(props.now?.slug || "", selectedTableId)
            props.setNow(null)
        }
    }

    const onUnbind = async () => {
        await props.onUnbind(props.now?.slug || "")
        props.setNow(null)
    }
    //todo loading

    return (
        <>
            <div
                id="modal-order-preview"
                aria-hidden={!!props.now}
                style={{display: props.now ? "block" : "none"}}
                className={props.now ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    props.setNow(null)
                                    setSelectInd(0)
                                }}
                            ></button>
                            <div id="order-preview-content">
                                <form className="form">
                                    <div className="lang-tab" style={{display: "block"}}>
                                        <h2 className="tx-semibold mb-4">
                                            <span>{i18n.iiko_binding()}</span>
                                        </h2>
                                    </div>
                                    <div className="control">
                                        <label className="control__label">{i18n.placement_slug()}</label>
                                        <div>{props.now?.slug}</div>
                                    </div>
                                    <CustomSelect
                                        options={
                                            props.tables?.restaurantSections?.[props?.iikoMenuSettings?.iikoOrganizationId]?.[props?.iikoMenuSettings?.iikoTerminalGroupId]?.flatMap(
                                                (v) => v?.tables?.map(table => {
                                                    return table?.data?.name
                                                        ? `Зал: ${v.name}, ${i18n.table_number()}: ${table?.data?.number}, ${i18n.title()}: ${table?.data?.name}`
                                                        : `Зал: ${v.name}, ${i18n.table_number()}: ${table?.data?.number}`
                                                })
                                            ) || []
                                        }
                                        value={selectInd}
                                        onChange={(v) => {
                                            console.log(v)
                                            let ind = parseInt(v)
                                            const arr = props.tables?.restaurantSections?.[props?.iikoMenuSettings?.iikoOrganizationId]?.[props?.iikoMenuSettings?.iikoTerminalGroupId]?.flatMap(
                                                (v) => v?.tables?.map(table => {
                                                    return table
                                                })
                                            ) || []
                                            const table = arr[ind || 0]
                                            setSelectInd(ind || 0)
                                            setSelectedTableId(table?.id || "")
                                        }}
                                        label={"iiko"}
                                    />
                                    <div className="form__buttons mt-4">
                                        <div className="form__buttons-left">
                                            {!props?.iikoMenuSettings?.tableBindings?.[props?.now?.slug || ""] || ""
                                                ?
                                                <SubmitButton
                                                    onSubmit={onBind}
                                                    label={i18n.bind()} modal={true}
                                                    disabled={false}
                                                    isLoading={props.createDeleteIikoTableBindingLoading}
                                                />
                                                :
                                                <SubmitButton
                                                    onSubmit={onUnbind}
                                                    label={i18n.unbind_iiko()}
                                                    modal={true} disabled={false}
                                                    isDelete={true}
                                                    isLoading={props.createDeleteIikoTableBindingLoading}
                                                />
                                            }
                                            <button
                                                className="button -bordered_blue form__button"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    props.setNow(null)
                                                }}
                                            >
                                                {i18n.cancel()}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.now ? "modal-backdrop show" : ""}></div>
        </>
    )
}

interface RkeeperPlacementModalProps {
    now: Placement | null
    setNow: (now: Placement | null) => void
    onBind: (placementSlug: string, rkeeperTableId: number) => void
    onUnbind: (id: string) => void
    tables: RkeeperRestaurant
    rkeeperMenuSettings: RkeeperMenuSettings
    isLoading: boolean
}

function RkeeperPlacementModal(props: RkeeperPlacementModalProps) {
    const {i18n} = useGlobalContext()

    const [selectInd, setSelectInd] = useState<number>(0)

    const onBind = async () => {
        await props.onBind(props.now?.slug || "", selectInd)
        props.setNow(null)
    }

    const onUnbind = async () => {
        await props.onUnbind(props.now?.slug || "")
        props.setNow(null)
    }

    useEffect(() => {
        if (isEmpty(props.rkeeperMenuSettings?.tableBindings)) return;
        setSelectInd(props.rkeeperMenuSettings?.tableBindings?.[props.now?.slug || ""] || 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rkeeperMenuSettings]);
    //todo loading
    return (
        <>
            <div
                id="modal-order-preview"
                aria-hidden={!!props.now}
                style={{display: props.now ? "block" : "none"}}
                className={props.now ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    props.setNow(null)
                                    setSelectInd(0)
                                }}
                            ></button>
                            <div id="order-preview-content">
                                <form className="form">
                                    <div className="lang-tab" style={{display: "block"}}>
                                        <h2 className="tx-semibold mb-4">
                                            <span>{i18n.iiko_binding()}</span>
                                        </h2>
                                    </div>
                                    <div className="control">
                                        <label className="control__label">{i18n.placement_slug()}</label>
                                        <div>{props.now?.slug}</div>
                                    </div>
                                    {/*<CustomSelect*/}
                                    {/*    options={*/}
                                    {/*        props.tables?.restaurantSections?.[props?.iikoMenuSettings?.iikoOrganizationId]?.[props?.iikoMenuSettings?.iikoTerminalGroupId]?.map(*/}
                                    {/*            (v) => v.data?.name*/}
                                    {/*                ? `${i18n.table_number()}: ${v?.data?.number}, ${i18n.title()}: ${v?.data?.name}`*/}
                                    {/*                : `${i18n.table_number()}: ${v?.data?.number}`*/}
                                    {/*        ) || []*/}
                                    {/*    }*/}
                                    {/*    value={selectInd}*/}
                                    {/*    onChange={(v) => {*/}
                                    {/*        console.log(v)*/}
                                    {/*        let ind = parseInt(v)*/}
                                    {/*        setSelectInd(ind || 0)*/}
                                    {/*    }}*/}
                                    {/*    label={"iiko"}*/}
                                    {/*/>*/}

                                    <Select
                                        value={selectInd}
                                        onChange={(v) => setSelectInd(v || 0)}
                                        style={{width: "100%"}}
                                        options={props.tables?.halls?.flatMap(hall => {
                                            return hall?.tables?.map(table => {
                                                return {
                                                    value: table?.code,
                                                    label: `Code: ${table?.code} - Hall: ${hall?.name} - ${table?.name}`
                                                }
                                            })
                                        })}
                                    />
                                    <div className="form__buttons mt-4">
                                        <div className="form__buttons-left">
                                            {!props?.rkeeperMenuSettings?.tableBindings?.[props?.now?.slug || ""] || ""
                                                ?
                                                <SubmitButton
                                                    onSubmit={onBind}
                                                    label={i18n.bind()}
                                                    modal={true}
                                                    disabled={false}
                                                    isLoading={props.isLoading}
                                                />
                                                :
                                                <SubmitButton
                                                    onSubmit={onUnbind}
                                                    label={i18n.unbind_iiko()}
                                                    modal={true}
                                                    disabled={false}
                                                    isDelete={true}
                                                    isLoading={props.isLoading}
                                                />
                                            }
                                            <button
                                                className="button -bordered_blue form__button"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    props.setNow(null)
                                                }}
                                            >
                                                {i18n.cancel()}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.now ? "modal-backdrop show" : ""}></div>
        </>
    )
}
