import {Feedback, FeedbackDashboardData} from "migration/entities/Feedback";

export interface FeedbackDashboardDataState {
    feedbackDashboardData: FeedbackDashboardData;
    feedbacks: Feedback[];
    feedbacksCount: number;
    isLoadingGetFeedbackDashboardData: boolean;
    isLoadingGetFeedbacks: boolean;
}

export enum FeedbackDashboardDataActionEnum {
    SET_FEEDBACK_DASHBOARD_DATA = 'SET_FEEDBACK_DASHBOARD_DATA',
    SET_FEEDBACKS = 'SET_FEEDBACKS',
    SET_FEEDBACKS_COUNT = 'SET_FEEDBACKS_COUNT',
    SET_IS_LOADING_GET_FEEDBACK_DASHBOARD_DATA = 'SET_IS_LOADING_GET_FEEDBACK_DASHBOARD_DATA',
    SET_IS_LOADING_GET_FEEDBACKS = 'SET_IS_LOADING_GET_FEEDBACKS',
}

export interface SetFeedbackDashboardDataAction {
    type: FeedbackDashboardDataActionEnum.SET_FEEDBACK_DASHBOARD_DATA;
    payload: FeedbackDashboardData;
}

export interface SetFeedbacksAction {
    type: FeedbackDashboardDataActionEnum.SET_FEEDBACKS;
    payload: Feedback[];
}

export interface SetFeedbacksCountAction {
    type: FeedbackDashboardDataActionEnum.SET_FEEDBACKS_COUNT;
    payload: number;
}

export interface SetIsLoadingGetFeedbackDashboardDataAction {
    type: FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACK_DASHBOARD_DATA;
    payload: boolean;
}

export interface SetIsLoadingGetFeedbacksAction {
    type: FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACKS;
    payload: boolean;
}

export type FeedbackDashboardDataAction =
    SetFeedbackDashboardDataAction |
    SetFeedbacksAction |
    SetFeedbacksCountAction |
    SetIsLoadingGetFeedbackDashboardDataAction |
    SetIsLoadingGetFeedbacksAction;
