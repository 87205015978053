import {OrganizationsAction, OrganizationsActionEnum, OrganizationsState} from "./types";

const initialState: OrganizationsState = {
    foodChannel: null,
    otherDrink: null,
    drinkMaterial: null,
}

export default function organizationsReducer(state = initialState, action: OrganizationsAction): OrganizationsState {
    switch (action.type) {
        case OrganizationsActionEnum.SET_FOOD_CHANNEL:
            return {...state, foodChannel: action.payload}
        case OrganizationsActionEnum.SET_OTHER_DRINK:
            return {...state, otherDrink: action.payload}
        case OrganizationsActionEnum.SET_DRINK_MATERIAL:
            return {...state, drinkMaterial: action.payload}
        default:
            return state;
    }
}
