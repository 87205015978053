import React, {FunctionComponent, ReactNode} from "react";
import {Modal} from 'antd';

export interface FeedbackCreateModalProps {
    title: string;
    content: ReactNode;
    isModalOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    okText?: string;
    cancelText?: string;
}

export const FeedbackModal: FunctionComponent<FeedbackCreateModalProps> = (props) => {
    return (
        <Modal
            title={props.title}
            open={props.isModalOpen}
            onOk={props.onOk}
            onCancel={props.onCancel}
            okText={props.okText}
            cancelText={props.cancelText}
        >
            {props.content}
        </Modal>
    )
}
