import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import TaggingService, {ProductTagByIdReq, TenantTagByIdReq} from "../api/taggingService";
import {
    SetFoodChannelAction,
    SetOtherDrinkAction,
    SetDrinkMaterialAction,
    OrganizationsActionEnum,
    FoodChannelEnum,
    OtherDrinkEnum,
    DrinkMaterialEnum
} from "./types";

export const OrganizationsActionCreators = {
    setFoodChannel: (payload: FoodChannelEnum | null): SetFoodChannelAction => ({
        type: OrganizationsActionEnum.SET_FOOD_CHANNEL,
        payload
    }),
    setOtherDrink: (payload: OtherDrinkEnum | null): SetOtherDrinkAction => ({
        type: OrganizationsActionEnum.SET_OTHER_DRINK,
        payload
    }),
    setDrinkMaterial: (payload: DrinkMaterialEnum | null): SetDrinkMaterialAction => ({
        type: OrganizationsActionEnum.SET_DRINK_MATERIAL,
        payload
    }),

    tenantTagById: (orgId: string, controller: AbortController, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        await HttpActionCreatorWithResponse(dispatch, currentLang, TaggingService.getTenantTagById(orgId || "", controller), {
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                const foodChannel = res.data.data?.tags.find(tag => tag.includes("food-channel")) as FoodChannelEnum | null;
                dispatch(OrganizationsActionCreators.setFoodChannel(foodChannel));
            },
            hideNotify: true
        })
    },

    productTagById: (orgId: string, productId: string, controller: AbortController, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        await HttpActionCreatorWithResponse(dispatch, currentLang, TaggingService.getProductTagById(orgId || "", productId || "", controller), {
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                const otherDrink = res.data.data?.tags.find(tag => tag.includes("other-drink")) as OtherDrinkEnum | null;
                const drinkMaterial = res.data.data?.tags.find(tag => tag.includes("drink_material")) as DrinkMaterialEnum | null;
                dispatch(OrganizationsActionCreators.setOtherDrink(otherDrink));
                dispatch(OrganizationsActionCreators.setDrinkMaterial(drinkMaterial));
            },
            hideNotify: true
        })
    },

    setTagsForTenant: (request: TenantTagByIdReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        await HttpActionCreator(dispatch, currentLang, TaggingService.cmdSetTagsForTenant(request.tenantId || "", request), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const foodChannel = request.tags.find(tag => tag.includes("food-channel")) as FoodChannelEnum | null;
                dispatch(OrganizationsActionCreators.setFoodChannel(foodChannel))
            }
        })
    },

    setTagsForProduct: (request: ProductTagByIdReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, TaggingService.cmdSetTagsForProduct(currentUser?.org?.id || "", request), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const otherDrink = request.tags.find(tag => tag.includes("other-drink")) as OtherDrinkEnum | null;
                const drinkMaterial = request.tags.find(tag => tag.includes("drink_material")) as DrinkMaterialEnum | null;
                dispatch(OrganizationsActionCreators.setOtherDrink(otherDrink));
                dispatch(OrganizationsActionCreators.setDrinkMaterial(drinkMaterial));
            }
        })
    }
}
