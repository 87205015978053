import React from "react";

interface IProps {
    options: {
        id: string,
        name: string,
        link: string,
    }[],
    value: string
    onChange: (value: string, name:string) => void
}

export function MenuSelect(props: IProps) {
    return (
            <select className="button -blue"
                    value={props.value}
                    onChange={(e) => {
                        let now = props.options.find((option) => option.id == e.currentTarget.value)
                        if (now) (
                            props.onChange(e.currentTarget.value, now.name)
                        )
                    }}
            >

                {props.options.map((option, index) => {
                    return (
                        <option selected={option.id == props.value} style={{display:option.id == props.value?"none":"block"}}
                                value={option.id}>{option.name}</option>
                    )
                })}

            </select>

    )
}