import {AppDispatch, RootState} from "migration/app/store";
import {MentionsMapping} from "migration/entities/mentions";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {
    FailedResponseHandler,
    HttpActionCreator,
    HttpActionCreatorWithResponse,
    httpHandler
} from "migration/shared/lib/http-handler/httpHandler";
import MenuService, {
    AddProductMentionToProductReq,
    AddProductMentionToTopOfCategoryReq,
    ProductMentionsOfMenuReq,
    RemoveProductMentionReq
} from "../api/menuService";
import {
    ExcelFileUploadError,
    MenuActionEnum,
    SetExcelFileUploadErrorAction,
    SetIsLoadingExcelFileAction,
    SetMentionsMappingAction,
    SetIsLoadingGetMentionsMapping
} from "./types";

export const MenuActionCreators = {
    setIsLoadingExcelFile: (payload: boolean): SetIsLoadingExcelFileAction => ({
        type: MenuActionEnum.SET_IS_LOADING_EXCEL_FILE,
        payload
    }),
    setExcelFileUploadError: (payload: ExcelFileUploadError): SetExcelFileUploadErrorAction => ({
        type: MenuActionEnum.SET_EXCEL_FILE_UPLOAD_ERROR,
        payload
    }),
    setMentionsMapping: (payload: MentionsMapping): SetMentionsMappingAction => ({
        type: MenuActionEnum.SET_MENTIONS_MAPPING,
        payload
    }),
    setIsLoadingGetMentionsMapping: (payload: boolean): SetIsLoadingGetMentionsMapping => ({
        type: MenuActionEnum.SET_IS_LOADING_GET_MENTIONS_MAPPING,
        payload
    }),

    fillOutMenuFromExcel: (request: FormData, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(MenuActionCreators.setIsLoadingExcelFile(true));
            const res = await MenuService.cmdFillOutMenuFromExcel(request);
            if (res.data.success) {
                Notify.Success({title: txt.file_successfully_uploaded[currentLang], message: ""});
                dispatch(MenuActionCreators.setExcelFileUploadError({
                    success: true,
                    message: "no errors"
                }));
            } else {
                dispatch(MenuActionCreators.setExcelFileUploadError({
                    success: false,
                    message: res.data?.error?.message || txt.file_upload_error[currentLang]
                }));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback
            });
        } finally {
            dispatch(MenuActionCreators.setIsLoadingExcelFile(false));
        }
    },

    productMentionsOfMenu: (request: ProductMentionsOfMenuReq, controller: AbortController, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, MenuService.productMentionsOfMenu(currentUser?.org?.id || "", request, controller), {
            navigateCallback: navigationCallback,
            loadingCallback: MenuActionCreators.setIsLoadingGetMentionsMapping,
            successCallback: (res) => {
                dispatch(MenuActionCreators.setMentionsMapping(res.data?.data));
            },
        });
    },

    addProductMentionToProduct: (request: AddProductMentionToProductReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {mentionsMapping} = getState().menu;
        await HttpActionCreator(dispatch, currentLang, MenuService.addProductMentionToProduct(currentUser?.org?.id || "", request), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                if (Object.keys(mentionsMapping.toProducts).includes(request.toProductId)) {
                    dispatch(MenuActionCreators.setMentionsMapping({
                        ...mentionsMapping,
                        toProducts: {
                            ...mentionsMapping.toProducts,
                            [request.toProductId]: [...(mentionsMapping.toProducts[request.toProductId] || []), {
                                mentionId: request.mentionId,
                                productId: request.productId
                            }]
                        }
                    }));
                } else {
                    dispatch(MenuActionCreators.setMentionsMapping({
                        ...mentionsMapping,
                        toProducts: {
                            ...mentionsMapping.toProducts,
                            [request.toProductId]: [{
                                mentionId: request.mentionId,
                                productId: request.productId
                            }]
                        }
                    }));
                }
            },
        });
    },

    addProductMentionToTopOfCategory: (request: AddProductMentionToTopOfCategoryReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {mentionsMapping} = getState().menu;
        await HttpActionCreator(dispatch, currentLang, MenuService.addProductMentionToTopOfCategory(currentUser?.org?.id || "", request), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                if (Object.keys(mentionsMapping.toCategories).includes(request.toCategoryId)) {
                    dispatch(MenuActionCreators.setMentionsMapping({
                        ...mentionsMapping,
                        toCategories: {
                            ...mentionsMapping.toProducts,
                            [request.toCategoryId]: [...(mentionsMapping.toCategories[request.toCategoryId] || []), {
                                mentionId: request.mentionId,
                                productId: request.productId
                            }]
                        }
                    }));
                } else {
                    dispatch(MenuActionCreators.setMentionsMapping({
                        ...mentionsMapping,
                        toCategories: {
                            ...mentionsMapping.toCategories,
                            [request.toCategoryId]: [{
                                mentionId: request.mentionId,
                                productId: request.productId
                            }]
                        }
                    }));
                }
            },
        });
    },

    removeProductMention: (request: RemoveProductMentionReq, details: {toProductId: string, toCategoryId: string}, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {mentionsMapping} = getState().menu;
        await HttpActionCreator(dispatch, currentLang, MenuService.removeProductMention(currentUser?.org?.id || "", request), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const copy = {...mentionsMapping};
                if (!!details.toCategoryId) {
                    copy.toCategories[details.toCategoryId] = copy.toCategories[details.toCategoryId].filter(mention => mention.mentionId !== request.mentionId);
                    dispatch(MenuActionCreators.setMentionsMapping(copy));
                }

                if (!!details.toProductId) {
                    copy.toProducts[details.toProductId] = copy.toProducts[details.toProductId].filter(mention => mention.mentionId !== request.mentionId);
                    dispatch(MenuActionCreators.setMentionsMapping(copy));
                }
            },
        });
    },
}
