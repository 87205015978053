import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {Button} from "antd";
import {TXT} from "../../txtMap/embedded/txt";
import {IOrder} from "../../models/orders/order";
import {CURRENCY} from "../../utils/options/options";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {isEmpty} from "lodash";

interface IIikoOrderItem {
    order: IOrder
    iikoStatus: 'IN_PROGRESS' | 'ERROR' | 'DONE' | "Closed" | "Cancelled" | "Deleted" | null,
    iikoDetailedStatus: string | null,
    iikoOrderTask: {
        tableOrderIikoId: string | null,
        deliveryOrderIikoId: string | null
    } | null,
    setIikoOrderModalInfo: React.Dispatch<React.SetStateAction<{ order: IOrder | null, active: boolean }>>
    createdAt: string | null
    trigger: boolean
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>
}


export function IikoOrderItem(props: IIikoOrderItem) {
    const navigate = useNavigate();
    const {i18n} = useGlobalContext()
    const {user, menu, currentMenuId} = useUserContext()
    let paymetMethod = props.order.payment?.method as Exclude<keyof TXT, "runOne">

    const [loaders, setLoaders] = useState({
        approveIikoOrder: false,
        retryIikoOrder: false,
        cancelIikoDeliveryOrder: false
    });
    const [stillCanceling, setStillCanceling] = useState(false);
    const {iikoMenuSettings} = useTypedSelector(state => state.iiko)
    const {approveIikoOrder, retryIikoOrder, cancelIikoDeliveryOrder} = useActions();

    const handleApproveIikoOrder = async () => {
        setLoaders({...loaders, approveIikoOrder: true});
        await approveIikoOrder({
            menuId: currentMenuId,
            orderId: props.order?.id,
            organizationId: user?.org?.id || ""
        }, {navigate});
        setLoaders({...loaders, approveIikoOrder: false});
        props.setTrigger(!props.trigger);
    }

    const handleRetryIikoOrder = async () => {
        setLoaders({...loaders, retryIikoOrder: true});
        await retryIikoOrder({
            menuId: currentMenuId,
            orderId: props.order?.id,
            organizationId: user?.org?.id || ""
        }, {navigate});
        setLoaders({...loaders, retryIikoOrder: false});
        props.setTrigger(!props.trigger);
    }

    const handleCancelIikoDeliveryOrder = async () => {
        setLoaders({...loaders, cancelIikoDeliveryOrder: true});
        await cancelIikoDeliveryOrder({
            menuId: currentMenuId,
            orderId: props.order?.id,
            organizationId: user?.org?.id || ""
        }, {navigate});
        setLoaders({...loaders, cancelIikoDeliveryOrder: false});
        props.setTrigger(!props.trigger);
    }

    useEffect(() => {
        if (!props.order?.id) return;
        const cancelExpiresAt = JSON.parse(localStorage.getItem(`${currentMenuId}-${props.order?.id}`) || "{}")?.expiresAt;
        if (cancelExpiresAt) {
            if (moment(cancelExpiresAt).isAfter(moment(), "minute")) {
                setStillCanceling(true);
            } else {
                localStorage.removeItem(`${currentMenuId}-${props.order?.id}`);
            }
        }
    }, [props.order, currentMenuId]);

    return (
        <tr key={props.order.iiko?.order.externalNumber}>
            <td data-title="№">
                <a href="#/"
                   onClick={(event) => {
                       event.preventDefault()
                       props.setIikoOrderModalInfo({order: props.order, active: true})
                   }}
                >
                    {props.order.iiko?.order.externalNumber}
                </a>

            </td>
            <td data-title={i18n.customer()}>
                <span className="text-secondary">{props.order.iiko?.order.customer.name}</span><br/>
                <span className="">{props.order.iiko?.order.phone}</span>
            </td>
            <td data-title={i18n.order_content()}
                className="lg-text-right">
                {
                    props.order.iiko?.order.items?.map((item, index) => {
                        return (
                            <div key={`${item.productId}-${index}-${props.order.iiko?.order.externalNumber}`}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div>
                                        <span>{(item.name || {})[menu.i18n.defaultLang] || ""} {item.productSizeName} </span>
                                        {item.modifiers ?
                                            <span className="text-secondary">
                                                {item.modifiers.map((modifier) => {
                                                    return (
                                                        <>
                                                            <br/>
                                                            <span>
                                                            {modifier.name} x{modifier.amount}
                                                        </span>

                                                        </>
                                                    )
                                                })}
                                    </span>
                                            : null

                                        }
                                    </div>
                                    <span>x {item.amount}</span></div>
                            </div>
                        )
                    })
                }
                <br/>
                <span
                    className="font-weight-bold">{Math.ceil(props.order.totalPrice / 100)} {CURRENCY[props.order.currency]} </span>
            </td>
            <td data-title={i18n.delivery_type()}>
                {props.order.iiko?.order.tableNumbers?.length ?
                    i18n.in_doors()
                    :
                    <>
                        {i18n.delivery()}
                        <div className={'text-secondary'}>{props.order.iiko?.order.address}</div>
                    </>
                }
                {props.order.delivery.data?.deliveryComment && props.order.delivery.data?.deliveryComment.length > 0 ?
                    <>
                        <div className="tx-gray">{i18n.delivery_comment()}:</div>
                        <div>{props.order.delivery.data?.deliveryComment}</div>
                    </>
                    : null}
                {props.order.customerComment && props.order.customerComment.length > 0 ?
                    <>
                        <div className="tx-gray">{i18n.customerComment()}:</div>
                        <div>{props.order?.customerComment}</div>
                    </>
                    : null}
            </td>

            <td data-title={i18n.url_parameters()} style={{maxWidth: "400px"}}>

                {Object.keys(props.order.originURLParams || {}).map((key) => {
                    return (
                        <div style={{width: "100%", wordBreak: "break-all",}}>
                            <span style={{color: "orange"}}>{key}</span>
                            <span>{`=${props.order.originURLParams?.[key]}`}</span>
                        </div>
                    )
                })}

            </td>

            <td data-title={i18n.date()}>
                {props.createdAt ?
                    <span
                        style={{paddingLeft: "10px"}}>{moment(props.createdAt).format("DD.MM.YYYY HH:mm")}</span>
                    : null}
            </td>

            {user?.org?.availableModules?.includes("payments") ?
                <td data-title={i18n.status()}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {props.order.payment?.method && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#c1c1c1"}}>{i18n.runOne(paymetMethod)}</span>}
                        <div className="default-table__status">
                            {props.order.payment?.status === "approved" ?
                                <span
                                    className="tags__payment -paid">{i18n.paid()}</span>
                                :
                                <span
                                    className="tags__payment -not_paid">{i18n.unpaid()}</span>
                            }
                        </div>
                    </div>
                    {props.order.payment?.isStatusSetManually && <><b
                        style={{paddingLeft: "10px"}}>{i18n.changed_manually_short()}</b>
                        <br/></>}
                    {props.order.payment?.completedAt ?
                        <span
                            style={{paddingLeft: "10px"}}>{moment(props.order.payment.completedAt).format("DD.MM.YYYY HH:mm")}</span>
                        : null}

                </td>
                : null}

            {user?.org?.availableModules?.includes("iiko") ?
                <td data-title={i18n.iiko_creation_status()}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {(props.iikoStatus === "DONE") && (
                            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <span className={'tags__payment'} style={{backgroundColor: "#1b9956"}}>
                                    {i18n.iiko_creation_status_success()}
                                </span>
                                {(
                                    props.iikoDetailedStatus === "Cancelled" && (
                                        <span className={'tags__payment'}
                                              style={{backgroundColor: "#ff4136"}}>
                                    {i18n.iiko_creation_status_cancelled()}
                                </span>
                                    )
                                ) || (
                                    props.iikoDetailedStatus === "Deleted" && (
                                        <span className={'tags__payment'} style={{backgroundColor: "#ff4136"}}>
                                    {i18n.iiko_creation_status_deleted()}
                                </span>
                                    )
                                ) || (
                                    (!stillCanceling && props.iikoDetailedStatus === "ReadyForCooking") && (
                                        <span className={'tags__payment'} style={{backgroundColor: "rgb(26, 122, 255)"}}>
                                    {i18n.iiko_creation_status_is_ready_for_cooking()}
                                </span>
                                    )
                                )
                                }
                                {(stillCanceling && props.iikoDetailedStatus !== "Cancelled") && (
                                    <span className={'tags__payment'} style={{backgroundColor: "#686968"}}>
                                        {i18n.iiko_order_is_in_the_process_of_cancel()}
                                    </span>
                                )}
                            </div>
                        )}
                        {props.iikoStatus === "ERROR" && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#ff4136"}}>{i18n.iiko_creation_status_error()}
                        </span>}
                        {(props.iikoStatus === "IN_PROGRESS" || (!props.iikoStatus && !isEmpty(props.order?.iikoOrderTask))) &&
                            <span
                                className={'tags__payment'}
                                style={{backgroundColor: "#f2cc56"}}>{i18n.iiko_creation_status_in_progress()}
                        </span>}
                    </div>
                </td>
                : null}

            <td data-title={i18n.actions()}>
                <div className="default-table_actions">
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>

                        {(
                            iikoMenuSettings?.mustApproveOrdersBeforeSend &&
                            !props.iikoStatus &&
                            isEmpty(props.iikoOrderTask)
                        ) && (
                            <Button
                                style={{width: "100%"}}
                                type={"primary"}
                                onClick={handleApproveIikoOrder}
                                loading={loaders.approveIikoOrder}
                                disabled={loaders.approveIikoOrder}
                            >
                                {i18n.approve()}
                            </Button>
                        )}

                        {(
                            props.iikoStatus === "ERROR"
                        ) && (
                            <Button
                                style={{width: "100%"}}
                                onClick={handleRetryIikoOrder}
                                loading={loaders.retryIikoOrder}
                                disabled={loaders.retryIikoOrder}
                            >
                                {i18n.retry_process()}
                            </Button>
                        )}

                        {(
                            props.iikoStatus === "DONE" &&
                            !!props.iikoOrderTask?.deliveryOrderIikoId &&
                            props.iikoDetailedStatus !== "Closed" &&
                            props.iikoDetailedStatus !== "Cancelled" &&
                            props.iikoDetailedStatus !== "Deleted" &&
                            !stillCanceling
                        ) && (
                            <Button
                                style={{width: "100%"}}
                                type={"primary"}
                                danger
                                onClick={handleCancelIikoDeliveryOrder}
                                loading={loaders.cancelIikoDeliveryOrder}
                                disabled={loaders.cancelIikoDeliveryOrder}
                            >
                                {i18n.cancel()}
                            </Button>
                        )}
                    </div>
                </div>
            </td>

        </tr>
    )
}
