import {Link} from "react-router-dom";
import Select from "react-select";
import {options} from "../../utils/options/options";
import React, {useEffect} from "react";
import useAccount from "../../hooks/account/account";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useActions} from "../../migration/shared/lib/hooks/useActions";
import {LangsList} from "../../migration/entities/base/mlString";

export function Account() {
    const info = useAccount()
    const {user} = useUserContext()
    const {i18n, userInfoLoading} = useGlobalContext()
    const {setLang} = useActions();
    useEffect(() => {
        document.title = i18n.account_settings()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[{
                    title: i18n.account(),
                    link: "/account/",
                    index: "brcr-0"
                }]}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.account()}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            <form className="form">
                                <div className="control">
                                    <div className="control__label">{i18n.email()}</div>
                                    <span>{user?.user?.email}
                                    </span>
                                </div>


                                <div className="control">
                                    <div className="control__label">{i18n.ui_lang()}</div>
                                    <Select options={options}
                                            onChange={(v) => {
                                                info.handleChangeUserLang(v ? v.value : "RU")
                                            }}
                                            value={
                                                options.filter((v) => v.value === info.langSett)
                                            }
                                            getOptionValue={v => v.value}
                                            getOptionLabel={v => v.label}
                                    />
                                </div>
                                {!user?.isStaff ?
                                    <div className="control">
                                        <div className="control__label">{i18n.password()}</div>
                                        <Link to="/account/change-password/" className="button -bordered_blue">
                                            {i18n.change_password()}
                                        </Link>
                                    </div>
                                    : null}
                                <hr/>

                                <div className="form__buttons">
                                    <div className="form__buttons-left">
                                        <button className="button -blue form__button" type="submit"
                                                onClick={(e) => {
                                                    setLang(info.langSett as LangsList)
                                                    e.preventDefault()
                                                    info.handleSubmit()
                                                }}
                                        >
                                            {i18n.save()}
                                        </button>
                                        <Link to="/account/"
                                              className="button -bordered_blue form__button">
                                            {i18n.cancel()}
                                        </Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>


            </main>
        </section>
    )
}
