import {Link} from "react-router-dom";
import Select from 'react-select'
import {currencyOptions, options} from "../../utils/options/options";
import {SubmitButton} from "../input/submitButton";
import {useMenuForm} from "../../hooks/menu/menuCreate";
import {useGlobalContext} from "../../hooks/globalContext";
import {RouteNames} from "../../migration/pages";

export function MenuForm() {
    const info = useMenuForm()
    const {i18n} = useGlobalContext()
    return (
        <form className="form">

            <div className="control">
                <label className="control__label">{i18n.menu_name()}</label>
                <input className="control__input" name="org_name" value={info.menuInfo?.name}
                       onChange={(e) => info.onChangeName(e.currentTarget.value)}
                       type="text" placeholder={i18n.enter_name()}/>
            </div>


            <div className="control">
                <label className="control__label">{i18n.menu_url()}</label>
                <input className="control__input" name="org_address" value={info.menuInfo?.link}
                       onChange={(e) => info.onChangeLink(e.currentTarget.value)}
                       type="text" placeholder={i18n.enter_address()}/>
            </div>


            <div className="control">
                <div className="control__label">{i18n.menu_currency()}</div>
                <Select options={currencyOptions}
                        onChange={(e) => info.onChangeCurrency(e ? e.value : "")}
                        isOptionSelected={(v) => v.value === info.menuInfo.currency}
                        value={
                            currencyOptions.filter((v) => v.value === info.menuInfo.currency)
                        }
                />
            </div>


            <div className="control">
                <div className="control__label">{i18n.default_menu_lang()}</div>
                <Select options={options}
                        onChange={(v) => info.onChangeDefaultLang(v ? v.value : "RU")}
                        value={
                            options.filter((v) => v.value === info.menuInfo.defaultLang)
                        }
                />
            </div>


            <div className="control">
                <div className="control__label">{i18n.additional_langs()}</div>
                <Select options={options}
                        onChange={(v) => info.onChangeOtherLangs(v.map((value) => value.value))}
                        isMulti={true}
                        value={options.filter((v) => info.menuInfo.otherLangs?.includes(v.value))}
                />
            </div>
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.onSubmit} label={i18n.save()} isLoading={info.saveLoading}
                                  disabled={false} />
                    <Link to={RouteNames.SLIDER} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>
        </form>
    )
}
