import {LangsList} from "migration/entities/base/mlString";
import {txt} from "../core/i18ngen";

export const OrgModulesTranslate = (modules: string[], currentLang: LangsList): string[] => {
    return modules.map((module) => {
        switch (module) {
            case "menuOrders":
                return txt.co_module_available[currentLang];
            case "indoorsOrders":
                return txt.in_door_orders[currentLang];
            case "iiko":
                return "IIKO";
            case "payments":
                return txt.payment[currentLang];
            case "feedbacks":
                return txt.feedbacks[currentLang];
            default:
                return module;
        }
    });
}
