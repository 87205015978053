import {AxiosResponse} from "axios";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";
import {ICurrentUserInfo} from "../../../models/user/userLogin";

export default class UserService {
    static async fetchCurrentUser(): Promise<AxiosResponse<SuccessResponse<ICurrentUserInfo> | ErrorResponse>> {
        return $api.post<SuccessResponse<ICurrentUserInfo> | ErrorResponse>(ApiRoutes.CURRENT_USER)
    }
}
