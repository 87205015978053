import {OrgSettingsAction, OrgSettingsEnum, OrgSettingsState} from "./types";

const initialState: OrgSettingsState = {
    publicKey: "",
    encryptedPrivateKey: "",
    isLoadingLiqpaySettings: false,
    isLoadingEncodeLiqpayPrivateKey: false,
    isLoadingSaveLiqpaySettings: false,
}

export default function orgSettingsReducer(state = initialState, action: OrgSettingsAction): OrgSettingsState {
    switch (action.type) {
        case OrgSettingsEnum.SET_PUBLIC_KEY:
            return {...state, publicKey: action.payload};
        case OrgSettingsEnum.SET_ENCRYPTED_PRIVATE_KEY:
            return {...state, encryptedPrivateKey: action.payload};
        case OrgSettingsEnum.SET_IS_LOADING_LIQPAY_SETTINGS:
            return {...state, isLoadingLiqpaySettings: action.payload};
        case OrgSettingsEnum.SET_IS_LOADING_ENCODE_LIQPAY_PRIVATE_KEY:
            return {...state, isLoadingEncodeLiqpayPrivateKey: action.payload};
        case OrgSettingsEnum.SET_IS_LOADING_SAVE_LIQPAY_SETTINGS:
            return {...state, isLoadingSaveLiqpaySettings: action.payload};
        default:
            return {...state};
    }
}
