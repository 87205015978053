import "./phone.mobule.css";
import {useEffect, useState} from "react";
import {MenuStylize} from "../../models/menuStylize/menuStylize";
import {useUserContext} from "../../hooks/globalContext";
import moment from "moment";

interface IProps {
    style: MenuStylize
}

export function PhoneIframe(props: IProps) {
    const {user, currentMenuId} = useUserContext()
    const [power, setPower] = useState(true)
    const [iframeUrl, setIframeUrl] = useState<string>("")
    useEffect(() => {
        if (user?.org?.subdomain && user?.org.menus.menus && currentMenuId) {
            const url = `${process.env.REACT_APP_API_URL?.replace("api", user?.org.subdomain)}/menu/${user?.org.menus.menus?.find((v) => v.id === currentMenuId)?.link || ""}?preview=true`
            setIframeUrl(url)
        } else {
            //todo remove this after fix
            setIframeUrl(`${process.env.REACT_APP_API_URL?.replace("api", "demo")}/menu/delivery?preview=true`)
        }
    }, [user?.org, currentMenuId])
    useEffect(() => {
        const iframe = document.getElementById("phoneIframe") as HTMLIFrameElement
        if (iframe) {
            let data = {
                fontFamily: props.style.fontFamily,
                mainBg: props.style.mainBg,
                mainFg: props.style.mainFg,
                secondaryBg: props.style.secondaryBg,
                accentBg: props.style.accentBg,
                accentFg: props.style.accentFg,
                priceFg: props.style.priceFg,
                salesPriceFg: props.style.salesPriceFg,
                outOfStockFg: props.style.outOfStockFg,
                helperBorderBg: props.style.helperBorderBg,
                itemDisplayType: props.style.itemDisplayType,
                showSectionsOnMainPage: props.style.showSectionsOnMainPage
            }
            iframe.contentWindow?.postMessage({type: "setStyles", data: data}, "*")
        }
    }, [props.style])

    return (
        <div className={"mob"}>
            <div className="device device-iphone-x">
                <div className="device-frame">
                    <div style={{
                        height: "5%",
                        background: "white",
                        borderTopLeftRadius: "40px",
                        borderTopRightRadius: "40px",
                        paddingTop: "10px",
                        paddingLeft: "30px",
                        display: power ? "flex" : "none",
                    }}>
                        <div>{moment().format("HH:mm")}</div>
                    </div>
                    <iframe id={"phoneIframe"} style={{
                        width: "100%", height: "95%", display: power ? "block" : "none",
                        border: 0,
                        borderBottomLeftRadius: "40px",
                        borderBottomRightRadius: "40px",
                    }} className="phone-iframe" src={iframeUrl}></iframe>
                </div>
                <div className="device-stripe"></div>
                <div className="device-header"></div>
                <div className="device-sensors"></div>
                <div className="device-btns"></div>
                <div className="device-power" onClick={() => setPower(!power)}></div>
            </div>
        </div>
    )
}
