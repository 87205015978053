import {AxiosResponse} from "axios";
import {RkeeperMenu} from "migration/entities/rkeeperMenu";
import {RkeeperAgents} from "migration/entities/rkeeperAgents";
import {RkeeperRestaurant} from "migration/entities/rkeeperRestaurant";
import {RkeeperMenuSettings} from "migration/entities/rkeeperMenuSettings";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface CmdApproveRkeeperOrder {
    "menuId": string,
    "orderId": string,
    "organizationId": string
}

export interface CmdDeleteMenuRkeeperSettings {
    "menuId": string,
    "organizationId": string
}

export interface CmdImportRkeeperProducts {
    "menuId": string,
    "organizationId": string,
    "rkeeperProducts": { [key: string]: string[] }
}

export interface CmdImportRkeeperProductsRes {
    "notFoundProductIds": string[],
    "notFoundCategoryIds": string[],
    "alreadyBoundProductIds": string[],
}

export interface CmdRetryRkeeperOrder {
    "menuId": string,
    "orderId": string,
    "organizationId": string
}

export interface CmdSaveMenuRkeeperSettings {
    "menuId": string,
    "mustApproveOrdersBeforeSend": boolean,
    "organizationId": string,
    "rkeeperDiscountId": string,
    "rkeeperObjectId": string
}

export interface CmdSaveRkeeperProductBindings {
    "menuId": string,
    "organizationId": string,
    "productBindings": { [key: string]: string }
}

export interface CmdSaveRkeeperTableBindings {
    "menuId": string,
    "organizationId": string,
    "tableBindings": { [key: string]: number }
}

export interface GetMenuRkeeperSettingsReq {
    menuId: string
}

export interface GetRkeeperMenuReq {
    objectId: number
}

export interface GetRkeeperRestaurantReq {
    objectId: number
}

export class RkeeperService {
    static async cmdApproveRkeeperOrder(request: CmdApproveRkeeperOrder): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.APPROVE_RKEEPER_ORDER, request);
    }

    static async cmdDeleteMenuRkeeperSettings(request: CmdDeleteMenuRkeeperSettings): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.DELETE_MENU_RKEEPER_SETTINGS, request);
    }

    static async cmdImportRkeeperProducts(request: CmdImportRkeeperProducts): Promise<AxiosResponse<SuccessResponse<CmdImportRkeeperProductsRes> | ErrorResponse>> {
        return $api.post<SuccessResponse<CmdImportRkeeperProductsRes> | ErrorResponse>(ApiRoutes.IMPORT_RKEEPER_PRODUCTS, request);
    }

    static async cmdRetryRkeeperOrder(request: CmdRetryRkeeperOrder): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.RETRY_RKEEPER_ORDER, request);
    }

    static async cmdSaveMenuRkeeperSettings(request: CmdSaveMenuRkeeperSettings): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SAVE_MENU_RKEEPER_SETTINGS, request);
    }

    static async cmdSaveRkeeperProductBindings(request: CmdSaveRkeeperProductBindings): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SAVE_RKEEPER_PRODUCT_BINDINGS, request);
    }

    static async cmdSaveRkeeperTableBindings(request: CmdSaveRkeeperTableBindings): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SAVE_RKEEPER_TABLE_BINDINGS, request);
    }

    static async getMenuRkeeperSettings(request: GetMenuRkeeperSettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<RkeeperMenuSettings> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.MENU_RKEEPER_SETTIGNS, request, {signal: controller.signal});
    }

    static async getRkeeperAgents(request = {}, controller: AbortController): Promise<AxiosResponse<SuccessResponse<RkeeperAgents[]> | ErrorResponse>> {
        return $api.post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.RKEEPER_AGENTS, request, {signal: controller.signal});
    }

    static async getRkeeperMenu(request: GetRkeeperMenuReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<RkeeperMenu> | ErrorResponse>> {
        return $api.post<SuccessResponse<RkeeperMenu> | ErrorResponse>(ApiRoutes.RKEEPER_MENU, request, {signal: controller.signal});
    }

    static async getRkeeperRestaurant(request: GetRkeeperRestaurantReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<RkeeperRestaurant> | ErrorResponse>> {
        return $api.post<SuccessResponse<RkeeperRestaurant> | ErrorResponse>(ApiRoutes.RKEEPER_RESTAURANT, request, {signal: controller.signal});
    }
}
