import {AxiosResponse} from "axios";
import {OrgId} from "migration/entities/baseAliases";
import {TelegramPoolOfTenant} from "migration/entities/telegramPoolOfTenant";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";
import {ReceiverList} from "../../../../../entities/receiverList";

export interface cmdBlockTgGroupInTelegramPoolReq {
    "tgChatId": number,
    "tgGroupTitle": string,
}

export interface cmdUnBlockTgGroupInTelegramPoolReq {
    "tgChatId": number,
    "tgGroupTitle": string,
}

export interface cmdBlockTgUserInTelegramPoolReq {
    "tgChatId": number,
    "tgUserId": number,
    "tgUsername": string,
}

export interface cmdUnBlockTgUserInTelegramPoolReq {
    "tgChatId": number,
    "tgUserId": number,
    "tgUsername": string,
}

export interface cmdCreateReceiverListReq {
    "lang": string,
    "name": string,
    "receiverListId": string,
}

export interface cmdSpecifyTgUsersInReceiverList {
    receiverListId: string,
    tgUsers: {
        chatId: number,
        userId: number,
        username: string,
    }[]
}

export interface cmdSpecifyTgGroupsInReceiverList {
    receiverListId: string,
    tgGroups: {
        chatId: number,
        groupTitle: string,
    }[]
}

export interface cmdSpecifyEmailsInReceiverList {
    receiverListId: string,
    emails: string[],
}

export interface cmdDeleteReceiverListReq {
    "receiverListId": string
}

export interface cmdRenameReceiverListReq {
    "name": string,
    "receiverListId": string
}

export interface cmdBindReceiverListFromFeedbackPageReq {
    "feedbackPageId": string,
    "receiverListId": string,
}

export interface cmdUnbindReceiverListFromFeedbackPageReq {
    "feedbackPageId": string,
    "receiverListId": string,
}

export default class NotificationService {
    static async getTelegramPoolOfTenant(orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<TelegramPoolOfTenant> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<TelegramPoolOfTenant> | ErrorResponse>(ApiRoutes.TELEGRAM_POOL_OF_TENANT, {}, {signal: controller.signal});
    }

    static async getAllReceiverLists(orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<{receiverLists: ReceiverList[]}> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<{receiverLists: ReceiverList[]}> | ErrorResponse>(ApiRoutes.ALL_RECEIVER_LISTS, {}, {signal: controller.signal});
    }

    static async cmdBlockTgGroupInTelegramPool(request: cmdBlockTgGroupInTelegramPoolReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.BLOCK_TG_GROUP_IN_TELEGRAM_POOL, request);
    }

    static async cmdUnblockTgGroupInTelegramPool(request: cmdUnBlockTgGroupInTelegramPoolReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNBLOCK_TG_GROUP_IN_TELEGRAM_POOL, request);
    }

    static async cmdBlockTgUserInTelegramPool(request: cmdBlockTgUserInTelegramPoolReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.BLOCK_TG_USER_IN_TELEGRAM_POOL, request);
    }

    static async cmdUnblockTgUserInTelegramPool(request: cmdUnBlockTgUserInTelegramPoolReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNBLOCK_TG_USER_IN_TELEGRAM_POOL, request);
    }

    static async cmdCreateReceiverList(request: cmdCreateReceiverListReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.CREATE_RECEIVER_LIST, request);
    }

    static async cmdSpecifyTgUsersInReceiverList(request: cmdSpecifyTgUsersInReceiverList, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SPECIFY_TG_USERS_IN_RECEIVER_LIST, request);
    }

    static async cmdSpecifyTgGroupsInReceiverList(request: cmdSpecifyTgGroupsInReceiverList, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SPECIFY_TG_GROUPS_IN_RECEIVER_LIST, request);
    }

    static async cmdSpecifyEmailsInReceiverList(request: cmdSpecifyEmailsInReceiverList, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.SPECIFY_EMAILS_IN_RECEIVER_LIST, request);
    }

    static async cmdDeleteReceiverList(request: cmdDeleteReceiverListReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.DELETE_RECEIVER_LIST, request);
    }

    static async cmdRenameReceiverList(request: cmdRenameReceiverListReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<any> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<any> | ErrorResponse>(ApiRoutes.RENAME_RECEIVER_LIST, request);
    }

    static async cmdBindReceiverListToFeedbackPage(request: cmdBindReceiverListFromFeedbackPageReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.BIND_RECEIVER_LIST_TO_FEEDBACK_PAGE, request);
    }

    static async cmdUnbindReceiverListFromFeedbackPage(request: cmdBindReceiverListFromFeedbackPageReq, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.UNBIND_RECEIVER_LIST_FROM_FEEDBACK_PAGE, request);
    }
}
