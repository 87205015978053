import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {TenantCrossSelling} from "migration/entities/tenant";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import {CrossSellingActionEnum, SetIsLoadingGetTenant, SetTenantAction} from "./types";
import {
    CrossSellingService,
    AssociateCrossSellingToMenuCategoryReq,
    AssociateCrossSellingToProductReq,
    DissociateCrossSellingFromMenuCategory,
    DissociateCrossSellingFromProduct,
    SetCommonCrossSellingProducts,
    SetDefaultCrossSellingLabel, RemoveCommonCrossSellingProducts,
} from "../api/crossSellingService";

export const CrossSellingActionCreators = {
    setCrossSellingMapping: (payload: TenantCrossSelling): SetTenantAction => ({
        type: CrossSellingActionEnum.SET_TENANT,
        payload: payload
    }),
    setIsLoadingGetTenant: (payload: boolean): SetIsLoadingGetTenant => ({
        type: CrossSellingActionEnum.SET_IS_LOADING_GET_TENANT,
        payload: payload
    }),

    getTenantById: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, CrossSellingService.tenantById(currentUser?.org?.id || "", controller), {
            loadingCallback: CrossSellingActionCreators.setIsLoadingGetTenant,
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                dispatch(CrossSellingActionCreators.setCrossSellingMapping({
                    products: res.data?.data?.["cross-selling"]?.products || {},
                    categories: res.data?.data?.["cross-selling"]?.categories || {},
                    commonProductIds: res.data?.data?.["cross-selling"]?.commonProductIds || []
                }));
            }
        })
    },

    associateCrossSellingToMenuCategory: (request: AssociateCrossSellingToMenuCategoryReq, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.associateCrossSellingToMenuCategory(currentUser?.org?.id || "", request), {
            mainCallback: () => {
                dispatch(CrossSellingActionCreators.setCrossSellingMapping({
                    ...crossSelling,
                    categories: {
                        ...crossSelling.categories,
                        [request.sourceCategoryId]: {
                            customLabel: request.customLabel,
                            productIds: request.targetProductIds,
                        }
                    }
                }));
            }
        })
    },

    associateCrossSellingToProduct: (request: AssociateCrossSellingToProductReq, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.associateCrossSellingToProduct(currentUser?.org?.id || "", request), {
            mainCallback: () => {
                dispatch(CrossSellingActionCreators.setCrossSellingMapping({
                    ...crossSelling,
                    products: {
                        ...crossSelling.products,
                        [request.sourceProductId]: {
                            customLabel: request.customLabel,
                            productIds: request.targetProductIds,
                        }
                    }
                }));
            }
        })
    },

    dissociateCrossSellingFromMenuCategory: (request: DissociateCrossSellingFromMenuCategory, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.dissociateCrossSellingFromMenuCategory(currentUser?.org?.id || "", request), {
            mainCallback: () => {
                const copy: TenantCrossSelling = {...crossSelling};
                delete copy?.categories[request.sourceCategoryId];
                dispatch(CrossSellingActionCreators.setCrossSellingMapping(copy));
            }
        })
    },

    dissociateCrossSellingFromProduct: (request: DissociateCrossSellingFromProduct, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.dissociateCrossSellingFromProduct(currentUser?.org?.id || "", request), {
            mainCallback: () => {
                const copy: TenantCrossSelling = {...crossSelling};
                delete copy?.products[request.sourceProductId];
                dispatch(CrossSellingActionCreators.setCrossSellingMapping(copy));
            }
        })
    },

    removeCommonCrossSellingProducts: (request: RemoveCommonCrossSellingProducts, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.removeCommonCrossSellingProducts( currentUser?.org?.id || "", request), {
            mainCallback: () => {
                dispatch(CrossSellingActionCreators.setCrossSellingMapping({
                    ...crossSelling,
                    commonProductIds: [
                        ...crossSelling?.commonProductIds?.filter(itemId => !request.productIds.includes(itemId))
                    ]
                }));
            }
        })
    },

    setCommonCrossSellingProducts: (request: SetCommonCrossSellingProducts, crossSelling: TenantCrossSelling) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.setCommonCrossSellingProducts(currentUser?.org?.id || "", request), {
            mainCallback: () => {
                dispatch(CrossSellingActionCreators.setCrossSellingMapping({
                    ...crossSelling,
                    commonProductIds: request.productIds
                }))
            }
        })
    },

    setDefaultCrossSellingLabel: (request: SetDefaultCrossSellingLabel) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, CrossSellingService.setDefaultCrossSellingLabel(currentUser?.org?.id || "", request), {
            mainCallback: () => {

            }
        })
    },

}
