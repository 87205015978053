import {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {useParams} from "react-router-dom";
import {iikoProductBinding, iikoProducts, IiikoProduct} from "../../models/iiko/product";

export function useItemIiko() {
    const {api} = useGlobalContext();
    const {currentMenuId, iikoCompany, iikoActiveMenu} = useUserContext();

    // const [products, setProducts] = useState<iikoProducts>({
    //     iikoProducts: []
    // });
    // const [bindProduct, setBindProduct] = useState<IiikoProduct|null>(null);
    // const [bindProd, setBindProd] = useState<iikoProductBinding | null>(null);
    const [bindNow, setBindNow] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    // const {id} = useParams()

    // const [iikoProductsLoading, setIikoProductsLoading] = useState<boolean>(false)
    // const [iikoProductBindingLoading, setIikoProductBindingLoading] = useState<boolean>(false)
    // const [bindLoading, setBindLoading] = useState<boolean>(false)

    // useEffect(() => {
    //     if (!bindProd) return;
    //     if (!iikoActiveMenu) return;
    //     if (!id) return;
    //     const controller = new AbortController();
    //     let item  = products.iikoProducts?.find((item) => item.id === bindProd?.iikoProductId)
    //     if (item) {
    //         setBindProduct(item)
    //     }
    // }, [id, currentMenuId])


    // useEffect(() => {
    //     if (!iikoActiveMenu) return;
    //     if (!id) return;
    //     if (!currentMenuId) return;
    //     const controller = new AbortController();
    //     api.getIIkoProductBinding({
    //         setLoading: setIikoProductBindingLoading,
    //         controller
    //     }, currentMenuId, id).then((res) => {
    //         if (res.data) {
    //             setBindProd(res.data)
    //             setBindNow(res.data.iikoProductId)
    //         }
    //     })
    //     api.getIIkoProducts({controller, setLoading: setIikoProductsLoading}, currentMenuId).then((res) => {
    //         if (res.data) {
    //             setProducts(res.data)
    //         }
    //     })
    //     return () => controller.abort()
    // }, [])

    // function onBindProduct() {
    //     if (!iikoActiveMenu) return;
    //     if (!id) return;
    //     if (!bindNow) return;
    //     api.createIIkoProductBinding({setLoading: setBindLoading}, currentMenuId, id, bindNow).then((res) => {
    //         if (res.data) {
    //             api.getIIkoProductBinding({setLoading: setIikoProductsLoading}, currentMenuId, id).then((res) => {
    //                 if (res.data) {
    //                     setBindProd(res.data)
    //                 }
    //             })
    //         }
    //     })
    // }

    // function onUnbindProduct() {
    //     if (!iikoActiveMenu) return;
    //     if (!bindProd?.id) return;
    //     api.deleteIIkoProductBinding({setLoading: setBindLoading}, bindProd.id).then((res) => {
    //         if (res.data) {
    //             setBindProd(null)
    //         }
    //     })
    // }

    return {
        // products,
        // bindProd,
        bindNow,
        setBindNow,
        // onBindProduct,
        // onUnbindProduct,
        modalOpen,
        iikoCompany,
        setModalOpen,
        iikoActiveMenu: iikoActiveMenu,
        // bindLoading,
        // bindProduct
    }
}
