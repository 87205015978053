import {LangsList, MlString} from "migration/entities/base/mlString";

export const MlStringCompare = (firstMlString: MlString, secondMlString: MlString): boolean => {
    if (Object.keys(firstMlString || {}).length !== Object.keys(secondMlString || {}).length) {
        return false;
    }

    let isValid = true;

    const firstArr = Object.keys(firstMlString || {}).sort();
    const secondArr = Object.keys(secondMlString || {}).sort();
    firstArr.forEach((_, i) => {
        if (firstArr[i] !== secondArr[i]) {
            isValid = false;
            return;
        }
    })

    Object.keys(firstMlString || {}).forEach((lang) => {
        if (firstMlString[lang as LangsList] !== secondMlString[lang as LangsList]) {
            isValid = false;
            return;
        }
    })

    return isValid;
}
