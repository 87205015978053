import {useGlobalContext} from "../globalContext";
import {useEffect, useState} from "react";
import {IOrganization} from "../../models/organizations/organization";


export function useClientsInfo() {
    const {api} = useGlobalContext()

    const paginationStep = 20
    const [fetchInfo, setFetchInfo] = useState<{ page: number, find: string }>({page: 0, find: ""})
    const [count, setCount] = useState<number>(0)
    let [orgs, setOrgs] = useState<IOrganization[]>([])

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        api.getOrganizations({
            controller,
            setLoading
        }, paginationStep, fetchInfo.page * paginationStep, fetchInfo.find).then((res) => {
            if (res.data) {
                setOrgs(res.data.organizations || [])
                setCount(res.data.count)
            }
        })
        return () => controller.abort()
    }, [fetchInfo])

    function handleFind(value: string) {
        setFetchInfo({page: 0, find: value})
    }

    function resetThePassword(pass: string, index: number) {
        setOrgs(orgs.map((org, i) => {
            if (i === index) {
                org.tmpPassword = pass
                org.tmpPswChanged = false
            }
            return org
        }))
    }

    function enableDisableOrg(orgId: string, isDisabled: boolean, setEnableDisableLoading: (loading: boolean) => void) {
        if (isDisabled) {
            api.enableOrganization({setLoading: setEnableDisableLoading}, orgId).then((res) => {
                if (res.success) {
                    setOrgs(orgs.map((org) => {
                        if (org.id === orgId) {
                            org.isDisabled = false
                            return {...org}
                        }
                        return org
                    }))
                }
            })
        } else {
            api.disableOrganization({setLoading: setEnableDisableLoading}, orgId).then((res) => {
                if (res.success) {
                    setOrgs(orgs.map((org) => {
                        if (org.id === orgId) {
                            org.isDisabled = true
                            return {...org}
                        }
                        return org
                    }))
                }
            })
        }
    }

    function setPage(page: number) {
        setFetchInfo({...fetchInfo, page})
    }

    return {
        count, loading,
        orgs, page: fetchInfo.page,
        step: paginationStep,
        setPage, enableDisableOrg,
        handleFind, resetThePassword
    }
}