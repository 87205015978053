import {AxiosResponse} from "axios";
import {FeedbackPageId, OrgId} from "migration/entities/baseAliases";
import {Feedback, FeedbackDashboardData} from "migration/entities/Feedback";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface getFeedbacksByFiltersReq {
    feedbackPageId: FeedbackPageId;
    limit: number;
    offset: number;
    filterType: "all" | "positive-only" | "negative-only";
}

export default class FeedbackPageService {
    static async getFeedbackDashboardData(id: string, orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<FeedbackDashboardData> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<FeedbackDashboardData> | ErrorResponse>(ApiRoutes.FEEDBACK_DASHBOARD_DATA, {id: id}, {signal: controller.signal});
    }

    static async getFeedbacks(request: getFeedbacksByFiltersReq, orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<{ totalCount: number, feedbacks: Feedback[] }> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<{ totalCount: number, feedbacks: Feedback[] }> | ErrorResponse>(ApiRoutes.FEEDBACKS_BY_FILTERS, request, {signal: controller.signal});
    }
}
