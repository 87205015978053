import {useEffect, useMemo, useState} from "react";
import {I18nString} from "../../models/lang/i18nString";
import {useGlobalContext, useUserContext} from "../globalContext";
import {menuItem} from "../../models/menu/menu";
import { IStopListItem } from "../../models/iiko/stopList";

export function useCatalog() {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, iikoActiveMenu, iikoBindOrg} = useUserContext()

    let [catSec, setCatSec] = useState<{ [key: string]: { category: I18nString, section: I18nString } }>({})
    let [items, setItems] = useState<menuItem[]>([])
    let [page, setPage] = useState<number>(0)
    const [modalInfo, setModalInfo] = useState<menuItem | null>(null)
    const [sortConfig, setSortConfig] = useState<{ price: string, name: string, find: string }>({
        price: "",
        name: "",
        find: ""
    });

    const [stopList, setStopList] = useState<{[id:string]:IStopListItem}>({})

    const [loading, setLoading] = useState<boolean>(false)
    const [iikoLoading, setIikoLoading] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!iikoActiveMenu || !iikoBindOrg) {
            return
        }
        let controller = new AbortController()
        if (currentMenuId) {
            api.getIikoStopList({controller, setLoading:setIikoLoading},currentMenuId).then((res) => {
                if (res.success && res.data) {
                    let temp:{[key:string]:string} = {}
                    res.data.bindItems.forEach((item) => {
                        temp[item.iikoProductId] = item.kamiItemId
                    })
                    let TempStopList:{[key:string]:IStopListItem} = {}
                    res.data.items.forEach((item) => {
                        TempStopList[temp[item.productId]] = item
                    })
                    setStopList(TempStopList)
                }
            })
        }
        return () => controller.abort()
    }, [currentMenuId, iikoActiveMenu, iikoBindOrg])

    let [withdraw] = useState<number>(20)


    const itemMemo = useMemo(() => {
        setLoading(true)
        let sortedProducts = [...items];
        sortedProducts = sortedProducts.filter(value => !!catSec[value.id])///todo metastatus isDeleted
        setPage(0)
        if (sortConfig.price) {
            if (sortConfig.price === "up") {
                sortedProducts.sort((a, b) => {
                    if (a.prices.primary.price - b.prices.primary.price >= 0) {
                    }
                    return a.prices.primary.price - b.prices.primary.price
                })
            } else {
                sortedProducts.sort((a, b) => {
                    return b.prices.primary.price - a.prices.primary.price
                })
            }
        } else if (sortConfig.name) {
            if (sortConfig.name === "up") {
                sortedProducts.sort((a, b) => {
                    if (a.name[menu.i18n.defaultLang] > b.name[menu.i18n.defaultLang]) {
                        return 1
                    } else if (a.name[menu.i18n.defaultLang] < b.name[menu.i18n.defaultLang]) {
                        return -1
                    }
                    return 0
                })
            } else {
                sortedProducts.sort((a, b) => {
                    if (a.name[menu.i18n.defaultLang] > b.name[menu.i18n.defaultLang]) {
                        return -1
                    } else if (a.name[menu.i18n.defaultLang] < b.name[menu.i18n.defaultLang]) {
                        return 1
                    }
                    return 0
                })
            }

        }
        if (sortConfig.find) {
            sortedProducts = sortedProducts.filter((item) => {
                let name = (item.name[menu.i18n.defaultLang] ? item.name[menu.i18n.defaultLang] : "").toLowerCase().includes(sortConfig.find.toLowerCase())
                let price = (Math.ceil(item.prices.primary.price / 100)).toString().includes(sortConfig.find.toLowerCase())
                let findCatSec = (catSec[item.id].section[menu.i18n.defaultLang] ? catSec[item.id].section[menu.i18n.defaultLang] : "").toLowerCase().includes(sortConfig.find.toLowerCase()) ||
                    (catSec[item.id].category[menu.i18n.defaultLang] ? catSec[item.id].category[menu.i18n.defaultLang] : "").toLowerCase().includes(sortConfig.find.toLowerCase())
                return name || price || findCatSec
            })
        }
        setLoading(false)
        return sortedProducts;
    }, [items, sortConfig]);

    useEffect(() => {
        const controller = new AbortController()
        if (!currentMenuId) return
        api.getMenuStruct({controller, setLoading: setLoading}, currentMenuId).then((res) => {
            if (res.success && res.data) {
                setLoading(true)
                let menu = res.data
                let itemsList: menuItem[] = []
                let catSecDef: { [key: string]: { category: I18nString, section: I18nString } } = {}
                let keys = Object.keys(menu.menuItems)
                for (const key of keys) {
                    itemsList.push(menu.menuItems[key])
                    let catId = menu.menuStruct.items[key].parent
                    let secId = menu.menuStruct.categories[catId].parent
                    catSecDef[key] = {
                        category: menu.menuCategory[catId].name,
                        section: menu.menuSection[secId].name
                    }
                }
                setCatSec(catSecDef)
                setItems(itemsList)
                setLoading(false)
            }
        })

        return () => controller.abort()
    }, [currentMenuId])

    //Event
    function onChangeFindValue(s: string) {
        setSortConfig({...sortConfig, find: s})
    }

    function onClickSortByName() {
        if (sortConfig.name === "up") {
            setSortConfig({...sortConfig, price: "", name: "down"})
            return
        }
        setSortConfig({...sortConfig, price: "", name: "up"})
    }

    function onClickSortByPrice() {
        if (sortConfig.price === "up") {
            setSortConfig({...sortConfig, price: "down", name: ""})
            return
        }
        setSortConfig({...sortConfig, price: "up", name: ""})
    }

    function onDeleteProduct(id: string) {
        api.deleteItem({setLoading: setDeleteLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                let newItems = items.filter(item => item.id !== id)
                setItems(newItems)
            }
        })
    }

    return {
        catSec,
        itemMemo,
        page, withdraw,
        items,
        modalInfo,
        onDeleteProduct,
        setModalInfo,
        onChangeFindValue,
        onClickSortByName,
        onClickSortByPrice,
        menu,
        setPage,
        iikoActiveMenu: iikoActiveMenu,
        deleteLoading,
        loading,
        stopList,
    }
}
