

interface ILittleSpinnerProps {
    style?: React.CSSProperties
}

export function LittleSpinner(props: ILittleSpinnerProps) {
    return (
        <i className="lds-dual-ring" style={props.style}></i>
    )
}