import {InputText} from "../input/inputText";
import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {SubmitButton} from "../input/submitButton";
import {DomainSettings} from "../../models/requests/domainSettings";
import {FormWithLoader} from "../loader/formWithLoader";
import {addNotification} from "../../utils/toast/toast";

export function DomainSettingsModule() {
    const {user} = useUserContext();
    const {i18n, api} = useGlobalContext();

    const [domainSetttings, setDomainSettings] = useState<DomainSettings>({
        "host": "",
        "orgId": user?.org?.id || "",
        "mainMenuId": "",
        "privateKey": "",
        "certificate": "",
    });
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [canBeDeleted, setCanBeDeleted] = useState<boolean>(false);

    const handleCertificateChange = (certificate: any) => {
        setDomainSettings({...domainSetttings, certificate: certificate});
    }

    const handleHostChange = (host: string) => {
        setDomainSettings({...domainSetttings, host: host});
    }

    const handleMenuIdChange = (menuId: string) => {
        setDomainSettings({...domainSetttings, mainMenuId: menuId});
    }

    const handlePrivateKeyChange = (privateKey: string) => {
        setDomainSettings({...domainSetttings, privateKey: privateKey});
    }

    const onSave = async () => {
        if (!domainSetttings?.host) {
            addNotification({message: i18n.please_enter_host(), type: "info"})
            return;
        }
        if (!domainSetttings?.certificate) {
            addNotification({message: i18n.please_enter_certificate(), type: "info"})
            return;
        }
        if (!domainSetttings?.privateKey) {
            addNotification({message: i18n.please_enter_private_key(), type: "info"})
            return;
        }

        await api.updateOrgDomainSettings({setLoading: setLoading}, domainSetttings?.mainMenuId ? {
            ...domainSetttings,
            certificate: domainSetttings.certificate.trim(),
            privateKey: domainSetttings.privateKey.trim(),
        } : {
            orgId: domainSetttings?.orgId,
            host: domainSetttings.host,
            certificate: domainSetttings.certificate.trim(),
            privateKey: domainSetttings.privateKey.trim(),
        })
        await fetchDomainSettings()
    }

    const onDelete = async () => {
        if (!canBeDeleted) return;
        await api.deleteOrgDomainSettings({setLoading: setLoading}, user?.org?.id || "")
        setDomainSettings({
            "host": "",
            "orgId": user?.org?.id || "",
            "mainMenuId": "",
            "privateKey": "",
            "certificate": "",
        })
        await fetchDomainSettings()
    }

    const fetchDomainSettings = async () => {
        const response = await api.getOrgDomainSettngs({setLoading: setFetchLoading}, user?.org?.id || "")
        if (response.success && Object.keys(response?.data || {}).length > 0) {
            setCanBeDeleted(true)
            setDomainSettings({...response.data || {} as DomainSettings})
        }
    }

    useEffect(() => {
        fetchDomainSettings()
    }, [])

    return (
        <FormWithLoader className="form" isloading={fetchLoading}>
            <div className="control">
                <label className="control__label">{i18n.select_menu()}</label>

                <select
                    className="control__input"
                    value={domainSetttings.mainMenuId || ""}
                    onChange={(e) => {
                        handleMenuIdChange(e.currentTarget.value)
                    }}
                >

                    <option key={"default"} value={""}>{i18n.not_chosen()}</option>
                    {user?.org?.menus?.menus?.map((item) => {
                        return (
                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                        )
                    })}

                </select>

            </div>

            <InputText
                label={i18n.host()}
                value={domainSetttings.host || ""}
                onChange={(cd) => handleHostChange(cd)}
                placeholder={i18n.enter_host()} isValidate={true}
            />

            <div className="control">
                <label className="control__label">{i18n.certificate()}</label>
                <textarea
                    className="control__textarea"
                    value={domainSetttings.certificate || ""}
                    onChange={(e) => handleCertificateChange(e.target.value)}
                    rows={15}
                    placeholder={i18n.enter_certificate()}
                ></textarea>
            </div>

            <div className="control">
                <label className="control__label">{i18n.private_key()}</label>
                <textarea
                    className="control__textarea"
                    value={domainSetttings.privateKey || ""}
                    onChange={(e) => handlePrivateKeyChange(e.target.value)}
                    rows={15}
                    placeholder={i18n.enter_private_key()}
                ></textarea>
            </div>

            <div className="form__buttons mt-4">
                <SubmitButton onSubmit={onSave} label={i18n.save()}
                              disabled={loading} isLoading={loading}
                              modal={true}/>

                {
                    canBeDeleted && (
                        <SubmitButton onSubmit={onDelete} label={i18n.delete()}
                                      modal={true} isLoading={loading} disabled={loading} isDelete={true}/>
                    )
                }
            </div>
        </FormWithLoader>
    )
}
