import {FunctionComponent} from "react";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {RouteNames} from "migration/pages";
import {Loading} from "../Loading";
import classes from "./Tabs.module.scss";

export interface Tab {
    link: RouteNames;
    title: string;
}

export const Tabs: FunctionComponent<{ tabs: Tab[], loading?: boolean, hideLoading?: boolean }> = (
    {
        tabs,
        loading,
        hideLoading = false
    }
) => {
    const location = useLocation();
    return (
        <div className={classes.tab__container}>
            <div className={classes.tab__bar}>
                {tabs.map((tab) => (
                    <NavLink
                        key={tab.link}
                        to={tab.link}
                        className={location.pathname === tab.link ? classes.tab__active : classes.tab}
                    >
                        <div className={classes.tab__text}>{tab.title}</div>
                    </NavLink>
                ))}
            </div>

            <div className={classes.tab__content}>
                <div className={classes.tab__content__card}>
                    {(!hideLoading && loading) && (
                        <div className={classes.tab__content__card__loading}>
                            <Loading/>
                        </div>
                    )}
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}
