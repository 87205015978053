import React, {useState} from "react";
import {ColorResult, SketchPicker} from 'react-color';

interface ColorInputProps {
    label?: string,
    value: {
        r: number,
        g: number,
        b: number,
    },
    setValue: (c: number[]) => void,
}

export function ColorInput(props: ColorInputProps) {
    const [isShow, setIsShow] = useState(false)
    return (
        <div className="control">
            {props.label ? <label className="control__label">{props.label}</label> : null}
            <div style={{display: "flex"}}>
                <div style={{paddingLeft: "3px"}}>
                    <input className="control__input_i18n -color" value={props.value.r}
                           type="text" onChange={(e) => {
                        let val = parseInt(e.currentTarget.value)
                        if (val > 255) val = 255
                        if (val < 0) val = 0
                        if (isNaN(val)) val = 0
                        props.setValue([val, props.value.g, props.value.b])
                    }
                    }
                    />
                </div>
                {/*<div style={{width: "3px", background: "rgba(217,1,49,0.62)"}}/>*/}
                <div style={{paddingLeft: "3px"}}>
                    <input className="control__input_i18n -color" value={props.value.g}
                           type="text" onChange={(e) => {
                        let val = parseInt(e.currentTarget.value)
                        if (val > 255) val = 255
                        if (val < 0) val = 0
                        if (isNaN(val)) val = 0
                        props.setValue([props.value.r, val, props.value.b])
                    }}
                    />
                </div>
                {/*<div style={{width: "3px", background: "rgba(11,159,0,0.62)"}}/>*/}
                <div style={{paddingLeft: "3px"}}>
                    <input className="control__input_i18n -color" value={props.value.b}
                           type="text" onChange={(e) => {
                        let val = parseInt(e.currentTarget.value)
                        if (val > 255) val = 255
                        if (val < 0) val = 0
                        if (isNaN(val)) val = 0
                        props.setValue([props.value.r, props.value.g, val])
                    }}
                    />
                </div>
                {/*<div style={{width: "3px", background: "rgba(1,44,217,0.62)"}}/>*/}
                <div style={{paddingLeft: "3px"}}>
                    <ColorButton isShow={isShow} setIsShow={setIsShow} state={props.value} setState={props.setValue}/>
                </div>
            </div>
        </div>
    )
}

interface ColorButtonProps {
    state: {
        r: number,
        g: number,
        b: number,
    },
    setState: (c: number[]) => void,
    isShow: boolean,
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>,
}

function ColorButton({state, setState, isShow, setIsShow}: ColorButtonProps) {

    const styles = {
        'default': {
            color: {
                background: `rgb(${state.r}, ${state.g}, ${state.b})`,
            },
            swatch: {
                padding: '6px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
        },
    }

    const handleClick = () => {
        setIsShow(!isShow)
    };

    const handleClose = () => {
        setIsShow(false)
    };

    const handleChange = (color: ColorResult) => {
        setState([color.rgb.r, color.rgb.g, color.rgb.b])
    };


    return (
        <div>
            <div className="button -gray" style={{height: "40px"}} onClick={handleClick}>
                <div style={{...styles.default.swatch, ...styles.default.color}} onClick={handleClick}></div>
            </div>
            {isShow ?
                <div style={{
                    position: 'absolute',
                    zIndex: '2',
                }}>
                    <div style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    }} onClick={handleClose}/>
                    <SketchPicker color={state} onChange={handleChange} disableAlpha={true}/>
                </div> : null}

        </div>
    )
}

function componentToHex(c: number) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}