import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Select} from 'antd';
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {SettingsTab} from "../../components/tabs/settingsTab";
import {Checkbox} from "../../components/input/checkbox";
import {useNotification} from "../../hooks/settings/notification";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../../components/loader/formWithLoader";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {ReactComponent as TurnOnSVG} from '../../resourse/img/general/ic-turn-on.svg';
import {ReactComponent as TurnOffSVG} from '../../resourse/img/general/ic-turn-off.svg';
import {addNotificationWithButton} from "../../utils/toast/toast";
import {Button} from "../../migration/shared/ui/Button";
import {RouteNames} from "../../migration/pages";


const BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;
const BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME;

export function SettingsNotification() {
    const navigate = useNavigate();
    const info = useNotification();
    const {i18n} = useGlobalContext();
    const {currentMenuId} = useUserContext();

    const {
        isOnEmailNotification,
        emailReceivers,
        isLoadingGetNotificationsSettings,
        isLoadingToggleEmailNotification,
        isLoadingSaveEmailReceivers
    } = useTypedSelector(state => state.notification);
    const {fetchNotificationsSettings, toggleEmailNotification, saveEmailReceivers} = useActions();

    const [isEmailNotificationOn, setIsEmailNotificationOn] = useState<boolean>(isOnEmailNotification || false);
    const [emailNotificationReceivers, setEmailNotificationReceivers] = useState<string[]>(emailReceivers || []);

    const isChanged = () => {
        let isReceiversChanged = false;

        if (emailNotificationReceivers.length !== emailReceivers?.length) {
            isReceiversChanged = true;
        }

        if (JSON.stringify(emailReceivers.sort()) !== JSON.stringify(emailNotificationReceivers.sort())) {
            isReceiversChanged = true;
        }

        return isReceiversChanged;
    }

    const handleToggleEmailNotification = (isChecked: boolean) => {
        setIsEmailNotificationOn(isChecked);
        toggleEmailNotification({
            menuId: currentMenuId,
            toggle: isChecked
        }, {menuId: currentMenuId}, {navigate: navigate});
    };

    const handleSaveEmailReceivers = () => {
        saveEmailReceivers({
            menuId: currentMenuId,
            emails: emailNotificationReceivers
        }, {menuId: currentMenuId}, {navigate: navigate});
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchNotificationsSettings({menuId: currentMenuId}, controller, {navigate: navigate});
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    useEffect(() => {
        setIsEmailNotificationOn(isOnEmailNotification || false);
        setEmailNotificationReceivers(emailReceivers || []);
    }, [isOnEmailNotification, emailReceivers]);

    useEffect(() => {
        document.title = i18n.notification_settings()
    }, [i18n]);

    return (
        <section className={"app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.settings(), link: RouteNames.SETTINGS, index: "brcr-0"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.settings()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <SettingsTab/>
                        <div className="tab-content">
                            <div className="default-card">
                                <FormWithLoader isloading={info.loading} className="form">
                                    <Checkbox checked={info.notification?.telegram.isOn || false}
                                              onChange={info.handleTelegramCheck}
                                              label={"Telegram"}/>
                                    <div className={info.notification?.telegram.isOn ? "some" : "some disabled-segment"}
                                         style={{marginLeft: "20px"}}>
                                        <div className="default-table my-4">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th style={{width: "33%"}}>{i18n.users()}</th>
                                                    <th style={{width: "33%"}}>{i18n.groups()}</th>
                                                    <th style={{width: "33%"}}>{i18n.disabled_users_groups()}</th>
                                                </tr>
                                                </thead>

                                                <TBodyWithLoader isLoading={info.telegramLoading} columns={3} rows={3}>
                                                    {info.maxLength != 0 && Array.from(Array(info.maxLength).keys()).map((i) => {
                                                            if (!info.notification?.telegram.settings) {
                                                                return null
                                                            }
                                                            let tsub = info.notification?.telegram.settings.subscribers
                                                            let tgroup = info.notification?.telegram.settings.groupSubscribers
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {tsub && tsub[i] ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "space-between"
                                                                            }}>
                                                                                {tsub[i].userName}
                                                                                <TurnOffSVG onClick={() => {
                                                                                    addNotificationWithButton({
                                                                                        message: i18n.q_disabled_subscribers().replaceAll("%v", tsub ? tsub[i].userName : ""),
                                                                                        onSubmit: () => {
                                                                                            info.disabledSubscribers(tsub ? tsub[i].userId : 0)
                                                                                        },
                                                                                        i18n,
                                                                                        loading: false
                                                                                    })
                                                                                }}
                                                                                />
                                                                            </div> : ""}
                                                                    </td>
                                                                    <td>
                                                                        {tgroup && tgroup[i] ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "space-between"
                                                                            }}>
                                                                                {tgroup[i].groupTitle}
                                                                                <TurnOffSVG onClick={() => {
                                                                                    addNotificationWithButton({
                                                                                        message: i18n.q_disabled_subscribers().replaceAll("%v", tgroup ? tgroup[i].groupTitle : ""),
                                                                                        onSubmit: () => {
                                                                                            info.disabledSubscribers(tgroup ? tgroup[i].groupId : 0)
                                                                                        },
                                                                                        i18n,
                                                                                        loading: false
                                                                                    })
                                                                                }}/>
                                                                            </div> : ""}
                                                                    </td>
                                                                    <td>
                                                                        {info.disabledSubscribersList[i] ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "space-between"
                                                                            }}>
                                                                                {info.disabledSubscribersList[i].name}
                                                                                <TurnOnSVG onClick={() => {
                                                                                    addNotificationWithButton({
                                                                                        message: i18n.q_enable_subscriber().replaceAll("%v", info.disabledSubscribersList[i].name),
                                                                                        onSubmit: () => {
                                                                                            info.enableSubscribers(info.disabledSubscribersList ? info.disabledSubscribersList[i].id : 0)
                                                                                        },
                                                                                        i18n,
                                                                                        loading: false
                                                                                    })
                                                                                }}
                                                                                />
                                                                            </div> : ""}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}

                                                </TBodyWithLoader>

                                            </table>
                                            <div className="alert alert-info py-4" dangerouslySetInnerHTML={
                                                {
                                                    __html: i18n.subscriber_info_old().replaceAll("/s %v",
                                                        "<span color='blue' style='font-weight: bold;cursor: pointer;' onclick='navigator.clipboard.writeText(\"/s " + info.menu.number + "\")'>/s " +
                                                        info.menu.number
                                                        + "</span>").replaceAll("%l", "<a href='" + BOT_URL + "' target='_blank'>" + BOT_NAME + "</a>")
                                                }
                                            }>

                                            </div>
                                        </div>


                                    </div>
                                </FormWithLoader>

                                <FormWithLoader
                                    isloading={isLoadingGetNotificationsSettings || isLoadingToggleEmailNotification || isLoadingSaveEmailReceivers}
                                    className="form"
                                >
                                    <Checkbox
                                        checked={isEmailNotificationOn}
                                        onChange={(v: boolean) => handleToggleEmailNotification(v)}
                                        label={i18n.email()}
                                    />
                                    <div
                                        className={isEmailNotificationOn ? "some" : "some disabled-segment"}
                                        style={{marginLeft: "20px"}}
                                    >
                                        <Select
                                            mode="tags"
                                            style={{width: '100%'}}
                                            placeholder={i18n.enter_email()}
                                            value={emailNotificationReceivers}
                                            onChange={(value) => setEmailNotificationReceivers(value)}
                                            dropdownStyle={{display: "none"}}
                                            suffixIcon={null}
                                            tokenSeparators={[',', ';']}
                                        />

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                gap: "10px",
                                                marginTop: "10px"
                                            }}
                                        >
                                            <Button
                                                text={i18n.cancel()}
                                                loading={isLoadingSaveEmailReceivers}
                                                onClick={() => setEmailNotificationReceivers(emailReceivers)}
                                                isDelete={true}
                                                disabled={!isChanged()}
                                            />

                                            <Button
                                                text={i18n.save()}
                                                loading={isLoadingSaveEmailReceivers}
                                                onClick={() => handleSaveEmailReceivers()}
                                                disabled={!isChanged()}
                                            />
                                        </div>
                                    </div>
                                </FormWithLoader>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
