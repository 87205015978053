import React, {useEffect} from "react";
import {ICurrentMenuInfo} from "../../models/menu/menu";
import {ICustomPage} from "../../models/customPage/customPage";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {TextI18n} from "../input/textI18n";
import {I18nString} from "../../models/lang/i18nString";
import {InputText} from "../input/inputText";
import {HtmlEditor} from "../input/htmlEditor";
import {CustomSelect} from "../input/customSelect";
import {ArticleEditor} from "../../migration/widgets/ckeditor";


interface ModalCustomPage {
    setModalInfo: React.Dispatch<React.SetStateAction<ICustomPage | null>>
    modalInfo: ICustomPage | null
    menu: ICurrentMenuInfo
    create: () => void,
    update: () => void
    createUpdateCustomPageLoading: boolean
}

export function ModalCustomPage({modalInfo, setModalInfo, menu, create, update, createUpdateCustomPageLoading}: ModalCustomPage) {
    const {i18n} = useGlobalContext()
    const {user} = useUserContext()
    useEffect(() => {
        if (modalInfo) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [modalInfo])

    function handleChangeTitle(e: I18nString) {
        setModalInfo((pre) => {
            if (pre) {
                return {
                    ...pre,
                    title: e
                }
            }
            return pre
        })
    }

    function handleChangeText(e: I18nString) {
        setModalInfo((pre) => {
            if (pre) {
                return {
                    ...pre,
                    text: e
                }
            }
            return pre
        })
    }

    function handleChangeUrl(e: string) {
        setModalInfo((pre) => {
            if (pre) {
                return {
                    ...pre,
                    url: e
                }
            }
            return pre
        })
    }

    function handleChangePosition(e: string) {
        setModalInfo((pre) => {
            if (pre) {
                return {
                    ...pre,
                    position: parseInt(e)
                }
            }
            return pre
        })
    }

    function addImage(file: string) {
        setModalInfo((pre) => {
            if (pre) {
                return {
                    ...pre,
                    images: [...(pre.images || []), file]
                }
            }
            return pre
        })
    }

    return (
        <>
            <div className={modalInfo ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 id="modal-preview" style={{display: modalInfo ? "block" : "none"}}
                 aria-hidden={!!modalInfo}>
                {modalInfo ?
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body px-3 py-4 p-lg-5">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                        onClick={() => setModalInfo(null)}
                                ></button>
                                <div id="item-preview-content">


                                    <TextI18n values={modalInfo.title} label={i18n.title()} isStaff={!!user?.isStaff}
                                              setValues={handleChangeTitle} isRequired={true}
                                              lang={menu.i18n.defaultLang} allLang={menu.i18n.otherLangs || []}
                                    />

                                    <HtmlEditor
                                        label={i18n.page()}
                                        lang={menu.i18n.defaultLang}
                                        allLangs={menu.i18n.otherLangs || []}
                                        text={modalInfo.text}
                                        onChangeText={handleChangeText}
                                        isRequired={true}
                                        addImage={addImage}
                                    />

                                    <InputText value={modalInfo.url || ""} label={"URL"} onChange={handleChangeUrl}
                                               isValidate={true} isRequired={true}
                                    />

                                    <CustomSelect options={[i18n.position_in_bottom(), i18n.position_in_main_page()]}
                                                  value={modalInfo.position} onChange={handleChangePosition}
                                                  label={i18n.position()}/>

                                    <div className="form__buttons">
                                        <div className="form__buttons-left">
                                            <SubmitButton onSubmit={() => {
                                                if (modalInfo.id) {
                                                    update()
                                                } else {
                                                    create()
                                                }
                                            }} disabled={false} label={i18n.save()} isLoading={createUpdateCustomPageLoading}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
            {modalInfo ? <div className="modal-backdrop show"></div> : null}
        </>
    )
}
