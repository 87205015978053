import {FunctionComponent, useEffect, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {RouteNames, userRoutes} from "migration/pages";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Tab, Tabs} from "migration/shared/ui/Tabs";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Breadcrumbs, BreadcrumbsData} from "migration/shared/ui/Breadcrumbs";
import {useUserContext} from "../../../../../hooks/globalContext";
import classes from "./Iiko.module.scss";

export const Iiko: FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {iikoActiveMenu, currentMenuId} = useUserContext();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {iikoMenuSettings} = useTypedSelector(state => state.iiko);
    const {
        isLoadingConfigWebhookSettings,
        isLoadingGetWebhookSettings,
        isLoadingGetIikoApiKey,
        isLoadingGetIikoOrganizationSettings,
        isLoadingGetIikoMenuSettings,
        isLoadingGetIikoOrganizations,
        isLoadingGetIikoTerminalGroups,
        isLoadingGetIikoPaymentTypes,
        isLoadingSaveOrganizationIikoSettings,
        isLoadingDeleteOrganizationIikoSettings,
        isLoadingSaveMenuIikoSettings,
        isLoadingDeleteMenuIikoSettings,
    } = useTypedSelector(state => state.iiko);

    const breadcrumbs: BreadcrumbsData[] = useMemo(() => {
        switch (location.pathname) {
            case RouteNames.IIKO_ORGANIZATION_SETTINGS:
                return [{title: txt.iiko_menu_settings[currentLang], link: RouteNames.IIKO_ORGANIZATION_SETTINGS}];
            case RouteNames.IIKO_MENU_SETTINGS:
                return [{title: txt.iiko_org_settings[currentLang], link: RouteNames.IIKO_MENU_SETTINGS}];
            case RouteNames.IIKO_HEALTHCHECK:
                return [{title: txt.iiko_healthcheck[currentLang], link: RouteNames.IIKO_HEALTHCHECK}];
            case RouteNames.IIKO_WEBHOOKS:
                return [{title: txt.settings_webhooks[currentLang], link: RouteNames.IIKO_WEBHOOKS}];
            default:
                return [{title: txt.iiko[currentLang], link: RouteNames.IIKO}];
        }
    }, [location.pathname, currentLang]);

    const tabs: Tab[] = userRoutes.find(route => route.path === RouteNames.IIKO)?.children?.filter(route => {
        if (route.path === RouteNames.IIKO_WEBHOOKS && (isEmpty(iikoMenuSettings) || !iikoActiveMenu)) {
            return false;
        }

        if (route.path === RouteNames.IIKO_HEALTHCHECK && (isEmpty(iikoMenuSettings) || !iikoActiveMenu)) {
            return false;
        }
        return true;
    })?.map(route => {
        return {
            title: route.name[currentLang],
            link: route.path,
        };
    }) || [];

    useEffect(() => {
        if (location.pathname === RouteNames.IIKO) {
            navigate(RouteNames.IIKO_ORGANIZATION_SETTINGS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, iikoActiveMenu]);

    useEffect(() => {
        navigate(RouteNames.IIKO_ORGANIZATION_SETTINGS);
    }, [currentMenuId]);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <div className={classes.container__header}>
                    <Breadcrumbs breadcrumbs={breadcrumbs}/>
                    <div className={classes.container__header__title}>
                        {breadcrumbs?.[breadcrumbs.length - 1]?.title}
                    </div>
                </div>

                <Tabs
                    tabs={tabs}
                    loading={
                        isLoadingConfigWebhookSettings ||
                        isLoadingGetWebhookSettings ||
                        isLoadingGetIikoApiKey ||
                        isLoadingGetIikoOrganizationSettings ||
                        isLoadingGetIikoMenuSettings ||
                        isLoadingGetIikoOrganizations ||
                        isLoadingGetIikoTerminalGroups ||
                        isLoadingGetIikoPaymentTypes ||
                        isLoadingSaveOrganizationIikoSettings ||
                        isLoadingDeleteOrganizationIikoSettings ||
                        isLoadingSaveMenuIikoSettings ||
                        isLoadingDeleteMenuIikoSettings
                    }
                    hideLoading={location.pathname === RouteNames.IIKO_HEALTHCHECK}
                />
            </div>
        </div>
    )
}
