import {TenantCrossSelling} from "migration/entities/tenant";
import {CrossSellingAction, CrossSellingActionEnum, CrossSellingState} from "./types";

const initialState: CrossSellingState = {
    crossSellingMapping: {
        categories: {},
        products: {},
        commonProductIds: []
    } as TenantCrossSelling,
    isLoadingGetTenant: true,
}

export default function crossSellingReducer(state = initialState, action: CrossSellingAction): CrossSellingState {
    switch (action.type) {
        case CrossSellingActionEnum.SET_TENANT:
            return {...state, crossSellingMapping: action.payload};
        case CrossSellingActionEnum.SET_IS_LOADING_GET_TENANT:
            return {...state, isLoadingGetTenant: action.payload}
        default:
            return state;
    }
}
