export interface NotificationsState {
    isOnEmailNotification: boolean;
    emailReceivers: string[];
    isLoadingGetNotificationsSettings: boolean;
    isLoadingToggleEmailNotification: boolean;
    isLoadingSaveEmailReceivers: boolean;
}

export enum NotificationsActionEnum {
    SET_IS_ON_EMAIL_NOTIFICATION = "SET_IS_ON_EMAIL_NOTIFICATION",
    SET_EMAIL_RECEIVERS = "SET_EMAIL_RECEIVERS",
    SET_IS_LOADING_GET_NOTIFICATIONS_SETTINGS = "SET_IS_LOADING_GET_NOTIFICATIONS_SETTINGS",
    SET_IS_LOADING_TOGGLE_EMAIL_NOTIFICATION = "SET_IS_LOADING_TOGGLE_EMAIL_NOTIFICATION",
    SET_IS_LOADING_SAVE_EMAIL_RECEIVERS = "SET_IS_LOADING_SAVE_EMAIL_RECEIVERS",
}

export interface SetIsOnEmailNotificationAction {
    type: NotificationsActionEnum.SET_IS_ON_EMAIL_NOTIFICATION;
    payload: boolean;
}

export interface SetEmailReceiversAction {
    type: NotificationsActionEnum.SET_EMAIL_RECEIVERS;
    payload: string[];
}

export interface SetIsLoadingGetNotificationsSettingsAction {
    type: NotificationsActionEnum.SET_IS_LOADING_GET_NOTIFICATIONS_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingToggleEmailNotificationAction {
    type: NotificationsActionEnum.SET_IS_LOADING_TOGGLE_EMAIL_NOTIFICATION;
    payload: boolean;
}

export interface SetIsLoadingSaveEmailReceiversAction {
    type: NotificationsActionEnum.SET_IS_LOADING_SAVE_EMAIL_RECEIVERS;
    payload: boolean;
}

export type NotificationsAction =
    SetIsOnEmailNotificationAction |
    SetEmailReceiversAction |
    SetIsLoadingGetNotificationsSettingsAction |
    SetIsLoadingToggleEmailNotificationAction |
    SetIsLoadingSaveEmailReceiversAction;
