import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from 'migration/app';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from "./migration/app/store";

const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>

            <div style={{
                zIndex: 10000, height: 4, background: "red", position: "fixed", width: "100%",
                display: (process.env.REACT_APP_API_URL || "").includes("kamiqr") ? "none" : "block"
            }}></div>
            <App/>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
