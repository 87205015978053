import React from "react";
import {BlockLoader} from "./loader";

interface ISearchProps extends React.FormHTMLAttributes<HTMLFormElement> {
    isloading: boolean
}

export function FormWithLoader(props: ISearchProps): JSX.Element {
    return (
        <form {...props} className={`${props.className} ${props.isloading?"hidden-w-opacity":""}`}>
            <BlockLoader style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: 100,
                display: props.isloading ? "flex" : "none"
            }}/>
            {props.children}
        </form>
    )
}