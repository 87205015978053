import React from "react";
import {useUserContext} from "../../hooks/globalContext";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {ICategoryForSort, IItemForSort, IMenuForSort} from "../../models/menu/menuTree";
import {CURRENCY} from "../../utils/options/options";


// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

interface IProps {
    state: { menu: IMenuForSort[] }
    setState: React.Dispatch<React.SetStateAction<{ menu: IMenuForSort[] }>>
    show: { [key: string]: boolean }
    setShow: React.Dispatch<React.SetStateAction<{ [p: string]: boolean }>>
}

export function MenuDnd({state, setState, show, setShow}: IProps) {
    const {menu} = useUserContext()
    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        if (result.type === "droppableSection") {
            const menu = reorder(state.menu, sourceIndex, destIndex);

            setState({
                menu
            });
        } else if (result.type === "droppableCategory") {
            const categories = state.menu.reduce((acc: {
                [key: string]: { id: string, category: ICategoryForSort, items: { id: string, item: IItemForSort }[] }[]
            }, item) => {
                acc[item.id] = item.categories;
                return acc;
            }, {});

            const sourceParentId = result.source.droppableId
            const destParentId = result.destination.droppableId

            const sourceSubItems = categories[sourceParentId];
            const destSubItems = categories[destParentId];

            let newMenu = [...state.menu];

            /** In this case subItems are reOrdered inside same Parent */
            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);
                newMenu = newMenu.map(item => {
                    if (item.id === sourceParentId) {
                        item.categories = reorderedSubItems;
                    }
                    return item;
                });
                setState({
                    menu: newMenu
                });
            } else {
                let newSourceCategories = [...sourceSubItems];
                const [draggedItem] = newSourceCategories.splice(sourceIndex, 1);

                let newDestCategories = [...destSubItems];
                newDestCategories.splice(destIndex, 0, draggedItem);
                newMenu = newMenu.map(item => {
                    if (item.id === sourceParentId) {
                        item.categories = newSourceCategories;
                    } else if (item.id === destParentId) {
                        item.categories = newDestCategories;
                    }
                    return item;
                });
                setState({
                    menu: newMenu
                });
            }
        } else if (result.type === "droppableItem") {
            const sourceParentId = result.source.droppableId
            const destParentId = result.destination.droppableId

            let newSections = [...state.menu];

            /** In this case subItems are reOrdered inside same Parent */
            if (sourceParentId === destParentId) {
                let swapped = false
                for (let isec = 0; isec < newSections.length; isec++) {
                    for (let icat = 0; icat < newSections[isec].categories.length; icat++) {
                        if (newSections[isec].categories[icat].id == sourceParentId) {
                            newSections[isec].categories[icat].items = reorder(newSections[isec].categories[icat].items, sourceIndex, destIndex)
                            swapped = true
                            break
                        }
                    }
                    if (swapped) {
                        break
                    }
                }
                setState({
                    menu: newSections
                })
            } else {
                let sourceSecInd = -1
                let destSecInd = -1
                let sourceCatInd = -1
                let destCatInd = -1
                for (let isec = 0; isec < newSections.length; isec++) {
                    for (let icat = 0; icat < newSections[isec].categories.length; icat++) {
                        if (newSections[isec].categories[icat].id == sourceParentId) {
                            sourceSecInd = isec
                            sourceCatInd = icat
                        }
                        if (newSections[isec].categories[icat].id == destParentId) {
                            destSecInd = isec
                            destCatInd = icat
                        }
                    }
                }
                const [draggedItem] = newSections[sourceSecInd].categories[sourceCatInd].items.splice(sourceIndex, 1)
                newSections[destSecInd].categories[destCatInd].items.splice(destIndex, 0, draggedItem)

                setState({
                    menu: newSections
                })
            }
        }
    }

    return (
        <DragDropContext onDragEnd={(result, provided) => {
            onDragEnd(result, provided)
        }}>
            <Droppable droppableId="droppable" type="droppableSection">
                {(provided, snapshot) => (
                    <ul id="catalog" className="catalog" ref={provided.innerRef}>
                        {state.menu.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <li ref={provided.innerRef} className="catalog__item"
                                        data-id="884cdc5f-15a4-4ef8-b523-bcdfba31b045"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className="catalog__title">
                                            <div className="catalog__draggable-area"></div>
                                            <a href="#" className="catalog__text" aria-expanded={Boolean(show[item.id])}
                                               onClick={(e) => {
                                                   e.preventDefault()
                                                   setShow({...show, [item.id]: !Boolean(show[item.id])})
                                               }}
                                            >
                                                {item.section.name[menu.i18n.defaultLang]}
                                                <span></span>
                                            </a>
                                        </div>

                                        <CategoryUnit
                                            id={item.id}
                                            show={show}
                                            setShow={setShow}
                                            subItems={item.categories}
                                            type={item.id.toString()}
                                        />
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>
        </DragDropContext>

    );
}

const grid = 1;

const getListStyle1 = (isDraggingOver: any) => ({
    padding: grid,
});

function CategoryUnit({type, subItems, show, setShow, id}: {
    id: string,
    show: { [p: string]: boolean },
    setShow: React.Dispatch<React.SetStateAction<{ [p: string]: boolean }>>,
    type: string
    subItems: {
        id: string,
        category: ICategoryForSort
        items: {
            id: string,
            item: IItemForSort
        }[]
    }[]
}) {
    const {menu} = useUserContext()

    return (
        <Droppable droppableId={type} type={`droppableCategory`}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                     className={Boolean(show[id]) ? "scts-catalog collapse show" : "scts-catalog collapse"}
                     style={getListStyle1(snapshot.isDraggingOver)}>
                    {subItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided, snapshot) => (
                                <div className="catalog__body"
                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className="catalog__title">
                                        <div className="catalog__draggable-area"></div>
                                        <a href="#" className="catalog__text" aria-expanded={Boolean(show[item.id])}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               setShow({
                                                   ...show,
                                                   [item.id]: !Boolean(show[item.id])
                                               })
                                           }}
                                        >
                                            {item.category.name[menu.i18n.defaultLang]}

                                            <span></span>
                                        </a>
                                        <button className="button"
                                                onClick={() => {
                                                }}
                                                style={{
                                                    borderLeftWidth: "0px",
                                                    background: "#F9F9F9",
                                                    borderRadius: "0px 7px 7px 0px",
                                                    display: show[item.id] && item.items.length != 0 ? "block" : "none"
                                                }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                 style={{fill: "gray"}}>
                                                <path stroke="null" id="svg_1"
                                                      d="m3.33342,14.50482c-0.07562,0.11342 -0.19775,0.18706 -0.33553,0.18706c-0.13778,0 -0.25992,-0.07364 -0.33553,-0.18706l-2.45202,-2.69332c-0.16231,-0.17828 -0.16231,-0.46732 0,-0.6456c0.1623,-0.17829 0.42545,-0.17829 0.58776,0l1.78418,1.95974l0,-12.59318c0,-0.25212 0.18608,-0.45651 0.41561,-0.45651c0.22953,0 0.41561,0.20439 0.41561,0.45651l0,12.59318l1.78418,-1.95974c0.1623,-0.17829 0.42545,-0.17829 0.58776,0c0.16231,0.17828 0.16231,0.46732 0,0.6456l-2.45202,2.69332l0,0zm4.65181,-12.60283c-0.22953,0 -0.41561,-0.20439 -0.41561,-0.45651c0,-0.25212 0.18608,-0.45651 0.41561,-0.45651l6.64979,0c0.22953,0 0.41561,0.20439 0.41561,0.45651c0,0.25212 -0.18608,0.45651 -0.41561,0.45651l-6.64979,0zm0,3.65208c-0.22953,0 -0.41561,-0.20439 -0.41561,-0.45651c0,-0.25212 0.18608,-0.45651 0.41561,-0.45651l4.98734,0c0.22953,0 0.41561,0.20439 0.41561,0.45651c0,0.25212 -0.18608,0.45651 -0.41561,0.45651l-4.98734,0zm0,3.65208c-0.22953,0 -0.41561,-0.20439 -0.41561,-0.45651c0,-0.25212 0.18608,-0.45651 0.41561,-0.45651l3.32489,0c0.22953,0 0.41561,0.20439 0.41561,0.45651c0,0.25212 -0.18608,0.45651 -0.41561,0.45651l-3.32489,0zm0,3.65208c-0.22953,0 -0.41561,-0.20439 -0.41561,-0.45651c0,-0.25212 0.18608,-0.45651 0.41561,-0.45651l1.66245,0c0.22953,0 0.41561,0.20439 0.41561,0.45651c0,0.25212 -0.18608,0.45651 -0.41561,0.45651l-1.66245,0z"></path>
                                            </svg>
                                        </button>
                                    </div>

                                    <ItemUnit
                                        id={item.id}
                                        show={show}
                                        subItems={item.items}
                                        type={item.id.toString()}
                                    />

                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}

        </Droppable>

    )
}

function ItemUnit({type, subItems, show, id}: {
    id: string,
    show: { [p: string]: boolean },
    type: string
    subItems: {
        id: string,
        item: IItemForSort
    }[]
}) {
    const {menu} = useUserContext()

    return (
        <Droppable droppableId={type} type={`droppableItem`}>
            {(provided, snapshot) => (
                <div className={Boolean(show[id]) ? "cats-catalog collapse show" : "cats-catalog collapse"}
                     ref={provided.innerRef} aria-expanded="true"
                     style={getListStyle1(snapshot.isDraggingOver)}
                >
                    {subItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided, snapshot) => (

                                <div className="catalog__body"
                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                     data-price="690">
                                    <div className="catalog__title">
                                        <div className="catalog__draggable-area"></div>
                                        <div className="catalog__text">

                                            <div className="catalog__text">
                                                {item.item.name[menu.i18n.defaultLang]}
                                            </div>


                                            <span className="price-in-menu-row">
                                          {item.item.price / 100} {CURRENCY[menu.i18n.currency]}
                                        </span>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>

    )
}
