import React, {useEffect, useMemo} from "react";
import {CropperModal} from "../modal/cropperModal";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useItemForm} from "../../hooks/item/item";
import {SubmitButton} from "../input/submitButton";
import {TextI18n} from "../input/textI18n";
import {TextAreaI18n} from "../input/textAreaI18n";
import {PriceInput} from "../input/priceInput";
import {NumberInput} from "../input/numberInput";
import {TagsI18n} from "../input/tagsI18n";
import {useItemIiko} from "../../hooks/item/itemIIKO";
import {ModalIIkoItem} from "../modal/modalIIkoItem";
import {CustomCategorySelect} from "../input/customCategorySelect";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {IItemOtherPrice, IModifierExtra, IModSelect} from "../../models/item/IItem";
import {I18nString} from "../../models/lang/i18nString";
import {FormWithLoader} from "../loader/formWithLoader";
import {ImageUploadComponent} from "../input/imageUploadComponent";
import {LittleSpinner} from "../loader/littleSpinner";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {isEmpty} from "lodash";
import {RouteNames} from "migration/pages";
import {CocaColaSettings} from "migration/pages/user/menu/cola/components/CocaColaSettings";
import {TaggingSettings} from "migration/pages/staff/organizations/components/TaggingSettings";
import {HtmlEditor} from "../input/htmlEditor";
import {IikoNomenclatureProduct} from "../../migration/entities/iikoNomenclature/iikoNomenclatureProduct";
import {IikoExternalMenuItem} from "../../migration/entities/iikoExternalMenu";

export function ItemForm({isEdit}: { isEdit: boolean }) {
    const navigate = useNavigate();
    const {id} = useParams();
    const info = useItemForm(isEdit)
    const iiko = useItemIiko()
    const {i18n} = useGlobalContext()
    const {currentMenuId, user, iikoActiveMenu} = useUserContext();
    const {
        iikoMenuSettings,
        iikoNomenclature,
        iikoCompanyId,
        isLoadingSaveIikoProductBindings,
        iikoExternalMenuItems,
    } = useTypedSelector(state => state.iiko);
    const {
        rkeeperMenuSettings,
        rkeeperMenu,
    } = useTypedSelector(state => state.rkeeper);
    const {
        fetchIikoMenuSettings,
        fetchIikoNomenclature,
        saveIikoProductBindings,
        fetchIikoOrganizationSettings,
        getRkeeperMenu,
        getMenuRkeeperSettings,
        fetchIikoExternalMenu
    } = useActions();

    const options = Object.keys(info.menuStr).map((key) => {
        return {
            id: key, name: info.menuStr[key].section.name[info.menu.i18n.defaultLang],
            values: (info.menuStr[key].categories || []).map((cat) => {
                return {id: cat.id, name: cat.name[info.menu.i18n.defaultLang]}
            })
        }
    })

    const [saveCombinationClicked, setSaveCombinationClicked] = React.useState<boolean>(false)

    function keyDownHandler(e: KeyboardEvent) {
        if (e.key === 'Enter' && e.ctrlKey) {
            e.preventDefault()
            if (saveCombinationClicked) return
            setSaveCombinationClicked(true)
            info.saveItem()
        }
    }

    const iikoProduct: IikoExternalMenuItem | IikoNomenclatureProduct | null = useMemo(() => {
        if (iikoMenuSettings.iikoExternalMenuId ) {
            if (!id || isEmpty(iikoExternalMenuItems)) return null;

            return iikoExternalMenuItems?.[iikoMenuSettings?.productBindings?.[id]]
        } else {
            if (!id || isEmpty(iikoNomenclature) || isEmpty(iikoMenuSettings)) return null;

            return iikoNomenclature?.products?.[iikoMenuSettings?.productBindings?.[id]];
        }
    }, [id, iikoMenuSettings, iikoNomenclature]);

    const rkeeperProduct = useMemo(() => {
        if (!id || isEmpty(rkeeperMenu) || isEmpty(rkeeperMenuSettings)) return null;

        return rkeeperMenu.products.find(product => product.id === rkeeperMenuSettings.productBindings[id]);
    }, [id, rkeeperMenuSettings, rkeeperMenu]);

    const onBindIikoProduct = async (iikoProductId: string) => {
        const iikoProductBindings = iikoMenuSettings?.productBindings || {};
        if (id) {
            iikoProductBindings[id] = iikoProductId;
        }

        saveIikoProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": {
                    ...iikoProductBindings,
                },
            }, {navigate: navigate}, true
        )
    }

    const onUnbindIikoProduct = async () => {
        const iikoProductBindings = iikoMenuSettings?.productBindings || {};
        if (id && iikoProductBindings[id]) {
            delete iikoProductBindings[id];
        }

        saveIikoProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": {
                    ...iikoProductBindings,
                },
            }, {navigate: navigate}, false
        )
    }

    useEffect(() => {
        if (iikoActiveMenu) {
            const controller = new AbortController();
            fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (iikoActiveMenu) {
            const controller = new AbortController();
            fetchIikoOrganizationSettings({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!iikoMenuSettings.iikoExternalMenuId ) return;
        if (!!iikoMenuSettings?.iikoOrganizationId) {
            const controller = new AbortController();
            fetchIikoNomenclature({
                organizationId: user?.org?.id || "",
                iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId
            }, controller, {navigate: navigate});
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings])

    useEffect(() => {
        if (!!iikoMenuSettings.iikoExternalMenuId ) {
            const controller = new AbortController();
            fetchIikoExternalMenu({
                iikoExternalMenuId: iikoMenuSettings.iikoExternalMenuId,
                iikoOrganizationId: iikoMenuSettings.iikoOrganizationId,
                iikoPriceCategoryId: iikoMenuSettings.iikoPriceCategoryId || null,
                organizationId: user?.org?.id || "",
            }, controller, {navigate: navigate});
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings])

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler])

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper")) {
            const controller = new AbortController();
            getMenuRkeeperSettings({menuId: currentMenuId}, controller,);
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper")) {
            if (isEmpty(rkeeperMenuSettings)) return;
            const controller = new AbortController();
            getRkeeperMenu({objectId: Number(rkeeperMenuSettings.rkeeperObjectId)}, controller,);
            return () => controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rkeeperMenuSettings]);

    return (
        <>
            <FormWithLoader isloading={info.itemLoading} className="form">

                <ImageUploadComponent img={info.item.mainImg} ImgType={"menuItems"} size={"-big"}
                                      onUploadImage={info.onUploadFile} onDeleteImage={info.onDeleteImg}
                                      imgUploading={info.imageUploading} type={"-section"}
                                      message={i18n.image_upload_hint_std()}/>

                <TextI18n values={info.item.name} setValues={info.onChangeItemName} isStaff={info.isStaff}
                          label={i18n.item_name()}
                          lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                          isRequired={true}/>

                <TextAreaI18n values={info.item.specs.shortDesc} setValues={info.onChangeShortDesc}
                              isStaff={info.isStaff}
                              lang={info.menu.i18n.defaultLang} allLangs={info.menu.i18n.otherLangs || []}
                              label={i18n.short_desc()} small={true}/>

                {!info.itemLoading && (
                    <HtmlEditor
                        text={info.item.specs.fullDesc}
                        onChangeText={info.onChangeDescription}
                        label={i18n.full_desc()}
                        lang={info.menu.i18n.defaultLang}
                        allLangs={info.menu.i18n.otherLangs || []}
                    />
                )}

                <CustomCategorySelect value={info.categoryId} onChange={info.setCategoryId}
                                      label={i18n.category()}
                                      options={options}/>

                <TagsI18n values={info.item.specs.tags} setValues={info.onChangeTags}
                          lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                          placeholder={i18n.enter_tags()} label={i18n.tags()}
                />


                <div className="row" data-gutter="15">
                    <div className="col-md-6">

                        <TextI18n values={info.item.prices.primary.label} setValues={info.onChangeMainPriceLabel}
                                  label={i18n.price_label()} isStaff={info.isStaff}
                                  lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                        />


                    </div>
                    <div className="col-md-3">
                        <PriceInput
                            label={i18n.price()} isRequired={true}
                            disabled={!isEmpty(iikoProduct) || !isEmpty(rkeeperProduct)}
                            value={
                                !isEmpty(rkeeperProduct)
                                    ? (Number(rkeeperProduct.price) || 0) * 100
                                    : isEmpty(iikoProduct)
                                        ? info.item.prices.primary.price
                                        : (!!iikoMenuSettings.iikoExternalMenuId )
                                            ? iikoExternalMenuItems?.[iikoMenuSettings?.productBindings?.[id || ""]]?.itemSizes?.find(size => size?.isDefault)?.prices?.[0]?.price || 0
                                            : ((iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice || 0) * 100
                            }
                            onChange={info.onChangeMainPrice}
                        />
                    </div>

                    <div className="col-11 col-md-3">
                        <PriceInput label={i18n.old_price()}
                                    value={info.item.prices.primary.oldPrice} onChange={info.onChangeMainOldPrice}/>
                    </div>
                </div>
                {isEdit && !isEmpty(iikoProduct) ? <div
                    className="alert alert-info py-4">{i18n.for_editing_price_go_to_iiko()}</div> : null}

                {isEdit && !isEmpty(rkeeperProduct) ? <div
                    className="alert alert-info py-4">{i18n.for_editing_price_go_to_rkeeper()}</div> : null}


                <div id="dynamicPart">
                    {/*Other price*/}
                    {isEmpty(iikoProduct)
                        ?
                        <>
                            {info.item.prices.others ? info.item.prices.others.map((otherPrice, index) => {
                                return (
                                    <OtherPrice index={index} isStaff={info.isStaff}
                                                onDeleteOtherPrice={info.onDeleteOtherPrice}
                                                onChangeOtherPriceLabel={info.onChangeOtherPriceLabel}
                                                onChangeOtherOldPrice={info.onChangeOtherOldPrice}
                                                onChangeOtherPrice={info.onChangeOtherPrice}
                                                key={"price-tuple-" + index}
                                                otherPrice={otherPrice}
                                    />
                                )
                            }) : ""}


                            <a href="#" onClick={(e) => {
                                e.preventDefault()
                                info.onCreateOtherPrice()
                            }}>
                                {i18n.add_addit_price()}
                            </a>

                            <hr/>
                        </>
                        : null
                    }

                    <h3>{i18n.cooking_time()}</h3>
                    {/*cooking time*/}
                    <div className="row" data-gutter="15">
                        <div className="col-md-3">
                            <NumberInput label={i18n.from()} value={info.item.specs.cookingTimeFrom}
                                         onChange={(from) => info.onChangeCookingTime(from, info.item.specs.cookingTimeTo)}
                                         onBlur={(from) => info.onBlurCookingTime(from, info.item.specs.cookingTimeTo)}
                            />
                        </div>
                        <div className="col-11 col-md-3">
                            <NumberInput label={i18n.to()} value={info.item.specs.cookingTimeTo}
                                         onChange={(to) => info.onChangeCookingTime(info.item.specs.cookingTimeFrom, to)}
                                         onBlur={(to) => info.onBlurCookingTime(info.item.specs.cookingTimeFrom, to)}
                            />
                        </div>
                    </div>
                    <h3>{i18n.product_info()}</h3>
                    <div className="row" data-gutter="15">
                        <div className="col-md-3">
                            <NumberInput label={i18n.protein()} value={info.item.specs.proteins}
                                         onChange={(numb) => {
                                             let specs = info.item.specs
                                             info.onChangeProductInfo(numb, specs.fats, specs.carbs, specs.kiloCalories)
                                         }}/>
                        </div>
                        <div className="col-11 col-md-3">
                            <NumberInput label={i18n.fats()} value={info.item.specs.fats}
                                         onChange={(numb) => {
                                             let specs = info.item.specs
                                             info.onChangeProductInfo(specs.proteins, numb, specs.carbs, specs.kiloCalories)
                                         }}/>
                        </div>
                        <div className="col-11 col-md-3">
                            <NumberInput label={i18n.carbohydrates()} value={info.item.specs.carbs}
                                         onChange={(numb) => {
                                             let specs = info.item.specs
                                             info.onChangeProductInfo(specs.proteins, specs.fats, numb, specs.kiloCalories)
                                         }}/>
                        </div>
                        <div className="col-11 col-md-3">
                            <NumberInput label={i18n.calories()} value={info.item.specs.kiloCalories}
                                         onChange={(numb) => {
                                             let specs = info.item.specs
                                             info.onChangeProductInfo(specs.proteins, specs.fats, specs.carbs, numb)
                                         }}/>
                        </div>
                    </div>


                    <h3 className="mt-4">{i18n.mod_selects()}</h3>

                    {/*Modifications Selects*/}
                    {!isEmpty(iikoProduct)
                        ? (
                            <div className="alert alert-info py-4">{i18n.edit_in_iiko()}</div>
                        ) : !isEmpty(rkeeperProduct)
                            ? (
                                <div className="alert alert-info py-4">{i18n.edit_in_rkeeper()}</div>
                            ) : (
                                <>
                                    {info.item.prices.modSelects ? info.item.prices.modSelects.map((modifierSelect, msIndex) => {
                                        return (
                                            <ModificationsSelect index={msIndex} isStaff={info.isStaff}
                                                                 key={"mod-select-" + msIndex}
                                                                 modifierSelect={modifierSelect}
                                                                 onChangeHeaderModifierSelects={info.onChangeHeaderModifierSelects}
                                                                 onChangeIsHiddenModifierSelects={info.onChangeIsHiddenModifierSelects}
                                                                 onChangeIsRequiredModifierSelects={info.onChangeIsRequiredModifierSelects}
                                                                 onDeleteModifierSelects={info.onDeleteModifierSelects}
                                                                 onChangeLabelOptionModifierSelects={info.onChangeLabelOptionModifierSelects}
                                                                 onChangePriceDeltaOptionModifierSelects={info.onChangePriceDeltaOptionModifierSelects}
                                                                 onChangeStatusOptionModifierSelects={info.onChangeStatusOptionModifierSelects}
                                                                 onHideStatusOptionModifierSelects={info.onHideStatusOptionModifierSelects}
                                                                 onDeleteOptionModifierSelects={info.onDeleteOptionModifierSelects}
                                                                 onCreateOptionModifierSelects={info.onCreateOptionModifierSelects}
                                            />

                                        )
                                    }) : null}

                                    <div className="mt-4">
                                        <a href="#"
                                           onClick={(e) => {
                                               e.preventDefault()
                                               info.onCreateModifierSelects()
                                           }}
                                        >{i18n.add_mod_select()}</a>
                                    </div>
                                </>
                            )
                    }

                    <h3 className="mt-4">{i18n.mod_extras()}</h3>

                    {/*Modifications Extras*/}
                    {!isEmpty(iikoProduct)
                        ? (
                            <div className="alert alert-info py-4">{i18n.edit_in_iiko()}</div>
                        ) : !isEmpty(rkeeperProduct)
                            ? (
                                <div className="alert alert-info py-4">{i18n.edit_in_rkeeper()}</div>
                            ) : (
                                <>
                                    {info.item.prices.modExtras && info.item.prices.modExtras.length > 0 ?
                                        <div className="row" data-gutter="15">
                                            <div className="col-md-8">
                                                <div className="mod-extra-panel">
                                                    {info.item.prices.modExtras ? info.item.prices.modExtras.map((modifierExtra, index) => {
                                                        return (
                                                            <ModificationExtra index={index} isStaff={info.isStaff}
                                                                               key={"mod-extra-" + index}
                                                                               modifierExtra={modifierExtra}
                                                                               onChangeLabelModifierExtra={info.onChangeLabelModifierExtra}
                                                                               onChangePriceDeltaModifierExtra={info.onChangePriceDeltaModifierExtra}
                                                                               onHideModifierExtra={info.onHideModifierExtra}
                                                                               onDeleteModifierExtra={info.onDeleteModifierExtra}
                                                            />
                                                        )
                                                    }) : null}
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault()
                                                        info.onCreateModifierExtra()
                                                    }}>
                                                        {i18n.add_mod_extra()}
                                                    </a>
                                                </div>
                                            </div>


                                        </div>
                                        : ""}
                                    {info.item.prices.modExtras && info.item.prices.modExtras.length > 0 ? "" :
                                        <div className="mt-4">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                                info.onCreateModifierExtra()
                                            }}>
                                                {i18n.add_mod_extras()}
                                            </a>
                                        </div>}
                                </>
                            )
                    }

                </div>
                {(iikoActiveMenu && !isEdit) && (
                    <div className="alert alert-info py-4">
                        {i18n.in_iiko_binding_menu_kami_product_will_not_be_prominent()}
                    </div>
                )}

                {(user?.org?.availableModules?.includes("rkeeper") && !isEdit) && (
                    <div className="alert alert-info py-4">
                        {i18n.in_rkeeper_binding_menu_kami_product_will_not_be_prominent()}
                    </div>
                )}

                {(isEdit && !isEmpty(iikoProduct)) && (
                    <div
                        className="alert alert-info py-4">{i18n.for_enabling_and_disabling_go_to_iiko_stop_list()}</div>
                )}

                {(isEdit && !isEmpty(rkeeperProduct)) && (
                    <div className="alert alert-info py-4">{i18n.for_enabling_and_disabling_go_to_rkeeper()}</div>
                )}


                <div className="form__buttons" style={{marginTop: "30px"}}>
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={info.saveItem} label={i18n.save()} isLoading={info.saveLoading}
                                      disabled={!info.currentMenuId}
                        />
                        <Link to={RouteNames.MENUS}
                              className="button -bordered_blue form__button">
                            {i18n.cancel()}
                        </Link>

                    </div>
                </div>

                {isEdit ?
                    <>
                        {info.item.isHidden ?
                            <button className="button form__button -bordered_blue mt-2" type="button"
                                    onClick={info.handleShowItem}
                            >
                                {info.hideShowLoading ? <LittleSpinner/> : i18n.show_in_menu()}
                            </button> :
                            <button className="button form__button -bordered_red mt-2" type="button"
                                    onClick={info.handleHideItem}
                            >
                                {info.hideShowLoading ? <LittleSpinner/> : i18n.hide_in_menu()}
                            </button>
                        }
                        {(isEmpty(iikoProduct) && isEmpty(rkeeperProduct)) ? !info.item.isOff ?
                            <button className="button form__button -bordered_red mt-2 ml-2" type="button"
                                    onClick={info.handleOffItem}
                            >
                                {info.turnOnOffLoading ? <LittleSpinner/> : i18n.turn_off()}
                            </button> :
                            <button className="button form__button -bordered_blue mt-2 ml-2" type="button"
                                    onClick={info.handleOnItem}
                            >
                                {info.turnOnOffLoading ? <LittleSpinner/> : i18n.turn_on()}
                            </button> : null}
                        {(!isEmpty(iikoNomenclature?.products) && !!iikoCompanyId && iikoActiveMenu)
                            ?
                            !isEmpty(iikoProduct) ?
                                <button
                                    className={
                                        isEmpty(iikoProduct)
                                            ? "button form__button -bordered_red mt-2"
                                            : "button form__button -bordered_red mt-2 ml-2"
                                    }
                                    type="button"
                                    style={{display: isEmpty(iikoProduct) ? "block" : "inline"}}
                                    onClick={() => onUnbindIikoProduct()}
                                >
                                    {i18n.unbind_iiko()}</button> :
                                <button
                                    className={
                                        isEmpty(iikoProduct)
                                            ? "button form__button -bordered_blue mt-2"
                                            : "button form__button -bordered_blue mt-2 ml-2"}
                                    type="button"
                                    style={{display: isEmpty(iikoProduct) ? "block" : "inline"}}
                                    onClick={() => {
                                        iiko.setModalOpen(true)
                                    }}
                                >
                                    {i18n.bind_iiko()}
                                </button>
                            :
                            null
                        }
                    </>
                    :
                    null
                }

                <CocaColaSettings type={"product"} productId={info.item?.id}/>
                <TaggingSettings type={"product"} orgId={user?.org?.id || ""} productId={info.item?.id}/>
            </FormWithLoader>
            <CropperModal
                modal={info.modal}
                selectedImage={info.selectedImage}
                setModal={info.setModal}
                saveImage={info.UploadImage}
                setSelectImage={info.setSelectedImage}
                aspectRatio={1}
            />
            <ModalIIkoItem
                iikoNomenclature={iikoNomenclature}
                bindProduct={iikoProduct}
                setNowProduct={iiko.setBindNow}
                nowProduct={iiko.bindNow}
                modalOpen={iiko.modalOpen}
                setModalOpen={iiko.setModalOpen}
                onBindIikoProduct={onBindIikoProduct}
                onUnbindIikoProduct={onUnbindIikoProduct}
                bindLoading={isLoadingSaveIikoProductBindings}
            />
        </>
    )
}

interface IOtherPriceProps {
    otherPrice: IItemOtherPrice
    index: number
    onChangeOtherPriceLabel: (index: number, value: I18nString) => void
    onChangeOtherPrice: (index: number, value: number) => void
    onChangeOtherOldPrice: (index: number, value: number) => void
    onDeleteOtherPrice: (index: number) => void
    isStaff: boolean
}

function OtherPrice(props: IOtherPriceProps) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div className="row add-price-tuple" data-gutter="15">
            <div className="col-md-5">
                <TextI18n values={props.otherPrice.label}
                          setValues={(v) => props.onChangeOtherPriceLabel(props.index, v)}
                          label={i18n.price_label()} isStaff={props.isStaff}
                          lang={menu.i18n.defaultLang}
                          allLang={menu.i18n.otherLangs || []}
                />
            </div>
            <div className="col-md-3">
                <PriceInput label={i18n.price()} isRequired={true}
                            value={props.otherPrice.price}
                            onChange={(v) => props.onChangeOtherPrice(props.index, v)}/>
            </div>
            <div className="col-11 col-md-3">
                <PriceInput label={i18n.old_price()}
                            value={props.otherPrice.oldPrice}
                            onChange={(v) => props.onChangeOtherOldPrice(props.index, v)}/>
            </div>
            <div className="col-1">
                <button type="button" className="form__remove-button"
                        onClick={() => props.onDeleteOtherPrice(props.index)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                            fill="#8392A5"></path>
                        <path
                            d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                            fill="#8392A5"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

interface IModificationSelectProps {
    modifierSelect: IModSelect
    index: number
    isStaff: boolean
    onChangeHeaderModifierSelects: (index: number, value: I18nString) => void
    onChangeIsHiddenModifierSelects: (index: number) => void
    onChangeIsRequiredModifierSelects: (index: number) => void
    onDeleteModifierSelects: (index: number) => void
    onChangeLabelOptionModifierSelects: (index: number, optionIndex: number, value: I18nString) => void
    onChangePriceDeltaOptionModifierSelects: (index: number, optionIndex: number, value: number) => void
    onChangeStatusOptionModifierSelects: (index: number, optionIndex: number) => void
    onHideStatusOptionModifierSelects: (index: number, optionIndex: number) => void
    onDeleteOptionModifierSelects: (index: number, optionIndex: number) => void
    onCreateOptionModifierSelects: (index: number) => void
}

function ModificationsSelect(props: IModificationSelectProps) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div data-gutter="15"
             className={props.modifierSelect.isHidden ? "row mod-select hidden-w-opacity" : "row mod-select "}
        >
            <div className="col-md-4 left-side-panel">
                <TextI18n values={props.modifierSelect.header} isStaff={props.isStaff}
                          setValues={(v) => props.onChangeHeaderModifierSelects(props.index, v)}
                          label={i18n.title()}
                          lang={menu.i18n.defaultLang}
                          allLang={menu.i18n.otherLangs || []}
                />
                <div className="control">
                    <label className="custom-checkbox">
                        <input type="checkbox" checked={props.modifierSelect.isHidden}
                               onChange={() => props.onChangeIsHiddenModifierSelects(props.index)}
                        />
                        <span>{i18n.hide()}</span>
                    </label>
                </div>
                <div className="control">
                    <label className="custom-checkbox">
                        <input type="checkbox" checked={props.modifierSelect.isRequired}
                               onChange={() => props.onChangeIsRequiredModifierSelects(props.index)}
                        />
                        <span>{i18n.required()}</span>
                    </label>
                </div>
                <a href="#" className="text-danger text-lowercase"
                   onClick={(e) => {
                       e.preventDefault()
                       props.onDeleteModifierSelects(props.index)
                   }}
                >
                    {i18n.delete_mod()}
                </a>
            </div>
            <div className="col-md-8">
                <div className="mod-option-panel">
                    {props.modifierSelect.options.map((opt, optIndex) => {
                        return (
                            <div data-gutter="15"
                                 className={opt.status === 'hidden' ? "row mod-item hidden-w-opacity" : "row mod-item"}>
                                <div className="col-md-6">
                                    <TextI18n values={opt.label} isStaff={props.isStaff}
                                              setValues={(v) => props.onChangeLabelOptionModifierSelects(props.index, optIndex, v)}
                                              label={i18n.mod_option_name()}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <PriceInput label={i18n.price_delta()}
                                                value={opt.priceDelta}
                                                onChange={(v) => props.onChangePriceDeltaOptionModifierSelects(props.index, optIndex, v)}/>
                                    <div className="control">
                                        <label className="custom-radio">
                                            <input type="radio" checked={opt.status == "default"}
                                                   disabled={opt.status == 'hidden'}
                                                   onClick={() => props.onChangeStatusOptionModifierSelects(props.index, optIndex)}
                                            />
                                            <span>{i18n.default()}</span>
                                        </label>
                                    </div>
                                    <div className="control">
                                        <label className="custom-checkbox">
                                            <input type="checkbox"
                                                   disabled={opt.status === 'default'}
                                                   checked={opt.status === 'hidden'}
                                                   onClick={() => {
                                                       props.onHideStatusOptionModifierSelects(props.index, optIndex)
                                                   }}
                                            />
                                            <span>{i18n.hide()}</span>
                                        </label>
                                    </div>
                                </div>
                                {opt.status !== 'default' ? <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                props.onDeleteOptionModifierSelects(props.index, optIndex)
                                            }
                                            }
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div> : ""}
                            </div>
                        )
                    })}
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        props.onCreateOptionModifierSelects(props.index)
                    }}>
                        + {i18n.add_mod_option()}
                    </a>
                </div>
            </div>
        </div>
    )
}

interface IModificationExtraProps {
    index: number
    modifierExtra: IModifierExtra
    isStaff: boolean
    onChangeLabelModifierExtra: (index: number, value: I18nString) => void
    onChangePriceDeltaModifierExtra: (index: number, value: number) => void
    onHideModifierExtra: (index: number) => void
    onDeleteModifierExtra: (index: number) => void
}

function ModificationExtra(props: IModificationExtraProps) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div data-gutter="15"
             className={props.modifierExtra.isHidden ? "row mod-item hidden-w-opacity" : "row mod-item"}>
            <div className="col-md-6">
                <TextI18n values={props.modifierExtra.label} isStaff={props.isStaff}
                          setValues={(v) => props.onChangeLabelModifierExtra(props.index, v)}
                          label={i18n.mod_extra_name()}
                          lang={menu.i18n.defaultLang}
                          allLang={menu.i18n.otherLangs || []}
                />
            </div>
            <div className="col-md-5">
                <PriceInput label={i18n.price_delta()}
                            value={props.modifierExtra.priceDelta}
                            onChange={(numb) => props.onChangePriceDeltaModifierExtra(props.index, numb)}/>
                <div className="control">
                    <label className="custom-checkbox">
                        <input type="checkbox" checked={props.modifierExtra.isHidden}
                               onClick={() => {
                                   props.onHideModifierExtra(props.index)
                               }}
                        />
                        <span>{i18n.hide()}</span>
                    </label>
                </div>
            </div>
            <div className="col-1">
                <button type="button" className="form__remove-button"
                        onClick={() => {
                            props.onDeleteModifierExtra(props.index)
                        }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                            fill="#8392A5"></path>
                        <path
                            d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                            fill="#8392A5"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}
