import React from "react";
import {CropperModal} from "../modal/cropperModal";
import {Link} from "react-router-dom";
import {useSectionForm} from "../../hooks/menu/section";
import {TextAreaI18n} from "../input/textAreaI18n";
import {TextI18n} from "../input/textI18n";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {ImageUploadComponent} from "../input/imageUploadComponent";
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";

export function SectionForm({id, edit}: { id: string, edit: boolean }) {
    const info = useSectionForm(id, edit)
    const {i18n} = useGlobalContext()
    return (
        <>
            <FormWithLoader isloading={info.sectionLoading} className="form">
                <ImageUploadComponent img={info.section.img} ImgType={"menuSections"} onUploadImage={info.onUploadImage}
                                      onDeleteImage={info.onDeleteImg} imgUploading={info.saveSectionImgLoading}
                                      type={"-section"} size={"-big"} message={i18n.image_upload_hint_std()}/>


                <TextI18n values={info.section.name} setValues={info.onChangeSectionName} isStaff={info.isStaff}
                          lang={info.menu.i18n.defaultLang} allLang={info.menu.i18n.otherLangs || []}
                          isRequired={true} label={i18n.section_name()}/>

                <TextAreaI18n values={info.section.description || {}} small={true} isStaff={info.isStaff}
                              setValues={info.onChangeSectionDescription} lang={info.menu.i18n.defaultLang}
                              allLangs={info.menu.i18n.otherLangs || []}
                              label={i18n.short_desc()}
                />

                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={info.onSave} label={i18n.save()}
                                      disabled={!info.currentMenuId} isLoading={info.saveSectionLoading}/>
                        <Link to={RouteNames.MENUS}
                              className="button -bordered_blue form__button">
                            {i18n.cancel()}
                        </Link>
                    </div>
                </div>

            </FormWithLoader>
            <CropperModal modal={info.modal} selectedImage={info.selectedImage} setModal={info.setModal}
                          saveImage={info.UploadImg} setSelectImage={info.setSelectedImage}/>
        </>
    )
}
