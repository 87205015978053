import {DashboardAction, DashboardsActionEnum, DashboardsState} from "./types";
import {
    ReportDashboardDuringPeriodRes,
    ReportDashboardDynamicsRes,
    ReportDashboardTodayRes,
    ReportMostVisitedMenus
} from "../api";

const initialState: DashboardsState = {
    reportToday: {} as ReportDashboardTodayRes,
    reportDuringPeriod: {} as ReportDashboardDuringPeriodRes,
    reportDynamicsVisits: {} as ReportDashboardDynamicsRes,
    reportDynamicsActiveUsers: {} as ReportDashboardDynamicsRes,
    reportDynamicsNewUsers: {} as ReportDashboardDynamicsRes,
    reportMostVisitedMenus: {} as ReportMostVisitedMenus,
    isLoadingReportToday: true,
    isLoadingReportDuringPeriod: true,
    isLoadingReportDynamics: true,
    isLoadingReportDynamicsVisits: false,
    isLoadingReportDynamicsActiveUsers: false,
    isLoadingReportDynamicsNewUsers: false,
    isLoadingReportMostVisitedMenus: false,
}

export default function dashboardReducer(state = initialState, action: DashboardAction): DashboardsState {
    switch (action.type) {
        case DashboardsActionEnum.SET_REPORT_TODAY:
            return {...state, reportToday: action.payload};
        case DashboardsActionEnum.SET_REPORT_DURING_PERIOD:
            return {...state, reportDuringPeriod: action.payload};
        case DashboardsActionEnum.SET_REPORT_DYNAMICS_VISITS:
            return {...state, reportDynamicsVisits: action.payload};
        case DashboardsActionEnum.SET_REPORT_DYNAMICS_ACTIVE_USERS:
            return {...state, reportDynamicsActiveUsers: action.payload};
        case DashboardsActionEnum.SET_REPORT_DYNAMICS_NEW_USERS:
            return {...state, reportDynamicsNewUsers: action.payload};
        case DashboardsActionEnum.SET_REPORT_MOST_VISITED_MENUS:
            return {...state, reportMostVisitedMenus: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_TODAY:
            return {...state, isLoadingReportToday: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_DURING_PERIOD:
            return {...state, isLoadingReportDuringPeriod: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS:
            return {...state, isLoadingReportDynamics: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_VISITS:
            return {...state, isLoadingReportDynamicsVisits: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_ACTIVE_USERS:
            return {...state, isLoadingReportDynamicsActiveUsers: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_NEW_USERS:
            return {...state, isLoadingReportDynamicsNewUsers: action.payload};
        case DashboardsActionEnum.SET_IS_LOADING_REPORT_MOST_VISITED_MENUS:
            return {...state, isLoadingReportMostVisitedMenus: action.payload};
        default:
            return {...state};
    }
}
