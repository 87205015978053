import React, {useEffect} from "react";
import {I18nString} from "../../models/lang/i18nString";
import {LangKey, useGlobalContext} from "../../hooks/globalContext";

interface HtmlEditorProps {
    text: I18nString
    onChangeText: (text: I18nString) => void
    lang: LangKey
    allLangs: LangKey[]
    label?: string
    isRequired?: boolean
    addImage?: (file: string) => void
}
const URL = process.env.REACT_APP_IMAGE_URL;

export function HtmlEditor(props: HtmlEditorProps) {
    const {api} = useGlobalContext()

    const [langInd, setLangInd] = React.useState<number>(0)
    let globLang = ([props.lang,...props.allLangs])[langInd]
    let text = props.text
    function onChange(contents:string) {
        text = {...text, [globLang]: contents}
        props.onChangeText({
            ...text
        })
    }

    const [loading, setLoading] = React.useState<boolean>(false)
    useEffect(() => {
        let note = $('#summernote')
        note.summernote({
            callbacks: {
                onChange: onChange,
                onImageUpload: function (files) {
                    if (files.length) {
                        let file = new File([files[0]], "cropped." + files[0].type.split("/")[1], {type: files[0].type})
                        api.saveCustomPageImage({setLoading},file).then((res) => {
                            if (res.data) {
                                let name = res.data.newFilename
                                props.addImage?.(name)
                                note.summernote("editor.insertImage", URL + "customPages/" + name)
                            }
                        })
                    }
                },
                onInit: function () {
                    const node = document.getElementsByClassName("note-toolbar")[0]
                    const div = document.createElement("div")
                    div.className = "note-btn-group lang-btn-group"
                        //button
                    const button = document.createElement("button")
                    button.innerHTML = "<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 512 512\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"#000000\"><g id=\"SVGRepo_bgCarrier\" stroke-width=\"0\"></g><g id=\"SVGRepo_tracerCarrier\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></g><g id=\"SVGRepo_iconCarrier\"> <path fill=\"var(--ci-primary-color, #000000)\" d=\"M320,24H16V328H192V496H496V192H320ZM148.305,96,98.093,239.3H132l8.166-23.3H192v80H48V56H288V192H221.332L187.7,96Zm36.317,88H151.378L168,136.562ZM464,224V464H224V224Z\" class=\"ci-primary\"></path> <path fill=\"var(--ci-primary-color, #000000)\" d=\"M317.432,368.48A136.761,136.761,0,0,0,327.521,382.6q-17.4,9.384-39.521,9.4v32c24.141,0,45.71-6.408,64-18.824C370.29,417.592,391.859,424,416,424V392q-22.075,0-39.52-9.407a136.574,136.574,0,0,0,10.088-14.113A166.212,166.212,0,0,0,406.662,320H424V288H368V264H336v24H280v32h17.338A166.212,166.212,0,0,0,317.432,368.48ZM373.53,320a133.013,133.013,0,0,1-14.1,31.52A104.39,104.39,0,0,1,352,361.968a103.546,103.546,0,0,1-6.93-9.651A132.384,132.384,0,0,1,330.466,320Z\" class=\"ci-primary\"></path> </g></svg>"
                    button.className = "note-btn"
                    button.onclick = (e) => {
                        e.preventDefault()
                        let n = document.getElementsByClassName("lang-btn-group")[0]?.classList
                        if (n.contains("open")) {
                            n.remove("open")
                        } else {
                            n.add("open")
                        }
                    }
                    div.appendChild(button)
                    //select
                    const div2 = document.createElement("div")
                    div2.className = "note-dropdown-menu dropdown-style"
                    for (let i = ([props.lang,...props.allLangs]).length - 1; i >= 0; i--) {
                        const lang = ([props.lang,...props.allLangs])[i]
                        const a = document.createElement("a")
                        a.className = "note-dropdown-item"
                        a.id = "lang-" + lang
                        a.href = "#"
                        a.innerHTML = lang
                        if (i==0) a.style.border = "1px solid #000"
                        a.onclick = (e) => {
                            e.preventDefault()
                            globLang = ([props.lang,...props.allLangs])[i]
                            setLangInd(i)
                        }
                        div2.appendChild(a)
                    }
                    div.appendChild(div2)
                    node.appendChild(div)
                }
            },
            placeholder: '',
            tabsize: 2,
            height: 120,
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontsize', ['fontname', 'fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', props.addImage ? ['link', 'picture'] : ['link']],
                ['view', ['codeview']],
                // ['extensions', ['summernoteGallery']],
            ]
        });
    }, [])

    useEffect(() => {
        if (props.text[props.lang]) {
            let note = $('#summernote')
            let list = ([props.lang,...props.allLangs])
            list.forEach((lang) => {
                let elem = document.getElementById("lang-" + lang)
                if (elem) elem.style.border = "none"
            })
            let elem = document.getElementById("lang-" + globLang)
            if (elem) elem.style.border = "1px solid #000"
            note.summernote('code', props.text[([props.lang,...props.allLangs])[langInd]]||"<div></div>");
        }
    }, [langInd])
    return (
        <>
        <div className="control">
            <label className="control__label">{props.label}</label>
            {props.isRequired ? <span className="required-star">*</span> : null}
            <form method="post">
                <div id="summernote">
                </div>
            </form>
        </div>
        </>
    )
}
