import {IEditMenuInfo} from "../../models/menuEditor/IEditMenuInfo";
import {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {I18nString} from "../../models/lang/i18nString";
import {Phone} from "../../models/other/phone";

export function useMainPageEditor() {
    const [mainPageInfo, setMainPageInfo] = useState<IEditMenuInfo>({
        name: "",
        desc: {},
        logo: null,
        backgroundImg: null,
        address: {},
        schedule: {},
        phoneContacts: [],
        instagram: null,
        whatsapp: {number: "", countryCode: ""},
        facebook: null,
        site: null
    });

    const [logoModal, setLogoModal] = useState<boolean>(false);
    const [backgroundModal, setBackgroundModal] = useState<boolean>(false);
    const [selectedLogoImage, setSelectedLogoImage] = useState<File | null>(null);
    const [selectedBackgroundImage, setSelectedBackgroundImage] = useState<File | null>(null);


    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()

    const [menuOrgAttrLoading, setMenuOrgAttrLoading] = useState<boolean>(false)
    const [uploadLogoImageLoading, setUploadLogoImageLoading] = useState<boolean>(false)
    const [uploadBackgroundImageLoading, setUploadBackgroundImageLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (!currentMenuId) {
            return
        }
        api.getMenuOrgAttrs({controller, setLoading:setMenuOrgAttrLoading},currentMenuId).then((res) => {
            if (res.data) {
                setMainPageInfo(res.data)
            }
        })
        return () => controller.abort()
    }, [currentMenuId])

    function isNotEmpty(val: I18nString):boolean {
        let notEmpty = false
        if (val) {
            if (Object.keys(val).length === 0) {
                return false
            }
            Object.keys(val).forEach((key) => {
                if (val[key]) {
                    notEmpty = true
                }
            })
        }
        return notEmpty
    }

    function onChangeName(name: string) {
        setMainPageInfo({...mainPageInfo, name: name})
    }

    function onChangeDesc(desc: I18nString) {
        setMainPageInfo({...mainPageInfo, desc: isNotEmpty(desc) ? desc : null})
    }

    function onChangeAddress(address: I18nString) {
        setMainPageInfo({...mainPageInfo, address: isNotEmpty(address)?address:null})
    }

    function onChangeSchedule(schedule: I18nString) {
        setMainPageInfo({...mainPageInfo, schedule: isNotEmpty(schedule)?schedule:null})
    }

    function onChangePhoneContacts(phoneContacts: Phone[]) {
        let newPhoneContacts: Phone[] = []
        phoneContacts.forEach((phone) => {
            if (phone.number !== "") {
                newPhoneContacts.push(phone)
            }
        })
        setMainPageInfo({...mainPageInfo, phoneContacts: newPhoneContacts})
    }

    function onChangeIntagram(intagram: string | null) {
        if (intagram === "") {
            intagram = null
        }
        setMainPageInfo({...mainPageInfo, instagram: intagram})
    }

    function onChangeWhatsapp(whatsapp: Phone) {
        let newWhatsapp: Phone | null = whatsapp
        if (whatsapp.number === "") {
            newWhatsapp = null
        }
        setMainPageInfo({...mainPageInfo, whatsapp: newWhatsapp})
    }

    function onChangeFacebook(facebook: string | null) {
        if (facebook === "") {
            facebook = null
        }
        setMainPageInfo({...mainPageInfo, facebook: facebook})
    }

    function onChangeSite(site: string | null) {
        if (site === "") {
            site = null
        }
        setMainPageInfo({...mainPageInfo, site: site})
    }

    function UploadLogoImage(file: File) {
        api.saveMenuOrgAttrsLogoImage({setLoading:setUploadLogoImageLoading},file).then((res) => {
            if (res.data) {
                setMainPageInfo({...mainPageInfo, logo: res.data.newFilename})
            }
        })
    }

    function onUploadLogoFile(file: File | null) {
        setSelectedLogoImage(file);
        setLogoModal(true)
    }

    function onUploadBackgroundFile(file: File | null) {
        setSelectedBackgroundImage(file);
        setBackgroundModal(true)
    }

    function onDeleteLogo() {
        setMainPageInfo({...mainPageInfo, logo: null})
    }

    function UploadBackgroundImage(file: File) {
        api.saveBackgroundImage({setLoading:setUploadBackgroundImageLoading},file).then((res) => {
            if (res.data) {
                setMainPageInfo({...mainPageInfo, backgroundImg: res.data.newFilename})
            }
        })
    }

    function onDeleteBackground() {
        setMainPageInfo({...mainPageInfo, backgroundImg: null})
    }

    function onSave() {
        api.updateMenuOrgAttrs({setLoading:setSaveLoading},{
            menuId: currentMenuId,
            name: mainPageInfo.name,
            desc: mainPageInfo.desc,
            logo: mainPageInfo.logo ? mainPageInfo.logo : null,
            backgroundImg: mainPageInfo.backgroundImg ? mainPageInfo.backgroundImg : null,
            address: mainPageInfo.address,
            schedule: mainPageInfo.schedule,
            phoneContacts: mainPageInfo.phoneContacts || [],
            instagram: mainPageInfo.instagram,
            whatsapp: mainPageInfo.whatsapp,
            facebook: mainPageInfo.facebook,
            site: mainPageInfo.site
        })
    }

    return {
        menu,
        isStaff: user?.isStaff || false,
        mainPageInfo,
        currentMenuId,
        onChangeName,
        onChangeDesc,
        onChangeAddress,
        onChangeSchedule,
        onChangePhoneContacts,
        onChangeInstagram: onChangeIntagram,
        onChangeWhatsapp,
        onChangeFacebook,
        onChangeSite,
        onSave,
        logoModal,
        setLogoModal,
        backgroundModal,
        setBackgroundModal,
        UploadLogoImage,
        UploadBackgroundImage,
        selectedLogoImage,
        setSelectedLogoImage,
        selectedBackgroundImage,
        setSelectedBackgroundImage,
        onUploadLogoFile,
        onUploadBackgroundFile,
        onDeleteLogo,
        onDeleteBackground,


        saveLoading,
        menuOrgAttrLoading,
        uploadLogoImageLoading,
        uploadBackgroundImageLoading
    }
}