import system from './system';
import lang from './lang';
import auth from 'migration/pages/public/Login/store/auth';
import user from './user';
import menu from "migration/pages/user/menu/menu/store";
import iiko from 'migration/pages/user/menu/Iiko/store';
import menuIiko from "migration/pages/user/menu/iikoMenuBindings/store";
import notification from "migration/pages/user/menu/settings/notifications/store";
import feedback from "migration/pages/user/feedback/feedbackEdit/store";
import feedbacksList from "migration/pages/user/feedback/main/store";
import feedbackDashboardData from "migration/pages/user/feedback/feedbackPage/store";
import generalNotification from "migration/pages/user/general/Notifications/store";
import cola from "migration/pages/user/menu/cola/store";
import organizations from "migration/pages/staff/organizations/store";
import crossSelling from "migration/pages/user/crossSelling/store";
import orgSettings from "migration/pages/user/menu/orgSettings/store";
import dashboards from "migration/pages/staff/dashboards/store";
import rkeeper from "migration/pages/user/menu/rkeeper/store";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    system,
    lang,
    auth,
    user,
    menu,
    iiko,
    menuIiko,
    notification,
    feedback,
    feedbacksList,
    feedbackDashboardData,
    generalNotification,
    cola,
    organizations,
    crossSelling,
    orgSettings,
    dashboards,
    rkeeper
}
