import React, {CSSProperties, FunctionComponent, ReactNode} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import classes from "./Button.module.scss";

export interface ButtonProps {
    text: ReactNode;
    onClick?: (props?: any) => any;
    style?: CSSProperties;
    disabled?: boolean;
    loading?: boolean;
    isFilled?: boolean;
    isDelete?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (props?.disabled || props.loading) return;

        if (props?.onClick) {
            props?.onClick();
        }
    }

    const styles: CSSProperties = {
        backgroundColor: props?.isFilled ? "" : "transparent",
        color: props?.isFilled ? "#fff" : (props?.isDelete ? "#ff4136" : "#0252cc"),
    }

    return (
        <button
            onClick={(handleClick)}
            className={props.isDelete ? classes.button__delete : classes.button}
            style={{
                ...styles,
                ...props?.style,
                opacity: (props?.disabled || props.loading) ? 0.5 : 1,
                cursor: (props?.disabled || props.loading) ? "not-allowed" : "pointer",
            }}

        >
            {props.text || ""}
            {props?.loading && <LoadingOutlined className={classes.btn__loading}/>}
        </button>
    )
}
