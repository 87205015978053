import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Reason as GradeReason} from "migration/entities/Feedback";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ModalReasonEdit} from "../ModalReasonEdit";
import classes from "./Reason.module.scss";
import {Skeleton} from "antd";

interface ReasonProps {
    index: number;
    reason: GradeReason;
    gradeType: "positiveGrading" | "negativeGrading" | 1 | 2 | 3 | 4 | 5;
}

export const Reason: FunctionComponent<ReasonProps> = (props) => {
    const navigate = useNavigate();
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {
        removePositiveGradeReasonFeedbackPage,
        removeNegativeGradeReasonFeedbackPage,
        removeSpecificGradeReasonFeedbackPage,
    } = useActions();
    const [isReasonEditModalOpen, setIsReasonEditModalOpen] = useState<boolean>(false);
    const [isLoadingRemoveReason, setIsLoadingRemoveReason] = useState<boolean>(false);

    const handleRemoveReason = () => {
        setIsLoadingRemoveReason(true);
        const updatedFeedbackPage = {...feedbackPage};
        if (props.gradeType === "positiveGrading") {
            updatedFeedbackPage.positiveGrading.reasons = updatedFeedbackPage.positiveGrading?.reasons?.filter(reason => reason.reasonId !== props.reason.reasonId);
            removePositiveGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason.reasonId,
            }, feedbackPage, {navigate: navigate});
        } else if (props.gradeType === "negativeGrading") {
            updatedFeedbackPage.negativeGrading.reasons = updatedFeedbackPage.negativeGrading?.reasons?.filter(reason => reason.reasonId !== props.reason.reasonId);
            removeNegativeGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason.reasonId,
            }, feedbackPage, {navigate: navigate});
        } else {
            updatedFeedbackPage.specificGrading[props.gradeType].reasons = updatedFeedbackPage.specificGrading[props.gradeType]?.reasons?.filter(reason => reason.reasonId !== props.reason.reasonId);
            removeSpecificGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason.reasonId,
            }, feedbackPage, {navigate: navigate}, props.gradeType);
        }
        setIsLoadingRemoveReason(false);
    }

    if (isLoadingRemoveReason) return (
        <Skeleton className={classes.reason} active/>
    );

    return (
        <div className={classes.reason}>
            <div className={classes.reason__header}>
                <div className={classes.reason__header__item}>
                    {`#${props.index}`}
                </div>

                <div className={classes.reason__header__item}>
                    <EditOutlined
                        onClick={() => setIsReasonEditModalOpen(true)}
                        className={classes.reason__header__item__icon}
                    />

                    <DeleteOutlined
                        onClick={() => handleRemoveReason()}
                        className={classes.reason__header__item__icon}
                    />
                </div>
            </div>

            <div className={classes.reason__lang__item} key={feedbackPage?.defaultLang}>
                {`${feedbackPage?.defaultLang}: ${props?.reason?.reasonName?.[feedbackPage?.defaultLang] || "-"}`}
            </div>

            {feedbackPage?.otherLangs?.map(lang => (
                <div className={classes.reason__lang__item} key={lang}>
                    {`${lang}: ${props?.reason?.reasonName?.[lang] || "-"}`}
                </div>
            ))}

            <ModalReasonEdit
                isReasonEditModalOpen={isReasonEditModalOpen}
                setIsReasonEditModalOpen={setIsReasonEditModalOpen}
                reason={props.reason}
                gradeType={props.gradeType}
            />
        </div>
    )
}
