import {LangKey, useGlobalContext} from "../globalContext";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {IStaffCreate} from "../../models/user/staff";
import {EmailIsValid} from "../../utils/scripts";

export function useStaffForm() {
    const {api} = useGlobalContext()
    const navigate = useNavigate()

    const [saveLoading, setSaveLoading] = useState(false)

    const [staffInfo, setStaffInfo] = useState<IStaffCreate>({
        userName: "",
        email: "",
        lang: "RU",
    })

    const [staffInfoValid, setStaffInfoValid] = useState({
        userName: true,
        email: true,
    })


    function onChangeUserName(userName: string) {
        setStaffInfo({
            ...staffInfo, userName
        })
        setStaffInfoValid({
            ...staffInfoValid, userName: userName.length > 0
        })
    }

    function onChangeEmail(email: string) {
        setStaffInfo({
            ...staffInfo, email
        })
        setStaffInfoValid({
            ...staffInfoValid, email: EmailIsValid(email)
        })
    }


    function onChangeLang(lang: LangKey) {
        setStaffInfo({
            ...staffInfo, lang
        })
    }

    function onSave() {
        if (!staffInfoValid.userName || !EmailIsValid(staffInfo.email)) {
            setStaffInfoValid({
                userName: staffInfo.userName.length > 0,
                email: !EmailIsValid(staffInfo.email),
            })
            return
        }
        api.createStaffUser({setLoading:setSaveLoading},staffInfo.userName, staffInfo.email, staffInfo.lang).then((res) => {
            if (res.success) {
                navigate("/staff/managers")
            }
        })
    }

    return {
        staffInfo,
        staffInfoValid,
        onChangeUserName,
        onChangeEmail,
        onChangeLang,
        onSave,
        saveLoading,
    }
}