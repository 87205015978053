import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    DeliveryItemStatusesEnum,
    DeliveryOrderStatusesEnum,
    TableItemStatusesEnum,
    TableOrderStatusesEnum,
} from "migration/entities/webhookSettings";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {SubmitButton} from "../../../../../../../components/input/submitButton";
import {useGlobalContext, useUserContext} from "../../../../../../../hooks/globalContext";
import {isEmpty} from "lodash";

export function IikoWebhooks() {
    const navigate = useNavigate();
    const {currentMenuId, user, iikoBindOrg} = useUserContext();
    const {i18n} = useGlobalContext();
    const {webhookSettings, isLoadingConfigWebhookSettings} = useTypedSelector(state => state.iiko);
    const {fetchWebhookSettings, configWebhookSettings} = useActions();

    const startConfig = () => {
        configWebhookSettings(
            {menuId: currentMenuId, organizationId: user?.org?.id || ""},
            {menuId: currentMenuId, organizationId: user?.org?.id || ""},
            {navigate: navigate}
        );
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchWebhookSettings({
            menuId: currentMenuId,
            organizationId: user?.org?.id || ""
        }, controller, {navigate: navigate});
        return () => controller.abort();
    }, [JSON.stringify(iikoBindOrg)]);

    useEffect(() => {
        document.title = i18n.scripts()
    }, [i18n]);

    return (
        <React.Fragment>
            <div className="control" style={{display: "flex", gap: "40px"}}>
                <label className="control__label" style={{width: "150px"}}>
                    {i18n.api_login_name() + ":"}
                </label>
                <label className="control__label" style={{color: "#000000"}}>
                    {webhookSettings?.apiLoginName || "-"}
                </label>
            </div>

            <div className="control" style={{display: "flex", gap: "40px"}}>
                <label className="control__label" style={{width: "150px"}}>
                    {i18n.web_hooks_uri() + ":"}
                </label>
                <label className="control__label" style={{color: "#000000"}}>
                    {webhookSettings?.webHooksUri || "-"}
                </label>
            </div>

            <div className="control" style={{display: "flex", gap: "40px"}}>
                <label className="control__label" style={{width: "150px"}}>
                    {i18n.auth_token() + ":"}
                </label>
                <label className="control__label" style={{color: "#000000"}}>
                    {webhookSettings?.authToken || "-"}
                </label>
            </div>

            <div
                className="control"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    opacity: isEmpty(webhookSettings) ? "0.5" : "1",
                    pointerEvents: isEmpty(webhookSettings) ? "none" : "auto",
                    transition: "opacity 0.3s ease-in-out"
                }}
            >
                <label className="control__label" style={{width: "150px"}}>
                    {i18n.web_hooks_filter() + ":"}
                </label>

                <div
                    style={{
                        width: "100%",
                        border: "1px solid #000000",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px"
                    }}
                >
                    <div
                        className="control"
                        style={{display: "flex", flexDirection: "column", gap: "20px", margin: "0"}}
                    >
                        <label
                            className="control__label"
                            style={{
                                color: "#000000",
                                fontWeight: "700"
                            }}
                        >
                            {i18n.delivery_order_filter()}
                        </label>

                        <div
                            className="control"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                margin: "0"
                            }}
                        >
                            <label className="control__label">
                                {i18n.orders_status() + ":"}
                            </label>

                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                }}
                            >
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.Unconfirmed)}/>
                                    {i18n.unconfirmed()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.WaitCooking)}/>
                                    {i18n.wait_cooking()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.ReadyForCooking)}/>
                                    {i18n.ready_for_cooking()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.CookingStarted)}/>
                                    {i18n.cooking_started()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.CookingCompleted)}/>
                                    {i18n.cooking_completed()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.Waiting)}/>
                                    {i18n.waiting()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.OnWay)}/>
                                    {i18n.on_way()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.Delivered)}/>
                                    {i18n.delivered()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.Closed)}/>
                                    {i18n.closed()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.orderStatuses?.includes(DeliveryOrderStatusesEnum.Cancelled)}/>
                                    {i18n.cancelled()}
                                </label>
                            </div>
                        </div>

                        <div style={{width: "100%", height: "1px", backgroundColor: "grey"}}></div>

                        <div
                            className="control"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                margin: "0"
                            }}
                        >
                            <label className="control__label">
                                {i18n.status_of_dish() + ":"}
                            </label>

                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                }}
                            >
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.itemStatuses?.includes(DeliveryItemStatusesEnum.Added)}/>
                                    {i18n.added()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.itemStatuses?.includes(DeliveryItemStatusesEnum.PrintedNotCooking)}/>
                                    {i18n.printed_not_cooking()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.itemStatuses?.includes(DeliveryItemStatusesEnum.CookingStarted)}/>
                                    {i18n.cooking_started()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.itemStatuses?.includes(DeliveryItemStatusesEnum.CookingCompleted)}/>
                                    {i18n.cooking_completed()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.itemStatuses?.includes(DeliveryItemStatusesEnum.Served)}/>
                                    {i18n.served()}
                                </label>
                            </div>
                        </div>

                        <div style={{width: "100%", height: "1px", backgroundColor: "grey"}}></div>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.errors() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.deliveryOrderFilter?.errors}/>
                                {i18n.send()}
                            </label>
                        </div>
                    </div>

                    <div
                        className="control"
                        style={{display: "flex", flexDirection: "column", gap: "20px", margin: "0"}}
                    >
                        <label
                            className="control__label"
                            style={{
                                color: "#000000",
                                fontWeight: "700"
                            }}
                        >
                            {i18n.table_order_filter()}
                        </label>

                        <div
                            className="control"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                margin: "0"
                            }}
                        >
                            <label className="control__label">
                                {i18n.orders_status() + ":"}
                            </label>

                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                }}
                            >
                                <label
                                    style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.orderStatuses?.includes(TableOrderStatusesEnum.New)}/>
                                    {i18n.new()}
                                </label>
                                <label
                                    style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.orderStatuses?.includes(TableOrderStatusesEnum.Bill)}/>
                                    {i18n.bill()}
                                </label>
                                <label
                                    style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.orderStatuses?.includes(TableOrderStatusesEnum.Closed)}/>
                                    {i18n.closed()}
                                </label>
                                <label
                                    style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.orderStatuses?.includes(TableOrderStatusesEnum.Deleted)}/>
                                    {i18n.deleted()}
                                </label>
                            </div>
                        </div>

                        <div style={{width: "100%", height: "1px", backgroundColor: "grey"}}></div>

                        <div
                            className="control"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                margin: "0"
                            }}
                        >
                            <label className="control__label">
                                {i18n.status_of_dish() + ":"}
                            </label>

                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                }}
                            >
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.itemStatuses?.includes(TableItemStatusesEnum.Added)}/>
                                    {i18n.added()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.itemStatuses?.includes(TableItemStatusesEnum.PrintedNotCooking)}/>
                                    {i18n.printed_not_cooking()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.itemStatuses?.includes(TableItemStatusesEnum.CookingStarted)}/>
                                    {i18n.cooking_started()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.itemStatuses?.includes(TableItemStatusesEnum.CookingCompleted)}/>
                                    {i18n.cooking_completed()}
                                </label>
                                <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                    <input type={"checkbox"}
                                           checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.itemStatuses?.includes(TableItemStatusesEnum.Served)}/>
                                    {i18n.served()}
                                </label>
                            </div>
                        </div>

                        <div style={{width: "100%", height: "1px", backgroundColor: "grey"}}></div>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.errors() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.tableOrderFilter?.errors}/>
                                {i18n.send()}
                            </label>
                        </div>
                    </div>

                    <div
                        className="control"
                        style={{display: "flex", flexDirection: "column", gap: "20px", margin: "0"}}
                    >
                        <label
                            className="control__label"
                            style={{
                                color: "#000000",
                                fontWeight: "700"
                            }}
                        >
                            {i18n.reserve_filter()}
                        </label>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.updates() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.reserveFilter?.updates}/>
                                {i18n.send()}
                            </label>
                        </div>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.errors() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.reserveFilter?.errors}/>
                                {i18n.send()}
                            </label>
                        </div>
                    </div>

                    <div
                        className="control"
                        style={{display: "flex", flexDirection: "column", gap: "20px", margin: "0"}}
                    >
                        <label
                            className="control__label"
                            style={{
                                color: "#000000",
                                fontWeight: "700"
                            }}
                        >
                            {i18n.stop_list_update_filter()}
                        </label>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.updates() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.stopListUpdateFilter?.updates}/>
                                {i18n.send()}
                            </label>
                        </div>
                    </div>

                    <div
                        className="control"
                        style={{display: "flex", flexDirection: "column", gap: "20px", margin: "0"}}
                    >
                        <label
                            className="control__label"
                            style={{
                                color: "#000000",
                                fontWeight: "700"
                            }}
                        >
                            {i18n.personal_shift_filter()}
                        </label>

                        <div
                            className="control"
                            style={{display: "flex", gap: "20px", margin: "0"}}
                        >
                            <label className="control__label" style={{width: "80px"}}>
                                {i18n.updates() + ":"}
                            </label>

                            <label style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                <input type={"checkbox"}
                                       checked={webhookSettings?.webHooksFilter?.personalShiftFilter?.updates}/>
                                {i18n.send()}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <SubmitButton onSubmit={startConfig} label={i18n.configure()}
                          disabled={isLoadingConfigWebhookSettings} isLoading={isLoadingConfigWebhookSettings}
                          modal={true}/>
        </React.Fragment>
    )
}
