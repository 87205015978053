import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {useGlobalContext} from "../../hooks/globalContext";
import {MainPageTabs} from "../../components/tabs/MainPageTabs";
import {BespokeForm} from "../../components/form/bespoke";
import {RouteNames} from "../../migration/pages";

export function MenuBespoke() {
    const {userInfoLoading, i18n} = useGlobalContext()
    useEffect(() => {
        document.title = "Bespoke"
    }, [i18n])
    return (

        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.main_menu_page(), link: RouteNames.MAIN_PAGE_EDITOR, index: "brcr-0"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.settings()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <MainPageTabs/>
                        <div className="tab-content">


                            <div className="default-card" style={{display: "flex", justifyContent: "space-between"}}>
                                <BespokeForm/>
                            </div>

                        </div>

                    </div>
                </div>


            </main>
        </section>
    )
}
