import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import {RouteNames} from "migration/pages";
import classes from "./Breadcrumbs.module.scss";

export interface BreadcrumbsData {
    title: string;
    link: RouteNames;
}

export const Breadcrumbs: FunctionComponent<{ breadcrumbs: BreadcrumbsData[] }> = ({breadcrumbs}) => {
    if (isEmpty(breadcrumbs)) return null;
    return (
        <div className={classes.breadcrumbs__bar}>
            {breadcrumbs.map((item, index) => (
                <React.Fragment>
                    <Link to={item.link} className={classes.breadcrumb} key={index} role={"link"}>
                        <div className={classes.breadcrumb__text}>{item.title}</div>
                    </Link>
                    {(index !== breadcrumbs.length - 1 || breadcrumbs.length === 1) && (
                        <div className={classes.breadcrumb__divider}>/</div>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}
