import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {Input, Modal} from "antd";
import {v4 as uuidv4} from "uuid";
import {Reason} from "migration/entities/Feedback";
import {LangsList, MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {TextI18n} from "../../../../../../../components/input/textI18n";
import classes from "./ModalReasonCreate.module.scss";

interface ModalReasonCreateProps {
    isReasonCreateModalOpen: boolean;
    setIsReasonCreateModalOpen: (value: boolean) => void;
    gradeType: "positiveGrading" | "negativeGrading" | 1 | 2 | 3 | 4 | 5;
}

export const ModalReasonCreate: FunctionComponent<ModalReasonCreateProps> = (props) => {
    const navigate = useNavigate();
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {currentLang} = useTypedSelector(state => state.lang);
    const {
        setFeedbackPage,
        addPositiveGradeReasonFeedbackPage,
        addNegativeGradeReasonFeedbackPage,
        addSpecificGradeReasonFeedbackPage,
    } = useActions();

    const [newReason, setNewReason] = useState<Reason>({reasonName: {} as MlString, reasonId: uuidv4()});

    const setNewReasonName = (value: MlString) => {
        setNewReason({...newReason, reasonName: value});
    }

    const onOk = () => {
        const updatedFeedback = {...feedbackPage};

        switch (props.gradeType) {
            case "positiveGrading":
                if (isEmpty(updatedFeedback.positiveGrading?.reasons)) {
                    updatedFeedback.positiveGrading = {
                        ...updatedFeedback.positiveGrading,
                        reasons: [],
                    };
                }
                updatedFeedback.positiveGrading?.reasons.push(newReason);
                break;
            case "negativeGrading":
                if (isEmpty(updatedFeedback.negativeGrading?.reasons)) {
                    updatedFeedback.negativeGrading = {
                        ...updatedFeedback.negativeGrading,
                        reasons: [],
                    };
                }
                updatedFeedback.negativeGrading?.reasons.push(newReason);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                if (isEmpty(updatedFeedback?.specificGrading?.[props.gradeType]?.reasons)) {
                    updatedFeedback.specificGrading = {
                        ...updatedFeedback.specificGrading,
                        [props.gradeType]: {
                            ...updatedFeedback.specificGrading?.[props.gradeType],
                            reasons: [],
                        }
                    };
                }
                updatedFeedback?.specificGrading?.[props.gradeType]?.reasons.push(newReason);
                break;
        }

        if (props.gradeType === "positiveGrading") {
            setFeedbackPage(updatedFeedback);
            addPositiveGradeReasonFeedbackPage({
                ...newReason,
                feedbackPageId: feedbackPage?.id
            }, updatedFeedback, {navigate: navigate});
        } else if (props.gradeType === "negativeGrading") {
            addNegativeGradeReasonFeedbackPage({
                ...newReason,
                feedbackPageId: feedbackPage?.id
            }, updatedFeedback, {navigate: navigate});
        } else {
            addSpecificGradeReasonFeedbackPage({
                ...newReason,
                feedbackPageId: feedbackPage?.id
            }, updatedFeedback, {navigate: navigate}, props.gradeType);
        }

        setNewReason({reasonName: {} as MlString, reasonId: uuidv4()});
        props.setIsReasonCreateModalOpen(false);
    }

    const onCancel = () => {
        setNewReason({reasonName: {} as MlString, reasonId: uuidv4()});
        props.setIsReasonCreateModalOpen(false);
    }

    return (
        <Modal
            title={txt.add_reason[currentLang]}
            open={props.isReasonCreateModalOpen}
            onOk={onOk}
            onCancel={onCancel}
        >
            <div className={classes.reasons}>
                <TextI18n
                    values={newReason?.reasonName}
                    setValues={setNewReasonName as (c: { [key: string]: string }) => void}
                    lang={feedbackPage?.defaultLang}
                    allLang={feedbackPage?.otherLangs || []}
                    isStaff={true}
                    placeholder={txt.enter_reason[currentLang]}
                />
            </div>
        </Modal>
    )
}
