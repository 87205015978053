import {ICurrentMenuInfo} from "../../../../../models/menu/menu";
import {IIKOCompany} from "../../../../../models/iiko/company";
import {iikoOrganizationBinding} from "../../../../../models/iiko/org";
import {ICurrentUserInfo} from "../../../../../models/user/userLogin";

export type UserState = {
    currentUser: ICurrentUserInfo | null;
    isAuth: boolean;
    isLoadingUser: boolean;

    menu: ICurrentMenuInfo;
    currentMenuId: string;
    isLoadingMenu: boolean;

    iikoActiveGeneral: boolean;
    iikoActiveMenu: boolean;
}

export enum UserActionEnum {
    SET_CURRENT_USER = "SET_CURRENT_USER",
    SET_AUTH = "SET_AUTH",
    SET_IS_LOADING_USER = "SET_IS_LOADING_USER",

    SET_MENU = "SET_MENU",
    SET_CURRENT_MENU_ID = "SET_CURRENT_MENU_ID",
    SET_IS_LOADING_MENU = "SET_IS_LOADING_MENU",

    SET_IIKO_ACTIVE_GENERAL = "SET_IIKO_ACTIVE_GENERAL",
    SET_IIKO_ACTIVE_MENU = "SET_IIKO_ACTIVE_MENU",
}

export type SetCurrentUserAction = {
    type: UserActionEnum.SET_CURRENT_USER;
    payload: ICurrentUserInfo | null;
}

export type SetAuthAction = {
    type: UserActionEnum.SET_AUTH;
    payload: boolean;
}

export type SetIsLoadingUserAction = {
    type: UserActionEnum.SET_IS_LOADING_USER;
    payload: boolean;
}

export type SetMenuAction = {
    type: UserActionEnum.SET_MENU;
    payload: ICurrentMenuInfo;
}

export type SetCurrentMenuIdAction = {
    type: UserActionEnum.SET_CURRENT_MENU_ID;
    payload: string;
}

export type SetIsLoadingMenuAction = {
    type: UserActionEnum.SET_IS_LOADING_MENU;
    payload: boolean;
}

export type SetIikoActiveGeneralAction = {
    type: UserActionEnum.SET_IIKO_ACTIVE_GENERAL;
    payload: boolean;
}

export type SetIikoActiveMenuAction = {
    type: UserActionEnum.SET_IIKO_ACTIVE_MENU;
    payload: boolean;
}


export type UserAction =
    SetCurrentUserAction |
    SetAuthAction |
    SetIsLoadingUserAction |
    SetMenuAction |
    SetCurrentMenuIdAction |
    SetIsLoadingMenuAction |
    SetIikoActiveGeneralAction |
    SetIikoActiveMenuAction;
