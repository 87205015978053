import React, {useEffect, useState} from "react";
import {MenuDnd} from "../../components/dnd/dndMenu";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {I18nString} from "../../models/lang/i18nString";
import {EditCategoryTree, EditSectionTree, EditTreeItem, IItemForSort, IMenuForSort} from "../../models/menu/menuTree";
import {useNavigate} from "react-router-dom";
import {MenuUlWithLoader} from "../../components/loader/menuUlWithLoader";
import {RouteNames} from "../../migration/pages";

export function MenuSorting() {
    const [state, setState] = useState<{ menu: IMenuForSort[] }>({menu: []})
    const {currentMenuId} = useUserContext()
    const navigate = useNavigate()
    const {i18n, api} = useGlobalContext()
    const [show, setShow] = useState<{ [key: string]: boolean }>({})
    useEffect(() => {
        document.title = i18n.sorting()
    }, [i18n])

    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [menuStrLoading, setMenuStrLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (!currentMenuId) {
            return
        }
        //todo: add mutation
        api.getMenuStructForMenuPage({controller, setLoading: setMenuStrLoading}, currentMenuId).then((res) => {
            let sections: IMenuForSort[] = []
            if (res.data) {
                (res.data.sections || []).forEach((sec) => {
                    let section: IMenuForSort = {
                        id: sec.section.id,
                        section: {
                            id: sec.section.id,
                            name: sec.section.name,
                        },
                        categories: []
                    }

                    if (sec.categories) {
                        sec.categories.forEach((cat) => {
                            let category: { id: string, category: { id: string, name: I18nString }, items: { id: string, item: IItemForSort }[] } = {
                                id: cat.category.id,
                                items: [],
                                category: {
                                    id: cat.category.id,
                                    name: cat.category.name,
                                }
                            }
                            if (cat.items) {
                                cat.items.forEach((itm) => {
                                    category.items.push({
                                        id: itm.id,
                                        item: {id: itm.id, name: itm.name, price: itm.prices.primary.price}
                                    })
                                })
                            }
                            section.categories.push(category)
                        })
                    }

                    sections.push(section)
                })
            }
            setState({menu: sections})
        })
        return () => controller.abort()
    }, [currentMenuId])

    function onSave() {
        api.saveMenuSort({setLoading: setSaveLoading}, currentMenuId, {
            sections: state.menu.map((section) => {
                let categories: EditCategoryTree[] = section.categories.map((category) => {
                    let items: EditTreeItem[] = category.items.map((item) => {
                        return {
                            itemId: item.id,
                        }
                    })
                    return {
                        categoryId: category.id,
                        items: items
                    }
                })
                let sect: EditSectionTree = {
                    sectionId: section.id,
                    categories: categories,
                }
                return sect
            }),
        }).then((res) => {
            if (res.success) {
                navigate(RouteNames.MENUS)
            }
        })
    }

    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.menus(), link: RouteNames.MENUS, index: "brcr-0"},
                    {title: i18n.sorting(), link: RouteNames.MENUS_SORTING, index: "brcr-1"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.menus()}</h1>
                    </div>
                    <div className="app__header-right">

                        <button className="button -blue"
                                onClick={onSave}
                        >
                            {i18n.save_sorting()}
                        </button>
                        <a href={RouteNames.MENUS} className="button -bordered_blue">
                            {i18n.cancel()}
                        </a>
                    </div>
                </div>

                {<MenuUlWithLoader loading={menuStrLoading} count={2} level={3} styleli={{listStyleType: "none"}}
                                   notFound={(state.menu || []).length === 0 && !menuStrLoading}
                                   notFoundText={i18n.help_empty_menu()}>
                    <MenuDnd show={show} setShow={setShow} state={state} setState={setState}/>
                </MenuUlWithLoader>}


            </main>
        </section>
    )
}
