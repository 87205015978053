import React, {FunctionComponent, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Skeleton} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {GroupAt, UserAt} from "migration/entities/telegramPoolOfTenant";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useUserContext} from "../../../../../../../hooks/globalContext";
import classes from "./NotificationsTelegram.module.scss";

const BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;
const BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME;

export const NotificationsTelegram: FunctionComponent = () => {
    const navigate = useNavigate();
    const {menu} = useUserContext();
    const {currentUser} = useTypedSelector(state => state.user);
    const {currentLang} = useTypedSelector(state => state.lang);
    const {
        telegramPoolOfTenant,
        isLoadingGetTelegramPoolOfTenant
    } = useTypedSelector(state => state.generalNotification);
    const {
        getTelegramPoolOfTenant,
        blockTgUserInTelegramPool,
        blockTgGroupInTelegramPool,
        unBlockTgUserInTelegramPool,
        unBlockTgGroupInTelegramPool,
    } = useActions();

    const blockUser = (user: UserAt) => {
        blockTgUserInTelegramPool({
            tgChatId: user.chatId,
            tgUserId: user.userId,
            tgUsername: user.username,
        }, {navigate});
    }

    const unBlockUser = (user: UserAt) => {
        unBlockTgUserInTelegramPool({
            tgChatId: user.chatId,
            tgUserId: user.userId,
            tgUsername: user.username,
        }, {navigate});
    }

    const blockGroup = (group: GroupAt) => {
        blockTgGroupInTelegramPool({
            tgChatId: group.chatId,
            tgGroupTitle: group.groupTitle,
        }, {navigate});
    }

    const unBlockGroup = (group: GroupAt) => {
        unBlockTgGroupInTelegramPool({
            tgChatId: group.chatId,
            tgGroupTitle: group.groupTitle,
        }, {navigate});
    }

    useEffect(() => {
        const controller = new AbortController();
        getTelegramPoolOfTenant({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.main}>
            {
                isLoadingGetTelegramPoolOfTenant
                    ?
                    <Skeleton/>
                    :
                    <React.Fragment>
                        <div className="alert alert-info py-4" dangerouslySetInnerHTML={
                            {
                                __html: txt.subscriber_info[currentLang].replaceAll("/sub %v",
                                    "<span color='blue' style='font-weight: bold;cursor: pointer;' onclick='navigator.clipboard.writeText(\"/sub " + currentUser?.org?.subdomain + "\")'>/sub " +
                                    currentUser?.org?.subdomain
                                    + "</span>").replaceAll("%l", "<a href='" + BOT_URL + "' target='_blank'>" + BOT_NAME + "</a>")
                            }
                        }></div>

                        <div className={classes.row}>
                            <div className={classes.row__label}>{`${txt.users[currentLang]}:`}</div>
                            <div className={classes.row__value}>
                                {
                                    telegramPoolOfTenant.activeUsers.map((user) => (
                                        <TgUser
                                            key={user.chatId}
                                            name={user.username}
                                            onBlock={() => blockUser(user)}
                                        />
                                    ))
                                }
                            </div>
                        </div>

                        <div className={classes.row}>
                            <div className={classes.row__label}>{`${txt.groups[currentLang]}:`}</div>
                            <div className={classes.row__value}>
                                {
                                    telegramPoolOfTenant.activeGroups.map((group) => (
                                        <TgUser
                                            key={group.chatId}
                                            name={group.groupTitle}
                                            onBlock={() => blockGroup(group)}
                                        />
                                    ))
                                }
                            </div>
                        </div>

                        <div className={classes.divider}></div>

                        <div className={classes.row}>
                            <div className={classes.row__label}>{`${txt.blocked_users[currentLang]}:`}</div>
                            <div className={classes.row__value}>
                                {
                                    telegramPoolOfTenant.blockedUsers.map((user) => (
                                        <TgUser
                                            key={user.chatId}
                                            name={user.username}
                                            onBlock={() => unBlockUser(user)}
                                        />
                                    ))
                                }
                            </div>
                        </div>

                        <div className={classes.row}>
                            <div className={classes.row__label}>{`${txt.blocked_groups[currentLang]}:`}</div>
                            <div className={classes.row__value}>
                                {
                                    telegramPoolOfTenant.blockedGroups.map((user) => (
                                        <TgUser
                                            key={user.chatId}
                                            name={user.groupTitle}
                                            onBlock={() => unBlockGroup(user)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </React.Fragment>
            }
        </div>
    );
};

interface TgUserProps {
    name: string;
    onBlock?: () => void;
}

const TgUser: FunctionComponent<TgUserProps> = ({name, onBlock}) => {

    return (
        <div className={classes.row__value__item}>
            <div className={classes.row__value__item__text}>{name}</div>
            {!!onBlock && (
                <CloseOutlined
                    onClick={onBlock}
                    className={classes.row__value__item__remove__icon}
                />
            )}
        </div>
    )
}
