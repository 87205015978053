import React, {CSSProperties, FunctionComponent} from "react";
import {useLinks} from "../../hooks/mainPageEditor/links";
import {InputText} from "../input/inputText";
import {TextI18n} from "../input/textI18n";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../loader/formWithLoader";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {ILink, ILinkGroup} from "../../models/link/link";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function LinksForm() {
    const info = useLinks();
    const {i18n} = useGlobalContext();

    const onDragEndLinkGroup = (result: DropResult) => {
        const {destination, source} = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        info.setLinksGroup([...reorder(info.linksGroup, source.index, destination.index)]);
    }

    const onDragEndLinkItem = (result: DropResult) => {
        const {destination, source} = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const linkGroupsCopy = [...info.linksGroup];
        const linkGroupIndex = linkGroupsCopy.findIndex((_, index) => `group-${index}` === source.droppableId);
        linkGroupsCopy[linkGroupIndex].links = [...reorder(linkGroupsCopy[linkGroupIndex].links, source.index, destination.index)];
        info.setLinksGroup(linkGroupsCopy);
    }

    return (
        <FormWithLoader isloading={info.loading} id="dynamicPart" className={"form"}>

            <h3 className="mt-4">{i18n.link_groups()}</h3>

            <DragDropContext onDragEnd={onDragEndLinkGroup}>
                <Droppable droppableId={`group`}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {info.linksGroup?.length > 0 && info.linksGroup.map((linkGroup, linkGroupIndex) => (
                                <LinkGroup
                                    linkGroup={linkGroup}
                                    linkGroupIndex={linkGroupIndex}
                                    key={`group-${linkGroupIndex}`}
                                    onDragEnd={onDragEndLinkItem}
                                    info={info}
                                    i18n={i18n}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className="mt-4">
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                    info.handelAddLinkGroup()
                }}>{i18n.add_group()}</a>
            </div>

            <div className="form__buttons">
                <div className="form__buttons-left">
                    <SubmitButton
                        onSubmit={info.handleSave}
                        label={i18n.save()}
                        isLoading={info.saveLoading}
                        disabled={!info.currentMenuId}
                    />
                </div>
            </div>
        </FormWithLoader>
    )
}

interface LinkGroupProps {
    linkGroup: ILinkGroup;
    linkGroupIndex: number;
    onDragEnd: (result: DropResult) => void;
    info: any;
    i18n: any;
}

const LinkGroup: FunctionComponent<LinkGroupProps> = ({linkGroup, linkGroupIndex, onDragEnd, info, i18n}) => {
    return (
        <Draggable
            index={linkGroupIndex}
            key={`group-${linkGroupIndex}`}
            draggableId={`group-${linkGroupIndex}`}
        >
            {(providedDraggable) => (
                <div
                    data-gutter="15"
                    className={"row mod-select "}
                    key={linkGroupIndex}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                    ref={providedDraggable.innerRef}
                >
                    <div className="col-md-4 left-side-panel">
                        <TextI18n values={linkGroup.groupName} isStaff={info.isStaff}
                                  setValues={(val) => info.handleChangeLinkGroupName(linkGroupIndex, val)}
                                  lang={info.menu.i18n.defaultLang}
                                  allLang={info.menu.i18n.otherLangs || []}/>
                        <a href="#" className="text-danger text-lowercase" onClick={(e) => {
                            e.preventDefault()
                            info.handleDeleteLinkGroup(linkGroupIndex)
                        }}>
                            {i18n.delete_group()}
                        </a>
                    </div>
                    <div className="col-md-8">
                        <div className="mod-option-panel">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId={`group-${linkGroupIndex}`}>
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {linkGroup.links.map((linkItem, linkItemIndex) => (
                                                <LinkItem
                                                    linkItem={linkItem}
                                                    linkItemIndex={linkItemIndex}
                                                    linkGroupIndex={linkGroupIndex}
                                                    key={`group-${linkGroupIndex}-item-${linkItemIndex}`}
                                                    info={info}
                                                    i18n={i18n}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <a href="#" onClick={(e) => {
                                e.preventDefault()
                                info.handleAddLink(linkGroupIndex)
                            }}>
                                <div className={"col-md-8"}>
                                    + {i18n.add_link()}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

interface LinkItemProps {
    linkItem: ILink;
    linkItemIndex: number;
    linkGroupIndex: number;
    info: any;
    i18n: any;
}

const LinkItem: FunctionComponent<LinkItemProps> = ({linkItem, linkItemIndex, linkGroupIndex, info, i18n}) => {
    return (
        <Draggable
            index={linkItemIndex}
            key={`group-${linkGroupIndex}-item-${linkItemIndex}`}
            draggableId={`group-${linkGroupIndex}-item-${linkItemIndex}`}
        >
            {(providedDraggable) => (
                <div
                    data-gutter="15"
                    className={"row mod-item"}
                    key={linkItemIndex}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                    ref={providedDraggable.innerRef}
                >
                    <div className="col-md-6" style={{backgroundColor: "#fff"}}>
                        <TextI18n
                            label={i18n.link_caption()}
                            values={linkItem.caption}
                            isStaff={info.isStaff}
                            setValues={(val) => info.handleChangeLinkCaption(linkGroupIndex, linkItemIndex, val)}
                            lang={info.menu.i18n.defaultLang}
                            allLang={info.menu.i18n.otherLangs || []}
                            placeholder={i18n.link_caption()}
                        />
                    </div>
                    <div className="col-md-5" style={{backgroundColor: "#fff"}}>
                        <InputText
                            onChange={(v) => {
                                info.handelChangeLinkUrl(linkGroupIndex, linkItemIndex, v)
                            }}
                            label={i18n.link()} value={linkItem.url}
                            placeholder={i18n.link()}
                            isValidate={true}
                        />
                    </div>
                    <div className="col-1" style={{backgroundColor: "#fff"}}>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                info.handleDeleteLink(linkGroupIndex, linkItemIndex)
                            }}
                            type="button"
                            className="form__remove-button"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                    fill="#8392A5"></path>
                                <path
                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                    fill="#8392A5"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </Draggable>
    )
}
