import {useGlobalContext} from "../../hooks/globalContext";
import React from "react";

interface IPrintButtonProps {
    previewId: string
    cardId: string
    tableCardId: string
}

export function PrintButton(props:IPrintButtonProps) {
    const {i18n} = useGlobalContext();
    const deepCloneWithStyles = (node: any) => {
        const style = document.defaultView?.getComputedStyle(node, null);
        const clone = node.cloneNode(false);
        if (clone.style && style?.cssText) clone.style.cssText = style.cssText;
        for (let child of node.childNodes) {
            if (child.nodeType === 1) clone.appendChild(deepCloneWithStyles(child));
            else clone.appendChild(child.cloneNode(false));
        }
        return clone;
    };

    const print = () => {
        const printWindow = window.open("", "", "height=400,width=800");
        printWindow?.document.write(
            "<html lang='ru'><head><title>Page Title</title></head><body id='print-body'></body></html>"
        );
        const body = printWindow?.document.getElementById("print-body");
        let refsToPrint = document.getElementById(props.previewId)
        body?.appendChild(deepCloneWithStyles(refsToPrint))
        printWindow?.document.close();
        printWindow?.print();
    };

    const printAsTable = () => {
        const printWindow = window.open("", "", "height=400,width=800");
        printWindow?.document.write(
            `<html lang='ru'><head>
            <style>
                            
                           *,
                *::before,
                *::after {
                  box-sizing: border-box;
                }
                
                /* Убираем внутренние отступы */
                ul[class],
                ol[class] {
                  padding: 0;
                }
                
                /* Убираем внешние отступы */
                body,
                h1,
                h2,
                h3,
                h4,
                p,
                ul[class],
                ol[class],
                li,
                figure,
                figcaption,
                blockquote,
                dl,
                dd {
                  margin: 0;
                }
                
                body {
                  margin: 0;
                  background: #F4F1F8;
                }
                table {
                  border-collapse: collapse; 
                  line-height: 1.1;
                  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
                }
                caption {
                  font-family: annabelle, cursive;
                  font-size: 2em;
                  padding: 10px; 
                 }
                caption:before, caption:after {
                  color: #000000;
                  margin: 0 10px;
                }
                th {
                  padding: 10px; 
                  border: 1px solid #000000;
                }
                td {
                  font-size: 0.8em;
                  padding: 5px 7px;
                  border: 1px solid #000000;
                }
                .first {
                  font-size: 1em;
                  font-weight: bold;
                  text-align: center;
                }
                </style>
            <title>Page Title</title></head><body id='print-body'></body></html>`
        );
        const body = printWindow?.document.getElementById("print-body");
        let refsToPrint = document.getElementById(props.previewId)
        if (!refsToPrint) return

        body?.appendChild(refsToPrint.cloneNode(true))

        let firstCard = printWindow?.document.getElementById(props.cardId)
        if (firstCard && firstCard.style) {
            firstCard.style.display = "none"
        }

        let table = printWindow?.document.getElementById(props.tableCardId)
        if (table && table.style) {
            table.style.display = "block"
        }
        printWindow?.document.close();
        printWindow?.print();
    }

    return (
        <div className="form__buttons mt-4">
            <div className="form__buttons-left">
                <button className="button -bordered_blue" onClick={(e) => {
                    e.preventDefault()
                    print()
                }}>
                    {i18n.print()}
                </button>
                <button className="button -bordered_blue" onClick={(e) => {
                    e.preventDefault()
                    printAsTable()
                }}>
                    {i18n.print_as_table()}
                </button>
            </div>
        </div>
    )
}