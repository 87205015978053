import {
    ReportDashboardTodayRes,
    ReportDashboardDuringPeriodRes,
    ReportDashboardDynamicsRes,
    ReportMostVisitedMenus
} from "../api";

export interface DashboardsState {
    reportToday: ReportDashboardTodayRes;
    reportDuringPeriod: ReportDashboardDuringPeriodRes;
    reportDynamicsVisits: ReportDashboardDynamicsRes;
    reportDynamicsActiveUsers: ReportDashboardDynamicsRes;
    reportDynamicsNewUsers: ReportDashboardDynamicsRes;
    reportMostVisitedMenus: ReportMostVisitedMenus;
    isLoadingReportToday: boolean;
    isLoadingReportDuringPeriod: boolean;
    isLoadingReportDynamics: boolean;
    isLoadingReportDynamicsVisits: boolean;
    isLoadingReportDynamicsActiveUsers: boolean;
    isLoadingReportDynamicsNewUsers: boolean;
    isLoadingReportMostVisitedMenus: boolean;
}

export enum DashboardsActionEnum {
    SET_REPORT_TODAY = "SET_REPORT_TODAY",
    SET_REPORT_DURING_PERIOD = "SET_REPORT_DURING_PERIOD",
    SET_REPORT_DYNAMICS_VISITS = "SET_REPORT_DYNAMICS_VISITS",
    SET_REPORT_DYNAMICS_ACTIVE_USERS = "SET_REPORT_DYNAMICS_ACTIVE_USERS",
    SET_REPORT_DYNAMICS_NEW_USERS = "SET_REPORT_DYNAMICS_NEW_USERS",
    SET_REPORT_MOST_VISITED_MENUS = "SET_REPORT_MOST_VISITED_MENUS",
    SET_IS_LOADING_REPORT_TODAY = "SET_IS_LOADING_REPORT_TODAY",
    SET_IS_LOADING_REPORT_DURING_PERIOD = "SET_IS_LOADING_REPORT_DURING_PERIOD",
    SET_IS_LOADING_REPORT_DYNAMICS = "SET_IS_LOADING_REPORT_DYNAMICS",
    SET_IS_LOADING_REPORT_DYNAMICS_VISITS = "SET_IS_LOADING_REPORT_DYNAMICS_VISITS",
    SET_IS_LOADING_REPORT_DYNAMICS_ACTIVE_USERS = "SET_IS_LOADING_REPORT_DYNAMICS_ACTIVE_USERS",
    SET_IS_LOADING_REPORT_DYNAMICS_NEW_USERS = "SET_IS_LOADING_REPORT_DYNAMICS_NEW_USERS",
    SET_IS_LOADING_REPORT_MOST_VISITED_MENUS = "SET_IS_LOADING_REPORT_MOST_VISITED_MENUS",
}

export interface SetReportTodayAction {
    type: DashboardsActionEnum.SET_REPORT_TODAY;
    payload: ReportDashboardTodayRes;
}

export interface SetReportDuringPeriodAction {
    type: DashboardsActionEnum.SET_REPORT_DURING_PERIOD;
    payload: ReportDashboardDuringPeriodRes;
}

export interface SetReportDynamicsVisitsAction {
    type: DashboardsActionEnum.SET_REPORT_DYNAMICS_VISITS;
    payload: ReportDashboardDynamicsRes;
}

export interface SetReportDynamicsActiveUsersAction {
    type: DashboardsActionEnum.SET_REPORT_DYNAMICS_ACTIVE_USERS;
    payload: ReportDashboardDynamicsRes;
}

export interface SetReportDynamicsNewUsersAction {
    type: DashboardsActionEnum.SET_REPORT_DYNAMICS_NEW_USERS;
    payload: ReportDashboardDynamicsRes;
}

export interface SetReportMostVisitedMenus {
    type: DashboardsActionEnum.SET_REPORT_MOST_VISITED_MENUS;
    payload: ReportMostVisitedMenus;
}

export interface SetIsLoadingReportTodayAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_TODAY;
    payload: boolean;
}

export interface SetIsLoadingReportDuringPeriodAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DURING_PERIOD;
    payload: boolean;
}

export interface SetIsLoadingReportDynamicsAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS;
    payload: boolean;
}

export interface SetIsLoadingReportDynamicsVisitsAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_VISITS;
    payload: boolean;
}

export interface SetIsLoadingReportDynamicsActiveUsersAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_ACTIVE_USERS;
    payload: boolean;
}

export interface SetIsLoadingReportDynamicsNewUsersAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_DYNAMICS_NEW_USERS;
    payload: boolean;
}

export interface SetIsLoadingReportMostVisitedMenusAction {
    type: DashboardsActionEnum.SET_IS_LOADING_REPORT_MOST_VISITED_MENUS;
    payload: boolean;
}

export type DashboardAction =
    SetReportTodayAction |
    SetReportDuringPeriodAction |
    SetReportDynamicsVisitsAction |
    SetReportDynamicsActiveUsersAction |
    SetReportDynamicsNewUsersAction |
    SetReportMostVisitedMenus |
    SetIsLoadingReportTodayAction |
    SetIsLoadingReportDuringPeriodAction |
    SetIsLoadingReportDynamicsAction |
    SetIsLoadingReportDynamicsVisitsAction |
    SetIsLoadingReportDynamicsActiveUsersAction |
    SetIsLoadingReportDynamicsNewUsersAction |
    SetIsLoadingReportMostVisitedMenusAction;
