import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {OrderPageTabs} from "../../components/tabs/orderPageTabs";
import {SubmitButton} from "../../components/input/submitButton";
import {InputDate} from "../../components/input/inputDate";
import {RouteNames} from "../../migration/pages";

export function Reports() {
    const {api, i18n, userInfoLoading} = useGlobalContext()
    const {currentMenuId} = useUserContext()
    useEffect(() => {
        document.title = i18n.reports()
    }, [i18n])

    const [loading, setLoading] = useState(false)

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    function onDownload() {
        api.downloadOrderReports({setLoading}, currentMenuId, startDate.getTime(), endDate.getTime()).finally(() => {
            setLoading(false)
        })
    }

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.reports(), link: RouteNames.REPORTS, index: "bracer-0"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.client_orders()}</h1>
                    </div>
                </div>

                <OrderPageTabs/>
                <div className="row">
                    <div className="col-lg-10 col-xl-6">
                        <div className="default-card">
                            <form className="form">
                                <InputDate
                                    label={i18n.from()}
                                    value={startDate}
                                    start={startDate}
                                    end={endDate}
                                    selectsStart={true}
                                    onChange={setStartDate}
                                    isValidate={true}/>
                                <InputDate
                                    label={i18n.to()}
                                    value={endDate}
                                    start={startDate}
                                    end={endDate}
                                    selectsEnd={true}
                                    onChange={setEndDate}
                                    isValidate={true}/>

                                <div className="form__buttons">
                                    <div className="form__buttons-left">
                                        <SubmitButton onSubmit={onDownload} label={i18n.download()} isLoading={loading}
                                                      disabled={false}/>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
