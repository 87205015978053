import {AxiosResponse} from "axios";
import {Notification} from "migration/entities/notification";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface getNotificationsSettingsReq {
    "menuId": string,
}

export interface cmdToggleEmailNotificationReq {
    "menuId": string,
    "toggle": boolean,
}

export interface cmdSaveEmailReceiversReq {
    "menuId": string,
    "emails": string[],
}

export default class NotificationService {
    static async getNotificationsSettings(request: getNotificationsSettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<Notification> | ErrorResponse>> {
        return $api.post<SuccessResponse<Notification> | ErrorResponse>(ApiRoutes.ORDER_NOTIFICATION_SETTINGS, request, {signal: controller.signal});
    }

    static async cmdToggleEmailNotification(request: cmdToggleEmailNotificationReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.MENU_SETTINGS_TOGGLE_EMAIL_NOTIFICATION, request);
    }

    static async cmdSaveEmailReceivers(request: cmdSaveEmailReceiversReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_EMAIL_RECEIVERS_MENU_SETTINGS_ORDER_NOTIFICATION, request);
    }
}
