import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useOrderEdit} from "../../hooks/order/edit";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {InputText} from "../../components/input/inputText";
import {InputPhone} from "../../components/input/phoneInput";
import {IOrderItem} from "../../models/orders/order";
import {ModalEditItem} from "../../components/modal/modalEditOrder";
import {PriceInput} from "../../components/input/priceInput";
import {TextI18n} from "../../components/input/textI18n";
import {NumberInput} from "../../components/input/numberInput";
import {SubmitButton} from "../../components/input/submitButton";
import {FormWithLoader} from "../../components/loader/formWithLoader";
import {RouteNames} from "../../migration/pages";
import {IOrderSupItem} from "../../models/orderSupItem/orderSupItem";
import {ModalEditSupItem} from "../../components/modal/modalEditSupItem";

export function OrderEdit() {
    const info = useOrderEdit()
    const {i18n, userInfoLoading} = useGlobalContext()
    const {user} = useUserContext()
    useEffect(() => {
        document.title = i18n.order_editing()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.client_orders(), link: RouteNames.ORDERS, index: "brcr-0"},
                    {title: i18n.edit_order(), link: RouteNames.ORDERS_EDIT.replace(":id", ""), index: "brcr-1"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.order_w_number()}: {info.order.number}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-xl-6">
                        <div className="default-card">
                            <FormWithLoader isloading={info.loading} id="order-form" className="form"
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputText label={i18n.name()}
                                                   value={info.order.customerContacts.name}
                                                   onChange={info.onChangeClientName} isValidate={true}/>

                                    </div>
                                    <div className="col-md-6">
                                        <InputPhone label={i18n.phone()}
                                                    value={info.order.customerContacts.phone}
                                                    onChange={info.onChangeClientPhone}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="control">
                                            <label className="custom-radio">
                                                <input type="radio" name="delivery_type"
                                                       checked={info.order.delivery.type === "selfPickupEmpty"}
                                                       onChange={() => info.onChangeDeliveryType("selfPickupEmpty")}
                                                />
                                                <span>{i18n.self_pickup()}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="control">
                                            <label className="custom-radio">
                                                <input type="radio" name="delivery_type"
                                                       checked={info.order.delivery.type === "city"}
                                                       onChange={() => info.onChangeDeliveryType("city")}
                                                />
                                                <span>{i18n.delivery()}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="control">
                                            <label className="custom-radio">
                                                <input type="radio" name="delivery_type"
                                                       checked={info.order.delivery.type === "customPlace"}
                                                       onChange={() => info.onChangeDeliveryType("customPlace")}
                                                />
                                                <span>{i18n.at_place()}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="control">
                                            <label className="custom-radio">
                                                <input type="radio" name="delivery_type"
                                                       checked={info.order.delivery.type === "cinema"}
                                                       onChange={() => info.onChangeDeliveryType("cinema")}
                                                />
                                                <span>{i18n.delivery_in_cinema_hall()}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* in room */}
                                <div className="row"
                                     style={{display: info.order.delivery.type === "customPlace" ? "block" : "none"}}>
                                    <div className="col-md-6">
                                        <div className="control">
                                            <label className="control__label">{i18n.table_number()}</label>
                                            <input className="control__input" type="text"
                                                   value={info.order.delivery.data?.value || ""}
                                                // onChange={(e) => info.onChangePointerLocal(e.currentTarget.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* in cinema */}
                                <div className="row"
                                     style={{display: info.order.delivery.type === "cinema" ? "block" : "none"}}>
                                    <div className="col-md-6">
                                        <InputText label={i18n.cinema_hall_number()}
                                                   value={info.order.delivery.data?.cinemaHallNumber || ""}
                                                   onChange={info.onChangeDeliveryCinemaHallNumber} isValidate={true}/>
                                        <InputText label={i18n.row_number()}
                                                   value={info.order.delivery.data?.rowNumber || ""}
                                                   onChange={info.onChangeDeliveryRowNumber} isValidate={true}/>
                                        <InputText label={i18n.seat_number()}
                                                   value={info.order.delivery.data?.seatNumber || ""}
                                                   onChange={info.onChangeDeliverySeatNumber} isValidate={true}/>
                                    </div>
                                </div>

                                {/* delivery */}
                                <div className="row"
                                     style={{display: info.order.delivery.type === "city" ? "flex" : "none"}}>
                                    <div className="col-md-6">
                                        <InputText label={i18n.home_address()}
                                                   value={info.order.delivery.data?.address || ""}
                                                   onChange={info.onChangeMainAddress} isValidate={true}/>

                                    </div>
                                    <div className="col-md-6">
                                        <InputText label={i18n.other_address()}
                                                   value={info.order.delivery.data?.detailedAddress || ""}
                                                   onChange={info.onChangeDetailAddress} isValidate={true}/>
                                    </div>
                                    <div className="col-md-12">
                                        <InputText label={i18n.delivery_comment()}
                                                   value={info.order.delivery.data?.deliveryComment || ""}
                                                   onChange={info.onChangeDeliveryComment} isValidate={true}
                                                   textArea={true}/>
                                    </div>
                                </div>

                                {info.order?.customerComment && info.order.customerComment.length > 0 ?
                                    <div className="row mb-4">
                                        <div className="col-md-6" style={{display: "flex"}}>
                                            <div className="tx-gray">{i18n.customerComment()}:</div>
                                        </div>
                                        <div className="col-md-6">{info.order?.customerComment}</div>
                                    </div>
                                    : null}


                                <div className="default-table" style={{overflow: "unset"}}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th style={{width: "3%"}}>№</th>
                                            <th style={{width: "52%"}}>{i18n.pl_item()}</th>
                                            <th style={{width: "15%"}}>{i18n.amount_short()}</th>
                                            <th style={{width: "20%"}}>{i18n.cost()}</th>
                                            <th style={{width: "10%"}}>{i18n.actions()}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(info.order.items || []).map((item, index) => {
                                            return (
                                                <OrderItem onDeleteItem={info.onDeleteItem} onEditItem={info.onEditItem}
                                                           item={item} index={index} key={item.id}
                                                           onChangeItemAmount={info.onChangeItemAmount}
                                                />
                                            )
                                        })}
                                        {
                                            (info.order.orderSupItems || []).map((item, index) => {
                                                return (
                                                    <OrderSupItem onDeleteSupItem={info.onDeleteSupItem} onEditSupItem={info.onEditSupItem}
                                                               supItem={item} index={index} key={item.id} onChangeSupItemAmount={info.onChangeSupItemAmount}
                                                    />
                                                )
                                            })
                                        }
                                        <tr>
                                            <td></td>
                                            <td colSpan={4}>
                                                <a href="#"
                                                   onClick={(e) => {
                                                       e.preventDefault()
                                                       info.onAddItem()
                                                   }}
                                                >
                                                    <i className="fa fa-plus mr-3"></i>
                                                    {i18n.add_item()}
                                                </a>
                                            </td>
                                        </tr>

                                        {(info.order.charges || []).map((charge, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td colSpan={2}>
                                                        <TextI18n values={charge.charge.data.name}
                                                                  isStaff={!!user?.isStaff}
                                                                  setValues={(v) => {
                                                                      info.onChangeCharges(index, v, charge.amount)
                                                                  }}
                                                                  lang={info.menu.i18n.defaultLang}
                                                                  allLang={info.menu.i18n.otherLangs || []}
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <PriceInput label={""} value={charge.amount} onChange={(v) => {
                                                            info.onChangeCharges(index, (charge.charge.data.name || {}), v)
                                                        }
                                                        }/>
                                                    </td>
                                                    <td>
                                                        <a href="#" className="mr-2"
                                                           title={i18n.delete()}
                                                           onClick={(e) => {
                                                               e.preventDefault()
                                                               info.onDeleteCharge(index)
                                                           }}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                        <tr>
                                            <td></td>
                                            <td colSpan={4}><a href={"#"} onClick={(e) => {
                                                e.preventDefault()
                                                info.onAddCharges()
                                            }}> <i className="fa fa-plus mr-3"></i>
                                                {i18n.add_additional_payment()}</a></td>
                                        </tr>
                                        <tr className="table-secondary">
                                            <td colSpan={4}>{i18n.total()}</td>
                                            <td className="text-right">{info.allPrice()}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form__buttons mt-4">
                                    <div className="form__buttons-left">
                                        <SubmitButton onSubmit={info.onSave} label={i18n.save()}
                                                      disabled={!info.currentMenuId} isLoading={info.saveLoading}/>
                                        <Link
                                            className="button -bordered_blue form__button" to={RouteNames.ORDERS}>
                                            {i18n.cancel()}
                                        </Link>
                                    </div>
                                </div>
                            </FormWithLoader>
                        </div>
                    </div>
                </div>
                <ModalEditItem items={info.items}
                               setModalInfo={info.setModalItem} modalInfo={info.modalItem}
                               onApproveSaveItem={info.onApproveSaveItem}/>
                <ModalEditSupItem onApproveSaveItem={info.onApproveSaveSupItem} modalInfo={info.modalSupItem} setModalInfo={info.setModalSupItem}/>
            </main>
        </section>
    )
}

interface OrderItemProps {
    item: IOrderItem
    index: number
    onChangeItemAmount: (index: number, amount: number) => void
    onDeleteItem: (index: number) => void
    onEditItem: (orderItem: IOrderItem) => void
}

function OrderItem({
                       item,
                       index,
                       onChangeItemAmount,
                       onDeleteItem,
                       onEditItem
                   }: OrderItemProps) {
    const {menu} = useUserContext()
    const {i18n} = useGlobalContext()

    const unitPrice = Math.ceil(
        (item.variant.price +
            (item.modExtras ? item.modExtras.reduce((sum, extra) => sum + extra.priceDelta, 0) : 0) +
            (item.modSelects ? item.modSelects.reduce((sum, select) => sum + select.priceDelta, 0) : 0)
        ) / 100
    );

    return (
        <tr key={item.id}>
            <td>{index + 1}</td>
            <td>
                <div className="order-item-row__main-item">
                    {item.name[menu.i18n.defaultLang]}
                </div>
            </td>
            <td>
                <div className="control">
                    <NumberInput style={{minWidth: "65px"}} label={""} value={item.quantity} onChange={(n) => {
                        onChangeItemAmount(index, n)
                    }}/>
                </div>
            </td>
            <td>
                <div className="text-center">
                    {i18n.for_a_unit()}: {unitPrice}<br/>
                    {i18n.total()}: {Math.ceil(item.totalPrice / 100) }
                </div>
            </td>
            <td>
                <>
                    <a href="#" className="mr-2"
                       title={i18n.edit()}
                       onClick={(e) => {
                           e.preventDefault()
                           onEditItem(item)
                       }}
                    >
                        <i className="fa fa-pen"></i>
                    </a>
                    <a href="#" className="mr-2"
                       title={i18n.delete()}
                       onClick={(e) => {
                           e.preventDefault()
                           onDeleteItem(index)
                       }}
                    >
                        <i className="fa fa-trash"></i>
                    </a>
                </>
            </td>
        </tr>
    )
}


interface OrderSupItemProps {
    supItem: IOrderSupItem
    index: number
    onChangeSupItemAmount: (index: number, amount: number) => void
    onDeleteSupItem: (index: number) => void
    onEditSupItem: (orderSupItem: IOrderSupItem) => void
}

function OrderSupItem({
                       supItem,
                       index,
                       onChangeSupItemAmount,
                       onDeleteSupItem,
                       onEditSupItem
                   }: OrderSupItemProps) {
    const {menu} = useUserContext()
    const {i18n} = useGlobalContext()
    return (
        <tr key={supItem.id}>
            <td>{index + 1}</td>
            <td>
                <div className="order-item-row__main-item">
                    {supItem.name[menu.i18n.defaultLang]}
                </div>
            </td>
            <td>
                <div className="control">
                    <NumberInput style={{minWidth: "65px"}} label={""} value={supItem.quantity} onChange={(n) => {
                        onChangeSupItemAmount(index, n)
                    }}/>
                </div>
            </td>
            <td>
                <div className="text-center">
                    {i18n.for_a_unit()}: {Math.ceil(supItem.price / 100)}<br/>
                    {i18n.total()}: {Math.ceil(supItem.price * supItem.quantity / 100 )}
                </div>
            </td>
            <td>
                <>
                    <a href="#" className="mr-2"
                       title={i18n.edit()}
                       onClick={(e) => {
                           e.preventDefault()
                           onEditSupItem(supItem)
                       }}
                    >
                        <i className="fa fa-pen"></i>
                    </a>
                    <a href="#" className="mr-2"
                       title={i18n.delete()}
                       onClick={(e) => {
                           e.preventDefault()
                           onDeleteSupItem(index)
                       }}
                    >
                        <i className="fa fa-trash"></i>
                    </a>
                </>
            </td>
        </tr>
    )
}