export type AuthState = {
    isLoading: boolean;
}

export enum AuthActionEnum {
    SET_IS_LOADING = "SET_IS_LOADING",
    LOGIN = "LOGIN",
}

export type SetIsLoadingAction = {
    type: AuthActionEnum.SET_IS_LOADING;
    payload: boolean;
}

export type SetLoginAction = {
    type: AuthActionEnum.LOGIN;
}

export type AuthAction = SetIsLoadingAction | SetLoginAction;
