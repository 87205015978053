import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {
    OrgSettingsEnum,
    SetEncryptedPrivateKeyAction,
    SetIsLoadingLiqpaySettingsAction,
    SetIsLoadingSaveLiqpaySettingsAction,
    SetIsLoadingEncodeLiqpayPrivateKeyAction,
    SetPublicKeyAction
} from "./types";
import {EncodeLiqpayPrivateKey, OrgSettingsService, SaveLiqpayPaymentSettingsReq} from "../api";

export const OrgSettingsActionCreators = {
    setPublicKey: (payload: string): SetPublicKeyAction => ({type: OrgSettingsEnum.SET_PUBLIC_KEY, payload}),
    SetEncryptedPrivateKey: (payload: string): SetEncryptedPrivateKeyAction => ({
        type: OrgSettingsEnum.SET_ENCRYPTED_PRIVATE_KEY,
        payload
    }),
    SetIsLoadingLiqpaySettingsAction: (payload: boolean): SetIsLoadingLiqpaySettingsAction => ({
        type: OrgSettingsEnum.SET_IS_LOADING_LIQPAY_SETTINGS,
        payload
    }),
    SetIsLoadingSaveLiqpaySettingsAction: (payload: boolean): SetIsLoadingSaveLiqpaySettingsAction => ({
        type: OrgSettingsEnum.SET_IS_LOADING_SAVE_LIQPAY_SETTINGS,
        payload
    }),
    SetIsLoadingEncodeLiqpayPrivateKey: (payload: boolean): SetIsLoadingEncodeLiqpayPrivateKeyAction => ({
        type: OrgSettingsEnum.SET_IS_LOADING_ENCODE_LIQPAY_PRIVATE_KEY,
        payload
    }),

    fetchLiqpaySettings: (orgId: string, controller: AbortController, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(OrgSettingsActionCreators.SetIsLoadingLiqpaySettingsAction(true));
            const res = await OrgSettingsService.liqpaySettings({orgId: orgId}, controller);
            if (res.data.success) {
                dispatch(OrgSettingsActionCreators.setPublicKey(res.data.data.publicKey));
                dispatch(OrgSettingsActionCreators.SetEncryptedPrivateKey(res.data.data.encryptedPrivateKey));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(OrgSettingsActionCreators.SetIsLoadingLiqpaySettingsAction(false));
        }
    },

    saveLiqpayPaymentSettings: (request: SaveLiqpayPaymentSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(OrgSettingsActionCreators.SetIsLoadingSaveLiqpaySettingsAction(true));
            const res = await OrgSettingsService.saveLiqpayPaymentSettings(request);
            if (res.data.success) {
                dispatch(OrgSettingsActionCreators.setPublicKey(request.publicKey));
                dispatch(OrgSettingsActionCreators.SetEncryptedPrivateKey(request.encryptedPrivateKey));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(OrgSettingsActionCreators.SetIsLoadingSaveLiqpaySettingsAction(false));
        }
    },

    encodeLiqpayPrivateKey: (request: EncodeLiqpayPrivateKey, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(OrgSettingsActionCreators.SetIsLoadingEncodeLiqpayPrivateKey(true));
            const res = await OrgSettingsService.encodeLiqpayPrivateKey(request);
            if (res.data.success) {
                dispatch(OrgSettingsActionCreators.SetEncryptedPrivateKey(res.data.data.encryptedPrivateKey));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(OrgSettingsActionCreators.SetIsLoadingEncodeLiqpayPrivateKey(false));
        }
    }
}
