import {RkeeperMenu} from "migration/entities/rkeeperMenu";
import {RkeeperAgents} from "migration/entities/rkeeperAgents";
import {RkeeperRestaurant} from "migration/entities/rkeeperRestaurant";
import {RkeeperMenuSettings} from "migration/entities/rkeeperMenuSettings";

export interface RkeeperState {
    rkeeperMenuSettings: RkeeperMenuSettings;
    rkeeperAgents: RkeeperAgents[];
    rkeeperMenu: RkeeperMenu;
    rkeeperRestaurant: RkeeperRestaurant;

    isLoadingApproveRkeeperOrder: boolean;
    isLoadingDeleteMenuRkeeperSettings: boolean;
    isLoadingImportRkeeperProducts: boolean;
    isLoadingRetryRkeeperOrder: boolean;
    isLoadingSaveMenuRkeeperSettings: boolean;
    isLoadingSaveRkeeperProductBindings: boolean;
    isLoadingSaveRkeeperTableBindings: boolean;

    isLoadingGetRkeeperMenuSettings: boolean;
    isLoadingGetRkeeperAgents: boolean;
    isLoadingGetRkeeperMenu: boolean;
    isLoadingGetRkeeperRestaurant: boolean;
}

export enum RkeeperStoreActions {
    SET_RKEEPER_MENU_SETTINGS = "SET_RKEEPER_MENU_SETTINGS",
    SET_RKEEPER_AGENTS = "SET_RKEEPER_AGENTS",
    SET_RKEEPER_MENU = "SET_RKEEPER_MENU",
    SET_RKEEPER_RESTAURANT = "SET_RKEEPER_RESTAURANT",

    SET_IS_LOADING_APPROVE_RKEEPER_ORDER = "SET_IS_LOADING_APPROVE_RKEEPER_ORDER",
    SET_IS_LOADING_DELETE_MENU_RKEEPER_SETTINGS = "SET_IS_LOADING_DELETE_MENU_RKEEPER_SETTINGS",
    SET_IS_LOADING_IMPORT_RKEEPER_PRODUCTS = "SET_IS_LOADING_IMPORT_RKEEPER_PRODUCTS",
    SET_IS_LOADING_RETRY_RKEEPER_ORDER = "SET_IS_LOADING_RETRY_RKEEPER_ORDER",
    SET_IS_LOADING_SAVE_MENU_RKEEPER_SETTINGS = "SET_IS_LOADING_SAVE_MENU_RKEEPER_SETTINGS",
    SET_IS_LOADING_SAVE_RKEEPER_PRODUCT_BINDINGS = "SET_IS_LOADING_SAVE_RKEEPER_PRODUCT_BINDINGS",
    SET_IS_LOADING_SAVE_RKEEPER_TABLE_BINDINGS = "SET_IS_LOADING_SAVE_RKEEPER_TABLE_BINDINGS",

    SET_IS_LOADING_GET_RKEEPER_MENU_SETTINGS = "SET_IS_LOADING_GET_RKEEPER_MENU_SETTINGS",
    SET_IS_LOADING_GET_RKEEPER_AGENTS = "SET_IS_LOADING_GET_RKEEPER_AGENTS",
    SET_IS_LOADING_GET_RKEEPER_MENU = "SET_IS_LOADING_GET_RKEEPER_MENU",
    SET_IS_LOADING_GET_RKEEPER_RESTAURANT = "SET_IS_LOADING_GET_RKEEPER_RESTAURANT",
}

export interface SetRkeeperMenuSettingsAction {
    type: RkeeperStoreActions.SET_RKEEPER_MENU_SETTINGS;
    payload: RkeeperMenuSettings;
}

export interface SetRkeeperAgentsAction {
    type: RkeeperStoreActions.SET_RKEEPER_AGENTS;
    payload: RkeeperAgents[];
}

export interface SetRkeeperMenuAction {
    type: RkeeperStoreActions.SET_RKEEPER_MENU;
    payload: RkeeperMenu;
}

export interface SetRkeeperRestaurantAction {
    type: RkeeperStoreActions.SET_RKEEPER_RESTAURANT;
    payload: RkeeperRestaurant;
}

export interface SetIsLoadingApproveRkeeperOrderAction {
    type: RkeeperStoreActions.SET_IS_LOADING_APPROVE_RKEEPER_ORDER;
    payload: boolean;
}

export interface SetIsLoadingDeleteMenuRkeeperSettingsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_DELETE_MENU_RKEEPER_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingImportRkeeperProductsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_IMPORT_RKEEPER_PRODUCTS;
    payload: boolean;
}

export interface SetIsLoadingRetryRkeeperOrderAction {
    type: RkeeperStoreActions.SET_IS_LOADING_RETRY_RKEEPER_ORDER;
    payload: boolean;
}

export interface SetIsLoadingSaveMenuRkeeperSettingsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_SAVE_MENU_RKEEPER_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingSaveRkeeperProductBindingsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_PRODUCT_BINDINGS;
    payload: boolean;
}

export interface SetIsLoadingSaveRkeeperTableBindingsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_TABLE_BINDINGS;
    payload: boolean;
}

export interface SetIsLoadingGetRkeeperMenuSettingsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU_SETTINGS;
    payload: boolean;
}

export interface SetIsLoadingGetRkeeperAgentsAction {
    type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_AGENTS;
    payload: boolean;
}

export interface SetIsLoadingGetRkeeperMenuAction {
    type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU;
    payload: boolean;
}

export interface SetIsLoadingGetRkeeperRestaurantAction {
    type: RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_RESTAURANT;
    payload: boolean;
}

export type RkeeperStoreAction =
    SetRkeeperMenuSettingsAction |
    SetRkeeperAgentsAction |
    SetRkeeperMenuAction |
    SetRkeeperRestaurantAction |
    SetIsLoadingApproveRkeeperOrderAction |
    SetIsLoadingDeleteMenuRkeeperSettingsAction |
    SetIsLoadingImportRkeeperProductsAction |
    SetIsLoadingRetryRkeeperOrderAction |
    SetIsLoadingSaveMenuRkeeperSettingsAction |
    SetIsLoadingSaveRkeeperProductBindingsAction |
    SetIsLoadingSaveRkeeperTableBindingsAction |
    SetIsLoadingGetRkeeperMenuSettingsAction |
    SetIsLoadingGetRkeeperAgentsAction |
    SetIsLoadingGetRkeeperMenuAction |
    SetIsLoadingGetRkeeperRestaurantAction;
