import {Link} from "react-router-dom";
import Select from 'react-select'
import {options} from "../../utils/options/options";
import {SubmitButton} from "../input/submitButton";
import {InputText} from "../input/inputText";
import {useStaffForm} from "../../hooks/staff/staffForm";
import {useGlobalContext} from "../../hooks/globalContext";

export function StaffForm() {
    const info = useStaffForm()
    const {i18n} = useGlobalContext()
    return (
        <form className="form">
            <InputText label={i18n.name()} value={info.staffInfo.userName}
                       onChange={info.onChangeUserName} isValidate={info.staffInfoValid.userName}/>
            <InputText label={i18n.email()} value={info.staffInfo.email}
                       onChange={info.onChangeEmail} isValidate={info.staffInfoValid.email}/>


            <div className="control">
                <div className="control__label">{i18n.default_language()}</div>
                <Select options={options}
                        placeholder={i18n.choose_language()}
                        onChange={(v) => info.onChangeLang(v ? v.value : "RU")}
                        value={
                            options.filter((v) => v.value === info.staffInfo.lang)
                        }
                />
            </div>

            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.onSave} label={i18n.save()} modal={true} isLoading={info.saveLoading}
                                  disabled={!info.staffInfoValid.email || !info.staffInfoValid.userName}/>
                    <Link to="/staff/managers" className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>
        </form>
    )
}