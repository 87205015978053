import {AppDispatch, RootState} from "migration/app/store";
import {IikoApiKey} from "migration/entities/iikoApiKey";
import {IikoCompanyId, IikoProductId} from "migration/entities/baseAliases";
import {IikoStopLists} from "migration/entities/iikoStopLists";
import {WebhookSettings} from "migration/entities/webhookSettings";
import {IikoPaymentType} from "migration/entities/iikoPaymentType";
import {IikoNomenclature} from "migration/entities/iikoNomenclature";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {IikoMenuSettings} from "migration/entities/iikoMenuSettings";
import {IikoExternalMenu, IikoExternalMenuItem} from "migration/entities/iikoExternalMenu";
import {IikoOrganizations} from "migration/entities/iikoOrganizations";
import {IikoTerminalGroups} from "migration/entities/iikoTerminalGroups";
import {IikoExternalMenuInfo} from "migration/entities/iikoExternalMenuInfo";
import {IikoRestaurantSections} from "migration/entities/iikoRestaurantSections";
import {
    FailedResponseHandler,
    HttpActionCreatorWithResponse,
    httpHandler
} from "migration/shared/lib/http-handler/httpHandler";
import {
    IikoActionEnum,
    SetErrorMsgGetIikoMenuSettingsAction,
    SetErrorMsgGetIikoNomenclatureAction,
    SetErrorMsgGetIikoOrganizationsAction,
    SetErrorMsgGetIikoOrganizationSettingsAction,
    SetErrorMsgGetIikoPaymentTypesAction,
    SetErrorMsgGetIikoRestaurantSectionsAction,
    SetErrorMsgGetIikoStopListsAction,
    SetErrorMsgGetIikoTerminalGroupsAction,
    SetErrorMsgGetWebhookSettingsAction,
    SetIikoApiKeyAction,
    SetIikoCompanyIdAction,
    SetIikoMenuSettingsAction,
    SetIikoNomenclatureAction,
    SetIikoOrganizationsAction,
    SetIikoPaymentTypesAction,
    SetIikoRestaurantSectionsAction,
    SetIikoStopListsAction,
    SetIikoTerminalGroupsAction,
    SetIsLoadingApproveIikoOrderAction,
    SetIsLoadingCancelIikoDeliveryOrderAction,
    SetIsLoadingConfigWebhookSettingsAction,
    SetIsLoadingDeleteMenuIikoSettingsAction,
    SetIsLoadingDeleteOrganizationIikoSettingsAction,
    SetIsLoadingGetIikoApiKeyAction,
    SetIsLoadingGetIikoExternalMenuAction, SetIsLoadingGetIikoExternalMenuInfoAction,
    SetIsLoadingGetIikoMenuSettingsAction,
    SetIsLoadingGetIikoNomenclatureAction,
    SetIsLoadingGetIikoOrganizationsAction,
    SetIsLoadingGetIikoOrganizationSettingsAction,
    SetIsLoadingGetIikoPaymentTypesAction,
    SetIsLoadingGetIikoTerminalGroupsAction,
    SetIsLoadingGetRestaurantSectionsAction,
    SetIsLoadingGetStopListsAction,
    SetIsLoadingGetWebhookSettingsAction,
    SetIsLoadingImportIikoProductsAction,
    SetIsLoadingSaveIikoProductBindingsAction,
    SetIsLoadingSaveIikoTableBindingsAction,
    SetIsLoadingSaveMenuIikoSettingsAction,
    SetIsLoadingSaveOrganizationIikoSettingsAction,
    SetIikoExternalMenuAction,
    SetIikoExternalMenuItemsAction,
    SetIikoExternalMenuInfoAction,
    SetWebhookSettingsAction
} from "./types";
import IikoService, {
    cmdApproveIikoOrderReq,
    cmdCancelIikoDeliveryOrderReq,
    cmdConfigWebhookSettingsReq,
    cmdDeleteMenuIikoSettingsReq,
    cmdDeleteOrganizationIikoSettingsReq,
    cmdImportIikoProductsReq,
    cmdSaveIikoMenuSettingsReq,
    cmdSaveIikoOrganizationSettingsReq,
    cmdSaveIikoProductBindingsReq,
    cmdSaveIikoTableBindingsReq,
    getIikoApiKeyReq,
    getIikoExternalMenuInfoReq,
    getIikoExternalMenuReq,
    getIikoMenuSettingsReq,
    getIikoNomenclatureReq,
    getIikoOrganizationSettingsReq,
    getIikoOrganizationsReq,
    getIikoPaymentTypesReq,
    getIikoRestaurantSectionsReq,
    getIikoStopListsReq,
    getIikoTerminalGroupsReq,
    getWebhookSettingsReq
} from "../api/iikoService";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";

export const IikoActionCreators = {
    setIikoApiKey: (payload: IikoApiKey): SetIikoApiKeyAction => ({
        type: IikoActionEnum.SET_IIKO_API_KEY,
        payload
    }),
    setIikoNomenclature: (payload: IikoNomenclature): SetIikoNomenclatureAction => ({
        type: IikoActionEnum.SET_IIKO_NOMENCLATURE,
        payload
    }),
    setIikoOrganizations: (payload: IikoOrganizations): SetIikoOrganizationsAction => ({
        type: IikoActionEnum.SET_IIKO_ORGANIZATIONS,
        payload
    }),
    setIikoPaymentTypes: (payload: IikoPaymentType): SetIikoPaymentTypesAction => ({
        type: IikoActionEnum.SET_IIKO_PAYMENT_TYPES,
        payload
    }),
    setIikoRestaurantSections: (payload: IikoRestaurantSections): SetIikoRestaurantSectionsAction => ({
        type: IikoActionEnum.SET_IIKO_RESTAURANT_SECTIONS,
        payload
    }),
    setIikoStopLists: (payload: IikoStopLists): SetIikoStopListsAction => ({
        type: IikoActionEnum.SET_IIKO_STOP_LISTS,
        payload
    }),
    setIikoTerminalGroups: (payload: IikoTerminalGroups): SetIikoTerminalGroupsAction => ({
        type: IikoActionEnum.SET_IIKO_TERMINAL_GROUPS,
        payload
    }),
    setWebhookSettings: (payload: WebhookSettings): SetWebhookSettingsAction => ({
        type: IikoActionEnum.SET_WEBHOOK_SETTINGS,
        payload
    }),
    setIikoMenuSettings: (payload: IikoMenuSettings): SetIikoMenuSettingsAction => ({
        type: IikoActionEnum.SET_IIKO_MENU_SETTINGS,
        payload
    }),
    setIikoCompanyId: (payload: IikoCompanyId): SetIikoCompanyIdAction => ({
        type: IikoActionEnum.SET_IIKO_COMPANY_ID,
        payload
    }),
    setIikoExternalMenu: (payload: IikoExternalMenu): SetIikoExternalMenuAction => ({
        type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU,
        payload
    }),
    setIikoExternalMenuItems: (payload: {[key: IikoProductId]: IikoExternalMenuItem}): SetIikoExternalMenuItemsAction => ({
        type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU_ITEMS,
        payload
    }),
    setIikoExternalMenuInfo: (payload: IikoExternalMenuInfo): SetIikoExternalMenuInfoAction => ({
        type: IikoActionEnum.SET_IIKO_EXTERNAL_MENU_INFO,
        payload
    }),

    setErrorMsgGetIikoNomenclature: (payload: string): SetErrorMsgGetIikoNomenclatureAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_NOMENCLATURE,
        payload
    }),
    setErrorMsgGetIikoOrganizations: (payload: string): SetErrorMsgGetIikoOrganizationsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATIONS,
        payload
    }),
    setErrorMsgGetIikoPaymentTypes: (payload: string): SetErrorMsgGetIikoPaymentTypesAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_PAYMENT_TYPES,
        payload
    }),
    setErrorMsgGetIikoRestaurantSections: (payload: string): SetErrorMsgGetIikoRestaurantSectionsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_RESTAURANT_SECTIONS,
        payload
    }),
    setErrorMsgGetIikoStopLists: (payload: string): SetErrorMsgGetIikoStopListsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_STOP_LISTS,
        payload
    }),
    setErrorMsgGetIikoTerminalGroups: (payload: string): SetErrorMsgGetIikoTerminalGroupsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_TERMINAL_GROUPS,
        payload
    }),
    setErrorMsgGetWebhookSettings: (payload: string): SetErrorMsgGetWebhookSettingsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_WEBHOOK_SETTINGS,
        payload
    }),
    setErrorMsgGetIikoMenuSettings: (payload: string): SetErrorMsgGetIikoMenuSettingsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_MENU_SETTINGS,
        payload
    }),
    setErrorMsgGetIikoOrganizationSettings: (payload: string): SetErrorMsgGetIikoOrganizationSettingsAction => ({
        type: IikoActionEnum.SET_ERROR_MSG_IIKO_ORGANIZATION_SETTINGS,
        payload
    }),

    setIsLoadingGetIikoApiKey: (payload: boolean): SetIsLoadingGetIikoApiKeyAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_API_KEY,
        payload
    }),
    setIsLoadingGetIikoNomenclature: (payload: boolean): SetIsLoadingGetIikoNomenclatureAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_NOMENCLATURE,
        payload
    }),
    setIsLoadingGetIikoOrganizations: (payload: boolean): SetIsLoadingGetIikoOrganizationsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATIONS,
        payload
    }),
    setIsLoadingGetIikoPaymentTypes: (payload: boolean): SetIsLoadingGetIikoPaymentTypesAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_PAYMENT_TYPES,
        payload
    }),
    setIsLoadingGetIikoRestaurantSections: (payload: boolean): SetIsLoadingGetRestaurantSectionsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_RESTAURANT_SECTIONS,
        payload
    }),
    setIsLoadingGetIikoStopLists: (payload: boolean): SetIsLoadingGetStopListsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_STOP_LISTS,
        payload
    }),
    setIsLoadingGetIikoTerminalGroups: (payload: boolean): SetIsLoadingGetIikoTerminalGroupsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_TERMINAL_GROUPS,
        payload
    }),
    setIsLoadingGetWebhookSettings: (payload: boolean): SetIsLoadingGetWebhookSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_WEBHOOK_SETTINGS,
        payload
    }),
    setIsLoadingGetIikoMenuSettings: (payload: boolean): SetIsLoadingGetIikoMenuSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_MENU_SETTINGS,
        payload
    }),
    setIsLoadingGetIikoOrganizationSettings: (payload: boolean): SetIsLoadingGetIikoOrganizationSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_ORGANIZATION_SETTINGS,
        payload
    }),

    setIsLoadingConfigWebhookSettings: (payload: boolean): SetIsLoadingConfigWebhookSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_CONFIG_WEBHOOK_SETTINGS,
        payload
    }),
    setIsLoadingDeleteMenuIikoSettings: (payload: boolean): SetIsLoadingDeleteMenuIikoSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_DELETE_MENU_IIKO_SETTINGS,
        payload
    }),
    setIsLoadingDeleteOrganizationIikoSettings: (payload: boolean): SetIsLoadingDeleteOrganizationIikoSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_DELETE_ORGANIZATION_IIKO_SETTINGS,
        payload
    }),
    setIsLoadingImportIikoProducts: (payload: boolean): SetIsLoadingImportIikoProductsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_IMPORT_IIKO_PRODUCTS,
        payload
    }),
    setIsLoadingSaveIikoProductBindings: (payload: boolean): SetIsLoadingSaveIikoProductBindingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_PRODUCT_BINDINGS,
        payload
    }),
    setIsLoadingSaveIikoTableBindings: (payload: boolean): SetIsLoadingSaveIikoTableBindingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_SAVE_IIKO_TABLE_BINDINGS,
        payload
    }),
    setIsLoadingSaveMenuIikoSettings: (payload: boolean): SetIsLoadingSaveMenuIikoSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_SAVE_MENU_IIKO_SETTINGS,
        payload
    }),
    setIsLoadingSaveOrganizationIikoSettings: (payload: boolean): SetIsLoadingSaveOrganizationIikoSettingsAction => ({
        type: IikoActionEnum.SET_IS_LOADING_SAVE_ORGANIZATION_IIKO_SETTINGS,
        payload
    }),
    setIsLoadingApproveIikoOrder: (payload: boolean): SetIsLoadingApproveIikoOrderAction => ({
        type: IikoActionEnum.SET_IS_LOADING_APPROVE_IIKO_ORDER,
        payload
    }),
    setIsLoadingCancelIikoDeliveryOrder: (payload: boolean): SetIsLoadingCancelIikoDeliveryOrderAction => ({
        type: IikoActionEnum.SET_IS_LOADING_CANCEL_IIKO_DELIVERY_ORDER,
        payload
    }),
    setIsLoadingGetIikoExternalMenu: (payload: boolean): SetIsLoadingGetIikoExternalMenuAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU,
        payload
    }),
    setIsLoadingGetIikoExternalMenuInfo: (payload: boolean): SetIsLoadingGetIikoExternalMenuInfoAction => ({
        type: IikoActionEnum.SET_IS_LOADING_GET_IIKO_EXTERNAL_MENU_INFO,
        payload
    }),

    fetchIikoApiKey: (request: getIikoApiKeyReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoApiKey(true));
            const res = await IikoService.getIikoApiKey(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoApiKey(res.data.data));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoApiKey(false));
        }
    },

    fetchIikoNomenclature: (request: getIikoNomenclatureReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoNomenclature(true));
            const res = await IikoService.getIikoNomenclature(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoNomenclature(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoNomenclature({} as IikoNomenclature));
                dispatch(IikoActionCreators.setErrorMsgGetIikoNomenclature(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoNomenclature(false));
        }
    },

    fetchIikoOrganizations: (request: getIikoOrganizationsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoOrganizations(true));
            const res = await IikoService.getIikoOrganizations(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoOrganizations(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoOrganizations({} as IikoOrganizations));
                dispatch(IikoActionCreators.setErrorMsgGetIikoOrganizations(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoOrganizations(false));
        }
    },

    fetchIikoPaymentTypes: (request: getIikoPaymentTypesReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoPaymentTypes(true));
            const res = await IikoService.getIikoPaymentTypes(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoPaymentTypes(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoPaymentTypes({} as IikoPaymentType));
                dispatch(IikoActionCreators.setErrorMsgGetIikoPaymentTypes(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoPaymentTypes(false));
        }
    },

    fetchIikoRestaurantSections: (request: getIikoRestaurantSectionsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoRestaurantSections(true));
            const res = await IikoService.getIikoRestaurantSections(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoRestaurantSections(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoRestaurantSections({} as IikoRestaurantSections));
                dispatch(IikoActionCreators.setErrorMsgGetIikoRestaurantSections(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoRestaurantSections(false));
        }
    },

    fetchIikoStopLists: (request: getIikoStopListsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoStopLists(true));
            const res = await IikoService.getIikoStopLists(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoStopLists(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoStopLists({} as IikoStopLists));
                dispatch(IikoActionCreators.setErrorMsgGetIikoStopLists(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoStopLists(false));
        }
    },

    fetchIikoTerminalGroups: (request: getIikoTerminalGroupsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoTerminalGroups(true));
            const res = await IikoService.getIikoTerminalGroups(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoTerminalGroups(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoTerminalGroups({} as IikoTerminalGroups));
                dispatch(IikoActionCreators.setErrorMsgGetIikoTerminalGroups(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoTerminalGroups(false));
        }
    },

    fetchWebhookSettings: (request: getWebhookSettingsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetWebhookSettings(true));
            const res = await IikoService.getWebhookSettings(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setWebhookSettings(res.data.data));
            } else {
                dispatch(IikoActionCreators.setWebhookSettings({} as WebhookSettings));
                dispatch(IikoActionCreators.setErrorMsgGetWebhookSettings(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetWebhookSettings(false));
        }
    },

    fetchIikoMenuSettings: (request: getIikoMenuSettingsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoMenuSettings(true));
            const res = await IikoService.getIikoMenuSettings(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoMenuSettings(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoMenuSettings({} as IikoMenuSettings));
                dispatch(IikoActionCreators.setErrorMsgGetIikoMenuSettings(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoMenuSettings(false));
        }
    },

    fetchIikoOrganizationSettings: (request: getIikoOrganizationSettingsReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoOrganizationSettings(true));
            const res = await IikoService.getIikoOrganizationSettings(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoCompanyId(res.data.data.iikoCompanyId));
            } else {
                dispatch(IikoActionCreators.setIikoCompanyId(''));
                dispatch(IikoActionCreators.setErrorMsgGetIikoOrganizationSettings(res.data?.error?.message));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: true,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoOrganizationSettings(false));
        }
    },

    fetchIikoExternalMenu: (request: getIikoExternalMenuReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoExternalMenu(true));
            const res = await IikoService.getIikoExternalMenu(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoExternalMenu(res.data.data));
                const iikoExternalMenuItems: {[key: IikoProductId]: IikoExternalMenuItem} = {};
                res.data.data?.itemCategories?.forEach((category) => {
                    category.items.forEach((item) => {
                        iikoExternalMenuItems[item.itemId] = item;
                    });
                });
                dispatch(IikoActionCreators.setIikoExternalMenuItems(iikoExternalMenuItems));
            } else {
                dispatch(IikoActionCreators.setIikoExternalMenu({} as IikoExternalMenu));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoExternalMenu(false));
        }
    },

    fetchIikoExternalMenuInfo: (request: getIikoExternalMenuInfoReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingGetIikoExternalMenuInfo(true));
            const res = await IikoService.getIikoExternalMenuInfo(request, controller);
            if (res.data.success) {
                dispatch(IikoActionCreators.setIikoExternalMenuInfo(res.data.data));
            } else {
                dispatch(IikoActionCreators.setIikoExternalMenuInfo({} as IikoExternalMenuInfo));
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingGetIikoExternalMenuInfo(false));
        }
    },

    // ==================================================== COMMANDS ===================================================

    configWebhookSettings: (request: cmdConfigWebhookSettingsReq, getRequest: getWebhookSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        // const {currentLang} = getState().lang;
        // try {
        //     dispatch(IikoActionCreators.setIsLoadingConfigWebhookSettings(true));
        //     const res = await IikoService.cmdConfigWebhookSettings(request);
        //     if (res.data.success) {
        //         await IikoActionCreators.fetchWebhookSettings(getRequest, new AbortController(), navigationCallback)(dispatch, getState)
        //         Notify.Success({title: txt.settings_webhooks_successfully_updated[currentLang], message: ""})
        //     } else {
        //         FailedResponseHandler({
        //             message: res.data?.error?.message,
        //             httpStatus: res.status,
        //         });
        //     }
        // } catch (e: any) {
        //     httpHandler({
        //         error: e,
        //         httpStatus: e?.response?.status,
        //         dispatch: dispatch,
        //         currentLang: currentLang,
        //         navigateCallback: navigationCallback,
        //     });
        // } finally {
        //     dispatch(IikoActionCreators.setIsLoadingConfigWebhookSettings(false));
        // }

        const {currentLang} = getState().lang;
        await HttpActionCreatorWithResponse(dispatch, currentLang, IikoService.cmdConfigWebhookSettings(request), {
            loadingCallback: IikoActionCreators.setIsLoadingConfigWebhookSettings,
            successCallback: async () => {
                await IikoActionCreators.fetchWebhookSettings(getRequest, new AbortController(), navigationCallback)(dispatch, getState)
                Notify.Success({title: txt.settings_webhooks_successfully_updated[currentLang], message: ""})
            },
            navigateCallback: navigationCallback,
        });
    },

    deleteMenuIikoSettings: (request: cmdDeleteMenuIikoSettingsReq, getRequest: getIikoMenuSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingDeleteMenuIikoSettings(true));
            const res = await IikoService.cmdDeleteMenuIikoSettings(request);
            if (res.data.success) {
                dispatch(IikoActionCreators.setWebhookSettings({} as WebhookSettings));
                Notify.Success({title: txt.iiko_menu_settings_deleted[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingDeleteMenuIikoSettings(false));
        }
    },

    deleteOrganizationIikoSettings: (request: cmdDeleteOrganizationIikoSettingsReq, getRequest: getIikoOrganizationSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingDeleteOrganizationIikoSettings(true));
            const res = await IikoService.cmdDeleteOrganizationIikoSettings(request);
            if (res.data.success) {
                // await IikoActionCreators.fetchIikoOrganizationSettings(getRequest, new AbortController(), navigationCallback)(dispatch, getState)
                dispatch(IikoActionCreators.setIikoMenuSettings({} as IikoMenuSettings));
                dispatch(IikoActionCreators.setIikoCompanyId(null as unknown as string));
                dispatch(IikoActionCreators.setIikoTerminalGroups({} as IikoTerminalGroups));
                dispatch(IikoActionCreators.setIikoPaymentTypes({} as IikoPaymentType));
                dispatch(IikoActionCreators.setIikoOrganizations({} as IikoOrganizations));
                dispatch(IikoActionCreators.setWebhookSettings({} as WebhookSettings));
                dispatch(IikoActionCreators.setIikoApiKey({} as IikoApiKey));
                dispatch(IikoActionCreators.setIikoRestaurantSections({} as IikoRestaurantSections));
                dispatch(IikoActionCreators.setIikoNomenclature({} as IikoNomenclature));
                dispatch(IikoActionCreators.setIikoStopLists({} as IikoStopLists));
                Notify.Success({title: txt.iiko_api_key_deleted[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingDeleteOrganizationIikoSettings(false));
        }
    },

    importIikoProducts: (request: cmdImportIikoProductsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingImportIikoProducts(true));
            const res = await IikoService.cmdImportIikoProducts(request);
            if (res.data.success) {
                // await IikoActionCreators.fetchIikoProducts(request, new AbortController(), navigationCallback)(dispatch, getState)
                if (!!res.data.data?.alreadyBoundProductIds) {
                    res.data.data?.alreadyBoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                if (!!res.data.data?.notFoundCategoryIds) {
                    res.data.data?.notFoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                if (!!res.data.data?.notFoundProductIds) {
                    res.data.data?.notFoundProductIds?.forEach(msg => {
                        Notify.Warning({title: msg, message: ""});
                    });
                    return;
                }

                await IikoActionCreators.fetchIikoMenuSettings({menuId: getState().user?.currentMenuId} as getIikoMenuSettingsReq, new AbortController(), navigationCallback)(dispatch, getState);
                Notify.Success({title: txt.iiko_products_imported[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingImportIikoProducts(false));
        }
    },

    saveIikoProductBindings: (request: cmdSaveIikoProductBindingsReq, navigationCallback: NavigateCallback, bind: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingSaveIikoProductBindings(true));
            const res = await IikoService.cmdSaveIikoProductBindings(request);
            if (res.data.success) {
                if (bind) {
                    Notify.Success({title: txt.create_iiko_product_binding_success[currentLang], message: ""});
                } else {
                    Notify.Success({title: txt.delete_iiko_product_binding_success[currentLang], message: ""});
                }
                await IikoActionCreators.fetchIikoMenuSettings({menuId: getState().user?.currentMenuId} as getIikoMenuSettingsReq, new AbortController(), navigationCallback)(dispatch, getState);
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingSaveIikoProductBindings(false));
        }
    },

    saveIikoTableBindings: (request: cmdSaveIikoTableBindingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingSaveIikoTableBindings(true));
            const res = await IikoService.cmdSaveIikoTableBindings(request);
            if (res.data.success) {
                // await IikoActionCreators.fetchIikoMenuSettings({iikoCompanyId: request.iikoCompanyId}, new AbortController(), navigationCallback)(dispatch, getState)
                Notify.Success({title: txt.create_iiko_table_binding_success[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingSaveIikoTableBindings(false));
        }
    },

    saveIikoMenuSettings: (request: cmdSaveIikoMenuSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingSaveMenuIikoSettings(true));
            const res = await IikoService.cmdSaveMenuIikoSettings(request);
            if (res.data.success) {
                // await IikoActionCreators.fetchIikoMenuSettings({iikoCompanyId: request.iikoCompanyId}, new AbortController(), navigationCallback)(dispatch, getState)
                Notify.Success({title: txt.iiko_menu_settings_saved[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingSaveMenuIikoSettings(false));
        }
    },

    saveIikoOrganizationSettings: (request: cmdSaveIikoOrganizationSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingSaveOrganizationIikoSettings(true));
            const res = await IikoService.cmdSaveOrganizationIikoSettings(request);
            if (res.data.success) {
                // await IikoActionCreators.fetchIikoOrganizationSettings({iikoCompanyId: request.iikoCompanyId}, new AbortController(), navigationCallback)(dispatch, getState)
                Notify.Success({title: txt.iiko_org_settings_saved[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingSaveOrganizationIikoSettings(false));
        }
    },

    approveIikoOrder: (request: cmdApproveIikoOrderReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingApproveIikoOrder(true));
            const res = await IikoService.cmdApproveIikoOrders(request);
            if (res.data.success) {
                Notify.Success({title: txt.iiko_order_approved[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingApproveIikoOrder(false));
        }
    },

    retryIikoOrder: (request: cmdApproveIikoOrderReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(IikoActionCreators.setIsLoadingApproveIikoOrder(true));
            const res = await IikoService.cmdRetryIikoOrders(request);
            if (res.data.success) {
                Notify.Success({title: txt.iiko_order_retried[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingApproveIikoOrder(false));
        }
    },

    cancelIikoDeliveryOrder: (request: cmdCancelIikoDeliveryOrderReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentMenuId} = getState().user;
        try {
            dispatch(IikoActionCreators.setIsLoadingCancelIikoDeliveryOrder(true));
            const res = await IikoService.cmdCancelIikoDeliveryOrder(request);
            if (res.data.success) {
                localStorage.setItem(`${currentMenuId}-${request.orderId}`, JSON.stringify({expiresAt: new Date().getTime() + (1000 * 60 * 5)}))
                Notify.Success({title: txt.iiko_delivery_order_cancelled[currentLang], message: ""});
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(IikoActionCreators.setIsLoadingCancelIikoDeliveryOrder(false));
        }
    },
}
