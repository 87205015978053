import {createContext, useContext} from "react"
import {ICurrentUserInfo} from "../models/user/userLogin";
import KamiService from "../api/kamiService";
import {ICurrentMenuInfo} from "../models/menu/menu";
import {IOrganizationName} from "../models/org/name";
import {IPublishSSEData} from "../models/sse/publish";
import {IIKOCompany} from "../models/iiko/company";
import {iikoOrganizationBinding} from "../models/iiko/org";
import {TXT} from "../txtMap/embedded/txt";

//----------------------------------------
// Lang context
//----------------------------------------
export type LangKey = "RU" | "KZ" | "EN" | "TR" | "AZ" | "CH" | "JP" | "AR" | "UZ" | "ID" | "KY" | "UK";

export type GlobalContent = {
    api: KamiService
    setLang: (c: LangKey) => void
    userInfoLoading: boolean
    orgsNameLoading: boolean
    menusInfoLoading: boolean
    iikoInfoLoading: boolean
    lastOrder: string | null
    i18n: TXT
}
export const MyGlobalContext = createContext<GlobalContent>({
    api: new KamiService(() => {
    }, "RU", new TXT("RU")),
    setLang: () => {
    },
    userInfoLoading: false,
    orgsNameLoading: false,
    menusInfoLoading: false,
    iikoInfoLoading: false,
    lastOrder: null,
    i18n: new TXT("RU")
})
export const useGlobalContext = () => useContext(MyGlobalContext)

//----------------------------------------
// User context
//----------------------------------------
export type UserContent = {
    user: ICurrentUserInfo | null
    menu: ICurrentMenuInfo
    setMenu: (menu: ICurrentMenuInfo) => void
    setUser: (c: ICurrentUserInfo | null) => void
    currentMenuId: string,
    setCurrentMenuId: (c: string) => void
    reloadCurrentUser: () => void
    setOrgInfo: (name: string, subdomain: string, address: string, defaultLang: LangKey, logo: string | null, customDomain: string | null) => void,
    iikoCompany: string | null,
    setIikoCompany: (c: string) => void
    iikoBindOrg: string,
    iikoActiveGeneral: boolean
    iikoActiveMenu: boolean
}
export const UserContext = createContext<UserContent>({
    user: null, // set a default value
    menu: {} as ICurrentMenuInfo, // set a default value
    setMenu: () => {
    },
    currentMenuId: "",
    setCurrentMenuId: () => {
    },
    setUser: () => {
    },
    reloadCurrentUser: () => {
    },
    setOrgInfo: () => {
    },
    iikoCompany: null,
    setIikoCompany: () => {
    },
    iikoBindOrg: "",
    iikoActiveGeneral: false,
    iikoActiveMenu: false,
})
export const useUserContext = () => useContext(UserContext)

const callbackSignal = () => {
    const signal = true;

    if (!signal) {
        return false;
    }

    return true;
}

//----------------------------------------
// Staff context
//----------------------------------------
export type StaffContent = {
    addOrg: (orgName: IOrganizationName) => void
    removeOrg: (orgId: string) => void
    orgNames: IOrganizationName[]
    changeOrg: (orgId: string) => void
    onStaffPage: () => void
}
export const StaffContext = createContext<StaffContent>({
    addOrg: () => {
    },
    removeOrg: () => {
    },
    changeOrg: () => {
    },
    orgNames: [],
    onStaffPage: () => {
    }
})
export const useStaffContext = () => useContext(StaffContext)

//----------------------------------------
// SSE context
//----------------------------------------
export type SSEContent = {
    menuPublishSSE: IPublishSSEData
    setMenuPublishSSE: (c: IPublishSSEData) => void
}
export const SSEContext = createContext<SSEContent>({
    menuPublishSSE: {},
    setMenuPublishSSE: () => {
    },
})
export const useSSEContext = () => useContext(SSEContext)
