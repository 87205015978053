import React from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
    page: number,
    withdraw: number,
    all: number,
    setPage: (n: number) => void
}

export function Pagination({page, withdraw, all, setPage}:PaginationProps) {
    const handlePageClick = (n: number) => {
        setPage(n)
    };
    return (
        <div className="mt-lg-3 d-flex align-items-center justify-content-between">
            <div className="results-text d-none d-lg-block d-sm-block">
                {page * withdraw + 1} — {(page + 1) * withdraw < all ? (page + 1) * withdraw : all} / {all.toString()}
            </div>
            <div className="paginator">
                <ReactPaginate
                    forcePage={page}
                    breakLabel={"..."}
                    nextLabel={""}
                    previousLabel={""}
                    onPageChange={(n) => {
                        handlePageClick(n.selected)
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(all / withdraw)}
                    className={"paginator__inner"}
                    pageClassName={"paginator__item"}
                    pageLinkClassName={"paginator__link"}
                    activeClassName={"paginator__item is-active"}
                    breakClassName={"paginator__item ellipsis"}
                />
            </div>
        </div>
    )
}