import {AxiosResponse} from "axios";
import {KamiMenuStruct} from "migration/entities/kamiMenuStruct";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface getMenuStructForMenuPageReq {
    menuId: string;
}

export default class KamiMenuService {
    static getMenuStructForMenuPage(request: getMenuStructForMenuPageReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<KamiMenuStruct> | ErrorResponse>> {
        return $api.post<SuccessResponse<KamiMenuStruct> | ErrorResponse>(ApiRoutes.MENU_STRUCT_FOR_MENU_PAGE, request, {signal: controller.signal});
    }
}
