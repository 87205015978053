import {GeneralNotificationAction, GeneralNotificationActionEnum, GeneralNotificationState} from "./types";

const initialState: GeneralNotificationState = {
    telegramPoolOfTenant: {
        activeUsers: [],
        activeGroups: [],
        blockedUsers: [],
        blockedGroups: [],
    },
    receiverLists: [],
    isLoadingGetTelegramPoolOfTenant: true,
    isLoadingGetAllReceiverLists: true,
}

export default function generalNotificationReducer(state = initialState, action: GeneralNotificationAction): GeneralNotificationState {
    switch (action.type) {
        case GeneralNotificationActionEnum.SET_TELEGRAM_POOL_OF_TENANT:
            return {...state, telegramPoolOfTenant: action.payload}
        case GeneralNotificationActionEnum.SET_RECEIVER_LISTS:
            return {...state, receiverLists: action.payload}
        case GeneralNotificationActionEnum.SET_IS_LOADING_GET_TELEGRAM_POOL_OF_TENANT:
            return {...state, isLoadingGetTelegramPoolOfTenant: action.payload}
        case GeneralNotificationActionEnum.SET_IS_LOADING_GET_ALL_RECEIVER_LISTS:
            return {...state, isLoadingGetAllReceiverLists: action.payload}
        default:
            return state
    }
}
