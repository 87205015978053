import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {useGlobalContext} from "../../hooks/globalContext";
import {CopyMenuForm} from "../../components/form/copyMenu";
import {useParams} from "react-router-dom";

export function CopyMenuToAnotherOrg() {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.copy_menu_to_another_org()
    }, [i18n])
    let {id} = useParams()
    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={[{
                    title: i18n.copy_menu(),
                    link: `/staff/copy-menu-to-another-org/${id}`,
                    index: "brcr-1"
                }]}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.copy_menu()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            <CopyMenuForm backUrl={"/staff/clients"}/>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}