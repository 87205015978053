import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Select} from 'antd'
import {isEmpty} from "lodash";
import {IikoOrgId} from "migration/entities/baseAliases";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {CustomRadio} from "../../../../../../../components/input/customRadio";
import {SubmitButton} from "../../../../../../../components/input/submitButton";
import {FormWithLoader} from "../../../../../../../components/loader/formWithLoader";
import {useGlobalContext, useUserContext} from "../../../../../../../hooks/globalContext";

export function IikoMenuSettings() {
    const {i18n} = useGlobalContext()

    const navigate = useNavigate();
    const {currentMenuId, user} = useUserContext();
    const {
        iikoCompanyId,
        iikoOrganizations,
        iikoMenuSettings,
        iikoTerminalGroups,
        iikoPaymentTypes,
        iikoExternalMenuInfo,
        isLoadingSaveMenuIikoSettings,
        isLoadingDeleteMenuIikoSettings,
        isLoadingGetIikoTerminalGroups,
        isLoadingGetIikoPaymentTypes,
        isLoadingGetIikoExternalMenuInfo,
        isLoadingGetIikoMenuSettings,
        isLoadingGetIikoOrganizations,
    } = useTypedSelector(state => state.iiko);
    const {
        fetchIikoMenuSettings,
        fetchIikoOrganizations,
        fetchIikoTerminalGroups,
        fetchIikoPaymentTypes,
        fetchIikoExternalMenuInfo,
        deleteMenuIikoSettings,
        saveIikoMenuSettings,
    } = useActions();

    const [currentIikoOrg, setCurrentIikoOrg] = useState<IikoOrgId>("");
    const [selectedTerminalGroup, setSelectedTerminalGroup] = useState<string>("");
    const [selectedPaymentType, setSelectedPaymentType] = useState<string>("");
    const [changesHaveBeenMade, setChangesHaveBeenMade] = useState<boolean>(false);
    const [isManualApproveChecked, setIsManualApproveChecked] = useState<boolean>(false);
    const [mustSendDeliveryOrdersForCouriers, setMustSendDeliveryOrdersForCouriers] = useState<boolean>(false);
    const [isStopListsIgnored, setIsStopListsIgnored] = useState<boolean>(false);
    const [isIikoV2, setIsIikoV2] = useState<boolean>(false);
    const [selectedExternalMenu, setSelectedExternalMenu] = useState<string>("");
    const [selectedPriceCategory, setSelectedPriceCategory] = useState<string>("");

    const terminalGroupsOptions = useMemo(() => {
        const terminalGroups = iikoTerminalGroups?.terminalGroups?.[currentIikoOrg]?.map(item => ({
            value: item?.id,
            label: item?.data?.name
        })) || [];

        if (!isEmpty(iikoTerminalGroups)) {
            return [{value: "", label: i18n.select_terminal_group()}, ...terminalGroups];
        }
        return [];
    }, [iikoTerminalGroups, iikoMenuSettings, user?.org?.id, i18n, currentIikoOrg]);

    const iikoPaymentTypesOptions = useMemo(() => {
        const paymentTypes = iikoPaymentTypes?.paymentTypes?.[currentIikoOrg]?.[selectedTerminalGroup]?.map(item => ({
            value: item?.id,
            label: item?.data?.name
        })) || [];

        if (!isEmpty(iikoPaymentTypes)) {
            return [{value: "", label: i18n.select_payment_type()}, ...paymentTypes];
        }
        return [];
    }, [iikoPaymentTypes, iikoMenuSettings, user?.org?.id, i18n, selectedTerminalGroup, currentIikoOrg]);

    const handleSave = async () => {
        if (currentIikoOrg === "") {
            Notify.Warning({title: i18n.select_organization(), message: ""});
            return;
        }

        if (selectedTerminalGroup === "") {
            Notify.Warning({title: i18n.select_terminal_group(), message: ""});
            return;
        }

        await saveIikoMenuSettings({
            organizationId: user?.org?.id || "",
            menuId: currentMenuId,
            iikoOrganizationId: currentIikoOrg,
            iikoTerminalGroupId: selectedTerminalGroup,
            iikoPaymentTypeId: selectedPaymentType || null,
            mustApproveOrdersBeforeSend: isManualApproveChecked,
            mustIgnoreStopLists: isStopListsIgnored,
            iikoExternalMenuId: selectedExternalMenu || null,
            iikoPriceCategoryId: selectedPriceCategory || null,
            mustSendDeliveryOrdersForCouriers: mustSendDeliveryOrdersForCouriers,
        }, {navigate: navigate});

        await fetchIikoMenuSettings({menuId: currentMenuId}, new AbortController(), {navigate: navigate});
    }

    const handleDelete = async () => {
        await deleteMenuIikoSettings({
            organizationId: user?.org?.id || "",
            menuId: currentMenuId,
        }, {menuId: currentMenuId}, {navigate: navigate});
    }

    useEffect(() => {
        if (!iikoCompanyId) return;

        const controller = new AbortController();
        fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iikoCompanyId) return;

        const controller = new AbortController();
        fetchIikoOrganizations({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iikoCompanyId) return;

        const controller = new AbortController();
        fetchIikoTerminalGroups({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iikoCompanyId) return;

        const controller = new AbortController();
        fetchIikoPaymentTypes({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoExternalMenuInfo({
            organizationId: user?.org?.id || "",
        }, controller, {navigate: navigate});
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentIikoOrg(iikoMenuSettings?.iikoOrganizationId || "");
        setSelectedTerminalGroup(iikoMenuSettings?.iikoTerminalGroupId || "");
        setSelectedPaymentType(iikoMenuSettings?.iikoPaymentTypeId || "");
        setIsManualApproveChecked(iikoMenuSettings?.mustApproveOrdersBeforeSend || false);
        setMustSendDeliveryOrdersForCouriers(iikoMenuSettings?.mustSendDeliveryOrdersForCouriers || false)
        setIsStopListsIgnored(iikoMenuSettings?.mustIgnoreStopLists || false);
        setIsIikoV2(!!iikoMenuSettings.iikoExternalMenuId );
        setSelectedExternalMenu(iikoMenuSettings?.iikoExternalMenuId || "");
        setSelectedPriceCategory(iikoMenuSettings?.iikoPriceCategoryId || "");
    }, [iikoOrganizations, iikoMenuSettings]);

    useEffect(() => {
        if (currentIikoOrg !== iikoMenuSettings?.iikoOrganizationId) {
            setChangesHaveBeenMade(true);
        } else if (selectedTerminalGroup !== iikoMenuSettings?.iikoTerminalGroupId) {
            setChangesHaveBeenMade(true);
        } else if (selectedPaymentType !== iikoMenuSettings?.iikoPaymentTypeId) {
            setChangesHaveBeenMade(true);
        }
    }, [currentIikoOrg, selectedTerminalGroup, selectedPaymentType]);

    if (!iikoCompanyId) {
        return (
            <div className="alert alert-warning py-4">
                {i18n.help_empty_iiko()}
            </div>
        )
    }

    return (
        <FormWithLoader isloading={
            isLoadingGetIikoMenuSettings ||
            isLoadingGetIikoOrganizations ||
            isLoadingGetIikoTerminalGroups ||
            isLoadingGetIikoPaymentTypes ||
            isLoadingGetIikoExternalMenuInfo
        } id="dynamicPart" className={"form"} style={{padding: "0"}}>

            {iikoOrganizations?.organizations?.length > 0 &&
                <React.Fragment>
                    <h3>{i18n.select_organization()}</h3>

                    {iikoOrganizations?.organizations?.map((item, index) => {
                        return (
                            <CustomRadio
                                key={"iiko" + index}
                                value={item.id}
                                isChecked={currentIikoOrg === item.id}
                                label={item.data?.name}
                                onChange={() => setCurrentIikoOrg(item?.id)}
                            />
                        )
                    })}
                </React.Fragment>
            }

            {!iikoOrganizations?.organizations?.length && (
                <div className="alert alert-danger py-4">
                    {i18n.organizations_list_empty()}
                </div>
            )}

            {(iikoOrganizations?.organizations?.length > 0 && currentIikoOrg === "") && (
                <div className="alert alert-warning py-4">
                    {i18n.select_organization()}
                </div>
            )}

            {(iikoOrganizations?.organizations?.length > 0) && (
                isLoadingGetIikoTerminalGroups
                    ?
                    null
                    :
                    !isEmpty(iikoTerminalGroups)
                        ?
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            marginBottom: "20px"
                        }}>
                            <h3 className="mt-4" style={{marginBottom: "0"}}>{i18n.select_terminal_group()}</h3>

                            <Select
                                defaultValue={selectedTerminalGroup}
                                onChange={(value) => setSelectedTerminalGroup(value)}
                                options={terminalGroupsOptions}
                                disabled={currentIikoOrg === ""}
                            />

                            {(selectedTerminalGroup === "" && currentIikoOrg !== "") && (
                                <div className="alert alert-warning py-4">
                                    {i18n.select_terminal_group()}
                                </div>
                            )}

                            {isLoadingGetIikoPaymentTypes
                                ?
                                null
                                :
                                !isEmpty(iikoPaymentTypes)
                                    ?
                                    <React.Fragment>
                                        <h3 className="mt-4"
                                            style={{marginBottom: "0"}}>{i18n.select_payment_type()}</h3>

                                        <Select
                                            defaultValue={selectedPaymentType}
                                            onChange={(value) => setSelectedPaymentType(value)}
                                            options={iikoPaymentTypesOptions}
                                            disabled={selectedTerminalGroup === ""}
                                        />
                                    </React.Fragment>
                                    :
                                    <div className="alert alert-danger py-4">
                                        {i18n.payment_type_not_found()}
                                    </div>
                            }

                            <div className="mt-4" style={{margin: "0"}}>
                                <label
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        marginBottom: "0",
                                        lineHeight: "18px"
                                    }}
                                >
                                    <input
                                        type={"checkbox"}
                                        checked={isIikoV2}
                                        style={{width: "18px", height: "18px"}}
                                        onChange={() => setIsIikoV2(!isIikoV2)}
                                    />
                                    {"IIKO v2.0"}
                                </label>
                            </div>

                            {isIikoV2 && (
                                <React.Fragment>
                                    <h3 style={{marginBottom: "0"}}>{i18n.select_external_menu()}</h3>

                                    <Select
                                        defaultValue={selectedExternalMenu}
                                        onChange={(value) => setSelectedExternalMenu(value)}
                                        options={[
                                            {value: "", label: i18n.select_external_menu()},
                                            ...(iikoExternalMenuInfo?.externalMenus?.map(item => ({
                                                value: item.id,
                                                label: item.name
                                            })) || [])
                                        ]}
                                        disabled={currentIikoOrg === ""}
                                    />

                                    {(isIikoV2 && selectedExternalMenu === "") && (
                                        <div className="alert alert-warning py-4">
                                            {i18n.select_external_menu()}
                                        </div>
                                    )}

                                    <h3 className="mt-4" style={{marginBottom: "0"}}>{i18n.select_price_category()}</h3>

                                    <Select
                                        defaultValue={selectedPriceCategory}
                                        onChange={(value) => setSelectedPriceCategory(value)}
                                        options={[
                                            {value: "", label: i18n.select_price_category()},
                                            ...(iikoExternalMenuInfo?.priceCategories?.map(item => ({
                                                value: item.id,
                                                label: item.name
                                            })) || [])
                                        ]}
                                        disabled={currentIikoOrg === ""}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                        :
                        <div className="alert alert-danger py-4">
                            {i18n.terminal_group_not_found()}
                        </div>
            )}

            <div className="mt-4 mb-4">
                <label
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "0",
                        lineHeight: "18px"
                    }}
                >
                    <input
                        type={"checkbox"}
                        checked={isManualApproveChecked}
                        style={{width: "18px", height: "18px"}}
                        onChange={() => setIsManualApproveChecked(!isManualApproveChecked)}
                    />
                    {i18n.manual_apporve_before_order_sent_to_iiko()}
                </label>
            </div>

            <div className="mt-4 mb-4">
                <label
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "0",
                        lineHeight: "18px"
                    }}
                >
                    <input
                        type={"checkbox"}
                        checked={mustSendDeliveryOrdersForCouriers}
                        style={{width: "18px", height: "18px"}}
                        onChange={() => setMustSendDeliveryOrdersForCouriers(!mustSendDeliveryOrdersForCouriers)}
                    />
                    {i18n.send_delivery_orders_as_for_couriers()}
                </label>
            </div>

            <div className="mt-4 mb-4">
                <label
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "0",
                        lineHeight: "18px"
                    }}
                >
                    <input
                        type={"checkbox"}
                        checked={isStopListsIgnored}
                        style={{width: "18px", height: "18px"}}
                        onChange={() => setIsStopListsIgnored(!isStopListsIgnored)}
                    />
                    {i18n.ignore_iiko_stop_lists()}
                </label>
            </div>

            <div className="form__buttons">
                <div className="form__buttons-left">
                    {(!!currentIikoOrg && !!selectedTerminalGroup) && (
                        <SubmitButton
                            onSubmit={handleSave}
                            label={i18n.save()}
                            isLoading={isLoadingSaveMenuIikoSettings}
                            disabled={isLoadingSaveMenuIikoSettings || !changesHaveBeenMade}
                        />
                    )}

                    {(!isEmpty(iikoMenuSettings)) && (
                        <SubmitButton
                            onSubmit={handleDelete}
                            label={i18n.delete()}
                            isLoading={isLoadingDeleteMenuIikoSettings}
                            disabled={isLoadingDeleteMenuIikoSettings}
                            isDelete={true}
                        />
                    )}
                </div>
            </div>
        </FormWithLoader>
    )
}
