import {FeedbackPage} from "migration/entities/Feedback";
import {FeedbackAction, FeedbackActionEnum, FeedbackState} from "./types";

const initialState: FeedbackState = {
    feedbackPage: {} as FeedbackPage,
    isLoadingGetFeedbackPageById: false,
}

export default function feedbackReducer(state = initialState, action: FeedbackAction): FeedbackState {
    switch (action.type) {
        case FeedbackActionEnum.SET_FEEDBACK_PAGE:
            return {...state, feedbackPage: action.payload};
        case FeedbackActionEnum.SET_IS_LOADING_GET_FEEDBACK_PAGE_BY_ID:
            return {...state, isLoadingGetFeedbackPageById: action.payload};
        default:
            return state;
    }
}
