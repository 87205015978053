import React, {FunctionComponent, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Tabs} from "migration/shared/ui/Tabs";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {RouteNames} from "../../../index";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import classes from "./Notifications.module.scss";

export const Notifications: FunctionComponent = () => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);

    const tabItems = [
        {title: txt.telegram[currentLang], link: RouteNames.NOTIFICATIONS_TELEGRAM},
        {title: txt.list_of_receivers[currentLang], link: RouteNames.NOTIFICATIONS_RECEIVERS}
    ];

    useEffect(() => {
        navigate(RouteNames.NOTIFICATIONS_TELEGRAM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <Breadcrumbs
                    infos={[{
                        title: txt.notification_settings[currentLang],
                        link: RouteNames.NOTIFICATIONS,
                        index: "bracer-0"
                    }]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{txt.notification_settings[currentLang]}</h1>
                    </div>
                </div>

                <Tabs tabs={tabItems}/>
            </div>
        </div>
    );
};
