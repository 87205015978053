import {LangsList} from "migration/entities/base/mlString";
import {LangAction, LangActionEnum, LangState} from "./types";

const initialState: LangState = {
    currentLang: LangsList.RU,
    otherLangs: [],
}

export default function langReducer(state = initialState, action: LangAction): LangState {
    switch (action.type) {
        case LangActionEnum.SET_LANG:
            return {...state, currentLang: action.payload}
        case LangActionEnum.SET_OTHER_LANGS:
            return {...state, otherLangs: action.payload}
        default:
            return state
    }
}
