import {CocaColaState, ColaAction, ColaProductKeyEnum, ColaActionEnum} from "./types";

const initialState: CocaColaState = {
    isCocaColaClient: false,
    isCurrentCategoryCocaCola: false,
    isCurrentProductCocaCola: false,
    currentProductKey: ColaProductKeyEnum.COCA_COLA,
}

export default function cocaColaReducer(state = initialState, action: ColaAction): CocaColaState {
    switch (action.type) {
        case ColaActionEnum.SET_IS_COCA_COLA_CLIENT:
            return {...state, isCocaColaClient: action.payload};
        case ColaActionEnum.SET_IS_CURRENT_CATEGORY_COCA_COLA:
            return {...state, isCurrentCategoryCocaCola: action.payload};
        case ColaActionEnum.SET_IS_CURRENT_PRODUCT_COCA_COLA:
            return {...state, isCurrentProductCocaCola: action.payload};
        case ColaActionEnum.SET_CURRENT_PRODUCT_KEY:
            return {...state, currentProductKey: action.payload};
        default:
            return state;
    }
}
