import {SubmitButton} from "../input/submitButton";
import React, {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {InputText} from "../input/inputText";
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {IKaspiPaymentSettings} from "../../models/kaspi/kaspi";
import {FormWithLoader} from "../loader/formWithLoader";

export function KaspiPaymentModule() {
    const {i18n, api} = useGlobalContext()
    const {user} = useUserContext()
    const [apiKey, setApiKey] = useState<string>("")
    const [kaspiSettings, setKaspiSettings] = useState<IKaspiPaymentSettings>(null)

    const [loading, setLoading] = useState<boolean>(false)
    const [createDeleteLoading, setCreateDeleteLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (user?.org) {
            api.getKaspiPaymentSettings({controller, setLoading},user?.org.id).then((res) => {
                if (res.data) {
                    setKaspiSettings(res.data)
                }
            })
        }
        return () => controller.abort()
    }, [user?.org])

    function onSave() {
        api.createKaspiPaymentSettings({setLoading:setCreateDeleteLoading},user?.org?.id || "", apiKey).then((res) => {
            if (res.data) {
                setKaspiSettings(res.data)
            }
        })
    }

    function onDelete() {
        if (kaspiSettings?.id) {
            api.deleteKaspiPaymentSettings({setLoading:setCreateDeleteLoading},kaspiSettings.id).then((res) => {
                if (res.data) {
                    setKaspiSettings(null)
                }
            })
        }
    }

    return (
        <FormWithLoader isloading={loading} className="form">
            {!kaspiSettings?.id ?
                <InputText label={i18n.api_key()} value={apiKey} onChange={setApiKey} isValidate={true}/>
                :
                <div className="control">
                    <label className="control__label">{i18n.kaspi_settings_id()}</label>
                    <div className={"iiko_Tag"}>
                        <div className={"control__input"}
                             style={{
                                 display: "flex",
                                 alignItems: "center",
                                 textOverflow: "clip",
                                 overflow: "hidden"
                             }}>{kaspiSettings.id}</div>
                        <DeleteSVG onClick={() => onDelete()}/>
                    </div>
                </div>
            }
            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={onSave} label={i18n.save()}
                                  disabled={false} isLoading={createDeleteLoading}
                                  modal={true}/>
                </div>
            </div>
        </FormWithLoader>
    )
}