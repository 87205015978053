interface ImageProps {
    type?: 'orgLogos'|'menuSections'|'menuItems'|'menuSlides'|'menuOrgAttrsBackgrounds'|'menuOrgAttrsLogos';
    className: string;
    style?: React.CSSProperties;
    alt: string;
    src: string;
}
const URL = (process.env.REACT_APP_IMAGE_URL||"").replace("http://", "https://");

function getImageUrl(type: string, src: string) {
    return URL + type + '/' + src;
}

export function Image(props: ImageProps) {
    return (
        <img alt={props.alt||""} src={props.src?props.type?getImageUrl(props.type, props.src):props.src:""}
            className={props.className} style={props.style}/>
    )
}
