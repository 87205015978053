import {useGlobalContext, useUserContext} from "../globalContext";
import {Placement, Placements} from "../../models/placements/placements";
import {useEffect, useState} from "react";
import {I18nString} from "../../models/lang/i18nString";
import {addNotificationWithButton} from "../../utils/toast/toast";

export function usePlacements() {
    const {api, i18n} = useGlobalContext()
    const {currentMenuId, menu} = useUserContext()

    const [oldPlacements, setOldPlacements] = useState<Placements>({menuId: '', label: {}, placements: null})
    const [placements, setPlacements] = useState<Placements>({menuId: '', label: {}, placements: null})
    const [iikoModalPlacement, setIikoModalPlacement] = useState<Placement | null>(null)
    const [rkeeperModalPlacement, setRkeeperModalPlacement] = useState<Placement | null>(null)

    const [PlacementsLoading, setPlacementsLoading] = useState<boolean>(false)
    const [savePlacementsLoading, setSavePlacementsLoading] = useState<boolean>(false)
    const [downloadQrLoading, setDownloadQrLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!currentMenuId) return
        const controller = new AbortController()
        ReloadAll(controller)
        return () => controller.abort()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    function ReloadAll(controller: AbortController = new AbortController()) {
        setPlacements({menuId: currentMenuId, label: {}, placements: null})
        api.getPlacements({controller, setLoading: setPlacementsLoading}, currentMenuId).then((res) => {
            if (res.data) {
                setPlacements({
                    ...res.data, placements: res.data.placements?.map((p) => {
                        return {...p, isCreated: true}
                    }) || []
                })
                setOldPlacements(res.data)
            }
        })

        // if (user?.org?.availableModules?.includes("iiko")) {
        //     api.getIikoTables({setLoading: setIikoTablesLoading, controller}, currentMenuId).then((res) => {
        //         if (res.data?.iikoTables) {
        //             setIikoTables(res.data)
        //             let tables: ITablesObject = {}
        //             for (let table of res.data.iikoTables) {
        //                 tables[table.id] = table
        //             }
        //             setIikoTablesObject(tables)
        //         }
        //     })
        //     reloadIikoTableBindings(controller)
        // }
    }

    // function reloadIikoTableBindings(controller: AbortController = new AbortController()) {
    //     api.getIikoTableBindings({controller, setLoading: setIikoTableBindingsLoading}, currentMenuId).then((res) => {
    //         if (res.data) {
    //             let bindings: { [key: string]: IIikoTableBinding } = {}
    //             for (let binding of res.data) {
    //                 bindings[binding.kamiPlacementSlug] = binding
    //             }
    //             setIikoTableBindings(bindings)
    //         }
    //     })
    // }

    function changeLabel(label: I18nString) {
        setPlacements({...placements, label: {...label}})
    }

    function changePlacement(slug: string, name: I18nString, index: number) {
        const newPlacements = [...(placements.placements || [])]
        newPlacements[index] = {...newPlacements[index], slug, name}
        setPlacements({...placements, placements: newPlacements})
    }

    function changePlacementIsOff(isOff: boolean, index: number) {
        const newPlacements = [...(placements.placements || [])]
        newPlacements[index] = {...newPlacements[index], isOff}
        setPlacements({...placements, placements: newPlacements})
    }

    function addPlacement(e: any) {
        e.preventDefault()
        const newPlacements = [...(placements.placements || [])]
        //generate hash to slug
        let slug = (Math.random() + 1).toString(36).substring(7);
        newPlacements.push({slug: slug, name: {}, isOff: false, isCreated: false})
        setPlacements({...placements, placements: newPlacements})
    }

    function deletePlacement(e: any, index: number) {
        e.preventDefault()
        const newPlacements = [...(placements.placements || [])]
        newPlacements.splice(index, 1)
        setPlacements({...placements, placements: newPlacements})
    }

    function handleSubmit() {
        let isChanged = oldPlacements.placements?.length !== placements.placements?.length
        if (!isChanged && oldPlacements.placements && placements.placements) {
            for (let i = 0; i < oldPlacements.placements?.length; i++) {
                if (oldPlacements.placements[i].slug !== placements.placements[i].slug) {
                    isChanged = true
                    break
                }
            }
        }
        let validPlacements = {
            ...placements, placements: placements.placements?.map((p) => {
                return {slug: p.slug, name: p.name, isOff: p.isOff, isCreated: undefined}
            }) || []
        }
        if (isChanged) {
            addNotificationWithButton({
                message: i18n.q_need_reprint_qr(),
                onSubmit: () => {
                    savePlacements(validPlacements)
                },
                loading: savePlacementsLoading,
                i18n
            })
            return
        }
        savePlacements(validPlacements)
    }

    function savePlacements(validPlacements: Placements) {
        api.savePlacements({setLoading: setSavePlacementsLoading}, currentMenuId, validPlacements).then(
            (res) => {
                if (res.success) {
                    setPlacements({
                        ...validPlacements, placements: validPlacements.placements?.map((p) => {
                            return {...p, isCreated: true}
                        }) || []
                    })
                }
            }
        )
    }

    function handleDownloadQr() {
        api.downloadQrCodeForPlacements({setLoading: setDownloadQrLoading}, currentMenuId)
    }

    function handleCancel(e: any) {
        e.preventDefault()
        setPlacements(oldPlacements)
    }

    // function createIikoTableBinding(placementSlug: string, iikoTableId: string) {
    //     api.createIikoTableBinding({setLoading: setCreateDeleteIikoTableBindingLoading}, user?.org?.id as string, currentMenuId, placementSlug, iikoTableId).then((res) => {
    //
    //         if (res.success) {
    //             setModalPlacement(null)
    //             reloadIikoTableBindings()
    //         }
    //     })
    // }
    //
    // function deleteIikoTableBinding(id: string) {
    //     api.deleteIikoTableBinding({setLoading: setCreateDeleteIikoTableBindingLoading}, id).then((res) => {
    //         if (res.success) {
    //             let n: { [key: string]: IIikoTableBinding } = {}
    //             for (let key in iikoTableBindings) {
    //                 if (iikoTableBindings[key].id !== id) {
    //                     n[key] = iikoTableBindings[key]
    //                 }
    //             }
    //             setIikoTableBindings({...n})
    //         }
    //     })
    // }

    return {
        currentMenuId,
        placements,
        menu,
        handleSubmit,
        changeLabel,
        changePlacement,
        addPlacement,
        deletePlacement,
        handleCancel,
        handleDownloadQr,
        changePlacementIsOff,
        iikoModalPlacement,
        setIikoModalPlacement,
        rkeeperModalPlacement,
        setRkeeperModalPlacement,
        savePlacementsLoading,
        downloadQrLoading,
        PlacementsLoading,
    }
}
