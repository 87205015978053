import {useEffect, useState} from "react";
import {FontFamily, MenuStylize, RGBColor} from "../../models/menuStylize/menuStylize";
import {useGlobalContext, useUserContext} from "../globalContext";

const defaultParams: MenuStylize = {
    fontFamily: "'SF Pro Display', sans-serif",
    borderRounding: "none",
    itemDisplayType: "list",
    itemImgPosition: "left",
    showSectionsOnMainPage: false,
    mainBg: [255, 255, 255],
    mainFg: [32, 32, 34],
    secondaryBg: [243, 244, 245],
    secondaryFg: [32, 32, 34],
    accentBg: [32, 32, 34],
    accentFg: [243, 244, 245],
    priceFg: [32, 32, 34],
    salesPriceFg: [252, 50, 75],
    outOfStockFg: [147, 152, 161],
    helperBorderBg: [231, 231, 231],
}

function mergeParams(params: MenuStylize): MenuStylize {
    return {
        fontFamily: params.fontFamily || "'SF Pro Display', sans-serif",
        borderRounding: params.borderRounding || "none",
        itemDisplayType: params.itemDisplayType || "list",
        itemImgPosition: params.itemImgPosition || "left",
        showSectionsOnMainPage: params.showSectionsOnMainPage || false,
        mainBg: params.mainBg || [255, 255, 255],
        mainFg: params.mainFg || [32, 32, 34],
        secondaryBg: params.secondaryBg || [243, 244, 245],
        secondaryFg: params.secondaryFg || [32, 32, 34],
        accentBg: params.accentBg || [32, 32, 34],
        accentFg: params.accentFg || [243, 244, 245],
        priceFg: params.priceFg || [32, 32, 34],
        salesPriceFg: params.salesPriceFg || [252, 50, 75],
        outOfStockFg: params.outOfStockFg || [147, 152, 161],
        helperBorderBg: params.helperBorderBg || [231, 231, 231]
    }
}

export function useStylize() {
    const {currentMenuId} = useUserContext()
    const {api} = useGlobalContext()
    const [color, setColor] = useState<MenuStylize>(defaultParams)

    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!currentMenuId) return
        const controller = new AbortController()
        api.getMenuStylize({controller, setLoading}, currentMenuId).then((res) => {
            if (res.data) {
                setColor(mergeParams(res.data))
            }
        })
        return () => controller.abort()
    }, [currentMenuId])

    function handleChangeFontFamily(value: string) {
        let fontFamily = FontFamily.SF_PRO_DISPLAY
        switch (value) {
            case "0":
                fontFamily = FontFamily.SF_PRO_DISPLAY
                break
            case "1":
                fontFamily = FontFamily.ROBOTO
                break
            case "2":
                fontFamily = FontFamily.MONTSERRAT
                break
            case "3":
                fontFamily = FontFamily.ROBOTO_MONO
                break
            case "4":
                fontFamily = FontFamily.RALEWAY
                break
            case "5":
                fontFamily = FontFamily.CIRCE_ROUNDED
                break
        }

        let fontFamilyRes = "'SF Pro Display', sans-serif"
        switch (fontFamily) {
            case FontFamily.SF_PRO_DISPLAY:
                fontFamilyRes = "'SF Pro Display', sans-serif"
                break
            case FontFamily.ROBOTO:
                fontFamilyRes = "'Roboto', sans-serif"
                break
            case FontFamily.MONTSERRAT:
                fontFamilyRes = "'Montserrat', sans-serif"
                break
            case FontFamily.ROBOTO_MONO:
                fontFamilyRes = "'Roboto Mono', monospace"
                break
            case FontFamily.RALEWAY:
                fontFamilyRes = "'Raleway', sans-serif"
                break
            case FontFamily.CIRCE_ROUNDED:
                fontFamilyRes = "'Circe Rounded', sans-serif"
                break
        }

        setColor({...color, fontFamily: fontFamilyRes})
    }

    function handleChangeBorderRounding(value: string) {
        setColor({...color, borderRounding: value === "0" ? "none" : value === "1" ? "small" : "big"})
    }

    function handleChangeItemDisplayType(value: string) {
        setColor({...color, itemDisplayType: value === "0" ? "list" : "grid"})
    }

    function handleChangeItemImgPosition(value: string) {
        setColor({...color, itemImgPosition: value === "0" ? "left" : "right"})
    }

    function handleChangeShowSectionsOnMainPage(value: boolean) {
        setColor({...color, showSectionsOnMainPage: value})
    }

    function handleChangeMainBg(value: RGBColor) {
        setColor({...color, mainBg: value})
    }

    function handleChangeMainFg(value: RGBColor) {
        setColor({...color, mainFg: value})
    }

    function handleChangeSecondaryBg(value: RGBColor) {
        setColor({...color, secondaryBg: value})
    }

    function handleChangeSecondaryFg(value: RGBColor) {
        setColor({...color, secondaryFg: value})
    }

    function handleChangeAccentBg(value: RGBColor) {
        setColor({...color, accentBg: value})
    }

    function handleChangeAccentFg(value: RGBColor) {
        setColor({...color, accentFg: value})
    }

    function handleChangePriceFg(value: RGBColor) {
        setColor({...color, priceFg: value})
    }

    function handleChangeSalesPriceFg(value: RGBColor) {
        setColor({...color, salesPriceFg: value})
    }

    function handleChangeOutOfStockFg(value: RGBColor) {
        setColor({...color, outOfStockFg: value})
    }

    function handleChangeHelperBorder(value: RGBColor) {
        setColor({...color, helperBorderBg: value})
    }

    function handleSave() {
        if (!currentMenuId) return
        api.updateMenuStylize({setLoading: setSaveLoading}, currentMenuId, color)
    }

    function handleReset() {
        setColor(defaultParams)
    }

    return {
        color,
        currentMenuId,
        handleChangeFontFamily,
        handleChangeBorderRounding,
        handleChangeItemDisplayType,
        handleChangeItemImgPosition,
        handleChangeShowSectionsOnMainPage,
        handleChangeMainBg,
        handleChangeMainFg,
        handleChangeSecondaryBg,
        handleChangeSecondaryFg,
        handleChangeAccentBg,
        handleChangeAccentFg,
        handleChangePriceFg,
        handleChangeSalesPriceFg,
        handleChangeOutOfStockFg,
        handleChangeHelperBorder,
        handleSave,
        handleReset,

        saveLoading,
        loading
    }
}
