import React, {useEffect, useRef, useState} from "react";
import {ICurrentMenuInfo, menuItem} from "../../models/menu/menu";
import {useGlobalContext} from "../../hooks/globalContext";
import {Image} from "../image/image";
import ItemSquare from "../../resourse/img/item_square.png";
import {CURRENCY} from "../../utils/options/options";
import {Link} from "react-router-dom";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {IikoStopLists} from "../../migration/entities/iikoStopLists";
import {IikoMenuSettings} from "../../migration/entities/iikoMenuSettings";
import {IikoNomenclature} from "../../migration/entities/iikoNomenclature";
import {RouteNames} from "../../migration/pages";
import {RkeeperMenuSettings} from "../../migration/entities/rkeeperMenuSettings";
import {RkeeperMenu} from "../../migration/entities/rkeeperMenu";

interface ModalPreViewProps {
    setModalInfo: React.Dispatch<React.SetStateAction<menuItem | null>>
    modalInfo: menuItem | null
    menu: ICurrentMenuInfo
    iikoNomenclature?: IikoNomenclature
    iikoMenuSettings?: IikoMenuSettings
    iikoStopLists?: IikoStopLists
    rkeeperMenuSettings?: RkeeperMenuSettings
    rkeeperMenu?: RkeeperMenu
}

export function ModalPreView({modalInfo, setModalInfo, menu, iikoNomenclature, iikoMenuSettings, rkeeperMenuSettings, rkeeperMenu}: ModalPreViewProps) {
    const {i18n} = useGlobalContext()
    let [nowLang, setNowLang] = useState(menu.i18n.defaultLang)
    const modalRef = useRef(null)
    useOnClickOutside(modalRef, () => setModalInfo(null), "mouseup")
    useEffect(() => {
        if (modalInfo) {
            setNowLang(menu.i18n.defaultLang)
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [modalInfo])
    return (
        <>
            <div className={modalInfo ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 id="modal-preview" style={{display: modalInfo ? "block" : "none"}}
                 aria-hidden={!!modalInfo}>
                {modalInfo ?
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div ref={modalRef} className="modal-content">
                            <div className="modal-body px-3 py-4 p-lg-5">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                        onClick={() => setModalInfo(null)}
                                ></button>
                                <div id="item-preview-content">

                                    <form className="form">

                                        <div className="lang-tab" style={{display: "block"}}>
                                            <h2 className="tx-semibold mb-4">


                                                <span>{modalInfo.name[nowLang]}</span>


                                            </h2>
                                        </div>
                                        {menu.i18n.otherLangs && menu.i18n.otherLangs.length != 0 ?
                                            <ul className="nav default-tabs mb-2 mb-lg-3">
                                                {[menu.i18n.defaultLang, ...menu.i18n.otherLangs].map((v) => {
                                                    return (
                                                        <li className={"default-tabs__item"}>
                                                            <a className={v == nowLang ? "default-tabs__link active" : "default-tabs__link"}
                                                               href="#" role="tab"
                                                               onClick={(e) => {
                                                                   e.preventDefault()
                                                                   setNowLang(v)
                                                               }}
                                                            >
                                                                {i18n[v]()}
                                                            </a>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                            : null}

                                        <div className="lang-tab" style={{display: "block"}}>
                                            <div className="d-flex flex-wrap mb-3">
                                                <Image className={"modal-preview__img"} alt={"modalImg"}
                                                       src={modalInfo.mainImage ? modalInfo.mainImage : ItemSquare}
                                                       type={modalInfo.mainImage ? "menuItems" : undefined}/>
                                            </div>
                                            <div className="control">
                                                <div className="control__label">{i18n.short_desc()}</div>


                                                <span>{modalInfo.specs.shortDesc[nowLang] || i18n.empty()}</span>


                                            </div>
                                            <div className="control">
                                                <div className="control__label">{i18n.full_desc()}</div>


                                                <span>{modalInfo.specs.fullDesc[nowLang] || i18n.empty()}</span>


                                            </div>
                                            <div className="control">
                                                <div className="control__label">{i18n.tags()}</div>
                                                <div className="tags">

                                                    {modalInfo.specs.tags[nowLang] ? modalInfo.specs
                                                        .tags[nowLang].split(",").map((tag, index) => {
                                                        return (
                                                            <div key={"tag" + modalInfo.id + index + "modal"}
                                                                 className={"tags__item"}>{tag}</div>
                                                        )
                                                    }) : <span>{i18n.empty()}</span>
                                                    }

                                                </div>
                                            </div>
                                            <div className="row" data-gutter="15">
                                                <div className="col-md-4">
                                                    <div className="control">
                                                        <div
                                                            className="control__label">{i18n.price_label()}</div>
                                                        <span>{modalInfo.prices.primary.label[nowLang] || i18n.empty()}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="control">
                                                        <div className="control__label">{i18n.price()}</div>
                                                        {
                                                            !!modalInfo?.prices?.primary?.price
                                                                ? `${modalInfo.prices.primary.price / 100} ${CURRENCY[menu.i18n.currency]}`
                                                                : !!iikoNomenclature?.products?.[iikoMenuSettings?.productBindings?.[modalInfo?.id] || ""]?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice
                                                                    ? `${iikoNomenclature?.products?.[iikoMenuSettings?.productBindings?.[modalInfo?.id] || ""]?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice} ${CURRENCY[menu.i18n.currency]}`
                                                                    : modalInfo.rkeeperBind
                                                                        ? `${rkeeperMenu?.products?.find(product => product?.id === rkeeperMenuSettings?.productBindings[modalInfo.id])?.price} ${CURRENCY[menu.i18n.currency]}`
                                                                        : `${0} ${CURRENCY[menu.i18n.currency]}`
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="control">
                                                        <div className="control__label">{i18n.old_price()}</div>
                                                        <s>{modalInfo.prices.primary.oldPrice ? `${modalInfo.prices.primary.oldPrice / 100} ${CURRENCY[menu.i18n.currency]}` : null}</s>
                                                    </div>
                                                </div>
                                            </div>
                                            {(modalInfo.prices.others || []).map((value) => {
                                                return (
                                                    <div className="row" data-gutter="15">
                                                        <div className="col-md-4">
                                                            <div className="control">
                                                                <span>{value.label[nowLang] || i18n.empty()}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="control">
                                                                {value.price / 100} {CURRENCY[menu.i18n.currency]}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="control">
                                                                <s>{value.oldPrice ? `${value.oldPrice / 100} ${CURRENCY[menu.i18n.currency]}` : null}</s>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                            <div className="form__buttons mt-4">
                                                <div className="form__buttons-left">
                                                    <Link to={RouteNames.MENUS_ITEM_EDIT.replace(":id", modalInfo.id)}
                                                          className="button -blue modal__button">
                                                        {i18n.edit()}
                                                    </Link>
                                                    <button aria-label="Close"
                                                            className="button -bordered_blue modal__button"
                                                            onClick={() => setModalInfo(null)}
                                                            data-dismiss="modal" type="button">{i18n.cancel()}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
            {modalInfo ? <div className="modal-backdrop show"></div> : null}
        </>
    )
}
