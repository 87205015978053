import {useGlobalContext} from "../../hooks/globalContext";
import React, {useEffect} from "react";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {SettingsTab} from "../../components/tabs/settingsTab";
import {SubmitButton} from "../../components/input/submitButton";
import {InputText} from "../../components/input/inputText";
import {useScripts} from "../../hooks/settings/scripts";
import {FormWithLoader} from "../../components/loader/formWithLoader";
import {RouteNames} from "../../migration/pages";

export function SettingsScripts() {
    const info = useScripts()
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.scripts()
    }, [i18n])
    ///todo add loading
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.settings(), link: RouteNames.SETTINGS, index: "brcr-0"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.scripts()}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <SettingsTab/>
                        <div className="tab-content">


                            <div className="default-card">
                                <FormWithLoader isloading={info.loading} className="form">

                                    <InputText label={i18n.script()} value={info.scriptData.script}
                                               onChange={info.handleChangeScript} textArea={true} isValidate={true}/>
                                    <InputText label={i18n.meta_tags()} value={info.scriptData.metaTags}
                                               onChange={info.handleChangeMetaTags} textArea={true} isValidate={true}/>

                                    <div className="form__buttons mt-4">
                                        <div className="form__buttons-left">
                                            <SubmitButton onSubmit={info.handleSubmit} label={i18n.save()} isLoading={info.saveLoading}
                                                          modal={true} disabled={!info.currentMenuId}/>
                                            <a href={RouteNames.SETTINGS} className="button -bordered_blue form__button">
                                                {i18n.cancel()}
                                            </a>
                                        </div>
                                    </div>
                                </FormWithLoader>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        </section>
    )

}
