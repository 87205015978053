import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface ITBodyWithLoadingProps {
    isLoading: boolean;
    columns: number;
    rows: number;
    heights?: (number | null)[];
    isEmpty?: boolean;
    emptyMessage?: string
}

export function TBodyWithLoader(props: React.PropsWithChildren<ITBodyWithLoadingProps>) {
    return (
        <tbody>
        {props.isEmpty && props.emptyMessage && !props.isLoading &&
            <tr>
                <td colSpan={props.columns} className={"alert alert-primary py-4"}>
                    {props.emptyMessage}
                </td>
            </tr>
        }
        {props.isLoading ?
            <>
                {Array(props.rows).fill(0).map((_, i) =>
                    <tr key={i}>
                        {Array(props.columns).fill(0).map((_, j) => {
                                let height = props.heights ? props.heights[j] : null
                                return (<td key={j}>
                                    {typeof height === "number" ?
                                        <Skeleton height={height}/> :
                                        <Skeleton/>}
                                </td>)
                            }
                        )}
                    </tr>
                )}

            </>
            :
            props.children}
        </tbody>
    )
}