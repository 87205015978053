import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ReactNotifications} from "react-notifications-component"
import moment from "moment/moment";
import AppRouter, {RouteNames} from "migration/pages";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {TXT} from "../../txtMap/embedded/txt";
import {LangKey} from "../../hooks/globalContext";
import KamiService from "../../api/kamiService";
import {GlobalContextProvider} from "../../components/context/global";
import 'animate.css';
import "react-notifications-component/dist/theme.css"
import "../../App.css";
import "../../index.css";

export const App: FunctionComponent = () => {
    const [lang, setLang] = useState<LangKey>("RU")
    const {fetchCurrentUser} = useActions();
    useEffect(() => {
        moment.locale(lang)
    }, [lang])
    //api
    let navigate = useNavigate()

    const i18n = new TXT(lang)

    const api = new KamiService(navigate, lang, i18n)

    useEffect(() => {
        fetchCurrentUser({navigate: navigate, to: RouteNames.LOGIN});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GlobalContextProvider
            lang={lang}
            setLang={setLang}
            api={api}
            i18n={i18n}
        >
            <ReactNotifications className={"notifications"}/>
            <AppRouter/>
        </GlobalContextProvider>
    )
}
