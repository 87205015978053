import {NavLink} from "react-router-dom";
import React from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {RouteNames} from "../../migration/pages";

export function MainPageTabs() {
    const {i18n} = useGlobalContext()
    const {user} = useUserContext()
    return (
        <ul className="nav default-tabs mb-2 mb-lg-3">
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to={RouteNames.MAIN_PAGE_EDITOR}>
                    {i18n.main_menu_page()}
                </NavLink>
            </li>
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link " end={true} to={RouteNames.MAIN_PAGE_EDITOR_CUSTOM_LINKS}>
                    {i18n.custom_links()}
                </NavLink>
            </li>

            <li className="default-tabs__item">
                <NavLink className="default-tabs__link " end={true} to={RouteNames.MAIN_PAGE_EDITOR_CUSTOM_PAGES}>
                    {i18n.custom_pages()}
                </NavLink>
            </li>
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link " end={true} to={RouteNames.MAIN_PAGE_EDITOR_MENU_STYLIZE}>
                    {i18n.menu_stylize()}
                </NavLink>
            </li>
            {user?.user?.isSuper && (
                <li className="default-tabs__item">
                    <NavLink className="default-tabs__link " end={true} to={RouteNames.MAIN_PAGE_EDITOR_MENU_BESPOKE}>
                        {"Bespoke"}
                    </NavLink>
                </li>
            )}
        </ul>
    )
}
