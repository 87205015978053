import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {ISlide, ISlideCreate, ISlideUpdate} from "../../models/slider/slider";
import {useNavigate, useParams} from "react-router-dom";
import {I18nString} from "../../models/lang/i18nString";
import {RouteNames} from "../../migration/pages";

export function useSliderForm(isEdit: boolean) {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()
    const navigate = useNavigate()


    const [modal, setModal] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [slide, setSlide] = useState<ISlide>({} as ISlide)
    const [img, setImg] = useState<string>("")
    const {id} = useParams()

    const [slideLoading, setSlideLoading] = useState<boolean>(false)
    const [saveSlideLoading, setSaveSlideLoading] = useState<boolean>(false)
    const [uploadSlideLoading, setUploadSlideLoading] = useState<boolean>(false)

    useEffect(() => {
        setSlide({...slide, img: img})
    }, [img])

    useEffect(() => {
        const controller = new AbortController()
        if (isEdit) {
            api.getSlide({controller, setLoading: setSlideLoading}, id as string, currentMenuId).then((res) => {
                if (res.data) {
                    setSlide({...res.data})
                }
            })
        }
        return () => controller.abort()
    }, [currentMenuId])

    function saveSlide() {
        if (isEdit) {
            let s: ISlideUpdate = {
                menuId: currentMenuId,
                id: id as string,
                img: slide.img,
                url: slide.url,
                text: slide.text
            }
            api.updateSlide({setLoading: setSaveSlideLoading}, s).then((res) => {
                if (res.success) {
                    navigate(RouteNames.SLIDER);
                }
            })
        } else {
            let s: ISlideCreate = {
                menuId: currentMenuId,
                img: slide.img,
                url: slide.url,
                text: slide.text
            }
            api.createSlide({setLoading: setSaveSlideLoading}, s).then((res) => {
                if (res.success) {
                    navigate(RouteNames.SLIDER);
                }
            })
        }
    }

    //Event
    function onChangeUploadImg(file: File|null) {
        if (!file) return
        setSelectedImage(file)
        setModal(true)
    }

    function UploadImg(file: File) {
        api.uploadSlideFile({setLoading: setUploadSlideLoading}, file).then((res) => {
            if (res.data) {
                setSlide({...slide, img: res.data.newFilename})
            }
        })
    }

    function onDeleteImg() {
        setSlide({...slide, img: ""})
    }

    function onChangeURL(url: string) {
        setSlide({
            ...slide,
            url: url.length === 0 ? null : url
        })
    }

    function onChangeText(text: I18nString) {
        setSlide({
            ...slide,
            text: text
        })
    }

    return {
        menu,
        img,
        isStaff: !!user?.isStaff,
        modal,
        setModal,
        selectedImage,
        currentMenuId,
        setSelectedImage,
        slide,
        setImg,
        onChangeUploadImg,
        onDeleteImg,
        onChangeURL,
        saveSlide,
        UploadImg,
        onChangeText,
        saveSlideLoading,
        slideLoading,
        uploadSlideLoading
    }
}
