import {useEffect, useState} from "react";
import {ISlide} from "../../models/slider/slider";
import {useGlobalContext, useUserContext} from "../globalContext";

export function useSliderIndex() {
    let [slides, setSlides] = useState<ISlide[] | null>([])
    const {api} = useGlobalContext()
    const {currentMenuId} = useUserContext()

    const [sliderLoading, setSliderLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (currentMenuId) {
            api.getSlider({controller, setLoading:setSliderLoading},currentMenuId).then((resp) => {
                setSlides(resp.data ? resp.data.slides : [])
            })
        }
        return () => controller.abort()
    }, [currentMenuId])

    function deleteSlide(id: string, setDeleteLoading:  (loading: boolean) => void) {
        api.deleteSlide({setLoading:setDeleteLoading},id, currentMenuId).then((resp) => {
            if (resp.success) {
                setSlides((slides || []).filter((s) => s.id !== id))
            }
        })
    }

    return {
        slides,
        deleteSlide,
        sliderLoading,
    }
}