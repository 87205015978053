import {KamiMenuStruct} from "migration/entities/kamiMenuStruct";
import {KamiItemAction, KamiItemActionEnum, KamiItemState} from "./types";

const initialState: KamiItemState = {
    menuData: {} as KamiMenuStruct,
    kamiMenuItemsList: {},
    kamiMenuCategoryList: {},
    isLoadingGetMenuData: true,
}

export default function kamiItemReducer(state = initialState, action: KamiItemAction): KamiItemState {
    switch (action.type) {
        case KamiItemActionEnum.SET_MENU_DATA:
            return {...state, menuData: action.payload};
        case KamiItemActionEnum.SET_KAMI_MENU_ITEMS_LIST:
            return {...state, kamiMenuItemsList: action.payload};
        case KamiItemActionEnum.SET_KAMI_MENU_CATEGORY_LIST:
            return {...state, kamiMenuCategoryList: action.payload};
        case KamiItemActionEnum.SET_IS_LOADING_GET_MENU_DATA:
            return {...state, isLoadingGetMenuData: action.payload};
        default:
            return state;
    }
}
