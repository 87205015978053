import {PasswordInput} from "../input/passwordInput";
import {SubmitButton} from "../input/submitButton";
import {Link} from "react-router-dom";
import React from "react";
import usePasswordInfo from "../../hooks/account/changePassword";
import {useGlobalContext} from "../../hooks/globalContext";

export function ChangePasswordForm({backUrl}: { backUrl?: string }) {
    const info = usePasswordInfo()
    const {i18n} = useGlobalContext()
    return (
        <form className="form">
            <PasswordInput label={i18n.old_password()}
                           password={info.passwordInfo.oldPass}
                           onChangePassword={info.handleChangeOldPassword} isValid={true}
            />
            <PasswordInput label={i18n.new_password()}
                           password={info.passwordInfo.newPass}
                           onChangePassword={info.handleChangeNewPassword}
                           isValid={info.passwordInfo.newPassIsValid}
            />
            <PasswordInput label={i18n.repeat_new_password()}
                           password={info.passwordInfo.reNewPass}
                           onChangePassword={info.handleChangeRepeatPassword}
                           isValid={info.passwordInfo.reNewPassIsValid}
            />


            <div className="form__buttons">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.handleOnSubmit} label={i18n.save()} isLoading={info.loadingSave}
                                  disabled={!(info.passwordInfo.newPassIsValid && info.passwordInfo.reNewPassIsValid)}/>
                    <Link to={backUrl ? backUrl : "/account/"}
                          className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>

        </form>
    )
}