export enum LangsList {
    RU = "RU",
    KZ = "KZ",
    EN = "EN",
    TR = "TR",
    AZ = "AZ",
    CH = "CH", 
    JP = "JP",
    AR = "AR",
    UZ = "UZ",
    ID = "ID",
    KY = "KY",
    UK = "UK",
}

export type MlString = Record<LangsList, string>
