import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {Collapse} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useUserContext} from "../../../../../../../hooks/globalContext";
import classes from "./IikoHealthCheck.module.scss";
import {IikoActionCreators} from "../../store/action-creators";

enum HealthCheckStatusEnum {
    FAILED = 0,
    SUCCESS = 1,
    WARNING = 2
}

export const IikoHealthCheck: FunctionComponent = () => {
    const {currentLang} = useTypedSelector(state => state.lang);

    const navigate = useNavigate();
    const {user, currentMenuId} = useUserContext();

    const {
        iikoApiKey,
        iikoOrganizations,
        iikoMenuSettings,
        iikoNomenclature,
        iikoRestaurantSections,
        iikoTerminalGroups,
        iikoPaymentTypes,

        errorMsgIikoNomenclature,
        errorMsgIikoOrganizations,
        errorMsgIikoPaymentTypes,
        errorMsgIikoRestaurantSections,
        errorMsgIikoTerminalGroups,

        isLoadingGetIikoApiKey,
        isLoadingGetIikoOrganizations,
        isLoadingGetIikoMenuSettings,
        isLoadingGetNomenclature,
        isLoadingGetRestaurantSections,
        isLoadingGetIikoTerminalGroups,
        isLoadingGetIikoPaymentTypes
    } = useTypedSelector(state => state.iiko);
    const {
        fetchIikoApiKey,
        fetchIikoOrganizations,
        fetchIikoMenuSettings,
        fetchIikoNomenclature,
        fetchIikoRestaurantSections,
        fetchIikoTerminalGroups,
        fetchIikoPaymentTypes,
    } = useActions();

    const [apiKey, setApiKey] = useState({
        name: txt.api_key[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });
    const [org, setOrg] = useState({
        name: txt.organization[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });
    const [productBinding, setProductBinding] = useState({
        name: txt.product_binding[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });
    const [tableBinding, setTableBinding] = useState({
        name: txt.table_binding[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });
    const [terminalGroup, setTerminalGroup] = useState({
        name: txt.terminal_group[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });
    const [paymentType, setPaymentType] = useState({
        name: txt.payment_type[currentLang],
        status: HealthCheckStatusEnum.FAILED
    });

    // Fetch data

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoApiKey({organizationId: user?.org?.id || ""}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoOrganizations({organizationId: user?.org?.id || ""}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmpty(iikoMenuSettings)) return;
        if (!!iikoMenuSettings.iikoExternalMenuId) return;

        const controller = new AbortController();
        fetchIikoNomenclature({
            organizationId: user?.org?.id || "",
            iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId
        }, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings]);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoRestaurantSections({organizationId: user?.org?.id || ""}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoTerminalGroups({organizationId: user?.org?.id || ""}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoPaymentTypes({organizationId: user?.org?.id || ""}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check data

    useEffect(() => {
        if (!isEmpty(iikoApiKey) && iikoApiKey?.isValid) {
            setApiKey(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
        }
    }, [iikoApiKey]);

    useEffect(() => {
        if (!isEmpty(iikoOrganizations) && !isEmpty(iikoMenuSettings)) {
            if (iikoOrganizations?.organizations?.map(org => org.id)?.includes(iikoMenuSettings?.iikoOrganizationId)) {
                setOrg(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
            }
        }
    }, [iikoOrganizations, iikoMenuSettings]);

    useEffect(() => {
        if (!isEmpty(iikoTerminalGroups) && !isEmpty(iikoMenuSettings)) {
            if (iikoTerminalGroups?.terminalGroups?.[iikoMenuSettings?.iikoOrganizationId]?.map(tg => tg.id)?.includes(iikoMenuSettings?.iikoTerminalGroupId)) {
                setTerminalGroup(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
            }
        }
    }, [iikoTerminalGroups, iikoMenuSettings]);

    useEffect(() => {
        if (!isEmpty(iikoPaymentTypes) && !isEmpty(iikoMenuSettings)) {
            if (iikoPaymentTypes?.paymentTypes?.[iikoMenuSettings?.iikoOrganizationId]?.[iikoMenuSettings?.iikoTerminalGroupId]?.map(pt => pt.id)?.includes(iikoMenuSettings?.iikoPaymentTypeId)) {
                setPaymentType(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
            }
        }
    }, [iikoPaymentTypes, iikoMenuSettings]);

    useEffect(() => {
        if (!isEmpty(iikoNomenclature) && !isEmpty(iikoMenuSettings)) {
            let count = 0;
            Object.keys(iikoMenuSettings?.productBindings || {}).forEach(kamiItemId => {
                if (Object.keys(iikoNomenclature?.products || {}).includes(iikoMenuSettings?.productBindings?.[kamiItemId])) {
                    count += 1;
                }
            })

            if (Object.keys(iikoMenuSettings?.productBindings || {})?.length === count) {
                setProductBinding(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
            }
        }
    }, [iikoNomenclature, iikoMenuSettings]);

    useEffect(() => {
        if (!isEmpty(iikoRestaurantSections) && !isEmpty(iikoMenuSettings)) {
            let count = 0;
            Object.keys(iikoMenuSettings?.tableBindings || {}).forEach(placementLSug => {
                iikoRestaurantSections?.restaurantSections?.[iikoMenuSettings?.iikoOrganizationId]?.[iikoMenuSettings?.iikoTerminalGroupId]?.forEach(table => {
                    if (table?.id === iikoMenuSettings?.tableBindings?.[placementLSug]) {
                        count += 1;
                    }
                })
            })

            if (Object.keys(iikoMenuSettings?.tableBindings || {})?.length === count) {
                setTableBinding(prevState => ({...prevState, status: HealthCheckStatusEnum.SUCCESS}));
            }
        }
    }, [iikoRestaurantSections, iikoMenuSettings]);

    return (
        <div className={classes.main}>
            <div className={classes.list}>
                <HealthCheckItem
                    index={1}
                    name={apiKey.name}
                    loading={isLoadingGetIikoApiKey}
                    status={apiKey.status}
                    reason={iikoApiKey?.isValid ? iikoApiKey?.errorDescription : ""}
                />
                <HealthCheckItem
                    index={2}
                    name={org.name}
                    loading={isLoadingGetIikoOrganizations || isLoadingGetIikoMenuSettings}
                    status={org.status}
                    reason={`${errorMsgIikoOrganizations} \n${errorMsgIikoOrganizations}`}
                />
                <HealthCheckItem
                    index={3}
                    name={terminalGroup.name}
                    loading={isLoadingGetIikoTerminalGroups || isLoadingGetIikoMenuSettings}
                    status={terminalGroup.status}
                    reason={`${errorMsgIikoOrganizations} \n${errorMsgIikoTerminalGroups}`}
                />
                <HealthCheckItem
                    index={4}
                    name={paymentType.name}
                    loading={isLoadingGetIikoPaymentTypes || isLoadingGetIikoMenuSettings}
                    status={paymentType.status}
                    reason={`${errorMsgIikoOrganizations} \n${errorMsgIikoPaymentTypes}`}
                />
                <HealthCheckItem
                    index={5}
                    name={productBinding.name}
                    loading={isLoadingGetNomenclature || isLoadingGetIikoMenuSettings}
                    status={productBinding.status}
                    reason={`${errorMsgIikoOrganizations} \n${errorMsgIikoNomenclature}`}
                />
                <HealthCheckItem
                    index={6}
                    name={tableBinding.name}
                    loading={isLoadingGetRestaurantSections || isLoadingGetIikoMenuSettings}
                    status={tableBinding.status}
                    reason={`${errorMsgIikoOrganizations} \n${errorMsgIikoRestaurantSections}`}
                />
            </div>
        </div>
    )
}

interface IHealthCheckItem {
    index: number;
    name: string;
    loading: boolean;
    status: HealthCheckStatusEnum;
    reason?: JSX.Element | string;
}

const HealthCheckItem: FunctionComponent<IHealthCheckItem> = ({index, name, loading, status, reason}) => {
    const {currentLang} = useTypedSelector(state => state.lang);

    const items = [
        {
            key: index,
            label: (
                <div className={classes.list__item}>
                    <div className={classes.list__item__index}>{`${index}.`}</div>
                    <div className={classes.list__item__name}>{name}</div>
                    <div className={classes.check}>
                        {loading
                            ? <div className={classes.loader__wrapper}><LoadingOutlined/></div>
                            : status === HealthCheckStatusEnum.SUCCESS ? "✅"
                                : status === HealthCheckStatusEnum.WARNING ? "⚠️" : "❌"
                        }
                    </div>
                </div>
            ),
            children:
                <p>{(status === HealthCheckStatusEnum.FAILED && !loading && !reason) ? txt.request_cancelled[currentLang] : reason}</p>
        }
    ]

    return (
        <Collapse
            items={items}
            collapsible={(status === HealthCheckStatusEnum.SUCCESS || loading) ? "disabled" : undefined}
            style={{color: "rgba(0, 0, 0, 0.88)"}}
        />
    )
}
