import React from "react";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {ColorInput} from "../input/colorInput";
import {CustomSelect} from "../input/customSelect";
import {useStylize} from "../../hooks/menuStylize/stylize";
import {PhoneIframe} from "../phone/phoneIframe";
import {Checkbox} from "../input/checkbox";
import {FontFamily, RGBColor} from "../../models/menuStylize/menuStylize";
import {FormWithLoader} from "../loader/formWithLoader";

export function MenuStylizeForm() {
    const info = useStylize()
    const {i18n} = useGlobalContext()
    return (
        <>
            <FormWithLoader isloading={info.loading} id="dynamicPart col-5">
                <h3 className="mt-4">{i18n.menu_stylize()}</h3>
                {/*todo uncomment if complete
                <CustomSelect
                    label={i18n.border_rounding()}
                    options={[i18n.border_rounding_none(), i18n.border_rounding_small(), i18n.border_rounding_big()]}
                    onChange={info.handleChangeBorderRounding}
                    value={
                        info.color.borderRounding === "none" ? 0 :
                            info.color.borderRounding === "small" ? 1 :
                                info.color.borderRounding === "big" ? 2 : 0
                    }/>*/}
                <CustomSelect
                    label={i18n.item_display_type()}
                    options={[i18n.item_display_type_list(), i18n.item_display_type_grid()]}
                    value={
                        info.color.itemDisplayType === "list" ? 0 :
                            info.color.itemDisplayType === "grid" ? 1 : 0
                    }
                    onChange={info.handleChangeItemDisplayType}
                />
                <CustomSelect
                    label={i18n.font_family()}
                    options={[
                        FontFamily.SF_PRO_DISPLAY,
                        FontFamily.ROBOTO,
                        FontFamily.MONTSERRAT,
                        FontFamily.ROBOTO_MONO,
                        FontFamily.RALEWAY,
                        FontFamily.CIRCE_ROUNDED
                    ]}
                    value={
                        info.color.fontFamily === "'SF Pro Display', sans-serif" ? 0 :
                            info.color.fontFamily === "'Roboto', sans-serif" ? 1 :
                                info.color.fontFamily === "'Montserrat', sans-serif" ? 2 :
                                    info.color.fontFamily === "'Roboto Mono', monospace" ? 3 :
                                        info.color.fontFamily === "'Raleway', sans-serif" ? 4 :
                                            info.color.fontFamily === "'Circe Rounded', sans-serif" ? 5 : 0
                    }
                    onChange={info.handleChangeFontFamily}
                />
                <Checkbox label={i18n.show_sections_on_main_page()}
                          checked={info.color.showSectionsOnMainPage}
                          onChange={info.handleChangeShowSectionsOnMainPage}
                />
                {/*<CustomSelect
                    label={i18n.itemImgPosition()}
                    options={[i18n.itemImgPosition_left(), i18n.itemImgPosition_right()]}
                    onChange={info.handleChangeItemImgPosition}
                    value={
                        info.color.itemImgPosition === "left" ? 0 :
                            info.color.itemImgPosition === "right" ? 1 : 0
                    }/>
                    */}

                <ColorInput label={i18n.main_bg_color()} value={listToRGB(info.color.mainBg)}
                            setValue={info.handleChangeMainBg}/>
                <ColorInput label={i18n.main_fg_color()} value={listToRGB(info.color.mainFg)}
                            setValue={info.handleChangeMainFg}/>
                <ColorInput label={i18n.secondary_bg_color()} value={listToRGB(info.color.secondaryBg)}
                            setValue={info.handleChangeSecondaryBg}/>
                <ColorInput label={i18n.secondary_fg_color()} value={listToRGB(info.color.secondaryFg)}
                            setValue={info.handleChangeSecondaryFg}/>
                <ColorInput label={i18n.accent_bg()} value={listToRGB(info.color.accentBg)}
                            setValue={info.handleChangeAccentBg}/>
                <ColorInput label={i18n.accent_fg()} value={listToRGB(info.color.accentFg)}
                            setValue={info.handleChangeAccentFg}/>
                <ColorInput label={i18n.price_bg()} value={listToRGB(info.color.priceFg)}
                            setValue={info.handleChangePriceFg}/>
                <ColorInput label={i18n.sales_price_fg()} value={listToRGB(info.color.salesPriceFg)}
                            setValue={info.handleChangeSalesPriceFg}/>
                <ColorInput label={i18n.out_of_stock_bg()} value={listToRGB(info.color.outOfStockFg)}
                            setValue={info.handleChangeOutOfStockFg}/>
                <ColorInput label={i18n.helper_border_color()} value={listToRGB(info.color.helperBorderBg)}
                            setValue={info.handleChangeHelperBorder}/>

                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={info.handleSave} label={i18n.save()} isLoading={info.saveLoading}
                                      disabled={!info.currentMenuId}/>
                        <a href="#" onClick={info.handleReset}
                           className="button -bordered_blue form__button">
                            {i18n.reset()}
                        </a>
                    </div>
                </div>
            </FormWithLoader>
            <PhoneIframe style={info.color}/>
        </>
    )
}

function listToRGB(color: RGBColor) {
    if (color === null) return {r: 0, g: 0, b: 0}
    return {
        r: color[0],
        g: color[1],
        b: color[2],
    }
}
