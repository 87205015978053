import React, {FunctionComponent} from 'react';
import {ArticleEditor} from "migration/widgets/ckeditor";
import {Layout} from "migration/shared/ui/Layout";
import classes from "./CustomPagesAdd.module.scss";

export const CustomPagesAdd: FunctionComponent = () => {
    return (
        <div className={classes.main}>
            <Layout breadcrumbs={[]} title={""}>
                {/*<ArticleEditor/>*/}
            </Layout>
        </div>
    );
};

