import {IOrder} from "../../models/orders/order";
import React from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import moment from "moment";
import {PrintButton} from "../helpers/printButtons";
import {CURRENCY} from "../../utils/options/options";

interface IIIKOOrderModalProps {
    iikoOrderModalInfo: { order: IOrder | null, active: boolean }
    setIikoOrderModalInfo: React.Dispatch<React.SetStateAction<{ order: IOrder | null, active: boolean }>>
}

export function IikoOrderModal(props: IIIKOOrderModalProps) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()

    const tableRef = React.useRef(null);
    useOnClickOutside(tableRef, () => props.setIikoOrderModalInfo({order: null, active: false}), "mouseup")

    return (
        <>
            <div
                className={props.iikoOrderModalInfo.active ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                style={{display: props.iikoOrderModalInfo.active ? "block" : "none"}}
                id="modal-order-preview"
                aria-hidden={props.iikoOrderModalInfo.active}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" ref={tableRef}>
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button aria-label="Close" className="close" data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        props.setIikoOrderModalInfo(
                                            {
                                                order: null,
                                                active: false
                                            }
                                        )
                                    }}
                            ></button>
                            <div id="iiko-order-preview-content">

                                <form className="form">
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.order_w_number()}</div>
                                        </div>
                                        <div className="col-md-6">
                                            : {props.iikoOrderModalInfo.order?.iiko?.order.externalNumber || ""}

                                        </div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.date()}</div>
                                        </div>
                                        <div className="col-md-6">

                                        <span className="calendar"
                                              data-iso-value="2022-03-31T04:12:34.943Z">: {moment(props.iikoOrderModalInfo.order?.createdAt).format('D MMMM YYYY, h:mm:ss ')}</span>

                                        </div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.name() || ""}</div>
                                        </div>
                                        <div
                                            className="col-md-6">: {props.iikoOrderModalInfo.order?.iiko?.order.customer.name || ""}</div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.phone()}</div>
                                        </div>
                                        <div
                                            className="col-md-6">: {props.iikoOrderModalInfo.order?.iiko?.order.phone}</div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.delivery_type()}</div>
                                        </div>
                                        <div className="col-md-6">

                                            : {props.iikoOrderModalInfo.order?.iiko?.order.tableNumbers &&
                                        props.iikoOrderModalInfo.order?.iiko?.order.tableNumbers.length > 0
                                            ? i18n.at_place() : i18n.delivery()}
                                        </div>
                                    </div>

                                    {props.iikoOrderModalInfo.order?.iiko?.order.tableNumbers ? (
                                        <>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div
                                                        className="tx-gray">
                                                        {i18n.table_number()}
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6">: {props.iikoOrderModalInfo.order.iiko?.order.tableNumbers.map((place => {
                                                    return (
                                                        <span>{place}</span>
                                                    )
                                                }))}</div>
                                            </div>
                                        </>

                                    ) : ""}

                                    {props.iikoOrderModalInfo.order?.iiko?.order.address ? (
                                        <>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div
                                                        className="tx-gray">
                                                        {i18n.home_address()}
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6">: {props.iikoOrderModalInfo.order.iiko?.order.address}</div>
                                            </div>
                                        </>
                                    ) : ""}


                                    <div className="default-table my-4" id={"iiko-card"}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>{i18n.pl_item()}</th>
                                                <th>{i18n.amount()}</th>
                                                <th>{i18n.price()}</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {props.iikoOrderModalInfo.order?.iiko?.order.items?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td data-title={i18n.pl_item()}>
                                                            <div>
                                                          <span>
                                                              {item.name[menu.i18n.defaultLang]}
                                                          </span>

                                                                <br/>
                                                                <div
                                                                    className="text-secondary modifier-tuple">
                                                                    {item.modifiers?.map((me) => {
                                                                        return (
                                                                            <>
                                                                                <span>{me.name} {me.amount}</span><br/>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>


                                                        </td>
                                                        <td data-title={i18n.amount()} className="number-td">
                                                            {item.amount}
                                                        </td>
                                                        <td data-title={i18n.price()} className="number-td">
                                                            {item.price}
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            {props.iikoOrderModalInfo.order?.orderSupItems?.map((v, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td data-title={i18n.pl_item()}>
                                                            <div>
                                                          <span>
                                                              {v.name[menu.i18n.defaultLang]}
                                                          </span>
                                                            </div>


                                                        </td>
                                                        <td data-title={i18n.amount()} className="number-td">
                                                            {v.quantity}
                                                        </td>
                                                        <td data-title={i18n.price()} className="number-td">
                                                            {v.price / 100}
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            {(props.iikoOrderModalInfo.order?.charges || []).map((v, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td data-title={i18n.pl_item()}>
                                                            <div>
                                                            <span>
                                                                {(v.charge.data.name || {})[menu.i18n.defaultLang]}
                                                            </span>
                                                            </div>
                                                        </td>
                                                        <td data-title={i18n.amount()} className="number-td">
                                                        </td>
                                                        <td data-title={i18n.price()} className="number-td">
                                                            {v.amount / 100} {CURRENCY[props.iikoOrderModalInfo.order?.currency || ""]}

                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            <tr className="table-secondary">
                                                <td>Итого</td>
                                                <td data-title={i18n.amount()} className="number-td">
                                                </td>
                                                <td data-title={i18n.price()} className="number-td">
                                                    {(props.iikoOrderModalInfo.order?.totalPrice || 0) / 100} {CURRENCY[props.iikoOrderModalInfo.order?.currency || ""]}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <OrderExtendedTable order={props.iikoOrderModalInfo.order || null}/>
                                </form>
                            </div>
                            <PrintButton previewId={"iiko-order-preview-content"} tableCardId={"iiko-table-card"}
                                         cardId={"iiko-card"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.iikoOrderModalInfo.active ? "modal-backdrop show" : ""}></div>
        </>
    )
}


function OrderExtendedTable({order}: { order: IOrder | null }) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div id={"iiko-table-card"} style={{display: "none"}}>
            <br/>
            <table style={{width: "100%"}}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>{i18n.pl_item()}</th>
                    <th>{i18n.amount()}</th>
                    <th>{i18n.price()}</th>
                    <th>{i18n.summ()}</th>
                </tr>
                </thead>
                <tbody>

                {order?.iiko?.order.items?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                          <span>
                                                              {item.name[menu.i18n.defaultLang]}
                                                          </span>

                                    <br/>
                                    <div
                                        className="text-secondary modifier-tuple">
                                        {item.modifiers?.map((me) => {
                                            return (
                                                <>
                                                    <span>{me.name} {me.amount}</span><br/>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>


                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {item.amount}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {item.price / item.amount}
                            </td>
                            <td data-title={i18n.summ()} className="number-td">
                                {item.price}
                            </td>
                        </tr>
                    )
                })}

                {order?.orderSupItems?.map((v, index) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                          <span>
                                                              {v.name[menu.i18n.defaultLang]}
                                                          </span>
                                </div>


                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {v.quantity}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.price / v.quantity / 100}
                            </td>
                            <td data-title={i18n.summ()} className="number-td">
                                {v.price / 100}
                            </td>
                        </tr>
                    )
                })}

                {(order?.charges || []).map((v) => {
                    return (
                        <tr>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                            <span>
                                                                {(v.charge.data.name || {})[menu.i18n.defaultLang]}
                                                            </span>
                                </div>
                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.amount / 100} {CURRENCY[order?.currency || ""]}

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <br/>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <p>
                    <strong>{i18n.total_summ()}</strong> :
                    {(order?.totalPrice || 0) / 100} {CURRENCY[order?.currency || ""]}
                </p>
            </div>
        </div>
    )
}