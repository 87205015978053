import {Link} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import kamiLogo from "../../resourse/img/kami_logo_small.png"
import {Image} from "../image/image";
import {SiteBarNavLink} from "./siteBarNavLink";
import {RouteNames} from "migration/pages";

export function SiteBarStaff() {
    const {i18n} = useGlobalContext()
    const {user} = useUserContext()
    return (
        <aside id="sidebar" className="app__sidebar sidebar">
            <div className="sidebar__header">
                <Link to="#" title="logo" className="logo">
                    <div className="logo__img">
                        <Image className={''} alt={'logo'} src={kamiLogo}/>
                    </div>
                    <div className="logo__slogan">
                        kami
                    </div>
                </Link>
            </div>
            <div className="sidebar__body">
                <ul id="sidebar-menu" className="sidebar__menu">

                    {user?.user?.isSuper && user?.isStaff
                        ?
                        <li className="sidebar__item">
                            <SiteBarNavLink
                                link={RouteNames.STAFF_DASHBOARDS}
                                title={i18n.dashboards()}
                                setShowSiteBar={() => {
                                }}
                                icon={
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                         transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)">
                                        <g id="SVGRepo_bgCarrier"></g>
                                        <g id="SVGRepo_tracerCarrier"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M17.9998 15.8369C19.4557 16.5683 20.704 17.742 21.6151 19.2096C21.7955 19.5003 21.8857 19.6456 21.9169 19.8468C21.9803 20.2558 21.7006 20.7585 21.3198 20.9204C21.1323 21 20.9215 21 20.4998 21M15.9998 11.5322C17.4816 10.7959 18.4998 9.26686 18.4998 7.5C18.4998 5.73314 17.4816 4.20411 15.9998 3.46776M13.9998 7.5C13.9998 9.98528 11.9851 12 9.49984 12C7.01455 12 4.99984 9.98528 4.99984 7.5C4.99984 5.01472 7.01455 3 9.49984 3C11.9851 3 13.9998 5.01472 13.9998 7.5ZM2.55907 18.9383C4.15337 16.5446 6.66921 15 9.49984 15C12.3305 15 14.8463 16.5446 16.4406 18.9383C16.7899 19.4628 16.9645 19.725 16.9444 20.0599C16.9287 20.3207 16.7578 20.64 16.5494 20.7976C16.2818 21 15.9137 21 15.1775 21H3.82219C3.08601 21 2.71791 21 2.45028 20.7976C2.24189 20.64 2.07092 20.3207 2.05527 20.0599C2.03517 19.725 2.2098 19.4628 2.55907 18.9383Z"
                                                stroke="#000000"></path>
                                        </g>
                                    </svg>
                                }
                            />
                        </li>
                        :
                        null
                    }

                    <li className="sidebar__item">
                        <SiteBarNavLink
                            link={"/staff/clients"}
                            title={i18n.clients()}
                            setShowSiteBar={() => ({})}
                            icon={
                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier"></g>
                                    <g id="SVGRepo_tracerCarrier"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M16 3.46776C17.4817 4.20411 18.5 5.73314 18.5 7.5C18.5 9.26686 17.4817 10.7959 16 11.5322M18 16.7664C19.5115 17.4503 20.8725 18.565 22 20M2 20C3.94649 17.5226 6.58918 16 9.5 16C12.4108 16 15.0535 17.5226 17 20M14 7.5C14 9.98528 11.9853 12 9.5 12C7.01472 12 5 9.98528 5 7.5C5 5.01472 7.01472 3 9.5 3C11.9853 3 14 5.01472 14 7.5Z"
                                            stroke="#646D7C"></path>
                                    </g>
                                </svg>
                            }
                        />
                    </li>

                    {user?.user?.isSuper
                        ?
                        <li className="sidebar__item">
                            <SiteBarNavLink
                                link={"/staff/managers"}
                                title={i18n.managers()}
                                setShowSiteBar={() => {
                                }}
                                icon={
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                         transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)">
                                        <g id="SVGRepo_bgCarrier"></g>
                                        <g id="SVGRepo_tracerCarrier"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M17.9998 15.8369C19.4557 16.5683 20.704 17.742 21.6151 19.2096C21.7955 19.5003 21.8857 19.6456 21.9169 19.8468C21.9803 20.2558 21.7006 20.7585 21.3198 20.9204C21.1323 21 20.9215 21 20.4998 21M15.9998 11.5322C17.4816 10.7959 18.4998 9.26686 18.4998 7.5C18.4998 5.73314 17.4816 4.20411 15.9998 3.46776M13.9998 7.5C13.9998 9.98528 11.9851 12 9.49984 12C7.01455 12 4.99984 9.98528 4.99984 7.5C4.99984 5.01472 7.01455 3 9.49984 3C11.9851 3 13.9998 5.01472 13.9998 7.5ZM2.55907 18.9383C4.15337 16.5446 6.66921 15 9.49984 15C12.3305 15 14.8463 16.5446 16.4406 18.9383C16.7899 19.4628 16.9645 19.725 16.9444 20.0599C16.9287 20.3207 16.7578 20.64 16.5494 20.7976C16.2818 21 15.9137 21 15.1775 21H3.82219C3.08601 21 2.71791 21 2.45028 20.7976C2.24189 20.64 2.07092 20.3207 2.05527 20.0599C2.03517 19.725 2.2098 19.4628 2.55907 18.9383Z"
                                                stroke="#000000"></path>
                                        </g>
                                    </svg>
                                }
                            />
                        </li>
                        :
                        null
                    }

                    {user?.user?.isSuper
                        ?
                        <li className="sidebar__item">
                            <SiteBarNavLink
                                link={"/staff/reports"}
                                title={i18n.reports()}
                                setShowSiteBar={() => {
                                }}
                                icon={
                                    <svg fill="#646D7C" width="32px" height="32px" viewBox="0 0 32 32"
                                         id="icon" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <defs>
                                            </defs>
                                            <title>report</title>
                                            <rect x="15" y="20" width="2" height="4"></rect>
                                            <rect x="20" y="18" width="2" height="6"></rect>
                                            <rect x="10" y="14" width="2" height="10"></rect>
                                            <path
                                                d="M25,5H22V4a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2V5H7A2,2,0,0,0,5,7V28a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V7A2,2,0,0,0,25,5ZM12,4h8V8H12ZM25,28H7V7h3v3H22V7h3Z"></path>
                                            <rect id="_Transparent_Rectangle_"
                                                  data-name="<Transparent Rectangle>" className="cls-1"
                                                  style={{fill: "none"}}
                                                  width="32" height="32"></rect>
                                        </g>
                                    </svg>
                                }
                            />
                        </li>
                        :
                        null
                    }
                </ul>
            </div>
        </aside>
    )
}
