import {FunctionComponent} from "react";
import classes from "./Loading.module.scss";

export const Loading: FunctionComponent = () => {
    return (
        <div className={classes.lds__roller__wrapper}>
            <div className={classes.lds__roller}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
