import {IOrderSupItem} from "../../models/orderSupItem/orderSupItem";
import {useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {I18nString} from "../../models/lang/i18nString";

export function useSupItems() {
    const [nowModal, setNowModal] = useState<IOrderSupItem | null>(null)

    const {api} = useGlobalContext()
    const {currentMenuId} = useUserContext()

    const [supItems, setSupItems] = useState<IOrderSupItem[]>([])
    const [reloadCount, setReloadCount] = useState<number>(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [updateLoading, setUpdateLoading] = useState<boolean>(false)

    useEffect(() => {
        let controller = new AbortController()
        api.getOrderSupItems({setLoading: setIsLoading, controller}, currentMenuId
        ).then((res) => {
            if (res.data) {
                setSupItems(res.data)
            }
        })
        return () => {
            controller.abort()
        }
    }, [currentMenuId, reloadCount])

    function onCreate(name: I18nString, price: number, minQuantity: number) {
        api.createOrderSupItem({setLoading: setUpdateLoading}, currentMenuId, name, price, minQuantity).then((res) => {
            if (res.data) {
                setNowModal(null)
                setReloadCount(reloadCount + 1)
            }
        })
    }

    function onUpdate(id: string, name: I18nString, price: number, minQuantity: number) {
        api.updateOrderSupItem({setLoading: setUpdateLoading}, currentMenuId, id, name, price, minQuantity).then((res) => {
            if (res.data) {
                setNowModal(null)
                setReloadCount(reloadCount + 1)
            }
        })
    }

    function reload() {
        setReloadCount(reloadCount + 1)
    }
    return {
        supItems,
        isLoading,
        updateLoading: updateLoading,
        onCreate,
        onUpdate,
        reload, now: nowModal,
        setNow: setNowModal
    }
}