import {LangKey, useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {ISettingsMenu} from "../../models/settings/settings";
import {addNotificationWithButton} from "../../utils/toast/toast";

export function useSettingsPriceList() {
    const {i18n, api} = useGlobalContext()
    const {menu, currentMenuId, user, setUser, setMenu, setCurrentMenuId} = useUserContext()
    const [settingsMenuInfo, setSettingsMenuInfo] = useState<ISettingsMenu>({
        defLang: "RU",
        additionallyLang: [],
        currency: '',
        timezone: ''
    })

    const [isNamePage, setIsNamePage] = useState(false)

    const [menuLink, setMenuLink] = useState('')

    const [menuInfo, setMenuInfo] = useState<{ name: string, link: string }>({name: '', link: ''})

    useEffect(() => {
        if (user?.org) {
            (user?.org.menus.menus || []).forEach((item) => {
                if (item.id === currentMenuId) {
                    setMenuInfo({name: item.name, link: item.link})
                    setMenuLink(item.link)
                }
            })
        }
    }, [user, currentMenuId])

    useEffect(() => {
        setSettingsMenuInfo({
            defLang: menu.i18n.defaultLang,
            additionallyLang: menu.i18n.otherLangs || [],
            currency: menu.i18n.currency,
            timezone: menu.i18n.timezone
        })
    }, [menu])

    function linkChanged() {
        return menuLink !== menuInfo.link
    }

    //Event
    function onChangeCurrency(currency: string) {
        setSettingsMenuInfo({...settingsMenuInfo, currency: currency})
    }

    function onChangeMenuLang(defLang: LangKey) {
        setSettingsMenuInfo({
            ...settingsMenuInfo,
            defLang: defLang,
            additionallyLang: settingsMenuInfo.additionallyLang.filter(lang => lang !== defLang)
        })
    }

    function onChangeAdditionallyLang(v: { value: LangKey; label: string; }[]) {
        setSettingsMenuInfo({...settingsMenuInfo, additionallyLang: v.map((value) => value.value)})
    }

    const [saveLoading, setSaveLoading] = useState(false)
    const [saveMenuInfoLoading, setSaveMenuInfoLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    async function onSubmit() {
        api.updateMenuI18n({setLoading: setSaveLoading}, {
            menuId: currentMenuId,
            defaultLang: settingsMenuInfo.defLang,
            otherLangs: settingsMenuInfo.additionallyLang,
            currency: settingsMenuInfo.currency,
            timezone: settingsMenuInfo.timezone
        }).then((res) => {
            if (res.success) {
                setMenu({
                    ...menu,
                    i18n: {
                        defaultLang: settingsMenuInfo.defLang,
                        otherLangs: settingsMenuInfo.additionallyLang,
                        currency: settingsMenuInfo.currency,
                        timezone: settingsMenuInfo.timezone
                    }
                })
            }
        })
    }

    function onCancel() {
        setSettingsMenuInfo({
            defLang: menu.i18n.defaultLang,
            additionallyLang: menu.i18n.otherLangs || [],
            currency: menu.i18n.currency,
            timezone: menu.i18n.timezone
        })
    }


    function onChangeMenuName(name: string) {
        setMenuInfo({...menuInfo, name: name})
    }

    function onChangeMenuLink(link: string) {
        setMenuInfo({...menuInfo, link: link})
    }

    async function onSubmitMenuInfo() {
        const save = () => {
            api.saveMenuNameAndLink({setLoading: setSaveMenuInfoLoading}, {
                menuId: currentMenuId,
                name: menuInfo.name,
                link: menuInfo.link
            }).then((res) => {
                if (res.success) {
                    if (user?.org) {
                        setUser({
                            ...user, org: {
                                ...user?.org, menus: {
                                    ...user?.org.menus, menus: (user?.org.menus.menus || []).map((item) => {
                                            if (item.id === currentMenuId) {
                                                return {...item, name: menuInfo.name, link: menuInfo.link}
                                            }
                                            return item
                                        }
                                    )
                                }
                            }
                        })
                    }

                }
            })
        }
        if (linkChanged()) {
            addNotificationWithButton({
                message: i18n.q_link_list(),
                onSubmit: save,
                loading: saveMenuInfoLoading,
                i18n
            })
        } else {
            save()
        }
    }

    function onCancelMenuInfo() {
        if (user?.org) {
            (user?.org.menus.menus || []).forEach((item) => {
                if (item.id === currentMenuId) {
                    setMenuInfo({name: item.name, link: item.link})
                }
            })
        }
    }

    function onDeleteMenu() {
        addNotificationWithButton({
            message: i18n.q_delete_menu(),
            onSubmit: () => {
                if (user?.org) {
                    api.archiveMenu({setLoading: setDeleteLoading}, currentMenuId, user?.org.id).then((res) => {
                        if (res.success && res.data) {
                            if (user?.org) {
                                setUser({
                                    ...user,
                                    org: {
                                        ...user?.org,
                                        menus: {
                                            ...user?.org.menus,
                                            defaultId: res.data.defaultId,
                                            menus: (user?.org?.menus.menus || []).filter((m) => m.id !== currentMenuId),
                                            all: (user?.org?.menus.all || []).filter((m) => m.menuId !== currentMenuId)
                                        }
                                    }
                                })
                            }
                            setCurrentMenuId(res.data.defaultId)
                        }
                    })
                }
            },
            loading: deleteLoading,
            i18n
        })
    }

    return {
        menu,
        isNamePage,
        setIsNamePage,
        settingsMenuInfo,
        menuInfo,
        currentMenuId,
        onChangeCurrency,
        onChangeMenuLang,
        onChangeAdditionallyLang,
        onSubmit,
        onCancel,
        onChangeMenuName,
        onChangeMenuLink,
        onSubmitMenuInfo,
        onCancelMenuInfo,
        onDeleteMenu,
        saveLoading,
        saveMenuInfoLoading,
        deleteLoading
    }
}