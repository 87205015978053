import React, {FunctionComponent, useMemo} from 'react';
import {Button, Input, Modal, Select} from "antd";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import classes from "./ModalBase.module.scss";
import {isEmpty} from "lodash";

interface ModalBaseProps {
    categoryOptions?: { value: string, label: string }[],
    associatedProductsList: string[],
    addAssociatedProduct: (itemId: string) => void,
    isOpen: boolean,
    onCancel: () => void,
    onSubmit: () => void,
    searchQuery: string,
    setSearchQuery: React.Dispatch<string>,
    title: string,
    isEditMode?: boolean,
    selectedItem?: string,
    setSelectedItem?: React.Dispatch<string>,
    clearCommonProductsList?: () => void,
}

export const ModalBase: FunctionComponent<ModalBaseProps> = (
    {
        categoryOptions,
        associatedProductsList,
        addAssociatedProduct,
        isOpen,
        onCancel,
        onSubmit,
        searchQuery,
        setSearchQuery,
        title,
        isEditMode,
        selectedItem,
        setSelectedItem,
        clearCommonProductsList,
    }
) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menu} = useTypedSelector(state => state.user);
    const {kamiMenuItemsList} = useTypedSelector(state => state.menuIiko);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);

    const kamiMenuItemsListOptions = useMemo<{ value: string, label: string }[]>(() => {
        return Object.keys(kamiMenuItemsList || {})?.filter(itemId => {
            if (!searchQuery) return true;
            return kamiMenuItemsList[itemId].name[menu?.i18n?.defaultLang].toLowerCase().includes(searchQuery.toLowerCase())
        })?.map(itemId => ({
            value: kamiMenuItemsList[itemId]?.id,
            label: kamiMenuItemsList[itemId]?.name?.[menu?.i18n?.defaultLang]
        })) || []
    }, [kamiMenuItemsList, currentLang, searchQuery]);

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            onOk={onSubmit}
            title={title}
            footer={
                clearCommonProductsList ? [
                    <Button key="close" onClick={onCancel}>
                        {txt.cancel[currentLang]}
                    </Button>,
                    <Button key="clear" type="primary" onClick={clearCommonProductsList} danger>
                        {txt.clear[currentLang]}
                    </Button>,
                    <Button key="submit" type="primary" onClick={onSubmit}>
                        {txt.save[currentLang]}
                    </Button>,
                ] : [
                    <Button key="close" onClick={onCancel}>
                        {txt.cancel[currentLang]}
                    </Button>,
                    <Button key="submit" type="primary" onClick={onSubmit}>
                        {txt.save[currentLang]}
                    </Button>,
                ]
            }
        >
            <div className={classes.modal__body}>
                {(!isEditMode && selectedItem !== undefined) && (
                    <Select
                        style={{width: "100%"}}
                        value={selectedItem}
                        onChange={(value) => setSelectedItem?.(value)}
                        options={
                            isEmpty(categoryOptions)
                                ? [{
                                    value: "",
                                    label: txt.select_product[currentLang]
                                }, ...kamiMenuItemsListOptions]?.filter(item => {
                                    return !Object.keys(crossSellingMapping?.products).includes(item.value);
                                })
                                : [{value: "", label: txt.select_category[currentLang]}, ...categoryOptions!]
                        }
                    />
                )}

                <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                    placeholder={txt.search[currentLang]}
                    style={{
                        opacity: (selectedItem || selectedItem === undefined) ? "" : ".5",
                        pointerEvents: (selectedItem || selectedItem === undefined) ? "auto" : "none"
                    }}
                />

                <div
                    className={classes.products__list}
                    style={{
                        opacity: (selectedItem || selectedItem === undefined) ? "" : ".5",
                        pointerEvents: (selectedItem || selectedItem === undefined) ? "auto" : "none"
                    }}
                >
                    {
                        !!kamiMenuItemsListOptions?.filter(item => {
                            if (!selectedItem) return true;
                            return selectedItem !== item.value
                        })?.length
                            ?
                            kamiMenuItemsListOptions?.filter(item => {
                                return selectedItem !== item.value
                            })?.map(item => (
                                <ProductListItem
                                    key={item.value}
                                    item={item}
                                    checked={associatedProductsList.includes(item.value)}
                                    setList={addAssociatedProduct}
                                />
                            ))
                            :
                            <div className={classes.empty}>
                                {txt.nothing_found[currentLang]}
                            </div>
                    }
                </div>
            </div>
        </Modal>
    );
};

interface ProductListItemProps {
    item: { value: string, label: string }
    checked: boolean
    setList: (itemId: string) => void
}

const ProductListItem: FunctionComponent<ProductListItemProps> = ({item, checked, setList}) => {
    return (
        <div className={classes.product__list__item}>
            <label className={classes.product__list__item__label}>
                <input
                    type={"checkbox"}
                    checked={checked}
                    onChange={() => setList(item.value)}
                    className={classes.product__list__item__checkbox}
                />
                {item.label}
            </label>
        </div>
    )
}
