import React, {FunctionComponent, useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {ColumnsType} from "antd/es/table";
import {Button, Card, Divider, Statistic, Table} from "antd";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {priceWithSpaceAndCurrency} from "migration/shared/lib/format/formatNumber";
import {RouteNames} from "../../index";
import {Breadcrumbs} from "../../../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext} from "../../../../hooks/globalContext";
import classes from "./Dashboards.module.scss";

interface DataType {
    key: React.Key
    url: string
    todayVisits: number
    allTimeVisits: number
}

export const Dashboards: FunctionComponent = () => {
    const navigate = useNavigate();
    const {i18n, userInfoLoading} = useGlobalContext();
    const {
        reportToday,
        reportDuringPeriod,
        reportDynamicsVisits,
        reportDynamicsActiveUsers,
        reportDynamicsNewUsers,
        reportMostVisitedMenus,
        isLoadingReportToday,
        isLoadingReportDuringPeriod,
        isLoadingReportDynamics,
        isLoadingReportDynamicsVisits,
        isLoadingReportDynamicsActiveUsers,
        isLoadingReportDynamicsNewUsers,
        isLoadingReportMostVisitedMenus
    } = useTypedSelector(state => state.dashboards);
    const {
        fetchReportToday,
        fetchReportDuringPeriod,
        fetchReportDynamicsVisits,
        fetchReportDynamicsActiveUsers,
        fetchReportDynamics,
        fetchReportMostVisitedMenus
    } = useActions();
    const [periodFrom, setPeriodFrom] = useState("");
    const [periodTo, setPeriodTo] = useState("");
    const [periodTitle, setPeriodTitle] = useState(`${periodFrom || new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toLocaleDateString()} - ${periodTo || new Date().toLocaleDateString()}`)
    const [dynamicsVisitsFrom, setDynamicsVisitsFrom] = useState("");
    const [dynamicsVisitsTo, setDynamicsVisitsTo] = useState("");
    const [dynamicsVisitsPeriodTitle, setDynamicsVisitsPeriodTitle] = useState(`${dynamicsVisitsFrom || new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toLocaleDateString()} - ${dynamicsVisitsTo || new Date().toLocaleDateString()}`);
    const [dynamicsActiveUsersFrom, setDynamicsActiveUsersFrom] = useState("");
    const [dynamicsActiveUsersTo, setDynamicsActiveUsersTo] = useState("");
    const [dynamicsActiveUsersPeriodTitle, setDynamicsActiveUsersPeriodTitle] = useState(`${dynamicsActiveUsersFrom || new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toLocaleDateString()} - ${dynamicsActiveUsersTo || new Date().toLocaleDateString()}`);

    const columns: ColumnsType<DataType> = [
        {
            title: "URL",
            dataIndex: "url",
            render: (value) => <a href={value} target={"_blank"}>{value}</a>
        },
        {
            title: "Посещения за все время",
            dataIndex: "allTimeVisits",
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.allTimeVisits - b.allTimeVisits,
            render: (value) => <div style={{width: "100%", textAlign: "end"}}>{priceWithSpaceAndCurrency(value)}</div>
        },
        {
            title: "Посещения за сегодня",
            dataIndex: "todayVisits",
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.todayVisits - b.todayVisits,
            render: (value) => <div style={{width: "100%", textAlign: "end"}}>{priceWithSpaceAndCurrency(value)}</div>
        },
    ];

    const data: DataType[] = reportMostVisitedMenus?.urls?.filter(item => !!item)?.map(
        (item, index) => {
            return {
                key: index,
                url: item,
                todayVisits: reportMostVisitedMenus.todayVisits[index],
                allTimeVisits: reportMostVisitedMenus.allTimeVisits[index]
            };
        }
    );

    const updatePeriod = () => {
        const from = new Date(periodFrom);
        const to = new Date(periodTo);
        if (!periodFrom || !periodTo) {
            Notify.Info({title: 'Выберите дату', message: ""})
            return;
        }
        if (from.getTime() > to.getTime()) {
            Notify.Info({title: 'Дата "От" должна быть раньше даты "До"', message: ""})
            return;
        }
        fetchReportDuringPeriod(
            {from: from.toISOString(), to: to.toISOString()},
            {navigate},
            new AbortController()
        );
        const fromDate = from.getDate() < 10 ? `0${from.getDate()}` : from.getDate();
        const fromMonth = from.getMonth() + 1 < 10 ? `0${from.getMonth() + 1}` : from.getMonth() + 1;
        const fromYear = from.getFullYear();

        const toDate = to.getDate() < 10 ? `0${to.getDate()}` : to.getDate();
        const toMonth = to.getMonth() + 1 < 10 ? `0${to.getMonth() + 1}` : to.getMonth() + 1;
        const toYear = to.getFullYear();
        setPeriodTitle(`${fromDate}/${fromMonth}/${fromYear} - ${toDate}/${toMonth}/${toYear}`);
    }

    const updateDynamicsVisits = () => {
        const from = new Date(dynamicsVisitsFrom);
        const to = new Date(dynamicsVisitsTo);
        if (!dynamicsVisitsFrom || !dynamicsVisitsTo) {
            Notify.Info({title: 'Выберите дату', message: ""})
            return;
        }
        if (from.getTime() > to.getTime()) {
            Notify.Info({title: 'Дата "От" должна быть раньше даты "До"', message: ""})
            return;
        }
        fetchReportDynamicsVisits(
            {from: from.toISOString(), to: to.toISOString()},
            {navigate},
            new AbortController()
        );
        const fromDate = from.getDate() < 10 ? `0${from.getDate()}` : from.getDate();
        const fromMonth = from.getMonth() + 1 < 10 ? `0${from.getMonth() + 1}` : from.getMonth() + 1;
        const fromYear = from.getFullYear();

        const toDate = to.getDate() < 10 ? `0${to.getDate()}` : to.getDate();
        const toMonth = to.getMonth() + 1 < 10 ? `0${to.getMonth() + 1}` : to.getMonth() + 1;
        const toYear = to.getFullYear();
        setDynamicsVisitsPeriodTitle(`${fromDate}/${fromMonth}/${fromYear} - ${toDate}/${toMonth}/${toYear}`);
    }

    const updateDynamicsActiveUsers = () => {
        const from = new Date(dynamicsActiveUsersFrom);
        const to = new Date(dynamicsActiveUsersTo);
        if (!dynamicsActiveUsersFrom || !dynamicsActiveUsersTo) {
            Notify.Info({title: 'Выберите дату', message: ""})
            return;
        }
        if (from.getTime() > to.getTime()) {
            Notify.Info({title: 'Дата "От" должна быть раньше даты "До"', message: ""})
            return;
        }
        fetchReportDynamicsActiveUsers(
            {
                from: from.toISOString(),
                to: to.toISOString()
            },
            {navigate},
            new AbortController()
        );
        const fromDate = from.getDate() < 10 ? `0${from.getDate()}` : from.getDate();
        const fromMonth = from.getMonth() + 1 < 10 ? `0${from.getMonth() + 1}` : from.getMonth() + 1;
        const fromYear = from.getFullYear();

        const toDate = to.getDate() < 10 ? `0${to.getDate()}` : to.getDate();
        const toMonth = to.getMonth() + 1 < 10 ? `0${to.getMonth() + 1}` : to.getMonth() + 1;
        const toYear = to.getFullYear();
        setDynamicsActiveUsersPeriodTitle(`${fromDate}/${fromMonth}/${fromYear} - ${toDate}/${toMonth}/${toYear}`);
    }

    useLayoutEffect(() => {
        if (isLoadingReportDynamicsVisits || isLoadingReportDynamics) return;

        let x = am4core.create("oDynamics-visits", am4charts.XYChart);
        x.logo.dispose();

        x.data = reportDynamicsVisits.visitDates?.map((item: string, index: number) => {
            return {
                date: item,
                value: reportDynamicsVisits.visits[index]
            }
        })

        x.language.locale["_date_millisecond"] = "mm:ss SSS";
        x.language.locale["_date_second"] = "HH:mm:ss";
        x.language.locale["_date_minute"] = "HH:mm";
        x.language.locale["_date_hour"] = "HH:mm";
        x.language.locale["_date_week"] = "ww";
        x.language.locale["_date_month"] = "MMM";
        x.language.locale["_date_year"] = "yyyy";

        // Create axes
        const dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.fontSize = 11;
        dateAxis.renderer.labels.template.rotation = -45;
        dateAxis.renderer.labels.template.horizontalCenter = "right";

        x.language.locale["_date_day"] = "dd/MM/yyyy";
        x.dateFormatter.dateFormat = "dd/MM/yyyy";

        const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        // Create series
        function createSeries(field: any, name: any) {
            const series = x.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            series.smoothing = "monotoneX";

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            return series;
        }

        createSeries("value", "Series");

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomX";

        return () => {
            x.dispose();
        }
    }, [reportDynamicsVisits.visits, reportDynamicsVisits.visitDates]);

    useLayoutEffect(() => {
        if (isLoadingReportDynamicsActiveUsers || isLoadingReportDynamics) return;

        let x = am4core.create("oDynamics-active-users", am4charts.XYChart);
        x.logo.dispose();

        x.data = reportDynamicsActiveUsers.activeUsersDates?.map((item: string, index: number) => {
            return {
                date: item,
                value: reportDynamicsActiveUsers.activeUsersCount[index]
            }
        })

        x.language.locale["_date_millisecond"] = "mm:ss SSS";
        x.language.locale["_date_second"] = "HH:mm:ss";
        x.language.locale["_date_minute"] = "HH:mm";
        x.language.locale["_date_hour"] = "HH:mm";
        x.language.locale["_date_week"] = "ww";
        x.language.locale["_date_month"] = "MMM";
        x.language.locale["_date_year"] = "yyyy";

        // Create axes
        const dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.fontSize = 11;
        dateAxis.renderer.labels.template.rotation = -45;
        dateAxis.renderer.labels.template.horizontalCenter = "right";

        x.language.locale["_date_day"] = "dd/MM/yyyy";
        x.dateFormatter.dateFormat = "dd/MM/yyyy";

        const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        // Create series
        function createSeries(field: any, name: any) {
            const series = x.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            series.smoothing = "monotoneX";

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            return series;
        }

        createSeries("value", "Series");

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomX";

        return () => {
            x.dispose();
        }
    }, [reportDynamicsActiveUsers.activeUsersCount, reportDynamicsActiveUsers.activeUsersDates]);

    useLayoutEffect(() => {
        if (isLoadingReportDynamicsNewUsers || isLoadingReportDynamics) return;

        let chart = am4core.create("oDynamics-new-users", am4charts.XYChart);
        chart.logo.dispose();

        const dates: string[] = [];
        const month: Date[]  = [];
        Array.from(Array(35).keys()).forEach((v, i) => month.push(new Date(new Date().getTime() - (i * 24 * 60 * 60 * 1000))));
        const startOfTheWeeks = month.filter(i => i.getDay() === 1);
        const endOfTheWeeks = month.filter(i => i.getDay() === 0);
        endOfTheWeeks.unshift(month[0]);
        endOfTheWeeks.pop();
        Array.from(Array(5).keys()).forEach((v, i) => dates.push(`${startOfTheWeeks[i].toLocaleDateString()} - ${endOfTheWeeks[i].toLocaleDateString()}`))

        chart.data = reportDynamicsNewUsers.newUsers?.map((item: number, index: number) => {
            return {
                period: dates[index],
                users: item
            }
        }).reverse();

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "period";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "users";
        series.dataFields.categoryX = "period";
        series.name = "Users";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

    }, [reportDynamicsNewUsers.newUsers]);

    useEffect(() => {
        const controller = new AbortController();
        fetchReportToday({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchReportDuringPeriod(
            {
                from: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString(),
                to: new Date().toISOString()
            }, {navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchReportDynamics(
            {
                from: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString(),
                to: new Date().toISOString()
            }, {navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchReportMostVisitedMenus({navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, []);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[{title: i18n.dashboards(), link: RouteNames.STAFF_DASHBOARDS, index: "bracer-0"}]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.statictics_of_kamiqr()}</h1>
                    </div>
                </div>

                <div className={classes.container}>
                    <CardStats
                        title={"Сегодня"}
                        loading={isLoadingReportToday}
                        visits={reportToday.visits}
                        avgTime={reportToday.avgTime}
                        cartAdditionCount={reportToday.cartAdditionCount}
                        cartAdditionSumm={reportToday.cartAdditionSumm}
                        ordersCount={reportToday.ordersCount}
                        ordersSumm={reportToday.ordersSumm}
                        feedbackVisited={reportToday.feedbackVisited}
                        feedbackSubmitted={reportToday.feedbackSubmitted}
                    />

                    <CardStats
                        title={`За период (${periodTitle})`}
                        loading={isLoadingReportDuringPeriod}
                        visits={reportDuringPeriod.visits}
                        avgTime={reportDuringPeriod.avgTime}
                        cartAdditionCount={reportDuringPeriod.cartAdditionCount}
                        cartAdditionSumm={reportDuringPeriod.cartAdditionSumm}
                        ordersCount={reportDuringPeriod.ordersCount}
                        ordersSumm={reportDuringPeriod.ordersSumm}
                        feedbackVisited={reportDuringPeriod.feedbackVisited}
                        feedbackSubmitted={reportDuringPeriod.feedbackSubmitted}
                        period={{
                            from: periodFrom,
                            setFrom: setPeriodFrom,
                            to: periodTo,
                            setTo: setPeriodTo,
                            updatePeriod: updatePeriod
                        }}
                    />

                    <ChartStats
                        id={"oDynamics-visits"}
                        title={`Динамика посещений всех меню (${dynamicsVisitsPeriodTitle})`}
                        loading={isLoadingReportDynamicsVisits}
                        period={{
                            from: dynamicsVisitsFrom,
                            setFrom: setDynamicsVisitsFrom,
                            to: dynamicsVisitsTo,
                            setTo: setDynamicsVisitsTo,
                            updatePeriod: updateDynamicsVisits
                        }}
                    />

                    <ChartStats
                        id={"oDynamics-active-users"}
                        title={`Активные юзеры (${dynamicsActiveUsersPeriodTitle})`}
                        loading={isLoadingReportDynamicsVisits}
                        period={{
                            from: dynamicsActiveUsersFrom,
                            setFrom: setDynamicsActiveUsersFrom,
                            to: dynamicsActiveUsersTo,
                            setTo: setDynamicsActiveUsersTo,
                            updatePeriod: updateDynamicsActiveUsers
                        }}
                    />

                    <ChartStats
                        id={"oDynamics-new-users"}
                        title={"Новые юзеры"}
                        loading={isLoadingReportDynamicsVisits}
                    />

                    <TableStats title={"Топ 100 меню"} data={data} columns={columns} loading={isLoadingReportMostVisitedMenus}/>
                </div>
            </main>
        </section>
    );
};

interface CardStatsProps {
    title: string
    loading: boolean
    visits: number
    avgTime: number
    cartAdditionCount: number
    cartAdditionSumm: number
    ordersCount: number
    ordersSumm: number
    feedbackVisited: number
    feedbackSubmitted: number
    period?: {
        from: string
        setFrom: React.Dispatch<string>
        to: string
        setTo: React.Dispatch<string>
        updatePeriod: () => void
    }
}

const CardStats: FunctionComponent<CardStatsProps> = (props) => {
    return (
        <Card
            title={props.title}
            bodyStyle={{padding: "10px"}}
            headStyle={{padding: "0 10px"}}
            style={{width: "fit-content"}}
        >
            {!isEmpty(props.period) && (
                <React.Fragment>
                    <div className={classes.period} style={{marginTop: "10px"}}>
                        <label className={classes.period__label}>
                            От
                            <input
                                value={props.period?.from}
                                onChange={(event) => props.period?.setFrom(event.target.value)}
                                type={"date"}
                                className={classes.period__input}
                            />
                        </label>

                        <label className={classes.period__label}>
                            До
                            <input
                                value={props.period?.to}
                                onChange={(event) => props.period?.setTo(event.target.value)}
                                type={"date"}
                                className={classes.period__input}
                            />
                        </label>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                props.period?.updatePeriod();
                            }}
                            className={classes.period__btn}
                            type={"primary"}
                        >
                            OK
                        </Button>
                    </div>
                    <Divider style={{margin: "10px 0"}}/>
                </React.Fragment>
            )}

            <div className={classes.card__content}>
                <Card
                    title={"Посещения"}
                    className={classes.card}
                    bodyStyle={{padding: "10px"}}
                    headStyle={{padding: "0 10px"}}
                    loading={props.loading}
                >
                    <Statistic
                        title="Количество"
                        value={priceWithSpaceAndCurrency(props.visits)}
                    />
                    <Divider style={{margin: "10px 0"}}/>
                    <Statistic
                        title="Время пребывания"
                        value={props.avgTime}/>
                </Card>
                <Card
                    title={"Добавлений в корзину"}
                    className={classes.card}
                    bodyStyle={{padding: "10px"}}
                    headStyle={{padding: "0 10px"}}
                    loading={props.loading}
                >
                    <Statistic
                        title="Количество"
                        value={priceWithSpaceAndCurrency(props.cartAdditionCount)}
                        precision={2}
                    />
                    <Divider style={{margin: "10px 0"}}/>
                    <Statistic
                        title="Сумма"
                        value={priceWithSpaceAndCurrency(props.cartAdditionSumm)}
                        precision={2}
                    />
                </Card>
                <Card
                    title={"Заказы"}
                    className={classes.card}
                    bodyStyle={{padding: "10px"}}
                    headStyle={{padding: "0 10px"}}
                    loading={props.loading}
                >
                    <Statistic
                        title="Количество"
                        value={priceWithSpaceAndCurrency(props.ordersCount)}
                        precision={2}
                    />
                    <Divider style={{margin: "10px 0"}}/>
                    <Statistic
                        title="Сумма"
                        value={priceWithSpaceAndCurrency(props.ordersSumm)}
                        precision={2}
                    />
                </Card>
                <Card
                    title={"Отзывы"}
                    className={classes.card}
                    bodyStyle={{padding: "10px"}}
                    headStyle={{padding: "0 10px"}}
                    loading={props.loading}
                >
                    <Statistic
                        title="Посетили"
                        value={priceWithSpaceAndCurrency(props.feedbackVisited)}
                    />
                    <Divider style={{margin: "10px 0"}}/>
                    <Statistic
                        title="Оставили отзыв"
                        value={priceWithSpaceAndCurrency(props.feedbackSubmitted)}
                    />
                </Card>
            </div>
        </Card>
    )
}

interface ChartStatsProps {
    id: string
    title: string
    loading: boolean
    period?: {
        from: string
        setFrom: React.Dispatch<string>
        to: string
        setTo: React.Dispatch<string>
        updatePeriod: () => void
    }
}

const ChartStats: FunctionComponent<ChartStatsProps> = (props) => {
    return (
        <Card
            title={props.title}
            bodyStyle={{padding: "10px"}}
            headStyle={{padding: "0 10px"}}
        >
            {!isEmpty(props.period) && (
                <React.Fragment>
                    <div className={classes.period} style={{marginTop: "10px"}}>
                        <label className={classes.period__label}>
                            От
                            <input
                                value={props.period?.from}
                                onChange={(event) => props.period?.setFrom(event.target.value)}
                                type={"date"}
                                className={classes.period__input}
                            />
                        </label>

                        <label className={classes.period__label}>
                            До
                            <input
                                value={props.period?.to}
                                onChange={(event) => props.period?.setTo(event.target.value)}
                                type={"date"}
                                className={classes.period__input}
                            />
                        </label>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                props.period?.updatePeriod();
                            }}
                            className={classes.period__btn}
                            type={"primary"}
                        >
                            OK
                        </Button>
                    </div>
                    <Divider style={{margin: "10px 0"}}/>
                </React.Fragment>
            )}

            <div id={props.id} style={{width: "100%", minHeight: '370px'}}></div>
        </Card>
    )
}

interface TableStatsProps {
    title: string
    loading: boolean
    columns: ColumnsType<any>
    data: any[]
}

function TableStats(props: TableStatsProps) {
    return (
        <Card
            title={props.title}
            bodyStyle={{padding: "10px"}}
            headStyle={{padding: "0 10px"}}
        >
            <Table
                loading={props.loading}
                columns={props.columns}
                dataSource={props.data}
                pagination={false}
                scroll={{ x: 300 }}
            />
        </Card>
    )
}
