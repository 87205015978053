import React from 'react'

import {ISlide} from "../../models/slider/slider";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {Image} from "../image/image";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

interface SliderDnDProps {
    slides: ISlide[],
    setSlides: React.Dispatch<React.SetStateAction<ISlide[]>>
    loading?: boolean
}

export function SliderDnD({slides, setSlides}: SliderDnDProps) {


    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;

        setSlides(reorder(slides, sourceIndex, destIndex))
    }

    return (
        <DragDropContext onDragEnd={(result, provided) => {
            onDragEnd(result, provided)
        }}>

            <Droppable droppableId="droppable" type="droppableSection">
                {(provided, snapshot) => (
                    <ul id="catalog" className="catalog" ref={provided.innerRef}>
                        {slides.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <li ref={provided.innerRef} className="catalog__item"
                                        data-id="884cdc5f-15a4-4ef8-b523-bcdfba31b045"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className="catalog__title">
                                            <div className="catalog__draggable-area"></div>
                                            <a href="#" className="catalog__text"
                                            >
                                                <Image className={"catalog__img"} alt={"catalog image"} src={item.img} type={"menuSlides"} />
                                                {item.url}
                                                <span></span>
                                            </a>
                                        </div>

                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>

        </DragDropContext>

    );
}
