import React, {FunctionComponent, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {HeaderUser} from "../../../../components/base/headerUser";
import {SiteBarUser} from "../../../../components/base/siteBarUser";
import {ChangePassword} from "../../../../pages/account/changePassword";
import {useUserContext} from "../../../../hooks/globalContext";
import classes from "./ModulesLayout.module.scss";

export const ModulesLayout: FunctionComponent = () => {
    const [showSiteBar, setShowSiteBar] = useState<boolean>(false)
    const {user, currentMenuId} = useUserContext()
    const [menuIsEmpty, setIsEmpty] = useState<boolean>(false)
    const [pswChanged, setPswChanged] = useState<boolean>(false)
    useEffect(() => {
        const all = user?.org?.menus.all
        if (!all) {
            if (user?.user) {
                setIsEmpty(true)
            }
        } else {
            setIsEmpty(all.length === 0)
        }
        if (Object.keys(user || {}).length > 0) {
            setPswChanged(!user?.isStaff && !user?.user?.tmpPswChanged)
        }
    }, [user])

    useEffect(() => {
        function keyDownHandler(e: KeyboardEvent) {
            if (e.key === 'S' && e.ctrlKey && e.shiftKey) {
                e.preventDefault()
                window.open(`${process.env.REACT_APP_API_URL?.replace("api", user?.org?.subdomain || "")}/menu/${user?.org?.menus.menus?.find((v) => v.id === currentMenuId)?.link}`)
            }
        }

        document.addEventListener("keydown", keyDownHandler)
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [user, currentMenuId])

    return (
        <React.Fragment>
            <HeaderUser setShowSiteBar={setShowSiteBar} menuIsEmpty={menuIsEmpty}/>
            <SiteBarUser showSiteBar={showSiteBar} setShowSiteBar={setShowSiteBar} menuIsEmpty={menuIsEmpty}/>
            <div className={classes.main}>
                {pswChanged ? <ChangePassword/> : <Outlet/>}
            </div>
        </React.Fragment>
    )
}
