import {range} from "../../models/settings/menu";

interface NumberInputProps {
    label: string;
    value: number;
    onChange: (value: number) => void;
    onBlur?: (value: number) => void;
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
    isRequired?: boolean;
}

export function NumberInput(props: NumberInputProps) {
    return (
        <div className="control">
            <div className="control__label">
                {props.label}
                {props.isRequired && <span className="required-star">*</span>}
            </div>
            <input type="number" className="control__input" step="1" placeholder={props.placeholder}
                   value={props.value.toString()}
                   style={props.style}
                   onChange={(e) => {
                       let num = Number(e.currentTarget.value.replace(/\D/g, ''))
                       if (isNaN(num)) {
                           num = 0
                       }
                       props.onChange(num)
                   }}
                   onBlur={() => {
                       if (props.onBlur) {
                           props.onBlur(props.value)
                       }
                   }}
            />
        </div>
    )
}

interface NumberInputRangeProps {
    fromLabel: string;
    toLabel: string;
    label: string;
    isRequired?: boolean;
    value: range;
    onChange: (value: range) => void;
}

export function NumberInputRange(props: NumberInputRangeProps) {
    return (
        <div className="control" style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <div className="control">
                <div className="control__label">
                    {props.fromLabel}
                    {props.isRequired && <span className="required-star">*</span>}
                </div>
                <input type="number" className="control__input" step="1" placeholder={props.fromLabel}
                       value={props.value.from.toString()}
                       onChange={(e) => {
                           let num = Number(e.currentTarget.value.replace(/\D/g, ''))
                           if (isNaN(num)) {
                               num = 0
                           }
                           const copyValue = {...props.value}
                           copyValue.from = num
                           props.onChange(copyValue)
                       }}
                />
            </div>
            <div className="control">
                <div className="control__label">
                    {props.toLabel}
                    {props.isRequired && <span className="required-star">*</span>}
                </div>
                <input type="number" className="control__input" step="1" placeholder={props.toLabel}
                       value={props.value.to.toString()}
                       onChange={(e) => {
                           let num = Number(e.currentTarget.value.replace(/\D/g, ''))
                           if (isNaN(num)) {
                               num = 0
                           }
                           const copyValue = {...props.value}
                           copyValue.to = num
                           props.onChange(copyValue)
                       }}
                />
            </div>
            <div className="control">
                <div className="control__label">
                    {props.label}
                    {<span className="required-star">*</span>}
                </div>
                <input type="number" className="control__input" step="1" placeholder={props.label}
                       value={props.value.value.toString()}
                       onChange={(e) => {
                           let num = Number(e.currentTarget.value.replace(/\D/g, ''))
                           if (isNaN(num)) {
                               num = 0
                           }
                           const copyValue = {...props.value}
                           copyValue.value = num
                           props.onChange(copyValue)
                       }}
                />
            </div>
        </div>
    )
}
