import {NavLink} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React, {FunctionComponent} from "react";
import {RouteNames} from "../../migration/pages";

export const SettingsTab: FunctionComponent = () => {
    const {i18n} = useGlobalContext();
    const {iikoActiveGeneral, user} = useUserContext();
    let menuOrders = user?.org?.availableModules?.includes("menuOrders");

    return (
        <ul className="nav default-tabs mb-2 mb-lg-3">
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link" end={true} to={RouteNames.SETTINGS}
                >
                    {i18n.menus()}
                </NavLink>
            </li>
            {menuOrders ?
                <>
                    <li className="default-tabs__item">
                        <NavLink className="default-tabs__link " end={true} to={RouteNames.SETTINGS_CLIENT_ORDERS}>
                            {i18n.co_module()}
                        </NavLink>
                    </li>
                    <li className="default-tabs__item">
                        <NavLink className="default-tabs__link " end={true} to={RouteNames.SETTINGS_NOTIFICATION}>
                            {i18n.notification()}
                        </NavLink>
                    </li>
                </>
                : null}
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link " end={true} to={RouteNames.SETTINGS_SCRIPTS}>
                    {i18n.scripts()}
                </NavLink>
            </li>
            <li className="default-tabs__item">
                <NavLink className="default-tabs__link " end={true} to={RouteNames.SETTINGS_PLACEMENTS}>
                    {i18n.placements()}
                </NavLink>
            </li>
        </ul>
    )
}
