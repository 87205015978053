import { useGlobalContext, useUserContext } from "../globalContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INotification } from "../../models/settings/notification";
import {RouteNames} from "../../migration/pages";

export function useNotification() {
    const { user, currentMenuId, menu } = useUserContext()
    const { api } = useGlobalContext()

    const [notification, setNotification] = useState<INotification | null>(null)
    const [disabledSubscribersList, setDisabledSubscribersList] = useState<{
        id: number,
        name: string
    }[]>([])
    const [maxLength, setMaxLength] = useState<number>(0)
    const [notificationReloader, setNotificationReloader] = useState<number>(0)

    const navigate = useNavigate()

    useEffect(() => {
        let menuOrders = user?.org?.availableModules?.includes("menuOrders")
        if (!menuOrders) {
            navigate(RouteNames.SETTINGS)
        }
    }, [])

    const [loading, setLoading] = useState<boolean>(false)
    const [telegramLoading, setTelegramLoading] = useState<boolean>(false)



    useEffect(() => {
        const controller = new AbortController()
        api.getOrderNotificationSettings({ controller, setLoading }, currentMenuId).then((res) => {
            if (res.data) {
                setNotification(res.data)
                if (res.data.telegram) {
                    setMaxLength(res.data.telegram.settings.subscribers?.length ||
                        res.data.telegram.settings.groupSubscribers?.length ||
                        Object.keys(res.data.telegram.settings.disabledSubscribers || {}).length)
                }
                if (res.data.telegram?.settings?.disabledSubscribers) {
                    setDisabledSubscribersList(Object.values(res.data.telegram.settings.disabledSubscribers))
                }
            }
        })
        return () => controller.abort()
    }, [currentMenuId, notificationReloader])

    function handleTelegramCheck(toggle: boolean) {
        api.switchTelegramMenuSettingsOrderNotification({ setLoading: setTelegramLoading }, currentMenuId, toggle).then((res) => {
            if (res.data) {
                setNotification((pre) => {
                    if (!pre) {
                        return {
                            telegram: {
                                isOn: toggle,
                                settings: {
                                    subscribers: null,
                                    groupSubscribers: null,
                                    disabledSubscribers: null
                                }

                            }
                        }
                    }
                    return {
                        ...pre,
                        telegram: {
                            ...pre.telegram,
                            isOn: toggle
                        }
                    }
                })
            }
        })
    }

    function disabledSubscribers(id: number) {
        api.disableUserMenuSettingsOrderNotification({ setLoading: setTelegramLoading }, currentMenuId, id).then((res) => {
            if (res.data) {
                setNotificationReloader((pre) => pre + 1)
            }
        })
    }

    function enableSubscribers(id: number) {
        api.enableUserMenuSettingsOrderNotification({ setLoading: setTelegramLoading }, currentMenuId, id).then((res) => {
            if (res.data) {
                setNotificationReloader((pre) => pre + 1)
            }
        })
    }
    return {
        menu,
        notification,
        handleTelegramCheck,
        loading,
        telegramLoading,
        maxLength,
        disabledSubscribersList,
        disabledSubscribers,
        enableSubscribers
    }
}
