import React from "react";
import styles from "./button.module.css";
import {LittleSpinner} from "../loader/littleSpinner";
interface DeleteButtonProps {
    /** async onSubmit Event, f is finally func */
    submitLoading: boolean,
    onSubmit: () => void,
    label: string
    modal?: boolean
    disabled?: boolean
}
export function DeleteButton(props: DeleteButtonProps) {
    return (
        <button type="submit" style={{color:"white"}}
                className={props.modal ?
                    "button -gray  modal__button" :
                    "button -gray form__button"}
                disabled={props.submitLoading||props.disabled}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.submitLoading) return;
                    props.onSubmit()}}
        >
            {props.submitLoading?
                <LittleSpinner/>
                :props.label}
        </button>
    );
}