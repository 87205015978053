import {Image} from "../image/image";
import React from "react";
import {useGlobalContext} from "../../hooks/globalContext";

interface ImageUploadComponentProps {
    img: string | null
    ImgType: 'orgLogos'|'menuSections'|'menuItems'|'menuSlides'|'menuOrgAttrsBackgrounds'|'menuOrgAttrsLogos';

    onUploadImage: (file: File | null) => void
    onDeleteImage: () => void

    imgUploading: boolean

    title?: string
    message?: string

    size?: "-big"
    type: "-slider" | "-logo"|"-section"
}

export function ImageUploadComponent(props: ImageUploadComponentProps) {
    const {i18n} = useGlobalContext()

    let className = "uploader"
    if (props.type) {
        className += " " + props.type
    }
    if (props.size) {
        className += " " + props.size
    }
    return (
        <>
            {props.title && <p className="tx-15 mb-2">{props.title}</p>}


            <div className={className} style={{width: "18rem", height: "18rem"}}>
                <label id="uploaderLabelPanel" className="uploader__area ">
                    <input type="hidden" name="old_img"/>
                    <div id="emptyImg" className="uploader__placeholder"
                         style={{display: props.img ? "none" : "block"}}>
                        <div className="uploader__icon">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z"
                                    fill="currentColor"></path>
                            </svg>
                        </div>
                        <div className="uploader__title">{i18n.upload_image()}</div>
                    </div>
                    <input type="file" className="uploader__input" id="uploaderFile"
                           data-crop-aspect-ratio="2.2" data-crop-img-max-width="1500"
                           data-crop-img-max-height="1500"
                           onChange={(e) => {
                               props.onUploadImage(e ? e.currentTarget ? e.currentTarget.files ? e.currentTarget.files.item(0) : null : null : null)
                               e.currentTarget.value = ''
                           }}
                    />
                    <div id="setImg" style={{display: props.img ? "block" : "none"}}>
                        <Image className={"uploader__img"} alt={'logo'} src={props.img as string}
                               type={props.ImgType}/>
                        <button type="button" onClick={() => {
                            props.onDeleteImage()
                        }}
                                className="uploader__delete">
                        </button>
                    </div>
                </label>
            </div>

            {props.message && <p className="tx-gray mt-4">{props.message}</p>}
        </>
    )
}
