import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {Feedback, FeedbackDashboardData} from "migration/entities/Feedback";
import {HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import {
    FeedbackDashboardDataActionEnum,
    SetFeedbackDashboardDataAction,
    SetFeedbacksAction,
    SetFeedbacksCountAction,
    SetIsLoadingGetFeedbackDashboardDataAction,
    SetIsLoadingGetFeedbacksAction
} from "./types";
import FeedbackPageService, {getFeedbacksByFiltersReq} from "../api/feedbackPageService";

export const FeedbackDashboardDataActionCreators = {
    setFeedbackDashboardData: (payload: FeedbackDashboardData): SetFeedbackDashboardDataAction => ({
        type: FeedbackDashboardDataActionEnum.SET_FEEDBACK_DASHBOARD_DATA,
        payload
    }),
    setFeedbacks: (payload: Feedback[]): SetFeedbacksAction => ({
        type: FeedbackDashboardDataActionEnum.SET_FEEDBACKS,
        payload
    }),
    setFeedbacksCount: (payload: number): SetFeedbacksCountAction => ({
        type: FeedbackDashboardDataActionEnum.SET_FEEDBACKS_COUNT,
        payload
    }),
    setIsLoadingGetFeedbackDashboardData: (payload: boolean): SetIsLoadingGetFeedbackDashboardDataAction => ({
        type: FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACK_DASHBOARD_DATA,
        payload
    }),
    setIsLoadingGetFeedbacks: (payload: boolean): SetIsLoadingGetFeedbacksAction => ({
        type: FeedbackDashboardDataActionEnum.SET_IS_LOADING_GET_FEEDBACKS,
        payload
    }),

    getFeedbackDashboardData: (feedbackPageId: string, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, FeedbackPageService.getFeedbackDashboardData(feedbackPageId, currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: FeedbackDashboardDataActionCreators.setIsLoadingGetFeedbackDashboardData,
            successCallback: (res) => {
                dispatch(FeedbackDashboardDataActionCreators.setFeedbackDashboardData(res.data?.data || {} as FeedbackDashboardData));
            },
        });
    },

    getFeedbacks: (request: getFeedbacksByFiltersReq, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, FeedbackPageService.getFeedbacks(request, currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: FeedbackDashboardDataActionCreators.setIsLoadingGetFeedbacks,
            successCallback: (res) => {
                dispatch(FeedbackDashboardDataActionCreators.setFeedbacks(res.data?.data?.feedbacks || []));
                dispatch(FeedbackDashboardDataActionCreators.setFeedbacksCount(res.data?.data?.totalCount || 0));
            },
        });
    }
}
