import {useGlobalContext, useUserContext} from "../globalContext";
import {IMenuCreate} from "../../models/menu/IMenuCreate";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../migration/pages";

export function useMenuForm() {
    const {api} = useGlobalContext()
    const {user, setUser, setCurrentMenuId, currentMenuId} = useUserContext()
    const navigate = useNavigate()
    const [menuInfo, setMenuInfo] = useState<IMenuCreate>({
        name: '',
        link: '',
        defaultLang: 'RU',
        otherLangs: [],
        currency: ''
    })
    const [saveLoading, setSaveLoading] = useState(false)


    function onChangeName(name: string) {
        setMenuInfo({...menuInfo, name: name})
    }

    function onChangeLink(link: string) {
        setMenuInfo({...menuInfo, link: link})
    }

    function onChangeDefaultLang(defaultLang: string) {
        setMenuInfo({...menuInfo, defaultLang: defaultLang})
    }

    function onChangeOtherLangs(otherLangs: string[]) {
        setMenuInfo({...menuInfo, otherLangs: otherLangs})
    }

    function onChangeCurrency(currency: string) {
        setMenuInfo({...menuInfo, currency: currency})
    }


    function onSubmit() {
        if (!user?.org) return
        api.createMenu({setLoading: setSaveLoading}, {
            organizationId: user?.org?.id as string,
            name: menuInfo.name,
            link: menuInfo.link,
            defaultLang: menuInfo.defaultLang,
            otherLangs: menuInfo.otherLangs,
            currency: menuInfo.currency
        }).then((res) => {
                if (res.success && res.data) {
                    if (user?.org) {
                        let menus = (user?.org.menus.menus || [])
                        menus.push({
                            id: res.data.newMenuId,
                            name: menuInfo.name,
                            link: menuInfo.link
                        })
                        let all = (user?.org.menus.all || [])
                        all.push({
                            menuId: res.data.newMenuId,
                            modulesActive: {},
                        })
                        setUser({
                                ...user,
                                org: {
                                    ...user?.org,
                                    menus: {
                                        ...user?.org.menus,
                                        menus: menus,
                                        all: all
                                    }
                                }
                            }
                        )
                    }
                    if (res.data) {
                        localStorage.setItem(user?.user?.id + "_" + user?.org?.id, res.data.newMenuId)
                        setCurrentMenuId(res.data.newMenuId)
                    }
                    navigate(RouteNames.SLIDER)
                }
            }
        )
    }

    return {
        user,
        menuInfo,
        currentMenuId,
        onChangeName,
        onChangeLink,
        onChangeDefaultLang,
        onChangeOtherLangs,
        onChangeCurrency,
        onSubmit,
        saveLoading
    }
}
