import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "migration/pages";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";

export function useLogin() {
    const navigate = useNavigate();
    const {isLoading} = useTypedSelector(state => state.auth);
    const {currentLang} = useTypedSelector(state => state.lang);
    const {isLoadingUser} = useTypedSelector(state => state.user);
    const {makeLogin} = useActions();
    const [user, setUser] = useState({username: "", password: ""});

    //Events
    const onChangeUsername = (username: string) => setUser({...user, username: username});

    const onChangePassword = (password: string) => setUser({...user, password: password});

    const onSubmit = async () => {
        if (user?.username === '' || user?.password === '') {
            Notify.Warning({title: txt.authorization[currentLang], message: txt.req_fields_not_filled[currentLang]})
            return;
        }
        makeLogin(user.username, user.password, {navigate: navigate, to: RouteNames.LOGIN});
    }

    return {user, onChangeUsername, onChangePassword, onSubmit, isLoading, isLoadingUser}
}
