import {LangsList} from "migration/entities/base/mlString";

export type LangState = {
    currentLang: LangsList;
    otherLangs: LangsList[];
}

export enum LangActionEnum {
    SET_LANG = "SET_LANG",
    SET_OTHER_LANGS = "SET_OTHER_LANGS",
}

export type SetLangAction = {
    type: LangActionEnum.SET_LANG;
    payload: LangsList;
}

export type SetOtherLangsAction = {
    type: LangActionEnum.SET_OTHER_LANGS;
    payload: LangsList[];
}

export type LangAction = SetLangAction | SetOtherLangsAction;
