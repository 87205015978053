import React, {useState} from "react";

interface ISearchProps {
    onSubmit: (s: string) => void
    label: string,
}

export function SearchHeader(props: ISearchProps): JSX.Element {
    const [find, setFind] = useState<string>("")
    return (
        <div className="search">
            <form style={{display: "flex"}}>
                <input type="search" className="search__input" placeholder={props.label}
                       value={find} onChange={(e) => setFind(e.target.value)}
                />
                <button type="submit"
                        className={"button modal__button -blue"}
                        onClick={(e) => {
                            e.preventDefault()
                            props.onSubmit(find)
                        }}
                >
                    {props.label}
                </button>
            </form>
        </div>
    )
}
