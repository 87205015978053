import {LangKey, useGlobalContext} from "../globalContext";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PaymentMethods, UserEditInfo} from "../../models/user/userEditInfo";

export function useClientForm() {
    const {api} = useGlobalContext()
    const navigate = useNavigate()
    let {id} = useParams()
    let [userinfo, setUserInfo] = useState<UserEditInfo>({
        id: "",
        user_id: "",
        org_name: "",
        organization_address: "",
        user_login: "",
        subdomain: "",
        defaultlang: "",
        can_use_client_orders: false,
        can_use_in_door_orders: false,
        can_use_iiko: false,
        can_use_rkeeper: false,
        can_use_payment: false,
        feedback: false
    })

    let [paymentMethods, setPaymentMethods] = useState<PaymentMethods>({onevision: false, liqpay: false, kaspi: false, jetpay: false})

    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (!id) return
        api.getOrgWithPrimaryUser({setLoading, controller}, id).then((res) => {
            if (res.success && res.data) {
                setUserInfo({
                    id: res.data.id,
                    user_id: res.data.userId,
                    org_name: res.data.orgName,
                    organization_address: res.data.orgAddress,
                    user_login: res.data.username,
                    subdomain: res.data.subdomain,
                    defaultlang: res.data.defLang,
                    can_use_client_orders: (res.data.modules || []).includes("menuOrders"),
                    can_use_in_door_orders: (res.data.modules || []).includes("indoorsOrders"),
                    can_use_iiko: (res.data.modules || []).includes("iiko"),
                    can_use_rkeeper: (res.data.modules || []).includes("rkeeper"),
                    can_use_payment: (res.data.modules || []).includes("payments"),
                    feedback: (res.data.modules || []).includes("feedbacks")
                })
                setPaymentMethods({
                    onevision: (res.data.availablePaymentMethods || []).includes("onevision"),
                    liqpay: (res.data.availablePaymentMethods || []).includes("liqpay"),
                    kaspi: (res.data.availablePaymentMethods || []).includes("kaspi"),
                    jetpay: (res.data.availablePaymentMethods || []).includes("jetpay")
                })
            }
        })
        return () => controller.abort()
    }, [])

    //Events
    function onChangeOrgName(org: string) {
        setUserInfo({
            ...userinfo, org_name: org
        })
    }

    function onChangeOrgAddress(address: string) {
        setUserInfo({
            ...userinfo, organization_address: address
        })
    }

    function onChangeLogin(login: string) {
        setUserInfo({
            ...userinfo, user_login: login
        })
    }

    function onChangeMenuLang(lang: LangKey) {
        setUserInfo({...userinfo, defaultlang: lang})
    }

    function onChangeCanUseClientOrders(can_use_client_orders: boolean) {
        setUserInfo({...userinfo, can_use_client_orders: can_use_client_orders})
    }

    function onChangeCanUseInDoorOrders(can_use_hybrid_pl: boolean) {
        setUserInfo({...userinfo, can_use_in_door_orders: can_use_hybrid_pl})
    }

    function onChangeCanUseIiko(can_use_iiko: boolean) {
        setUserInfo({...userinfo, can_use_iiko: can_use_iiko})
    }

    function onChangeCanUseRkeeper(can_use_rkeeper: boolean) {
        setUserInfo({...userinfo, can_use_rkeeper: can_use_rkeeper})
    }

    function onChangeCanUsePayment(can_use_payment: boolean) {
        setUserInfo({...userinfo, can_use_payment: can_use_payment})
    }

    function onChangeSubdomain(subdomain: string) {
        setUserInfo({...userinfo, subdomain: subdomain})
    }

    function onCheckOneVision(check: boolean) {
        setPaymentMethods({...paymentMethods, onevision: check})
    }

    function onCheckKaspi(check: boolean) {
        setPaymentMethods({...paymentMethods, kaspi: check})
    }

    function onCheckLiqpay(check: boolean) {
        setPaymentMethods({...paymentMethods, liqpay: check})
    }

    function onCheckJetpay(check: boolean) {
        setPaymentMethods({...paymentMethods, jetpay: check})
    }

    function onCheckFeedback(check: boolean) {
        setUserInfo({...userinfo, feedback: check})
    }

    async function onSubmit() {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let modules = []
        if (userinfo.can_use_client_orders) {
            modules.push("menuOrders")
        }
        if (userinfo.can_use_in_door_orders) {
            modules.push("indoorsOrders")
        }
        if (userinfo.can_use_iiko) {
            modules.push("iiko")
        }
        if (userinfo.can_use_rkeeper) {
            modules.push("rkeeper")
        }
        if (userinfo.can_use_payment) {
            modules.push("payments")
        }
        if (userinfo.feedback) {
            modules.push("feedbacks")
        }
        let pMethods: string[] = []
        if (paymentMethods.onevision) {
            pMethods.push("onevision")
        }
        if (paymentMethods.liqpay) {
            pMethods.push("liqpay")
        }
        if (paymentMethods.kaspi) {
            pMethods.push("kaspi")
        }
        if (paymentMethods.jetpay) {
            pMethods.push("jetpay")
        }
        if (id) {
            api.updateOrganization({setLoading: setSaveLoading}, {
                orgId: userinfo.id,
                userId: userinfo.user_id,
                orgName: userinfo.org_name,
                orgAddress: userinfo.organization_address,
                defLang: userinfo.defaultlang,
                subdomain: userinfo.subdomain,
                timezone: timeZone,
                modules: modules,
                username: userinfo.user_login,
                availablePaymentMethods: pMethods
            }).then((res) => {
                if (res.success) {
                    navigate("/staff/clients")
                }
            })
        } else {
            api.createOrganization({setLoading: setSaveLoading}, {
                orgName: userinfo.org_name,
                orgAddress: userinfo.organization_address,
                defLang: userinfo.defaultlang,
                subdomain: userinfo.subdomain,
                timezone: timeZone,
                modules: modules,
                username: userinfo.user_login,
                availablePaymentMethods: pMethods
            }).then((res) => {
                if (res.success) {
                    navigate("/staff/clients")
                }
            })
        }
    }

    return {
        id,
        userinfo,
        paymentMethods,
        onCheckOneVision,
        onCheckKaspi,
        onCheckLiqpay,
        onCheckJetpay,
        onChangeOrgName,
        onChangeOrgAddress,
        onChangeLogin,
        onChangeMenuLang,
        onChangeSubdomain,
        onChangeCanUseClientOrders,
        onChangeCanUseInDoorOrders,
        onChangeCanUseIiko,
        onChangeCanUseRkeeper,
        onChangeCanUsePayment,
        onCheckFeedback,
        onSubmit,
        loading, saveLoading
    }
}
