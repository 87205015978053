import {RouteNames, userRoutes} from "migration/pages";
import React, {FunctionComponent, useEffect, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Tab, Tabs} from "migration/shared/ui/Tabs";
import {Breadcrumbs} from "migration/shared/ui/Breadcrumbs";
import {BreadcrumbsData} from "migration/shared/ui/Breadcrumbs";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useUserContext} from "../../../../../hooks/globalContext";
import classes from "./RKeeper.module.scss";

export const RKeeper: FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {iikoActiveMenu, currentMenuId} = useUserContext();
    const {currentLang} = useTypedSelector(state => state.lang);

    const breadcrumbs: BreadcrumbsData[] = useMemo(() => {
        switch (location.pathname) {
            case RouteNames.R_KEEPER_MENU_SETTINGS:
                return [{title: txt.r_keeper_menu_settings[currentLang], link: RouteNames.R_KEEPER_MENU_SETTINGS}];
            default:
                return [{title: txt.r_keeper[currentLang], link: RouteNames.R_KEEPER}];
        }
    }, [location.pathname, currentLang]);

    const tabs: Tab[] = userRoutes.find(route => route.path === RouteNames.R_KEEPER)?.children?.map(route => {
        return {
            title: route.name[currentLang],
            link: route.path,
        };
    }) || [];

    useEffect(() => {
        if (location.pathname === RouteNames.R_KEEPER) {
            navigate(RouteNames.R_KEEPER_MENU_SETTINGS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, iikoActiveMenu]);

    useEffect(() => {
        navigate(RouteNames.R_KEEPER_MENU_SETTINGS);
    }, [currentMenuId]);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <div className={classes.container__header}>
                    <Breadcrumbs breadcrumbs={breadcrumbs}/>
                    <div className={classes.container__header__title}>
                        {breadcrumbs?.[breadcrumbs.length - 1]?.title}
                    </div>
                </div>

                <Tabs tabs={tabs}/>
            </div>
        </div>
    );
};
