import {FormRule} from "antd";

interface IRules {
    required: (message: string) => FormRule,
    email: (message: string) => FormRule,
    positiveNumber: (message: string) => FormRule,
}

export const rules: IRules = {
    required: (message: string) => ({required: true, message: message}),
    email: (message: string) => ({type: 'email', message: message}),
    positiveNumber: (message: string) => ({
        validator(_: any, value: number) {
            if (value === undefined || value === null) {
                return Promise.resolve();
            }
            if (value < 0) {
                return Promise.reject(message);
            }
            return Promise.resolve();
        }
    }),
}
