import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {ICustomer} from "../../models/orders/customer";

export function useCustomers() {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()

    const [customers, setCustomers] = useState<ICustomer[] | null>(null)
    const [customersFound, setCustomersFound] = useState<boolean>(false)
    const [pageSize] = useState<number>(20)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [fetchInfo, setFetchInfo] = useState<{ page: number, find: string }>({page: 0, find: ""})

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        api.getCustomers({controller, setLoading},user?.org?.id || "", currentMenuId, fetchInfo.find, pageSize, pageSize * fetchInfo.page).then((resp) => {
            if (resp.data) {
                setCustomers(resp.data?.customers || null)
                setTotalCount(resp.data.count)
                setCustomersFound(resp.data.count > 0)
            }
        })
        return () => controller.abort()
    }, [currentMenuId, fetchInfo, pageSize, user?.org?.id])

    function handleFind(value:string) {
        setFetchInfo({page: 0, find: value})
    }

    function setPage(page: number) {
        setFetchInfo({...fetchInfo, page})
    }

    return {
        customers,
        customersFound,
        handleFind,
        page: fetchInfo.page,
        menu,
        pageSize,
        totalCount,
        setPage,
        loading
    }
}