import {useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {useNavigate} from "react-router-dom";

export default function usePasswordInfo() {
    /** User password state */
    const [passwordInfo, setPasswordInfo] = useState<{ oldPass: string, newPass: string, reNewPass: string, newPassIsValid: boolean, reNewPassIsValid: boolean }>({
        oldPass: "",
        newPass: "",
        newPassIsValid: true,
        reNewPass: "",
        reNewPassIsValid: true
    })

    /** Global */
    const {api} = useGlobalContext()
    const {user, setUser} = useUserContext()

    const [loadingSave, setLoadingSave] = useState(false)

    /** Change old password method */
    function handleChangeOldPassword(password: string) {
        setPasswordInfo({...passwordInfo, oldPass: password})
    }

    /** Change new password method */
    function handleChangeNewPassword(new_pass: string) {
        let is_valid = new_pass.length >= 4
        setPasswordInfo({...passwordInfo, newPass: new_pass, newPassIsValid: is_valid})
    }

    /** Change repeat new password method */
    function handleChangeRepeatPassword(repeat_pass: string) {
        let is_valid = passwordInfo.newPass === repeat_pass
        setPasswordInfo({...passwordInfo, reNewPass: repeat_pass, reNewPassIsValid: is_valid})
    }

    /** Save new password method */
    function handleOnSubmit() {
        if (!user?.user) return
        api.changePassword({setLoading:setLoadingSave},{
            oldPassword: passwordInfo.oldPass,
            newPassword: passwordInfo.newPass,
            reNewPass: passwordInfo.reNewPass,
            userId: user?.user?.id
        }).then((res)=>{
            if (res.success) {
                setUser(null)
            }
        })
    }

    return {
        passwordInfo, handleChangeNewPassword,loadingSave,
        handleChangeOldPassword, handleChangeRepeatPassword, handleOnSubmit
    }
}