import React, {FunctionComponent} from "react";
import {useLogin} from "../../hooks/useLogin";
import {Loading} from "migration/shared/ui/Loading";
import {SubmitButton} from "../../../../../../components/input/submitButton";
import {PasswordInput} from "../../../../../../components/input/passwordInput";
import {InputText} from "../../../../../../components/input/inputText";
import {useGlobalContext} from "../../../../../../hooks/globalContext";
import {Image} from "../../../../../../components/image/image";
import Logo from "../../../../../../resourse/img/kami_logo_small.png";

export const LoginForm: FunctionComponent = () => {
    const {user, onChangeUsername, onChangePassword, onSubmit, isLoading, isLoadingUser} = useLogin()
    const {i18n} = useGlobalContext()

    if (isLoadingUser) {
        return <Loading/>
    }

    return (
        <div className="login__inner">
            <div className="login__logo">
                <Image className={""} alt={"logo"} src={Logo}/>
            </div>
            <form id="login-form" className="login__form">
                <div className="login__header">
                    <h2 className="login__header-title">{i18n.welcome()}
                    </h2>
                </div>

                <InputText
                    label={i18n.login()}
                    value={user?.username}
                    onChange={onChangeUsername}
                    placeholder={i18n.enter_login()}
                    isValidate={true}
                />

                <PasswordInput
                    password={user?.password}
                    onChangePassword={onChangePassword}
                    label={i18n.password()}
                    placeholder={i18n.enter_password()}
                    isValid={true}
                />

                <SubmitButton
                    onSubmit={onSubmit}
                    label={i18n.log_in()}
                    login={true}
                    disabled={false}
                    isLoading={isLoading}
                />
            </form>
        </div>
    )
}
