import React, {FunctionComponent, useEffect, useState} from 'react';
import {DeleteOutlined} from "@ant-design/icons";
import {Button, Form, Input} from "antd";
import {TYLink} from "migration/entities/Feedback";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {MlStringCompare} from "migration/shared/lib/ml-string/ml-string-compare";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {TextI18n} from "../../../../../../../components/input/textI18n";
import classes from "./TYLink.module.scss";

interface TyLinkProps {
    link: TYLink;
    index: number;
    saveTYLink: (TYLink: TYLink) => void;
    removeLink: (TYLinkId: string) => void;
    attachImage: (TYLinkId: string, file: File | null, setTYLinkImg: (img: {
        fileId: string,
        filename: string
    }) => void) => void;
    detachImage: (TYLinkId: string, setTYLinkImg: (img: { fileId: string, filename: string }) => void) => void;
}

export const TyLink: FunctionComponent<TyLinkProps> = (
    {
        link,
        index,
        saveTYLink,
        removeLink,
        attachImage,
        detachImage
    }
) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {feedbackPage} = useTypedSelector(state => state.feedback);

    const [isChangesExist, setIsChangesExist] = useState<boolean>(false);
    const [TYLabelText, setTYLabelText] = useState<MlString>({} as MlString);
    const [TYBtnText, setTYBtnText] = useState<MlString>({} as MlString);
    const [TYLinkURL, setTYLinkURL] = useState<string>("");
    const [TYLinkImg, setTYLinkImg] = useState<{ fileId: string, filename: string }>({fileId: "", filename: ""});

    const handleTYLinkSave = () => {
        if (!isChangesExist) return;
        saveTYLink({
            TYLinkId: link?.TYLinkId,
            TYLinkCaption: TYBtnText,
            TYLinkText: TYLabelText,
            TYLinkURL: TYLinkURL,
            TYLinkImg: TYLinkImg,
        });
    }

    useEffect(() => {
        if (
            MlStringCompare(TYLabelText, link.TYLinkText) &&
            MlStringCompare(TYBtnText, link.TYLinkCaption) &&
            TYLinkURL === link.TYLinkURL &&
            TYLinkImg.filename === (link.TYLinkImg?.filename || "")
        ) {
            setIsChangesExist(false);
        } else {
            setIsChangesExist(true);
        }
    }, [TYLabelText, TYBtnText, TYLinkURL, TYLinkImg, link, feedbackPage]);

    useEffect(() => {
        if (link?.TYLinkId) {
            setTYLinkImg({fileId: link?.TYLinkImg?.fileId || "", filename: link?.TYLinkImg?.filename || ""});
            setTYLinkURL(link.TYLinkURL || "");
            setTYLabelText(link.TYLinkText || {} as MlString);
            setTYBtnText(link.TYLinkCaption || {} as MlString);
        }
    }, [link, feedbackPage]);

    return (
        <div className={classes.link__item} key={link.TYLinkId}>
            <div className={classes.link__item__header}>
                <div className={classes.link__item__header__index}>{`#${index + 1}`}</div>
                <DeleteOutlined
                    onClick={() => removeLink(link?.TYLinkId)}
                    className={classes.link__item__header__delete}
                />
            </div>

            <Form.Item
                label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.link_desc[currentLang]}</p>}
                required={true}
            >
                <label className={classes.link__label}>
                    <TextI18n
                        values={TYLabelText}
                        setValues={setTYLabelText as (c: { [key: string]: string }) => void}
                        lang={feedbackPage?.defaultLang}
                        allLang={feedbackPage?.otherLangs || []}
                        isStaff={true}
                        placeholder={txt.enter_link_desc[currentLang]}
                    />
                </label>
            </Form.Item>

            <Form.Item
                label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.link_btn_label[currentLang]}</p>}
                required={true}
            >
                <label className={classes.link__label}>
                    <TextI18n
                        values={TYBtnText}
                        setValues={setTYBtnText as (c: { [key: string]: string }) => void}
                        lang={feedbackPage?.defaultLang}
                        allLang={feedbackPage?.otherLangs || []}
                        isStaff={true}
                        placeholder={txt.enter_link_btn_label[currentLang]}
                    />
                </label>
            </Form.Item>

            <Form.Item
                label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.link[currentLang]}</p>}
                required={true}
            >
                <Input
                    value={TYLinkURL}
                    onChange={(e) => setTYLinkURL(e.target.value)}
                    placeholder={txt.enter_link[currentLang]}
                />
            </Form.Item>

            <Form.Item
                label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.image[currentLang]}</p>}
                style={{marginBottom: "10px"}}
            >
                {!!TYLinkImg?.filename
                    ?
                    <div className={classes.image} style={{cursor: "default"}}>
                        <img
                            alt="link-view"
                            className={classes.image__img}
                            src={!!TYLinkImg?.filename ? `${process.env.REACT_APP_IMAGE_URL}feedback-page-link-img/${TYLinkImg?.filename}` : ""}
                        />
                        <DeleteOutlined onClick={() => detachImage(link.TYLinkId, setTYLinkImg)}
                                        className={classes.image__delete}/>
                    </div>
                    :
                    <label className={classes.image}>
                        <div>{txt.upload_image[currentLang]}</div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => attachImage(link.TYLinkId, e?.target?.files?.[0] || null, setTYLinkImg)}
                            style={{display: "none"}}
                        />
                    </label>
                }
            </Form.Item>

            <Form.Item>
                <Button type={"primary"} onClick={handleTYLinkSave} disabled={!isChangesExist}>
                    {txt.save[currentLang]}
                </Button>
            </Form.Item>
        </div>
    );
};
