import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect, useLayoutEffect} from "react";
import {useGlobalContext} from "../../hooks/globalContext";
import {Pagination} from "../../components/pagination/pagination";
import {useReports} from "../../hooks/staff/reports";
import {IReport} from "../../models/reports/reports";
import {ReportSearchHeader} from "../../components/input/reportSearch";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";


export function ReportsPage() {
    const info = useReports()

    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.reports()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[{link: "/staff/managers", title: i18n.reports(), index: "breadcrumbs-id"}]}/>
                <div className="default-card">

                    <div className="default-card__top-row">
                        <ReportSearchHeader onSubmit={info.onFind}/>


                        <div className="results-text d-none d-lg-block d-sm-block">
                            {i18n.all()}:
                            <span className="search-count">{info.count}</span>
                        </div>


                    </div>


                    <div className="default-table">
                        <table id="clients-table">
                            <thead>
                            <tr>
                                <th>{i18n.org_name()}</th>
                                <th>{i18n.menu_name()}</th>
                                <th>{i18n.link()}</th>
                                <th>{i18n.visits()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader columns={4} rows={10} isLoading={info.loading}
                                             heights={[null, null, null, 100]}>

                                {info.reports.map((report) =>
                                    <ReportTd report={report} key={report.menuId}
                                    />)}

                            </TBodyWithLoader>
                        </table>
                    </div>

                    <Pagination page={info.page} all={info.count} setPage={info.setPage} withdraw={info.step}/>
                </div>

            </main>
        </section>
    )
}

interface IReportTd {
    report: IReport,
}

function ReportTd(props: IReportTd) {
    const {i18n} = useGlobalContext()
    // oHeat Chart
    useLayoutEffect(() => {
        let x = am4core.create("oDynamics-" + props.report.menuId, am4charts.XYChart);
        x.logo.dispose();

        x.data = props.report.visits.map((item) => {
            return {
                date: item.date,
                value: item.count,
            }
        })

        x.language.locale["_date_millisecond"] = "mm:ss SSS";
        x.language.locale["_date_second"] = "HH:mm:ss";
        x.language.locale["_date_minute"] = "HH:mm";
        x.language.locale["_date_hour"] = "HH:mm";
        x.language.locale["_date_week"] = "ww";
        x.language.locale["_date_month"] = "MMM";
        x.language.locale["_date_year"] = "yyyy";

        // Create axes
        const dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.fontSize = 11;
        dateAxis.renderer.labels.template.rotation = -45;
        dateAxis.renderer.labels.template.horizontalCenter = "right";

        x.language.locale["_date_day"] = "dd/MM/yyyy";
        x.dateFormatter.dateFormat = "dd/MM/yyyy";

        const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        // Create series
        function createSeries(field: any, name: any) {
            const series = x.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            series.tensionX = 0.8;

            series.smoothing = "monotoneX";

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            return series;
        }

        createSeries("value", `${i18n.visits()}`);

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomX";

        return () => {
            x.dispose();
        }
    }, [props.report]);

    return (
        <tr>
            <td>
                {props.report.orgName}
            </td>
            <td>

                {props.report.name}

            </td>
            <td>
                {props.report.link}
            </td>
            <td>
                <div id={"oDynamics-" + props.report.menuId} style={{width: "100%", height: "200px"}}></div>
            </td>
        </tr>
    )
}