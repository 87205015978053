import {Outlet} from "react-router-dom";
import {HeaderUser} from "../../components/base/headerUser";
import {SiteBarUser} from "../../components/base/siteBarUser";
import React, {useEffect, useState} from "react";
import {useUserContext} from "../../hooks/globalContext";
import {EmptyMenus} from "../menus/emptyMenus";
import {ChangePassword} from "../account/changePassword";


export function BaseUser() {
    const [showSiteBar, setShowSiteBar] = useState<boolean>(false)
    const {user, currentMenuId} = useUserContext()
    const [menuIsEmpty, setIsEmpty] = useState<boolean>(false)
    const [pswChanged, setPswChanged] = useState<boolean>(false)
    useEffect(() => {
        const all = user?.org?.menus.all
        if (!all) {
            if (user?.user) {
                setIsEmpty(true)
            }
        } else {
            setIsEmpty(all.length === 0)
        }
        if (Object.keys(user || {}).length > 0) {
            setPswChanged(!user?.isStaff && !user?.user?.tmpPswChanged)
        }
    }, [user])

    useEffect(() => {
        function keyDownHandler(e: KeyboardEvent) {
            if (e.key === 'S' && e.ctrlKey && e.shiftKey) {
                e.preventDefault()
                window.open(`${process.env.REACT_APP_API_URL?.replace("api", user?.org?.subdomain || "")}/menu/${user?.org?.menus.menus?.find((v) => v.id === currentMenuId)?.link}`)
            }
        }
        document.addEventListener("keydown", keyDownHandler)
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [user, currentMenuId])

    return (
        <>
            <HeaderUser setShowSiteBar={setShowSiteBar} menuIsEmpty={menuIsEmpty}/>
            <SiteBarUser showSiteBar={showSiteBar} setShowSiteBar={setShowSiteBar} menuIsEmpty={menuIsEmpty}/>
            {pswChanged ?
                <ChangePassword/>
                :
                menuIsEmpty && !window.location.pathname.includes("/menu/create") ?
                    <EmptyMenus/>
                    :
                    <Outlet/>
            }
        </>
    )
}