import React from "react";

interface CustomRadioProps {
    label: string;
    value: string;
    isChecked: boolean;
    onChange: () => void;
}

export function CustomRadio(props: CustomRadioProps) {
    return (
        <div className="control">
            <label className="custom-radio">
                <input type="radio" value={props.value} checked={props.isChecked} onChange={props.onChange}/>
                <span>{props.label}</span>
            </label>
        </div>
    );
}