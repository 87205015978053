import {MentionsMapping} from "migration/entities/mentions";

export type ExcelFileUploadError = {success: boolean, message: string};

export interface MenuState {
    excelFileUploadError: ExcelFileUploadError;
    mentionsMapping: MentionsMapping;
    isLoadingExcelFile: boolean;
    isLoadingGetMentionsMapping: boolean;
}

export enum MenuActionEnum {
    SET_IS_LOADING_EXCEL_FILE = "SET_IS_LOADING_EXCEL_FILE",
    SET_EXCEL_FILE_UPLOAD_ERROR = "SET_EXCEL_FILE_UPLOAD_ERROR",
    SET_MENTIONS_MAPPING = "SET_MENTIONS_MAPPING",
    SET_IS_LOADING_GET_MENTIONS_MAPPING = "SET_IS_LOADING_GET_MENTIONS_MAPPING",
}

export interface SetIsLoadingExcelFileAction {
    type: MenuActionEnum.SET_IS_LOADING_EXCEL_FILE;
    payload: boolean;
}

export interface SetExcelFileUploadErrorAction {
    type: MenuActionEnum.SET_EXCEL_FILE_UPLOAD_ERROR;
    payload: ExcelFileUploadError;
}

export interface SetMentionsMappingAction {
    type: MenuActionEnum.SET_MENTIONS_MAPPING;
    payload: MentionsMapping;
}

export interface SetIsLoadingGetMentionsMapping {
    type: MenuActionEnum.SET_IS_LOADING_GET_MENTIONS_MAPPING;
    payload: boolean;
}

export type MenuAction =
    SetIsLoadingExcelFileAction |
    SetExcelFileUploadErrorAction |
    SetMentionsMappingAction |
    SetIsLoadingGetMentionsMapping;
