import {UserAction, UserActionEnum, UserState} from "./types";
import {ICurrentMenuInfo} from "../../../../../models/menu/menu";

export const initialState: UserState = {
    currentUser: null,
    isAuth: false,
    isLoadingUser: false,

    menu: {
        number: "00000000",
        i18n: {
            defaultLang: "RU",
            otherLangs: [],
            currency: "KZT",
            timezone: "Asia/Almaty"
        }
    } as ICurrentMenuInfo,
    currentMenuId: "",
    isLoadingMenu: false,

    iikoActiveGeneral: false,
    iikoActiveMenu: false,
}

export default function userReducer(state = initialState, action: UserAction): UserState {
    switch (action.type) {
        case UserActionEnum.SET_CURRENT_USER:
            return {...state, currentUser: action.payload}
        case UserActionEnum.SET_AUTH:
            return {...state, isAuth: action.payload}
        case UserActionEnum.SET_IS_LOADING_USER:
            return {...state, isLoadingUser: action.payload}
        case UserActionEnum.SET_MENU:
            return {...state, menu: action.payload}
        case UserActionEnum.SET_CURRENT_MENU_ID:
            return {...state, currentMenuId: action.payload}
        case UserActionEnum.SET_IS_LOADING_MENU:
            return {...state, isLoadingMenu: action.payload}
        case UserActionEnum.SET_IIKO_ACTIVE_GENERAL:
            return {...state, iikoActiveGeneral: action.payload}
        case UserActionEnum.SET_IIKO_ACTIVE_MENU:
            return {...state, iikoActiveMenu: action.payload}
        default:
            return state;
    }
}
