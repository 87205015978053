import React from "react";

interface CheckboxProps {
    checked: boolean;
    onChange?: (checked: boolean) => void;
    onClick?: (e: React.MouseEvent) => void;
    label: string;
    children?: React.ReactNode;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export function Checkbox(props: CheckboxProps) {
    return (
        <div className="control" style={props.style}>
            <label className="custom-checkbox">
                <input
                    type="checkbox"
                    name="orders_checkbox"
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={() => props.onChange ? props.onChange(!props.checked) : {}}
                    onClick={props.onClick}
                    style={{cursor: "pointer"}}
                />
                <span>{props.label}</span> {props.children}
            </label>
        </div>
    )
}
