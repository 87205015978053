import {CategoryForm} from "../../components/form/categoryForm";
import {useGlobalContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {RouteNames} from "../../migration/pages";

export function CategoryEdit() {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.category_editing()
    }, [i18n])
    let {id} = useParams()

    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.menus(), link: RouteNames.MENUS, index: "brcr-0"},
                    {title: i18n.edit_category(), link: RouteNames.MENUS_CATEGORY_EDIT.replace(":id", id || ""), index: "brcr-1"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.category()}

                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">
                            <CategoryForm edit={true}/>
                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
