import React, {useCallback, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {IOrderItemChange, IOrderSupItemChange} from "../../models/orders/order";
import Select from "react-select";
import {CURRENCY} from "../../utils/options/options";
import {TextI18n} from "../input/textI18n";
import {PriceInput} from "../input/priceInput";
import {I18nString} from "../../models/lang/i18nString";
import {NumberInput} from "../input/numberInput";
import {IOrderSupItem} from "../../models/orderSupItem/orderSupItem";
import {InputText} from "../input/inputText";

interface ModalEditSupItemProps {
    onApproveSaveItem: () => void
    modalInfo: IOrderSupItem | null
    setModalInfo: React.Dispatch<React.SetStateAction<IOrderSupItem | null>>
}

export function ModalEditSupItem({modalInfo, setModalInfo, onApproveSaveItem}: ModalEditSupItemProps) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const modalRef = useRef(null)

    useOnClickOutside(modalRef, () => setModalInfo(null), "mouseup")

    useEffect(() => {
        if (modalInfo) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [modalInfo])

    function sumPrice(): number {
        let sum = modalInfo ? (modalInfo.price) : 0
        if (typeof modalInfo?.quantity === "number") {
            sum *= modalInfo.quantity
        }
        return sum
    }

    const onChangeName = useCallback((name: I18nString) => {
        if (modalInfo) {
            setModalInfo({...modalInfo, name})
        }
    }, [modalInfo, setModalInfo])

    return (
        <>
            <div className={modalInfo ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 id="modal-preview" style={{display: modalInfo ? "block" : "none"}}
                 aria-hidden={!!modalInfo}>
                {modalInfo ?
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div ref={modalRef} className="modal-content">
                            <div className="modal-body px-3 py-4 p-lg-5">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                        onClick={() => setModalInfo(null)}
                                ></button>
                                <div id="item-preview-content">

                                    <form className="form">
                                        <div className="lang-tab" style={{display: "block"}}>
                                            <h2 className="tx-semibold mb-4">
                                                <span>{i18n.pl_item()}</span>
                                            </h2>
                                        </div>
                                        <div className="lang-tab" style={{display: "block"}}>
                                            <div className="control">
                                                {/*<Select options={items}*/}
                                                {/*        onChange={(v) => {*/}
                                                {/*            if (v) {*/}
                                                {/*                let item = v*/}
                                                {/*                v.quantity = 1*/}
                                                {/*                setModalInfo(item)*/}
                                                {/*            }*/}
                                                {/*        }}*/}
                                                {/*        isOptionSelected={(v) => v.id === modalInfo?.id}*/}
                                                {/*        value={*/}
                                                {/*            items.filter((v) => v.id === modalInfo?.id)*/}
                                                {/*        }*/}
                                                {/*/>*/}
                                                <TextI18n label={i18n.name()} values={modalInfo?.name || {}}
                                                          allLang={menu.i18n.otherLangs || []}
                                                          setValues={onChangeName} lang={menu.i18n.defaultLang}
                                                          isStaff={!!user?.isStaff}/>
                                            </div>


                                            <h3>{i18n.price()}</h3>
                                            <div className={"row"}>
                                                {/*<div className="col-md-5">*/}
                                                {/*    <TextI18n values={modalInfo.prices.primary.label} isStaff={!!user?.isStaff}*/}
                                                {/*              setValues={(label) => {*/}
                                                {/*                  setModalInfo({*/}
                                                {/*                      ...modalInfo,*/}
                                                {/*                      prices: {*/}
                                                {/*                          ...modalInfo?.prices,*/}
                                                {/*                          primary: {*/}
                                                {/*                              ...modalInfo?.prices.primary,*/}
                                                {/*                              label: label*/}
                                                {/*                          }*/}
                                                {/*                      }*/}
                                                {/*                  })*/}
                                                {/*              }} label={i18n.item_name()}*/}
                                                {/*              lang={menu.i18n.defaultLang}*/}
                                                {/*              allLang={menu.i18n.otherLangs || []}*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                                <div className="col-md-4">
                                                    <PriceInput label={i18n.price()}
                                                                value={modalInfo.price}
                                                                onChange={(v) => {
                                                                    setModalInfo({
                                                                        ...modalInfo,
                                                                        price: v
                                                                    })
                                                                }}/>
                                                </div>
                                                <div className="col-md-3">
                                                    <NumberInput label={i18n.amount()}
                                                                 value={modalInfo.quantity || 0}
                                                                 onChange={(n) => {
                                                                     setModalInfo({
                                                                         ...modalInfo,
                                                                         quantity: n
                                                                     })
                                                                 }
                                                                 }/>
                                                </div>
                                            </div>

                                            <div className="row" style={{marginLeft: "0px"}}>
                                                <></>
                                                <span>{i18n.total()}: {sumPrice() / 100} {CURRENCY[menu.i18n.currency]}</span>
                                            </div>


                                            <div className="form__buttons mt-4">
                                                <div className="form__buttons-left">
                                                    <Link to={"#"}
                                                          className="button -blue modal__button"
                                                          onClick={(e) => {
                                                              e.preventDefault()
                                                              onApproveSaveItem()
                                                          }
                                                          }
                                                    >
                                                        {i18n.save()}
                                                    </Link>
                                                    <button aria-label="Close"
                                                            className="button -bordered_blue modal__button"
                                                            onClick={() => setModalInfo(null)}
                                                            data-dismiss="modal" type="button">{i18n.cancel()}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {
                modalInfo ? <div className="modal-backdrop show"></div> : null
            }
        </>
    )
}