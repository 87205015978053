import {AppDispatch, RootState} from "migration/app/store";
import {
    KamiMenuCategoryList,
    KamiMenuItem,
    KamiMenuItemsList,
    KamiMenuStruct
} from "migration/entities/kamiMenuStruct";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {
    KamiItemActionEnum,
    SetIsLoadingGetMenuDataAction,
    SetKamiMenuCategoryList,
    SetKamiMenuItemsListAction,
    SetMenuDataAction
} from "./types";
import KamiMenuService, {getMenuStructForMenuPageReq} from "../api/kamiMenuService";

export const KamiItemActionCreators = {
    setMenuData: (menuData: KamiMenuStruct): SetMenuDataAction => ({
        type: KamiItemActionEnum.SET_MENU_DATA,
        payload: menuData
    }),
    setKamiMenuItems: (itemsList: KamiMenuItemsList): SetKamiMenuItemsListAction => ({
        type: KamiItemActionEnum.SET_KAMI_MENU_ITEMS_LIST,
        payload: itemsList
    }),
    setKamiMenuCategories: (categoryList: KamiMenuCategoryList): SetKamiMenuCategoryList => ({
       type: KamiItemActionEnum.SET_KAMI_MENU_CATEGORY_LIST,
       payload: categoryList
    }),
    setIsLoadingGetMenuData: (isLoading: boolean): SetIsLoadingGetMenuDataAction => ({
        type: KamiItemActionEnum.SET_IS_LOADING_GET_MENU_DATA,
        payload: isLoading
    }),

    fetchMenuData: (request: getMenuStructForMenuPageReq, controller: AbortController, navigationCallback: NavigateCallback, hideNotification: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(KamiItemActionCreators.setIsLoadingGetMenuData(true));
            const res = await KamiMenuService.getMenuStructForMenuPage(request, controller);
            if (res.data.success) {
                dispatch(KamiItemActionCreators.setMenuData(res.data.data));

                const kamiItems: {[itemId: string]: KamiMenuItem} = {};
                const kamiCategories: KamiMenuCategoryList = {};
                res.data.data?.sections?.forEach(section => {
                    section?.categories?.forEach(category => {
                        kamiCategories[category.category.id] = category;
                        category?.items?.forEach(item => {
                            kamiItems[item.id] = item;
                        });
                    });
                });
                dispatch(KamiItemActionCreators.setKamiMenuItems(kamiItems));
                dispatch(KamiItemActionCreators.setKamiMenuCategories(kamiCategories));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                    hideNotify: hideNotification,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
                hideNotify: hideNotification,
            });
        } finally {
            dispatch(KamiItemActionCreators.setIsLoadingGetMenuData(false));
        }
    }
}
