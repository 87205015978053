import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {
    SetEmailReceiversAction,
    SetIsOnEmailNotificationAction,
    SetIsLoadingGetNotificationsSettingsAction,
    SetIsLoadingToggleEmailNotificationAction,
    SetIsLoadingSaveEmailReceiversAction,
    NotificationsActionEnum
} from "./types";
import NotificationService, {
    cmdSaveEmailReceiversReq,
    cmdToggleEmailNotificationReq,
    getNotificationsSettingsReq
} from "../api/notificationService";

export const NotificationActionCreators = {
    setIsOnEmailNotification: (isOnEmailNotification: boolean): SetIsOnEmailNotificationAction => ({
        type: NotificationsActionEnum.SET_IS_ON_EMAIL_NOTIFICATION,
        payload: isOnEmailNotification
    }),
    setEmailReceivers: (emailReceivers: string[]): SetEmailReceiversAction => ({
        type: NotificationsActionEnum.SET_EMAIL_RECEIVERS,
        payload: emailReceivers
    }),
    setIsLoadingGetNotificationsSettings: (isLoadingGetNotificationsSettings: boolean): SetIsLoadingGetNotificationsSettingsAction => ({
        type: NotificationsActionEnum.SET_IS_LOADING_GET_NOTIFICATIONS_SETTINGS,
        payload: isLoadingGetNotificationsSettings
    }),
    setIsLoadingToggleEmailNotification: (isLoadingToggleEmailNotification: boolean): SetIsLoadingToggleEmailNotificationAction => ({
        type: NotificationsActionEnum.SET_IS_LOADING_TOGGLE_EMAIL_NOTIFICATION,
        payload: isLoadingToggleEmailNotification
    }),
    setIsLoadingSaveEmailReceivers: (isLoadingSaveEmailReceivers: boolean): SetIsLoadingSaveEmailReceiversAction => ({
        type: NotificationsActionEnum.SET_IS_LOADING_SAVE_EMAIL_RECEIVERS,
        payload: isLoadingSaveEmailReceivers
    }),

    fetchNotificationsSettings: (request: getNotificationsSettingsReq, controller: AbortController, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(NotificationActionCreators.setIsLoadingGetNotificationsSettings(true));
            const res = await NotificationService.getNotificationsSettings(request, controller);
            if (res.data.success) {
                dispatch(NotificationActionCreators.setIsOnEmailNotification(res.data.data?.email?.isOn || false));
                dispatch(NotificationActionCreators.setEmailReceivers(res.data.data?.email?.settings?.receivers || []));
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(NotificationActionCreators.setIsLoadingGetNotificationsSettings(false));
        }
    },

    toggleEmailNotification: (request: cmdToggleEmailNotificationReq, getRequest: getNotificationsSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(NotificationActionCreators.setIsLoadingToggleEmailNotification(true));
            const res = await NotificationService.cmdToggleEmailNotification(request);
            if (res.data.success) {
                await NotificationActionCreators.fetchNotificationsSettings(getRequest, new AbortController(), navigationCallback)(dispatch, getState);
                if (request?.toggle) {
                    Notify.Success({title: txt.email_notification_turned_on[currentLang], message: ""})
                } else {
                    Notify.Success({title: txt.email_notification_turned_off[currentLang], message: ""})
                }
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(NotificationActionCreators.setIsLoadingToggleEmailNotification(false));
        }
    },

    saveEmailReceivers: (request: cmdSaveEmailReceiversReq, getRequest: getNotificationsSettingsReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(NotificationActionCreators.setIsLoadingSaveEmailReceivers(true));
            const res = await NotificationService.cmdSaveEmailReceivers(request);
            if (res.data.success) {
                await NotificationActionCreators.fetchNotificationsSettings(getRequest, new AbortController(), navigationCallback)(dispatch, getState);
                Notify.Success({title: txt.email_notification_receivers_saved[currentLang], message: ""})
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
                navigateCallback: navigationCallback,
            });
        } finally {
            dispatch(NotificationActionCreators.setIsLoadingSaveEmailReceivers(false));
        }
    }
}
