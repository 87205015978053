import {ItemForm} from "../../components/form/itemForm";
import {useGlobalContext} from "../../hooks/globalContext";
import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {RouteNames} from "../../migration/pages";

export function ItemCreate() {
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.item_creating()
    }, [i18n])

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <ul className="breadcrumbs">

                    <li className="breadcrumbs__item">
                        <Link to={RouteNames.MENUS} className="breadcrumbs__link"
                              km-partial="true">
                            {i18n.menus()}
                        </Link>
                    </li>

                    <li className="breadcrumbs__item">
                        <a href={RouteNames.MENUS_ITEM_CREATE}
                           className="breadcrumbs__link">
                            {i18n.add_item()}
                        </a>
                    </li>

                </ul>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.pl_item()}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">

                            <ItemForm isEdit={false}/>

                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}
