import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {IOrderItemChange} from "../../models/orders/order";
import Select from "react-select";
import {CURRENCY} from "../../utils/options/options";
import {TextI18n} from "../input/textI18n";
import {PriceInput} from "../input/priceInput";
import {I18nString} from "../../models/lang/i18nString";
import {NumberInput} from "../input/numberInput";

interface ModalEditOrderProps {
    items: { item: IOrderItemChange, value: string, label: string }[]
    onApproveSaveItem: () => void
    modalInfo: IOrderItemChange | null
    setModalInfo: React.Dispatch<React.SetStateAction<IOrderItemChange | null>>
}

export function ModalEditItem({items, modalInfo, setModalInfo, onApproveSaveItem}: ModalEditOrderProps) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const modalRef = useRef(null)

    function changeModSelect(index: number, header: I18nString, label: I18nString, price: number) {
        if (!modalInfo) {
            return
        }
        let now = {...modalInfo}
        if (now && now.prices.modSelects) {
            now.prices.modSelects[index].header = header
            now.prices.modSelects[index].options[0].label = label
            now.prices.modSelects[index].options[0].priceDelta = price
            setModalInfo(now)
        }
    }

    function addModSelect() {
        setModalInfo((pre) => {
            if (pre) {
                if (!pre.prices.modSelects) {
                    pre.prices.modSelects = []
                }
                pre.prices.modSelects.push({
                    isHidden: false,
                    isRequired: false,
                    header: {},
                    options: [{
                        status: "",
                        label: {},
                        priceDelta: 0
                    }]
                })
            }
            return pre
        })
    }

    function removeModSelect(index: number) {
        setModalInfo((pre) => {
            if (pre && pre.prices.modSelects) {
                pre = {...pre}
                if (pre.prices.modSelects) {
                    pre.prices.modSelects.splice(index, 1)
                }
            }
            return pre
        })
    }

    function changeModExtra(index: number, label: I18nString, price: number) {
        if (modalInfo && modalInfo.prices.modExtras) {
            let now = {...modalInfo}
            if (!now.prices.modExtras) {
                return
            }
            now.prices.modExtras[index].priceDelta = price
            now.prices.modExtras[index].label = label
            setModalInfo(now)
        }
    }

    function addModExtra() {
        setModalInfo((pre) => {
            if (pre) {
                if (!pre.prices.modExtras) {
                    pre.prices.modExtras = []
                }
                pre.prices.modExtras.push({
                    isHidden: false,
                    label: {},
                    priceDelta: 0
                })
            }
            return pre
        })
    }

    function removeModExtra(index: number) {
        setModalInfo((pre) => {
            if (pre && pre.prices.modExtras) {
                pre = {...pre}
                if (pre.prices.modExtras) {
                    pre.prices.modExtras.splice(index, 1)
                }
            }
            return pre
        })
    }

    useOnClickOutside(modalRef, () => setModalInfo(null), "mouseup")

    useEffect(() => {
        if (modalInfo) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [modalInfo])

    function sumPrice(): number {
        let sum = modalInfo ? (modalInfo.prices.primary.price) : 0
        if (modalInfo?.prices.modSelects) {
            modalInfo.prices.modSelects.forEach((mod) => {
                sum += mod.options[0].priceDelta
            })
        }
        if (modalInfo?.prices.modExtras) {
            modalInfo.prices.modExtras.forEach((mod) => {
                sum += mod.priceDelta
            })
        }
        if (typeof modalInfo?.quantity === "number") {
            sum *= modalInfo.quantity
        }
        return sum
    }

    return (
        <>
            <div className={modalInfo ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 id="modal-preview" style={{display: modalInfo ? "block" : "none"}}
                 aria-hidden={!!modalInfo}>
                {modalInfo ?
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div ref={modalRef} className="modal-content">
                            <div className="modal-body px-3 py-4 p-lg-5">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                        onClick={() => setModalInfo(null)}
                                ></button>
                                <div id="item-preview-content">

                                    <form className="form">
                                        <div className="lang-tab" style={{display: "block"}}>
                                            <h2 className="tx-semibold mb-4">


                                                <span>{i18n.pl_item()}</span>


                                            </h2>
                                        </div>
                                        <div className="lang-tab" style={{display: "block"}}>
                                            <div className="control">
                                                <Select options={items}
                                                        onChange={(v) => {
                                                            if (v) {
                                                                let item = v.item
                                                                item.quantity = 1
                                                                item.prices.modSelects = null
                                                                item.prices.modExtras = null
                                                                setModalInfo(item)
                                                            }
                                                        }}
                                                        isOptionSelected={(v) => v.value === modalInfo?.id}
                                                        value={
                                                            items.filter((v) => v.value === modalInfo?.id)
                                                        }
                                                />
                                            </div>


                                            <h3>{i18n.price()}</h3>
                                            <div className={"row"}>
                                                <div className="col-md-5">
                                                    <TextI18n values={modalInfo.prices.primary.label} isStaff={!!user?.isStaff}
                                                              setValues={(label) => {
                                                                  setModalInfo({
                                                                      ...modalInfo,
                                                                      prices: {
                                                                          ...modalInfo?.prices,
                                                                          primary: {
                                                                              ...modalInfo?.prices.primary,
                                                                              label: label
                                                                          }
                                                                      }
                                                                  })
                                                              }} label={i18n.item_name()}
                                                              lang={menu.i18n.defaultLang}
                                                              allLang={menu.i18n.otherLangs || []}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <PriceInput label={i18n.price()}
                                                                value={modalInfo.prices.primary.price}
                                                                onChange={(v) => {
                                                                    setModalInfo({
                                                                        ...modalInfo,
                                                                        prices: {
                                                                            ...modalInfo?.prices,
                                                                            primary: {
                                                                                ...modalInfo?.prices.primary,
                                                                                price: v
                                                                            }
                                                                        }
                                                                    })
                                                                }}/>
                                                </div>
                                                <div className="col-md-3">
                                                    <NumberInput label={i18n.amount()}
                                                                 value={modalInfo.quantity || 0}
                                                                 onChange={(n) => {
                                                                     setModalInfo({
                                                                         ...modalInfo,
                                                                         quantity: n
                                                                     })
                                                                 }
                                                                 }/>
                                                </div>
                                            </div>


                                            <h3>{i18n.mod_selects()}</h3>
                                            <div>
                                                {modalInfo.prices.modSelects?.map((v, i) => {
                                                    return (
                                                        <div className={"row add-price-tuple"} key={i + "modSelect"}>
                                                            <div className="col-md-4">
                                                                <TextI18n values={v.header} setValues={(header) => {
                                                                    changeModSelect(i, header, v.options[0].label, v.options[0].priceDelta)
                                                                }} lang={menu.i18n.defaultLang} isStaff={!!user?.isStaff}
                                                                          allLang={menu.i18n.otherLangs || []}
                                                                          label={i18n.title()}/>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <TextI18n values={v.options[0].label} isStaff={!!user?.isStaff}
                                                                          setValues={(label) => {
                                                                              changeModSelect(i, v.header, label, v.options[0].priceDelta)
                                                                          }} lang={menu.i18n.defaultLang}
                                                                          allLang={menu.i18n.otherLangs || []}
                                                                          label={i18n.mod_option_name()}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <PriceInput label={i18n.price_delta()}
                                                                            value={v.options[0].priceDelta}
                                                                            onChange={(price) => {
                                                                                changeModSelect(i, v.header, v.options[0].label, price)

                                                                            }}
                                                                />
                                                            </div>
                                                            <div className="col-md-1">
                                                                <button type="button" className="form__remove-button"
                                                                        onClick={() => removeModSelect(i)}
                                                                >
                                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                            fill="#8392A5"></path>
                                                                        <path
                                                                            d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                            fill="#8392A5"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <a href="#" onClick={() => {
                                                addModSelect()
                                            }}
                                            >
                                                + {i18n.add_mod_select()}
                                            </a>
                                            <hr/>


                                            <h3>{i18n.mod_extras()}</h3>

                                            {
                                                modalInfo.prices.modExtras?.map((val, index) => {
                                                    return (
                                                        <div className="row add-price-tuple" data-gutter="15"
                                                             key={"price-tuple-" + index}>
                                                            <div className="col-md-5">
                                                                <TextI18n values={val.label} isStaff={!!user?.isStaff}
                                                                          setValues={(v) => changeModExtra(index, v, val.priceDelta)}
                                                                          label={i18n.name()}
                                                                          lang={menu.i18n.defaultLang}
                                                                          allLang={menu.i18n.otherLangs || []}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <PriceInput label={i18n.price_delta()}
                                                                            isRequired={true}
                                                                            value={val.priceDelta}
                                                                            onChange={(v) => changeModExtra(index, val.label, v)}/>
                                                            </div>
                                                            <div className="col-1">
                                                                <button type="button" className="form__remove-button"
                                                                        onClick={() => removeModExtra(index)}
                                                                >
                                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                            fill="#8392A5"></path>
                                                                        <path
                                                                            d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                            fill="#8392A5"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <a href="#" onClick={() => {
                                                addModExtra()
                                            }}>
                                                + {i18n.add_mod_extras()}
                                            </a>
                                            <hr/>

                                            <div className="row" style={{marginLeft: "0px"}}>
                                                <></>
                                                <span>{i18n.total()}: {sumPrice() / 100} {CURRENCY[menu.i18n.currency]}</span>
                                            </div>


                                            <div className="form__buttons mt-4">
                                                <div className="form__buttons-left">
                                                    <Link to={"#"}
                                                          className="button -blue modal__button"
                                                          onClick={(e) => {
                                                              e.preventDefault()
                                                              onApproveSaveItem()
                                                          }
                                                          }
                                                    >
                                                        {i18n.save()}
                                                    </Link>
                                                    <button aria-label="Close"
                                                            className="button -bordered_blue modal__button"
                                                            onClick={() => setModalInfo(null)}
                                                            data-dismiss="modal" type="button">{i18n.cancel()}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {
                modalInfo ? <div className="modal-backdrop show"></div> : null
            }
        </>
    )
}