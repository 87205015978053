import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {isEmpty} from "lodash";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {ModalBase} from "../ModalBase";

interface ModalAddCategoryProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<boolean>,
    isEditMode?: boolean,
    selectedCategoryId?: string,
}

export const ModalAddCategory: FunctionComponent<ModalAddCategoryProps> = (
    {isOpen, setIsOpen, isEditMode, selectedCategoryId}
) => {
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menu} = useTypedSelector(state => state.user);
    const {crossSellingMapping} = useTypedSelector(state => state.crossSelling);
    const {kamiMenuCategoryList} = useTypedSelector(state => state.menuIiko);
    const {associateCrossSellingToMenuCategory} = useActions();
    const [selectedCategory, setSelectedCategory] = useState<string>(selectedCategoryId || "");
    const [associatedProductsListState, setAssociatedProductsListState] = useState<{ [itemId: string]: string[] }>({});
    const [searchQuery, setSearchQuery] = useState<string>("");

    const kamiMenuCategoryListOptions = useMemo<{ value: string, label: string }[]>(() => {
        return Object.keys(kamiMenuCategoryList || {})?.filter(categoryId => {
            return !Object.keys(crossSellingMapping?.categories).includes(categoryId)
        })?.map(itemId => ({
            value: kamiMenuCategoryList[itemId]?.category?.id,
            label: kamiMenuCategoryList[itemId]?.category?.name?.[menu?.i18n?.defaultLang]
        })) || []
    }, [kamiMenuCategoryList, currentLang, crossSellingMapping]);

    const associatedProductsList = useMemo<string[]>(() => {
        if (!selectedCategory || isEmpty(associatedProductsListState[selectedCategory])) return [];
        return associatedProductsListState[selectedCategory] || [];
    }, [selectedCategory, associatedProductsListState]);

    const itemTitle = useMemo(() => {
        return (isEditMode && selectedCategory) ? kamiMenuCategoryList[selectedCategory].category?.name[menu?.i18n?.defaultLang] : txt.add_category[currentLang];
    }, [selectedCategory, isEditMode, kamiMenuCategoryList, currentLang])

    const addAssociatedProduct = (itemId: string) => {
        const copy = {...associatedProductsListState};
        const selectedCategoryCopy: string = selectedCategory || ""
        if (!(selectedCategoryCopy in copy)) {
            copy[selectedCategoryCopy] = [];
        }
        if (copy[selectedCategoryCopy].includes(itemId)) {
            copy[selectedCategoryCopy] = copy[selectedCategoryCopy].filter(item => item !== itemId);
            setAssociatedProductsListState(copy);
            return;
        }
        const notUniqueArr = [...copy[selectedCategoryCopy], itemId];
        copy[selectedCategoryCopy] = notUniqueArr.filter((item, index) => index === notUniqueArr.indexOf(item));
        setAssociatedProductsListState(copy);
    }

    const onCancel = () => {
        if (isEditMode) {
            setSearchQuery("");
            setSelectedCategory("");
            setAssociatedProductsListState({});
        }
        setIsOpen(false);
    }

    const onSubmit = () => {
        associateCrossSellingToMenuCategory({
            customLabel: {} as MlString,
            sourceCategoryId: selectedCategory,
            targetProductIds: associatedProductsList
        }, crossSellingMapping);
        setSearchQuery("");
        setSelectedCategory("");
        setAssociatedProductsListState({});
        setIsOpen(false);
    }

    useEffect(() => {
        if (!isEditMode || !selectedCategoryId) return;
        setSelectedCategory(selectedCategoryId)
        setAssociatedProductsListState({[selectedCategoryId]: crossSellingMapping.categories[selectedCategoryId]?.productIds || []});
    }, [crossSellingMapping, isEditMode, selectedCategoryId, isOpen]);

    return (
        <ModalBase
            categoryOptions={kamiMenuCategoryListOptions}
            associatedProductsList={associatedProductsList}
            addAssociatedProduct={addAssociatedProduct}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmit={onSubmit}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title={itemTitle}
            isEditMode={isEditMode}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
        />
    )
};
