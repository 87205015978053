import {FeedbackPage} from "migration/entities/Feedback";

export interface FeedbackState {
    feedbackPage: FeedbackPage;
    isLoadingGetFeedbackPageById: boolean;
}

export enum FeedbackActionEnum {
    SET_FEEDBACK_PAGE = "SET_FEEDBACK_PAGE",
    SET_IS_LOADING_GET_FEEDBACK_PAGE_BY_ID = "SET_IS_LOADING_GET_FEEDBACK_PAGE_BY_ID",
}

export interface SetFeedbackPageAction {
    type: FeedbackActionEnum.SET_FEEDBACK_PAGE;
    payload: FeedbackPage;
}

export interface SetIsLoadingGetFeedbackPageByIdAction {
    type: FeedbackActionEnum.SET_IS_LOADING_GET_FEEDBACK_PAGE_BY_ID;
    payload: boolean;
}

export type FeedbackAction =
    SetFeedbackPageAction |
    SetIsLoadingGetFeedbackPageByIdAction;
