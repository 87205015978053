import {Checkbox} from "../input/checkbox";
import {InputPhone} from "../input/phoneInput";
import {SubmitButton} from "../input/submitButton";
import React, {Dispatch} from "react";
import {useSettingClientOrders} from "../../hooks/settings/clientOrders";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {
    IDeliveryCinemaState,
    IDeliveryState,
    IInDoorsListState,
    IInDoorsState,
    IPickupState,
    range
} from "../../models/settings/menu";
import {I18nString} from "../../models/lang/i18nString";
import {CustomSwitch} from "../input/customSwitch";
import {TextAreaI18n} from "../input/textAreaI18n";
import {NumberInput, NumberInputRange} from "../input/numberInput";
import {TextI18n} from "../input/textI18n";
import {Placement} from "../../models/placements/placements";
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";

export function OrderModule() {
    const info = useSettingClientOrders()
    const {i18n} = useGlobalContext()

    return (
        <FormWithLoader isloading={info.settingsLoading} className="form">

            <Checkbox checked={info.orderCheck} onChange={info.handleOrderCheck}
                      label={i18n.turn_on_co()}/>
            <div id="order_segment" style={{marginLeft: "20px"}}
                 className={info.orderCheck ? "some" : "some disabled-segment"}>

                <PickUpSettings pickUpCheck={info.pickUpCheck}
                                pickUpToggled={info.pickUpToggled}
                                pickUpState={info.pickUpState}
                                setPickUpToggled={info.setPickUpToggled}
                                setPickUpCheck={info.setPickUpCheck}
                                setPickUpState={info.setPickUpState}
                />

                <DeliverySettings deliveryCheck={info.deliveryCheck}
                                  deliveryToggled={info.deliveryToggled}
                                  deliveryState={info.deliveryState}
                                  setDeliveryToggled={info.setDeliveryToggled}
                                  setDeliveryCheck={info.setDeliveryCheck}
                                  setDeliveryState={info.setDeliveryState}
                />

                <InDoorSettings inDoorCheck={info.inDoorCheck}
                                inDoorToggled={info.inDoorToggled}
                                inDoorState={info.inDoorState}
                                setInDoorToggled={info.setInDoorToggled}
                                setInDoorCheck={info.setInDoorCheck}
                                setInDoorState={info.setInDoorState}
                />

                {info.placements ?
                    <InDoorListSettings inDoorListCheck={info.inDoorListCheck}
                                        inDoorListToggled={info.inDoorListToggled}
                                        inDoorListState={info.inDoorListState}
                                        setInDoorListToggled={info.setInDoorListToggled}
                                        setInDoorListCheck={info.setInDoorListCheck}
                                        setInDoorListState={info.setInDoorListState}
                                        placements={info.placements}
                    />
                    : null}

                <CinemaSettings cinemaCheck={info.cinemaCheck}
                                cinemaToggled={info.cinemaToggled}
                                cinemaState={info.cinemaState}
                                setCinemaToggled={info.setCinemaToggled}
                                setCinemaCheck={info.setCinemaCheck}
                                setCinemaState={info.setCinemaState}
                />

                <Checkbox checked={info.whatsAppCheck} onChange={info.handleWhatsAppCheck}
                          label={"WhatsApp"}/>
                <div className={info.whatsAppCheck ? "some" : "some disabled-segment"}
                     style={{marginLeft: "20px"}}>
                    <InputPhone label={i18n.phone()} value={info.whatsAppNumber}
                                onChange={info.setWhatsAppNumber}/>

                </div>
            </div>

            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton onSubmit={info.handleSave} label={i18n.save()} isLoading={info.saveLoading}
                                  disabled={info.orderCheck && !(info.inDoorCheck || info.deliveryCheck || info.pickUpCheck || info.inDoorListCheck || info.cinemaCheck)}
                                  modal={true}/>
                    <a href={RouteNames.SETTINGS} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </a>
                </div>
            </div>
        </FormWithLoader>
    )
}

interface IPickUpSettings {
    pickUpCheck: boolean
    pickUpToggled: boolean
    pickUpState: IPickupState
    setPickUpToggled: (toggled: boolean) => void
    setPickUpCheck: (check: boolean) => void
    setPickUpState: (state: IPickupState) => void
}

function PickUpSettings(props: IPickUpSettings) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const options = [i18n.fixed(), i18n.percent(), i18n.range_charge()]
    const optionsDiscount = [i18n.fixed(), i18n.percent(), i18n.enter_manually()]

    function handlePickUpCheck(check: boolean) {
        props.setPickUpToggled(true)
        props.setPickUpState({comment: {}, paymentSupplement: [], discounts: []});
        props.setPickUpCheck(check);
    }

    function handlePickUpCommentChange(comment: I18nString) {
        props.setPickUpState({...props.pickUpState, comment})
    }

    // =================================================================================================================

    function handlePickUpPaymentSupplementTypeChange(index: number, type: number) {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: props.pickUpState.paymentSupplement.map((item, i) => {
                if (i === index) {
                    if (type === 2) {
                        return {...item, type: 2, value: [{from: 0, to: 0, value: 0}]};
                    } else {
                        return {...item, type, value: 0};
                    }
                } else {
                    return item
                }
            }),
        });
    }

    function handleAddAdditionalRange() {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: props.pickUpState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: [...(item.value as range[]), {from: 0, to: 0, value: 0}]}
                } else {
                    return item;
                }
            })
        });
    }

    function handleRemoveAdditionalRange(index: number) {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: props.pickUpState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: (item.value as range[]).filter((_, i) => i !== index)}
                } else {
                    return item;
                }
            })
        });
    }

    function handlePickUpPaymentSupplementValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.pickUpState.paymentSupplement[index].type) {
            case 0:
                props.setPickUpState({
                    ...props.pickUpState,
                    paymentSupplement: props.pickUpState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setPickUpState({
                    ...props.pickUpState,
                    paymentSupplement: props.pickUpState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setPickUpState({
                    ...props.pickUpState,
                    paymentSupplement: props.pickUpState.paymentSupplement.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                value: (item.value as range[]).map((range, rangeInd) => {
                                    if (rangeInd === rangeIndex) {
                                        return {...value as range}
                                    }
                                    return range;
                                })
                            }
                        }
                        return item
                    })
                });
                break;
        }
    }

    function handlePickUpPaymentSupplementLabelChange(index: number, label: I18nString) {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: props.pickUpState.paymentSupplement.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemovePickUpPaymentSupplement(index: number) {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: props.pickUpState.paymentSupplement.filter((_, i) => i !== index)
        });
    }

    function handleAddPickUpPaymentSupplement() {
        props.setPickUpState({
            ...props.pickUpState,
            paymentSupplement: [...props.pickUpState.paymentSupplement, {type: 0, value: 0, label: {}}]
        });
    }

    // =================================================================================================================

    function handlePickUpDiscountTypeChange(index: number, type: number) {
        props.setPickUpState({
            ...props.pickUpState,
            discounts: props.pickUpState.discounts.map((item, i) => {
                if (i === index) {
                    return {...item, type, value: 0};
                } else {
                    return item
                }
            }),
        });
    }

    function handlePickUpDiscountValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.pickUpState.discounts[index].type) {
            case 0:
                props.setPickUpState({
                    ...props.pickUpState,
                    discounts: props.pickUpState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setPickUpState({
                    ...props.pickUpState,
                    discounts: props.pickUpState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setPickUpState({
                    ...props.pickUpState,
                    discounts: props.pickUpState.discounts.map((item, i) => i === index ? {
                        ...item,
                    } : item)
                });
                break;
        }
    }

    function handlePickUpDiscountLabelChange(index: number, label: I18nString) {
        props.setPickUpState({
            ...props.pickUpState,
            discounts: props.pickUpState.discounts.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemovePickUpDiscount(index: number) {
        props.setPickUpState({
            ...props.pickUpState,
            discounts: props.pickUpState.discounts.filter((_, i) => i !== index)
        });
    }

    function handleAddPickUpDiscount() {
        props.setPickUpState({
            ...props.pickUpState,
            discounts: [...props.pickUpState.discounts, {type: 0, value: 0, label: {}}]
        });
    }

    return (
        <>
            <Checkbox checked={props.pickUpCheck} onChange={handlePickUpCheck}
                      label={i18n.self_pickup()}/>
            <div style={{display: props.pickUpCheck ? "block" : "none"}}>
                <CustomSwitch size={48}
                              label={props.pickUpToggled ? i18n.self_pickup_is_on() :
                                  i18n.self_pickup_is_off()}
                              checked={props.pickUpToggled}
                              onChange={props.setPickUpToggled}/>

                <TextAreaI18n values={props.pickUpState.comment} isStaff={user?.isStaff || false}
                              setValues={handlePickUpCommentChange}
                              lang={menu.i18n.defaultLang}
                              allLangs={menu.i18n.otherLangs || []}
                              label={i18n.comment_for_order()}
                              small={true}/>
                <div className={"control"}>
                    <label className="control__label">{i18n.payment_supplement()}</label>
                    {props.pickUpState.paymentSupplement ? props.pickUpState.paymentSupplement.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label
                                            className="control__label">{i18n.type()}</label>

                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handlePickUpPaymentSupplementTypeChange(index, parseInt(e.currentTarget.value))}
                                        >

                                            {options.map((option, index) => {
                                                return (
                                                    <option
                                                        selected={index == v.type} value={index}
                                                        disabled={index === 2 && !!props.pickUpState.paymentSupplement.find((v) => v.type === 2)}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </select>

                                    </div>
                                </div>
                                {
                                    v.type == 2
                                        ?
                                        (v.value as range[]).map((range, rangeIndex) => {
                                            return (
                                                <div style={{display: "flex"}} key={rangeIndex}>
                                                    <NumberInputRange
                                                        fromLabel={i18n.from()}
                                                        toLabel={i18n.to()}
                                                        label={i18n.value()}
                                                        isRequired={true}
                                                        value={range}
                                                        onChange={(int) => handlePickUpPaymentSupplementValueChange(index, int, rangeIndex)}
                                                    />
                                                    <div className="col-1">
                                                        <button type="button" className="form__remove-button"
                                                                onClick={() => handleRemoveAdditionalRange(rangeIndex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                    fill="#8392A5"></path>
                                                                <path
                                                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                    fill="#8392A5"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handlePickUpPaymentSupplementValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handlePickUpPaymentSupplementLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemovePickUpPaymentSupplement(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>

                                {(v.type == 2 && index === props.pickUpState.paymentSupplement.length - 1) && (
                                    <div className="row add-price-tuple" data-gutter="15" style={{width: "100%"}}>
                                        <a
                                            href="src/components/settings#"
                                            style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px"}}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleAddAdditionalRange()
                                            }}
                                        >
                                            {i18n.add_addit_range()}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddPickUpPaymentSupplement()
                           }}>
                            {i18n.add_addit_price()}
                        </a>
                    </div>
                </div>
                <div className={"control"}>
                    <label className="control__label">{i18n.discounts()}</label>
                    {props.pickUpState.discounts ? props.pickUpState.discounts.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label className="control__label">{i18n.type()}</label>
                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handlePickUpDiscountTypeChange(index, parseInt(e.currentTarget.value))}
                                        >
                                            {optionsDiscount.map((option, index) => {
                                                return (
                                                    <option selected={index == v.type} value={index}>
                                                        {option}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {
                                    v.type !== 2 &&
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handlePickUpDiscountValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handlePickUpDiscountLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemovePickUpDiscount(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddPickUpDiscount()
                           }}>
                            {i18n.add_discount()}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

interface IDeliverySettings {
    deliveryCheck: boolean;
    deliveryToggled: boolean;
    deliveryState: IDeliveryState;
    setDeliveryCheck: (check: boolean) => void;
    setDeliveryToggled: (check: boolean) => void;
    setDeliveryState: (state: IDeliveryState) => void;
}

function DeliverySettings(props: IDeliverySettings) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const options = [i18n.fixed(), i18n.percent(), i18n.range_charge()]
    const optionsDiscount = [i18n.fixed(), i18n.percent(), i18n.enter_manually()]

    function handleDeliveryCheck(check: boolean) {
        props.setDeliveryToggled(true)
        props.setDeliveryState({comment: {}, paymentSupplement: [], discounts: []});
        props.setDeliveryCheck(check);
    }

    function handleDeliveryCommentChange(comment: I18nString) {
        props.setDeliveryState({...props.deliveryState, comment})
    }

    // =================================================================================================================

    function handleDeliveryPaymentSupplementTypeChange(index: number, type: number) {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: props.deliveryState.paymentSupplement.map((item, i) => {
                if (i === index) {
                    if (type === 2) {
                        return {...item, type: 2, value: [{from: 0, to: 0, value: 0}]};
                    } else {
                        return {...item, type, value: 0};
                    }
                } else {
                    return item
                }
            }),
        });
    }

    function handleAddAdditionalRange() {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: props.deliveryState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: [...(item.value as range[]), {from: 0, to: 0, value: 0}]}
                } else {
                    return item;
                }
            })
        });
    }

    function handleRemoveAdditionalRange(index: number) {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: props.deliveryState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: (item.value as range[]).filter((_, i) => i !== index)}
                } else {
                    return item;
                }
            })
        });
    }

    function handleDeliveryPaymentSupplementValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.deliveryState.paymentSupplement[index].type) {
            case 0:
                props.setDeliveryState({
                    ...props.deliveryState,
                    paymentSupplement: props.deliveryState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setDeliveryState({
                    ...props.deliveryState,
                    paymentSupplement: props.deliveryState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setDeliveryState({
                    ...props.deliveryState,
                    paymentSupplement: props.deliveryState.paymentSupplement.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                value: (item.value as range[]).map((range, rangeInd) => {
                                    if (rangeInd === rangeIndex) {
                                        return {...value as range}
                                    }
                                    return range;
                                })
                            }
                        }
                        return item
                    })
                });
                break;
        }
    }

    function handleAddDeliveryPaymentSupplement() {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: [...props.deliveryState.paymentSupplement, {type: 0, value: 0, label: {}}]
        });
    }

    function handleRemoveDeliveryPaymentSupplement(index: number) {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: props.deliveryState.paymentSupplement.filter((_, i) => i !== index)
        });
    }

    function handleDeliveryPaymentSupplementLabelChange(index: number, label: I18nString) {
        props.setDeliveryState({
            ...props.deliveryState,
            paymentSupplement: props.deliveryState.paymentSupplement.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    // =================================================================================================================

    function handleDeliveryDiscountTypeChange(index: number, type: number) {
        props.setDeliveryState({
            ...props.deliveryState,
            discounts: props.deliveryState.discounts.map((item, i) => {
                if (i === index) {
                    return {...item, type, value: 0};
                } else {
                    return item
                }
            }),
        });
    }

    function handleDeliveryDiscountValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.deliveryState.discounts[index].type) {
            case 0:
                props.setDeliveryState({
                    ...props.deliveryState,
                    discounts: props.deliveryState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setDeliveryState({
                    ...props.deliveryState,
                    discounts: props.deliveryState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setDeliveryState({
                    ...props.deliveryState,
                    discounts: props.deliveryState.discounts.map((item, i) => i === index ? {
                        ...item,
                    } : item)
                });
                break;
        }
    }

    function handleDeliveryDiscountLabelChange(index: number, label: I18nString) {
        props.setDeliveryState({
            ...props.deliveryState,
            discounts: props.deliveryState.discounts.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveDeliveryDiscount(index: number) {
        props.setDeliveryState({
            ...props.deliveryState,
            discounts: props.deliveryState.discounts.filter((_, i) => i !== index)
        });
    }

    function handleAddDeliveryDiscount() {
        props.setDeliveryState({
            ...props.deliveryState,
            discounts: [...props.deliveryState.discounts, {type: 0, value: 0, label: {}}]
        });
    }

    return (
        <>
            <Checkbox checked={props.deliveryCheck} onChange={handleDeliveryCheck}
                      label={i18n.delivery()}/>
            <div style={{display: props.deliveryCheck ? "block" : "none"}}>
                <CustomSwitch size={48}
                              label={props.deliveryToggled ? i18n.delivery_is_on() :
                                  i18n.delivery_is_off()}
                              checked={props.deliveryToggled}
                              onChange={props.setDeliveryToggled}/>
                <TextAreaI18n values={props.deliveryState.comment} isStaff={user?.isStaff || false}
                              setValues={handleDeliveryCommentChange}
                              lang={menu.i18n.defaultLang}
                              allLangs={menu.i18n.otherLangs || []}
                              label={i18n.comment_for_order()}
                              small={true}/>
                <div className={"control"}>
                    <label
                        className="control__label">{i18n.payment_supplement()}</label>
                    {props.deliveryState.paymentSupplement ? props.deliveryState.paymentSupplement.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label
                                            className="control__label">{i18n.type()}</label>

                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleDeliveryPaymentSupplementTypeChange(index, parseInt(e.currentTarget.value))}
                                        >

                                            {options.map((option, index) => {
                                                return (
                                                    <option
                                                        selected={index == v.type} value={index}
                                                        disabled={index === 2 && !!props.deliveryState.paymentSupplement.find((v) => v.type === 2)}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </select>

                                    </div>
                                </div>

                                {
                                    v.type == 2
                                        ?
                                        (v.value as range[]).map((range, rangeIndex) => {
                                            return (
                                                <div style={{display: "flex"}} key={rangeIndex}>
                                                    <NumberInputRange
                                                        fromLabel={i18n.from()}
                                                        toLabel={i18n.to()}
                                                        label={i18n.value()}
                                                        isRequired={true}
                                                        value={range}
                                                        onChange={(int) => handleDeliveryPaymentSupplementValueChange(index, int, rangeIndex)}
                                                    />
                                                    <div className="col-1">
                                                        <button type="button" className="form__remove-button"
                                                                onClick={() => handleRemoveAdditionalRange(rangeIndex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                    fill="#8392A5"></path>
                                                                <path
                                                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                    fill="#8392A5"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handleDeliveryPaymentSupplementValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleDeliveryPaymentSupplementLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveDeliveryPaymentSupplement(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>

                                {(v.type == 2 && index === props.deliveryState.paymentSupplement.length - 1) && (
                                    <div className="row add-price-tuple" data-gutter="15" style={{width: "100%"}}>
                                        <a
                                            href="src/components/settings#"
                                            style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px"}}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleAddAdditionalRange()
                                            }}
                                        >
                                            {i18n.add_addit_range()}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddDeliveryPaymentSupplement()
                           }}>
                            {i18n.add_addit_price()}
                        </a>
                    </div>
                </div>
                <div className={"control"}>
                    <label className="control__label">{i18n.discounts()}</label>
                    {props.deliveryState.discounts ? props.deliveryState.discounts.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label className="control__label">{i18n.type()}</label>
                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleDeliveryDiscountTypeChange(index, parseInt(e.currentTarget.value))}
                                        >
                                            {optionsDiscount.map((option, index) => {
                                                return (
                                                    <option selected={index == v.type} value={index}>
                                                        {option}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {
                                    v.type !== 2 &&
                                    <div className="col-3">
                                        <NumberInput label={i18n.value()}
                                                     value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                     onChange={(int) => handleDeliveryDiscountValueChange(index, int)}/>
                                    </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleDeliveryDiscountLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveDeliveryDiscount(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddDeliveryDiscount()
                           }}>
                            {i18n.add_discount()}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

interface IInDoorSettings {
    inDoorCheck: boolean;
    inDoorToggled: boolean;
    inDoorState: IInDoorsState;
    setInDoorState: (state: IInDoorsState) => void;
    setInDoorCheck: (state: boolean) => void;
    setInDoorToggled: (state: boolean) => void;
}

function InDoorSettings(props: IInDoorSettings) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const options = [i18n.fixed(), i18n.percent(), i18n.range_charge()]
    const optionsDiscount = [i18n.fixed(), i18n.percent(), i18n.enter_manually()]

    function handleInDoorCheck(check: boolean) {
        props.setInDoorToggled(true)
        props.setInDoorState({comment: {}, placeLabel: {}, paymentSupplement: [], discounts: []});
        props.setInDoorCheck(check);
    }

    function handleInDoorCommentChange(comment: I18nString) {
        props.setInDoorState({...props.inDoorState, comment});
    }

    // =================================================================================================================

    function handleInDoorPlaceLabelChange(placeLabel: I18nString) {
        props.setInDoorState({...props.inDoorState, placeLabel});
    }

    function handleInDoorPaymentSupplementTypeChange(index: number, type: number) {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: props.inDoorState.paymentSupplement.map((item, i) => {
                if (i === index) {
                    if (type === 2) {
                        return {...item, type: 2, value: [{from: 0, to: 0, value: 0}]};
                    } else {
                        return {...item, type, value: 0};
                    }
                } else {
                    return item
                }
            }),
        });
    }

    function handleAddAdditionalRange() {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: props.inDoorState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: [...(item.value as range[]), {from: 0, to: 0, value: 0}]}
                } else {
                    return item;
                }
            })
        });
    }

    function handleRemoveAdditionalRange(index: number) {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: props.inDoorState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: (item.value as range[]).filter((_, i) => i !== index)}
                } else {
                    return item;
                }
            })
        });
    }

    function handleInDoorPaymentSupplementValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.inDoorState.paymentSupplement[index].type) {
            case 0:
                props.setInDoorState({
                    ...props.inDoorState,
                    paymentSupplement: props.inDoorState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setInDoorState({
                    ...props.inDoorState,
                    paymentSupplement: props.inDoorState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setInDoorState({
                    ...props.inDoorState,
                    paymentSupplement: props.inDoorState.paymentSupplement.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                value: (item.value as range[]).map((range, rangeInd) => {
                                    if (rangeInd === rangeIndex) {
                                        return {...value as range}
                                    }
                                    return range;
                                })
                            }
                        }
                        return item
                    })
                });
                break;
        }
    }

    function handleInDoorPaymentSupplementLabelChange(index: number, label: I18nString) {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: props.inDoorState.paymentSupplement.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveInDoorPaymentSupplement(index: number) {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: props.inDoorState.paymentSupplement.filter((_, i) => i !== index)
        });
    }

    function handleAddInDoorPaymentSupplement() {
        props.setInDoorState({
            ...props.inDoorState,
            paymentSupplement: [...props.inDoorState.paymentSupplement, {type: 0, value: 0, label: {}}]
        });
    }

    // =================================================================================================================

    function handleInDoorDiscountTypeChange(index: number, type: number) {
        props.setInDoorState({
            ...props.inDoorState,
            discounts: props.inDoorState.discounts.map((item, i) => {
                if (i === index) {
                    return {...item, type, value: 0};
                } else {
                    return item
                }
            }),
        });
    }

    function handleInDoorDiscountValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.inDoorState.discounts[index].type) {
            case 0:
                props.setInDoorState({
                    ...props.inDoorState,
                    discounts: props.inDoorState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setInDoorState({
                    ...props.inDoorState,
                    discounts: props.inDoorState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setInDoorState({
                    ...props.inDoorState,
                    discounts: props.inDoorState.discounts.map((item, i) => i === index ? {
                        ...item,
                    } : item)
                });
                break;
        }
    }

    function handleInDoorDiscountLabelChange(index: number, label: I18nString) {
        props.setInDoorState({
            ...props.inDoorState,
            discounts: props.inDoorState.discounts.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveInDoorDiscount(index: number) {
        props.setInDoorState({
            ...props.inDoorState,
            discounts: props.inDoorState.discounts.filter((_, i) => i !== index)
        });
    }

    function handleAddInDoorDiscount() {
        props.setInDoorState({
            ...props.inDoorState,
            discounts: [...props.inDoorState.discounts, {type: 0, value: 0, label: {}}]
        });
    }

    return (
        <>
            <Checkbox checked={props.inDoorCheck} onChange={handleInDoorCheck}
                      label={i18n.in_doors()}/>

            <div style={{display: props.inDoorCheck ? "block" : "none"}}>
                <CustomSwitch size={48}
                              label={props.inDoorToggled ? i18n.in_door_is_on() :
                                  i18n.in_door_is_off()}
                              checked={props.inDoorToggled}
                              onChange={props.setInDoorToggled}/>

                <TextAreaI18n values={props.inDoorState.comment} isStaff={user?.isStaff || false}
                              setValues={handleInDoorCommentChange}
                              lang={menu.i18n.defaultLang}
                              allLangs={menu.i18n.otherLangs || []}
                              label={i18n.comment_for_order()}
                              small={true}/>
                <TextI18n values={props.inDoorState.placeLabel} isStaff={user?.isStaff || false}
                          setValues={handleInDoorPlaceLabelChange}
                          lang={menu.i18n.defaultLang}
                          allLang={menu.i18n.otherLangs || []}
                          label={i18n.place_name()}/>

                <div className={"control"}>
                    <label className="control__label">{i18n.payment_supplement()}</label>
                    {props.inDoorState.paymentSupplement ? props.inDoorState.paymentSupplement.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label
                                            className="control__label">{i18n.type()}</label>

                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleInDoorPaymentSupplementTypeChange(index, parseInt(e.currentTarget.value))}
                                        >

                                            {options.map((option, index) => {
                                                return (
                                                    <option
                                                        selected={index == v.type} value={index}
                                                        disabled={index === 2 && !!props.inDoorState.paymentSupplement.find((v) => v.type === 2)}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </select>

                                    </div>
                                </div>

                                {
                                    v.type == 2
                                        ?
                                        (v.value as range[]).map((range, rangeIndex) => {
                                            return (
                                                <div style={{display: "flex"}}>
                                                    <NumberInputRange
                                                        fromLabel={i18n.from()}
                                                        toLabel={i18n.to()}
                                                        label={i18n.value()}
                                                        isRequired={true}
                                                        value={range}
                                                        onChange={(int) => handleInDoorPaymentSupplementValueChange(index, int, rangeIndex)}
                                                    />
                                                    <div className="col-1">
                                                        <button type="button" className="form__remove-button"
                                                                onClick={() => handleRemoveAdditionalRange(rangeIndex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                    fill="#8392A5"></path>
                                                                <path
                                                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                    fill="#8392A5"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handleInDoorPaymentSupplementValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleInDoorPaymentSupplementLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              label={i18n.title()}
                                              allLang={menu.i18n.otherLangs || []}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveInDoorPaymentSupplement(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>

                                {(v.type == 2 && index === props.inDoorState.paymentSupplement.length - 1) && (
                                    <div className="row add-price-tuple" data-gutter="15" style={{width: "100%"}}>
                                        <a
                                            href="src/components/settings#"
                                            style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px"}}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleAddAdditionalRange()
                                            }}
                                        >
                                            {i18n.add_addit_range()}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddInDoorPaymentSupplement()
                           }}>
                            {i18n.add_addit_price()}
                        </a>
                    </div>
                </div>
                <div className={"control"}>
                    <label className="control__label">{i18n.discounts()}</label>
                    {props.inDoorState.discounts ? props.inDoorState.discounts.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label className="control__label">{i18n.type()}</label>
                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleInDoorDiscountTypeChange(index, parseInt(e.currentTarget.value))}
                                        >
                                            {optionsDiscount.map((option, index) => {
                                                return (
                                                    <option selected={index == v.type} value={index}>
                                                        {option}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {
                                    v.type !== 2 &&
                                    <div className="col-3">
                                        <NumberInput label={i18n.value()}
                                                     value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                     onChange={(int) => handleInDoorDiscountValueChange(index, int)}/>
                                    </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleInDoorDiscountLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveInDoorDiscount(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddInDoorDiscount()
                           }}>
                            {i18n.add_discount()}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

interface IInDoorListSettings {
    inDoorListState: IInDoorsListState;
    inDoorListToggled: boolean;
    inDoorListCheck: boolean;
    placements: Placement[] | null
    setInDoorListState: (state: IInDoorsListState) => void;
    setInDoorListCheck: (state: boolean) => void;
    setInDoorListToggled: (state: boolean) => void;
}

function InDoorListSettings(props: IInDoorListSettings) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const options = [i18n.fixed(), i18n.percent(), i18n.range_charge()]
    const optionsDiscount = [i18n.fixed(), i18n.percent(), i18n.enter_manually()]

    function handleInDoorListCheck(check: boolean) {
        props.setInDoorListToggled(true)
        props.setInDoorListState({comment: {}, paymentSupplement: [], discounts: []});
        props.setInDoorListCheck(check);
    }

    function handleInDoorListCommentChange(comment: I18nString) {
        props.setInDoorListState({...props.inDoorListState, comment});
    }

    // =================================================================================================================

    function handleInDoorListPaymentSupplementTypeChange(index: number, type: number) {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: props.inDoorListState.paymentSupplement.map((item, i) => {
                if (i === index) {
                    if (type === 2) {
                        return {...item, type: 2, value: [{from: 0, to: 0, value: 0}]};
                    } else {
                        return {...item, type, value: 0};
                    }
                } else {
                    return item
                }
            }),
        });
    }

    function handleAddAdditionalRange() {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: props.inDoorListState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: [...(item.value as range[]), {from: 0, to: 0, value: 0}]}
                } else {
                    return item;
                }
            })
        });
    }

    function handleRemoveAdditionalRange(index: number) {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: props.inDoorListState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: (item.value as range[]).filter((_, i) => i !== index)}
                } else {
                    return item;
                }
            })
        });
    }

    function handleInDoorListPaymentSupplementValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.inDoorListState.paymentSupplement[index].type) {
            case 0:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    paymentSupplement: props.inDoorListState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    paymentSupplement: props.inDoorListState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    paymentSupplement: props.inDoorListState.paymentSupplement.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                value: (item.value as range[]).map((range, rangeInd) => {
                                    if (rangeInd === rangeIndex) {
                                        return {...value as range}
                                    }
                                    return range;
                                })
                            }
                        }
                        return item
                    })
                });
                break;
        }
    }

    function handleInDoorListPaymentSupplementLabelChange(index: number, label: I18nString) {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: props.inDoorListState.paymentSupplement.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveInDoorListPaymentSupplement(index: number) {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: props.inDoorListState.paymentSupplement.filter((_, i) => i !== index)
        });
    }

    function handleAddInDoorListPaymentSupplement() {
        props.setInDoorListState({
            ...props.inDoorListState,
            paymentSupplement: [...props.inDoorListState.paymentSupplement, {type: 0, value: 0, label: {}}]
        });
    }

    // =================================================================================================================

    function handleInDoorListDiscountTypeChange(index: number, type: number) {
        props.setInDoorListState({
            ...props.inDoorListState,
            discounts: props.inDoorListState.discounts.map((item, i) => {
                if (i === index) {
                    return {...item, type, value: 0};
                } else {
                    return item
                }
            }),
        });
    }

    function handleInDoorListDiscountValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.inDoorListState.discounts[index].type) {
            case 0:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    discounts: props.inDoorListState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    discounts: props.inDoorListState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setInDoorListState({
                    ...props.inDoorListState,
                    discounts: props.inDoorListState.discounts.map((item, i) => i === index ? {
                        ...item,
                    } : item)
                });
                break;
        }
    }

    function handleInDoorListDiscountLabelChange(index: number, label: I18nString) {
        props.setInDoorListState({
            ...props.inDoorListState,
            discounts: props.inDoorListState.discounts.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveInDoorListDiscount(index: number) {
        props.setInDoorListState({
            ...props.inDoorListState,
            discounts: props.inDoorListState.discounts.filter((_, i) => i !== index)
        });
    }

    function handleAddInDoorListDiscount() {
        props.setInDoorListState({
            ...props.inDoorListState,
            discounts: [...props.inDoorListState.discounts, {type: 0, value: 0, label: {}}]
        });
    }

    return (
        <>
            <Checkbox checked={props.inDoorListCheck}
                      onChange={handleInDoorListCheck}
                      label={i18n.in_door_list()}/>

            <div style={{display: props.inDoorListCheck ? "block" : "none"}}>
                <div className="control">
                    <CustomSwitch size={48}
                                  label={props.inDoorListToggled ? i18n.in_door_list_is_on() :
                                      i18n.in_door_list_is_off()}
                                  checked={props.inDoorListToggled}
                                  onChange={props.setInDoorListToggled}/>
                    <div
                        className="control__label">{i18n.placements()}</div>
                    <div className="control__content">
                        <div className="row">
                            <ul>
                                {props.placements?.map((placement, index) => {
                                        return (
                                            <li key={index}>
                                                {placement.slug} {placement.name[menu.i18n.defaultLang]}
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <TextAreaI18n values={props.inDoorListState.comment} isStaff={user?.isStaff || false}
                              setValues={handleInDoorListCommentChange}
                              lang={menu.i18n.defaultLang}
                              allLangs={menu.i18n.otherLangs || []}
                              label={i18n.comment_for_order()}
                              small={true}/>
                <div className={"control"}>
                    <label className="control__label">{i18n.payment_supplement()}</label>
                    {props.inDoorListState.paymentSupplement ? props.inDoorListState.paymentSupplement.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label
                                            className="control__label">{i18n.type()}</label>

                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleInDoorListPaymentSupplementTypeChange(index, parseInt(e.currentTarget.value))}
                                        >

                                            {options.map((option, index) => {
                                                return (
                                                    <option
                                                        selected={index == v.type} value={index}
                                                        disabled={index === 2 && !!props.inDoorListState.paymentSupplement.find((v) => v.type === 2)}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </select>

                                    </div>
                                </div>

                                {
                                    v.type == 2
                                        ?
                                        (v.value as range[]).map((range, rangeIndex) => {
                                            return (
                                                <div style={{display: "flex"}}>
                                                    <NumberInputRange
                                                        fromLabel={i18n.from()}
                                                        toLabel={i18n.to()}
                                                        label={i18n.value()}
                                                        isRequired={true}
                                                        value={range}
                                                        onChange={(int) => handleInDoorListPaymentSupplementValueChange(index, int, rangeIndex)}
                                                    />
                                                    <div className="col-1">
                                                        <button type="button" className="form__remove-button"
                                                                onClick={() => handleRemoveAdditionalRange(rangeIndex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                    fill="#8392A5"></path>
                                                                <path
                                                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                    fill="#8392A5"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handleInDoorListPaymentSupplementValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleInDoorListPaymentSupplementLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              label={i18n.title()}
                                              allLang={menu.i18n.otherLangs || []}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveInDoorListPaymentSupplement(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>

                                {(v.type == 2 && index === props.inDoorListState.paymentSupplement.length - 1) && (
                                    <div className="row add-price-tuple" data-gutter="15" style={{width: "100%"}}>
                                        <a
                                            href="src/components/settings#"
                                            style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px"}}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleAddAdditionalRange()
                                            }}
                                        >
                                            {i18n.add_addit_range()}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddInDoorListPaymentSupplement()
                           }}>
                            {i18n.add_addit_price()}
                        </a>
                    </div>
                </div>
                <div className={"control"}>
                    <label className="control__label">{i18n.discounts()}</label>
                    {props.inDoorListState.discounts ? props.inDoorListState.discounts.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label className="control__label">{i18n.type()}</label>
                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleInDoorListDiscountTypeChange(index, parseInt(e.currentTarget.value))}
                                        >
                                            {optionsDiscount.map((option, index) => {
                                                return (
                                                    <option selected={index == v.type} value={index}>
                                                        {option}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {
                                    v.type !== 2 &&
                                    <div className="col-3">
                                        <NumberInput label={i18n.value()}
                                                     value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                     onChange={(int) => handleInDoorListDiscountValueChange(index, int)}/>
                                    </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleInDoorListDiscountLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveInDoorListDiscount(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddInDoorListDiscount()
                           }}>
                            {i18n.add_discount()}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

interface ICinemaSettings {
    cinemaState: IDeliveryCinemaState
    cinemaToggled: boolean
    cinemaCheck: boolean
    setCinemaState: (state: IDeliveryCinemaState) => void
    setCinemaCheck: (state: boolean) => void
    setCinemaToggled: (state: boolean) => void
}

function CinemaSettings(props: ICinemaSettings) {
    const {i18n} = useGlobalContext()
    const {menu, user} = useUserContext()
    const options = [i18n.fixed(), i18n.percent(), i18n.range_charge()]
    const optionsDiscount = [i18n.fixed(), i18n.percent(), i18n.enter_manually()]

    function handleCinemaCheck(check: boolean) {
        props.setCinemaToggled(true)
        props.setCinemaState({comment: {}, paymentSupplement: [], discounts: []});
        props.setCinemaCheck(check);
    }

    function handleCinemaCommentChange(comment: I18nString) {
        props.setCinemaState({...props.cinemaState, comment});
    }

    // =================================================================================================================

    function handleCinemaPaymentSupplementTypeChange(index: number, type: number) {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: props.cinemaState.paymentSupplement.map((item, i) => {
                if (i === index) {
                    if (type === 2) {
                        return {...item, type: 2, value: [{from: 0, to: 0, value: 0}]};
                    } else {
                        return {...item, type, value: 0};
                    }
                } else {
                    return item
                }
            }),
        });
    }

    function handleAddAdditionalRange() {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: props.cinemaState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: [...(item.value as range[]), {from: 0, to: 0, value: 0}]}
                } else {
                    return item;
                }
            })
        });
    }

    function handleRemoveAdditionalRange(index: number) {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: props.cinemaState.paymentSupplement.map((item) => {
                if (item.type === 2) {
                    return {...item, value: (item.value as range[]).filter((_, i) => i !== index)}
                } else {
                    return item;
                }
            })
        });
    }

    function handleCinemaPaymentSupplementValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.cinemaState.paymentSupplement[index].type) {
            case 0:
                props.setCinemaState({
                    ...props.cinemaState,
                    paymentSupplement: props.cinemaState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setCinemaState({
                    ...props.cinemaState,
                    paymentSupplement: props.cinemaState.paymentSupplement.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setCinemaState({
                    ...props.cinemaState,
                    paymentSupplement: props.cinemaState.paymentSupplement.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                value: (item.value as range[]).map((range, rangeInd) => {
                                    if (rangeInd === rangeIndex) {
                                        return {...value as range}
                                    }
                                    return range;
                                })
                            }
                        }
                        return item
                    })
                });
                break;
        }
    }

    function handleCinemaPaymentSupplementLabelChange(index: number, label: I18nString) {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: props.cinemaState.paymentSupplement.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveCinemaPaymentSupplement(index: number) {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: props.cinemaState.paymentSupplement.filter((_, i) => i !== index)
        });
    }

    function handleAddCinemaPaymentSupplement() {
        props.setCinemaState({
            ...props.cinemaState,
            paymentSupplement: [...props.cinemaState.paymentSupplement, {type: 0, value: 0, label: {}}]
        });
    }

    // =================================================================================================================

    function handleCinemaDiscountTypeChange(index: number, type: number) {
        props.setCinemaState({
            ...props.cinemaState,
            discounts: props.cinemaState.discounts.map((item, i) => {
                if (i === index) {
                    return {...item, type, value: 0};
                } else {
                    return item
                }
            }),
        });
    }

    function handleCinemaDiscountValueChange(index: number, value: number | range, rangeIndex?: number) {
        switch (props.cinemaState.discounts[index].type) {
            case 0:
                props.setCinemaState({
                    ...props.cinemaState,
                    discounts: props.cinemaState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number) * 100
                    } : item)
                });
                break;
            case 1:
                props.setCinemaState({
                    ...props.cinemaState,
                    discounts: props.cinemaState.discounts.map((item, i) => i === index ? {
                        ...item,
                        value: (value as number)
                    } : item)
                });
                break;
            case 2:
                props.setCinemaState({
                    ...props.cinemaState,
                    discounts: props.cinemaState.discounts.map((item, i) => i === index ? {
                        ...item,
                    } : item)
                });
                break;
        }
    }

    function handleCinemaDiscountLabelChange(index: number, label: I18nString) {
        props.setCinemaState({
            ...props.cinemaState,
            discounts: props.cinemaState.discounts.map((item, i) => i === index ? {
                ...item,
                label
            } : item)
        });
    }

    function handleRemoveCinemaDiscount(index: number) {
        props.setCinemaState({
            ...props.cinemaState,
            discounts: props.cinemaState.discounts.filter((_, i) => i !== index)
        });
    }

    function handleAddCinemaDiscount() {
        props.setCinemaState({
            ...props.cinemaState,
            discounts: [...props.cinemaState.discounts, {type: 0, value: 0, label: {}}]
        });
    }

    return (
        <>
            <Checkbox checked={props.cinemaCheck}
                      onChange={handleCinemaCheck}
                      label={i18n.delivery_in_cinema_hall()}/>

            <div style={{display: props.cinemaCheck ? "block" : "none"}}>
                <div className="control">
                    <CustomSwitch size={48}
                                  label={props.cinemaToggled ? i18n.delivery_in_cinema_is_on() :
                                      i18n.delivery_in_cinema_is_off()}
                                  checked={props.cinemaToggled}
                                  onChange={props.setCinemaToggled}/>
                </div>
                <TextAreaI18n values={props.cinemaState.comment} isStaff={!!user?.isStaff}
                              setValues={handleCinemaCommentChange}
                              lang={menu.i18n.defaultLang}
                              allLangs={menu.i18n.otherLangs || []}
                              label={i18n.comment_for_order()}
                              small={true}/>
                <div className={"control"}>
                    <label className="control__label">{i18n.payment_supplement()}</label>
                    {props.cinemaState.paymentSupplement ? props.cinemaState.paymentSupplement.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label
                                            className="control__label">{i18n.type()}</label>

                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleCinemaPaymentSupplementTypeChange(index, parseInt(e.currentTarget.value))}
                                        >

                                            {options.map((option, index) => {
                                                return (
                                                    <option
                                                        selected={index == v.type} value={index}
                                                        disabled={index === 2 && !!props.cinemaState.paymentSupplement.find((v) => v.type === 2)}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </select>

                                    </div>
                                </div>

                                {
                                    v.type == 2
                                        ?
                                        (v.value as range[]).map((range, rangeIndex) => {
                                            return (
                                                <div style={{display: "flex"}} key={rangeIndex}>
                                                    <NumberInputRange
                                                        fromLabel={i18n.from()}
                                                        toLabel={i18n.to()}
                                                        label={i18n.value()}
                                                        isRequired={true}
                                                        value={range}
                                                        onChange={(int) => handleCinemaPaymentSupplementValueChange(index, int, rangeIndex)}
                                                    />
                                                    <div className="col-1">
                                                        <button type="button" className="form__remove-button"
                                                                onClick={() => handleRemoveAdditionalRange(rangeIndex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                                    fill="#8392A5"></path>
                                                                <path
                                                                    d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                                    fill="#8392A5"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="col-3">
                                            <NumberInput label={i18n.value()}
                                                         value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                         onChange={(int) => handleCinemaPaymentSupplementValueChange(index, int)}/>
                                        </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={!!user?.isStaff}
                                              setValues={(val) => handleCinemaPaymentSupplementLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              label={i18n.title()}
                                              allLang={menu.i18n.otherLangs || []}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveCinemaPaymentSupplement(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>

                                {(v.type == 2 && index === props.cinemaState.paymentSupplement.length - 1) && (
                                    <div className="row add-price-tuple" data-gutter="15" style={{width: "100%"}}>
                                        <a
                                            href="src/components/settings#"
                                            style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px"}}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleAddAdditionalRange()
                                            }}
                                        >
                                            {i18n.add_addit_range()}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddCinemaPaymentSupplement()
                           }}>
                            {i18n.add_addit_price()}
                        </a>
                    </div>
                </div>
                <div className={"control"}>
                    <label className="control__label">{i18n.discounts()}</label>
                    {props.cinemaState.discounts ? props.cinemaState.discounts.map((v, index) => {
                        return (
                            <div className="row add-price-tuple" data-gutter="15"
                                 key={"pt-" + index.toString() + "paymentSupplement"}>
                                <div className="col-4">
                                    <div className="control">
                                        <label className="control__label">{i18n.type()}</label>
                                        <select className="control__input"
                                                value={v.type}
                                                onChange={(e) => handleCinemaDiscountTypeChange(index, parseInt(e.currentTarget.value))}
                                        >
                                            {optionsDiscount.map((option, index) => {
                                                return (
                                                    <option selected={index == v.type} value={index}>
                                                        {option}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {
                                    v.type !== 2 &&
                                    <div className="col-3">
                                        <NumberInput label={i18n.value()}
                                                     value={v.type == 0 ? (v.value as number) / 100 : (v.value as number)}
                                                     onChange={(int) => handleCinemaDiscountValueChange(index, int)}/>
                                    </div>
                                }

                                <div className="col-4">
                                    <TextI18n values={v.label} isStaff={user?.isStaff || false}
                                              setValues={(val) => handleCinemaDiscountLabelChange(index, val)}
                                              lang={menu.i18n.defaultLang}
                                              allLang={menu.i18n.otherLangs || []}
                                              label={i18n.title()}
                                              isRequired={true}
                                    />
                                </div>

                                <div className="col-1">
                                    <button type="button" className="form__remove-button"
                                            onClick={() => handleRemoveCinemaDiscount(index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.5109 2.3814H14.3669C14.3669 2.19883 14.3307 2.01807 14.2605 1.84957C14.1902 1.68108 14.0872 1.52818 13.9575 1.39973C13.8277 1.27128 13.6738 1.16983 13.5046 1.10123C13.3354 1.03262 13.1543 0.998243 12.9718 1.00007H10.2367C10.0542 0.998243 9.87308 1.03262 9.70389 1.10123C9.5347 1.16983 9.38079 1.27128 9.25106 1.39973C9.12132 1.52818 9.01834 1.68108 8.94806 1.84957C8.87778 2.01807 8.84159 2.19883 8.8416 2.3814H4.69762C4.51445 2.3814 4.33877 2.45416 4.20925 2.58369C4.07972 2.71321 4.00696 2.88888 4.00696 3.07206C4.00696 3.44502 4.31085 3.76272 4.69762 3.76272H18.5109C18.6941 3.76272 18.8697 3.68996 18.9993 3.56043C19.1288 3.43091 19.2016 3.25523 19.2016 3.07206C19.2016 2.88888 19.1288 2.71321 18.9993 2.58369C18.8697 2.45416 18.6941 2.3814 18.5109 2.3814ZM8.8416 9.97869C8.8416 9.59192 9.15931 9.28803 9.53227 9.28803C9.91904 9.28803 10.2229 9.60574 10.2229 9.97869V18.2667C10.2229 18.4498 10.1502 18.6255 10.0206 18.755C9.89112 18.8846 9.71544 18.9573 9.53227 18.9573C9.34909 18.9573 9.17342 18.8846 9.04389 18.755C8.91437 18.6255 8.8416 18.4498 8.8416 18.2667V9.97869ZM12.9856 9.97869C12.9856 9.60574 13.3033 9.28803 13.6762 9.28803C14.063 9.28803 14.3669 9.60574 14.3669 9.97869V18.2667C14.3669 18.4498 14.2941 18.6255 14.1646 18.755C14.0351 18.8846 13.8594 18.9573 13.6762 18.9573C13.4931 18.9573 13.3174 18.8846 13.1879 18.755C13.0584 18.6255 12.9856 18.4498 12.9856 18.2667V9.97869Z"
                                                fill="#8392A5"></path>
                                            <path
                                                d="M17.8213 6.52539L16.4262 21.5957C16.4262 21.6509 16.3433 21.72 16.2881 21.72H6.92267C6.8536 21.72 6.78453 21.6371 6.78453 21.5957L5.38939 6.52539H17.7937H17.8213ZM17.9318 5.14407H5.25126C5.0757 5.14298 4.9019 5.17909 4.7413 5.25002C4.5807 5.32095 4.43695 5.42509 4.3195 5.55558C4.20206 5.68608 4.11358 5.83997 4.0599 6.00713C4.00622 6.17429 3.98856 6.35092 4.00807 6.52539L5.41702 21.72C5.48609 22.4797 6.16294 23.1013 6.93648 23.1013H16.2743C16.6526 23.0967 17.0162 22.9536 17.2962 22.6991C17.5762 22.4446 17.7532 22.0962 17.7937 21.72L19.2027 6.52539C19.2222 6.35092 19.2045 6.17429 19.1508 6.00713C19.0972 5.83997 19.0087 5.68608 18.8912 5.55558C18.7738 5.42509 18.63 5.32095 18.4694 5.25002C18.3088 5.17909 18.135 5.14298 17.9595 5.14407H17.9318Z"
                                                fill="#8392A5"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : ""}

                    <div className="row add-price-tuple" data-gutter="15">
                        <a href="src/components/settings#" style={{paddingTop: "10px", paddingLeft: "10px"}}
                           onClick={(e) => {
                               e.preventDefault()
                               handleAddCinemaDiscount()
                           }}>
                            {i18n.add_discount()}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
