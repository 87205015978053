import {AppDispatch, RootState} from "migration/app/store";
import {ReceiverList} from "migration/entities/receiverList";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {TelegramPoolOfTenant} from "migration/entities/telegramPoolOfTenant";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import NotificationService, {
    cmdBindReceiverListFromFeedbackPageReq,
    cmdUnbindReceiverListFromFeedbackPageReq,
    cmdUnBlockTgGroupInTelegramPoolReq,
    cmdUnBlockTgUserInTelegramPoolReq
} from "../api/notificationService";
import {
    cmdBlockTgGroupInTelegramPoolReq,
    cmdBlockTgUserInTelegramPoolReq,
    cmdCreateReceiverListReq,
    cmdSpecifyTgUsersInReceiverList,
    cmdSpecifyTgGroupsInReceiverList,
    cmdSpecifyEmailsInReceiverList,
    cmdDeleteReceiverListReq,
    cmdRenameReceiverListReq,
} from "../api/notificationService";
import {
    SetTelegramPoolOfTenantAction,
    SetReceiverListsAction,
    SetIsLoadingGetTelegramPoolOfTenantAction,
    SetIsLoadingGetAllReceiverListsAction,
    GeneralNotificationActionEnum,
} from "./types";
import {LangsList} from "migration/entities/base/mlString";

export const GeneralNotificationActionCreators = {
    setTelegramPoolOfTenant: (telegramPoolOfTenant: TelegramPoolOfTenant): SetTelegramPoolOfTenantAction => ({
        type: GeneralNotificationActionEnum.SET_TELEGRAM_POOL_OF_TENANT,
        payload: telegramPoolOfTenant,
    }),
    setReceiverLists: (receiverLists: ReceiverList[]): SetReceiverListsAction => ({
        type: GeneralNotificationActionEnum.SET_RECEIVER_LISTS,
        payload: receiverLists,
    }),
    setIsLoadingGetTelegramPoolOfTenant: (isLoading: boolean): SetIsLoadingGetTelegramPoolOfTenantAction => ({
        type: GeneralNotificationActionEnum.SET_IS_LOADING_GET_TELEGRAM_POOL_OF_TENANT,
        payload: isLoading,
    }),
    setIsLoadingGetAllReceiverLists: (isLoading: boolean): SetIsLoadingGetAllReceiverListsAction => ({
        type: GeneralNotificationActionEnum.SET_IS_LOADING_GET_ALL_RECEIVER_LISTS,
        payload: isLoading,
    }),

    getTelegramPoolOfTenant: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, NotificationService.getTelegramPoolOfTenant(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: GeneralNotificationActionCreators.setIsLoadingGetTelegramPoolOfTenant,
            successCallback: (res) => {
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    activeUsers: res.data.data?.activeUsers || [],
                    activeGroups: res.data.data?.activeGroups || [],
                    blockedUsers: res.data.data?.blockedUsers || [],
                    blockedGroups: res.data.data?.blockedGroups || [],
                }));
            },
            failedCallback: () => {
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    activeUsers: [],
                    activeGroups: [],
                    blockedUsers: [],
                    blockedGroups: [],
                }));

            }
        });
    },

    getAllReceiverLists: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, NotificationService.getAllReceiverLists(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: GeneralNotificationActionCreators.setIsLoadingGetAllReceiverLists,
            successCallback: (res) => {
                dispatch(GeneralNotificationActionCreators.setReceiverLists(res.data.data?.receiverLists || []));
            },
        });
    },

    blockTgGroupInTelegramPool: (request: cmdBlockTgGroupInTelegramPoolReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdBlockTgGroupInTelegramPool(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const blockedGroup = getState().generalNotification.telegramPoolOfTenant.activeGroups.find((item) => item.chatId === request.tgChatId);
                if (!blockedGroup) return;
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    ...getState().generalNotification.telegramPoolOfTenant,
                    activeGroups: getState().generalNotification.telegramPoolOfTenant.activeGroups.filter((item) => item.chatId !== request.tgChatId),
                    blockedGroups: [...getState().generalNotification.telegramPoolOfTenant.blockedGroups, blockedGroup],
                }))
            },
        });
    },

    unBlockTgGroupInTelegramPool: (request: cmdUnBlockTgGroupInTelegramPoolReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdUnblockTgGroupInTelegramPool(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const blockedGroup = getState().generalNotification.telegramPoolOfTenant.activeGroups.find((item) => item.chatId === request.tgChatId);
                if (!blockedGroup) return;
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    ...getState().generalNotification.telegramPoolOfTenant,
                    blockedGroups: getState().generalNotification.telegramPoolOfTenant.blockedGroups.filter((item) => item.chatId !== request.tgChatId),
                }))
            },
        });
    },

    blockTgUserInTelegramPool: (request: cmdBlockTgUserInTelegramPoolReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdBlockTgUserInTelegramPool(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const blockedUser = getState().generalNotification.telegramPoolOfTenant.activeUsers.find((item) => item.chatId === request.tgChatId);
                if (!blockedUser) return;
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    ...getState().generalNotification.telegramPoolOfTenant,
                    activeUsers: getState().generalNotification.telegramPoolOfTenant.activeUsers.filter((item) => item.chatId !== request.tgChatId),
                    blockedUsers: [...getState().generalNotification.telegramPoolOfTenant.blockedUsers, blockedUser],
                }))
            },
        });
    },

    unBlockTgUserInTelegramPool: (request: cmdUnBlockTgUserInTelegramPoolReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdUnblockTgUserInTelegramPool(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const blockedUser = getState().generalNotification.telegramPoolOfTenant.blockedUsers.find((item) => item.chatId === request.tgChatId);
                if (!blockedUser) return;
                dispatch(GeneralNotificationActionCreators.setTelegramPoolOfTenant({
                    ...getState().generalNotification.telegramPoolOfTenant,
                    blockedUsers: getState().generalNotification.telegramPoolOfTenant.blockedUsers.filter((item) => item.chatId !== request.tgChatId),
                }))
            },
        });
    },

    createReceiverList: (request: cmdCreateReceiverListReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdCreateReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const newUser = {
                    id: request.receiverListId,
                    name: request.name,
                    emails: null,
                    isArchived: false,
                    lang: request.lang as LangsList,
                    usedBy: null,
                    telegram: {
                        users: [],
                        groups: [],
                    }
                };
                dispatch(GeneralNotificationActionCreators.setReceiverLists([
                    ...getState().generalNotification.receiverLists,
                    newUser,
                ]));
            },
        });
    },

    specifyTgUsersInReceiverList: (request: cmdSpecifyTgUsersInReceiverList, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdSpecifyTgUsersInReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    specifyTgGroupsInReceiverList: (request: cmdSpecifyTgGroupsInReceiverList, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdSpecifyTgGroupsInReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    specifyEmailsInReceiverList: (request: cmdSpecifyEmailsInReceiverList, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdSpecifyEmailsInReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const receiverList = getState().generalNotification.receiverLists.find((item) => item.id === request.receiverListId);
                if (!receiverList) return;
                dispatch(GeneralNotificationActionCreators.setReceiverLists([
                    ...getState().generalNotification.receiverLists.filter((item) => item.id !== request.receiverListId),
                    {...receiverList, emails: [...request.emails]},
                ]));
            },
        });
    },

    deleteReceiverList: (request: cmdDeleteReceiverListReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdDeleteReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(GeneralNotificationActionCreators.setReceiverLists(getState().generalNotification.receiverLists.filter((item) => item.id !== request.receiverListId)));
            },
        });
    },

    renameReceiverList: (request: cmdRenameReceiverListReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdRenameReceiverList(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    bindReceiverListToFeedbackPage: (request: cmdBindReceiverListFromFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        const {feedbacksList} = getState().feedbacksList;
        const {receiverLists} = getState().generalNotification;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdBindReceiverListToFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const receiversListCopy = [...receiverLists];
                const receiverList = receiversListCopy.find((item) => item.id === request.receiverListId);
                if (!receiverList) return;
                receiverList.usedBy = [...(receiverList?.usedBy || []), {
                    id: request.feedbackPageId,
                    type: "feedbackPage",
                    name: feedbacksList.find((item) => item.id === request.feedbackPageId)?.name || "",
                }];
                dispatch(GeneralNotificationActionCreators.setReceiverLists(receiversListCopy));
            },
        });
    },

    unbindReceiverListFromFeedbackPage: (request: cmdUnbindReceiverListFromFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, NotificationService.cmdUnbindReceiverListFromFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                const receiversListCopy = [...getState().generalNotification.receiverLists];
                const receiverList = receiversListCopy.find((item) => item.id === request.receiverListId);
                if (!receiverList) return;
                receiverList.usedBy = receiverList.usedBy?.filter((item) => item.id !== request.feedbackPageId) || [];
                dispatch(GeneralNotificationActionCreators.setReceiverLists(receiversListCopy));
            },
        });
    }
};
