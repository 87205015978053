import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {IMenuItemMedia} from "../../models/item/itemMedia";
import {useParams} from "react-router-dom";
import {Image} from "../image/image";
import {CropperModal} from "../modal/cropperModal";
import {ReactComponent as DeleteSVG} from "../../resourse/img/general/ic-close_white.svg";
import {FormWithLoader} from "../loader/formWithLoader";

const styles: { [key: string]: CSSProperties } = {
    imageBlock: {
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        border: "1px solid #e5e5e5",
        borderRadius: "4px",
        padding: "10px",
        marginBottom: "10px",
        flexWrap: "wrap",
    },
    imagesBox: {
        height: "100px",
        margin: "10px",
        borderRadius: "4px",
    },
    drag: {
        height: "100px",
        marginRight: "10px",
        borderRadius: "4px",
    },
    upload: {
        width: "100px",
        height: "100px",
        marginBottom: "auto",
    },
    deleteSVG: {
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        borderRadius: "20%",
        position: "relative",
        top: "-32px",
        right: "32px",
    },
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function ItemMediaForm() {
    const {i18n, api} = useGlobalContext()
    const {currentMenuId} = useUserContext()

    const {id} = useParams()

    const [itemMedia, setItemMedia] = useState<IMenuItemMedia | null>({
        images: [],
    })

    const [loading, setLoading] = useState<boolean>(false)
    const [uploadLoading, setUploadLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() =>{
        const controller = new AbortController()
        api.getMenuItemMedia({controller, setLoading},id as string).then((res) => {
            if (res.data) {
                setItemMedia(res.data)
            }
        })
        return() => controller.abort()
    }, [])
    const [modal, setModal] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<File | null>(null)

    function onUploadFile(file: File | null) {
        setSelectedImage(file);
        setModal(true)
    }

    function onDeleteImage(index: number) {
        setItemMedia({
            ...itemMedia,
            images: (itemMedia?.images || []).filter((_, i) => i !== index),
        })
    }

    function UploadImage(file: File) {
        api.saveItemImage({setLoading:setUploadLoading},file).then((res) => {
            if (res.data) {
                setItemMedia({
                    ...itemMedia,
                    images: itemMedia?.images ? [...itemMedia.images, res.data.newFilename] : [res.data.newFilename],
                })
            }
        })
    }

    function onSavedMedia() {
        api.SaveItemMedia({setLoading:setSaveLoading}, currentMenuId, id as string, itemMedia?.images || [])
    }

    const dragStartIndex = useRef<number>(-1)

    return (
        <>
            <FormWithLoader isloading={loading} className="form">
                <p className="tx-gray mt-4">
                    {i18n.uploaded_images()}
                </p>
                <div
                    style={{...styles.imageBlock}}
                >

                    {itemMedia?.images?.map((image, i) => {
                            return (
                                <div
                                    onDragStart={() => {
                                        dragStartIndex.current = i
                                    }}
                                    onDragOver={(e) => {
                                        e.preventDefault()
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        setItemMedia({
                                            ...itemMedia,
                                            images: reorder(itemMedia?.images || [], dragStartIndex.current, i)
                                        })
                                    }} key={image}>
                                    <Image className={""} alt={image} src={image} key={image}
                                           style={styles.imagesBox}
                                           type={"menuItems"}/>
                                    <DeleteSVG style={styles.deleteSVG}
                                               onClick={() => onDeleteImage(i)}/>
                                </div>

                            )
                        }
                    )}
                    <div className="uploader" style={{...styles.upload, width: "18rem", height: "18rem"}}>
                        <label id="uploaderLabelPanel" className="uploader__area ">
                            <input type="hidden" name="old_img"/>
                            <div id="emptyImg" className="uploader__placeholder">
                                <div className="uploader__icon">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z"
                                            fill="currentColor"></path>
                                    </svg>
                                </div>
                            </div>
                            <input type="file" className="uploader__input" id="uploaderFile"
                                   data-crop-aspect-ratio="2.2" data-crop-img-max-width="1500"
                                   data-crop-img-max-height="1500"
                                   onChange={(e) => {
                                       onUploadFile(e.currentTarget.files?.item(0) || null)
                                       e.currentTarget.value = ""
                                       if (e.currentTarget.files)
                                           for (let i = e.currentTarget.files?.length - 1; i >= 0; i--) {
                                               console.log(e.currentTarget.files?.item(i)?.name);
                                           }
                                   }}
                            />
                        </label>
                    </div>
                </div>

                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={onSavedMedia} label={i18n.save()}
                                      disabled={false} isLoading={saveLoading}
                        />
                    </div>
                </div>
            </FormWithLoader>
            <CropperModal modal={modal} selectedImage={selectedImage} setModal={setModal}
                          saveImage={UploadImage} setSelectImage={setSelectedImage} aspectRatio={1}/>
        </>
    )
}
