import {SectionForm} from "../../components/form/sectionForm";
import {useParams} from "react-router-dom";
import {useGlobalContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import React, {useEffect} from "react";
import {RouteNames} from "../../migration/pages";

export function SectionEdit() {
    let {id} = useParams()
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.section_editing()
    }, [i18n])
    return (
        <section className="app__main">
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.menus(), link: RouteNames.MENUS, index: "brcr-0"},
                    {title: i18n.edit_section(), link: RouteNames.MENUS_SECTION_EDIT.replace(":id", id || ""), index: "brcr-1"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.section()}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card">

                            <SectionForm id={id ? id : ""} edit={true}/>

                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}
