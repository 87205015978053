import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import {
    ColaProductKeyEnum,
    ColaActionEnum,
    SetIsCocaColaClientAction,
    SetIsCurrentProductCocaColaAction,
    SetCurrentProductKeyAction,
    SetIsCurrentCategoryCocaColaAction
} from "./types";
import ColaService from "../api/colaService";

export const CocaColaActionCreators = {
    setIsCocaColaClient: (payload: boolean): SetIsCocaColaClientAction => ({
        type: ColaActionEnum.SET_IS_COCA_COLA_CLIENT,
        payload
    }),
    setIsCurrentCategoryCocaCola: (payload: boolean): SetIsCurrentCategoryCocaColaAction => ({
        type: ColaActionEnum.SET_IS_CURRENT_CATEGORY_COCA_COLA,
        payload
    }),
    setIsCurrentProductCocaCola: (payload: boolean): SetIsCurrentProductCocaColaAction => ({
        type: ColaActionEnum.SET_IS_CURRENT_PRODUCT_COCA_COLA,
        payload
    }),
    setCurrentProductKey: (payload: ColaProductKeyEnum): SetCurrentProductKeyAction => ({
        type: ColaActionEnum.SET_CURRENT_PRODUCT_KEY,
        payload
    }),

    cocaColaClientById: (navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, ColaService.getCocaColaClientById(currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                dispatch(CocaColaActionCreators.setIsCocaColaClient(res.data.data.isCocaColaClient));
            },
            hideNotify: true
        });
    },

    cocaColaMenuCategoryById: (id: string, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, ColaService.getCocaColaMenuCategoryById(id, currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                dispatch(CocaColaActionCreators.setIsCurrentCategoryCocaCola(res.data.data.isCocaColaMenuCategory));
            },
            hideNotify: true
        });
    },

    cocaColaProductById: (id: string, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, ColaService.getCocaColaProductById(id, currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            successCallback: (res) => {
                dispatch(CocaColaActionCreators.setIsCurrentProductCocaCola(res.data.data.isCocaColaProduct));
                dispatch(CocaColaActionCreators.setCurrentProductKey(res.data.data.productKey));
            },
            hideNotify: true
        });
    },

    markAsCocaColaClient: (navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdMarkAsCocaColaClient( currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCocaColaClient(true));
                Notify.Success({title: txt.organization_marked_as_coca_cola_client_successfully[currentLang], message: ""});
            }
        })
    },

    markAsCocaColaMenuCategory: (id: string, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdMarkAsCocaColaMenuCategory(id, currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCurrentCategoryCocaCola(true));
                Notify.Success({title: txt.menu_category_marked_as_coca_cola_successfully[currentLang], message: ""});
            }
        })
    },

    markAsCocaColaProduct: (productId: string, colaProductKey: ColaProductKeyEnum, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdMarkAsCocaColaProduct({productId, colaProductKey}, currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCurrentProductCocaCola(true));
                Notify.Success({title: txt.product_marked_as_coca_cola_successfully[currentLang], message: ""});
            }
        })
    },

    unMarkAsCocaColaClient: (navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdUnMarkAsCocaColaClient(currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCocaColaClient(false));
                Notify.Success({title: txt.organization_unmarked_from_coca_cola_successfully[currentLang], message: ""});
            }
        })
    },

    unMarkAsCocaColaMenuCategory: (id: string, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdUnMarkAsCocaColaMenuCategory(id, currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCurrentCategoryCocaCola(false));
                Notify.Success({title: txt.menu_category_unmarked_from_coca_cola_successfully[currentLang], message: ""});
            }
        })
    },

    unMarkAsCocaColaProduct: (id: string, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, ColaService.cmdUnMarkAsCocaColaProduct(id, currentUser?.org?.id || ""),{
            navigateCallback: navigationCallback,
            mainCallback: () => {
                dispatch(CocaColaActionCreators.setIsCurrentProductCocaCola(false));
                Notify.Success({title: txt.product_unmarked_from_coca_cola_successfully[currentLang], message: ""});
            }
        })
    },

}
