import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Modal} from "antd";
import {Reason} from "migration/entities/Feedback";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {TextI18n} from "../../../../../../../components/input/textI18n";
import classes from "./ModalReasonEdit.module.scss";

interface ModalReasonEditProps {
    isReasonEditModalOpen: boolean;
    setIsReasonEditModalOpen: (value: boolean) => void;
    reason: Reason;
    gradeType: "positiveGrading" | "negativeGrading" | 1 | 2 | 3 | 4 | 5;
}

export const ModalReasonEdit: FunctionComponent<ModalReasonEditProps> = (props) => {
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {
        setFeedbackPage,
        editPositiveGradeReasonFeedbackPage,
        editNegativeGradeReasonFeedbackPage,
        editSpecificGradeReasonFeedbackPage,
    } = useActions();
    const [editedReason, setEditedReason] = useState<Reason>(props.reason || {});

    const setEditedReasonName = (value: MlString) => {
        setEditedReason({...editedReason, reasonName: value});
    }

    const onOk = () => {
        const updatedFeedback = {...feedbackPage};
        switch (props.gradeType) {
            case "positiveGrading":
                updatedFeedback.positiveGrading?.reasons.forEach((reason, index) => {
                    if (reason?.reasonId === props.reason?.reasonId) {
                        updatedFeedback.positiveGrading.reasons[index] = editedReason;
                    }
                });
                break;
            case "negativeGrading":
                updatedFeedback.negativeGrading?.reasons.forEach((reason, index) => {
                    if (reason?.reasonId === props.reason?.reasonId) {
                        updatedFeedback.negativeGrading.reasons[index] = editedReason;
                    }
                });
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                updatedFeedback?.specificGrading?.[props.gradeType]?.reasons.forEach((reason, index) => {
                    if (reason?.reasonId === props.reason?.reasonId) {
                        updatedFeedback.specificGrading[props.gradeType as number].reasons[index] = editedReason;
                    }
                });
                break;
        }
        setFeedbackPage(updatedFeedback);

        if (props.gradeType === "positiveGrading") {
            editPositiveGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason?.reasonId,
                reasonName: editedReason?.reasonName as MlString
            }, feedbackPage, {navigate: navigate});
        } else if (props.gradeType === "negativeGrading") {
            editNegativeGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason?.reasonId,
                reasonName: editedReason?.reasonName as MlString
            }, feedbackPage, {navigate: navigate});
        } else {
            editSpecificGradeReasonFeedbackPage({
                feedbackPageId: feedbackPage?.id,
                reasonId: props.reason?.reasonId,
                reasonName: editedReason?.reasonName as MlString
            }, feedbackPage, {navigate: navigate}, props.gradeType);
        }
        props.setIsReasonEditModalOpen(false);
    }

    const onCancel = () => {
        setEditedReason(props.reason);
        props.setIsReasonEditModalOpen(false);
    }

    return (
        <Modal
            title={txt.edit_reason[currentLang]}
            open={props.isReasonEditModalOpen}
            onOk={onOk}
            onCancel={onCancel}
        >
            <div className={classes.reasons}>
                <TextI18n
                    values={editedReason?.reasonName}
                    setValues={setEditedReasonName as (c: { [key: string]: string }) => void}
                    lang={feedbackPage?.defaultLang}
                    allLang={feedbackPage?.otherLangs || []}
                    isStaff={true}
                    placeholder={txt.enter_reason[currentLang]}
                />
            </div>
        </Modal>
    )
}
