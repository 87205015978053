import React, {useEffect, useMemo} from "react";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {CustomRadio} from "../input/customRadio";
import {IikoNomenclature} from "migration/entities/iikoNomenclature";
import {IikoNomenclatureProduct} from "migration/entities/iikoNomenclature/iikoNomenclatureProduct";
import {IikoProductId} from "../../migration/entities/baseAliases";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";
import {IikoExternalMenuItem} from "../../migration/entities/iikoExternalMenu";


interface modalIIkoItem {
    iikoNomenclature: IikoNomenclature
    bindProduct: IikoNomenclatureProduct | IikoExternalMenuItem | null
    nowProduct: string | null
    setNowProduct: (e: string | null) => void
    modalOpen: boolean
    setModalOpen: (e: boolean) => void
    onBindIikoProduct: (iikoProductId: string) => void
    onUnbindIikoProduct: () => void
    bindLoading: boolean
}

export function ModalIIkoItem(props: modalIIkoItem) {
    const {i18n} = useGlobalContext()

    const {iikoNomenclature, iikoMenuSettings, iikoExternalMenuItems} = useTypedSelector(state => state.iiko);

    const uniqueIikoProducts: {
        [iikoProductId: IikoProductId]: IikoNomenclatureProduct
    } = useMemo(() => {
        const iikoProducts: { [iikoProductId: IikoProductId]: IikoNomenclatureProduct } = {};

        Object.keys(iikoNomenclature?.products || {}).filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        }).forEach((productId) => {
            if (Object.values(iikoMenuSettings?.productBindings || {}).includes(productId)) {
                return;
            }
            iikoProducts[productId] = iikoNomenclature.products[productId];
        });

        return iikoProducts;
    }, [iikoNomenclature]);

    useEffect(() => {
        if (props.modalOpen) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [props.modalOpen])
    return (
        <>
            <div
                className={props.modalOpen ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                id="modal-preview" style={{display: props.modalOpen ? "block" : "none"}}
                aria-hidden={props.modalOpen}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                    onClick={() => props.setModalOpen(false)}>
                            </button>
                            <div id="item-preview-content">
                                <h3 className="mt-4">{i18n.iiko_binding()}</h3>
                                <div className="default-table my-4" style={{height: "500px", overflow: "scroll"}}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>{i18n.iiko_item_name()}</th>
                                            <th>{i18n.iiko_item_id()}</th>
                                            <th>{i18n.iiko_item_prices()}</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {Object.keys(uniqueIikoProducts)?.map((productId, index) => {
                                            return (
                                                <tr key={productId}>
                                                    <td data-title={i18n.iiko_item_name()}>
                                                        <CustomRadio
                                                            key={"iiko" + index}
                                                            value={productId}
                                                            isChecked={
                                                                (iikoMenuSettings.iikoExternalMenuId )
                                                                    ? productId === (props.bindProduct as IikoExternalMenuItem)?.itemId || productId === props.nowProduct
                                                                    : productId === (props.bindProduct as IikoNomenclatureProduct)?.id || productId === props.nowProduct
                                                            }
                                                            label={uniqueIikoProducts[productId]?.data?.name}
                                                            onChange={() => {
                                                                         if (props.bindProduct) return
                                                                         props.setNowProduct(productId)
                                                                     }}
                                                        />
                                                        <span>{uniqueIikoProducts[productId]?.data?.name}</span>
                                                    </td>
                                                    <td data-title={i18n.iiko_item_id()} className="number-td">
                                                        {productId}
                                                    </td>
                                                    <td data-title={i18n.iiko_item_prices()} className="number-td">
                                                        {uniqueIikoProducts[productId]?.data?.sizePrices?.map(size => size.price?.currentPrice || 0)?.join(", ")}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="form__buttons">
                                    <div className="">
                                        {props.bindProduct
                                            ?
                                            <button className="button form__button -bordered_red" type="button"
                                                    style={{display: "block"}}
                                                    onClick={() => {
                                                        props.onUnbindIikoProduct()
                                                    }}
                                            >
                                                {i18n.unbind_iiko()}
                                            </button>
                                            :
                                            <SubmitButton
                                                onSubmit={() => props.onBindIikoProduct(props.nowProduct || "")}
                                                disabled={!!props.bindProduct}
                                                label={i18n.bind()}
                                                isLoading={props.bindLoading}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.modalOpen ?
                <div className="modal-backdrop show"></div>
                : null}
        </>
    )
}
