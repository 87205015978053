import {MenuState, MenuAction, MenuActionEnum} from "./types";

const initialState: MenuState = {
    isLoadingExcelFile: false,
    excelFileUploadError: {
        success: true,
        message: ""
    },
    mentionsMapping: {
        menuId: "",
        toProducts: {},
        toCategories: {},
    },
    isLoadingGetMentionsMapping: true,
}

export default function menuReducer(state = initialState, action: MenuAction): MenuState {
    switch (action.type) {
        case MenuActionEnum.SET_IS_LOADING_EXCEL_FILE:
            return {...state, isLoadingExcelFile: action.payload};
        case MenuActionEnum.SET_EXCEL_FILE_UPLOAD_ERROR:
            return {...state, excelFileUploadError: action.payload};
        case MenuActionEnum.SET_MENTIONS_MAPPING:
            return {...state, mentionsMapping: action.payload}
        case MenuActionEnum.SET_IS_LOADING_GET_MENTIONS_MAPPING:
            return {...state, isLoadingGetMentionsMapping: action.payload}
        default:
            return state;
    }
}
