import React, {useEffect} from "react";
import {Pagination} from "../../components/pagination/pagination";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {CURRENCY} from "../../utils/options/options";
import {useCustomers} from "../../hooks/order/customers";
import {OrderPageTabs} from "../../components/tabs/orderPageTabs";
import {SearchHeader} from "../../components/input/search";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {RouteNames} from "../../migration/pages";

export function Customers() {
    const info = useCustomers()
    const {user, currentMenuId} = useUserContext()
    const {i18n, userInfoLoading, api} = useGlobalContext()
    const [isLoadingDownload, setIsLoadingDownload] = React.useState(false)
    const dowloadCustomersReport = async (e: any) => {
        api.downloadCustomerReports({setLoading: setIsLoadingDownload}, user?.org?.id || "", currentMenuId).finally(() => {
            setIsLoadingDownload(false)
        })
    }

    useEffect(() => {
        document.title = i18n.customers()
    }, [i18n])
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.customers(), link: RouteNames.CUSTOMERS, index: "brcr-0"}
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.customers()}</h1>
                    </div>
                </div>

                <OrderPageTabs/>
                <div className="default-card">

                    <div className="default-card__top-row">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <SearchHeader onSubmit={info.handleFind} label={i18n.search()}/>

                            <button
                                type="submit"
                                className={isLoadingDownload ? "button modal__button -blue -loading" : "button modal__button -blue"}
                                style={{margin: "0 0 15px 0"}}
                                onClick={dowloadCustomersReport}
                            >
                                {i18n.download()}
                            </button>
                        </div>

                        <div className="results-text d-none d-lg-block d-sm-block">
                            {i18n.all()}:
                            <span className="search-count">{info.totalCount}</span>
                        </div>
                    </div>

                    <div className="default-table">
                        <table id="results-not-found-table"
                               style={{display: info.customers?.length !== 0 ? "none" : "table"}}>
                            <tbody>
                            <tr>
                                <td className="px-2 py-5 text-center">
                                    <span className="tx-gray">{i18n.customers_not_found()}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="orders-table"
                               style={{display: info.customers?.length === 0 ? "none" : "table"}}>
                            <thead>
                            <tr>
                                <th>{i18n.name()}</th>
                                <th>{i18n.phone()}</th>
                                <th>{i18n.orders_count()}</th>
                                <th>{i18n.orders_sum()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader columns={4} rows={5}
                                             isEmpty={info.customers?.length === 0 && !userInfoLoading && !info.customersFound}
                                             emptyMessage={i18n.help_empty_customers()} isLoading={info.loading}>

                                {
                                    info.customers?.map((customer) => {
                                        return (
                                            <tr key={customer.id}>
                                                <td data-title={i18n.name()}>
                                                <span
                                                    className="text-secondary">{customer.name}</span>
                                                </td>
                                                <td data-title={i18n.phone()}
                                                    className="lg-text-right">
                                                    <span
                                                        className="text-secondary">{customer.phone.number}</span>
                                                </td>
                                                <td data-title={i18n.orders_count()}>
                                                    <span className="text-secondary">{customer.ordersCount}</span>
                                                </td>
                                                <td data-title={i18n.orders_sum()}>
                                                    <span
                                                        className="text-secondary">{customer.ordersSum / 100} {CURRENCY[info.menu.i18n.currency]}</span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </TBodyWithLoader>
                        </table>
                    </div>

                    <Pagination page={info.page} withdraw={info.pageSize} all={info.totalCount}
                                setPage={info.setPage}/>

                </div>
            </main>
        </section>
    )
}
