import {AxiosResponse} from "axios";
import {LiqpaySettings} from "migration/entities/payments";
import $api from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface LiqpaySettingsReq {
    orgId: string,
}

export interface SaveLiqpayPaymentSettingsReq {
    orgId: string,
    publicKey: string,
    encryptedPrivateKey: string,
}

export interface EncodeLiqpayPrivateKey {
    privateKey: string,
    // liqPayEncryptionKey: string,
}

export class OrgSettingsService {
    static async liqpaySettings(request: LiqpaySettingsReq, controller: AbortController): Promise<AxiosResponse<SuccessResponse<LiqpaySettings> | ErrorResponse>> {
        return $api.post<SuccessResponse<LiqpaySettings> | ErrorResponse>(ApiRoutes.LIQPAY_SETTINGS, request, {signal: controller.signal});
    }

    static async saveLiqpayPaymentSettings(request: SaveLiqpayPaymentSettingsReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return $api.post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SAVE_LIQPAY_PAYMENT_SETTINGS, request);
    }

    static async encodeLiqpayPrivateKey(request: EncodeLiqpayPrivateKey): Promise<AxiosResponse<SuccessResponse<{encryptedPrivateKey: string}> | ErrorResponse>> {
        return $api.post<SuccessResponse<{encryptedPrivateKey: string}> | ErrorResponse>(ApiRoutes.ENCODE_LIQPAY_PRIVATE_KEY, request);
    }
}
