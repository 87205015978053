import {RkeeperMenu} from "migration/entities/rkeeperMenu";
import {RkeeperAgents} from "migration/entities/rkeeperAgents";
import {RkeeperRestaurant} from "migration/entities/rkeeperRestaurant";
import {RkeeperMenuSettings} from "migration/entities/rkeeperMenuSettings";
import {RkeeperState, RkeeperStoreAction, RkeeperStoreActions} from "./types";

const initialState: RkeeperState = {
    rkeeperMenuSettings: {} as RkeeperMenuSettings,
    rkeeperAgents: [] as RkeeperAgents[],
    rkeeperMenu: {} as RkeeperMenu,
    rkeeperRestaurant: {} as RkeeperRestaurant,

    isLoadingApproveRkeeperOrder: false,
    isLoadingDeleteMenuRkeeperSettings: false,
    isLoadingImportRkeeperProducts: false,
    isLoadingRetryRkeeperOrder: false,
    isLoadingSaveMenuRkeeperSettings: false,
    isLoadingSaveRkeeperProductBindings: false,
    isLoadingSaveRkeeperTableBindings: false,

    isLoadingGetRkeeperMenuSettings: false,
    isLoadingGetRkeeperAgents: false,
    isLoadingGetRkeeperMenu: false,
    isLoadingGetRkeeperRestaurant: false,
}

export default function rkeeperReducer(state = initialState, action: RkeeperStoreAction): RkeeperState {
    switch (action.type) {
        case RkeeperStoreActions.SET_RKEEPER_MENU_SETTINGS:
            return {...state, rkeeperMenuSettings: action.payload};
        case RkeeperStoreActions.SET_RKEEPER_AGENTS:
            return {...state, rkeeperAgents: action.payload};
        case RkeeperStoreActions.SET_RKEEPER_MENU:
            return {...state, rkeeperMenu: action.payload};
        case RkeeperStoreActions.SET_RKEEPER_RESTAURANT:
            return {...state, rkeeperRestaurant: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_APPROVE_RKEEPER_ORDER:
            return {...state, isLoadingApproveRkeeperOrder: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_DELETE_MENU_RKEEPER_SETTINGS:
            return {...state, isLoadingDeleteMenuRkeeperSettings: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_IMPORT_RKEEPER_PRODUCTS:
            return {...state, isLoadingImportRkeeperProducts: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_RETRY_RKEEPER_ORDER:
            return {...state, isLoadingRetryRkeeperOrder: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_SAVE_MENU_RKEEPER_SETTINGS:
            return {...state, isLoadingSaveMenuRkeeperSettings: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_PRODUCT_BINDINGS:
            return {...state, isLoadingSaveRkeeperProductBindings: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_SAVE_RKEEPER_TABLE_BINDINGS:
            return {...state, isLoadingSaveRkeeperTableBindings: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU_SETTINGS:
            return {...state, isLoadingGetRkeeperMenuSettings: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_AGENTS:
            return {...state, isLoadingGetRkeeperAgents: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_MENU:
            return {...state, isLoadingGetRkeeperMenu: action.payload};
        case RkeeperStoreActions.SET_IS_LOADING_GET_RKEEPER_RESTAURANT:
            return {...state, isLoadingGetRkeeperRestaurant: action.payload};
        default:
            return state;
    }
}
