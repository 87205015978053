export type WebhookSettings = {
    "apiLoginName": string,
    "authToken": string,
    "webHooksUri": string,
    "webHooksFilter": {
        "deliveryOrderFilter": {
            "orderStatuses": string[]
            "itemStatuses": string[],
            "errors": boolean,
        },
        "tableOrderFilter": {
            "orderStatuses": string[]
            "itemStatuses": string[],
            "errors": boolean,
        }
        "personalShiftFilter": {
            "updates": boolean
        },
        "reserveFilter": {
            "errors": boolean,
            "updates": boolean
        },
        "stopListUpdateFilter": {
            "updates": boolean
        },
    },
}

export enum DeliveryOrderStatusesEnum {
    Unconfirmed = "Unconfirmed",
    WaitCooking = "WaitCooking",
    ReadyForCooking = "ReadyForCooking",
    CookingStarted = "CookingStarted",
    CookingCompleted = "CookingCompleted",
    Waiting = "Waiting",
    OnWay = "OnWay",
    Delivered = "Delivered",
    Closed = "Closed",
    Cancelled = "Cancelled",
}

export enum DeliveryItemStatusesEnum {
    Added = "Added",
    PrintedNotCooking = "PrintedNotCooking",
    CookingStarted = "CookingStarted",
    CookingCompleted = "CookingCompleted",
    Served = "Served",
}

export enum TableOrderStatusesEnum {
    New = "New",
    Bill = "Bill",
    Closed = "Closed",
    Deleted = "Deleted",
}

export enum TableItemStatusesEnum {
    Added = "Added",
    PrintedNotCooking = "PrintedNotCooking",
    CookingStarted = "CookingStarted",
    CookingCompleted = "CookingCompleted",
    Served = "Served",
}
