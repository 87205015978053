export interface MenuStylize {
    fontFamily: string;
    borderRounding: BorderRounding;
    itemDisplayType: ItemDisplayType;
    itemImgPosition: ItemImgPosition;
    showSectionsOnMainPage: boolean;
    mainBg: RGBColor;
    mainFg: RGBColor;
    secondaryBg: RGBColor;
    secondaryFg: RGBColor;
    accentBg: RGBColor;
    accentFg: RGBColor;
    priceFg: RGBColor;
    salesPriceFg: RGBColor;
    outOfStockFg: RGBColor;
    helperBorderBg: RGBColor;
}

export type RGBColor = number[] | null;

export type BorderRounding = "none" | "small" | "big";

export type ItemDisplayType = "list" | "grid";

export type ItemImgPosition = "left" | "right";

export enum FontFamily {
    SF_PRO_DISPLAY = 'SF Pro Display',
    ROBOTO = 'Roboto',
    MONTSERRAT = 'Montserrat',
    ROBOTO_MONO = 'Roboto Mono',
    RALEWAY = 'Raleway',
    CIRCE_ROUNDED = 'Circe Rounded',
}
