import React, {useEffect} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {isEmpty} from "lodash";
import {MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "../shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Login} from "./public/Login";
import {ClientsPage} from "../../pages/staff/staff";
import {CreateClient} from "../../pages/staff/createClient";
import {EditClient} from "../../pages/staff/editClient";
import {ReportsPage} from "../../pages/staff/reports";
import {CreateStaff} from "../../pages/staff/createStaff";
import {ManagersPage} from "../../pages/staff/managers";
import {CopyMenuToAnotherOrg} from "../../pages/staff/copyMenuToAnotherOrg";
import {StaffChangePassword} from "../../pages/staff/changePassword";
import {Account} from "../../pages/account/account";
import {ChangePassword} from "../../pages/account/changePassword";
import {CreateNewMenu} from "../../pages/newMenu/createNewMenu";
import {OrgSettings} from "../../pages/orgSettings/orgSettings";
import {IikoOrgSettings} from "./user/menu/Iiko/components/iikoOrgSettings/iikoOrgSettings";
import {OneVisionSettings} from "../../pages/orgSettings/oneVisionSettings";
import {KaspiSettings} from "../../pages/orgSettings/kaspiSettings";
import {DomainSettings} from "../../pages/orgSettings/domainSettings";
import {SliderIndex} from "../../pages/slider/sliderIndex";
import {SliderCreate} from "../../pages/slider/sliderCreate";
import {SliderEdit} from "../../pages/slider/sliderEdit";
import {SliderSorting} from "../../pages/slider/SliderSorting";
import {Menu} from "../../pages/menu/menu";
import {MenuSorting} from "../../pages/menu/menuSorting";
import {MenuCopyIIKO} from "../../pages/menu/menuCopyIIKO";
import {SectionCreate} from "../../pages/menu/sectionCreate";
import {SectionEdit} from "../../pages/menu/sectionEdit";
import {CategoryCreate} from "../../pages/menu/categoryCreate";
import {CategoryEdit} from "../../pages/menu/categoryEdit";
import {ItemCreate} from "../../pages/item/itemCreate";
import {ItemEdit} from "../../pages/item/itemEdit";
import {Catalog} from "../../pages/catalog/catalog";
import {Orders} from "../../pages/order/orders";
import {OrderEdit} from "../../pages/order/orderEdit";
import {Customers} from "../../pages/order/customers";
import {Reports} from "../../pages/order/reports";
import {Analytics} from "../../pages/analytics/analytics";
import {MainPageEditor} from "../../pages/mainPageEditor/mainPageEditor";
import {CustomLinks} from "../../pages/mainPageEditor/customLinks";
import {CustomPages} from "../../pages/mainPageEditor/customPages";
import {MenuStylize} from "../../pages/mainPageEditor/menuStylize";
import {MenuBespoke} from "../../pages/mainPageEditor/bespoke";
import {SettingsPriceList} from "../../pages/settings/settingsPriceList";
import {SettingsClientOrders} from "../../pages/settings/settingsClientOrders";
import {SettingsNotification} from "../../pages/settings/settingsNotification";
import {SettingsScripts} from "../../pages/settings/settingsScripts";
import {SettingsPlacements} from "../../pages/settings/settingsPlacements";
import {IikoWebhooks} from "./user/menu/Iiko/components/iikoWebhooks";
import {BaseStaff} from "../../pages/base/baseStaff";
import {BaseUser} from "../../pages/base/baseUser";
import {IikoMenuSettings} from "./user/menu/Iiko/components/iikoMenuSettings";
import {IikoHealthCheck} from "./user/menu/Iiko/components/IikoHealthCheck";
import {Iiko} from "./user/menu/Iiko";
import {IikoMenuBindings} from "./user/menu/iikoMenuBindings";
import {Main} from "./user/feedback/main";
import {ModulesLayout} from "./layouts/feedbackLayout";
import {FeedbackEdit} from "./user/feedback/feedbackEdit";
import FeedbackPage from "./user/feedback/feedbackPage/FeedbackPage";
import {Notifications} from "./user/general/Notifications";
import {NotificationsTelegram} from "./user/general/Notifications/components/NotificationsTelegram";
import {NotificationsReceivers} from "./user/general/Notifications/components/NotificationsReceivers";
import {CustomPagesEdit} from "./user/menu/mainPageEditor/СustomPages/component/CustomPagesEdit";
import {CustomPagesAdd} from "./user/menu/mainPageEditor/СustomPages/component/CustomPagesAdd";
import {CrossSelling} from "./user/crossSelling";
import {Mentions} from "./user/menu/menu/pages/mentions";
import {LiqPaySettings} from "../../pages/orgSettings/liqPaySettings";
import {Dashboards} from "./staff/dashboards";
import {RKeeper} from "./user/menu/rkeeper";
import {RkeeperMenuSettings} from "./user/menu/rkeeper/components/RkeeperMenuSettings";
import {RkeeperMenuBindings} from "./user/menu/rkeeperMenuBindings";
import {MenuCopyRkeeper} from "./user/menu/menuCopyRkeeper";
import {JetpaySettings} from "../../pages/orgSettings/jetpaySettings";

export interface IRoute {
    path: RouteNames;
    name: MlString;
    icon?: JSX.Element;
    element: React.ComponentType;
    children?: IRoute[];
}

export enum RouteNames {
    // PUBLIC
    LOGIN = "/login",

    // STAFF
    STAFF_DASHBOARDS = "/staff/dashboards",
    STAFF_CLIENTS = "/staff/clients",
    STAFF_CLIENTS_CREATE = "/staff/clients/create",
    STAFF_CLIENTS_EDIT = "/staff/clients/edit/:id",
    STAFF_MANAGERS = "/staff/managers",
    STAFF_MANAGERS_CREATE = "/staff/managers/create",
    STAFF_STAFF_REPORTS = "/staff/reports",
    STAFF_ACCOUNT_CHANGE_PASSWORD = "/staff/account/change-password",
    STAFF_COPY_MENU_TO_ANOTHER_ORG = "/staff/copy-menu-to-another-org/:id",

    // USER | USER + STAFF
    ACCOUNT = "/account",
    ACCOUNT_CHANGE_PASSWORD_USER = "/account/change-password",
    MENU_CREATE = "/menu/create",

    ORGANIZATION_SETTINGS = "/organization-settings",
    ORGANIZATION_ONE_VISION = "/organization-one-vision",
    ORGANIZATION_LIQPAY = "/organization-liqpay",
    ORGANIZATION_KASPI = "/organization-kaspi",
    ORGANIZATION_JETPAY = "/organization-jetpay",
    ORGANIZATION_DOMAIN_SETTINGS = "/organization-domain",

    MENU_DASHBOARD = "/menu-dashboard",

    SLIDER = "/menu-dashboard/slider",
    SLIDER_CREATE = "/menu-dashboard/slider/create/",
    SLIDER_EDIT = "/menu-dashboard/slider/edit/:id",
    SLIDER_SORTING = "/menu-dashboard/slider/sorting",

    MENUS = "/menu-dashboard/menus",
    MENUS_SORTING = "/menu-dashboard/menus/sorting",
    MENUS_COPY_IIKO = "/menu-dashboard/menus/copy-iiko",
    MENUS_COPY_R_KEEPER = "/menu-dashboard/menus/copy-r-keeper",
    MENUS_IIKO_PRODUCT_BINDINGS = "/menu-dashboard/menus/iiko-product-bindings",
    MENUS_R_KEEPER_PRODUCT_BINDINGS = "/menu-dashboard/menus/r-keeper-product-bindings",
    MENUS_SECTION_CREATE = "/menu-dashboard/menus/section/create",
    MENUS_SECTION_EDIT = "/menu-dashboard/menus/section/edit/:id",
    MENUS_CATEGORY_CREATE = "/menu-dashboard/menus/category/create",
    MENUS_CATEGORY_EDIT = "/menu-dashboard/menus/category/edit/:id",
    MENUS_ITEM_CREATE = "/menu-dashboard/menus/item/create",
    MENUS_ITEM_EDIT = "/menu-dashboard/menus/item/edit/:id",
    MENUS_MENTIONS = "/menu-dashboard/menus/mentions",

    CATALOG = "/menu-dashboard/catalog",
    ORDERS = "/menu-dashboard/orders",
    ORDERS_EDIT = "/menu-dashboard/orders/edit/:id",
    CUSTOMERS = "/menu-dashboard/customers",
    REPORTS = "/menu-dashboard/reports",
    ANALYTICS = "/menu-dashboard/analytics",

    MAIN_PAGE_EDITOR = "/menu-dashboard/main-page-editor",
    MAIN_PAGE_EDITOR_CUSTOM_LINKS = "/menu-dashboard/main-page-editor/custom-links",
    MAIN_PAGE_EDITOR_CUSTOM_PAGES = "/menu-dashboard/main-page-editor/custom-pages",
    MAIN_PAGE_EDITOR_CUSTOM_PAGES_ADD = "/menu-dashboard/main-page-editor/custom-pages/add",
    MAIN_PAGE_EDITOR_CUSTOM_PAGES_EDIT = "/menu-dashboard/main-page-editor/custom-pages/edit/:id",
    MAIN_PAGE_EDITOR_MENU_STYLIZE = "/menu-dashboard/main-page-editor/menu-stylize",
    MAIN_PAGE_EDITOR_MENU_BESPOKE = "/menu-dashboard/main-page-editor/menu-bespoke",

    IIKO = "/menu-dashboard/iiko",
    IIKO_ORGANIZATION_SETTINGS = "/menu-dashboard/iiko/organization-settings",
    IIKO_MENU_SETTINGS = "/menu-dashboard/iiko/menu-settings",
    IIKO_HEALTHCHECK = "/menu-dashboard/iiko/healthcheck",
    IIKO_WEBHOOKS = "/menu-dashboard/iiko/webhooks",

    R_KEEPER = "/menu-dashboard/r-keeper",
    R_KEEPER_MENU_SETTINGS = "/menu-dashboard/r-keeper/menu-settings",

    SETTINGS = "/menu-dashboard/settings",
    SETTINGS_CLIENT_ORDERS = "/menu-dashboard/settings/clientorders",
    SETTINGS_NOTIFICATION = "/menu-dashboard/settings/notification",
    SETTINGS_SCRIPTS = "/menu-dashboard/settings/scripts",
    SETTINGS_PLACEMENTS = "/menu-dashboard/settings/placements",

    FEEDBACK = "/feedback",
    FEEDBACK_BY_ID = "/feedback/:id",
    FEEDBACK_BY_ID_EDIT = "/feedback/edit/:id",

    NOTIFICATIONS = "/notifications",
    NOTIFICATIONS_TELEGRAM = "/notifications/telegram",
    NOTIFICATIONS_RECEIVERS = "/notifications/receivers",

    CROSS_SELLING = "/cross-selling",
}

export const publicRoutes: IRoute[] = [
    {path: RouteNames.LOGIN, name: txt.login, element: Login},
];

export const staffRoutes: IRoute[] = [
    {path: RouteNames.STAFF_DASHBOARDS, name: txt.dashboards, element: Dashboards},
    {path: RouteNames.STAFF_CLIENTS, name: txt.clients, element: ClientsPage},
    {path: RouteNames.STAFF_CLIENTS_CREATE, name: txt.add_client, element: CreateClient},
    {path: RouteNames.STAFF_CLIENTS_EDIT, name: txt.edit_client, element: EditClient},
    {path: RouteNames.STAFF_MANAGERS, name: txt.managers, element: ManagersPage},
    {path: RouteNames.STAFF_MANAGERS_CREATE, name: txt.create_staff_user, element: CreateStaff},
    {path: RouteNames.STAFF_STAFF_REPORTS, name: txt.reports, element: ReportsPage},
    {path: RouteNames.STAFF_ACCOUNT_CHANGE_PASSWORD, name: txt.change_password, element: StaffChangePassword},
    {path: RouteNames.STAFF_COPY_MENU_TO_ANOTHER_ORG, name: txt.copy_menu_to_another_org, element: CopyMenuToAnotherOrg}
];

export const userRoutes: IRoute[] = [
    {path: RouteNames.ACCOUNT, name: txt.account, element: Account},
    {path: RouteNames.ACCOUNT_CHANGE_PASSWORD_USER, name: txt.change_password, element: ChangePassword},
    {path: RouteNames.MENU_CREATE, name: txt.add_menu, element: CreateNewMenu},

    {path: RouteNames.ORGANIZATION_SETTINGS, name: txt.organization_settings, element: OrgSettings},
    {path: RouteNames.ORGANIZATION_ONE_VISION, name: txt.one_vision_payment, element: OneVisionSettings},
    {path: RouteNames.ORGANIZATION_LIQPAY, name: txt.liqpay_payment, element: LiqPaySettings},
    {path: RouteNames.ORGANIZATION_KASPI, name: txt.kaspi_payment, element: KaspiSettings},
    {path: RouteNames.ORGANIZATION_JETPAY, name: txt.kaspi_payment, element: JetpaySettings},
    {path: RouteNames.ORGANIZATION_DOMAIN_SETTINGS, name: txt.domain, element: DomainSettings},

    {path: RouteNames.SLIDER, name: txt.slider, element: SliderIndex},
    {path: RouteNames.SLIDER_CREATE, name: txt.add_slide, element: SliderCreate},
    {path: RouteNames.SLIDER_EDIT, name: txt.editing_slide, element: SliderEdit},
    {path: RouteNames.SLIDER_SORTING, name: txt.sorting, element: SliderSorting},

    {path: RouteNames.MENUS, name: txt.menus, element: Menu},
    {path: RouteNames.MENUS_SORTING, name: txt.sorting, element: MenuSorting},
    {path: RouteNames.MENUS_COPY_IIKO, name: txt.copy_iiko, element: MenuCopyIIKO},
    {path: RouteNames.MENUS_COPY_R_KEEPER, name: txt.copy_r_keeper, element: MenuCopyRkeeper},
    {path: RouteNames.MENUS_IIKO_PRODUCT_BINDINGS, name: txt.iiko_product_bindings, element: IikoMenuBindings},
    {
        path: RouteNames.MENUS_R_KEEPER_PRODUCT_BINDINGS,
        name: txt.rkeeper_product_bindings,
        element: RkeeperMenuBindings
    },
    {path: RouteNames.MENUS_SECTION_CREATE, name: txt.add_section, element: SectionCreate},
    {path: RouteNames.MENUS_SECTION_EDIT, name: txt.edit_section, element: SectionEdit},
    {path: RouteNames.MENUS_CATEGORY_CREATE, name: txt.add_category, element: CategoryCreate},
    {path: RouteNames.MENUS_CATEGORY_EDIT, name: txt.edit_category, element: CategoryEdit},
    {path: RouteNames.MENUS_ITEM_CREATE, name: txt.add_item, element: ItemCreate},
    {path: RouteNames.MENUS_ITEM_EDIT, name: txt.edit_item, element: ItemEdit},
    {path: RouteNames.MENUS_MENTIONS, name: txt.mention_product, element: Mentions},

    {path: RouteNames.CATALOG, name: txt.catalog, element: Catalog},
    {path: RouteNames.ORDERS, name: txt.order_content, element: Orders},
    {path: RouteNames.ORDERS_EDIT, name: txt.order_editing, element: OrderEdit},
    {path: RouteNames.CUSTOMERS, name: txt.customers, element: Customers},
    {path: RouteNames.REPORTS, name: txt.reports, element: Reports},
    {path: RouteNames.ANALYTICS, name: txt.analytics, element: Analytics},

    {path: RouteNames.MAIN_PAGE_EDITOR, name: txt.main_page_editor, element: MainPageEditor},
    {path: RouteNames.MAIN_PAGE_EDITOR_CUSTOM_LINKS, name: txt.custom_links, element: CustomLinks},
    {path: RouteNames.MAIN_PAGE_EDITOR_CUSTOM_PAGES, name: txt.custom_pages, element: CustomPages},
    {path: RouteNames.MAIN_PAGE_EDITOR_CUSTOM_PAGES_ADD, name: txt.custom_page_add, element: CustomPagesAdd},
    {path: RouteNames.MAIN_PAGE_EDITOR_CUSTOM_PAGES_EDIT, name: txt.custom_page_edit, element: CustomPagesEdit},
    {path: RouteNames.MAIN_PAGE_EDITOR_MENU_STYLIZE, name: txt.menu_stylize, element: MenuStylize},
    {path: RouteNames.MAIN_PAGE_EDITOR_MENU_BESPOKE, name: txt.menu_stylize, element: MenuBespoke},

    {
        path: RouteNames.IIKO, name: txt.iiko, element: Iiko, children: [
            {path: RouteNames.IIKO_ORGANIZATION_SETTINGS, name: txt.organization_settings, element: IikoOrgSettings},
            {path: RouteNames.IIKO_MENU_SETTINGS, name: txt.menu_settings, element: IikoMenuSettings},
            {path: RouteNames.IIKO_HEALTHCHECK, name: txt.iiko_healthcheck, element: IikoHealthCheck},
            {path: RouteNames.IIKO_WEBHOOKS, name: txt.settings_webhooks, element: IikoWebhooks},
        ]
    },

    {
        path: RouteNames.R_KEEPER, name: txt.r_keeper, element: RKeeper, children: [
            {path: RouteNames.R_KEEPER_MENU_SETTINGS, name: txt.menu_settings, element: RkeeperMenuSettings},
        ]
    },

    {path: RouteNames.SETTINGS, name: txt.settings, element: SettingsPriceList},
    {path: RouteNames.SETTINGS_CLIENT_ORDERS, name: txt.client_orders, element: SettingsClientOrders},
    {path: RouteNames.SETTINGS_NOTIFICATION, name: txt.notification, element: SettingsNotification},
    {path: RouteNames.SETTINGS_SCRIPTS, name: txt.scripts, element: SettingsScripts},
    {path: RouteNames.SETTINGS_PLACEMENTS, name: txt.placements, element: SettingsPlacements},

    {
        path: RouteNames.NOTIFICATIONS, name: txt.notification_settings, element: Notifications, children: [
            {path: RouteNames.NOTIFICATIONS_TELEGRAM, name: txt.telegram, element: NotificationsTelegram},
            {path: RouteNames.NOTIFICATIONS_RECEIVERS, name: txt.list_of_receivers, element: NotificationsReceivers},
        ]
    },
];

export const feedbackModuleRoutes: IRoute[] = [
    {path: RouteNames.FEEDBACK, name: txt.feedbacks, element: Main},
    {path: RouteNames.FEEDBACK_BY_ID, name: txt.feedback_page, element: FeedbackPage},
    {path: RouteNames.FEEDBACK_BY_ID_EDIT, name: txt.feedback_page_edit, element: FeedbackEdit},
];

export const crossSellingModuleRoutes: IRoute[] = [
    {path: RouteNames.CROSS_SELLING, name: txt.cross_selling, element: CrossSelling}
]

const AppRouter = () => {
    const location = useLocation();
    const {initialLocation} = useTypedSelector(state => state.system);
    const {isAuth, currentUser} = useTypedSelector(state => state.user);
    const {setInitialLocation} = useActions();
    const locationPathname = location.pathname;

    window.addEventListener("beforeunload", function (e) {
        localStorage.setItem("lastLocation", locationPathname);
    });

    const from = () => {
        if (isAuth) {
            if (currentUser?.isStaff) {
                if (!isEmpty(currentUser?.org)) {
                    return initialLocation || RouteNames.SLIDER;
                }
                if (initialLocation === RouteNames.LOGIN) {
                    return RouteNames.STAFF_CLIENTS;
                }
                return initialLocation || RouteNames.STAFF_CLIENTS;
            } else {
                if (initialLocation === RouteNames.LOGIN) {
                    return RouteNames.SLIDER;
                }
                if (initialLocation === RouteNames.MENU_DASHBOARD || location.pathname === RouteNames.MENU_DASHBOARD) {
                    return RouteNames.SLIDER;
                }
                return initialLocation || RouteNames.SLIDER;
            }
        }
        return RouteNames.LOGIN;
    }

    useEffect(() => {
        setInitialLocation(localStorage.getItem("lastLocation") || RouteNames.LOGIN);
    }, []);

    // useEffect(() => {
    //     if (currentUser?.org) {
    //         if (window.location.pathname.includes("staff/") || window.location.pathname.includes("login")) {
    //             navigate(RouteNames.SLIDER);
    //         }
    //         navigate(from());
    //     } else if (currentUser?.user) {
    //         let staffInfoL = localStorage.getItem(currentUser.user.id)
    //         if (staffInfoL) {
    //             let res: IStaff = JSON.parse(staffInfoL)
    //             if (res.inOrgPage) {
    //                 return
    //             }
    //         }
    //         if (!window.location.pathname.includes("staff/") || window.location.pathname.includes("login")) {
    //             navigate('/staff/clients')
    //         }
    //     }
    //     if (!currentUser && !window.location.pathname.includes("/login")) {
    //         navigate('/login')
    //     }
    //     // eslint-disable-next-line
    // }, [currentUser, location])

    return (
        <Routes>
            {isAuth
                ?
                currentUser?.isStaff
                    ?
                    <Route>
                        <Route element={<BaseStaff/>}>
                            {staffRoutes.map(route => (
                                <Route path={route.path} element={<route.element/>} key={route.path}/>
                            ))}
                            <Route path={"*"} element={<Navigate to={from()}/>}/>
                        </Route>
                        <Route element={<BaseUser/>}>
                            {userRoutes.map(route => {
                                if (route.children) {
                                    return (
                                        <Route path={route.path} element={<route.element/>} key={route.path}>
                                            {route.children.map((child, index) => (
                                                <Route
                                                    index={index === 0}
                                                    path={child.path}
                                                    element={<child.element/>}
                                                    key={child.path}
                                                />
                                            ))}
                                        </Route>
                                    )
                                }
                                return <Route path={route.path} element={<route.element/>} key={route.path}/>
                            })}
                            <Route path={"*"} element={<Navigate to={from()}/>}/>
                        </Route>
                        <Route element={<ModulesLayout/>}>
                            {[...feedbackModuleRoutes, ...crossSellingModuleRoutes].map(route => (
                                <Route path={route.path} element={<route.element/>} key={route.path}/>
                            ))}
                            <Route path={"*"} element={<Navigate to={from()}/>}/>
                        </Route>
                    </Route>
                    :
                    <Route>
                        <Route element={<BaseUser/>}>
                            {userRoutes.map(route => {
                                if (route.children) {
                                    return (
                                        <Route path={route.path} element={<route.element/>} key={route.path}>
                                            {route.children.map((child, index) => (
                                                <Route
                                                    index={index === 0}
                                                    path={child.path}
                                                    element={<child.element/>}
                                                    key={child.path}
                                                />
                                            ))}
                                        </Route>
                                    )
                                }
                                return <Route path={route.path} element={<route.element/>} key={route.path}/>
                            })}
                        </Route>
                        <Route element={<ModulesLayout/>}>
                            {[...feedbackModuleRoutes, ...crossSellingModuleRoutes].map(route => (
                                <Route path={route.path} element={<route.element/>} key={route.path}/>
                            ))}
                        </Route>
                        <Route path={"*"} element={<Navigate to={from()}/>}/>
                    </Route>
                :
                <Route>
                    {publicRoutes.map(route => <Route path={route.path} element={<route.element/>} key={route.path}/>)}
                    <Route path={"*"} element={<Navigate to={RouteNames.LOGIN}/>}/>
                </Route>
            }
        </Routes>
    );
};

export default AppRouter;
