import {AxiosResponse} from "axios";
import {OrgId} from "migration/entities/baseAliases";
import {$apiV2} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface TenantTagByIdRes {
    "id": string,
    "tags": string[],
}

export interface ProductTagByIdRes {
    "id": string,
    "tags": string[],
}

export interface TenantTagByIdReq {
    "tenantId": string,
    "tags": string[],
}

export interface ProductTagByIdReq {
    "productId": string,
    "tags": string[],
}

export default class TaggingService {
    static async getTenantTagById(orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<TenantTagByIdRes> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<TenantTagByIdRes> | ErrorResponse>(ApiRoutes.TENANT_TAG_BY_ID, {id: orgId}, {signal: controller.signal});
    }

    static async getProductTagById(orgId: OrgId, productId: string, controller: AbortController): Promise<AxiosResponse<SuccessResponse<ProductTagByIdRes> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<ProductTagByIdRes> | ErrorResponse>(ApiRoutes.PRODUCT_TAG_BY_ID, {id: productId}, {signal: controller.signal});
    }

    static async cmdSetTagsForTenant(orgId: OrgId, request: TenantTagByIdReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SET_TAGS_FOR_TENANT, request);
    }

    static async cmdSetTagsForProduct(orgId: OrgId, request: ProductTagByIdReq): Promise<AxiosResponse<SuccessResponse<null> | ErrorResponse>> {
        return await $apiV2(orgId).post<SuccessResponse<null> | ErrorResponse>(ApiRoutes.SET_TAGS_FOR_PRODUCT, request);
    }
}
