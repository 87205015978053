import {Link} from "react-router-dom";
import {useCategoryForm} from "../../hooks/menu/category";
import {SubmitButton} from "../input/submitButton";
import {TextI18n} from "../input/textI18n";
import {TextAreaI18n} from "../input/textAreaI18n";
import {CustomSelect} from "../input/customSelect";
import {useGlobalContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../loader/formWithLoader";
import {RouteNames} from "../../migration/pages";
import {CocaColaSettings} from "../../migration/pages/user/menu/cola/components/CocaColaSettings";

export function CategoryForm({edit}: { edit: boolean }) {
    const info = useCategoryForm(edit)
    const {i18n} = useGlobalContext()
    return (
        <FormWithLoader isloading={info.loading} className="form">
            <TextI18n values={info.category.name} setValues={info.onChangeName} lang={info.menu.i18n.defaultLang}
                      allLang={info.menu.i18n.otherLangs || []} label={i18n.category_name()}
                      isRequired={true} isStaff={info.isStaff}/>

            <TextAreaI18n values={info.category.description} setValues={info.onChangeDescription}
                          lang={info.menu.i18n.defaultLang} allLangs={info.menu.i18n.otherLangs || []}
                          label={i18n.short_desc()} isStaff={info.isStaff}
            />

            <CustomSelect options={info.category.sections.map((s) => (s.name[info.menu.i18n.defaultLang]))}
                          value={info.selectedSection}
                          onChange={info.onChangeSection} label={i18n.section()}/>

            <div className="form__buttons">
                <div className="form__buttons-left">
                    <SubmitButton
                        onSubmit={info.onSave}
                        label={i18n.save()}
                        disabled={!info.currentMenuId}
                        isLoading={info.saveCategoryLoading}
                    />
                    <Link to={RouteNames.MENUS} className="button -bordered_blue form__button">
                        {i18n.cancel()}
                    </Link>
                </div>
            </div>

            <CocaColaSettings type={"category"} categoryId={info.category?.id}/>

        </FormWithLoader>
    )
}
