import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Divider, Pagination, Rate, Select, Skeleton} from "antd";
import {StarFilled, StarOutlined} from "@ant-design/icons";
import {RouteNames} from "migration/pages";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {formatDate} from "migration/shared/lib/date/formatDate";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import FeedbackPageBarChart from "./components/FeedbackPageBarChart/FeedbackPageBarChart";
import classes from "./FeedbackPage.module.scss";

const FeedbackPage: FunctionComponent = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentUser} = useTypedSelector(state => state.user);
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {
        feedbackDashboardData,
        feedbacks,
        feedbacksCount,
        isLoadingGetFeedbackDashboardData,
        isLoadingGetFeedbacks
    } = useTypedSelector(state => state.feedbackDashboardData);
    const {
        getFeedbackPageById,
        getFeedbackDashboardData,
        getFeedbacks
    } = useActions();
    const [filters, setFilters] = useState({offset: 1, limit: 10, filterType: "all"});

    const onFiltersChange = async (value: string) => {
        setFilters({...filters, filterType: value, offset: 1});
        const controller = new AbortController();
        await getFeedbacks({
            feedbackPageId: feedbackPage?.id,
            offset: 0,
            limit: filters.limit,
            filterType: value as any
        }, {navigate: navigate}, controller);
        return () => controller.abort();
    }

    useEffect(() => {
        if (!id) return;
        const controller = new AbortController();
        getFeedbackPageById(id, {navigate: navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, currentUser?.org?.id]);

    useEffect(() => {
        if (!feedbackPage?.id) return;
        const controller = new AbortController();
        getFeedbackDashboardData(feedbackPage?.id, {navigate: navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackPage?.id]);

    useEffect(() => {
        if (!feedbackPage?.id) return;
        const controller = new AbortController();
        getFeedbacks({
            feedbackPageId: feedbackPage?.id,
            offset: (filters.offset - 1) * filters.limit,
            limit: filters.limit,
            filterType: filters.filterType as any
        }, {navigate: navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.limit, filters.offset, feedbackPage?.id]);

    return (
        <div className={classes.main}>
            <Breadcrumbs
                infos={[
                    {title: txt.feedbaker[currentLang], link: RouteNames.FEEDBACK, index: "bracer-0"},
                    {
                        title: txt.feedback_page[currentLang],
                        link: RouteNames.FEEDBACK_BY_ID.replace(":id", id || ""),
                        index: "bracer-1"
                    },
                ]}
            />
            <div className="app__header">
                <div className="app__header-left">
                    <h1 className="app__header-title">{txt.feedback_page[currentLang]}</h1>
                </div>
            </div>

            <div className={classes.container}>
                <div className={classes.container__inner}>
                    {isLoadingGetFeedbackDashboardData
                        ? <Skeleton className={classes.rating__profile} active/>
                        : <FeedbackPageRatingProfile
                            name={feedbackPage?.name}
                            avgGrade={feedbackDashboardData?.avgGrade}
                        />
                    }

                    <div className={classes.stats}>
                        <Divider className={classes.divider__title}>{txt.for_all_time[currentLang]}</Divider>
                        <FeedbackPageStats
                            loading={isLoadingGetFeedbackDashboardData}
                            items={[
                                {
                                    name: txt.page_visits_count[currentLang],
                                    count: feedbackDashboardData?.allTime?.pageVisitsCount,
                                },
                                {
                                    name: txt.feedbacks_count[currentLang],
                                    count: feedbackDashboardData?.allTime?.submitsCount,
                                },
                                {
                                    name: txt.link_visits_count[currentLang],
                                    count: feedbackDashboardData?.allTime?.linkVisitsCount,
                                }
                            ]}
                        />
                        <div className={classes.stats__block}>
                            <h3 className={classes.stats__block__title}>{txt.rating[currentLang]}</h3>
                            <FeedbackPageBarChart
                                grades={feedbackDashboardData?.allTime?.grades}
                                loading={isLoadingGetFeedbackDashboardData}
                            />
                        </div>
                    </div>

                    <div className={classes.stats}>
                        <Divider className={classes.divider__title}>{txt.for_today[currentLang]}</Divider>
                        <FeedbackPageStats
                            loading={isLoadingGetFeedbackDashboardData}
                            items={[
                                {
                                    name: txt.page_visits_count[currentLang],
                                    count: feedbackDashboardData?.today?.pageVisitsCount,
                                },
                                {
                                    name: txt.feedbacks_count[currentLang],
                                    count: feedbackDashboardData?.today?.submitsCount,
                                },
                                {
                                    name: txt.link_visits_count[currentLang],
                                    count: feedbackDashboardData?.today?.linkVisitsCount,
                                }
                            ]}
                        />
                        <div className={classes.stats__block}>
                            <h3 className={classes.stats__block__title}>{txt.rating[currentLang]}</h3>
                            <FeedbackPageBarChart
                                grades={feedbackDashboardData?.today?.grades}
                                loading={isLoadingGetFeedbackDashboardData}
                            />
                        </div>
                    </div>

                    <div className={classes.stats}>
                        <Divider className={classes.divider__title}>{txt.top_reasons[currentLang]}</Divider>

                        <div className={classes.stats__reasons}>
                            {isLoadingGetFeedbackDashboardData
                                ?
                                Array(5).fill(0).map((_, index) => (
                                    <Skeleton className={classes.stats__reasons__item} key={index} active/>
                                ))
                                :
                                !feedbackDashboardData?.topReasons?.length
                                    ?
                                    <div className={classes.stats__reasons__empty}>
                                        {txt.empty[currentLang]}
                                    </div>
                                    :
                                    feedbackDashboardData?.topReasons?.sort((a, b) => b.count - a.count)?.map((item) => (
                                        <div className={classes.stats__reasons__item}>
                                            <div className={classes.stats__reasons__item__name}>
                                                <div className={classes.stats__reasons__item__name__lang}>
                                                    <div className={classes.stats__reasons__item__name__lang__value}>
                                                        {item?.name?.[feedbackPage.defaultLang] || "-"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.stats__reasons__item__count}>
                                                {`${txt.amount[currentLang]}: ${item?.count || 0}`}
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>

                    <div className={classes.feedbacks}>
                        <Divider className={classes.divider__title}>{txt.feedbacks[currentLang]}</Divider>

                        <div className={classes.feedbacks__filter}>
                            <div className={classes.feedbacks__filter__label}>
                                {`${txt.feedback_type[currentLang]}:`}
                            </div>
                            <Select
                                value={filters.filterType}
                                onChange={(value: string) => onFiltersChange(value)}
                                options={[
                                    {value: "all", label: txt.all[currentLang]},
                                    {value: "positive-only", label: txt.positive[currentLang]},
                                    {value: "negative-only", label: txt.negative[currentLang]},
                                ]}
                                style={{width: "200px"}}
                            />
                        </div>

                        <div className={classes.feedbacks__list}>
                            {isLoadingGetFeedbacks
                                ?
                                Array(3).fill(0).map((_, index) => (
                                    <Skeleton className={classes.feedback__list__item} key={index} active/>
                                ))
                                :
                                !feedbacks?.length
                                    ?
                                    <div className={classes.feedbacks__list__empty}>
                                        {txt.empty[currentLang]}
                                    </div>
                                    :
                                    feedbacks?.map(item => (
                                        <div
                                            key={item?.id}
                                            className={classes.feedback__list__item}
                                            style={{
                                                borderColor: item?.chosenGrade < 4 ? "rgb(255, 107, 107)" : "rgb(0, 204, 153)"
                                            }}
                                        >
                                            <div className={classes.feedback__list__item__left}>
                                                <div className={classes.feedback__list__item__left__name}>
                                                    {item?.customerName}
                                                </div>
                                                {item?.askedForCallback && (
                                                    <div className={classes.feedback__list__item__left__phone}>
                                                        {`${item?.customerPhone?.countryCode} ${item?.customerPhone?.number}`}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.feedback__list__item__right}>
                                                <div className={classes.feedback__list__item__right__header}>
                                                    <div className={classes.feedback__list__item__right__rating__bar}>
                                                        {Array(item?.chosenGrade || 0).fill(0).map((_, index) => (
                                                            <StarFilled
                                                                key={index}
                                                                className={classes.feedback__list__item__right__rating__bar__item}
                                                            />
                                                        ))}
                                                        {Array(5 - item?.chosenGrade).fill(0).map((_, index) => (
                                                            <StarOutlined
                                                                key={index}
                                                                className={classes.feedback__list__item__right__rating__bar__item}
                                                            />
                                                        ))}
                                                    </div>

                                                    <div className={classes.feedback__list__item__left__submitted__at}>
                                                        {formatDate(item?.submittedAt || "")}
                                                    </div>
                                                </div>
                                                {!!item?.selectedReasons?.length && (
                                                    <div className={classes.feedback__list__item__right__reasons}>
                                                        {item?.selectedReasons?.map(reason => (
                                                            <div
                                                                className={classes.feedback__list__item__right__reasons__item}>
                                                                {reason?.name?.[feedbackPage.defaultLang]}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {!!item?.comment && (
                                                    <div className={classes.feedback__list__item__right__comment}>
                                                        {item?.comment}
                                                    </div>
                                                )}

                                                {!!item?.TYPageLinkActivated?.length && (
                                                    <div className={classes.feedback__list__item__right__clicked__links}>
                                                        <div className={classes.feedback__list__item__right__clicked__links__title}>
                                                            {txt.opened_links_by_user[currentLang]}
                                                        </div>
                                                        {item?.TYPageLinkActivated.map(link => (
                                                            <div
                                                                key={link?.linkId}
                                                                onClick={() => window.open(link?.linkURL, "_blank")}
                                                                className={classes.feedback__list__item__right__clicked__links__item__url}
                                                            >
                                                                {link?.linkURL}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>

                        <div className={classes.feedbacks__list__pagination}>
                            <Pagination
                                current={filters?.offset}
                                onChange={(page) => setFilters(prevState => ({...prevState, offset: page}))}
                                total={feedbacksCount || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackPage;

const FeedbackPageRatingProfile: FunctionComponent<{ name: string, avgGrade: number }> = ({name, avgGrade}) => {
    return (
        <div className={classes.rating__profile}>
            <div className={classes.rating__profile__left}>{avgGrade?.toFixed(1) || 0.0}</div>
            <div className={classes.rating__profile__right}>
                <div className={classes.rating__profile__right__name}>{name || ""}</div>
                <div className={classes.rating__profile__right__rate}>
                    <Rate allowHalf disabled defaultValue={avgGrade || 0}/>
                </div>
            </div>
        </div>
    )
}

const StatsItem: FunctionComponent<{ title: string, value: number }> = ({title, value}) => {
    return (
        <div className={classes.stats__numbers__item}>
            <div className={classes.stats__numbers__item__value}>{value || 0}</div>
            <div className={classes.stats__numbers__item__title}>{title}</div>
        </div>
    )
}

interface FeedbackPageStatsProps {
    items: { name: string, count: number }[];
    loading: boolean;
}

const FeedbackPageStats: FunctionComponent<FeedbackPageStatsProps> = ({items, loading}) => {
    return (
        <div className={classes.stats__numbers}>
            {loading
                ?
                Array(3).fill(0).map((_, index) => (
                    <Skeleton className={classes.stats__numbers__item} key={index} active/>
                ))
                :
                items?.map((item, index) => {
                    if (items.length - 1 === index) {
                        return <StatsItem title={item.name} value={item.count} key={index}/>
                    }
                    return (
                        <React.Fragment key={index}>
                            <StatsItem title={item.name} value={item.count}/>
                            <div className={classes.stats__numbers__item__divider}></div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}
