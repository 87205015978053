import React from "react";
import {LittleSpinner} from "../loader/littleSpinner";

interface SubmitButtonProps {
    onSubmit: () => void,
    login?: boolean,
    label: string
    modal?: boolean
    disabled: boolean
    isLoading: boolean
    isDelete?: boolean
    style?: any
}

export function SubmitButton(props: SubmitButtonProps) {
    let className = props.login ?
        "button login__button action"
        : props.modal ?
            "button modal__button" :
            "button form__button"

    if (props.isDelete) {
        className += " -bordered_red"
    } else {
        className += " -blue"
    }
    return (
        <button type="submit"
                className={className}
                disabled={props.isLoading || props.disabled}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.isLoading) return;
                    props.onSubmit()
                }}
                style={{
                    ...props.style,
                    opacity: props.disabled ? 0.5 : 1, pointerEvents: props.disabled ? "none" : "auto"
                }}
        >
            {props.isLoading
                ?
                <LittleSpinner/>
                :
                props.label}
        </button>
    );
}
