export enum ApiRoutes {
    // ======================================= COMMAND-AUTH ============================================
    LOGIN = "/cmd/login",
    LOGOUT = "/cmd/logout",

    // ======================================== QUERY-USER =============================================
    CURRENT_USER = "/q/current-user-info",

    // ======================================== QUERY-IIKO =============================================
    IIKO_API_KEY = "/q/iiko-api-key",
    IIKO_NOMENCLATURE = "/q/iiko-nomenclature",
    IIKO_ORGANIZATIONS = "/q/iiko-organizations",
    IIKO_PAYMENT_TYPES = "/q/iiko-payment-types",
    IIKO_RESTAURANT_SECTIONS = "/q/iiko-restaurant-sections",
    IIKO_STOP_LISTS = "/q/iiko-stop-lists",
    IIKO_TERMINAL_GROUPS = "/q/iiko-terminal-groups",
    IIKO_WEBHOOKS = "/q/iiko-webhook-config",
    MENU_IIKO_SETTINGS = "/q/menu-iiko-settings",
    ORGANIZATION_IIKO_SETTINGS = "/q/organization-iiko-settings",
    IIKO_EXTERNAL_MENU = "/q/iiko-external-menu",
    IIKO_EXTERNAL_MENU_INFO = "/q/iiko-external-menu-info",

    // ======================================= COMMAND-IIKO ============================================
    CONFIGURE_IIKO_WEBHOOK = "/cmd/configure-iiko-webhook",
    DELETE_MENU_IIKO_SETTINGS = "/cmd/delete-menu-iiko-settings",
    DELETE_ORGANIZATION_IIKO_SETTINGS = "/cmd/delete-organization-iiko-settings",
    IMPORT_IIKO_PRODUCTS = "/cmd/import-iiko-products",
    SAVE_IIKO_PRODUCTS_BINDINGS = "/cmd/save-iiko-product-bindings",
    SAVE_IIKO_TABLE_BINDINGS = "/cmd/save-iiko-table-bindings",
    SAVE_MENU_IIKO_SETTINGS = "/cmd/save-menu-iiko-settings",
    SAVE_ORGANIZATION_IIKO_SETTINGS = "/cmd/save-organization-iiko-settings",
    APPROVE_IIKO_ORDER = "/cmd/approve-iiko-order",
    RETRY_IIKO_ORDER = "/cmd/retry-iiko-order",
    CANCEL_IIKO_DELIVERY_ORDER = "/cmd/cancel-iiko-delivery-order",

    // ======================================== QUERY-MENU =============================================
    MENU_STRUCT_FOR_MENU_PAGE = "/q/menu-struct-for-menu-page",
    PRODUCT_MENTIONS_OF_MENU = "/q/product-mentions-of-menu",

    // ======================================= COMMAND-MENU ============================================
    FILL_OUT_MENU_FROM_EXCEL = "/cmd/fill-out-menu-from-excel",
    ADD_PRODUCT_MENTION_TO_PRODUCT = "/cmd/add-product-mention-to-product",
    ADD_PRODUCT_MENTION_TO_TOP_OF_CATEGORY = "/cmd/add-product-mention-to-top-of-category",
    REMOVE_PRODUCT_MENTION = "/cmd/remove-product-mention",


    // ===================================== QUERY-NOTIFICATIONS =======================================
    ORDER_NOTIFICATION_SETTINGS = "/q/order-notification-settings",

    // ==================================== COMMAND-NOTIFICATIONS ======================================
    MENU_SETTINGS_TOGGLE_EMAIL_NOTIFICATION = "/cmd/menu-settings-toggle-email-notification",
    SAVE_EMAIL_RECEIVERS_MENU_SETTINGS_ORDER_NOTIFICATION = "/cmd/save-email-receivers-menu-settings-order-notification",

    // ======================================== QUERY-FEEDBACK =========================================
    ALL_FEEDBACK_PAGES = "/q/all-feedback-pages",

    // ======================================= COMMAND-FEEDBACK ========================================
    CREATE_FEEDBACK_PAGE = "/cmd/create-feedback-page",
    DELETE_FEEDBACK_PAGE = "/cmd/delete-feedback-page",

    // ====================================== QUERY-FEEDBACK-EDIT ======================================
    FEEDBACK_PAGE_BY_ID = "/q/feedback-page-by-id",
    FEEDBACK_DASHBOARD_DATA = "/q/feedback-page-dashboard-by-id",
    FEEDBACKS_BY_FILTERS = "/q/feedbacks-by-filters",

    // ===================================== COMMAND-FEEDBACK-EDIT =====================================
    CHANGE_FEEDBACK_PAGE_SLUG = "/cmd/change-feedback-page-slug",
    RENAME_FEEDBACK_PAGE = "/cmd/rename-feedback-page",
    SET_DEFAULT_BACK_LICK_FEEDBACK_PAGE = "/cmd/set-default-back-link-feedback-page",
    CHOOSE_LANGS_FOR_FEEDBACK_PAGE = "/cmd/choose-langs-for-feedback-page",
    SAVE_TEXTS_FOR_FEEDBACK_PAGE = "/cmd/save-texts-for-feedback-page",

    ADD_POSITIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/add-positive-grade-reason-feedback-page",
    EDIT_POSITIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/edit-positive-grade-reason-feedback-page",
    REMOVE_POSITIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/remove-positive-grade-reason-feedback-page",
    SAVE_POSITIVE_GRADE_TY_TEXTS_FEEDBACK_PAGE = "/cmd/save-positive-grade-ty-texts-feedback-page",
    ADD_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/add-positive-grade-ty-link-feedback-page",
    EDIT_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/edit-positive-grade-ty-link-feedback-page",
    REMOVE_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/remove-positive-grade-ty-link-feedback-page",
    ATTACH_IMAGE_TO_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/attach-image-to-feedback-page-positive-grade",
    DETACH_IMAGE_FROM_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/detach-image-from-feedback-page-positive-grade-ty-link",

    ADD_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/add-negative-grade-reason-feedback-page",
    EDIT_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/edit-negative-grade-reason-feedback-page",
    REMOVE_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/remove-negative-grade-reason-feedback-page",
    SAVE_NEGATIVE_GRADE_TY_TEXTS_FEEDBACK_PAGE = "/cmd/save-negative-grade-ty-texts-feedback-page",
    ADD_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/add-negative-grade-ty-link-feedback-page",
    EDIT_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/edit-negative-grade-ty-link-feedback-page",
    REMOVE_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/remove-negative-grade-ty-link-feedback-page",
    ATTACH_IMAGE_TO_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/attach-image-to-feedback-page-negative-grade",
    DETACH_IMAGE_FROM_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/detach-image-from-feedback-page-negative-grade-ty-link",

    ADD_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/add-specific-grade-reason-feedback-page",
    EDIT_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/edit-specific-grade-reason-feedback-page",
    REMOVE_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE = "/cmd/remove-specific-grade-reason-feedback-page",
    SAVE_SPECIFIC_GRADE_GRADE_TY_TEXTS_FEEDBACK_PAGE = "/cmd/save-specific-grade-ty-texts-feedback-page",
    ADD_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/add-specific-grade-ty-link-feedback-page",
    EDIT_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/edit-specific-grade-ty-link-feedback-page",
    REMOVE_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/remove-specific-grade-ty-link-feedback-page",
    ATTACH_IMAGE_TO_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/attach-image-to-feedback-page-specific-grade-ty-link",
    DETACH_IMAGE_FROM_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE = "/cmd/detach-image-from-feedback-page-specific-grade-ty-link",

    // ========================================= UPLOAD-FEEDBACK ========================================
    UPLOAD_FEEDBACK_PAGE_TY_LINK_IMG = "/upload/feedback-page-ty-link-img",

    // ==================================== QUERY-NOTIFICATION-FEEDBACK =================================
    TELEGRAM_POOL_OF_TENANT = "/q/telegram-pool-of-tenant",
    ALL_RECEIVER_LISTS = "/q/all-receiver-lists",

    // =================================== COMMAND-NOTIFICATION-FEEDBACK ================================
    BLOCK_TG_USER_IN_TELEGRAM_POOL = "/cmd/block-tg-user-in-telegram-pool",
    UNBLOCK_TG_USER_IN_TELEGRAM_POOL = "/cmd/unblock-user-in-telegram-pool",
    BLOCK_TG_GROUP_IN_TELEGRAM_POOL = "/cmd/block-tg-group-in-telegram-pool",
    UNBLOCK_TG_GROUP_IN_TELEGRAM_POOL = "/cmd/unblock-tg-group-in-telegram-pool",
    CREATE_RECEIVER_LIST = "/cmd/create-receiver-list",
    SPECIFY_TG_USERS_IN_RECEIVER_LIST = "/cmd/specify-tg-users-in-receiver-list",
    SPECIFY_TG_GROUPS_IN_RECEIVER_LIST = "/cmd/specify-tg-groups-in-receiver-list",
    SPECIFY_EMAILS_IN_RECEIVER_LIST = "/cmd/specify-emails-in-receiver-list",
    RENAME_RECEIVER_LIST = "/cmd/rename-receiver-list",
    DELETE_RECEIVER_LIST = "/cmd/delete-receiver-list",
    BIND_RECEIVER_LIST_TO_FEEDBACK_PAGE = "/cmd/bind-receiver-list-to-feedback-page",
    UNBIND_RECEIVER_LIST_FROM_FEEDBACK_PAGE = "/cmd/unbind-receiver-list-from-feedback-page",

    // ========================================== QUERY-COCA-COLA =======================================
    COCA_COLA_CLIENT_BY_ID = "/q/coca-cola-client-by-id",
    COCA_COLA_MENU_CATEGORY_BY_ID = "/q/coca-cola-menu-category-by-id",
    COCA_COLA_PRODUCT_BY_ID = "/q/coca-cola-product-by-id",

    // ========================================= COMMAND-COCA-COLA ======================================
    MARK_AS_COCA_COLA_CLIENT = "/cmd/mark-as-coca-cola-client",
    MARK_AS_COCA_COLA_MENU_CATEGORY = "/cmd/mark-as-coca-cola-menu-category",
    MARK_AS_COCA_COLA_PRODUCT = "/cmd/mark-as-coca-cola-product",
    UNMARK_AS_COCA_COLA_CLIENT = "/cmd/unmark-as-coca-cola-client",
    UNMARK_AS_COCA_COLA_MENU_CATEGORY = "/cmd/unmark-as-coca-cola-menu-category",
    UNMARK_AS_COCA_COLA_PRODUCT = "/cmd/unmark-as-coca-cola-product",

    // =========================================== QUERY-TAGGING ========================================
    TENANT_TAG_BY_ID = "/q/tenant-tag-by-id",
    PRODUCT_TAG_BY_ID = "/q/product-tag-by-id",

    // ========================================== COMMAND-TAGGING =======================================
    SET_TAGS_FOR_PRODUCT = "/cmd/set-tags-for-product",
    SET_TAGS_FOR_TENANT = "/cmd/set-tags-for-tenant",

    // ============================================ QUERY-TENANT ========================================
    TENANT_BY_ID = "/q/tenant-by-id",

    // ======================================== COMMAND-CROSS-SELLING ===================================
    ASSOCIATE_CROSS_SELLING_TO_MENU_CATEGORY = "/cmd/associate-cross-selling-to-menu-category",
    ASSOCIATE_CROSS_SELLING_TO_PRODUCT = "/cmd/associate-cross-selling-to-product",
    DISSOCIATE_CROSS_SELLING_FROM_MENU_CATEGORY = "/cmd/dissociate-cross-selling-from-menu-category",
    DISSOCIATE_CROSS_SELLING_FROM_PRODUCT = "/cmd/dissociate-cross-selling-from-product",
    REMOVE_COMMON_CROSS_SELLING_PRODUCTS = "/cmd/remove-common-cross-selling-products",
    SET_COMMON_CROSS_SELLING_PRODUCTS = "/cmd/set-common-cross-selling-products",
    SET_DEFAULT_CROSS_SELLING_LABEL = "/cmd/set-default-cross-selling-label",

    // ===================================== QUERY-ORGANIZATION-SETTINGS ================================
    LIQPAY_SETTINGS = "/q/liqpay-settings",

    // ==================================== COMMAND-ORGANIZATION-SETTINGS ===============================
    SAVE_LIQPAY_PAYMENT_SETTINGS = "/cmd/save-liqpay-payment-settings",
    ENCODE_LIQPAY_PRIVATE_KEY = "/encode-liqpay-private-key",

    // ========================================== QUERY-DASHBOARDS ======================================
    REPORT_TODAY_DASHBOARD = "/report-today-dashboard",
    REPORT_DASHBOARD_DURING_PERIOD = "/report-dashboard-during-period",
    REPORT_DASHBOARD_DYNAMICS = "/report-dashboard-dynamics",
    REPORT_MOST_VISITED_MENUS = "/report-most-visited-menus",

    // =========================================== QUERY-RKEEPER ========================================
    MENU_RKEEPER_SETTIGNS = "/q/menu-rkeeper-settings",
    RKEEPER_AGENTS = "/q/rkeeper-agents",
    RKEEPER_MENU = "/q/rkeeper-menu",
    RKEEPER_RESTAURANT = "/q/rkeeper-restaurant",

    // ========================================== COMMAND-RKEEPER =======================================
    APPROVE_RKEEPER_ORDER = "/cmd/approve-rkeeper-order",
    DELETE_MENU_RKEEPER_SETTINGS = "/cmd/delete-menu-rkeeper-settings",
    IMPORT_RKEEPER_PRODUCTS = "/cmd/import-rkeeper-products",
    RETRY_RKEEPER_ORDER = "/cmd/retry-rkeeper-order",
    SAVE_MENU_RKEEPER_SETTINGS = "/cmd/save-menu-rkeeper-settings",
    SAVE_RKEEPER_PRODUCT_BINDINGS = "/cmd/save-rkeeper-product-bindings",
    SAVE_RKEEPER_TABLE_BINDINGS = "/cmd/save-rkeeper-table-bindings",
}
