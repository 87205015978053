import {IOrder} from "../../models/orders/order";
import React from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import moment from "moment";
import {CURRENCY} from "../../utils/options/options";
import {PrintButton} from "../helpers/printButtons";

interface IOrderModalProps {
    orderModalInfo: { order: IOrder | null, active: boolean }
    setOrderModalInfo: React.Dispatch<React.SetStateAction<{ order: IOrder | null, active: boolean }>>
}

export function OrderModal({orderModalInfo, setOrderModalInfo}: IOrderModalProps) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()

    const tableRef = React.useRef(null);
    useOnClickOutside(tableRef, () => setOrderModalInfo({order: null, active: false}), "mouseup")

    return (
        <>
            <div
                className={orderModalInfo.active ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                style={{display: orderModalInfo.active ? "block" : "none"}}
                id="modal-order-preview"
                aria-hidden={orderModalInfo.active}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" ref={tableRef}>
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button aria-label="Close" className="close" data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        setOrderModalInfo(
                                            {
                                                order: null,
                                                active: false
                                            }
                                        )
                                    }}
                            ></button>
                            <div id="order-preview-content">

                                <form className="form">
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.order_w_number()}:</div>
                                        </div>
                                        <div className="col-md-6">
                                            {orderModalInfo.order?.number || ""}

                                        </div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.date()}:</div>
                                        </div>
                                        <div className="col-md-6">

                                        <span className="calendar"
                                              data-iso-value="2022-03-31T04:12:34.943Z">{moment(orderModalInfo.order?.createdAt).format('D MMMM YYYY, h:mm:ss ')}</span>

                                        </div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.name() || ""}:</div>
                                        </div>
                                        <div
                                            className="col-md-6">{orderModalInfo.order?.customerContacts.name || ""}</div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.phone()}:</div>
                                        </div>
                                        <div
                                            className="col-md-6">{orderModalInfo.order?.customerContacts.phone.number}</div>
                                    </div>
                                    <div className="row mb-2"
                                         style={{
                                             display: orderModalInfo.order?.customerComment && orderModalInfo.order.customerComment.length > 0 ? "flex" : "none",
                                             justifyContent: "flex-start"
                                         }}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.customerComment()}:</div>
                                        </div>
                                        <div className="col-md-6">{orderModalInfo.order?.customerComment}</div>
                                    </div>
                                    <div className="row mb-2" style={{display: "flex", justifyContent: "flex-start"}}>
                                        <div className="col-md-6">
                                            <div className="tx-gray">{i18n.delivery_type()}:</div>
                                        </div>
                                        <div className="col-md-6">

                                            {orderModalInfo.order?.delivery.type === "selfPickupEmpty" ? i18n.self_pickup() : null}
                                            {orderModalInfo.order?.delivery.type === "customPlace" ? i18n.at_place() : null}
                                            {orderModalInfo.order?.delivery.type === "city" ? i18n.delivery() : null}
                                            {orderModalInfo.order?.delivery.type === "placements" ?
                                                `${(orderModalInfo.order.delivery.data?.label || {})[menu.i18n.defaultLang]}: ${(orderModalInfo.order.delivery.data?.name || {})[menu.i18n.defaultLang]}`
                                                : null}
                                            {orderModalInfo.order?.delivery.type === "cinema" ? i18n.delivery_in_cinema_hall() : null}

                                        </div>
                                    </div>

                                    {orderModalInfo.order?.delivery.type === "city" ? (
                                        <>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.home_address()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order?.delivery.data?.address}</div>
                                            </div>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.other_address()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order?.delivery.data?.detailedAddress}</div>
                                            </div>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.delivery_comment()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order?.delivery.data?.deliveryComment}</div>
                                            </div>
                                        </>

                                    ) : ""}

                                    {orderModalInfo.order?.delivery.type === "customPlace" ? (
                                        <>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{orderModalInfo.order.delivery.data?.label
                                                        ? orderModalInfo.order.delivery.data.label[menu.i18n.defaultLang] || i18n.table_number()
                                                        : i18n.table_number()}:
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order.delivery.data?.value}</div>
                                            </div>
                                        </>

                                    ) : ""}

                                    {orderModalInfo.order?.delivery.type === "cinema" ?
                                        <>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.cinema_hall_number()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order.delivery.data?.cinemaHallNumber}</div>
                                            </div>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.row_number()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order.delivery.data?.rowNumber}</div>
                                            </div>
                                            <div className="row mb-2"
                                                 style={{display: "flex", justifyContent: "flex-start"}}>
                                                <div className="col-md-6">
                                                    <div className="tx-gray">{i18n.seat_number()}:</div>
                                                </div>
                                                <div
                                                    className="col-md-6">{orderModalInfo.order.delivery.data?.seatNumber}</div>
                                            </div>
                                        </>
                                        : null}

                                    <OrderTable order={orderModalInfo.order}/>
                                    <OrderExtendedTable order={orderModalInfo.order}/>
                                </form>
                            </div>
                            <PrintButton previewId={"order-preview-content"} cardId={"card"}
                                         tableCardId={"table-card"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={orderModalInfo.active ? "modal-backdrop show" : ""}></div>
        </>
    )
}

function OrderTable({order}: { order: IOrder | null }) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div className="default-table my-4" id={"card"}>
            <table>
                <thead>
                <tr>
                    <th>{i18n.pl_item()}</th>
                    <th>{i18n.amount()}</th>
                    <th>{i18n.price()}</th>
                </tr>
                </thead>
                <tbody>

                {order?.items.map((v, index) => {
                    return (
                        <tr key={index}>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                          <span>
                                                              {v.name[menu.i18n.defaultLang]} {v.variant.label ?
                                                              v.variant.label[menu.i18n.defaultLang] ?
                                                                  `(${v.variant.label[menu.i18n.defaultLang]})` : null : null}
                                                          </span>

                                    <br/>
                                    <div
                                        className="text-secondary modifier-tuple">
                                        {(v.modSelects || []).map((ms) => {
                                            return (
                                                <div>{(ms.header || {})[menu.i18n.defaultLang]}: {(ms.label || {})[menu.i18n.defaultLang]}</div>
                                            )
                                        })}
                                    </div>
                                    <div
                                        className="text-secondary modifier-tuple">
                                        {v.modExtras?.map((me) => {
                                            return (
                                                <div>{(me.label || {})[menu.i18n.defaultLang]}</div>
                                            )
                                        })}
                                    </div>
                                </div>


                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {v.quantity}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.totalPrice / 100} {CURRENCY[order?.currency || ""]}
                            </td>
                        </tr>
                    )
                })}
                {order?.orderSupItems?.map((v, index) => {
                    return (
                        <tr key={index}>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                          <span>
                                                              {v.name[menu.i18n.defaultLang]}
                                                          </span>
                                </div>


                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {v.quantity}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.price / 100} {CURRENCY[order?.currency || ""]}
                            </td>
                        </tr>
                    )
                })}

                {(order?.charges || []).map((v, index) => {
                    return (
                        <tr className="table-secondary" key={index}>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                                            <span>
                                                                {(v.charge.data.name || {})[menu.i18n.defaultLang]}
                                                            </span>
                                </div>
                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.amount / 100} {CURRENCY[order?.currency || ""]}

                            </td>
                        </tr>
                    )
                })}

                {(order?.discounts || []).map((v, index) => {
                    return (
                        <tr className="table-secondary" key={index}>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                    <span>
                                        {(v.discount.data.name || {})[menu.i18n.defaultLang]}
                                    </span>
                                </div>
                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.amount / 100} {CURRENCY[order?.currency || ""]}

                            </td>
                        </tr>
                    )
                })}

                <tr className="table-secondary">
                    <td>Итого</td>
                    <td data-title={i18n.amount()} className="number-td">
                        {order?.totalQuantity}
                    </td>
                    <td data-title={i18n.price()} className="number-td">
                        {(order?.totalPrice || 0) / 100} {CURRENCY[order?.currency || ""]}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

function OrderExtendedTable({order}: { order: IOrder | null }) {
    const {i18n} = useGlobalContext()
    const {menu} = useUserContext()
    return (
        <div id={"table-card"} style={{display: "none"}}>
            <br/>
            <table style={{width: "100%"}}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>{i18n.pl_item()}</th>
                    <th>{i18n.amount()}</th>
                    <th>{i18n.price()}</th>
                    <th>{i18n.summ()}</th>
                </tr>
                </thead>
                <tbody>

                {order?.items.map((v, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                    {v.name[menu.i18n.defaultLang]} {v.variant.label ?
                                    v.variant.label[menu.i18n.defaultLang] ?
                                        `(${v.variant.label[menu.i18n.defaultLang]})` : null : null}
                                </div>
                                {(v.modSelects || []).map((ms) => {
                                    return (
                                        <div>{(ms.header || {})[menu.i18n.defaultLang]}: {(ms.label || {})[menu.i18n.defaultLang]}</div>
                                    )
                                })}
                                {v.modExtras?.map((me) => {
                                    return (
                                        <div>{(me.label || {})[menu.i18n.defaultLang]}</div>
                                    )
                                })}


                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {v.quantity}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.totalPrice / v.quantity / 100}
                            </td>
                            <td data-title={i18n.summ()} className="number-td">
                                {v.totalPrice / 100}
                            </td>
                        </tr>
                    )
                })}

                {order?.orderSupItems?.map((v, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td data-title={i18n.pl_item()}>
                                <div>
                                    {v.name[menu.i18n.defaultLang]}
                                </div>
                            </td>
                            <td data-title={i18n.amount()} className="number-td">
                                {v.quantity}
                            </td>
                            <td data-title={i18n.price()} className="number-td">
                                {v.price / v.quantity / 100}
                            </td>
                            <td data-title={i18n.summ()} className="number-td">
                                {v.price / 100}
                            </td>
                        </tr>
                    )
                })}

                {(order?.charges || []).map((v, index) => {
                    return (
                        <>
                            <tr>
                                <td colSpan={6}>{i18n.additional()}</td>
                            </tr>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td data-title={i18n.pl_item()} colSpan={3}>
                                    {(v.charge.data.name || {})[menu.i18n.defaultLang] || ""}
                                </td>
                                <td data-title={i18n.price()} className="number-td">
                                    {v.charge.type === "percentageCharge" ? v.amount / 100 + "%" : v.amount / 100 + " " + CURRENCY[order?.currency || ""]}
                                </td>
                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
            <br/>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <p>
                    <strong>{i18n.total_summ()}</strong> : {(order?.totalPrice || 0) / 100} {CURRENCY[order?.currency || ""]}
                </p>
            </div>
        </div>
    )
}
