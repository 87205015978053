import {useGlobalContext, useUserContext} from "../globalContext";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ICategory} from "../../models/category/ICategory";
import {I18nString} from "../../models/lang/i18nString";
import {RouteNames} from "../../migration/pages";

export function useCategoryForm(isEdit: boolean) {
    const {api} = useGlobalContext()
    const {currentMenuId, menu, user} = useUserContext()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    let {id} = useParams()

    let [category, setCategory] = useState<ICategory>({
        id: "",
        name: {},
        description: {},
        currentSectionId: "",
        sections: []
    })
    const [selectedSection, setSelectedSection] = useState<number>(0)

    const [loading, setLoading] = useState<boolean>(false)
    const [saveCategoryLoading, setSaveCategoryLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (isEdit) {
            api.getCategoryById({controller, setLoading:setLoading},id as string, currentMenuId).then((resp) => {
                    if (resp.data) {
                        setCategory(resp.data)
                        let ind = resp.data.sections.findIndex((s) => s.id === resp.data?.currentSectionId)
                        setSelectedSection(ind)
                    }
                }
            )
        } else {
            api.getSections({controller,setLoading:setLoading},currentMenuId).then((resp) => {
                    if (resp.data) {
                        let secid = searchParams.get("section_id")
                        setCategory({
                            id: "",
                            name: {},
                            description: {},
                            currentSectionId: secid || "",
                            sections: resp.data.sections
                        })
                        setSelectedSection(resp.data.sections.findIndex((s) => s.id === secid))
                    }
                }
            )
        }
        return () => controller.abort()
    }, [currentMenuId])



    //Event
    function onChangeSection(ind: string) {
        let index = parseInt(ind)
        let id = category.sections[index].id
        setSelectedSection(index)
        setCategory({
            ...category,
            currentSectionId: id
        })
    }

    function onChangeName(name: I18nString) {
        setCategory({
            ...category,
            name: name
        })
    }

    function onChangeDescription(description: I18nString) {
        setCategory({
            ...category,
            description: description
        })
    }

    async function onSave() {
        if (isEdit) {
            let update = {
                id: category.id,
                menuId: currentMenuId,
                sectionId: category.currentSectionId,
                name: category.name,
                desc: category.description
            }
            api.updateCategory({setLoading:setSaveCategoryLoading},update).then((resp) => {
                if (resp.success) {
                    navigate(RouteNames.MENUS)
                }
            })
        } else {
            let create = {
                menuId: currentMenuId,
                sectionId: category.currentSectionId,
                name: category.name,
                desc: category.description
            }
            api.createCategory({setLoading:setSaveCategoryLoading},create).then((resp) => {
                if (resp.success) {
                    navigate(RouteNames.MENUS)
                }
            })
        }
    }

    return {
        category,
        isStaff:!!user?.isStaff,
        menu,
        currentMenuId,
        selectedSection,
        onSave,
        onChangeName,
        onChangeSection,
        onChangeDescription,

        saveCategoryLoading,
        loading
    }
}
