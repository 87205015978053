import React from "react";
import {Link} from "react-router-dom";
import {TextAreaI18n} from "../input/textAreaI18n";
import {SubmitButton} from "../input/submitButton";
import {useMainPageEditor} from "../../hooks/mainPageEditor/mainPageEditor";
import {InputText} from "../input/inputText";
import {CropperModal} from "../modal/cropperModal";
import {InputPhone} from "../input/phoneInput";
import {MultiInputPhone} from "../input/mupltiPhoneInput";
import {useGlobalContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../loader/formWithLoader";
import {ImageUploadComponent} from "../input/imageUploadComponent";
import {RouteNames} from "../../migration/pages";

export function MainPageEditorForm() {
    const info = useMainPageEditor()
    const {i18n} = useGlobalContext()
    return (
        <>
            <FormWithLoader isloading={info.menuOrgAttrLoading} className="form">
                {/* logo */}

                <ImageUploadComponent img={info.mainPageInfo.logo} ImgType={"orgLogos"}
                                      onUploadImage={info.onUploadLogoFile}
                                      onDeleteImage={info.onDeleteLogo} imgUploading={info.uploadLogoImageLoading}
                                      type={"-section"} size={"-big"} title={i18n.org_logo()}
                                      message={i18n.logo_format()}/>

                <ImageUploadComponent img={info.mainPageInfo.backgroundImg} ImgType={"menuOrgAttrsBackgrounds"}
                                      onUploadImage={info.onUploadBackgroundFile}
                                      onDeleteImage={info.onDeleteBackground}
                                      imgUploading={info.uploadBackgroundImageLoading}
                                      type={"-section"} size={"-big"} title={i18n.background_img()}
                                      message={i18n.image_upload_hint_std()}/>

                <br/>

                {/* Name */}
                <InputText label={i18n.title()} value={info.mainPageInfo.name} onChange={info.onChangeName}
                           isValidate={true}/>

                {/* Description */}
                <TextAreaI18n values={info.mainPageInfo.desc || {}} setValues={info.onChangeDesc} isStaff={info.isStaff}
                              label={i18n.full_desc()} isRequired={true} lang={info.menu.i18n.defaultLang}
                              allLangs={info.menu.i18n.otherLangs || []}/>

                {/* Address */}
                <TextAreaI18n values={info.mainPageInfo.address || {}} setValues={info.onChangeAddress}
                              isStaff={info.isStaff}
                              label={i18n.org_address()} isRequired={false}
                              lang={info.menu.i18n.defaultLang}
                              allLangs={info.menu.i18n.otherLangs || []} small={true}/>

                {/* Schedule */}
                <TextAreaI18n values={info.mainPageInfo.schedule || {}} setValues={info.onChangeSchedule} small={true}
                              label={i18n.schedule()} isRequired={false} lang={info.menu.i18n.defaultLang}
                              allLangs={info.menu.i18n.otherLangs || []} isStaff={info.isStaff}/>
                {/* Phones */}
                <MultiInputPhone label={i18n.phone_numbers()} value={info.mainPageInfo.phoneContacts || []}
                                 onChange={info.onChangePhoneContacts}
                                 placeholder={i18n.press_enter_to_save_phone_number()}/>

                {/* Instagram */}
                <InputText label={"Instagram"} value={info.mainPageInfo.instagram || ""}
                           onChange={info.onChangeInstagram} isValidate={true}/>

                {/* Whatsapp */}
                <InputPhone label={"WhatsApp"} value={info.mainPageInfo.whatsapp || {number: "", countryCode: ""}}
                            onChange={info.onChangeWhatsapp}/>

                {/* Facebook */}
                <InputText label={"Facebook"} value={info.mainPageInfo.facebook || ""}
                           onChange={info.onChangeFacebook} isValidate={true}/>

                {/* Site */}
                <InputText label={"Site"} value={info.mainPageInfo.site || ""} onChange={info.onChangeSite}
                           isValidate={true}/>

                <div className="form__buttons">
                    <div className="form__buttons-left">
                        <SubmitButton onSubmit={info.onSave} label={i18n.save()} isLoading={info.saveLoading}
                                      disabled={!info.currentMenuId}
                        />
                        <Link to={RouteNames.MENUS}
                              className="button -bordered_blue form__button">
                            {i18n.cancel()}
                        </Link>
                    </div>
                </div>

            </FormWithLoader>
            <CropperModal modal={info.logoModal} selectedImage={info.selectedLogoImage} setModal={info.setLogoModal}
                          saveImage={info.UploadLogoImage} setSelectImage={info.setSelectedLogoImage}/>
            <CropperModal modal={info.backgroundModal} selectedImage={info.selectedBackgroundImage}
                          setModal={info.setBackgroundModal}
                          saveImage={info.UploadBackgroundImage} setSelectImage={info.setSelectedBackgroundImage}/>
        </>
    )
}
