import {UserActionCreators} from "migration/app/store/reducers/user/action-creators";
import {AppDispatch, RootState} from "migration/app/store";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FailedResponseHandler, httpHandler} from "migration/shared/lib/http-handler/httpHandler";
import {AuthActionEnum, SetIsLoadingAction, SetLoginAction} from "./types";
import AuthService from "../../api/authService";

export const AuthActionCreators = {
    setIsLoading: (payload: boolean): SetIsLoadingAction => ({type: AuthActionEnum.SET_IS_LOADING, payload}),
    login: (): SetLoginAction => ({type: AuthActionEnum.LOGIN}),

    makeLogin: (username: string, password: string, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        try {
            dispatch(AuthActionCreators.login());
            dispatch(AuthActionCreators.setIsLoading(true));
            const res = await AuthService.login({username, password});
            if (res.data.success) {
                await UserActionCreators.fetchCurrentUser(navigationCallback)(dispatch, getState);
            } else {
                FailedResponseHandler({
                    message: res.data?.error?.message,
                    httpStatus: res.status,
                });
            }
        } catch (e: any) {
            httpHandler({
                error: e,
                httpStatus: e?.response?.status,
                dispatch: dispatch,
                currentLang: currentLang,
            });
        } finally {
            dispatch(AuthActionCreators.setIsLoading(false));
        }
    },
}
