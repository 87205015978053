import {Link} from "react-router-dom";
import {CropperModal} from "../modal/cropperModal";
import {useSliderForm} from "../../hooks/slider/slideForm";
import React from "react";
import {TextAreaI18n} from "../input/textAreaI18n";
import {SubmitButton} from "../input/submitButton";
import {useGlobalContext} from "../../hooks/globalContext";
import {FormWithLoader} from "../loader/formWithLoader";
import {ImageUploadComponent} from "../input/imageUploadComponent";
import {InputText} from "../input/inputText";
import {RouteNames} from "../../migration/pages";

export function SlideForm({isEdit}: { isEdit: boolean }) {
    const info = useSliderForm(isEdit)
    const {i18n} = useGlobalContext()
    return (
        <>
            <div className="row">
                <div className="col-lg-8 col-xl-5">
                    <div className="default-card">
                        <FormWithLoader isloading={info.slideLoading} className="form">

                            <ImageUploadComponent img={info.slide.img} ImgType={"menuSlides"}
                                                  onUploadImage={info.onChangeUploadImg}
                                                  onDeleteImage={info.onDeleteImg}
                                                  imgUploading={info.uploadSlideLoading}
                                                  message={i18n.image_upload_hint_std()}
                                                  type={"-slider"} size={"-big"}
                            />

                            <InputText label={i18n.link()} value={info.slide?.url ? info.slide?.url : ""}
                                       onChange={info.onChangeURL} isValidate={true}/>
                            <TextAreaI18n values={info.slide.text || {}} setValues={info.onChangeText}
                                          lang={info.menu.i18n.defaultLang}
                                          label={i18n.text()} allLangs={info.menu.i18n.otherLangs || []}
                                          isStaff={info.isStaff}
                            />

                            <div className="form__buttons">
                                <div className="form__buttons-left">
                                    <SubmitButton onSubmit={info.saveSlide} label={i18n.save()}
                                                  disabled={!info.currentMenuId} isLoading={info.saveSlideLoading}/>
                                    <Link to={RouteNames.SLIDER}
                                          className="button -bordered_blue form__button">
                                        {i18n.cancel()}
                                    </Link>
                                </div>
                            </div>

                        </FormWithLoader>
                    </div>
                </div>
            </div>
            <CropperModal modal={info.modal} selectedImage={info.selectedImage} setModal={info.setModal}
                          saveImage={info.UploadImg} setSelectImage={info.setSelectedImage}/>
        </>

    )
}
