import {useGlobalContext, useUserContext} from "../globalContext";
import {useEffect, useState} from "react";
import {ISettingScripts} from "../../models/settings/scripts";

export function useScripts() {
    const {currentMenuId} = useUserContext()
    const {api} = useGlobalContext()

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const [scriptData, setScriptData] = useState<ISettingScripts>({metaTags: "", script: ""})
    useEffect(() => {
        if (!currentMenuId) {
            return
        }
        const controller = new AbortController()
        api.getScripts({controller, setLoading: setLoading}, currentMenuId).then((res) => {
            if (res.data) {
                setScriptData(res.data.customWeb)
            }
        })
        return () => controller.abort()
    }, [currentMenuId])

    function handleChangeScript(script: string) {
        setScriptData({...scriptData, script})
    }

    function handleChangeMetaTags(metaTags: string) {
        setScriptData({...scriptData, metaTags})
    }

    function handleSubmit() {
        api.saveScripts({setLoading: setSaveLoading}, scriptData, currentMenuId)
    }

    return {
        scriptData,
        handleChangeScript,
        handleChangeMetaTags,
        handleSubmit,
        currentMenuId,loading, saveLoading
    }
}