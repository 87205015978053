import {Link, useNavigate} from "react-router-dom";
import React, {useRef, useState} from "react";
import {useGlobalContext, useStaffContext, useUserContext} from "../../hooks/globalContext";
import {Image} from "../image/image";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {useActions} from "../../migration/shared/lib/hooks/useActions";
import {RouteNames} from "../../migration/pages";

export function HeaderStaff() {
    const {setAuth, setInitialLocation} = useActions();
    const [drop, setDrop] = useState<boolean>(false)
    const navigate = useNavigate()
    const {i18n, api} = useGlobalContext()
    const {user, setUser} = useUserContext()
    const {changeOrg, orgNames, removeOrg} = useStaffContext()
    const staffRef = useRef(null)
    const changeOrgRef = useRef(null)
    const [showChangeOrg, setShowChangeOrg] = useState(false)

    const [logout, setLogout] = useState(false)
    const [logoutLoading, setLogoutLoading] = useState(false)

    useOnClickOutside(staffRef, () => setDrop(false), "mouseup")
    useOnClickOutside(changeOrgRef, () => setShowChangeOrg(false), "mouseup")

    function onLogout(e: any) {
            e.preventDefault()
            setLogout(true)
            api.logout({setLoading:setLogoutLoading}).then((res) => {
                if (res.success) {
                    setUser(null)
                    setAuth(false);
                    localStorage.clear()
                    setInitialLocation(RouteNames.LOGIN)
                    navigate(RouteNames.LOGIN)
                }
            }).finally(() => {
                setLogout(false)
            })
    }


    return (
        <header className="header">
            <div className="header__container">
                <div className="header__title">

                    <Image className={"header__title-logo"} alt={""} src={user?.org?.logoImg as string}
                           type={"orgLogos"}/>
                    Kami
                </div>
                <div className="header__shop-name">
                    Kami
                </div>

                {user?.isStaff && orgNames.length > 0 ?
                    <div className="dropdown" ref={changeOrgRef}>
                        <Link to="" className="header__qr-link" onClick={(e) => {
                            e.preventDefault();
                            setShowChangeOrg(!showChangeOrg)
                        }
                        } id="qrDropdown" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                            {user?.org ?
                                user?.org.name
                                : i18n.select_org()
                            }
                            <i className="fa fa-caret-down"></i>
                        </Link>
                        <div
                            className={showChangeOrg ? "dropdown-menu dropdown-menu-left show" : "dropdown-menu dropdown-menu-left"}
                            tabIndex={0}>
                            {orgNames.map((org) => {
                                return (
                                    <div style={{display: "flex"}} key={"header-" + org.id}>
                                        <Link to="#" onClick={(e) => {
                                            e.preventDefault();
                                            setShowChangeOrg(false);
                                            changeOrg(org.id);
                                            navigate(RouteNames.SLIDER)
                                        }}
                                              className={org.id === user?.org?.id ? "header__user-sublink -blue -n80" : "header__user-sublink -n80"}>
                                            {org.name}
                                        </Link>
                                        <button style={{border: 0}}
                                                className={org.id === user?.org?.id ? "header__user-sublink -blue -n20" : "header__user-sublink -n20"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log("remove org", org.id)
                                                    setShowChangeOrg(false);
                                                    removeOrg(org.id)
                                                }}>x
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : null
                }


                <div className={drop ? "header__user dropdown show" : "header__user dropdown"}>
                    <Link className="header__user-toggle" to="#" onClick={(e) => {
                        e.preventDefault()
                        setDrop(!drop)
                    }} id="userDropdown"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded={drop}>
                        <div className="header__user-icon">

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 50 50">{/*style = {{enableBackground: 'new 0 0 50 50;'}} */}
                                <path d="M5,32v17h30.343l2.167-1.686C42.27,43.612,45,38.03,45,32v-0.649l-8.841-3.931c-1.59-4.225-5.229-7.262-9.55-8.147
                     C29.257,17.472,31,14.436,31,11c0-5.514-4.486-10-10-10S11,5.486,11,11c0,3.431,1.739,6.464,4.38,8.265C9.464,20.481,5,25.729,5,32
                     z M42.988,32.645c-0.187,5.16-2.607,9.903-6.707,13.092L35,46.733l-1.281-0.997c-4.1-3.188-6.521-7.932-6.707-13.092l7.99-3.55
                     L42.988,32.645z M13,11c0-4.411,3.589-8,8-8s8,3.589,8,8s-3.589,8-8,8S13,15.411,13,11z M18,21h6c4.312,0,8.173,2.517,9.961,6.367
                     L25,31.351V32c0,5.865,2.589,11.3,7.109,15H7V32C7,25.935,11.935,21,18,21z" fill="#646D7C"></path>
                                <polygon
                                    points="36,33 34,33 34,36 31,36 31,38 34,38 34,41 36,41 36,38 39,38 39,36 36,36 	"></polygon>
                            </svg>

                        </div>
                        <div className="header__user-info">
                            <div>
                                {user?.user ? user?.user?.username : ""}
                            </div>
                        </div>
                    </Link>

                    <div ref={staffRef}
                         className={+drop ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}
                         tabIndex={0}>
                        <Link to={"/staff/account/change-password"} className="header__user-sublink">
                            <svg width="20px" height="20px" viewBox="0 0 48.00 48.00" xmlns="http://www.w3.org/2000/svg"
                                 fill="#8392A5" stroke="#8392A5">
                                <g id="SVGRepo_bgCarrier"></g>
                                <g id="SVGRepo_tracerCarrier" stroke="#CCCCCC"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="invisible_box" data-name="invisible box">
                                            <rect width="48" height="48" fill="none"></rect>
                                        </g>
                                        <g id="Layer_7" data-name="Layer 7">
                                            <g>
                                                <path
                                                    d="M39,18H35V13A11,11,0,0,0,24,2H22A11,11,0,0,0,11,13v5H7a2,2,0,0,0-2,2V44a2,2,0,0,0,2,2H39a2,2,0,0,0,2-2V20A2,2,0,0,0,39,18ZM15,13a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v5H15ZM37,42H9V22H37Z"></path>
                                                <circle cx="15" cy="32" r="3"></circle>
                                                <circle cx="23" cy="32" r="3"></circle>
                                                <circle cx="31" cy="32" r="3"></circle>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {i18n.change_password()}
                        </Link>
                        {logout ?
                            <Link to="#" className="header__user-sublink">
                                <div className={"loader-button"}></div>
                            </Link>
                            :
                            <Link to={"#"} className="header__user-sublink"
                                  onClick={onLogout}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.4783 7.82608C13.7183 7.82608 13.9131 7.63129 13.9131 7.39129V2.1739C13.913 0.975652 12.9383 0 11.7391 0H2.1739C0.974796 0 0 0.975652 0 2.1739V17.8261C0 19.0243 0.974796 20 2.1739 20H11.7391C12.9383 20 13.913 19.0243 13.913 17.8261V12.6087C13.913 12.3687 13.7182 12.1739 13.4782 12.1739C13.2382 12.1739 13.0435 12.3687 13.0435 12.6087V17.8261C13.0435 18.5452 12.4583 19.1304 11.7391 19.1304H2.1739C1.45475 19.1304 0.869551 18.5452 0.869551 17.8261V2.1739C0.869551 1.45475 1.45475 0.869551 2.1739 0.869551H11.7391C12.4583 0.869551 13.0435 1.45475 13.0435 2.1739V7.39129C13.0435 7.63129 13.2383 7.82608 13.4783 7.82608Z"
                                        fill="#8392A5"></path>
                                    <path
                                        d="M19.5652 9.56543H5.6522C5.4122 9.56543 5.21741 9.76023 5.21741 10.0002C5.21741 10.2402 5.4122 10.435 5.6522 10.435H19.5652C19.8052 10.435 20 10.2402 20 10.0002C20 9.76018 19.8052 9.56543 19.5652 9.56543Z"
                                        fill="#8392A5"></path>
                                    <path
                                        d="M19.872 9.69388L17.2633 7.08518C17.0938 6.91562 16.8181 6.91562 16.6485 7.08518C16.479 7.25475 16.479 7.53041 16.6485 7.69998L18.9494 10.0008L16.6485 12.3017C16.479 12.4713 16.479 12.7469 16.6485 12.9165C16.7338 13.0008 16.845 13.0435 16.9564 13.0435C17.0677 13.0435 17.179 13.0009 17.2633 12.9174L19.872 10.3087C20.0416 10.1391 20.0416 9.86344 19.872 9.69388Z"
                                        fill="#8392A5"></path>
                                </svg>
                                {i18n.exit()}
                            </Link>
                        }

                    </div>
                </div>
            </div>
        </header>
    )
}
